import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Collapse, Slider, TextField, Typography } from '@material-ui/core';
import { Enums } from '../../../config/enums';
import { SettingsIcon } from '../../../config/svg/MyGoalSvg';
import { CreateKeyResult } from '../../Goals/KeyResult/CreateKeyResult';
import { OKRButton } from '../../Common/OKRButton';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { getDueDays, getDueDaysText, getUserDetails } from '../../../config/utils';
import { AddIconSvg, DeleteIcon } from '../../../config/svg/CommonSvg';
import { CallOutTooltip } from '../CallOutTooltip';
import { getLocalStorageItem } from '../../../services/StorageService';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import AlertDialog from '../../Common/Dialog';
import { calloutsDataForOKR, defaultCalloutProps } from '../../../config/constant';

export const CreateObjectiveDetail: React.FC<any> = (props) => {
	const {
		t,
		addNewKR,
		deleteAddedKR,
		updateFormDetail,
		handleSaveObjective,
		showAddGoal,
		durationCycleDetail,
		handleDrawerOpen,
		handleCloseCreateGoal,
		objectiveDetail,
		handleObjectiveNameChange,
		handleDateChange,
		currentCycleEndDate,
		resetKeyAttributeById,
		formSubmit,
		goalFormError,
		setShowKr,
		autoSaveData,
		isAutoSaving,
		isDataSaved,
		setIsDataSaved,
		activeOnboardingPanel,
		finalSubmitted,
		setFinalSubmitted,
		editModee,
		setEditModee,
		currentCycleId,
	} = props;

	const loginUserAvatar = getUserDetails();
	const [modalPropsOKR, setModalPropsOKR] = useState<any>({ open: false, type: '', message: '', module: '', data: {} });
	const [modalPropsOKRCalendar, setModalPropsOKRCalendar] = useState<any>({
		open: false,
		type: '',
		message: '',
		module: '',
		data: {},
	});
	const [userCycleChanged, setUserCycleChanged] = useState<boolean>(false);

	const handleDateCloseModalCalendar = (event: any, type: Number, startDate?: any) => {
		if (type === 1) {
			if (modalPropsOKRCalendar.type === 'updateDateForOKR') {
				setModalPropsOKRCalendar({ open: false, type: '', message: '', module: '', data: {} });
				setSelectedDateRange(modalPropsOKRCalendar.dateRange);
				handleDateChange(
					modalPropsOKRCalendar.data.date,
					'',
					modalPropsOKRCalendar.data.startDate,
					modalPropsOKRCalendar.selectedQuarterData || selectedCycleDetails
				);
				setUserCycleChanged(true);
			}
		}
		setModalPropsOKRCalendar({ open: false, type: '', message: '', module: '' });
	};

	const handleDateCloseModal = (event: any, type: Number, startDate?: any) => {
		if (type === 1) {
			if (modalPropsOKR.type === 'updateDateForOKR') {
				setModalPropsOKR({ open: false, type: '', message: '', module: '', data: {} });
				handleDateChange(
					modalPropsOKR.data.date,
					'',
					modalPropsOKR.data.startDate,
					modalPropsOKR.selectedQuarterData || selectedCycleDetails
				);
				setSelectedDateRange(modalPropsOKR.dateRange);
			}
		}
		setModalPropsOKR({ open: false, type: '', message: '', module: '' });
	};
	const hasContributor = (objectiveDetail: any) => {
		const krWithContributor = objectiveDetail.myGoalsDetails.filter(
			(item: any) => item.contributors && item.contributors.length > 0
		);
		if (krWithContributor.length > 0) {
			return true;
		}
		return false;
	};

	const isDisabled = () => {
		return Boolean(objectiveDetail) &&
			objectiveDetail?.objectiveName.length >= Enums.DEFAULT_TYPE_CHAR &&
			Boolean(objectiveDetail.myGoalsDetails) &&
			objectiveDetail.myGoalsDetails.length &&
			objectiveDetail.myGoalsDetails.find((item: any) => item.keyDescription.length >= Enums.DEFAULT_TYPE_CHAR)
			? false
			: true;
	};

	const checkMandatoryFieldforObjective = () => {
		return (
			Boolean(objectiveDetail) &&
			objectiveDetail?.objectiveName.length >= Enums.DEFAULT_TYPE_CHAR &&
			objectiveDetail?.goalNatureId &&
			objectiveDetail?.goalTypeId
		);
	};
	const checkMandatoryFieldforKR = () => {
		let isValid = Boolean(objectiveDetail) && Boolean(objectiveDetail.myGoalsDetails);
		if (isValid && objectiveDetail.myGoalsDetails.length) {
			let validKrs = objectiveDetail.myGoalsDetails.find((item: any) => {
				if (item?.metricId === Enums.ONE || item?.metricId === Enums.TWO || item?.metricId === Enums.THREE) {
					item.startValue = !item.startValue ? 0 : item.startValue;
					item.targetValue = !item.targetValue ? 0 : item.targetValue;
					if (item.startValue === item.targetValue) {
						return true;
					}
				}
				return (
					item.keyDescription.length >= Enums.DEFAULT_TYPE_CHAR && (item.metricId === 0 || item.metricId === undefined)
				);
			});
			isValid = !Boolean(validKrs);
		}
		return isValid;
	};

	const validateOkrData = (e: any, type: number) => {
		setFinalSubmitted(true);
		if (checkMandatoryFieldforObjective() && checkMandatoryFieldforKR()) {
			setFinalSubmitted(false);
			handleSaveObjective(e, type);
		} else {
			setModalPropsOKR({
				open: true,
				type: 'validateForm',
				message: t('confirmMandatoryOkrField'),
				module: 'validateForm',
				data: {},
			});
		}
	};

	//callout popup
	const [showCallouts, setShowCallouts] = useState<boolean>(false);
	const [allCycleDetails, setAllCycleDetails] = useState<any>([]);
	const [selectedDateRange, setSelectedDateRange] = useState<any>([]);
	const [OKRDateRangeDetail, setOKRDateRangeDetail] = useState<any>({
		start: objectiveDetail.startDate,
		end: objectiveDetail.endDate,
	});

	useEffect(() => {
		setOKRDateRangeDetail({
			...OKRDateRangeDetail,
			start: objectiveDetail.startDate,
			end: objectiveDetail.endDate,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveDetail]);
	useEffect(() => {
		if (getLocalStorageItem('showCallouts')) {
			setShowCallouts(true);
		}

		let getCycleDetails = JSON.parse(getLocalStorageItem('cycleDetail') || '');
		setAllCycleDetails(getCycleDetails);
	}, []);

	const initialCallout = defaultCalloutProps;
	const calloutsData = calloutsDataForOKR;
	const [callOut, setCallOut] = useState(initialCallout);
	const [anchorElCallouts, setAnchorElCallouts] = useState(null);
	const [showKrCallouts, setShowKrCallouts] = useState(false);
	const [showKrSettingCallouts, setShowKrSettingCallouts] = useState(false);
	const inputRefOkrTitle: any = useRef();
	const inputRefOkrSetting: any = useRef();
	const nextCallout = (currentCalloutIndex: Number) => {
		setShowKrCallouts(false);
		setShowKrSettingCallouts(false);

		if (currentCalloutIndex === 0) {
			setAnchorElCallouts(inputRefOkrSetting.current);
			setCallOut(calloutsData[1]);
		} else if (currentCalloutIndex === 1) {
			setShowKrCallouts(true);
		} else if (currentCalloutIndex === 2) {
			setShowKrSettingCallouts(true);
			setCallOut(calloutsData[3]);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	const prevCallout = (currentCalloutIndex: Number) => {
		setShowKrCallouts(false);
		setShowKrSettingCallouts(false);

		if (currentCalloutIndex === 3) {
			setShowKrCallouts(true);
		} else if (currentCalloutIndex === 2) {
			setAnchorElCallouts(inputRefOkrSetting.current);
			setCallOut(calloutsData[1]);
		} else if (currentCalloutIndex === 1) {
			inputRefOkrTitle.current.focus();
			//Callout for OKR Title Field
			setAnchorElCallouts(inputRefOkrTitle.current);
			setCallOut(calloutsData[0]);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	const [handIconShowed, setHandIconShowed] = useState(false);
	//black tooltip for seeting icon
	useEffect(() => {
		//Callout for Setting OKR Icon
		if (
			(!getLocalStorageItem('showCallouts') || anchorElCallouts === null) &&
			Boolean(objectiveDetail) &&
			objectiveDetail.objectiveName.length >= 1
		) {
			if (!handIconShowed) {
				setHandIconShowed(true);
				setShowCallouts(true);
				setAnchorElCallouts(inputRefOkrSetting.current);
				setCallOut(calloutsData[4]);
				setTimeout(() => {
					setAnchorElCallouts(null);
					setCallOut(initialCallout);
				}, 5000);
			}
		}
		if (Boolean(objectiveDetail) && objectiveDetail.objectiveName.length <= 0) {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveDetail.objectiveName]);

	// useEffect(() => {
	// 	console.log(objectiveDetail);
	// }, [objectiveDetail]);

	useEffect(() => {
		if (
			showAddGoal &&
			activeOnboardingPanel === 'createOkr' &&
			getLocalStorageItem('createOkrcalloutDone') === null &&
			getLocalStorageItem('showCallouts') === 'true'
		) {
			setShowCallouts(true);
			const timeout = setTimeout(() => {
				inputRefOkrTitle.current.focus();
				//Callout for OKR Title Field
				setAnchorElCallouts(inputRefOkrTitle.current);
				setCallOut(calloutsData[0]);
			}, 500);
			return () => {
				clearTimeout(timeout);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOnboardingPanel]);

	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(props.userSelectedCycle);

	useEffect(() => {
		objectiveDetail?.cycleDetailData && setSelectedCycleDetails(objectiveDetail?.cycleDetailData);
	}, [objectiveDetail]);

	return (
		<Collapse in={showAddGoal} className='create-okr-card-wrap'>
			<Box className='create-okr-card' id='create-okr-card'>
				{/* <OKRButton
					className='cancel-btn'
					title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('saveAndClose') : ''}
					icon={<CancelIcon />}
					handleClick={(e) => handleCloseCreateGoal(e, 'cancel')}
				/> */}
				<Box className='create-obj'>
					<Box className='create-obj-col'>
						<Box className='okr-col1'>
							<TextField
								className='add-objective'
								id='create-objective-field'
								value={objectiveDetail?.objectiveName}
								onChange={handleObjectiveNameChange}
								multiline
								autoFocus={true}
								error={(goalFormError.type === 'objectiveName' && goalFormError?.error) || ''}
								helperText={(goalFormError.type === 'objectiveName' && goalFormError?.helperText) || ''}
								minRows={2}
								placeholder={t('AddAnobjective')} //listOkrResult?.isFirstTimeUser ? t('AddFirstObjective')
								InputProps={{ 'aria-label': 'Add Objective', disableUnderline: true }}
								inputRef={inputRefOkrTitle}
							/>
						</Box>
						{Boolean(objectiveDetail) && objectiveDetail?.objectiveName.length >= Enums.DEFAULT_TYPE_CHAR ? (
							<Fragment>
								<Box className='okr-col2 contributors'>
									<Box className='contributors-avatar-group'>
										<UserAvatarGroup
											{...props}
											contributorDetails={objectiveDetail?.contributors}
											avatarClassName=''
											isButtonVisible={false}
											max={3}
											handleAddUserClick={() => {}}
											loginUserAvatar={
												objectiveDetail?.isCoach === true
													? {
															firstName: objectiveDetail?.ownerFirstName,
															lastName: objectiveDetail?.ownerLastName,
															imagePath: objectiveDetail?.ownerImagePath,
															employeeId: objectiveDetail?.employeeId,
													  }
													: loginUserAvatar
											}
											currentUser={false}
											contributorDetailsType={true}
											showTarget={false}
										/>
									</Box>
								</Box>
								<Box className='okr-col3'>
									<Box className='set-timeline-area'>
										<Box className='calender-date' id={'create-okr-cal-date'}>
											<ReactDateRangePicker
												type={'CreateObjectiveAttribute'}
												currentCycleEndDate={currentCycleEndDate}
												durationCycleDetail={durationCycleDetail}
												startDateValue={objectiveDetail.startDate}
												endDateValue={objectiveDetail.endDate}
												minimumDate={
													selectedCycleDetails.cycleStatus === Enums.CURRENT_CYCLE_ID
														? new Date()
														: selectedCycleDetails.startDate
												}
												maximumDate={selectedCycleDetails.endDate}
												isDateRangeTextFieldVisible={false}
												isOnlyDueDateVisible={true}
												handleDateSelection={(
													range: any,
													start: any,
													end: any,
													selectionType: String,
													isCycleChanged: Boolean,
													selectedQuarterData: any
												) => {
													if (!isCycleChanged) {
														// dateChangeHandler(range, start, end, selectionType);
														// setSelectedDateRange(range);
														// console.log(range);
														if (!isDisabled()) {
															setModalPropsOKR({
																open: true,
																type: 'updateDateForOKR',
																message: hasContributor(objectiveDetail)
																	? t('updateObjectiveAttribute')
																	: t('updateObjAttrNoContributor'),
																module: 'user',
																data: { date: end, okr: objectiveDetail, startDate: start },
																dateRange: range,
																selectedQuarterData: selectedQuarterData,
															});
														} else {
															handleDateChange(end, '', start, selectedCycleDetails);
															setUserCycleChanged(false);
															// if (isDisabled()) {
															// 	handleDateChange(end, '', start, selectedCycleDetails);
															// 	setUserCycleChanged(false);
															// } else {
															// 	setModalPropsOKR({
															// 		open: true,
															// 		type: 'updateDateForOKR',
															// 		message: hasContributor(objectiveDetail)
															// 			? t('updateObjectiveAttribute')
															// 			: t('updateObjAttrNoContributor'),
															// 		module: 'user',
															// 		data: { date: end, okr: objectiveDetail, startDate: start },
															// 		dateRange: range,
															// 		selectedQuarterData: selectedQuarterData,
															// 	});
															// }
														}
													} else {
														if (isDisabled()) {
															handleDateChange(end, '', start, selectedQuarterData || selectedCycleDetails);
															setSelectedDateRange(range);
															setUserCycleChanged(true);
														} else {
															setModalPropsOKRCalendar({
																open: true,
																type: 'updateDateForOKR',
																message: hasContributor(objectiveDetail)
																	? t('updateObjectiveAttribute')
																	: t('updateObjAttrNoContributor'),
																module: 'user',
																data: { date: end, okr: objectiveDetail, startDate: start },
																dateRange: range,
															});
														}
														// setSelectedDateRange(range);
														// handleDateChange(end, '', start, selectedQuarterData || selectedCycleDetails);
													}
												}}
												bottomChildComponent={getDueDaysText(
													objectiveDetail?.startDate,
													objectiveDetail.endDate,
													selectedCycleDetails
												)}
												allCycleDetails={allCycleDetails}
												selectedCycleDetails={selectedCycleDetails}
												setSelectedCycleDetails={setSelectedCycleDetails}
												// dueDaysDiff={dueDaysDiff}
												// setDueDaysDiff={setDueDaysDiff}
												isCurrentCycle={props.userSelectedCycle}
												isCycleVisible={true}
												isEndOfMonthVisible={false}
												numberOfCalendars={Enums.TWO}
												// selectionType={'range'}
												selectedDateRange={selectedDateRange}
												setSelectedDateRange={setSelectedDateRange}
												isCreationFlow={true}
												KRDateRangeDetail={OKRDateRangeDetail || null}
											/>
											{/* <DatePicker
												className='calender-popup'
												value={objectiveDetail.endDate}
												handleDateChange={(date: any) => {
													setModalPropsOKR({
														open: true,
														type: 'updateDateForOKR',
														message: hasContributor(objectiveDetail)
															? t('updateObjectiveAttribute')
															: t('updateObjAttrNoContributor'),
														module: 'user',
														data: { date: date, okr: objectiveDetail },
													});
												}}
												name={''}
												showLabel={false}
												minDate={new Date()}
												maxDate={new Date(currentCycleEndDate)}
												error={''}
												helperText={''}
												format='MMM d'
												open={openCalender}
												handleKeyboardButtonClick={handleKeyboardButtonClick}
												disableToolbar={false}
												durationCycleDetail={durationCycleDetail}
												handleEndOfMonthDateSelection={handleEndOfMonthDateSelection}
												module='goal'
												type={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? 'createGoal' : ''}
											/> */}
										</Box>
										{/* <Typography variant='h6'>{`${t('cycle')} ${
											selectedCycleDetails?.symbol ? selectedCycleDetails.symbol : props.userSelectedCycle?.symbol
										}, ${
											selectedCycleDetails?.year ? selectedCycleDetails.year : props.userSelectedCycle?.year
										}`}</Typography> */}
									</Box>
								</Box>
								<Box className='okr-col4'>
									<Box className='obj-progress'>
										<Box className='obj-progress-slider'>
											<Box className='progress-timestamp-info'>
												<Typography variant='h2'>
													0<sup>%</sup>
												</Typography>
											</Box>
											<Slider aria-label={t('MyGoalProgressSlider')} valueLabelDisplay='off' marks={false} disabled />
										</Box>
										{/* <Typography variant='h6'>{t('notSubmitted')}</Typography> */}
									</Box>
								</Box>
							</Fragment>
						) : (
							<></>
						)}
					</Box>
					{Boolean(objectiveDetail) && objectiveDetail?.objectiveName.length >= Enums.DEFAULT_TYPE_CHAR ? (
						<Typography component='div' className='okr-field-actions' ref={inputRefOkrSetting}>
							<OKRButton
								className={
									!checkMandatoryFieldforObjective() && finalSubmitted
										? 'settings-btn settings-btn-error'
										: 'settings-btn'
								}
								id='objective-setting-btn'
								icon={<SettingsIcon />}
								handleClick={(e) => {
									props.updateCalloutPanel('');
									setTimeout(() => {
										props.updateCalloutPanel('okrDrawer');
									}, 500);
									setCallOut(initialCallout);
									handleDrawerOpen(e);
								}}
								handleOver={(e) => {
									if (!getLocalStorageItem('showCallouts')) {
										props.updateCalloutPanel('');
										setCallOut(initialCallout);
									}
								}}
								text={t('attributesLabel')}
								title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('okrHoverText') : ''}
								tooltipClassName='tooltip-layout3'
							/>
							<OKRButton
								className='delete-btn'
								id='objective-delete-btn'
								icon={<DeleteIcon />}
								handleClick={(e) => handleCloseCreateGoal(e, 'delete')}
								handleOver={(e) => {}}
								text={t('delete')}
								title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('okrDeleteHoverText') : ''}
							/>
							{objectiveDetail?.isTyped && isAutoSaving ? (
								<Box className='spinner'>
									<Box className='bounce1'></Box>
									<Box className='bounce2'></Box>
									<Box className='bounce3'></Box>
								</Box>
							) : (
								objectiveDetail?.isTyped &&
								isAutoSaving === false && <Typography variant='body2'>{t('saved')}</Typography>
							)}
						</Typography>
					) : getLocalStorageItem('showCallouts') ? (
						//This is non clickable button to show callouts
						<Typography component='div' className='okr-field-actions' ref={inputRefOkrSetting}>
							<OKRButton
								className='settings-btn settings-btn-disable'
								icon={<SettingsIcon />}
								handleClick={(e) => {}}
								text={t('attributesLabel')}
								id='objective-setting-btn'
							/>
						</Typography>
					) : (
						<></>
					)}
				</Box>
				{Boolean(objectiveDetail?.myGoalsDetails) && objectiveDetail?.myGoalsDetails.length ? (
					objectiveDetail.myGoalsDetails.map((item: Number, index: Number) => (
						<Fragment key='index'>
							<CreateKeyResult
								placeHolderTxt={t('addKeyRes')} //getPlaceHolderText(index)
								currentCycleEndDate={currentCycleEndDate}
								objectiveEndDate={objectiveDetail.endDate || new Date()}
								objectiveStartDate={objectiveDetail.startDate || new Date()}
								objectiveDetail={objectiveDetail}
								durationCycleDetail={durationCycleDetail}
								defaultTypeChar={Enums.DEFAULT_TYPE_CHAR}
								t={t}
								addNewKR={addNewKR}
								updateFormDetail={updateFormDetail}
								resetKeyAttributeById={resetKeyAttributeById}
								dateCycle={selectedCycleDetails}
								keyData={item}
								deleteAddedKR={deleteAddedKR}
								formData={objectiveDetail.myGoalsDetails.find((krData: any) => krData.index === item)}
								goalFormError={goalFormError}
								setShowKr={setShowKr}
								autoSaveData={autoSaveData}
								isAutoSaving={isAutoSaving}
								setIsDataSaved={setIsDataSaved}
								setCallOut={setCallOut}
								anchorElCallouts={anchorElCallouts}
								setAnchorElCallouts={setAnchorElCallouts}
								initialCallout={initialCallout}
								showKrCallouts={showKrCallouts}
								showKrSettingCallouts={showKrSettingCallouts}
								setShowKrSettingCallouts={setShowKrSettingCallouts}
								setShowKrCallouts={setShowKrCallouts}
								index={index}
								calloutsData={calloutsData}
								finalSubmitted={finalSubmitted}
								krFocus={props.krFocus}
								editModee={editModee}
								setEditModee={setEditModee}
								{...props}
							/>
						</Fragment>
					))
				) : (
					<></>
				)}

				<Box display='flex' justifyContent='flex-end'>
					<Box
						className={`create-okr-actions ${
							Boolean(objectiveDetail) && objectiveDetail?.objectiveName.length >= Enums.DEFAULT_TYPE_CHAR
								? ''
								: 'hidden-actions'
						}`}
						display='flex'
						justifyContent='flex-end'
					>
						{/* <OKRButton
							className='button-blue-text create-add-another'
							icon={<AddIconSvg />}
							text={t('createAddAnother')}
							disabled={isDisabled() || isAutoSaving || formSubmit || objectiveDetail?.objectiveName?.trim() === ''}
							handleClick={(event: any) => validateOkrData(event, 1)} //handleSaveObjective(event, 1)
							title={
								!getLocalStorageItem('showCallouts') || anchorElCallouts === null
									? isDisabled()
										? t('okrButtonDisableTooltip')
										: t('okrSubmitAnotherButtonTooltip')
									: ''
							}
							tooltipClassName='tooltip-layout1 tooltip-layout-button'
							buttonType='createOkr'
						/> */}
						<OKRButton
							className='btn-link'
							id='save-as-draft'
							text={t('saveAsDraft')}
							handleClick={(e: any) => {
								handleSaveObjective(e, 3);
							}}
							disabled={isAutoSaving}
						/>
						<Typography>{t('orLabel')}</Typography>
						<OKRButton
							className='btn-primary'
							id='create-obj-btn'
							text={t('createObjective')}
							disabled={
								isDisabled() ||
								isDataSaved ||
								isAutoSaving ||
								formSubmit ||
								objectiveDetail?.objectiveName?.trim() === ''
							}
							handleClick={(event: any) => validateOkrData(event, 2)} //handleSaveObjective(event, 2)
							title={
								!getLocalStorageItem('showCallouts') || anchorElCallouts === null
									? isDisabled()
										? t('okrButtonDisableTooltip')
										: t('okrSubmitButtonTooltip')
									: ''
							}
							tooltipClassName='tooltip-layout3'
							buttonType='createOkr'
						/>
					</Box>
				</Box>
				{showCallouts && (
					<CallOutTooltip
						open={callOut.open}
						anchorEl={anchorElCallouts}
						setAnchorEl={setAnchorElCallouts}
						setCallOut={setCallOut}
						text={t(callOut.text)}
						placement={callOut.placement}
						classname={callOut.classname}
						handlePrev={() => prevCallout(callOut.currentCalloutIndex)}
						handleNext={() => nextCallout(callOut.currentCalloutIndex)}
						showNext={callOut.next}
						showPrevious={callOut.prev}
						showDone={callOut.done}
						initialCallout={initialCallout}
						tooltipIcon={callOut.tooltipIcon}
						activeOnboardingPanel={activeOnboardingPanel}
					/>
				)}
				{modalPropsOKR.open && (
					<AlertDialog
						module={modalPropsOKR.module ? modalPropsOKR.module : 'user'}
						message={modalPropsOKR.message}
						handleCloseModal={handleDateCloseModal}
						modalOpen={modalPropsOKR.open}
						isCancel={modalPropsOKR.module === 'validateForm' ? true : false}
					/>
				)}
				{modalPropsOKRCalendar.open && (
					<AlertDialog
						module={modalPropsOKRCalendar.module ? modalPropsOKRCalendar.module : 'user'}
						message={modalPropsOKRCalendar.message}
						handleCloseModal={handleDateCloseModalCalendar}
						modalOpen={modalPropsOKRCalendar.open}
						isCancel={modalPropsOKRCalendar.module === 'validateForm' ? true : false}
					/>
				)}
			</Box>
		</Collapse>
	);
};
