/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Tree from '../../ChartD3/index';
import { toTree, find } from '../../../config/utils';
import '../../../styles/pages/organization-chart/organization.scss';
import '../../../styles/pages/organization-chart/customTree.scss';
import { useTranslation } from 'react-i18next';
import RolesnPermisson from '../AddRolePermission/RolePermission';
import { AddTeam } from '../Organizations/AddTeam/AddTeam';

export default function Organizations(props) {
	const treeRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();
	const {
		getOrgList,
		tabSelected,
		fetchOrgFailed,
		showLoader,
		loaderStatus,
		listOrgResult,
		fetchOrgCompleted,
		openAddTeamDrawer,
		handleMenuClick,
	} = props;
	const [dataFound, setDataFound] = useState(false);
	const [orgData, setOrgData] = useState([]);
	const [organizationList, setOrganizationList] = useState([]);
	const [translate, setTranslate] = useState({ x: 0, y: 0 });
	const [searchClear, setSearchClear] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (tabSelected === 2) {
			showLoader(true);
			fetchOrganization();
			props.getLicenseDetails();
		}
	}, [tabSelected]);

	const [isSearch, setIsSearch] = useState(false);
	const filterSearchValue = (text) => {
		setSearchClear(false);
		if (text === '') {
			fetchOrganization();
			setIsSearch(false);
			setSearchClear(true);
		} else {
			const dataToSearch = listOrgResult ? listOrgResult : [];
			const result = find(dataToSearch, text);
			setIsSearch(true);
			if (result.length === 0) {
				setDataFound(true);
			}

			setOrgData(result);
		}
	};

	const fetchOrganization = (currentItem) => {
		setSearchClear(false);
		getOrgList(props.isOrgChart ? true : false)
			.then((response) => {
				if (response.status === 200) {
					showLoader(false);
					if (response.data && response.data.entityList) {
						setOrganizationList(response.data.entityList);
						const data = response.data.entityList;
						if (data.length === 0) {
							setDataFound(true);
						} else {
							let result = toTree(data, currentItem);
							fetchOrgCompleted(result);
							setOrgData(result);
							const dimensions = treeRef?.current?.getBoundingClientRect();
							setTranslate({
								x: dimensions && dimensions.width ? dimensions.width / 2 - 308 / 2 : 500,
								y: -370,
							});
						}
					} else {
						fetchOrgFailed('error');
					}
				} else {
					setDataFound(true);
					showLoader(false);
				}
			})
			.catch((error) => {
				setDataFound(true);
				showLoader(false);
				fetchOrgFailed(error);
			});
	};
	/* ==== Add Users ==== */
	return (
		<Fragment>
			<div
				style={{
					width: '100%',
					height: '100vh',
				}}
				ref={treeRef}
				className='organization-wrapper'
			>
				<Tree
					useCollapseData={true}
					data={[{ id: 1, name: 'parent', children: orgData }]}
					allowForeignObjects
					orientation={'vertical'}
					nodeSvgShape={{
						shape: 'none',
					}}
					nodeSize={{ x: 308, y: 500 }}
					separation={{ siblings: 1.1, nonSiblings: 1.1 }}
					styles={{
						links: {
							stroke: '#39A3FA',
							strokeWidth: 2,
						},
					}}
					enqueueSnackbar={enqueueSnackbar}
					deleteOrganization={props.deleteOrganization}
					filterSearchValue={filterSearchValue}
					chartType='organization'
					callApi={props.callApi}
					fetchOrganization={fetchOrganization}
					organizationList={organizationList}
					loaderStatus={loaderStatus}
					dataFound={dataFound}
					orgData={orgData}
					isSearch={isSearch}
					translate={translate}
					searchClear={searchClear}
					t={t}
					{...props}
				/>
			</div>

			{orgData && orgData.length > 0 && (
				<div className='scroll-drag-info'>
					<Typography variant='body2'>{t('scrollZoomLabel')}</Typography>
					<Typography variant='body2'>{t('dragToMoveView')}</Typography>
				</div>
			)}

			{openAddTeamDrawer && (
				<AddTeam
					{...props}
					fetchOrganization={fetchOrganization}
					handleMenuClick={handleMenuClick}
					openAddTeamDrawer={openAddTeamDrawer}
					refreshList={tabSelected === 2}
				/>
			)}
			{/* <RolesnPermisson {...props} /> */}
		</Fragment>
	);
}
