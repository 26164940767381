import React, { useEffect, useState } from 'react';
import Organizations from '../../Admin/Organizations';
interface OrganisationChartProps {}

const OrganisationChart: React.FC<OrganisationChartProps> = (props) => {
	const [loader, showLoader] = useState<boolean>(false);
	const [tabSelected, setTabSelected] = useState<number>(0);

	useEffect(() => {
		setTabSelected(2); // default as we are calling org component from admin
	}, [setTabSelected]);

	const handleMenuClick = () => {
		showLoader(true);
	};

	return (
		<Organizations
			{...props}
			tabSelected={tabSelected}
			handleMenuClick={handleMenuClick}
			showLoader={showLoader}
			isOrgChart={true}
		/>
	);
};

export default OrganisationChart;
