import {
	NOTIFICATION_BASE_URL,
	UserManagement,
	OkrManagement,
	FEEDBACK_BASE_URL,
	AlignmentMap,
	Tenant,
	GuideTour,
	Onboarding,
	AdminRoles,
	OkrUserManagement,
	OkrAuth,
	OkrTeamManagement,
	TeamManagement,
	OkrReports,
	OkrTask,
	okrNote,
	okrConversation,
	okrCheckins,
	OkrTrialEnv,
	SSODomain,
	SystemEmail,
} from './url';

export const NOTIFICATION = `${NOTIFICATION_BASE_URL}/Notifications`;
export const UNREAD_NOTIFICATION = `${NOTIFICATION_BASE_URL}/IsUnreadNotification`;
// export const NOTIFICATION_LISTING = `${NOTIFICATION_BASE_URL}/NotificationsAsync`;
// export const READ_ALERTS = `${NOTIFICATION_BASE_URL}/ReadAlerts`;
export const NOTIFICATION_ALL_LISTING = `${NOTIFICATION_BASE_URL}/AllNotifications`;

/** ------UserManagement------- */
export const AZURE_SIGN_IN = `${OkrUserManagement}/Identity/userByToken`;
export const ORG_CURRENT_CYCLE = `${TeamManagement}/cycles/currentcycle`;
export const SIGN_IN = `${UserManagement}/User/Login`;
export const LOGOUT = `${UserManagement}/User/LogOut`;
export const IS_FEDERATION_USER = `${UserManagement}/User/IsFederationUser`;
export const SSO_LOGIN = `${UserManagement}/PassportSso/SsoAuthentication`;
export const FORGOT_PASSWORD = `${UserManagement}/User/SendResetPasswordMail`;
export const RESET_PASSWORD = `${UserManagement}/User/ResetPassword`;
export const RESET_PASSWORD_MAIL = `${UserManagement}/User/ReSendResetPasswordMail`;
export const OKR_STATUS = `${UserManagement}/Master/GetAllOkrFilters`;
export const LIST_USER = `${OkrUserManagement}/users/getAll`;
export const ADD_USER = `${OkrUserManagement}/users`;
export const ADD_AD_USER = `${UserManagement}/User/AddAdUser`;
export const EDIT_USER = `${OkrUserManagement}/users`;
export const UPLOAD_BULK_USER = `${OkrUserManagement}/users/Bulk`;
export const UPLOAD_BULK_EMAILS = `${OkrUserManagement}/users/bulkEditEmails`;
export const SEARCH_USER = `${UserManagement}/User/Search`;
export const GET_USER_BY_ID = `${OkrUserManagement}/users`;
export const DELETE_USER = `${OkrUserManagement}/users`;
export const CHANGE_ROLE = `${OkrAuth}/employeeRole/assignRole`;
export const CHANGE_REPORTING = `${OkrUserManagement}/users/Reporting`;
export const GET_USER_DETAILS = `${OkrUserManagement}/users`;
export const IMPERSONATE_USER = `${OkrUserManagement}/Identity/ImpersonateUserByToken`;

export const GET_ALL_USERS = `${UserManagement}/User/GetAllusers`;
export const LIST_ROLES = `${UserManagement}/Permission/GetAllRolePermission`;
export const ADD_ROLES = `${UserManagement}/Role/AddRole`;
export const EDIT_ROLES = `${UserManagement}/Role/EditRole`;
export const ASSIGN_ROLES = `${UserManagement}/Role/AssignUsers`;
export const UPDATE_ROLES_STATUS = `${UserManagement}/Role/ActiveInactiveRole`;
export const UPDATE_ROLES_PERMISSION = `${UserManagement}/Permission/EditRolePermission`;
export const UPDATE_ROLENAME = `${AdminRoles}/roles/UpdateRoleName`;
export const UNLOCK_USER = `${OkrManagement}/MyGoals/BulkUnlockApprove`;
export const CHANGE_ORGANIZATION = `${UserManagement}/User/ChangeUserOrganisation`;
export const DOWNLOAD_USER_SAMPLE = `${UserManagement}/User/DownloadCSV`;
export const GET_DESIGNATION = `${UserManagement}/User/Designation`;
export const GET_PROFILE = `${UserManagement}/User/EmployeeProfile`;
export const UPDATE_PROFILE = `${UserManagement}/User/UpdateContactDetail`;
export const UPDATE_PROFILE_PIC = `${UserManagement}/User/UpdateProfileImage`;
export const DELETE_PROFILE_PIC = `${UserManagement}/User/DeleteImage`;
export const UPDATE_PROFILE_PASSWORD = `${UserManagement}/User/ChangePassword`;
export const VALIDATE_BULK_USER = `${OkrUserManagement}/users/bulkValidate`;
export const VALIDATE_BULK_EMAILS = `${OkrUserManagement}/users/bulkEditEmailsValidate`;

export const LICENSE_DETAILS = `${OkrUserManagement}/users/licence`;
export const LIST_ORG = `${TeamManagement}/organization`;
export const ADD_ORG = `${UserManagement}/Organisation/AddOrganisation`;
export const ADD_PARENT = `${UserManagement}/Organisation/AddParentToParent`;
export const GET_ORGANIZATION = `${UserManagement}/Organisation/GetOrganisationById`;
export const UPDATE_ORGANIZATION = `${UserManagement}/Organisation/EditOrganisation`;
export const ADD_CHILD = `${UserManagement}/Organisation/AddChildOrganisation`;
export const EDIT_CHILD = `${UserManagement}/Organisation/EditChildOrganisation`;

export const DELETE_ORGANIZATION = `${UserManagement}/Organisation/DeleteOrganisation`;
export const UPLOAD_LOGO = `${TeamManagement}/teams/UploadLogo`;
export const OKR_MASTER_DATA = `${UserManagement}/Master/OkrMasterData`;

/** ------OkrManagement------- */
export const OKR_LISTING = `${OkrManagement}/MyGoals/MyGoal`;
export const EMPLOYEE_VIEW = `${OkrManagement}/People/EmployeeView`;
export const PEOPLE_ALIGNMENT_VIEW = `${OkrManagement}/People/PeopleAlignmentView`;
export const ADD_GOALS = `${OkrManagement}/MyGoals/Goals`;
export const ALIGN_GOALS = `${OkrManagement}/MyGoals/Align`;
export const CONTRIBUTORS = `${OkrManagement}/MyGoals/Contributors`;
export const UPDATE_KR_PROGRESS = `${OkrManagement}/MyGoals/keyScore`;
export const UNLOCK_REQUEST = `${OkrManagement}/MyGoals/AskUnLock`;
export const ALIGN_STATUS = `${OkrManagement}/MyGoals/AlignStatus`;
export const DOWNLOAD_GOAL_PDF = `${OkrManagement}/MyGoals/DownloadPdf`;
export const QUARTER_REPORT = `${OkrManagement}/Report/QuarterReport`;
export const STATUS_REPORT = `${OkrManagement}/Report/StatusReport`;
export const PROGRESS_REPORT = `${OkrManagement}/Report/ProgressReport`;
export const UPDATE_OKR_SEQUENCE = `${OkrManagement}/MyGoals/UpdateSequence`;
export const KR_CONTRIBUTORS = `${OkrManagement}/MyGoals/KrContributors`;
export const UPDATE_GOAL_KR_NAME = `${OkrManagement}/MyGoals/GoalDescription`;
export const UPDATE_GOAL_ATTRIBUTES = `${OkrManagement}/MyGoals/GoalObjective`;
export const UPDATE_KR_ATTRIBUTES = `${OkrManagement}/MyGoals/GoalKey`;
export const ACCEPT_KR = `${OkrManagement}/MyGoals/KrContributor`;
export const UPDATE_KR_PROGRESS_VALUE = `${OkrManagement}/MyGoals/UpdateKrValue`;
export const BECOME_CONTRIBUTOR = `${OkrManagement}/MyGoals/BecomeContributor`;
export const GET_KR_DETAIL = `${OkrManagement}/MyGoals/GoalKeyDetail`;
export const GET_OKR_DETAIL = `${OkrManagement}/MyGoals/GoalObjectiveDetail`;
export const RESET_OBJECTIVE = `${OkrManagement}/MyGoals/ResetOkr`;
export const GET_LINK_OWNER_DATA = `${UserManagement}/Organisation/TeamDetailsById`;

/** ---------- dashboard --------------- */
export const DASHBOARD_LIST = `${OkrManagement}/Dashboard/AllOkrDashboard`;
export const MY_GOAL_DETAIL = `${OkrManagement}/Dashboard/GetGoalDetail`;
export const MY_TEAM_OKR_VIEW = `${OkrManagement}/Dashboard/TeamOkrView`;
export const NUDGE_TEAM = `${OkrManagement}/Dashboard/NudgeTeam`;
export const NUDGE_PERSON = `${OkrManagement}/Dashboard/NudgeDirectReporting`;
export const MY_TEAM_OKR_DETAILS_VIEW = `${OkrManagement}/Dashboard/TeamOkrGoalDetailsById`;
export const DELTA_SCORE = `${OkrManagement}/Dashboard/DeltaScore`;
export const RECENT_CONTRIBUTION_LIST = `${OkrManagement}/Dashboard/RecentContribution`;
// Dashboard Progress summary
export const DASHBOARD_PROGRESS = `${OkrManagement}/Dashboard/AllOkrProgressCard`;
export const TEAMSOKR_PROGRESS = `${OkrManagement}/Dashboard/TeamOkrProgressCard`;
export const TEAM_VIEW_LIST = `${OkrManagement}/Dashboard/TeamView`;

/** Import api */
export const OKR_IMPORT = `${OkrManagement}/Import`;
export const OKR_CATEGORY_IMPORT = `${OkrManagement}/Import/ImportCategory`;
/** ---------- alignment --------------- */
export const ALIGNMENT_LIST = `${AlignmentMap}/Alignment/OkrView`;
export const ALIGNMENT_DETAIL_LIST = `${OkrManagement}/Alignment/AssociateObjectiveAlignment`;
export const ALIGNMENT_PARENT_DETAIL = `${OkrManagement}/Alignment/GetAllParentAlignment`;
export const ALIGNMENT_GRAPH_DETAIL = `${OkrManagement}/Alignment/AlignmentGoalMapGraph`;
export const PEOPLE_MAP_LIST = `${AlignmentMap}/People/PeopleView`;
export const ALIGNMENT_TEAM_LIST = `${AlignmentMap}/Alignment/TeamsOkr`;
export const ALIGNMENT_DIRECT_REPORTS_LIST = `${OkrManagement}/Dashboard/DirectReports`;
export const UPDATE_PROGRESS = `${OkrManagement}/MyGoals/UpdateKrValueAlignmentMap`;
export const UPDATE_DUE_DATE = `${OkrManagement}/MyGoals/UpdateDueDate`;

/** ------Reports------- */
export const AVG_OKR_REPORT = `${OkrManagement}/Report/AvgOkrReport`;
export const WEEKLY_KR_REPORT = `${OkrManagement}/Report/WeeklyKrReports`;
export const MOST_FEEDBACK_REPORT = `${FEEDBACK_BASE_URL}/Feedback/MostFeedbackReport`;
export const PERSONAL_FEEDBACK_REPORT = `${FEEDBACK_BASE_URL}/export`;
export const WEEKLY_KR_GROWTH_REPORT = `${OkrManagement}/Report/KrUpdateGrowthCycle`;
export const LEAST_RISK_OKR_REPORT = `${OkrManagement}/Report/MostLeastOkrRisk`;
export const LEAST_RISK_KR_REPORT = `${OkrManagement}/Report/MostLeastOkrKeyResultRisk`;
export const GET_TEAMS_PERFORMANCE = `${OkrManagement}/Report/TeamPerformance`;
export const GET_TEAMS_PROGRESS = `${OkrManagement}/Report/TeamPerformance`;
export const GET_LEADING_TEAMS = `${TeamManagement}/teams/LeadingTeam`;
export const GET_MY_LEADING_TEAMS = `${OkrReports}/MyOkrProgressReport/LeadingTeam`;

/** Global Search */
export const GLOBAL_SEARCH = `${UserManagement}/User/Search`;
export const GLOBAL_SEARCH_WITH_TEAM = `${UserManagement}/User/GlobalSearch`;
export const SEARCH_IN_TEAM = `${UserManagement}/User/SearchTeamEmployee`;
export const SEARCH_IN_ROLE = `${OkrUserManagement}/users/search`;

/** Feedback */
export const GIVE_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Provide`;
export const ASK_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Ask`;
export const FETCH_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Feedback`;
export const FEEDBACK_1To1 = `${FEEDBACK_BASE_URL}/Feedback/OneToOne/Request`;
export const COMMENT_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/Comment`;
export const REDIRECT_FEEDBACK = `${FEEDBACK_BASE_URL}/Feedback/MyGoalFeedbackResponse`;

/** common */
export const CYCLE_DETAILS = `${TeamManagement}/cycles/TeamCycleDetails`;
export const IMPORT_CYCLE_DETAILS = `${UserManagement}/Organisation/GetImportOkrCycle`;
export const GET_OWNER_TEAMS = `${OkrManagement}/Dashboard/TeamDetailsById`;

/** Personal Feedback */
export const PERSONAL_FEEDBACK_OneOnOne = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PersonalFeedbackOneOnOne`;
export const PERSONAL_FEEDBACK_ASK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/AskPersonalFeedback`;
export const PERSONAL_FEEDBACK_ASK_DETAIL = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/AskedPersonalFeedbackDetails`;
export const PERSONAL_FEEDBACK_FETCH_GIVING_FEEDBACK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/ProvidePersonalFeedback`;
export const PERSONAL_FEEDBACK_UPDATE_GIVING_FEEDBACK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/Provide`;
export const PERSONAL_FEEDBACK_CRITERIA_MASTER = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/CriteriaMaster`;
export const PERSONAL_FEEDBACK_REQUEST_AGAIN = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/RequestFeedbackAgain`;
export const PERSONAL_FEEDBACK_CANCEL_REQUEST = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/CancelFeedback`;
export const PERSONAL_FEEDBACK_VIEW = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PersonalFeedback`;
export const PERSONAL_FEEDBACK_DETAIL = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PersonalFeedbackResponse`;
export const PERSONAL_FEEDBACK_ACCEPT_REJECT = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/ApproveRejectRequest`;
export const GET_LINK_OBJECTIVE_DATA = `${OkrManagement}/MyGoals/LinkObjectiveDetail`;
export const OWNER_CHANGE_DATA = `${OkrManagement}/MyGoals/ChangeOwner`;
export const VIRTUAL_ALIGNMENT = `${OkrManagement}/Dashboard/VirtualAlignment`;
export const PEOPLEVIEW_PERSONAL_FEEDBACK = `${FEEDBACK_BASE_URL}/PersonalizeFeedback/PeopleViewPersonalFeedback`;

/** Tenant */
export const TENANT_DATA = `${Tenant}/Tenant`;
export const TENANT_USER = `${Tenant}/SupportUser`;
export const TENANT_MODULES = `${Tenant}/Module`;

/**Guided Tour */
export const GUIDED_TOUR = `${GuideTour}/GuidedTour`;
export const GUIDED_TOUR_ANY_ACTIVITY = `${GuideTour}/GuidedTourAnyActivity`;

export const FETCH_ONBOARDING = `${Onboarding}/OnBoarding`;
export const UPDATE_ONBOARDING = `${Onboarding}/OnBoardingActionsCount`;
export const ONBOARDING_COUNT = `${Onboarding}/OnBoardingControlDetailById`;

// Archive
export const TENANT_LIST = `${Tenant}/TenantsDetails`;
export const DELETE_INSTANCE = `${Tenant}/DeleteInstance`;
export const UPDATE_INSTANCE = `${Tenant}/UpdateLicense`;
export const CONVERT_FEDERATED = `${SSODomain}/SSODomain`;
export const MOVE_SSO_TO_DB = `${Tenant}/SSOToDB`;
export const UPDATE_FORCE_LOGOUT = `${Tenant}/ForcelogOut`;
export const CLIENT_ENGAGEMENT_REPORT = `${Tenant}/ClientEngagementReport`;

export const ARCHIVE_LIST = `${OkrManagement}/Dashboard/Archive`;
export const OBJECTIVE_DETAILS = `${OkrManagement}/MyGoals/GetObjectiveDetails`;

//Add role and permission
export const ADD_ROLES_PERMISSION = `${AdminRoles}/roles`;
export const ADMIN_ROLES_AND_PERMISSION = `${AdminRoles}/employeeRolePermissions`;
export const GET_PERMISSIONS = `${AdminRoles}/permissions`;
export const GET_ROLES = `${AdminRoles}/roles/rolepermissions`;
export const EMPLOYEE_PERMISSION = `${AdminRoles}/employeeRole/permissions`;
export const UPDATE_ROLE = `${AdminRoles}/roles`;
export const GET_ROLE = `${AdminRoles}/roles/roleDetails`;
export const UPDATE_PERMISSION = `${AdminRoles}/permissions/assignRolePermission`;
export const DELETE_ROLE = `${AdminRoles}/roles`;
export const CLONE_ROLE = `${AdminRoles}/roles/clone`;
export const ASSIGN_USER_TO_ROLE = `${AdminRoles}/employeeRole/assignRole`;
export const GET_EMPLOYEE_PERMISSION = `${AdminRoles}/employeePermissions`;
export const SAVE_EMPLOYEE_PERMISSIONS = `${AdminRoles}/employeePermissions`;
export const GET_ROLE_PERMISSION = `${AdminRoles}/roles/permissions`;

//Add team
export const GET_PARENT_TEAM = `${OkrTeamManagement}/teams`;
export const ADD_TEAM = `${OkrTeamManagement}/teams`;
export const ASSIGNED_EMPLOYEE_TEAM = `${OkrTeamManagement}/Employeeteam`;
export const ORG_CHART_VIEW = `${OkrTeamManagement}/organization/OrgChartView`;

// Cycle data
export const GET_CYCLE_DATA = `${TeamManagement}/cycles`;
export const GET_PARENT_ORGANSATION = `${TeamManagement}/organization/parent`;
export const UPDATE_PARENT_ORGANSATION = `${TeamManagement}/organization`;
export const DELETE_TEAM = `${TeamManagement}/teams`;
export const GET_USER_TEAMS = `${TeamManagement}/teams/teamdetails`;

export const ORG_REPORTS = `${OkrReports}/OrganizationReport`;
export const MY_PROGRESS_REPORTS = `${OkrReports}/MyOkrProgressReport`;
export const OVERALL_PERFORMANCE = `${OkrReports}/EngagementReport/MyEngagementReport`;
export const MY_TEAM_PROGRESS_REPORTS = `${OkrReports}/TeamOkrProgressReport`;
export const OVERALL_TEAM_PERFORMANCE = `${OkrReports}/EngagementReport/TeamEngagementReport`;
export const LEADING_TEAM = `${OkrReports}/MyOkrProgressReport/LeadingTeam`;

//CFR
export const OBJECTIVE_TASK = `${OkrTask}/task`;
export const DELETE_TASK = `${OkrTask}/task`;
export const UPDATE_TASK = `${OkrTask}/task`;
export const TASK_COMPLETED = `${OkrTask}/task/taskCompleted`;

export const GET_NOTES = `${okrNote}/notes`;
export const UPLOAD_NOTES_FILES = `${okrNote}/notes/UploadNotes`;
export const POST_PROGRESS_CONVERSATION = `${okrConversation}/conversation`;
export const GET_NOTEBY_ID = `${okrNote}/notes/IsEmployeeTag`;
export const GET_CONVERSATION_BY_ID = `${okrConversation}/conversation/IsEmployeeTag`;

//Conversation
export const GET_CONVERSATION = `${okrConversation}/conversation`;
export const UPLOAD_CONVERSATION_FILES = `${okrConversation}/conversation/UploadConversation`;
export const LIKE_CONVERSATION = `${okrConversation}/conversation/like`;

//Checkins
export const CHECKINSTATUS = `${okrCheckins}/checkIn/IsCheckInSubmitted`;
export const CHECKINDATES = `${okrCheckins}/checkIn/CheckInWeeklyDates`;
export const CHECKINDATA = `${okrCheckins}/checkIn`;
export const DIRECT_REPORTEES = `${okrCheckins}/checkIn/directreports`;

//confidence
export const UPDATE_CONFIDENCE = `${OkrManagement}/Confidence`;
export const GET_WEEKLY_TREND_DETAILS = `${OkrManagement}/Report/AuditWeeklyTrend`;
export const GET_CONFIDENCE_ALERT = `${OkrManagement}/Confidence/Alert`;

//Free Trial
export const FREE_TRIAL = `${OkrTrialEnv}/bookdemo`;
export const FREE_TRIAL_EMAILID = `${OkrTrialEnv}/check-emailid`;
export const FREE_TRIAL_DOMAIN = `${OkrTrialEnv}/check-domain`;
export const FREE_TRIAL_PASSWORD = `${OkrTrialEnv}/check-password`;
export const TENANT_ACTION_OKR = `${OkrTrialEnv}/tenantDetails`;
export const USER_VALIDATION = `${OkrTrialEnv}/UserValidation`;

/**
 * OKR Update - What's New related action types
 */
export const GET_VERSION_DETAILS = `${OkrTrialEnv}/Version/VersionContent`;
export const GET_VERSION_DETAILS_FOR_USER = `${OkrTrialEnv}/Version/GetAll`;
export const UPDATE_VERSION_DETAILS = `${Tenant}/Version`;
export const PUBLISH_VERSION_DETAILS = `${Tenant}/Version/Publish`;
export const NOTIFY_VERSION_DETAILS = `${Tenant}/Version/Notify`;
export const SEND_EMAIL_DETAILS = `${Tenant}/Version/WhatNewPublish`;
export const CREATE_VERSION_FEATURE = `${Tenant}/Feature`;
export const UPLOAD_FILE = `${Tenant}/UploadFile`;

/**
 *  Mail related action types
 */
export const GET_MAIL_LIST = `${SystemEmail}/GetSystemEmails`;
export const CREATE_Mail = `${SystemEmail}/AddSystemEmail`;

export const DEFAULT_HOME_SCREEN = `${TENANT_MODULES}/DefaultHomeScreen`;
