export enum MapTypes {
	Relax = 0,
	RelaxTeam = 1,
	CompactViewOkr = 2,
	CompactViewTeam = 3,
	UltraCompactViewOkr = 4,
	UltraCompactViewTeam = 5,
}
export enum MapViews {
	Okr = 0,
	Team = 1,
}
export class DiagramHelperUtil {
	constructor() {}
	static MapType: MapTypes;
	static SetMapType(tabFilter: string, mapView: MapViews) {
		switch (tabFilter) {
			case 'relaxed':
				if (mapView === MapViews.Team) {
					this.MapType = MapTypes.RelaxTeam;
				} else {
					this.MapType = MapTypes.Relax;
				}
				break;
			case 'compact':
				if (mapView === MapViews.Team) {
					this.MapType = MapTypes.CompactViewTeam;
				} else {
					this.MapType = MapTypes.CompactViewOkr;
				}
				break;
			case 'ultracompact':
				if (mapView === MapViews.Team) {
					this.MapType = MapTypes.UltraCompactViewTeam;
				} else {
					this.MapType = MapTypes.UltraCompactViewOkr;
				}
				break;
		}
	}
	static nodeSelectedStyle = {
		strokeColor: 'rgba(57,158,247,255)',
		fill: 'white',
		strokeWidth: 3,
	};
	static nodeDefaultStyle = {
		strokeColor: '#C8C8C8',
		fill: 'white',
		strokeWidth: 1,
	};
	static parentConnectorDefaultStyle = {
		strokeColor: '#5B708B',
		strokeWidth: 3,
		opacity: 1,
		decorator: {
			fill: '#5B708B',
			strokeColor: '#5B708B',
			opacity: 1,
		},
	};
	static parentConnectorHiddenStyle = {
		strokeColor: '#C8C8C8',
		strokeWidth: 3,
		opacity: 0.5,
		decorator: {
			fill: '#C8C8C8',
			strokeColor: '#C8C8C8',
			opacity: 0.5,
		},
	};
	static distInfoNodeDefaultStyle = {
		color: '#C8C8C8',
	};
	static distInfoLineDefaultStyle = {
		strokeColor: 'rgba(161, 171, 185, 0.5)',
		strokeWidth: 1,
	};
	static connectorDefaultStyle = {
		strokeColor: '#C8C8C8',
		strokeWidth: 1,
		opacity: 0.5,
	};
	static connectorSelectedStyle = {
		strokeColor: 'rgba(57,158,247,255)',
		strokeWidth: 2,
		opacity: 1,
	};
	static distInfoLineGradientDefaultStyle = {
		strokeColor: 'linear-gradient(90deg, rgba(161, 171, 185, 0) 0%, rgba(161, 171, 185, 0.5) 100%)',
		strokeWidth: 1,
	};
	static isNullOrUndefined(val: any) {
		return val === null || val === undefined;
	}
	static IsHelperNode(obj: any): boolean {
		let helperList = ['_contributorIcon', '_sourceIcon', '_link_helper', 'krResItem_'];

		// if (this.IsTeamViewMap()) {
		// 	helperList.push('_okrItem');
		// }

		return helperList.filter((item: string) => obj.id.indexOf(item) >= 0).length > 0;
	}
	static IsRelaxViewMap() {
		let mapType = this.MapType;
		return (
			mapType === MapTypes.Relax || mapType === MapTypes.CompactViewOkr || mapType === MapTypes.UltraCompactViewOkr
		);
	}
	static IsTeamViewMap() {
		let mapType = this.MapType;
		return (
			mapType === MapTypes.RelaxTeam ||
			mapType === MapTypes.CompactViewTeam ||
			mapType === MapTypes.UltraCompactViewTeam
		);
	}
	static GetNode(nodeId: string, diagramIns: any) {
		let nodeModel;
		if (diagramIns) {
			nodeModel = diagramIns.nodes.find((n: any) => n.id === nodeId);
		}
		return nodeModel;
	}
	static GetConnector(connectorId: string, diagramIns: any) {
		let connModel = diagramIns.connectors.find((c: any) => c.id === connectorId);
		return connModel;
	}
	static datetime: any;

	static TimerStart(message: any) {
		this.datetime = Date.now();
		// console.log(message);
	}

	static TimerEnd(message: any) {
		var end: any;
		end = Date.now();
		var timeSpent = (end - this.datetime) / 1000 + 'secs';
		// console.log(message + '   ' + timeSpent);
	}

	static RefreshDiagram(diagramins: any) {
		if (diagramins && diagramins.dataBind) {
			//DiagramHelperUtil.TimerStart('databind start - :' + obj.id);
			diagramins.dataBind();
			//DiagramHelperUtil.TimerEnd('databind end - :' + obj.id);
		}
	}
	static RefreshUI(obj: any) {
		if (obj && obj.dataBind) {
			//DiagramHelperUtil.TimerStart('databind start - :' + obj.id);
			//obj.dataBind();
			//DiagramHelperUtil.TimerEnd('databind end - :' + obj.id);
		}
	}

	static connectorCollections: any[] = [];

	static SetVisibility(n: any, visibility: boolean) {
		if (n) {
			n.visible = visibility;
			if (n.data) n.data.visible = visibility;
			//this.RefreshUI(n);
			// setTimeout(() => {
			// 	let el = document.getElementById(n.id + "_content_html_element");
			// 	if (el) {
			// 		let visibleAttr = visibility ? 'visible' : 'hidden';
			// 		el.style.visibility = visibleAttr;
			// 	}
			// }, 0);
		}
	}
}
