import { Box, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { InfoIcon } from '../../../config/svg/CommonSvg';
import OKRFlag from '../../../images/okrFlag.svg';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import { getOkrProgressDetails } from '../../../config/utils';
import { ListTooltip } from '../../OkrAction/ListTooltip';
import { getLocalStorageItem } from '../../../services/StorageService';
import { formatDate, getDifferenceBetweenDates, getTeamFilterQueryString } from '../../../config/utils';
// import { GoalAttributes } from './GoalAttributes';
import { GoalAttributes } from '../../Goals/CreateObjective/GoalAttributes';
import { Enums } from '../../../config/enums';
import moment from 'moment';
import { Collapse } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { keyDetailObject, goalDetailObject } from '../../Common/OkrUtil';
const year = getLocalStorageItem('currentYear') || null;
const selectedYear = getLocalStorageItem('year') || year;
const cycleId = getLocalStorageItem('cycleId') || 0;

export interface UltraCompactObjectiveCardProps {}

const UltraCompactObjectiveCard: React.FC<any> = (props: any) => {
	const {
		t,
		currentUser,
		data,
		handleDeleteOkr,
		setModalProps,
		goalDetail,
		fetchOkr,
		listOkrResult,
		currentCycleEndDate,
		setGoalDetail,
		isLocked,
		teamOkrFilterData,
	} = props;
	const okr = data.data;
	const { color } = getOkrProgressDetails({
		value: okr.score || okr.krScore,
		dueDate: okr.dueDate || okr.krDueDate,
	});

	const { enqueueSnackbar } = useSnackbar();
	const [objectiveDetail, setObjectiveDetail] = useState<any>({
		...goalDetailObject,
		myGoalsDetails: [{ ...keyDetailObject }],
	});
	const [openObjectAttribute, setOpenObjectAttribute] = useState(false);
	const defaultGaolDateError = { error: '', helperText: '', type: '' };
	const [isSaveDisabled, setIsSaveDisabled] = useState<Boolean>(true);
	const [goalsAttribute, setGoalsAttribute] = useState<any>({});
	const [goalAttrDateError, setGaolAttrDateError] = useState<any>(defaultGaolDateError);
	const [dataUpdated, setdataUpdated] = useState<any>({});
	const [krLoader, setKrLoader] = useState<Boolean>(false);
	const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');

	const validGoalAttributeAlignmentForm = (formAlignData: any) => {
		if (!formAlignData.objectiveName) {
			setGaolAttrDateError({
				helperText: t('objectiveNameError'),
				type: 'objectiveName',
				error: t('objectiveNameError'),
			});
			return false;
		} else if (formAlignData.objectiveName?.trim() === '') {
			setGaolAttrDateError({
				type: 'objectiveName',
				helperText: t('objectiveNameError'),
				error: t('objectiveNameError'),
			});
			return false;
		}
		return true;
	};

	const isTeamArraySame = (goal: any, objective: any) => {
		//This check is here for only 1 team as of now;
		let isSame = true;
		if (goal.teamOkrRequests.length !== objective.teamOkrRequests.length) {
			isSame = false;
		} else if (goal.teamOkrRequests.length === 0 && objective.teamOkrRequests.length === 0) {
			isSame = true;
		} else if (!goal.teamOkrRequests[0].id && objective.teamOkrRequests[0].id) {
			isSame = false;
		} else if (goal.teamOkrRequests[0].id !== objective.teamOkrRequests[0].id) {
			isSame = false;
		}
		return isSame;
	};

	const isGoalAttributeUpdated = (isPopup: boolean = false) => {
		let goalPrev = goalsAttribute;
		let goalNew = objectiveDetail;
		if (isPopup === true) {
			goalNew = dataUpdated.okr;
			if (goalNew.goalTypeId === 1 && goalNew.teamOkrRequests && goalNew.teamOkrRequests.length > 0) {
				goalNew.teamOkrRequests[0].id = goalNew.teamOkrRequests[0].teamId;
			}
		}
		let isUpdated =
			(goalPrev.objectiveDescription && goalNew.objectiveDescription !== goalPrev.objectiveDescription) ||
			goalNew.objectiveName !== goalPrev.objectiveName ||
			goalNew.isPrivate !== goalPrev?.isPrivate ||
			(goalPrev?.owner && goalNew.owner !== goalPrev?.owner);

		if (!isUpdated) {
			if (isPopup === true) {
				isUpdated =
					goalNew?.linkedObjective?.objectiveId !== goalPrev?.linkedObjective?.objectiveId ||
					(goalPrev.startDate && formatDate(goalPrev.startDate) !== formatDate(goalNew.startDate)) ||
					(goalPrev.endDate && formatDate(goalPrev.endDate) !== formatDate(goalNew.endDate));
			} else {
				isUpdated =
					(goalPrev?.linkedObjective && goalNew.linkedObjectiveId !== goalPrev?.linkedObjectiveId) ||
					(goalPrev.startDate && goalNew.startDate !== goalPrev.startDate) ||
					(goalPrev.endDate && goalNew.endDate !== goalPrev.endDate);
			}
		}
		if (!isUpdated && goalPrev.goalTypeId && goalPrev.goalTypeId !== goalNew.goalTypeId) {
			isUpdated =
				goalPrev.goalTypeId !== 1 ||
				(goalPrev.teamOkrRequests !== undefined &&
					goalPrev.teamOkrRequests !== null &&
					goalPrev.teamOkrRequests.length !== 0 &&
					!isTeamArraySame(goalPrev, goalNew));
		} else if (
			!isUpdated &&
			goalPrev.goalTypeId === 1 &&
			goalNew.goalTypeId === 1 &&
			goalPrev.teamOkrRequests !== undefined &&
			goalNew.teamOkrRequests !== undefined
		) {
			isUpdated = !isTeamArraySame(goalPrev, goalNew);
		}
		if (
			(isUpdated || isUpdated === undefined) &&
			!isPopup &&
			(goalPrev.goalTypeId === 2 || (goalPrev.goalTypeId === undefined && goalNew.goalTypeId === 2))
		) {
			isUpdated = true;
		}
		return !isUpdated;
	};

	const handleCloseObjectiveAttribute = async (event: any, type: String) => {
		// setShowKr(false);
		setGaolAttrDateError(defaultGaolDateError);
		if (type === 'save') {
			const datesDifferenceInDays = moment(
				new Date(goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate || new Date())
			).isAfter(
				new Date(goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate || new Date()),
				'days'
			);
			if (Boolean(datesDifferenceInDays)) {
				setGaolAttrDateError({
					error: t('startDateDueDateError'),
					helperText: t('startDateDueDateError'),
					type: 'dueDate',
				});
			} else {
				if (goalDetail && goalDetail.showAttributeDrawer && goalDetail.actionType === 'editGoal') {
					if (validGoalAttributeAlignmentForm(goalsAttribute)) {
						setKrLoader(true);
						const data = { ...goalsAttribute };
						if (data.linkedObjective && data.linkedObjective.objectiveId && !data.linkedObjectiveId) {
							data.linkedObjectiveId = data.linkedObjective.objectiveId;
						}
						const response = await props.updateGoalAttributes(data);
						if (response && response.data && response.data.status === 200) {
							setKrLoader(false);
							// setIsSaveDisabled(true);
							setGaolAttrDateError(defaultGaolDateError);
							// setOpenDrawer(false);
							setOpenObjectAttribute(false);
							setGoalsAttribute({});
							// setGoalDetail({});
							setdataUpdated({});
							fetchOkr();
							if (props.tabValue === 0) {
								props.getAlignmentData();
							} else if (props.tabValue === 1) {
								const teamFilter = getTeamFilterQueryString(teamOkrFilterData);
								const data = `empId=${
									userDetail && userDetail.employeeId
								}&cycle=${cycleId}&year=${selectedYear}${teamFilter}`;
								props.fetchTeamsAlignmentMap(data);
							}
						} else if (response && response.data && response.data.status === 400) {
							setKrLoader(false);
							const responseAPI = response.data.messageList;
							const keys = Object.keys(responseAPI);
							const messages = keys.map((item) => responseAPI[item]);
							enqueueSnackbar(`${messages} `, {
								variant: 'error',
								autoHideDuration: 5000,
								TransitionComponent: Collapse,
							});
						}
					}
				} else {
					if (validGoalAttributeAlignmentForm(goalsAttribute)) {
						if (goalsAttribute.teamOkrRequests?.length > 0) {
							objectiveDetail.goalTypeId = 1;
						}
						setObjectiveDetail({
							...objectiveDetail,
							objectiveName: goalsAttribute.objectiveName
								? goalsAttribute.objectiveName
								: objectiveDetail.objectiveName,
							isPrivate:
								goalsAttribute.isPrivate === false
									? goalsAttribute.isPrivate
									: objectiveDetail.isPrivate
									? objectiveDetail.isPrivate
									: goalsAttribute.isPrivate,
							objectiveDescription: goalsAttribute.objectiveDescription
								? goalsAttribute.objectiveDescription
								: objectiveDetail.objectiveDescription,
							startDate: goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate,
							endDate: goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate,
							myGoalsDetails: goalsAttribute.myGoalsDetails
								? goalsAttribute.myGoalsDetails
								: objectiveDetail.myGoalsDetails,
							teamOkrRequests: goalsAttribute.teamOkrRequests
								? goalsAttribute.teamOkrRequests
								: objectiveDetail.teamOkrRequests,
						});
						// setIsSaveDisabled(true);
						setGaolAttrDateError(defaultGaolDateError);
						setOpenObjectAttribute(false);
						setGoalsAttribute({});
					}
				}
			}
		} else if (type === 'close') {
			// setShowKr(false);
			// setGoalDetail({});
			// if (!isGoalAttributeUpdated()) {
			// 	setModalProps({
			// 		open: true,
			// 		type: 'GoalAttr',
			// 		message: t('unSavedItemAlert'),
			// 		module: 'user',
			// 		details: { id: '' },
			// 	});
			// } else {
			setGoalsAttribute({});
			setOpenObjectAttribute(false);
			// }
		}
	};

	const openSettingsDrawer = async (okrObj: any) => {
		Promise.allSettled([props.getOkrDetail(okrObj.objectiveId)]).then((response: any) => {
			const okrData = response[0].value.data.entity;
			let obj = {
				kr: okrData.myGoalsDetails,
				okr: okrData,
				actionType: 'editGoal',
				goalDetail: {},
				showAttributeDrawer: true,
			};
			setGoalsAttribute(okrData);
			setOpenObjectAttribute(true);
			setdataUpdated(obj);
		});
	};

	return (
		<React.Fragment>
			<Box className='alignment-card ultra-compact-view'>
				<Box className='objective-section'>
					<Box
						className={`cardHeader ${
							okr.okrViewKeyResults &&
							okr.okrViewKeyResults.find((item: any) => item.krStatusId === Enums.KR_STATUS_PENDING)
								? 'pending-header'
								: ''
						}`}
					>
						<Box className='cardHeader-col1'>
							<Box className='okr-nature-type-tag'>
								{okr?.goalNatureId === 1 ? (
									<Typography className='operational'>{t('aspirationalLevel')}</Typography>
								) : (
									<Typography className='aspirational'>{t('operationalLevel')}</Typography>
								)}
							</Box>
							<Box display='flex'>
								<Box className='cardFlag'>
									<img src={OKRFlag} alt='Okr flag' />
								</Box>
								<Box className='okr-after-edit'>
									<ListTooltip
										objName={okr?.name || ''}
										description={okr?.objectiveDescription || ''}
										alignmentMapDescription={true}
										characterCount={20}
										titleClassName={'cardTitle'}
										diagramInstance={props.diagramInstance}
									/>
								</Box>
							</Box>
						</Box>
						<Box className='cardHeader-col2'>
							{okr.okrViewKeyResults &&
								okr.okrViewKeyResults.find((item: any) => item.krStatusId === Enums.KR_STATUS_PENDING) && (
									<Box
										className={`action-message-wrap action-message-wrap-pending ${
											okr.okrViewKeyResults &&
											okr.okrViewKeyResults.find((item: any) => item.krStatusId === 0 || item.krStatusId === 2)
												? 'with-percentage'
												: 'no-percentage'
										}`}
									>
										<Box className='action-message'>
											<InfoIcon />
										</Box>
									</Box>
								)}
							{okr.okrViewKeyResults &&
								okr.okrViewKeyResults.find((item: any) => item.krStatusId === 0 || item.krStatusId === 2) && (
									<Box className='progressText'>
										<Typography variant='body1' style={{ color: color }}>
											{okr?.score}%
										</Typography>
									</Box>
								)}
							{okr.okrViewKeyResults &&
								okr.okrViewKeyResults.find((item: any) => item.krStatusId === 0 || item.krStatusId === 2) && (
									<OkrPopperMenu
										{...props}
										poperClassName={'alignment-popper-menu popper-menu-list okr-list-popper-menu'}
										okr={okr}
										type={1}
										t={t}
										currentUser={currentUser}
										handleDeleteOkr={handleDeleteOkr}
										alignmentMap={true}
										//poperPlacement={'bottom-start'}
										poperContainer={false}
										openSettingsDrawer={openSettingsDrawer}
										dataUpdated={dataUpdated}
										setdataUpdated={setdataUpdated}
										goalDetail={goalDetail}
										setGoalDetail={setGoalDetail}
										isLocked={isLocked}
										diagramInstance={props.diagramInstance}
									/>
								)}
						</Box>
					</Box>
				</Box>
				<GoalAttributes
					currentCycleEndDate={currentCycleEndDate}
					open={openObjectAttribute}
					handleDrawerClose={handleCloseObjectiveAttribute}
					objectiveDetail={objectiveDetail}
					t={t}
					{...props}
					isGoalAttributeUpdated={isGoalAttributeUpdated}
					setIsSaveDisabled={setIsSaveDisabled}
					setGoalsAttribute={setGoalsAttribute}
					goalsAttribute={goalsAttribute}
					loader={krLoader}
					goalAttrDateError={goalAttrDateError}
					setGaolAttrDateError={setGaolAttrDateError}
					setdataUpdated={setdataUpdated}
					goalDetail={dataUpdated}
					setGoalDetail={setdataUpdated}
				/>
			</Box>
		</React.Fragment>
	);
};

export default UltraCompactObjectiveCard;
