import { getRequest, postRequest, deleteRequest, putRequest } from '../config/auth';
import { GET_NOTES, GET_CONVERSATION, LIKE_CONVERSATION } from '../config/api-url';

//CFR
export const getNotesAPI = (data) => getRequest(`${GET_NOTES}?${data}`, data);
export const deleteNotesAPI = (data) => deleteRequest(`${GET_NOTES}?${data}`, data);
export const UpdateNotesAPI = (data) => putRequest(`${GET_NOTES}`, data);
export const addNotesAPI = (data) => postRequest(`${GET_NOTES}`, data);
//export const taskCompletedAPI = (data) => putRequest(`${TASK_COMPLETED}`, data);

//Conversation
export const getConversationAPI = (data) => getRequest(`${GET_CONVERSATION}?${data}`, data);
export const deleteConversationAPI = (data) => deleteRequest(`${GET_CONVERSATION}?${data}`, data);
export const UpdateConversationAPI = (data) => putRequest(`${GET_CONVERSATION}`, data);
export const addConversationAPI = (data) => postRequest(`${GET_CONVERSATION}`, data);
export const likeConversationAPI = (data) => postRequest(`${LIKE_CONVERSATION}`, data);
