import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
	Box,
	List,
	ListItem,
	TextField,
	Typography,
	InputAdornment,
	FormLabel,
	Avatar,
	Chip,
	Tooltip,
	MenuList,
	MenuItem,
} from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { DeleteIcon, EditIcon, ExternalContributorIcon, SortIcon } from '../../../config/svg/CommonSvg';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import {
	abbreviateNumber,
	getCurrentQuarterAndYear,
	getKRStatus,
	getMonthDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { NumbersIcon, PercentageIcon } from '../../../config/svg/MyGoalSvg';
import { NumberFormatComponent } from '../../Common/NumberFormat';
import { getLocalStorageItem } from '../../../services/StorageService';
import '../../../styles/pages/drawer/contributorsListPanel.scss';

export const KrContributorList: React.FC<any> = (props) => {
	const {
		t,
		objectiveDetail,
		keyData,
		keyAttribute,
		contributorDetails,
		objectiveEndDate,
		getCurrencyIcon,
		okrMasterData,
		onDeleteContributor,
		onEditContributor,
		goalTypeId,
		sortContributorsList,
		sortingColumn,
		sortOrder,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
		anchorElCallouts,
	} = props;
	const [expandContributor, setExpandContributor] = useState<any>(null);
	const [addAnchorEl, setAddAnchorEl] = useState<HTMLElement | null>(null);
	const [addOpen, setAddOpen] = useState<boolean>(false);
	const loggedInUserDetail = getUserDetails();
	const { year, quarterSymbol } = getCurrentQuarterAndYear();
	const [filteredConList, setFilteredConList] = useState([]);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openPopper, setOpenPopper] = useState<boolean>(false);
	const selectedYear = getLocalStorageItem('year') || year;

	useEffect(() => {
		setAddOpen(false);
		setFilteredConList(contributorDetails);
	}, [contributorDetails]);

	const getMetricDetails = () => {
		const { metricMasters } = okrMasterData;
		const selectedMetricId = keyAttribute?.metricId
			? keyAttribute.metricId
			: keyData?.metricId === 0
			? 5
			: keyData?.metricId;
		const selectedCurrencyId = keyAttribute?.currencyId ? keyAttribute.currencyId : keyData?.currencyId;
		let selectedCurrencyDetail: any = {};
		const selectedMetric =
			metricMasters && metricMasters.length
				? metricMasters.find((item: any) => item.metricId === selectedMetricId)
				: null;
		if (selectedMetric && selectedMetric?.name === 'Currency') {
			const { metricDataMaster } = selectedMetric;
			const selectedCurrency =
				metricDataMaster && metricDataMaster.length
					? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
					: null;
			selectedCurrencyDetail = selectedCurrency;
		}
		return { selectedMetric, selectedCurrencyDetail };
	};

	const getMetricCurrencyIcon = (item: any) => {
		const { selectedMetric, selectedCurrencyDetail } = getMetricDetails();
		let component: React.ReactNode = <></>;
		if (selectedMetric.name === 'Currency') {
			component = (
				<>
					{getCurrencyIcon(selectedCurrencyDetail?.name ? selectedCurrencyDetail.name : 'Dollar')}
					{`${abbreviateNumber(item.startValue) || 0}/`}
					{getCurrencyIcon(selectedCurrencyDetail?.name ? selectedCurrencyDetail.name : 'Dollar')}
					{`${abbreviateNumber(item.targetValue) || 0}`}
				</>
			);
		} else if (selectedMetric.name === 'Percentage') {
			component = (
				<>
					{`${item.startValue || 0}`}
					<PercentageIcon />
					{`/${item.targetValue || 0}`}
					<PercentageIcon />
				</>
			);
		} else if (selectedMetric.name === 'Numbers') {
			component = (
				<>
					<NumbersIcon />
					{`${abbreviateNumber(item.startValue) || 0}/`}
					<NumbersIcon />
					{`${abbreviateNumber(item.targetValue) || 0}`}
				</>
			);
		} else if (selectedMetric.metricId === 4 || selectedMetric.metricId === 5) {
			component = <>{`${selectedMetric.description}`}</>;
		}

		return component;
	};
	const expandCollapseHandleClick = (event: any, item: any) => {
		event.stopPropagation();
		if (expandContributor && expandContributor.employeeId === item.employeeId) {
			setExpandContributor(null);
			setAddAnchorEl(null);
			setAddOpen(false);
		} else {
			setExpandContributor(item);
			setAddAnchorEl(event.currentTarget);
			setAddOpen(true);
		}
	};
	const MetricDetail = () => {
		const { selectedMetric, selectedCurrencyDetail } = getMetricDetails();

		return (
			<Box display='flex'>
				{selectedMetric.name === 'NoUnits' || selectedMetric.name === 'Boolean' ? (
					<></>
				) : (
					<Box className='kr-metrics-fileds'>
						<Box className='kr-metrics-fileds-inner'>
							<TextField
								value={`${expandContributor.startValue || 0}`}
								className='current-value'
								id={'start-value'}
								label={'Current'}
								disabled={true}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									inputComponent: NumberFormatComponent,
									startAdornment: (
										<InputAdornment position='start'>
											{selectedMetric.name === 'Percentage' ? (
												<PercentageIcon />
											) : selectedMetric.name === 'Numbers' ? (
												<NumbersIcon />
											) : (
												getCurrencyIcon(selectedCurrencyDetail?.name ? selectedCurrencyDetail.name : 'Dollar')
											)}
										</InputAdornment>
									),
								}}
							/>
							<Box className='matrics-field-seprator' component='span'>
								{'/'}
							</Box>
							<TextField
								value={expandContributor?.targetValue || 0}
								className='target-value'
								id={'target-value'}
								label={'Target'}
								disabled={true}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									inputComponent: NumberFormatComponent,
									startAdornment: (
										<InputAdornment position='start'>
											{selectedMetric.name === 'Percentage' ? (
												<PercentageIcon />
											) : selectedMetric.name === 'Numbers' ? (
												<NumbersIcon />
											) : (
												getCurrencyIcon(selectedCurrencyDetail?.name ? selectedCurrencyDetail.name : 'Dollar')
											)}
										</InputAdornment>
									),
								}}
							/>
						</Box>
					</Box>
				)}
			</Box>
		);
	};
	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
		setOpenPopper((prev) => !prev);
	};
	const getContributorStatus = (item: any, type: Number) => {
		let statusName = item?.krStatusName ? item?.krStatusName : getKRStatus(parseInt(item?.krStatusId || 1));
		if (item.isSelfCreation === true) {
			statusName = t('selfAligned');
		}
		return type === 1 ? statusName : statusName.toLowerCase();
	};

	const getStatusList = () => {
		const statuses =
			contributorDetails.length &&
			contributorDetails.map((item: any) => {
				return getContributorStatus(item, 1);
			});
		const uniqueStatus: any = [];
		statuses &&
			statuses.map((sts: any) => {
				if (uniqueStatus.indexOf(sts) === -1) {
					uniqueStatus.push(sts);
				}
				return true;
			});
		uniqueStatus.sort();
		return (
			<MenuList>
				{uniqueStatus &&
					uniqueStatus.map((item: any) => {
						return (
							<MenuItem
								key={`menu-item-${item}`}
								onClick={(e) => {
									setOpenPopper(false);
									filterContributor(item);
								}}
							>
								{item}
							</MenuItem>
						);
					})}
				{uniqueStatus.length > 1 && (
					<MenuItem
						key={`menu-item-all`}
						onClick={(e) => {
							setOpenPopper(false);
							filterContributor('all');
						}}
					>
						{t('viewAll')}
					</MenuItem>
				)}
			</MenuList>
		);
	};

	const filterContributor = (item: any) => {
		let getFilteredList = [];
		if (item === 'all') {
			getFilteredList = contributorDetails;
		} else if (item === t('selfAligned')) {
			getFilteredList =
				contributorDetails.length &&
				contributorDetails.filter((i: any) => {
					return i.isSelfCreation;
				});
		} else {
			getFilteredList =
				contributorDetails.length &&
				contributorDetails.filter((i: any) => {
					return getKRStatus(parseInt(i?.krStatusId || 1)) === item && !i?.isSelfCreation;
				});
		}
		setFilteredConList(getFilteredList);
	};
	//callouts
	const refcontributorList: any = useRef();
	useEffect(() => {
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel('krContributorDrawer');
		}, 500);

		return () => {
			props.updateCalloutPanel && props.updateCalloutPanel('');
		};
	}, []);
	useEffect(() => {
		if (
			getLocalStorageItem('showCallouts') &&
			refcontributorList.current &&
			props.activeOnboardingPanel === 'krContributorDrawer' &&
			getLocalStorageItem('krContributorDrawercalloutDone') === null
		) {
			setTimeout(() => {
				setAnchorElCallouts && setAnchorElCallouts(refcontributorList.current);
				setCallOut && setCallOut(calloutsData[9]);
			}, 800);
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
		}
	}, [props.activeOnboardingPanel]);
	return (
		<Box className='drawer-inner-content'>
			<Box className='drawer-content-panel okr-drawer-form-area'>
				<Box className='contributors-list-panel' {...({ ref: refcontributorList } as any)}>
					{filteredConList && filteredConList.length ? (
						<Box className='kr-contributor-listing' onClick={() => {}}>
							<Box className='kr-contributor-list-head'>
								<List component='ul' disablePadding>
									<ListItem className='user-col1'>
										<Typography
											variant='body2'
											className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											onClick={(e) => sortContributorsList(e, 'firstName')}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>{t('nameLabel')}</span> <SortIcon />
											</span>
										</Typography>
									</ListItem>
									<ListItem className='user-col2'>
										<Typography
											variant='body2'
											className={sortingColumn === 'targetValue' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											onClick={(e) => sortContributorsList(e, 'targetValue')}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>{t('startTarget')}</span>
												<SortIcon />
											</span>
										</Typography>
									</ListItem>
									<ListItem className='user-col3'>
										<Typography
											variant='body2'
											className={sortingColumn === 'dueDate' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											onClick={(e) => sortContributorsList(e, 'dueDate')}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>{t('dueDate')}</span>
												<SortIcon />
											</span>
										</Typography>
									</ListItem>
									<ListItem className='user-col4'>
										<Typography
											variant='body2'
											className={sortingColumn === 'krStatusName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
											onClick={(e) => sortContributorsList(e, 'krStatusName')}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>{t('acceptance')}</span> <SortIcon />
											</span>
										</Typography>
										{/* <OKRButton className='kebab-icon' icon={<KebabIcon />} handleClick={handleToggle} />
								<OKRPopper
									{...props}
									openPopper={openPopper}
									setOpenPopper={setOpenPopper}
									anchorEl={anchorEl}
									setAnchorEl={setAnchorEl}
									popperClassName='popper-menu-list acceptance-menu-list'
									children={getStatusList()}
								/> */}
									</ListItem>
								</List>
							</Box>
							<Box className='kr-contributor-list-content'>
								{filteredConList.length ? (
									filteredConList.map((item: any) => (
										<Box
											key={item.employeeId}
											className={`kr-contributor-list-row ${
												expandContributor && expandContributor.employeeId === item.employeeId
													? 'expanded-contributor'
													: ''
											}`}
											id={`kr-contributor-list-${item.employeeId}`}
											onClick={(event: any) => expandCollapseHandleClick(event, item)}
										>
											<Box className='contributor-expand-collapse-view'>
												<List component='ul'>
													<ListItem className='user-col1'>
														<Box className='user-info'>
															<UserAvatarGroup
																contributorDetails={[{ ...item }]}
																avatarClassName=''
																isButtonVisible={false}
																isButtonDisable={true}
																avatarGroupClassName='contributor-user-avatar'
																max={1}
															/>
															<Box className='user-info-details'>
																<Typography variant='h4'>
																	{item.fullName ? item.fullName : `${item?.firstName} ${item?.lastName}`}
																</Typography>
																<Typography variant='subtitle2'>{item.designation}</Typography>
																{item && item.teamName && (
																	<Box className='teams-chips'>
																		{item?.isExternal && (
																			<Tooltip
																				title={
																					!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																						? t('externalContributorTooltip')
																						: ''
																				}
																				arrow
																			>
																				<span className='external-link'>
																					{' '}
																					<ExternalContributorIcon />{' '}
																				</span>
																			</Tooltip>
																		)}
																		<Tooltip
																			title={
																				!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																					? item.teamName
																					: ''
																			}
																			arrow
																		>
																			<Chip
																				className='chip'
																				style={{
																					backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																					color: getRandomColor(item?.colorCode),
																				}}
																				label={item.teamName}
																			/>
																		</Tooltip>
																	</Box>
																)}
															</Box>
														</Box>
													</ListItem>
													<ListItem className='user-col2'>
														<Tooltip
															title={
																!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																	? t('metricNotChangeMessage')
																	: ''
															}
															arrow
														>
															<Typography variant='body1'>{getMetricCurrencyIcon(item)}</Typography>
														</Tooltip>
													</ListItem>
													<ListItem className='user-col3'>
														<Typography variant='body1'>{getMonthDate(item?.dueDate || objectiveEndDate)}</Typography>
														<Typography variant='h6'>
															{props.goalDetail && props.goalDetail?.okr?.goalTypeId
																? props.goalDetail.okr.dueCycle
																: props.userSelectedCycle?.symbol + ', ' + props.userSelectedCycle?.year}
														</Typography>
													</ListItem>

													<ListItem className='user-col4'>
														<Typography
															variant='body2'
															className={
																getContributorStatus(item, 1) === t('selfAligned')
																	? 'self-text'
																	: `${getContributorStatus(item, 2)}-text`
															}
														>
															{getContributorStatus(item, 1)}
														</Typography>
														<Box className='btn-wrap'>
															<OKRButton
																className='edit-btn'
																id={`kr-contributor-edit-${item.employeeId}`}
																disabled={false}
																icon={<EditIcon />}
																handleClick={(event: any) => onEditContributor(event, item, 'Edit')}
																title={
																	!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																		? t('editLabel')
																		: ''
																}
															/>
															<OKRButton
																className='delete-btn'
																id={`kr-contributor-delete-${item.employeeId}`}
																disabled={false}
																icon={<DeleteIcon />}
																handleClick={(event: any) => onDeleteContributor(event, item, 'Delete')}
																title={
																	!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('delete') : ''
																}
															/>
														</Box>
													</ListItem>
												</List>
												{expandContributor && expandContributor.employeeId === item.employeeId && (
													<Box className='okr-info-list'>
														<form className='okr-attributes-form' noValidate autoComplete='off'>
															{expandContributor?.assignmentTypeId && expandContributor.assignmentTypeId === 2 ? (
																<Box className='field-row'>
																	<FormLabel component='legend'>{t('objective')}</FormLabel>
																	<Typography>
																		{expandContributor?.objectiveName || objectiveDetail?.objectiveName || ''}
																	</Typography>
																</Box>
															) : goalTypeId !== 1 ? (
																<></>
															) : (
																<Box className='field-row'>
																	<FormLabel component='legend'>{t('objective')}</FormLabel>
																	<Typography>
																		{expandContributor?.objectiveName || objectiveDetail?.objectiveName || ''}
																	</Typography>
																</Box>
															)}
															<Box className='field-row'>
																<FormLabel component='legend'>{t('keyResult')}</FormLabel>
																<Typography>
																	{expandContributor?.keyResult ||
																		keyData?.keyDescription ||
																		keyAttribute?.keyDescription ||
																		''}
																</Typography>
															</Box>
														</form>
														{expandContributor.krAssignerMessage && (
															<Box className='message-trail'>
																<Typography className='message-trail-label'>{t('FeedbackMessageLabel')}</Typography>
																{expandContributor.krAssignerMessage && (
																	<Box className='message-trail-first-label'>
																		<Box className='first-label-row'>
																			<Box className='user-info'>
																				{loggedInUserDetail && loggedInUserDetail.imagePath ? (
																					<Avatar
																						className='user-img'
																						alt={`${loggedInUserDetail.fullname}`}
																						src={loggedInUserDetail.imagePath}
																					/>
																				) : (
																					<Avatar
																						className='user-img'
																						style={{
																							backgroundColor: getRandomBackgroundColor(
																								loggedInUserDetail.backGroundColorCode
																							),
																							color: getRandomColor(loggedInUserDetail.colorCode),
																						}}
																					>
																						{getUserName({
																							firstName: loggedInUserDetail.firstName,
																							lastName: loggedInUserDetail.lastName,
																							fullName: loggedInUserDetail.fullName,
																						})}
																					</Avatar>
																				)}
																				<Box className='user-info-details'>
																					<Typography variant='h4'>{`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}</Typography>
																					<Box className='user-info-list'>
																						<Typography variant='subtitle2'>
																							{loggedInUserDetail.designation}
																						</Typography>
																						<Typography variant='subtitle2'>
																							{t('sentOn')}{' '}
																							{expandContributor.createdOnAssigner &&
																								getMonthDate(expandContributor.createdOnAssigner)}
																						</Typography>
																					</Box>
																				</Box>
																			</Box>
																			<Box className='user-message-description'>
																				<Typography>{expandContributor.krAssignerMessage}</Typography>
																			</Box>
																			{expandContributor.krAssigneeMessage && (
																				<Box className='message-trail-second-label'>
																					<Box className='user-info'>
																						{expandContributor && expandContributor.imagePath ? (
																							<Avatar
																								className='user-img'
																								alt={`${expandContributor.fullname}`}
																								src={expandContributor.imagePath}
																							/>
																						) : (
																							<Avatar
																								className='user-img'
																								style={{
																									backgroundColor: getRandomBackgroundColor(
																										expandContributor.backGroundColorCode
																									),
																									color: getRandomColor(expandContributor.colorCode),
																								}}
																							>
																								{getUserName({
																									firstName: expandContributor.firstName,
																									lastName: expandContributor.lastName,
																									fullName: expandContributor.fullName,
																								})}
																							</Avatar>
																						)}
																						<Box className='user-info-details'>
																							<Typography variant='h4'>{`${expandContributor.firstName} ${expandContributor.lastName}`}</Typography>
																							<Box className='user-info-list'>
																								<Typography variant='subtitle2'>
																									{expandContributor.designation}
																								</Typography>
																								<Typography variant='subtitle2'>
																									{t('receivedOn')}{' '}
																									{expandContributor.createdOnAssigner &&
																										getMonthDate(expandContributor.createdOnAssignee)}
																								</Typography>
																							</Box>
																						</Box>
																					</Box>
																					<Box className='user-message-description'>
																						<Typography>{expandContributor.krAssigneeMessage}</Typography>
																					</Box>
																				</Box>
																			)}
																		</Box>
																	</Box>
																)}
															</Box>
														)}
														{!expandContributor.krAssignerMessage && expandContributor.krAssigneeMessage && (
															<Box className='message-trail'>
																<Typography className='message-trail-label'>{t('FeedbackMessageLabel')}</Typography>
																{expandContributor.krAssigneeMessage && (
																	<Box className='message-trail-first-label'>
																		<Box className='first-label-row'>
																			<Box className='user-info'>
																				{expandContributor && expandContributor.imagePath ? (
																					<Avatar
																						className='user-img'
																						alt={`${expandContributor.fullname}`}
																						src={expandContributor.imagePath}
																					/>
																				) : (
																					<Avatar
																						className='user-img'
																						style={{
																							backgroundColor: getRandomBackgroundColor(
																								expandContributor.backGroundColorCode
																							),
																							color: getRandomColor(expandContributor.colorCode),
																						}}
																					>
																						{getUserName({
																							firstName: expandContributor.firstName,
																							lastName: expandContributor.lastName,
																							fullName: expandContributor.fullName,
																						})}
																					</Avatar>
																				)}
																				<Box className='user-info-details'>
																					<Typography variant='h4'>{`${expandContributor.firstName} ${expandContributor.lastName}`}</Typography>
																					<Box className='user-info-list'>
																						<Typography variant='subtitle2'>{expandContributor.designation}</Typography>
																						<Typography variant='subtitle2'>
																							{t('receivedOn')}{' '}
																							{expandContributor.createdOnAssigner &&
																								getMonthDate(expandContributor.createdOnAssignee)}
																						</Typography>
																					</Box>
																				</Box>
																			</Box>
																			<Box className='user-message-description'>
																				<Typography>{expandContributor.krAssigneeMessage}</Typography>
																			</Box>
																		</Box>
																	</Box>
																)}
															</Box>
														)}
													</Box>
												)}
											</Box>
										</Box>
									))
								) : (
									<></>
								)}
							</Box>
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Box>
		</Box>
	);
};
