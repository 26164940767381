import React from 'react';
import T from 'prop-types';
import { svg, select } from 'd3';

import './style.css';

export default class Link extends React.PureComponent {
	state = {
		initialStyle: {
			opacity: 0,
		},
	};

	componentDidMount() {
		this.applyOpacity(1, this.props.transitionDuration);
	}

	componentWillLeave(done) {
		this.applyOpacity(0, this.props.transitionDuration, done);
	}

	applyOpacity(opacity, transitionDuration, done = () => {}) {
		if (this.props.linkData.source.name === 'parent') {
			opacity = 0;
		}
		if (transitionDuration === 0) {
			select(this.link).style('opacity', opacity);
			done();
		} else {
			select(this.link).transition().duration(transitionDuration).style('opacity', opacity).each('end', done);
		}
	}

	drawStepPath(linkData, orientation) {
		const { source, target } = linkData;
		const deltaY = target.y - source.y;

		return orientation === 'horizontal'
			? `M${source.y},${source.x} H${source.y + deltaY / 2} V${target.x} H${target.y}`
			: `M${source.x},${source.y} V${source.y + deltaY / 2} H${target.x} V${target.y}`;
	}

	drawDiagonalPath(d) {
		const { type } = this.props;
		const typT = type === 'topLink' ? 60 : 0;
		const sourceY = d.source.parentId === 0 ? this.props.nodeSize.y - 190 : this.props.nodeSize.y - this.props.linkSize;
		var p0 = {
				x: d.source.x + this.props.nodeSize.x / 2,
				y: d.source.y + sourceY,
			},
			p3 = {
				x: d.target.x + this.props.nodeSize.x / 2,
				y: d.target.y - typT, // -12, so the end arrows are just before the rect node
			},
			m = (p0.y + p3.y) / 2,
			p = [
				p0,
				{
					x: p0.x,
					y: m,
				},
				{
					x: p3.x,
					y: m,
				},
				p3,
			];
		p = p.map(function (d) {
			return [d.x, d.y];
		});
		return 'M' + p[0] + 'C' + p[1] + ' ' + p[2] + ' ' + p[3];
		//	const diagonal = svg.diagonal().projection((d) => (orientation === 'horizontal' ? [d.y, d.x] : [d.x + 145, d.y]));
		//return diagonal(linkData);
	}

	drawStraightPath(linkData, orientation) {
		const straight = svg
			.line()
			.interpolate('basis')
			.x((d) => d.x)
			.y((d) => d.y);

		let data = [
			{ x: linkData.source.x, y: linkData.source.y },
			{ x: linkData.target.x, y: linkData.target.y },
		];

		if (orientation === 'horizontal') {
			data = [
				{ x: linkData.source.y, y: linkData.source.x },
				{ x: linkData.target.y, y: linkData.target.x },
			];
		}

		return straight(data);
	}

	drawElbowPath(d, orientation) {
		return orientation === 'horizontal'
			? `M${d.source.y},${d.source.x}V${d.target.x}H${d.target.y}`
			: `M${d.source.x},${d.source.y}V${d.target.y}H${d.target.x}`;
	}

	drawPath() {
		const { linkData, orientation, pathFunc } = this.props;
		if (typeof pathFunc === 'function') {
			return pathFunc(linkData, orientation);
		}

		if (pathFunc === 'elbow') {
			return this.drawElbowPath(linkData, orientation);
		}

		if (pathFunc === 'straight') {
			return this.drawStraightPath(linkData, orientation);
		}

		if (pathFunc === 'step') {
			return this.drawStepPath(linkData, orientation);
		}

		return this.drawDiagonalPath(linkData, orientation);
	}

	handleOnClick = (evt) => {
		this.props.onClick(this.props.linkData.source, this.props.linkData.target, evt);
	};

	handleOnMouseOver = (evt) => {
		this.props.onMouseOver(this.props.linkData.source, this.props.linkData.target, evt);
	};

	handleOnMouseOut = (evt) => {
		this.props.onMouseOut(this.props.linkData.source, this.props.linkData.target, evt);
	};

	render() {
		const { styles } = this.props;
		return (
			<g
				className='link'
				target={this.props.linkData.target.id}
				sourceId={this.props.linkData.target.employeeId}
				level={this.props.linkData.target.alignLevel}
			>
				<path
					ref={(l) => {
						this.link = l;
					}}
					style={{ ...this.state.initialStyle, ...styles }}
					className='linkBase'
					d={this.drawPath()}
					onMouseOver={this.handleOnMouseOver}
					onMouseOut={this.handleOnMouseOut}
					data-source-id={this.props.linkData.source.id}
					data-target-id={this.props.linkData.target.id}
					opacity={this.props.linkData.source.name === 'parent' ? 0 : 1}
				/>
			</g>
		);
	}
}

Link.defaultProps = {
	styles: {},
};

Link.propTypes = {
	linkData: T.object.isRequired,
	orientation: T.oneOf(['horizontal', 'vertical']).isRequired,
	pathFunc: T.oneOfType([T.oneOf(['diagonal', 'elbow', 'straight', 'step']), T.func]).isRequired,
	transitionDuration: T.number.isRequired,
	onClick: T.func.isRequired,
	onMouseOver: T.func.isRequired,
	onMouseOut: T.func.isRequired,
	styles: T.object,
};
