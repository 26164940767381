import { Box } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { CreateUserDrawerForm } from './CreateUserDrawerForm';
import '../../../../styles/pages/admin/createUserDrawer.scss';
import {
	defaultUserData,
	userDataRequiredFields,
	prepareApiPayload,
	prepareAddAnotherFormData,
	isFieldValid,
	prepareEditUserFormData,
	freeTrialsRequiredFields,
} from '../UsersHelper';
import { Enums } from '../../../../config/enums';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { USER_ROLE_ID } from '../../../../config/users';
import { getUserDetails } from '../../../../config/utils';

export const CreateUser: React.FC<any> = (props) => {
	const { openCreateUserDrawer, handleMenuClick, addUsers, updateStatus, isEditForm, editUserDetails, editUsers } =
		props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [selectedAddNewUserTab, setselectedAddNewUserTab] = useState<any>(0);
	const [userFormData, setUserFormData] = useState<any>(defaultUserData);
	const [formError, setFormError] = useState<any>({});
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [validationInProgress, setValidationInProgress] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	const [loader, setLoader] = useState(false);
	const [addAnother, setAddAnother] = useState<boolean>(false);
	const [uploadFileData, setUploadFileData] = useState({
		filePath: '',
		fileName: '',
		fileUploaded: false,
	});
	const [errorLogMessage, setErrorLogMessage] = useState([]);
	const isGuidedTourShow = process.env.REACT_APP_ISGUIDEDTOURSHOW;
	const loggedInUserDetail = getUserDetails();

	useEffect(() => {
		if (isEditForm === true && editUserDetails) {
			setUserFormData(prepareEditUserFormData(editUserDetails));
		}
	}, [editUserDetails]);

	/**
	 * Handle drawer actions
	 */
	const handleDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		event.preventDefault();
		if (type === 'save') {
			if (selectedAddNewUserTab === 0) {
				createUser(event);
			} else if (selectedAddNewUserTab === 1) {
				uploadBulkUser(event);
				setValidationInProgress(true);
			} else if (selectedAddNewUserTab === 2) {
				uploadBulkEmails(event);
				setValidationInProgress(true);
			}
		} else if (type === 'cancel' || type === 'close') {
			if (formEdited) {
				setModalProps({
					open: true,
					module: 'information',
					type: 'addUser',
					message: t('unSavedItemAlert'),
				});
			} else {
				handleMenuClick(event, 'addNewUsers');
			}
		}
	};

	/**
	 * validate user form
	 * @returns boolean
	 */
	const validateUserForm = (): boolean => {
		let isValid = true;
		let errorList: any = {};
		let requireFields = userDataRequiredFields;

		if (isGuidedTourShow === 'false' && isEditForm !== true) {
			requireFields = freeTrialsRequiredFields;
		}
		requireFields.forEach((fieldName: string) => {
			let fieldError = isFieldValid(fieldName, userFormData[fieldName], t);
			if (fieldError !== '') {
				errorList[fieldName] = fieldError;
			}
			isValid = isValid && fieldError === '';
		});
		setFormError(errorList);
		return isValid;
	};

	/**
	 * Validate bulk users
	 */
	const validateBulkUsers = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			const response = await props.validateBulkFileUpload(formData);
			if (Boolean(response) && response.data && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				return response.data;
			}
		}
	};

	/**
	 * perform create user api call and show message on error / success
	 * @param event
	 */
	const createUser = async (event: any) => {
		if (validateUserForm() === true) {
			let createUserPayload: any = prepareApiPayload(userFormData);
			if (isGuidedTourShow === 'false' && isEditForm !== true) {
				createUserPayload.roleId = USER_ROLE_ID;
				createUserPayload.reportingTo = loggedInUserDetail.employeeId;
				createUserPayload.teamId = loggedInUserDetail.organisationId;
			}
			let response: any = {};
			setLoader(true);
			if (isEditForm === true) {
				createUserPayload.employeeId = editUserDetails.employeeId;
				response = await editUsers(createUserPayload);
			} else {
				response = await addUsers(createUserPayload);
			}
			if (response.data.status === Enums.STATUS_SUCCESS) {
				if (!isEditForm) {
					props.refetchLicenceDetails();
				}
				setFormError({});
				updateStatus();
				setLoader(false);
				enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
					variant: 'success',
					autoHideDuration: 5000,
				});

				if (addAnother === true) {
					setAddAnother(!addAnother);
					setUserFormData(prepareAddAnotherFormData(userFormData));
				} else {
					handleMenuClick(event, 'addNewUsers');
				}
			} else {
				if (response?.data?.messageList) {
					if (response?.data?.messageList.message) {
						enqueueSnackbar(response?.data?.messageList.message, {
							variant: 'error',
							autoHideDuration: 5000,
						});
					} else {
						setFormError(response?.data?.messageList);
					}
				}
				setLoader(false);
			}
		}
	};

	/**
	 * alert box button click event
	 * @param e
	 * @param type
	 */
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'bulkUserUpload' && uploadFileData.fileName != '') {
				uploadBulkUser(e);
			} else if (modalProps.type === 'bulkEmailsUpload' && uploadFileData.fileName != '') {
				uploadBulkEmails(e);
			} else {
				handleMenuClick(e, 'addNewUsers');
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	/**
	 * Handle Role detail and Manage User tab change
	 * @param event HTML Event
	 * @param newValue Number
	 */
	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setselectedAddNewUserTab(newValue);
		setErrorLogMessage([]);
		setUploadFileData({
			filePath: '',
			fileName: '',
			fileUploaded: false,
		});
	};

	const uploadFileHandler = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			const response = await props.uploadUser(formData);
			if (Boolean(response) && response.data && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				return response.data;
			}
		}
	};

	const uploadBulkUser = async (e: any) => {
		if (uploadFileData.fileName && uploadFileData.fileUploaded) {
			try {
				const fileUploadDetails = await uploadFileHandler();

				const responseAPI = fileUploadDetails?.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				setLoader(true);
				if (fileUploadDetails.status === 200) {
					enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
					props.getLicenseDetails();
					handleMenuClick(e, 'addNewUsers');
					setFormError({});
					setLoader(false);
				} else {
					enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
					setFormError({ bulkUserError: messages });
					setLoader(false);
				}
				if (
					fileUploadDetails.entity &&
					fileUploadDetails.entity.bulkErrors &&
					fileUploadDetails.entity.bulkErrors.length > 0
				) {
					const errorDetail = fileUploadDetails.entity.bulkErrors;
					setErrorLogMessage(errorDetail);
					setLoader(false);
				}
			} catch (err) {
				enqueueSnackbar(t('errorUploadingFile'), { variant: 'error', autoHideDuration: 5000 });
			}
		} else {
			enqueueSnackbar(`${t('UsersBulkUpload')}`, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	const uploadEmailsHandler = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			const response = await props.uploadEmails(formData);
			if (Boolean(response) && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				return response.data;
			}
		}
	};

	const uploadBulkEmails = async (e: any) => {
		if (uploadFileData.fileName && uploadFileData.fileUploaded) {
			try {
				const fileUploadDetails = await uploadEmailsHandler();

				const responseAPI = fileUploadDetails?.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				setLoader(true);
				if (fileUploadDetails.status === 200) {
					enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
					props.getLicenseDetails();
					handleMenuClick(e, 'addNewUsers');
					setFormError({});
					setLoader(false);
				} else {
					enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
					setFormError({ bulkUserError: messages });
					setLoader(false);
				}
				if (
					fileUploadDetails.entity &&
					fileUploadDetails.entity.bulkErrors &&
					fileUploadDetails.entity.bulkErrors.length > 0
				) {
					const errorDetail = fileUploadDetails.entity.bulkErrors;
					setErrorLogMessage(errorDetail);
					setLoader(false);
				}
			} catch (err) {
				enqueueSnackbar(t('errorUploadingFile'), { variant: 'error', autoHideDuration: 5000 });
			}
		} else {
			enqueueSnackbar(`${t('UsersBulkUpload')}`, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	return (
		<Fragment>
			<MainDrawer
				open={openCreateUserDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={isEditForm === true ? t('editUserHeader') : t('addUserTabLabelText')}
				loader={loader}
				children={
					<Box className='drawer-inner-content'>
						<CreateUserDrawerForm
							{...props}
							selectedAddNewUserTab={selectedAddNewUserTab}
							handleTabChange={handleTabChange}
							userFormData={userFormData}
							setUserFormData={setUserFormData}
							isFieldValid={isFieldValid}
							formError={formError}
							setFormError={setFormError}
							setFormEdited={setFormEdited}
							uploadFileData={uploadFileData}
							setUploadFileData={setUploadFileData}
							errorLogMessage={errorLogMessage}
							setErrorLogMessage={setErrorLogMessage}
							validateBulkUsers={validateBulkUsers}
							setValidationInProgress={setValidationInProgress}
							setLoader={setLoader}
						/>
					</Box>
				}
				saveButtonText={isEditForm ? t('update') : t('addBtn')}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
				showAddAnother={!isEditForm && selectedAddNewUserTab !== 1 && selectedAddNewUserTab !== 2}
				addAnother={addAnother}
				isSaveButtonDisabled={(isEditForm && !formEdited) || validationInProgress}
				addAnotherTooltipText={t('addAnotherTooltipText')}
				handleAddAnother={() => {
					setAddAnother(!addAnother);
				}}
			/>
			{modalProps.open && (
				<DialogComponent
					module={modalProps.module}
					message={modalProps.message}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
};
