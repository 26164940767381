import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import { getUserName, getFullName } from '../../../config/utils';
import { useTranslation } from 'react-i18next';

HC_more(Highcharts);

const BubbleChart = ({
	data,
	showDetails,
	setShowDetails,
	getOKRDetails,
	getDetailOnMouseHover,
	storeDataBeforeHover,
	contributorDetail,
	showActive,
}) => {
	const { t } = useTranslation();
	const [showSecondLevelContributors, setShowSecondLevelContributors] = useState(false);

	useEffect(() => {
		if (showSecondLevelContributors === false) {
			getOKRDetails(contributorDetail);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showSecondLevelContributors]);

	const getMaxYAxis = () => {
		if (data.length === 0) return 100;
		const max = Math.max.apply(
			Math,
			data.map((i) => i.score)
		);
		return Math.round(max + 50 <= 100 ? 100 : max + 50);
	};

	const getMinYAxis = () => {
		if (data.length === 0) return 0;
		const min = Math.min.apply(
			Math,
			data.map((i) => i.score)
		);
		return Math.round(min - 53 >= 0 ? 0 : min - 53);
	};

	const bottomLineAxis = () => {
		const min = getMinYAxis() === 0 ? -1 : getMinYAxis();
		return [min, min + 3]; // start and end of bottom line
	};

	const baseChartOptions = {
		chart: {
			type: 'bubble',
			plotBorderWidth: 1,
			zoomType: 'xy',
			height: 420,
			crisp: false,
		},

		legend: {
			enabled: false,
		},

		title: {
			text: '',
		},

		xAxis: {
			categories: [t('InsightsCategoriesAtRisk'), t('InsightsCategoriesLagging'), t('InsightsCategoriesOnTrack')],
			tickmarkPlacement: 'between',
			min: 0,
			max: 2,
			gridLineWidth: 1,
			title: {
				enabled: false,
			},
			lineColor: 'lightgray',
			plotBands: [
				{
					color: 'rgba(255,0,0,0.05)',
					from: -1,
					to: 0.5,
				},
				{
					color: 'rgba(255,148,1,0.05)',
					from: 0.5,
					to: 1.5,
				},
				{
					color: 'rgba(96,169,23,0.05)',
					from: 1.5,
					to: 3,
				},
			],
		},
		credits: {
			enabled: false,
		},

		yAxis: {
			startOnTick: false,
			endOnTick: false,
			max: getMaxYAxis(),
			min: getMinYAxis(),
			title: {
				enabled: false,
			},
			labels: {
				/*
				 * Commented for OP-1980
				 * Added formatter to hide negative and more then 100% labels
				 */
				// format: '{value}%',
				formatter: function () {
					return this.value < 0 || this.value > 100 ? '' : `${this.value}%`;
				},
			},
			tickInterval: 10,
			showLastLabel: false,
			showFirstLabel: false,
			plotBands: [
				{
					color: {
						linearGradient: {
							x1: '0%',
							y1: '0%',
							x2: '100%',
							y2: '0%',
						},
						stops: [
							['0%', '#FF0000'],
							['33.3%', '#FF0000'],
							['33.3%', '#FF9401'],
							['66.6%', '#FF9401'],
							['66.6%', '#60A917'],
							['100%', '#60A917'],
						],
					},
					from: bottomLineAxis()[0],
					to: bottomLineAxis()[1],
				},
			],
		},

		tooltip: {
			enabled: false,
		},
	};
	// level 1
	const bubbleChartOptions = Object.assign(
		{
			series: [
				{
					data: data.reduce(
						(acc, bubble) => [
							...acc,
							{
								x: bubble.axis,
								y: bubble.score,
								z: bubble.contributorsCount,
								objectiveDetails: bubble.objectiveDetails,
							},
						],
						[
							// sets maximum bubble size
							{
								x: -999,
								y: -999,
								z: 8,
								objectiveDetails: { secondLevelContributors: [], contributors: [] },
							},
							// sets minimum bubble size
							{
								x: -999,
								y: -999,
								z: 1,
								objectiveDetails: { contributors: [], secondLevelContributors: [] },
							},
						]
					),
					clip: false,
				},
			],
			plotOptions: {
				bubble: {
					minSize: '22%',
					maxSize: '52%',
					dataLabels: {
						color: '#39a3fa',
					},
					events: {
						click: (options) => {
							const contributorsCount = options.point.z;
							const contributors = options.point.objectiveDetails.contributors.filter((item) => item.score > 0);
							if (contributorsCount > 0 && contributors.length > 0) {
								// setShowDetails(true);

								storeDataBeforeHover(contributors);
								getOKRDetails(contributors);
							}
						},
					},
					color: '#39a3fa',
					marker: {
						fillOpacity: 0.5,
						fillColor: 'white',
						lineWidth: 2,
						states: {
							hover: {
								enabled: true,
								lineWidth: 10,
								lineColor: '#BBDEFA',
							},
						},
					},
				},
				series: {
					point: {
						events: {
							mouseOver: function () {
								getDetailOnMouseHover(this);
							},
						},
					},
					bubble: { borderWidth: 10 },
					dataLabels: {
						enabled: true,
						useHTML: true,
						align: 'center',
						varticalAlign: 'middle',
						style: {
							borderColor: 'white',
							textOutline: 0,
						},
						formatter: function () {
							return `<div className='bubble-wrapper bubble-wrapper-firstlvl'>
							<p className="bubble-label-1">${this.point.y ? this.point.y : 0} ${t('percentage')}</p>
							<p className="bubble-label-2"><span>${this.point.z ? this.point.z : 0}</span></p>
							<div/>`;
						},
					},
				},
			},
		},
		baseChartOptions
	);

	// level 2
	const bubbleChartDetailsOptions = Object.assign(
		{
			series: [
				{
					data: data.reduce(
						(acc, bubble) => [
							...acc,
							{
								x: bubble.axis,
								y: bubble.score,
								z: bubble.contributorsCount,
								objectiveDetails: bubble.objectiveDetails,
								contributorsName: bubble.contributorsName,
								secondLevelContributors: bubble.objectiveDetails.secondLevelContributors,
							},
						],
						[
							// sets maximum bubble size
							{
								x: -999,
								y: -999,
								z: 8,
								objectiveDetails: { secondLevelContributors: [], contributors: [] },
								contributorsName: [],
								secondLevelContributors: [],
							},
							// sets minimum bubble size
							{
								x: -999,
								y: -999,
								z: 1,
								objectiveDetails: { secondLevelContributors: [], contributors: [] },
								contributorsName: '',
								secondLevelContributors: [],
							},
						]
					),
					clip: false,
				},
			],
			plotOptions: {
				bubble: {
					minSize: '30%',
					maxSize: '60%',

					dataLabels: {
						color: 'white',
					},
					events: {
						click: (options) => {
							const { secondLevelContributors, objectiveDetails, isContributor } = options.point;
							if (isContributor) {
								// setShowSecondLevelContributors(false);
							}
							if (secondLevelContributors && secondLevelContributors.length > 0 && objectiveDetails) {
								const secondLevel = secondLevelContributors.filter((item) => item.score > 0);
								const contributorData = [...secondLevel, objectiveDetails];

								getOKRDetails([...contributorData], true);
							}
							setShowSecondLevelContributors((prev) => !prev);
						},
						mouseOut: () => {
							setShowSecondLevelContributors(false);
						},
					},
					color: 'white',
					marker: {
						fillColor: '#39a3fa',
						lineWidth: 2,
					},
				},
				series: {
					bubble: { borderWidth: 10 },
					dataLabels: {
						enabled: true,
						formatter: function () {
							const secContributor = this.point.secondLevelContributors;
							let img = '';
							if (secContributor && secContributor.length > 0) {
								for (let i = 0; i <= secContributor.length; i++) {
									if (secContributor[i]) {
										if (secContributor[i].imagePath) {
											img =
												img +
												`<li><div className='avatar-chart'><img src=${
													secContributor[i].imagePath
												}/></div><div>${getFullName(secContributor[i])}</div></li>`;
										} else {
											img =
												img +
												`<li><div className='avatar-chart'>${getUserName(secContributor[i])}</div><div>${getFullName(
													secContributor[i]
												)}</div></li>`;
										}
									}
								}
							}
							img = img
								? `<div className="contributor-details" id="custom-scroll"><div className="contributor-child"><ul>${img}</ul></div></div></div>`
								: '';
							return `<div className="bubble-wrapper">
							<p className="bubble-label-1">${this.point.y}${t('percentage')}</p>
							<p className="bubble-label-2">${this.point.contributorsName}</p>
							${img}`;
						},
					},
				},
			},
		},
		baseChartOptions
	);

	return (
		<div>
			<HighchartsReact
				highcharts={Highcharts}
				options={
					showSecondLevelContributors && showDetails
						? bubbleChartDetailsOptions
						: showDetails
						? bubbleChartDetailsOptions
						: bubbleChartOptions
				}
			/>
		</div>
	);
};

export default BubbleChart;
