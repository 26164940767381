import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormLabel, ButtonGroup } from '@material-ui/core';
import { TeamSelection } from './TeamSelection';
import { AssignContributors } from './AssignContributors';
import { getUserDetails } from '../../../config/utils';
import { checkPermission } from '../../../config/rolePermission';
import { assignOkr } from '../../Admin/AdminConstant';

export const AssignmentSelection: React.FC<any> = (props) => {
	const { keyData, kr, okr, okrMasterData, durationCycleDetail, updateData, krAttrDateError, getPermissionResult } =
		props;
	const [currentGoalId, setCurrentGoalId] = useState<number>(0);
	const [contributors, setContributors] = useState<any[]>([]);
	const [selectedTeams, setSelectedTeams] = React.useState<any>([]);
	const [selectedMetric, setSelectedMetric] = React.useState<any>({});
	const [resetTeamsListingData, setResetTeamsListingData] = React.useState<boolean>(false);
	const loggedInUserDetail = getUserDetails();

	useEffect(() => {
		const { metricId, currencyId } = keyData;
		if (metricId) {
			const { metricMasters } = okrMasterData;
			const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 5 : keyData?.metricId;
			const selectedMetricData =
				metricMasters && metricMasters.length
					? metricMasters.find((item: any) => item.metricId === selectedMetricId)
					: null;
			let selectedCurrencyDetail: any = {};
			if (selectedMetricData && selectedMetricData?.name === 'Currency') {
				const { metricDataMaster } = selectedMetricData;
				const selectedCurrency =
					metricDataMaster && metricDataMaster.length
						? metricDataMaster.find((item: any) => item.dataId === currencyId)
						: null;
				selectedCurrencyDetail = selectedCurrency;
			}
			setSelectedMetric({ selectedMetric: selectedMetricData, selectedCurrencyDetail });
		}
		if (loggedInUserDetail.isTeamLeader === false) {
			setCurrentGoalId(2);
		}
	}, []);

	useEffect(() => {
		if (krAttrDateError && krAttrDateError.type === 'assignment_error') {
			setTimeout(() => {
				const selectedEl = document.getElementById('error-txt-validation-message-label');
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}
			}, 15);
		}
	}, [krAttrDateError]);

	useEffect(() => {
		let contributorData = contributors ? contributors : [];
		let teamId = 0;
		if (selectedTeams && selectedTeams.length > 0) {
			teamId = selectedTeams[0].id;
		}
		if (keyData.TeamId !== teamId) {
			contributorData = [];
			setContributors([]);
			setResetTeamsListingData(true);
		} else {
			setResetTeamsListingData(false);
		}
		updateData({ currentGoalId, 'contributors': contributorData, selectedTeams });
	}, [currentGoalId, contributors, selectedTeams]);

	return (
		<Box className='okr-assign-btn-grp'>
			{loggedInUserDetail.isTeamLeader === true && (
				<Box className='kr-field-row assigned-btn helperText-bottom'>
					<TeamSelection
						{...props}
						keyData={keyData}
						kr={kr}
						okr={okr}
						currentGoalId={currentGoalId}
						setCurrentGoalId={setCurrentGoalId}
						contributors={contributors}
						setContributors={setContributors}
						selectedTeams={selectedTeams}
						setSelectedTeams={setSelectedTeams}
					/>
					{krAttrDateError.type === 'assignment_error' && krAttrDateError?.error && (
						<span className='error-txt-validation-message error-field' id='error-txt-validation-message-label'>
							{(krAttrDateError.type === 'assignment_error' && krAttrDateError?.error) || ''}
						</span>
					)}
				</Box>
			)}
			{checkPermission(getPermissionResult?.employeePermissions, assignOkr) &&
				(currentGoalId === 2 || (currentGoalId === 1 && selectedTeams.length > 0)) ? (
				<AssignContributors
					updatePage={props.updatePage}
					getOwnerTeamsData={props.getOwnerTeamsData}
					globalSearchAPIWithTeam={props.globalSearchAPIWithTeam}
					checkAlignStatus={props.checkAlignStatus}
					keyData={keyData}
					kr={kr}
					okr={okr}
					okrMasterData={okrMasterData}
					durationCycleDetail={durationCycleDetail}
					currentUser={props.currentUser}
					keyAttribute={{ ...keyData, teamId: selectedTeams.length > 0 ? selectedTeams[0].id : 0 }}
					objectiveDetail={okr}
					year={props.year}
					parentGoalTypeId={okr.goalTypeId}
					goalTypeId={currentGoalId}
					isContributorOnly={false}
					actionType={'editKR'}
					selectedMetricDetail={selectedMetric}
					objectiveStartDate={okr?.startDate}
					objectiveEndDate={okr?.endDate}
					updateKeyAttrDetail={() => { }}
					updateMetricDetails={() => { }} // addtarget
					setIsContributorUpdated={() => { }}
					deleteContributor={props.deleteContributor}
					isNoUnitOrBoolean={selectedMetric && (selectedMetric.metricId === 4 || selectedMetric.metricId === 5)}
					isBoolean={selectedMetric && selectedMetric.metricId === 4}
					isStartValueUpdated={() => { }}
					isTargetValueUpdated={() => { }}
					showAttributeDrawer={true}
					contributorDetails={contributors}
					setContributorDetails={setContributors}
					setCallOut={() => { }}
					setAnchorElCallouts={() => { }}
					updateCalloutPanel={() => { }}
					getPermissionResult={getPermissionResult}
					resetTeamListData={resetTeamsListingData}
					userSelectedCycle={props.userSelectedCycle}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
