import React, { Fragment, useEffect, useState } from 'react';
import { InputLabel, List, ListItem, Button, Avatar, Typography, Box } from '@material-ui/core';
import GlobalSearch from '../Common/GlobalSearch';
import { getUserName } from '../../config/utils';
import { CancelIcon } from '../../config/svg/Action';
import { useTranslation } from 'react-i18next';

export default function AssignContributor(props) {
	const { goal, onSelectedUser, removeUser, addKey, fieldLabel } = props;
	const { t } = useTranslation();
	const [defaultFocus, setDefaultFocus] = useState(false);
	useEffect(() => {
		if (addKey.type === 'assignOkr' || addKey.type === 'assignKr') {
			setDefaultFocus(true);
		}
	}, [addKey]);

	return (
		<Fragment>
			<InputLabel htmlFor='employee-search'>{fieldLabel ? fieldLabel : t('assignContributor')}</InputLabel>

			<GlobalSearch
				formData={goal}
				module={'myGoal'}
				onSelectedUser={onSelectedUser}
				{...props}
				defaultFocus={defaultFocus}
				customClass={'user-quick-search-type2'}
				customId={'assign-contri-src'}
				fetchCycleUsers={true}
			/>

			<Box className='contributors-chip'>
				<List>
					{goal &&
						goal.selectedUser &&
						goal.selectedUser.length > 0 &&
						goal.selectedUser.map((item) => (
							<ListItem key={`assign_contributor_${item.employeeId}`}>
								{item.imagePath ? <Avatar src={item.imagePath} /> : <Avatar>{getUserName(item)}</Avatar>}
								<Typography>{`${item.firstName} ${item.lastName}`}</Typography>
								<Button className='cancel-btn' onClick={() => removeUser(item)}>
									<CancelIcon />
								</Button>
							</ListItem>
						))}
				</List>
			</Box>
		</Fragment>
	);
}
