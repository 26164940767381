import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { AddIconSvg } from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import '../../../styles/pages/admin/assignUser.scss';
import { AvtarPlaceholderIcon, TeamUserIcon } from '../../../config/svg/CommonSvgIcon';
import { getUserName } from '../../../config/utils';
import { AssignedUserListPopover } from './UserListPopover';
import { useTranslation } from 'react-i18next';

interface AssignedUserDetailsProps {
	image?: string;
	employeeId: String | Number;
	firstName?: String;
	lastName?: String;
	fullName?: String;
	imagePath?: string;
}
interface UserAvatarGroupProps {
	assignedUserDetails?: Array<AssignedUserDetailsProps>;
	handleAddUserClick?: (event: any) => void;
	isButtonDisable?: boolean;
	diagramInstance?: any;
	max: number;
	subTitle?: any;
	helpText?: any;
	isOnHoverVisible?: Boolean;
	isDisabled?: boolean;
	totalMembers?: number;
}

export const UserAvatarGroup: React.FC<UserAvatarGroupProps> = (props) => {
	const {
		handleAddUserClick,
		max,
		subTitle,
		helpText,
		assignedUserDetails,
		isOnHoverVisible = true,
		isDisabled,
		totalMembers = 0,
	} = props;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const { t } = useTranslation();
	const handleClass = () => {
		if (totalMembers - (max - 1) > 9 && totalMembers - (max - 1) < 99) {
			return 'user-total-no two-digit';
		} else if (totalMembers - (max - 1) > 99 && totalMembers - (max - 1) < 999) {
			return 'user-total-no three-digit';
		} else if (totalMembers - (max - 1) > 999) {
			return 'user-total-no four-digit';
		} else {
			return 'user-total-no';
		}
	};
	return (
		<Box className='assign-users'>
			<Box className='assign-users-inner'>
				<Box
					className={`user-avatar-group ${totalMembers ? handleClass() : ''}`}
					aria-haspopup='true'
					aria-owns={open ? 'mouse-over-popover' : undefined}
					onMouseOver={isOnHoverVisible && handleOpen}
					onMouseLeave={isOnHoverVisible && handleClose}
				>
					{/**avatar for no user added **/}
					{assignedUserDetails && assignedUserDetails.length ? (
						<>
							<AvatarGroup
								max={max || 6}
								className={`assign-user-list ${assignedUserDetails.length - (max - 1) > 9 ? 'not-two-digit' : ''}`}
							>
								{assignedUserDetails.map((item: AssignedUserDetailsProps) => (
									<Avatar
										className={assignedUserDetails.length <= 6 ? 'removeBlue' : ''}
										key={`user-icon-${item.employeeId}`}
										src={item.imagePath || ''}
									>
										{getUserName({
											firstName: item.firstName || '',
											lastName: item.lastName || '',
											fullName: item.fullName || '',
										})}
									</Avatar>
								))}
							</AvatarGroup>
							{totalMembers > 0 && totalMembers > 6 && (
								<Box className={`total-member-count ${totalMembers - (max - 1) > 9 ? 'not-two-digit' : ''}`}>
									+{totalMembers - (max - 1)}
								</Box>
							)}
						</>
					) : (
						<Avatar className='user-avatar user-avatar-placeholder' key={`user-icon`}>
							<AvtarPlaceholderIcon />
						</Avatar>
					)}
					{assignedUserDetails && (
						<AssignedUserListPopover
							{...props}
							open={open}
							setOpen={setOpen}
							anchorEl={anchorEl}
							setAnchorEl={setAnchorEl}
							assignedUserDetails={assignedUserDetails}
							t={t}
						/>
					)}
				</Box>
				<OKRButton
					id='add-member'
					className='add-btn2'
					icon={<AddIconSvg />}
					handleClick={handleAddUserClick}
					disabled={isDisabled}
				/>
				<Typography variant='subtitle1'>
					<em>{subTitle || t('addMembersLabel')}</em>
				</Typography>
			</Box>
			<Box className='help-text'>
				<Typography className='short-des-text'>{helpText || t('assigneUserHelpText')}</Typography>
			</Box>
		</Box>
	);
};
