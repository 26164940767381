import React, { Fragment } from 'react';
import { Card, CardHeader, Avatar, CardContent, Typography, Menu, MenuItem } from '@material-ui/core';
import { getUserName, getFullName } from '../../../config/utils';
import { ALIGNMENT_MAP, USER_VIEW } from '../../../config/app-url';
import { setLocalStorageItem } from '../../../services/StorageService';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OkrProgressView = (props) => {
	const { contributorDetails, handleMenuClickHandler, handlePeopleRequestOneOnOne } = props;
	const history = useHistory();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuClick = (e, actionName) => {
		handleClose();
		handleMenuClickHandler && handleMenuClickHandler();
		if (actionName !== 3) {
			changeRoute(e, contributorDetails, actionName);
		} else {
			handlePeopleRequestOneOnOne(e, 'okr', contributorDetails, contributorDetails);
		}
	};

	const changeRoute = (e, option, type) => {
		e.preventDefault();
		e.stopPropagation();
		setLocalStorageItem('currentUser', JSON.stringify(option));
		if (type === 1) {
			props.resetAlignment();
			props.updatePrevRoute(props.currentRoute);
			history.push(ALIGNMENT_MAP);
			props.updateRoute(ALIGNMENT_MAP);
		} else if (type === 2) {
			props.resetOkr();
			props.updatePrevRoute(props.currentRoute);
			history.push(USER_VIEW);
			props.updateRoute(USER_VIEW);
		}
	};
	const { t } = useTranslation();
	return (
		<Fragment>
			<Card className='custom-node-card'>
				<CardHeader
					avatar={
						contributorDetails.imagePath ? (
							<Avatar
								alignItems='flex-start'
								aria-label='user-image'
								src={contributorDetails.imagePath}
								className='user-img'
							/>
						) : (
							<Avatar>{getUserName(contributorDetails)}</Avatar>
						)
					}
					action={
						<Fragment>
							<Menu id='menu-list-grow' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
								<MenuItem onClick={(e) => handleMenuClick(e, 1)}>{t('viewGoalsMap')}</MenuItem>
								<MenuItem onClick={(e) => handleMenuClick(e, 2)}>{t('ViewProfile')}</MenuItem>
								<MenuItem onClick={(e) => handleMenuClick(e, 3)}>{t('requestOnetoOne')}</MenuItem>
							</Menu>
						</Fragment>
					}
					title={<Typography>{getFullName(contributorDetails)}</Typography>}
					subheader={
						<Fragment>
							<Typography variant='body2'>{contributorDetails.designation}</Typography>
							<Typography variant='body2'>{contributorDetails.department}</Typography>
						</Fragment>
					}
				/>
				<CardContent>
					<div>{contributorDetails.keyDescription || ''}</div>
				</CardContent>
			</Card>
		</Fragment>
	);
};

export default OkrProgressView;
