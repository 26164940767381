import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { UpdateFeatureList } from '../UpdateFeatureList';
import { Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { AddIconSvg } from '../../../config/svg/CommonSvg';
import { AddNewFeaturePopup } from './AddNewFeaturePopup';
import { DownArrowIcon } from '../../../config/svg/ArrowSvg';
import { DropDown } from '../Dropdown';
import { DeleteIcon, EditIcon } from '../../../config/svg/CommonSvgIcon';
import { AddNewVersionPopup } from '../AddNewVersionPopup';
import {
	deleteVersionDetail,
	emailSent,
	getAllVersion,
	getAllVersionForUser,
	getVersionDetailsForPagination,
	notifyVersion,
	publishVersion,
	userVersionCompleted,
	userVersionPageDetails,
} from '../../../action/okrUpdate';
import AlertDialog from '../../Common/Dialog';
import { getMonthDateYear } from '../../../config/utils';
import { Enums } from '../../../config/enums';

export const Update: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { isUserLoggedIn } = props;
	const dispatch = useDispatch();
	const { allUserVersionDetails, allVersionFeatureDetails, allVersionPageDetails } = useSelector(
		(state: any) => state.okrUpdateReducer
	);

	const [selectedVersion, setSelectedVersion] = useState<any>();
	// const [open, setOpen] = useState<boolean>(false);
	const [featureModule, setFeatureModule] = useState<any>({});
	const [openDropdown, setOpenDropdown] = useState<boolean>(false);
	const [showDialog, setShowDialog] = useState<any>({ open: false, type: '', message: '' });
	const [pageIndex, setPageIndex] = useState<any>(1);
	const [imageList, setImageList] = useState<any[]>([]);
	useEffect(() => {
		callGetAllVersionDetails(pageIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (allUserVersionDetails && allUserVersionDetails.length) {
			setSelectedVersion(allUserVersionDetails[0]);
			getSelectedVersionFeatureList(allUserVersionDetails[0]);
		} else {
			setSelectedVersion(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allUserVersionDetails]);

	const loadMoreUser = async () => {
		const newPageIndex = pageIndex + 1;
		const reqPostPayload = {
			publishType: isUserLoggedIn ? 1 : 2,
			pageIndex: newPageIndex,
			pageSize: Enums.TEN,
		};
		const response: any = await dispatch(getVersionDetailsForPagination(reqPostPayload));
		if (response?.data?.isSuccess) {
			const { entity } = response.data || {};
			entity && dispatch(userVersionPageDetails(entity));
			entity?.records && dispatch(userVersionCompleted(entity?.records));
		}

		setPageIndex(newPageIndex);
	};

	const handleOpen = () => {
		setFeatureModule({ open: true, type: 'Add' });
	};

	const handleClose = () => {
		setFeatureModule({ open: false });
	};

	const callGetAllVersionDetails = (currentPageIndex: any) => {
		const reqPostPayload = {
			publishType: isUserLoggedIn ? 1 : 2,
			pageIndex: currentPageIndex,
			pageSize: Enums.TEN,
		};
		dispatch(getAllVersionForUser(reqPostPayload));
	};

	const getSelectedVersionFeatureDetails = (requestPayload: any) => {
		dispatch(getAllVersion(requestPayload));
	};

	const getSelectedVersionFeatureList = (item: any) => {
		const requestPayload = `?versionId=${item?.versionId}&featureListType=1`;
		getSelectedVersionFeatureDetails(requestPayload);
	};

	const handleVersionChange = (item: any) => {
		if (item?.versionId) {
			getSelectedVersionFeatureList(item);
			setSelectedVersion(item);
			setOpenDropdown(false);
		}
	};

	const handleVersionDelete = () => {
		setShowDialog({ open: true, type: 'delete', message: t('versionDeleteText') });
	};

	const handleCloseModal = async (event: any, type: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			if (showDialog?.type === 'delete') {
				if (selectedVersion && selectedVersion?.versionId) {
					setShowDialog({ open: false, type: ' ', message: '' });
					const queryParam = `?versionId=${selectedVersion?.versionId}`;
					const response: any = await dispatch(deleteVersionDetail(queryParam));
					if (response && response.data && response.data.isSuccess) {
						enqueueSnackbar(t('versionDeleteSuccessMsg'), {
							variant: 'success',
							autoHideDuration: 5000,
						});
						callGetAllVersionDetails(1);
						setPageIndex(1);
					} else {
						const { messageList } = response?.data || {};
						enqueueSnackbar(messageList?.featureId, {
							variant: 'error',
							autoHideDuration: 5000,
						});
					}
					setShowDialog({ open: false, type: ' ', message: '' });
				} else {
					//
				}
			} else if (showDialog?.type === 'PublishUnPublish') {
				handleVersionPublishUnPublishClick();
				setShowDialog({ open: false, type: ' ', message: '' });
			} else if (showDialog?.type === 'NotifyUser') {
				handleNotifyUserClick();
				setShowDialog({ open: false, type: ' ', message: '' });
			} else if (showDialog?.type === 'SendEmail') {
				setSelectedVersion({ ...selectedVersion, isMailSent: true });
				handleSendEmailClick();
				setShowDialog({ open: false, type: ' ', message: '' });
			}
		} else {
			setShowDialog({ open: false, type: ' ', message: '' });
		}
	};

	const editUpdateFeature = (record: any) => {
		// console.log(record);
		setFeatureModule({ open: true, type: 'Edit', data: record });
	};

	const handleVersionPublishUnPublishClick = async () => {
		const queryParam = `?versionId=${
			selectedVersion?.versionId
		}&isPublish=${!selectedVersion?.isPublished}&isNotify=false&isMailSent=false`;
		const response: any = await dispatch(publishVersion(queryParam));
		if (response && response.data && response.data.isSuccess) {
			enqueueSnackbar(!selectedVersion?.isPublished ? t('publishVersion') : t('unPublishVersion'), {
				variant: 'success',
				autoHideDuration: 5000,
			});
			callGetAllVersionDetails(1);
			setPageIndex(1);
			// setSelectedVersion({ ...selectedVersion, isPublished: !selectedVersion?.isPublished });
			// const allVersionDetails = allUserVersionDetails && allUserVersionDetails.length ? [...allUserVersionDetails] : [];
			// allVersionDetails.map((item) => {
			// 	if (item?.versionId === selectedVersion?.versionId) {
			// 		item.isPublished = !selectedVersion?.isPublished;
			// 		item.publishedOn = new Date();
			// 	}
			// 	return item;
			// });
			// dispatch(userVersionCompleted(allVersionDetails));
		} else {
			const { messageList } = response?.data || {};
			enqueueSnackbar(messageList?.versionId, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleNotifyUserClick = async () => {
		const queryParam = `?versionId=${selectedVersion?.versionId}`;
		const response: any = await dispatch(notifyVersion(queryParam));
		if (response && response.data && response.data.isSuccess) {
			enqueueSnackbar(t('notifyUsersSuccess'), {
				variant: 'success',
				autoHideDuration: 5000,
			});
			callGetAllVersionDetails(1);
			setPageIndex(1);
		} else {
			const { messageList } = response?.data || {};
			enqueueSnackbar(messageList?.featureId, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleSendEmailClick = async () => {
		const queryParam = { versionId: selectedVersion?.versionId };
		const requestData = `?versionId=${selectedVersion?.versionId}`;
		const response: any = await dispatch(emailSent(queryParam, requestData));
		if (response && response.data && response.data.isSuccess) {
			enqueueSnackbar(t('sendEmailSuccess'), {
				variant: 'success',
				autoHideDuration: 5000,
			});
			callGetAllVersionDetails(1);
			setPageIndex(1);
		} else {
			const { message } = response?.data || {};
			enqueueSnackbar(message || 'Some Error Occurred!', {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	return (
		<>
			<Box className='whats-new-version'>
				<Box className='version-left'>
					<Box className='select-dropdown version-dropdown'>
						{allUserVersionDetails && allUserVersionDetails.length ? (
							<DropDown
								{...props}
								buttonIcon={<DownArrowIcon />}
								buttonText={
									selectedVersion?.version
										? `Version ${
												selectedVersion?.version.length > 10
													? selectedVersion?.version.substring(0, 10) + '...'
													: selectedVersion?.version || ''
										  }`
										: 'Select Type'
								}
								setOpenDropdown={setOpenDropdown}
								openDropdown={openDropdown}
							>
								<List>
									{allUserVersionDetails && allUserVersionDetails.length ? (
										allUserVersionDetails.map((item: any) => (
											<ListItem
												className={item?.versionId === selectedVersion?.versionId ? 'active' : ''}
												id={`item.versionId`}
												key={item.versionId}
												onClick={() => handleVersionChange(item)}
											>
												<Box className='version-title'>
													<Tooltip arrow title={`Version ${item?.version}`}>
														<Typography variant='h5'>Version {item?.version}</Typography>
													</Tooltip>
												</Box>
												{item?.publishedOn && item?.isPublished ? (
													<Typography variant='subtitle2'>Published: {getMonthDateYear(item?.publishedOn)}</Typography>
												) : (
													<Typography variant='subtitle2'>Unpublished</Typography>
												)}
											</ListItem>
										))
									) : (
										<></>
									)}
									{allVersionPageDetails && allVersionPageDetails?.totalPages !== pageIndex && isUserLoggedIn && (
										<Box className='alignCenter' display={'flex'} justifyContent={'center'}>
											<OKRButton className='btn-link' handleClick={loadMoreUser} text={'Load More...'}></OKRButton>
										</Box>
									)}
								</List>
							</DropDown>
						) : (
							<></>
						)}
					</Box>

					{isUserLoggedIn && selectedVersion ? (
						<AddNewVersionPopup
							buttonIcon={<EditIcon />}
							buttonClassName={'version-edit-icon btn-circle-white'}
							buttonTitle={'editSelectedVersion'}
							LabelText={'updateVersionName'}
							placeHolderText={'updateVersionName'}
							type={'Edit'}
							selectedVersion={selectedVersion}
						/>
					) : (
						<></>
					)}
					{isUserLoggedIn && selectedVersion && !selectedVersion?.isPublished ? (
						<OKRButton
							className='version-del-icon btn-circle-white'
							icon={<DeleteIcon />}
							title={t('deleteSelectedVersion')}
							id={`del-icon`}
							handleClick={handleVersionDelete}
						/>
					) : (
						<></>
					)}
				</Box>
				{isUserLoggedIn && selectedVersion ? (
					<Box className='version-right'>
						{allVersionFeatureDetails && allVersionFeatureDetails.length ? (
							<>
								<OKRButton
									className='btn-primary-white'
									text={!selectedVersion?.isPublished ? t('publishBtnText') : t('unPublishBtnText')}
									title={!selectedVersion?.isPublished ? t('publishBtnText') : t('unPublishBtnText')}
									handleClick={() =>
										setShowDialog({
											open: true,
											type: 'PublishUnPublish',
											message: !selectedVersion?.isPublished ? t('publishPopup') : t('unpublishPopup'),
										})
									}
								></OKRButton>

								{selectedVersion?.isPublished ? (
									<OKRButton
										className='btn-primary-white'
										text={t('notifyUsers')}
										title={t('notifyUsers')}
										disabled={selectedVersion?.isNotify}
										handleClick={handleNotifyUserClick}
									></OKRButton>
								) : (
									<></>
								)}
								{selectedVersion?.isPublished ? (
									<OKRButton
										className='btn-primary-white'
										text={t('sendEmail')}
										title={t('sendEmail')}
										disabled={selectedVersion?.isMailSent}
										handleClick={() => setShowDialog({ open: true, type: 'SendEmail', message: t('sendEmailPopup') })}
										// handleClick={handleOpen}
									></OKRButton>
								) : (
									<></>
								)}
							</>
						) : (
							<></>
						)}
						<OKRButton
							className='btn-primary'
							icon={<AddIconSvg />}
							text={t('addFeature')}
							title={t('addNewItem')}
							handleClick={handleOpen}
						></OKRButton>
					</Box>
				) : (
					<></>
				)}
			</Box>
			<UpdateFeatureList
				isUserLoggedIn={isUserLoggedIn}
				selectedVersion={selectedVersion}
				getSelectedVersionFeatureList={getSelectedVersionFeatureList}
				editUpdateFeature={editUpdateFeature}
			/>
			{isUserLoggedIn && selectedVersion ? (
				<AddNewFeaturePopup
					{...props}
					open={featureModule?.open || false}
					handleClose={handleClose}
					handleChangeValue={() => {}}
					selectedVersion={selectedVersion}
					getSelectedVersionFeatureList={getSelectedVersionFeatureList}
					type={featureModule?.type || 'Add'}
					editableData={featureModule?.data || {}}
					tabType={'Updates'}
					setImageList={setImageList}
					imageList={imageList}
				/>
			) : (
				<></>
			)}
			{showDialog?.open && (
				<AlertDialog
					message={showDialog?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={showDialog.open || false}
					isCancel={false}
				/>
			)}
		</>
	);
};
