import { Avatar, Box, Link, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import '../../../styles/pages/weekly-checkins/weeklyCheckins.scss';
import GlobalSearch from '../../Common/GlobalSearch';
import { MembersList } from './MembersList';
import { QuesAns } from './QuesAns';
import { getUserName } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { WeekRangeTabPanel, WeekRangeTabs } from './WeekRangeTabsScrollar';
import { useCallback } from 'react';

export const WeeklyCheckIns: React.FC<any> = (props) => {
	const { userDetail, checkInResult, showApiMsgs } = props;
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = React.useState<Number>(0);
	const [checkInDates, setCheckInDates] = React.useState<any>([]);
	const [defaultCheckInData, setDefaultCheckInData] = React.useState<any>([]);
	const [checkInData, setCheckInData] = useState<any>([]);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

	const handleTabChange = (event: any, newValue: Number) => {
		if (newValue !== tabSelected) {
			setCheckInData([]);
		}
		setTabSelected(newValue);
		setIsEdit(false);
	};

	const [selectedUser, setSelectedUser] = useState<any>({});
	const [currentDate, setCurrentDate] = useState<any>({});
	const [selectedDate, setSelectedDate] = useState<any>({});
	const [directReportees, setDirectReportees] = useState<any>([]);
	const [directReporteesLoaded, setDirectReporteesLoaded] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [viewToShow, setViewToShow] = useState<string>('');
	const [isDataSaving, setIsDataSaving] = useState<boolean>(false);
	const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

	useEffect(() => {
		setSelectedUser(userDetail);
	}, []);

	useEffect(() => {
		if (selectedUser.employeeId) {
			getCheckInDates();
			fetchDirectReportees();
			// if(!directReporteesLoaded){
			// }
		}
	}, [selectedUser]);

	useEffect(() => {
		if (selectedDate?.startDate) {
			if (selectedDate.checkInStatus === Enums.CHECKED_IN_MISSED && checkIfNotLoggedInUser()) {
				setViewToShow('missed');
				props.showLoader(false);
			} else if (selectedDate.checkInStatus === Enums.NOT_YET_CHECKED_IN && checkIfNotLoggedInUser()) {
				setViewToShow('noYetChecked');
				props.showLoader(false);
			} else {
				props.showLoader(true);
				setViewToShow('');
				fetchCheckInData();
			}
		}
	}, [selectedDate]);

	const handleSelectedReportee = (reporteeData: any) => {
		let directReporteesData = reporteeData.map((item: any) => {
			if (item.employeeId === selectedUser.employeeId && selectedUser.employeeId !== userDetail.employeeId) {
				item.isSelected = true;
			} else {
				item.isSelected = false;
			}
			return item;
		});
		return directReporteesData;
	};

	const fetchDirectReportees = async () => {
		setIsDataLoaded(false);
		setCheckInData([]);
		let directEmployeeRes = await props.getUserDirectEmployees(userDetail.employeeId);
		if (directEmployeeRes.data.status === Enums.STATUS_SUCCESS) {
			setIsDataLoaded(() => true);
			setDirectReporteesLoaded(true);
			if (directEmployeeRes.data.entityList.length) {
				setDirectReportees(handleSelectedReportee(directEmployeeRes.data.entityList));
			}
		}
	};

	const fetchCheckInData = async () => {
		let data = `empId=${selectedUser.employeeId}&startDate=${selectedDate.startDate}&endDate=${selectedDate.endDate}`;
		let checkInDataResponse = await props.getCheckInData(data);
		setIsDataLoaded(() => false);
		setCheckInData([]);
		if (checkInDataResponse.data.status === Enums.STATUS_SUCCESS) {
			let data = checkInDataResponse.data.entityList;
			let updateResponse = data.map((item: any) => {
				return {
					...item,
					historyText: item.checkInDetails,
					employeeId: selectedUser.employeeId,
				};
			});
			setDefaultCheckInData(data)
			setCheckInData(updateResponse);
			props.showLoader(false);
			setIsDataLoaded(true);
			if (selectedDate.checkInStatus !== Enums.STATUS_CHECKED_ID) {
				setIsDataSaving(false);
			}
		}
	};

	const getCheckInDates = async () => {
		let empId = `empId=${selectedUser.employeeId}`;
		props.showLoader(true);
		let response = await props.getCheckInWeeklyDates(empId);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			if (response.data.entityList.length) {
				let reverseDates: any = response.data.entityList.reverse();
				let getLastDate;
				if (isFirstLoad) {
					getLastDate = reverseDates.slice(-1)[0];
					setTabSelected(reverseDates.length - 1);
					setIsFirstLoad(false);
					setSelectedDate(getLastDate);
					setCurrentDate(getLastDate);
				} else {
					getLastDate = reverseDates.slice(tabSelected)[0];
					setSelectedDate(getLastDate);
					setCurrentDate(getLastDate);
				}
				props.showLoader(false);
				setCheckInDates(reverseDates);
			}
		}
	};

	const handleSelectDate = (e: React.ChangeEvent<HTMLInputElement>, checkInDate: any) => {
		e.preventDefault();
		setSelectedDate(checkInDate);
	};

	const handleQuestionChange = (e: any, checkInQuestion: any, index: number) => {
		let checkInDataClone = [...checkInData];
		checkInDataClone[index].checkInDetails = e;
		// checkInDataClone[index].isEdited = true;
		setCheckInData(checkInDataClone);
	};

	const handleCheckInUpdate = async (e: any) => {
		setIsDataSaving(true);
		let setCheckInDataCopy = [...checkInData];
		let findBlankField = setCheckInDataCopy.filter(
			(item: any) =>
				!item.checkInDetails ||
				item.checkInDetails?.trim() === '' ||
				item.checkInDetails?.trim() === '<p></p>' ||
				item.checkInDetails?.trim() === '<p><br></p>'
		).length;

		setCheckInDataCopy = setCheckInDataCopy.map((item: any) => {
			return {
				...item,
				checkInDetails: item.checkInDetails === null ? '' : item.checkInDetails,
				checkInDate: selectedDate.startDate,
			};
		});

		if (findBlankField === checkInData.length) {
			showApiMsgs(t('leastFieldError'), 'error');
			setIsDataSaving(false);
		} else {
			let submitCheckIn = await props.updateCheckInData(setCheckInDataCopy);
			if (submitCheckIn.data.status === Enums.STATUS_SUCCESS) {
				handleAPImessages(submitCheckIn.data.messageList, 'success');
				props.isCheckinSubmitted();
				getCheckInDates();
				setIsEdit(false);
				// setIsDataSaving(false);
			}
		}
	};

	const handleAPImessages = (messageList: any, variant: any) => {
		const responseAPI = messageList;
		const keys = Object.keys(responseAPI);
		const messages = keys.map((item) => responseAPI[item]);
		showApiMsgs(`${messages} `, variant);
	};

	const checkIfNotLoggedInUser = () => {
		if (selectedUser.employeeId !== userDetail.employeeId) {
			return true;
		}
		return false;
	};

	const onSelectedUser = (user: any, isDirectReportee?: boolean) => {
		if (isDirectReportee) {
			setSelectedUser(user);
			window.scroll({ top: 0, behavior: 'smooth' });
			setIsFirstLoad(true);
		} else {
			setSelectedUser(user[0]);
			setIsFirstLoad(true);
		}
		setIsEdit(false);
	};

	const handleGoBack = useCallback(() => {
		setIsDataLoaded(false);
		setSelectedUser(userDetail);
		setIsFirstLoad(true);
	}, [selectedUser]);

	const canCheckInEdit = () => {
		if (selectedUser.employeeId === userDetail.employeeId) {
			return true;
		}
		return false;
	};

	const showQuestionHeading = () => {
		if (currentDate.startDate === selectedDate.startDate) {
			return true;
		}
		return false;
	};

	const editCheckIn = useCallback(() => {
		setIsDataSaving(false);
		setIsEdit((prev) => !prev);
	}, [isEdit]);

	return (
		<Box className='weekly-check-ins-wrap'>
			<Box className='check-ins-head'>
				<Typography variant='h2'>{t('weeklyCheckInsHead')}</Typography>
				<Typography variant='h5' className={props.checkInResult?.isAlert ? 'red' : ''}>
					{checkInResult.remainingDays}
				</Typography>
			</Box>
			<Box className='check-ins-content'>
				<Box className='col-two-third'>
					<Box className='check-ins-content-head'>
						<Box className='user-info'>
							<Box className='user-img'>
								{selectedUser && selectedUser.imagePath && selectedUser.imagePath.trim() !== '' ? (
									<Avatar className='user-avatar' key={`user-avtar`} src={selectedUser.imagePath} />
								) : selectedUser && selectedUser.firstName ? (
									<Avatar className='user-avatar' key={`user-avtar`}>
										{getUserName(selectedUser)}
									</Avatar>
								) : (
									<Avatar className='user-avatar' key={`user-avtar`}></Avatar>
								)}
							</Box>
							<Box className='user-info-details'>
								{checkIfNotLoggedInUser() && (
									<Box className='back-to-main'>
										<Typography variant='body2'>
											<Link onClick={handleGoBack} className='back-to-main-link'>
												<BackArrow /> {t('back')}
											</Link>
											{t('watchingCheckins', { name: `${selectedUser.firstName} ${selectedUser.lastName}` })}
										</Typography>
									</Box>
								)}
								<Typography variant='h4'>
									{selectedUser.firstName} {selectedUser.lastName}
								</Typography>
								<Typography variant='subtitle2'>{selectedUser.designation}</Typography>
								<WeekRangeTabs
									{...props}
									tabSelected={tabSelected}
									handleTabChange={handleTabChange}
									checkInDates={checkInDates}
									handleSelectDate={handleSelectDate}
								/>
							</Box>
						</Box>
					</Box>
					<WeekRangeTabPanel>
						{isDataLoaded ? (
							<QuesAns
								checkInData={checkInData}
								handleQuestionChange={handleQuestionChange}
								handleCheckInUpdate={handleCheckInUpdate}
								currentDate={currentDate}
								viewToShow={viewToShow}
								canCheckInEdit={canCheckInEdit}
								showQuestionHeading={showQuestionHeading}
								editCheckIn={editCheckIn}
								isEdit={isEdit}
								selectedUser={selectedUser}
								isDataSaving={isDataSaving}
								selectedDate={selectedDate}
								setIsDataSaving={setIsDataSaving}
								defaultCheckInData={defaultCheckInData}
							/>
						) : (
							<Box className='loading-center'>
								<Typography>{t('loading')}</Typography>
							</Box>
						)}
					</WeekRangeTabPanel>
				</Box>
				<Box className='col-one-third'>
					<GlobalSearch
						{...props}
						module={'myGoal'}
						fetchCycleUsers={true}
						customClass={'user-quick-search-type2'}
						placeHolderText={t('searchPeopleText')}
						onSelectedUser={onSelectedUser}
						customId={'weekly-checkins-src'}
					/>
					<MembersList
						{...props}
						directReporteesLoaded={directReporteesLoaded}
						directReportees={directReportees}
						getUserName={getUserName}
						handleUserClick={onSelectedUser}
					/>
				</Box>
			</Box>
		</Box>
	);
};
