import * as actionTypes from './actionTypes';
import {
	getCycleDetailsApi,
	getPreviousCycleDetailsApi,
	getUsersByIdAPI,
	getGuidedTourDataApi,
	getGuidedTourActivityDataApi,
	isFederationUserApi,
	getTenatListApi,
	deleteInstanceApi,
	updateInstanceLicenseAPI,
	convertFederatedAPI,
	getModuleListApi,
	moveSSOToDbAPI,
	updateForceLogoutAPI,
	clientEngagementReportApi,
	getDefaultHomeScreenListApi,
} from '../services/CommonService';
import { setLocalStorageItem } from '../services/StorageService';

/**
 * renders loader on screen on update of props
 * @param {*} showLoaderStatus
 */
export const showLoader = (showLoaderStatus) => {
	return {
		type: actionTypes.SHOW_LOADER,
		payload: showLoaderStatus,
	};
};

/**
 * Update Route
 * @param {*} route
 */
export const updateRoute = (route) => {
	setLocalStorageItem('currentRoute', route);
	return {
		type: actionTypes.UPDATE_ROUTE,
		payload: route,
	};
};

/**
 * Update Route
 * @param {*} route
 */
export const updatePrevRoute = (route) => {
	return {
		type: actionTypes.UPDATE_PREV_ROUTE,
		payload: route,
	};
};

/**
 * Update Page
 * @param {*} route
 */
export const updatePage = (page) => {
	return {
		type: actionTypes.UPDATE_PAGE,
		payload: page,
	};
};

export const getCycleDetails = (id) => {
	return () => {
		return getCycleDetailsApi(id);
	};
};

export const getPreviousCycleDetails = (id) => {
	return () => {
		return getPreviousCycleDetailsApi(id);
	};
};

export const getUserById = (data) => {
	return () => {
		return getUsersByIdAPI(data);
	};
};

export const showHideSnackbar = (data) => {
	return { type: actionTypes.SHOW_HIDE_SNACKBAR, payload: data };
};

export const updateGoalType = (data) => {
	return {
		type: actionTypes.UPDATE_GOAT_TYPE,
		payload: data,
	};
};

/**current dashboard tab  */
export const currentDashboardTab = (currentTab) => {
	return {
		type: actionTypes.CURRENT_DASHBOARD_TAB,
		currentTab,
	};
};

/**Guided Tour Data */

export const showGuidedTour = (guideTourVisible) => {
	return {
		type: actionTypes.SHOW_GUIDED_TOUR,
		guideTourVisible,
	};
};

export const userClosedGuidedTour = (isClosedByUser) => {
	return {
		type: actionTypes.USER_CLOSED_GUIDED_TOUR,
		isClosedByUser,
	};
};

const fetchGuidedTourDataPending = () => ({
	type: actionTypes.FETCH_GUIDED_DATA_PENDING,
});
const fetchGuidedTourDataCompleted = (payload) => ({
	type: actionTypes.FETCH_GUIDED_DATA_SUCCESS,
	payload,
});
const fetchGuidedTourDataFailed = (error) => ({
	type: actionTypes.FETCH_GUIDED_DATA_FAILED,
	error,
});

export const getGuidedTourData = () => {
	return (dispatch) => {
		dispatch(fetchGuidedTourDataPending());
		return getGuidedTourDataApi()
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchGuidedTourDataCompleted(response.data));
				} else {
					dispatch(fetchGuidedTourDataFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchGuidedTourDataFailed(error));
			});
	};
};

export const getGuidedTourActivity = (data) => {
	return () => {
		return getGuidedTourActivityDataApi(data);
	};
};

//change cycle based on notification

export const changeCycleId = (data) => {
	return {
		type: actionTypes.CHANGE_CYCLE,
		data,
	};
};

const isFederationUserPending = () => ({
	type: actionTypes.IS_FEDERATION_USER_PENDING,
});
const isFederationUserCompleted = (payload) => ({
	type: actionTypes.IS_FEDERATION_USER_SUCCESS,
	payload,
});
const isFederationUserFailed = (error) => ({
	type: actionTypes.IS_FEDERATION_USER_FAILED,
	error,
});
export const isFederationUser = (emailId) => {
	return (dispatch) => {
		dispatch(isFederationUserPending());
		return isFederationUserApi(emailId)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isFederationUserCompleted(response.data));
				} else {
					dispatch(isFederationUserFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(isFederationUserFailed(error));
			});
	};
};

export const signalRConnectionStatus = (payload) => ({
	type: actionTypes.SIGNAL_R_CONNECTION_STATUS,
	payload,
});

export const updateCurrentCycleId = (payload) => ({
	type: actionTypes.CURRENT_ORG_CYCLE_ID,
	payload,
});

export const currentCycleIdObject = (payload) => ({
	type: actionTypes.CYCLE_ID_DETAILS,
	payload,
});

export const getUserSelectedCycle = (payload) => ({
	type: actionTypes.USER_SELECTED_CYCLE,
	payload,
});

export const teamCycleDetails = (payload) => ({
	type: actionTypes.TEAM_CYCLE_DETAILS,
	payload,
});

export const showIdleStatePopup = (payload) => ({
	type: actionTypes.IDLE_STATE_SESSION_EXPIRE,
	payload,
});

export const isImpersonated = (payload) => ({
	type: actionTypes.IS_PERSONATED_USER,
	payload,
});

export const getTenantList = (data) => {
	return () => {
		return getTenatListApi(data);
	};
};

export const deleteInstance = (data) => {
	return () => {
		return deleteInstanceApi(data);
	};
};

export const updateInstanceData = (data) => {
	return () => {
		return updateInstanceLicenseAPI(data);
	};
};

export const convertFederated = (data) => {
	return () => {
		return convertFederatedAPI(data);
	};
};

export const getModuleList = (data) => {
	return () => {
		return getModuleListApi(data);
	};
};

export const moveSSOToDb = (data) => {
	return () => {
		return moveSSOToDbAPI(data);
	};
};

export const updateForceLogout = (data) => {
	return () => {
		return updateForceLogoutAPI(data);
	};
};

export const clientEngagementReport = (data) => {
	return () => {
		return clientEngagementReportApi(data);
	};
};

export const getDefaultHomeScreenList = (data) => {
	return () => {
		return getDefaultHomeScreenListApi(data);
	};
};
