import * as actionTypes from './actionTypes';
import {
	getObjectiveListApi,
	addGoalsAPI,
	deleteGoalsAPI,
	updateKrProgressAPI,
	updateGoalsAPI,
	deleteContributorAPI,
	unlockRequestAPI,
	alignGoalsAPI,
	getOkrStatusListApi,
	getEmployeeViewGoalsApi,
	dashboardMyGoalAPI,
	checkAlignStatusAPI,
	downloadPdfAPI,
	fetchQuarterReportAPI,
	fetchStatusReportAPI,
	fetchProgressReportAPI,
	fetchFeedbackReportAPI,
	getGoalsDetailsAPI,
	updateSequenceAPI,
	getOkrMasterDataApi,
	getKrContributorsApi,
	updateGoalAndKrNameApi,
	updateGoalAttributesApi,
	updateKrAttributesApi,
	acceptKrApi,
	updateKrProgressValueApi,
	becomeContributorApi,
	getKrDetailAPI,
	getOkrDetailAPI,
	getUserTeamsDataApi,
	getOwnerTeamsDataApi,
	getMyTeamDetailsAPI,
	updateNudgeTeamsAPI,
	updateNudgePersonAPI,
	getMyTeamOKRDetailsAPI,
	resetObjectiveApi,
	getLinkOwnerDataApi,
	getLinkObjectivedDataApi,
	changeOwnerDataApi,
	updateProgressApi,
	getMyDeltaScoreAPI,
	recentContributorAPI,
	virtualAlignmentAPI,
	archieveAPI,
	dashboardProgressAPI,
	teamOkrProgressAPI,
	getObjectiveDetailsAPI,
	getTaskAPI,
	deleteTaskAPI,
	updateTaskAPI,
	postTaskAPI,
	taskCompletedAPI,
	postProgressConversationAPI,
	getNoteByIdAPI,
	getConversationByIdAPI,
	myTeamGoalAPI,
	checkInStatus,
	checkInDates,
	checkInData,
	postCheckInAPI,
	getReportingEmployeeDataAPI,
	updateConfidenceApi,
	getWeeklyTrendDetailsAPI,
	getConfidenceAlertAPI,
	freeTrialApi,
	freeTrialApiEmailId,
	freeTrialApiDomain,
	freeTrialApiPassword,
	peopleViewFeedbackAPI,
	getOkrImportApi,
	getOkrImportCategoryApi,
	postOkrImportApi,
} from '../services/OkrService';
import { showLoader } from './common';

const fetchOkrPending = () => ({
	type: actionTypes.FETCH_OKR_PENDING,
});
const fetchOkrCompleted = (payload) => ({
	type: actionTypes.FETCH_OKR_SUCCESS,
	payload,
});
const fetchOkrFailed = (error) => ({
	type: actionTypes.FETCH_OKR_FAILED,
	error,
});

//Fetch delta score
const fetchDeltaScorePending = () => ({
	type: actionTypes.FETCH_DELTA_SCORE_PENDING,
});
const fetchDeltaScoreCompleted = (payload) => ({
	type: actionTypes.FETCH_DELTA_SCORE_SUCCESS,
	payload,
});
const fetchDeltaScoreFailed = (error) => ({
	type: actionTypes.FETCH_DELTA_SCORE_FAILED,
	error,
});

//Fetch recent contributor score
const fetchRecentContributionPending = () => ({
	type: actionTypes.FETCH_RECENT_CONTRIBUTION_PENDING,
});
const fetchRecentContributionCompleted = (payload) => ({
	type: actionTypes.FETCH_RECENT_CONTRIBUTION__SUCCESS,
	payload,
});
const fetchRecentContributionFailed = (error) => ({
	type: actionTypes.FETCH_RECENT_CONTRIBUTION__FAILED,
	error,
});

export const getObjectiveList = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchOkrPending());
		return getObjectiveListApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchOkrCompleted(response.data));
				} else {
					dispatch(fetchOkrFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchOkrFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const getDashboardDetail = (data, isLoader) => {
	return (dispatch) => {
		if (isLoader !== false) {
			dispatch(showLoader(true));
		}
		dispatch(fetchOkrPending());
		return dashboardMyGoalAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchOkrCompleted(response.data));
				} else {
					dispatch(fetchOkrFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchOkrFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const getRecentContributordDetail = (data, isLoader) => {
	return (dispatch) => {
		if (isLoader !== false) {
			dispatch(showLoader(true));
		}
		dispatch(fetchRecentContributionPending());
		return recentContributorAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchRecentContributionCompleted(response.data));
				} else {
					dispatch(fetchRecentContributionFailed('error'));
				}
				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchRecentContributionFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const getDeltaScore = (data, isLoader) => {
	return (dispatch) => {
		if (isLoader !== false) {
			dispatch(showLoader(true));
		}
		dispatch(fetchDeltaScorePending());
		return getMyDeltaScoreAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchDeltaScoreCompleted(response.data));
				} else {
					dispatch(fetchDeltaScoreFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchDeltaScoreFailed(error));
				dispatch(showLoader(false));
			});
	};
};

export const getEmployeeViewGoals = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchOkrPending());
		return getEmployeeViewGoalsApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchOkrCompleted(response.data));
				} else {
					dispatch(fetchOkrFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchOkrFailed(error));
				dispatch(showLoader(false));
			});
	};
};

const fetchAlignOkrPending = () => ({
	type: actionTypes.FETCH_ALIGN_OKR_PENDING,
});
const fetchAlignOkrCompleted = (payload) => ({
	type: actionTypes.FETCH_ALIGN_OKR_SUCCESS,
	payload,
});
const fetchAlignOkrFailed = (error) => ({
	type: actionTypes.FETCH_ALIGN_OKR_FAILED,
	error,
});

export const getAlignObjective = (data) => {
	return (dispatch) => {
		dispatch(fetchAlignOkrPending());
		return alignGoalsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchAlignOkrCompleted(response.data));
				} else {
					dispatch(fetchAlignOkrFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchAlignOkrFailed(error));
			});
	};
};

/*const addGoalsPending = () => ({
	type: actionTypes.ADD_GOALS_PENDING,
});*/
const addGoalsCompleted = (payload) => ({
	type: actionTypes.ADD_GOALS_SUCCESS,
	payload,
});
const addGoalsFailed = (error) => ({
	type: actionTypes.ADD_GOALS_FAILED,
	error,
});

export const addMyGoals = (data) => {
	return (dispatch) => {
		return addGoalsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(addGoalsCompleted(response.data));
				} else {
					dispatch(addGoalsFailed('failed'));
				}
			})
			.catch(() => {
				dispatch(addGoalsFailed('failed'));
			});
	};
};

const alignGoalsPending = () => ({
	type: actionTypes.ALIGN_GOALS_PENDING,
});
const alignGoalsCompleted = (payload) => ({
	type: actionTypes.ALIGN_GOALS_SUCCESS,
	payload,
});
const alignGoalsFailed = (error) => ({
	type: actionTypes.ALIGN_GOALS_FAILED,
	error,
});

export const alignMyGoals = (data) => {
	return (dispatch) => {
		dispatch(alignGoalsPending());
		return addGoalsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(alignGoalsCompleted(response.data));
				} else {
					dispatch(alignGoalsFailed('failed'));
				}
			})
			.catch(() => {
				dispatch(alignGoalsFailed('failed'));
			});
	};
};

const editGoalsPending = () => ({
	type: actionTypes.EDIT_GOALS_PENDING,
});
const editGoalsCompleted = (payload) => ({
	type: actionTypes.EDIT_GOALS_SUCCESS,
	payload,
});
const editGoalsFailed = (error) => ({
	type: actionTypes.EDIT_GOALS_FAILED,
	error,
});

export const editMyGoals = (data) => {
	return (dispatch) => {
		dispatch(editGoalsPending());
		return updateGoalsAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(editGoalsCompleted(response.data));
				} else {
					dispatch(editGoalsFailed('failed'));
				}
			})
			.catch((e) => {
				dispatch(editGoalsFailed('failed'));
			});
	};
};

const updateKrProgressPending = () => ({
	type: actionTypes.UPDATE_KR_PROGRESS_PENDING,
});
const updateKrProgressCompleted = (payload) => ({
	type: actionTypes.UPDATE_KR_PROGRESS_SUCCESS,
	payload,
});
const updateKrProgressFailed = (error) => ({
	type: actionTypes.UPDATE_KR_PROGRESS_FAILED,
	error,
});

export const updateKrProgress = (data) => {
	return (dispatch) => {
		dispatch(updateKrProgressPending());
		return updateKrProgressAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(updateKrProgressCompleted(response.data));
				} else {
					dispatch(updateKrProgressFailed('failed'));
				}
			})
			.catch(() => {
				dispatch(updateKrProgressFailed('failed'));
			});
	};
};

const fetchOkrStatusPending = () => ({
	type: actionTypes.FETCH_OKR_STATUS_PENDING,
});
const fetchOkrStatusCompleted = (payload) => ({
	type: actionTypes.FETCH_OKR_STATUS_SUCCESS,
	payload,
});
const fetchOkrStatusFailed = (error) => ({
	type: actionTypes.FETCH_OKR_STATUS_FAILED,
	error,
});

export const getOkrStatusList = (data) => {
	return (dispatch) => {
		dispatch(fetchOkrStatusPending());
		return getOkrStatusListApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchOkrStatusCompleted(response.data));
				} else {
					dispatch(fetchOkrStatusFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchOkrStatusFailed(error));
			});
	};
};

export const deleteObjective = (data) => {
	return () => {
		return deleteGoalsAPI(data);
	};
};

export const deleteContributor = (data) => {
	return () => {
		return deleteContributorAPI(data);
	};
};

export const unlockRequest = (data) => {
	return () => {
		return unlockRequestAPI(data);
	};
};

export const resetOkr = () => ({
	type: actionTypes.RESET_OKR,
});

export const updateStatus = () => ({
	type: actionTypes.UPDATE_GOAL_STATUS,
});

export const updateGoalDetails = (data) => {
	return () => {
		return updateGoalsAPI(data);
	};
};

export const getGoalsDetails = (data) => {
	return () => {
		return getGoalsDetailsAPI(data);
	};
};

export const getDashboardUpdatedDetails = (data) => {
	return () => {
		return dashboardMyGoalAPI(data);
	};
};

export const checkAlignStatus = (data) => {
	return () => {
		return checkAlignStatusAPI(data);
	};
};

export const downloadPdf = (data) => {
	return () => {
		return downloadPdfAPI(data);
	};
};

export const fetchStatusReport = (data) => {
	return () => {
		return fetchStatusReportAPI(data);
	};
};

export const fetchQuarterReport = (data) => {
	return () => {
		return fetchQuarterReportAPI(data);
	};
};

export const fetchProgressReport = (data) => {
	return () => {
		return fetchProgressReportAPI(data);
	};
};

export const fetchFeedbackReport = (data) => {
	return () => {
		return fetchFeedbackReportAPI(data);
	};
};

export const updateSequence = (data) => {
	return () => {
		return updateSequenceAPI(data);
	};
};

/** Get OKR Master Data for Creating OKR */
const getOkrMasterPending = () => ({
	type: actionTypes.GET_OKR_MASTER_PENDING,
});
const getOkrMasterCompleted = (payload) => ({
	type: actionTypes.GET_OKR_MASTER_SUCCESS,
	payload,
});
const getOkrMasterFailed = (error) => ({
	type: actionTypes.GET_OKR_MASTER_FAILED,
	error,
});

export const getOkrMasterData = () => {
	return (dispatch) => {
		dispatch(getOkrMasterPending());
		return getOkrMasterDataApi()
			.then((response) => {
				if (response.status === 200) {
					let confidenceMapping = {};
					response.data.entity.confidences.forEach((element) => {
						confidenceMapping[element.confidenceValue] = {
							name: element.confidenceName,
							descrip: element.description,
						};
					});
					response.data.entity.confidenceMapping = confidenceMapping;
					let metrics = [];
					// remove no unit and boolean metrics
					response.data.entity.metricMasters.forEach((element) => {
						if (element.metricId !== 4 && element.metricId !== 5) {
							metrics.push(element);
						}
					});
					response.data.entity.metricMasters = metrics;
					dispatch(getOkrMasterCompleted(response.data));
				} else {
					dispatch(getOkrMasterFailed(response));
				}
			})
			.catch((error) => {
				dispatch(getOkrMasterFailed(error));
			});
	};
};
/** OKR Master Data End */

/** add Update Goal -- creating new action that will return simple promise will not map with reducer */
export const addUpdateObjective = (data, isAdd) => {
	return () => {
		if (isAdd) {
			return addGoalsAPI(data);
		} else {
			return updateGoalsAPI(data);
		}
	};
};

/** Api to get KR contributor listing */
export const getKrContributors = (data) => {
	return () => {
		getKrContributorsApi(data);
	};
};

export const updateGoalAndKrName = (data) => {
	return () => {
		return updateGoalAndKrNameApi(data);
	};
};

export const updateGoalAttributes = (data) => {
	return () => {
		return updateGoalAttributesApi(data);
	};
};

export const updateKrAttributes = (data) => {
	return () => {
		return updateKrAttributesApi(data);
	};
};

export const acceptKr = (data) => {
	return () => {
		return acceptKrApi(data);
	};
};

export const updateKrProgressValue = (data) => {
	return () => {
		return updateKrProgressValueApi(data);
	};
};

export const becomeContributor = (data) => {
	return () => {
		return becomeContributorApi(data);
	};
};

export const fetchObjectiveList = (data) => {
	return () => {
		return dashboardMyGoalAPI(data);
	};
};
export const getKrDetail = (data) => {
	return () => {
		return getKrDetailAPI(data);
	};
};
export const getOkrDetail = (data) => {
	return () => {
		return getOkrDetailAPI(data);
	};
};

export const getUserTeamsData = (data) => {
	return () => {
		return getUserTeamsDataApi(data);
	};
};

export const getOwnerTeamsData = (data) => {
	return () => {
		return getOwnerTeamsDataApi(data);
	};
};

export const getMyTeamDetails = (data) => {
	return () => {
		return getMyTeamDetailsAPI(data);
	};
};

export const updateNudgeTeams = (data) => {
	return () => {
		return updateNudgeTeamsAPI(data);
	};
};

export const updateNudgePerson = (data) => {
	return () => {
		return updateNudgePersonAPI(data);
	};
};

export const getMyTeamOKRDetails = (data) => {
	return () => {
		return getMyTeamOKRDetailsAPI(data);
	};
};

export const resetObjective = (data) => {
	return () => {
		return resetObjectiveApi(data);
	};
};
export const getLinkOwnerData = (data) => {
	return () => {
		return getLinkOwnerDataApi(data);
	};
};

export const getLinkObjectiveData = (data) => {
	return () => {
		return getLinkObjectivedDataApi(data);
	};
};
export const changeOwnerData = (data) => {
	return () => {
		return changeOwnerDataApi(data);
	};
};
export const updateProgress = (data) => {
	return () => {
		return updateProgressApi(data);
	};
};

// virtualAlignment
export const virtualAlignment = (data) => {
	return () => {
		return virtualAlignmentAPI(data);
	};
};

export const peopleViewFeedback = (data) => {
	return () => {
		return peopleViewFeedbackAPI(data);
	};
};

export const getObjectiveDetails = (data) => {
	return () => {
		return getObjectiveDetailsAPI(data);
	};
};

//Archive list actions

const fetchArchivePending = () => ({
	type: actionTypes.ARCHIVE_OKR_PENDING,
});
const fetchArchiveCompleted = (payload) => ({
	type: actionTypes.ARCHIVE_OKR_SUCCESS,
	payload,
});
const fetchArchiveFailed = (error) => ({
	type: actionTypes.ARCHIVE_OKR_FAILED,
	error,
});

// Dashboard Progress actions

const fetchDaboardProgressPending = () => ({
	type: actionTypes.DASHBOARD_PROGRESS_PENDING,
});
const fetchDaboardProgressCompleted = (payload) => ({
	type: actionTypes.DASHBOARD_PROGRESS_SUCCESS,
	payload,
});
const fetchDaboardProgressFailed = (error) => ({
	type: actionTypes.DASHBOARD_PROGRESS_FAILED,
	error,
});

// Dashboard Progress actions FOR TEAMS OKR TAB

const fetchTeamsOkrProgressPending = () => ({
	type: actionTypes.TEAMSOKR_PROGRESS_PENDING,
});
const fetchTeamsOkrProgressCompleted = (payload) => ({
	type: actionTypes.TEAMSOKR_PROGRESS_SUCCESS,
	payload,
});
const fetchTeamsOkrProgressFailed = (error) => ({
	type: actionTypes.TEAMSOKR_PROGRESS_FAILED,
	error,
});

/**
 * Get Archive api call
 * @param {Object} data
 * @returns promise
 */
export const getArchiveDetail = (data) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchArchivePending());
		return archieveAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchArchiveCompleted(response.data));
				} else {
					dispatch(fetchArchiveFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchArchiveFailed(error));
				dispatch(showLoader(false));
			});
	};
};

/**
 * Get Dashboard Progress api call
 * @param {Object} data
 * @returns promise
 */

export const getDashboardProgress = (data, showProgress = false) => {
	return (dispatch) => {
		showProgress && dispatch(showLoader(true));
		dispatch(fetchDaboardProgressPending());
		return dashboardProgressAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchDaboardProgressCompleted(response.data));
				} else {
					dispatch(fetchDaboardProgressFailed('error'));
				}

				showProgress && dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchDaboardProgressFailed(error));
				showProgress && dispatch(showLoader(false));
			});
	};
};

/**
 * Get Dashboard Progress FOR TEAMS OKR TAB api call
 * @param {Object} data
 * @returns promise
 */

export const getTeamsOkrProgress = (data, showProgress = false) => {
	return (dispatch) => {
		showProgress && dispatch(showLoader(true));
		dispatch(fetchTeamsOkrProgressPending());
		return teamOkrProgressAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchTeamsOkrProgressCompleted(response.data));
				} else {
					dispatch(fetchTeamsOkrProgressFailed('error'));
				}

				showProgress && dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchTeamsOkrProgressFailed(error));
				showProgress && dispatch(showLoader(false));
			});
	};
};

// Get okr/KR task

const fetchTaskPending = () => ({
	type: actionTypes.TASK_PENDING,
});
const fetchTaskCompleted = (payload) => ({
	type: actionTypes.TASK_SUCCESS,
	payload,
});
const fetchTaskFailed = (error) => ({
	type: actionTypes.TASK_FAILED,
	error,
});

export const getTasks = (data) => {
	return (dispatch) => {
		dispatch(fetchTaskPending());
		return getTaskAPI(data)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					dispatch(fetchTaskCompleted(response.data));
				} else {
					dispatch(fetchTaskFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchTaskFailed(error));
			});
	};
};

export const deleteTask = (data) => {
	return () => {
		return deleteTaskAPI(data);
	};
};

export const updateTask = (data) => {
	return () => {
		return updateTaskAPI(data);
	};
};

export const postNewTask = (data) => {
	return () => {
		return postTaskAPI(data);
	};
};

export const taskCompleted = (data) => {
	return () => {
		return taskCompletedAPI(data);
	};
};

export const resetTasks = () => {
	return {
		type: actionTypes.RESET_TASK,
	};
};

export const postProgressConversation = (data) => {
	return () => {
		return postProgressConversationAPI(data);
	};
};

export const getNoteById = (noteId) => {
	return () => {
		return getNoteByIdAPI(noteId);
	};
};

export const getConversationById = (conversationId) => {
	return () => {
		return getConversationByIdAPI(conversationId);
	};
};

export const getMyTeamGoalDetails = (data) => {
	return () => {
		return myTeamGoalAPI(data);
	};
};

export const dispatchLoader = (loader) => {
	return (dispatch) => {
		dispatch(showLoader(loader));
	};
};

/** Checkins */

const fetchCheckinStatusPending = () => ({
	type: actionTypes.CHECKIN_STATUS_PENDING,
});
const fetchCheckinStatusCompleted = (payload) => ({
	type: actionTypes.CHECKIN_STATUS_SUCCESS,
	payload,
});
const fetchCheckinStatusFailed = (error) => ({
	type: actionTypes.CHECKIN_STATUS_FAILED,
	error,
});

export const isCheckinSubmitted = (data) => {
	return (dispatch) => {
		dispatch(fetchCheckinStatusPending());
		return checkInStatus(data)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					dispatch(fetchCheckinStatusCompleted(response.data));
				} else {
					dispatch(fetchCheckinStatusFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchCheckinStatusFailed(error));
			});
	};
};

export const getCheckInWeeklyDates = (data) => {
	return () => {
		return checkInDates(data);
	};
};

export const getCheckInData = (data) => {
	return () => {
		return checkInData(data);
	};
};

export const getUserDirectEmployees = (data) => {
	return () => {
		return getReportingEmployeeDataAPI(data);
	};
};

export const updateCheckInData = (data, type) => {
	return () => {
		return postCheckInAPI(data);
	};
};
export const updateConfidenceData = (data) => {
	return () => {
		return updateConfidenceApi(data);
	};
};

export const getWeeklyTrendDetails = (data) => {
	return () => {
		return getWeeklyTrendDetailsAPI(data);
	};
};

/** Confidence */

const fetchConfidenceAlertPending = () => ({
	type: actionTypes.CONFIDENCE_ALERT_PENDING,
});
const fetchConfidenceAlertCompleted = (payload) => ({
	type: actionTypes.CONFIDENCE_ALERT_SUCCESS,
	payload,
});
const fetchConfidenceAlertFailed = (error) => ({
	type: actionTypes.CONFIDENCE_ALERT_FAILED,
	error,
});
export const getConfidenceAlertDetails = (data) => {
	return (dispatch) => {
		dispatch(fetchConfidenceAlertPending());
		return getConfidenceAlertAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchConfidenceAlertCompleted(response.data));
				} else {
					dispatch(fetchConfidenceAlertFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchConfidenceAlertFailed(error));
			});
	};
};
//Free Trial Env Trial
const freeTrialApiPending = () => ({
	type: actionTypes.FREE_TRIAL_PENDING,
});
const freeTrialApiSuccess = (payload) => ({
	type: actionTypes.FREE_TRIAL_SUCCESS,
	payload,
});
const freeTrialApiFailure = (error) => ({
	type: actionTypes.FREE_TRIAL_FAILED,
	error,
});
export const freeTrialApiDetails = (data) => (dispatch) => {
	dispatch(freeTrialApiPending());
	return freeTrialApi(data)
		.then((response) => {
			if (response?.status === 200) {
				dispatch(freeTrialApiSuccess(response?.data));
				return response;
			} else {
				dispatch(freeTrialApiFailure(response?.response?.data?.messageList.emailId));
				return response.response.data.messageList.emailId;
			}
		})
		.catch((error) => {
			console.log(error, 'error');
			dispatch(freeTrialApiFailure(error));
		});
};
//Free Trial Email Env Trial
const freeTrialApiEmailIdPending = () => ({
	type: actionTypes.FREE_TRIAL_EMAILID_PENDING,
});
const freeTrialApiEmailIdSuccess = (payload) => ({
	type: actionTypes.FREE_TRIAL_EMAILID_SUCCESS,
	payload,
});
const freeTrialApiEmailIdFailure = (error) => ({
	type: actionTypes.FREE_TRIAL_EMAILID_FAILED,
	error,
});
export const freeTrialApiEmailIdDetails = (data) => (dispatch) => {
	dispatch(freeTrialApiEmailIdPending());
	return freeTrialApiEmailId(data)
		.then((response) => {
			if (response?.status === 200) {
				dispatch(freeTrialApiEmailIdSuccess(response?.data));
				return '';
			} else {
				dispatch(freeTrialApiEmailIdFailure(response?.response?.data?.messageList.emailId));
				return response.response.data.messageList.emailId;
			}
		})
		.catch((error) => {
			console.log(error, 'error');
			dispatch(freeTrialApiEmailIdFailure(error));
		});
};
//Free Trial Domain Env Trial
const freeTrialApiDomainPending = () => ({
	type: actionTypes.FREE_TRIAL_DOMAIN_PENDING,
});
const freeTrialApiDomainSuccess = (payload) => ({
	type: actionTypes.FREE_TRIAL_DOMAIN_SUCCESS,
	payload,
});
const freeTrialApiDomainFailure = (error) => ({
	type: actionTypes.FREE_TRIAL_DOMAIN_FAILED,
	error,
});
export const freeTrialApiDomainDetails = (data) => (dispatch) => {
	dispatch(freeTrialApiDomainPending());
	return freeTrialApiDomain(data)
		.then((response) => {
			if (response?.status === 200) {
				dispatch(freeTrialApiDomainSuccess(response?.data));
				return '';
			} else {
				dispatch(freeTrialApiDomainFailure(response?.response?.data?.messageList?.domainName));
				return response.response.data.messageList.domainName;
			}
		})
		.catch((error) => {
			console.log(error, 'error');
			dispatch(freeTrialApiDomainFailure(error));
		});
};
//Free Trial Password Env Trial
const freeTrialApiPasswordPending = () => ({
	type: actionTypes.FREE_TRIAL_PASSWORD_PENDING,
});
const freeTrialApiPasswordSuccess = (payload) => ({
	type: actionTypes.FREE_TRIAL_PASSWORD_SUCCESS,
	payload,
});
const freeTrialApiPasswordFailure = (error) => ({
	type: actionTypes.FREE_TRIAL_PASSWORD_FAILED,
	error,
});
export const freeTrialApiPasswordDetails = (data) => (dispatch) => {
	dispatch(freeTrialApiPasswordPending());
	return freeTrialApiPassword(data)
		.then((response) => {
			if (response?.status === 200) {
				dispatch(freeTrialApiPasswordSuccess(response?.data));
				return '';
			} else {
				dispatch(freeTrialApiPasswordFailure(response?.response?.data?.messageList?.password));
				return response.response.data.messageList.password;
			}
		})
		.catch((error) => {
			console.log(error, 'error');
			dispatch(freeTrialApiPasswordFailure(error));
		});
};


//Import Okrs
const getOkrImportPending = () => ({
	type: actionTypes.OKR_IMPORT_PENDING,
});
const getOkrImportSuccess = (payload) => ({
	type: actionTypes.OKR_IMPORT_SUCCESS,
	payload,
});
const getOkrImportFailure = (error) => ({
	type: actionTypes.OKR_IMPORT_FAILED,
	error,
});
export const getOkrImport = (data) => (dispatch) => {
	dispatch(getOkrImportPending());
	return getOkrImportApi(data)
		.then((response) => {
			if (response.status === 200) {
				dispatch(getOkrImportSuccess(response.data));
			} else {
				dispatch(getOkrImportFailure('error'));
			}
		})
		.catch((error) => {
			console.log(error, 'error');
			dispatch(getOkrImportFailure(error));
		});
};

const getOkrImportCategoryPending = () => ({
	type: actionTypes.OKR_IMPORT_CATEGORY_PENDING,
});
const getOkrImportCategorySuccess = (payload) => ({
	type: actionTypes.OKR_IMPORT_CATEGORY_SUCCESS,
	payload,
});
const getOkrImportCategoryFailure = (error) => ({
	type: actionTypes.OKR_IMPORT_CATEGORY_FAILED,
	error,
});
export const getOkrImportCategory = (data) => (dispatch) => {
	dispatch(getOkrImportCategoryPending());
	return getOkrImportCategoryApi(data)
		.then((response) => {
			if (response.status === 200) {
				dispatch(getOkrImportCategorySuccess(response.data));
			} else {
				dispatch(getOkrImportCategoryFailure('error'));
			}
		})
		.catch((error) => {
			console.log(error, 'error');
			dispatch(getOkrImportCategoryFailure(error));
		});
};

export const postOkrImport = (data) => {
	return () => {
		return postOkrImportApi(data);
	};
};