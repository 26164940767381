import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs, Tooltip } from '@material-ui/core';
import { ObjectiveIcon, TeamIcon, DirectReportsIcon, CheckInsIcon, ArchiveIcon } from '../../../config/svg/MyGoalSvg';
import { AlertInfoIcon, InfoIcon, OrgChartIcon } from '../../../config/svg/CommonSvg';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { BottomArrowIcon } from '../../../config/svg/CommonSvgIcon';
import { Enums } from '../../../config/enums';

export const OkrTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`notification-tabpanel-${index}`}
			aria-labelledby={`notification-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

OkrTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `okr-tab-${index}`,
		'aria-controls': `okr-tabpanel-${index}`,
	};
}

export const OkrListingTabs: React.FC<any> = (props) => {
	const { t, tabValue, handleTabChange, userDetail, okrType, showHideArchiveList, showArchiveList } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const handleClick = (event: any) => {
		if (tabValue === 0) {
			event.stopPropagation();
		}
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
	};
	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpen(false);
	};
	return (
		<>
			<Tabs className='okr-tabs okr-tabs-space' value={tabValue} onChange={handleTabChange} aria-label='OKR Tabs'>
				<Tab
					className={`my-okr-tab not-filled-icon ${showArchiveList ? 'selected' : ''} `}
					icon={showArchiveList ? <ArchiveIcon /> : <ObjectiveIcon />}
					label={
						<>
							{showArchiveList ? (
								t('archive')
							) : (
								<Box className='btn-text-wrap'>
									{(props.confidenceAlertData?.isNewOkrAlert || props.confidenceAlertData?.isConfidenceAlert) ?
										<Tooltip
											placement='top'
											arrow
											classes={{ popper: 'tooltip-alert' }}
											title={
												props.confidenceAlertData?.isNewOkrAlert && props.confidenceAlertData?.isConfidenceAlert ?
													<List>
														<ListItem>{t('receivedNewRequest')}</ListItem>
														<ListItem>{t('updateConfidence')}</ListItem>
													</List> : props.confidenceAlertData?.isNewOkrAlert ? t('receivedNewRequest')
														: t('updateConfidence')
											}
										>
											<Badge
												className={'badge-icon okr-badge-icon'}
												overlap='rectangular'
												badgeContent={<AlertInfoIcon />}
											></Badge>
										</Tooltip> : ''
									}
									{t('myOkrsLabel')}
								</Box>
							)}
							{props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID && (
								<span className='arrowIcon' onClick={handleClick} id='my-okr-dropdown-icon'>
									<BottomArrowIcon />
								</span>
							)}
						</>
					}
					value={0}
					{...a11yProps(0)}
				/>

				<Tab disabled={okrType === 1} icon={<TeamIcon />} value={1} label={t('TeamOkrsLabel')} {...a11yProps(1)} />

				{userDetail.directReports && (
					<Tab
						disabled={okrType === 1}
						icon={<DirectReportsIcon />}
						label={t('DirectReportsLable')}
						value={2}
						{...a11yProps(2)}
					/>
				)}
				{!props.isFutureCycle && (
					<Tab
						icon={<CheckInsIcon />}
						label={
							<>
								<Badge
									className='badge-icon'
									overlap='rectangular'
									badgeContent={props.checkInResult?.isAlert && <AlertInfoIcon />}
								>
									{t('checkInsLabel')}
								</Badge>
							</>
						}
						value={3}
						className='check-ins-tab'
						{...a11yProps(3)}
					/>
				)}
				{props.isFutureCycle != true && (
					<Tab
						className='btn-circle-white'
						label={
							<Tooltip title={t('orgChartLabel')} arrow>
								<span>
									<OrgChartIcon />
								</span>
							</Tooltip>
						}
						value={4}
						{...a11yProps(4)}
					/>
				)}
			</Tabs>
			<PopperMenu
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				connectorIcon={false}
				popperClassName={'popper-menu popper-okr-menu'}
				popperPlacement={'bottom-start'}
				{...props}
				children={
					<>
						<ListItem
							onClick={(e: any) => {
								showHideArchiveList(0);
								handleClose(e);
								props.handleOkrTypeChange(0);
							}}
							className={tabValue === 0 && showArchiveList === false ? 'active' : ''}
							id='my-okr-link'
						>
							<ListItemAvatar>
								<ObjectiveIcon />
							</ListItemAvatar>
							<ListItemText primary={t('myOkrsLabel')} />
						</ListItem>
						<ListItem
							onClick={(e: any) => {
								showHideArchiveList(1);
								handleClose(e);
								props.handleOkrTypeChange(1);
							}}
							className={showArchiveList === true ? 'active' : ''}
							id='archive-link'
						>
							<ListItemAvatar>
								<ArchiveIcon />
							</ListItemAvatar>
							<ListItemText primary={t('archive')} />
						</ListItem>
					</>
				}
			></PopperMenu>
		</>
	);
};
