import axios from 'axios';
import { clearLocalStorage, getLocalStorageItem, setLocalStorageItem } from '../services/StorageService';
import jwtDecode from 'jwt-decode';
import { loginRequest, msalConfig } from '../config/azureAuthConfig';
import { PublicClientApplication } from '@azure/msal-browser';
import { DefaultOrigin } from '../config/constant';
import { userIdentity, currentCycle } from '../config/utils';
import CryptoJS from 'crypto-js';
import store from '../store/configureStore';
import { showIdleStatePopup } from '../action/common';
import { getEncryptString } from './graph';

const msalInstance = new PublicClientApplication(msalConfig);
let myToken = '';

axios.interceptors.request.use((req) => {
	if (!req.isProfileUrl) {
		// we will not be require all these headers for loading profile image
		//set token in header
		const tokenId = getLocalStorageItem('tokenId');
		const accessTokenId = JSON.parse(getLocalStorageItem('accessTokenId'));
		req.headers['Authorization'] = 'Bearer ' + JSON.parse(tokenId);
		req.headers['AccessTokenId'] = accessTokenId;
	}
	if (req.url.includes('/okrtrial')) {
		req.headers['Authorization'] = '';
		req.headers['AccessTokenId'] = '';
		req.headers['Content-Type'] = 'application/json';
		req.headers['Access-Control-Allow-Origin'] = '*';
		req.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
		req.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_AZURE_SUBSCRIPTIONKEY;
		req.headers['token'] = getEncryptString(new Date().toUTCString());
	}
	return req;
});

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		const originalRequest = error.config;
		if (error.response.status === 500) {
			window.location = '/500';
		} else if (error.response.status === 403) {
			window.location = '/forbidden';
		} else if (error.response.status === 401 && !originalRequest._retry) {
			return new Promise(function (resolve, reject) {
				refreshToken(originalRequest, 'resp', resolve, reject);
			});
		} else if (error.response.status === 400) {
			return error;
		} else {
			// reloadApplication();
		}
	}
);
const refreshToken = (orgReq, type, resolve, reject) => {
	const msalAccounts = JSON.parse(getLocalStorageItem('msalAccounts'));
	msalInstance
		.acquireTokenSilent({
			...loginRequest,
			account: msalAccounts,
		})
		.then((response) => {
			const token = jwtDecode(response.idToken);
			if (token === null) {
				clearLocalStorage();
				sessionStorage.clear();
				window.location.href = window.location.origin;
			} else {
				const tokenExpTime = toDate(token.exp); // get current date
				if (tokenExpTime <= toDate(Date.now())) {
					clearLocalStorage();
					sessionStorage.clear();
					window.location.href = window.location.origin;
				} else {
					setLocalStorageItem('tokenId', JSON.stringify(response.idToken));
					setLocalStorageItem('accessTokenId', JSON.stringify(response.accessToken));
					if (type === 'resp') {
						axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.idToken;
						orgReq.headers['Authorization'] = 'Bearer ' + response.idToken;
						response.idToken && resolve(axios(orgReq));
					}
				}
			}
		})
		.catch((error) => {
			clearLocalStorage();
			sessionStorage.clear();
			window.location.href = window.location.origin;
		});
};
const refreshTokenPreRequest = (req) => {
	const msalAccounts = JSON.parse(getLocalStorageItem('msalAccounts'));
	return msalInstance.acquireTokenSilent({
		...loginRequest,
		account: msalAccounts,
		forceRefresh: true,
	});
};

export const requestOKR = async (path, data, method, axiosConfig) => {
	let headers = {};
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;

	headers = {
		'Content-Type': 'application/json',
		OriginHost: originHost,
		'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_SUBSCRIPTIONKEY,
		token: getCryptoToken(),
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

const toDate = (eObj) => {
	let currentDate = new Date();
	let mEpoch = eObj;
	if (mEpoch < 10000000000) mEpoch *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses Milliseconds)

	currentDate.setTime(mEpoch);
	return currentDate;
};

export const downloadXLSFile = async (path, data, method, axiosConfig) => {
	// Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');

	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	const token = jwtDecode(myToken || tokenId);
	let tokenExpTime = token && token?.exp ? toDate(token.exp) : 0; // get current date
	tokenExpTime.setHours(tokenExpTime.getHours(), tokenExpTime.getMinutes() - 5, 0, 0); //set token exp time to 5 min before
	axiosConfig = { ...axiosConfig, responseType: 'arraybuffer' };
	const headers = {
		'Content-Type': 'blob',
		OriginHost: originHost,
		TenantId: tenantId,
		UserIdentity: userIdentity(),
		CurrentCycle: currentCycle(),
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	};

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const request = async (path, data, method, axiosConfig) => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const loginRequired = getLocalStorageItem('signInStatus');
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const tenantId = getLocalStorageItem('TenantId');

	let headers = {};
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	const token = jwtDecode(myToken || tokenId);
	let tokenExpTime = token && token?.exp ? toDate(token.exp) : 0; // get current date
	tokenExpTime.setHours(tokenExpTime.getHours(), tokenExpTime.getMinutes() - 5, 0, 0); //set token exp time to 5 min before

	if (loginRequired) {
		headers = {
			'Content-Type': 'application/json',
			OriginHost: originHost,
			TenantId: tenantId,
			UserIdentity: userIdentity(),
			CurrentCycle: currentCycle(),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		};
	} else {
		headers = {
			'Content-Type': 'application/json',
			OriginHost: originHost,
			TenantId: tenantId,
			UserIdentity: userIdentity(),
			CurrentCycle: currentCycle(),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		};
	}

	// console.log('request ==> tokenExpTime ==> ', tokenExpTime, toDate(Date.now()));
	if (loginRequired && userDetail) {
		if (!Boolean(token) || tokenExpTime <= toDate(Date.now())) {
			try {
				const response = await refreshTokenPreRequest();
				if (response) {
					const idToken = response.idToken ? response.idToken : null;
					const accessToken = response.accessToken ? response.accessToken : null;
					const token = idToken ? jwtDecode(idToken) : null;
					if (!Boolean(token)) {
						reloadApplication();
					} else {
						const tokenExpTime = token.exp ? toDate(token.exp) : null;
						if (tokenExpTime && tokenExpTime <= toDate(Date.now())) {
							reloadApplication();
						} else if (idToken && accessToken) {
							myToken = idToken;
							setLocalStorageItem('tokenId', JSON.stringify(idToken));
							setLocalStorageItem('accessTokenId', JSON.stringify(accessToken));
							return initiateAxiosCall(method, path, headers, data, axiosConfig);
						} else {
							reloadApplication();
						}
					}
				} else {
					reloadApplication();
				}
			} catch (error) {
				console.debug(error);
				store.dispatch(showIdleStatePopup(true));
			}
		} else {
			return initiateAxiosCall(method, path, headers, data, axiosConfig);
		}
	} else {
		return initiateAxiosCall(method, path, headers, data, axiosConfig);
	}
};

const reloadApplication = () => {
	clearLocalStorage();
	sessionStorage.clear();
	window.location.href = window.location.origin;
};

const initiateAxiosCall = (method, path, headers, data, axiosConfig) => {
	return axios({
		method,
		url: path,
		headers: headers,
		data,
		...(axiosConfig || {}),
	});
};

/**
 * Created new method for handling profile image api call
 * @param {string} path
 * @param {obkect*} data
 * @param {string*} method
 * @param {*object} axiosConfig
 * @returns promise
 */
const requestImage = (path, data, method, axiosConfig) => {
	return axios({
		method,
		url: path,
		data,
		...(axiosConfig || {}),
	});
};

const getCryptoToken = () => {
	let key = CryptoJS.enc.Utf8.parse('aB8978GGjkio02K4');
	let iv = CryptoJS.enc.Utf8.parse('huI5K8o90Lhn4Jel');
	let encryptedToken = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(new Date().toUTCString()), key, {
		keySize: 128 / 8,
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	return encryptedToken;
};

export const requestFreeTrial = (path, data, method, axiosConfig) => {
	let headers = {};
	headers = {
		'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_SUBSCRIPTIONKEY,
		token: getCryptoToken(),
	};
	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};

export const postRequestFreeTrial = (path, data, axiosConfig) => requestFreeTrial(path, data, 'POST', axiosConfig);

export const getRequest = (path, data, axiosConfig) => request(path, data, 'GET', axiosConfig);
export const getRequestImage = (path, data, axiosConfig) => requestImage(path, data, 'GET', axiosConfig);

export const postRequest = (path, data) => request(path, data, 'POST');
export const patchRequest = (path, data) => request(path, data, 'PATCH');
export const deleteRequest = (path, data) => request(path, data, 'DELETE');
export const putRequest = (path, data) => request(path, data, 'PUT');
export const postRequestOKR = (path, data) => requestOKR(path, data, 'POST');

export const getHeaders = () => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	return {
		//'Content-Type': 'multipart/form-data',
		//Origin: originHost,
		OriginHost: originHost,
		TenantId: tenantId,
		UserIdentity: userIdentity(),
		CurrentCycle: currentCycle(),
		Authorization: `Bearer ${tokenId}`,
		controlerheader: `${tokenId}`,
	};
};
export const uploadFile = (url, formData) => {
	const tokenId = JSON.parse(localStorage.getItem('tokenId'));
	const tenantId = getLocalStorageItem('TenantId');

	let headers = {};
	const originHost = window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin;
	headers = {
		'Content-Type': 'multipart/form-data',
		Origin: originHost,
		TenantId: tenantId,
		UserIdentity: userIdentity(),
		CurrentCycle: currentCycle(),
		Authorization: `Bearer ${tokenId}`,
		controlerheader: `${tokenId}`,
	};

	return axios.post(url, formData, {
		headers: headers,
	});
};

export const getRequestFreeTrial = (path, data, axiosConfig) => requestForCycleDetail(path, data, 'GET', axiosConfig);

export const requestForCycleDetail = async (path, data, method, axiosConfig) => {
	const headers = {}; // new Headers();
	// headers.append('Content-Type', 'application/json');
	// headers.append('Access-Control-Allow-Origin', '*');
	// headers.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');
	// headers.append('Ocp-Apim-Subscription-Key', process.env.REACT_APP_AZURE_SUBSCRIPTIONKEY);
	// headers.append('token', getEncryptString(new Date().toUTCString()));

	return initiateAxiosCall(method, path, headers, data, axiosConfig);
};
