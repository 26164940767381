/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { DefaultIcon, HayHandIcon } from './svg/CommonSvg';

export const CHART_COLOR = ['#6C5BFA', '#8FC3F6', '#FA989D'];
export const TEAMS_CHART_COLOR = ['#8FC3F6', '#FA989D', '#45E7BA'];
export const URL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

export const APP_ID = 3;
export const REQUEST_TYPE_OKR = 1;
export const REQUEST_TYPE_KR = 2;
export const REQUEST_TYPE_FEEDBACK = 3;
export const REQUEST_TYPE_TEAMS = 4;
export const RAISED_TYPE_ID = 2;
export const GoalModel = {
	objectiveId: 0,
	goalObjectiveId: 0,
	employeeId: 0,
	isPrivate: false,
	objectiveName: '',
	objectiveDescription: '',
	objectiveCycleId: 17,
	startDate: '',
	endDate: '',
	importedType: 0,
	importedId: 0,
	contributors: [],
	myGoalsDetails: [],
};
export const MaxLength = 120;
export const Styles = {
	twoLine: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
	},
};
export const IS_VISIBLE = false;
export const IS_CLIENT = false;
export const marks = [
	{
		value: 0,
		label: '0',
	},
	{
		value: 1,
		label: '1',
	},
	{
		value: 2,
		label: '2',
	},
	{
		value: 3,
		label: '3',
	},
	{
		value: 4,
		label: '4',
	},
	{
		value: 5,
		label: '5',
	},
];
export const labels = {
	1: 'Needs improvement',
	2: 'Average',
	3: 'Good',
	4: 'Exceptional',
	5: 'Extraordinary',
};

export const AdUserDomain = '@msdnincsysinc.onmicrosoft.com';
export const DefaultOrigin = 'https://dev.unlockokr.com';
export const WithoutHttpDefaultOrigin = 'admin-dev.unlockokr.com';
export const AZURE_LOGOUTURI =
	'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
	window.location.origin +
	'/logout';
export const AZURE_Created_domains = ['msdnincsysinc.onmicrosoft.com', 'unlockokr.online'];

/**
 * Callouts constant
 */
export const defaultCalloutProps = {
	open: false,
	text: '',
	placement: '',
	classname: '',
	currentCallout: '',
	currentCalloutIndex: 0,
	prev: false,
	next: false,
	done: false,
	tooltipIcon: <DefaultIcon />,
};

export const calloutsDataForOKR = [
	{
		open: true,
		text: `${'createokrTitleGuidedText'}`,
		placement: '',
		classname: '',
		currentCallout: 'okrTitle',
		currentCalloutIndex: 0,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'settingGuidedText'}`,
		placement: '',
		classname: '',
		currentCallout: 'okrSetting',
		currentCalloutIndex: 1,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: '',
		placement: '',
		classname: 'create-title-guidedtext',
		currentCallout: 'krTitle',
		currentCalloutIndex: 2,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'settingKrGuidedText'}`,
		placement: '',
		classname: 'setting-kr-guidedtext',
		currentCallout: 'krSetting',
		currentCalloutIndex: 3,
		prev: true,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'okrSettingTooltipText'}`,
		placement: '',
		classname: 'okr-tooltip',
		currentCallout: 'okrSettingTooltip',
		currentCalloutIndex: 4,
		prev: false,
		next: false,
		done: false,
		tooltipIcon: <HayHandIcon />,
	},
	{
		open: true,
		text: `${'okrSettingTooltipText'}`,
		placement: '',
		classname: 'okr-tooltip kr-tooltip',
		currentCallout: 'okrSettingTooltip',
		currentCalloutIndex: 5,
		prev: false,
		next: false,
		done: false,
		tooltipIcon: <HayHandIcon />,
	},
];
export const calloutsDataForKeyResult = [
	{
		open: true,
		text: `${'krTeamGuidedText'}`,
		placement: 'left-start',
		classname: 'krTeamGuidedText',
		currentCallout: 'krTeamText',
		currentCalloutIndex: 0,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'krTitleGuidedText'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'KrTitleText',
		currentCalloutIndex: 1,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'krDuedateGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'KrDueDate',
		currentCalloutIndex: 2,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'measurementGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md',
		currentCallout: 'Krmeasurement',
		currentCalloutIndex: 3,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'krcontributorsGuidedText'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'Krcontributors',
		currentCalloutIndex: 4,
		prev: true,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'letsAssignContributorGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10 assignContributorTooltip',
		currentCallout: '',
		currentCalloutIndex: 5,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'assignTargetGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'assignTargetTitle',
		currentCalloutIndex: 6,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'assignKrTargetGuidedText'}`,
		placement: 'top-start',
		classname: 'assignkr-target-guidedtext',
		currentCallout: 'assignKrTarget',
		currentCalloutIndex: 7,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'assignKrDueDateGuidedText'}`,
		placement: 'top-start',
		classname: 'assignkr-date-guidedtext',
		currentCallout: 'assignKrCommonDueDate',
		currentCalloutIndex: 8,
		prev: true,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'showContributors'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'contributerList',
		currentCalloutIndex: 9,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'indvContributors'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'indvcontributerList',
		currentCalloutIndex: 10,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'letsAssignIndContributorGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10 assignContributorTooltip',
		currentCallout: '',
		currentCalloutIndex: 11,
		prev: false,
		next: false,
		done: true,
		tooltipIcon: <DefaultIcon />,
	},
];

export const calloutsDataForObjective = [
	{
		open: true,
		text: `${'okrTitleGuidedText'}`,
		placement: 'left-start',
		classname: '',
		currentCallout: 'okrTitle',
		currentCalloutIndex: 0,
		prev: false,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'desGuidedText'}`,
		placement: 'left-start',
		classname: 'desGuided',
		currentCallout: 'okrDesc',
		currentCalloutIndex: 1,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: '',
		placement: 'left-start',
		classname: 'middle-md-5',
		currentCallout: 'OkrTeamORIndividual',
		currentCalloutIndex: 2,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'ownerGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md',
		currentCallout: 'okrOwner',
		currentCalloutIndex: 3,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'dateGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'okrDate',
		currentCalloutIndex: 4,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
	{
		open: true,
		text: `${'virtuaLinkGuidedText'}`,
		placement: 'left-start',
		classname: 'middle-md-10',
		currentCallout: 'virtualLink',
		currentCalloutIndex: 5,
		prev: true,
		next: true,
		done: false,
		tooltipIcon: <DefaultIcon />,
	},
];

/**
 * This method adds placeholder based on its index
 * @param key Number
 */
export const getPlaceHolderText = (key, isFirstTimeUser) => {
	//Checking if first time user
	if (isFirstTimeUser) {
		switch (key) {
			case 0:
				return 'letsAddFirstKR';
			case 1:
				return 'letsAddAnotherKR';
			case 2:
				return 'wantAddAnother';
			case 3:
				return 'addFourthKey';
			case 4:
				return 'addFifthKey';
			case 5:
				return 'specialObjectiveKey';
			default:
				return 'specialObjectiveKey';
		}
	} else {
		switch (key) {
			case 0:
				return 'addKeyRes';
			case 1:
				return 'AddAnotherKeyRes';
			case 2:
				return 'wantAddAnother';
			case 3:
				return 'addFourthKey';
			case 4:
				return 'addFifthKey';
			case 5:
				return 'specialObjectiveKey';
			default:
				return 'specialObjectiveKey';
		}
	}
};

export const FreeTrialCredentials = {
	userName: 'FreeTrialUser',
	password: 'Admin@123',
};
export const LEARNING = 'https://academy.unlockokr.com/login/login.php';
