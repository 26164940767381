import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AlignmentMap } from '../../components/AlignmentMap/index';
import { withRouter } from 'react-router-dom';
import {
	getAlignmentList,
	resetAlignment,
	getPeopleMapList,
	fetchTeamsAlignmentMap,
	fetchDirectsReportsMap,
	addAlignmentFilter,
	addOrganisationFilter,
	setAlignmentMapAction,
} from '../../action/alignment';
import { searchEmployee, globalSearchAPIWithTeam } from '../../action/search';
import { updatePage, getPreviousCycleDetails, updateRoute, updatePrevRoute, getUserById } from '../../action/common';
import {
	updateStatus,
	getOkrStatusList,
	deleteObjective,
	deleteContributor,
	getOkrMasterData,
	acceptKr,
	getDashboardDetail,
	getKrDetail,
	getOkrDetail,
	updateKrProgressValue,
	getUserTeamsData,
	getOwnerTeamsData,
	updateGoalAttributes,
	updateNudgeTeams,
	updateNudgePerson,
	checkAlignStatus,
	resetOkr,
	addUpdateObjective,
	updateGoalAndKrName,
	getKrContributors,
	updateKrAttributes,
	resetObjective,
	fetchObjectiveList,
	becomeContributor,
	unlockRequest,
	getDashboardProgress,
} from '../../action/myGoal';
import { fetchFeedback } from '../../action/feedback';
import {
	requestOneToOnePersonalFeedback,
	askDetailsPersonalFeedback,
	askPersonalFeedback,
	giveDetailsPersonalFeedback,
	giveUpdatePersonalFeedback,
	getCriteriaMaster,
	cancelRequestPersonalFeedback,
	requestAgainPersonalFeedback,
	viewPersonalFeedback,
} from '../../action/personalFeedback';
import {
	giveFeedback,
	askFeedback,
	resetFeedback,
	requestOneToOneFeedback,
	commentFeedback,
} from '../../action/feedback';
import {
	getOnboarding,
	updateOnboarding,
	updateOnboardingCount,
	getOnboardingCount,
	updateCalloutPanel,
} from '../../action/onboarding';

const mapStateToProps = (state) => {
	return {
		alignStatus: state.alignReducer.alignStatus,
		alignResult: state.alignReducer.alignResult,
		teamStatus: state.alignReducer.teamStatus,
		teamResult: state.alignReducer.teamResult,
		peopleResult: state.alignReducer.peopleResult,
		peopleStatus: state.alignReducer.peopleStatus,
		directReportResult: state.alignReducer.directReportResult,
		directReportResultStatus: state.alignReducer.directReportResultStatus,

		listOkrPending: state.okrReducer.listOkrPending,
		listOkrResult: state.okrReducer.listOkrResult,
		listOkrStatusResult: state.okrReducer.listOkrStatusResult,
		listOkrSuccess: state.okrReducer.listOkrSuccess,
		listOkrError: state.okrReducer.listOkrError,

		// new reducer state for okr master data
		okrMasterData: state.okrReducer.okrMasterData,
		okrMasterDataStatus: state.okrReducer.okrMasterDataStatus,
		giveFeedbackStatus: state.feedbackReducer.giveFeedbackStatus,
		askFeedbackStatus: state.feedbackReducer.askFeedbackStatus,
		feedbackOneOnOneStatus: state.feedbackReducer.feedback1To1Status,

		alignmentFilterData: state.alignReducer.alignmentFilterData,
		organisationFilterData: state.alignReducer.organisationFilterData,

		peopleAlignmentMap: true,

		onboardingStatus: state.onboardingReducer.onboardingStatus,
		onboardingResult: state.onboardingReducer.onboardingResult,
		onboardingCount: state.onboardingReducer.onboardingCount,
		activeOnboardingPanel: state.onboardingReducer.activeOnboardingPanel,
		dashboardCurrentTab: state.commonReducer.dashboardCurrentTab,

		dashboardProgressData: state.okrReducer.dashboardProgressData,
		dashboardProgressStatus: state.okrReducer.dashboardProgressStatus,

		getPermissionStatus: state.rolesReducer.getPermissionStatus,
		getPermissionResult: state.rolesReducer.getPermissionResult,
		userSelectedCycle: state.commonReducer.userSelectedCycle,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getAlignmentList,
			getPeopleMapList,
			resetAlignment,
			updateStatus,
			getOkrStatusList,
			requestOneToOnePersonalFeedback,
			askDetailsPersonalFeedback,
			askPersonalFeedback,
			giveDetailsPersonalFeedback,
			giveUpdatePersonalFeedback,
			getCriteriaMaster,
			cancelRequestPersonalFeedback,
			requestAgainPersonalFeedback,
			viewPersonalFeedback,
			checkAlignStatus,
			deleteObjective,
			deleteContributor,
			giveFeedback,
			askFeedback,
			resetFeedback,
			requestOneToOneFeedback,
			commentFeedback,
			searchEmployee,
			globalSearchAPIWithTeam,
			updatePage,
			getPreviousCycleDetails,
			updateRoute,
			updatePrevRoute,
			getOkrMasterData,
			acceptKr,
			getDashboardDetail,
			getKrDetail,
			getOkrDetail,
			getUserById,
			updateKrProgressValue,
			fetchFeedback,
			fetchTeamsAlignmentMap,
			getUserTeamsData,
			getOwnerTeamsData,
			resetObjective,
			updateGoalAttributes,
			updateNudgeTeams,
			updateNudgePerson,
			addAlignmentFilter,
			resetOkr,
			fetchDirectsReportsMap,
			addUpdateObjective, // V2 API for add edit objective
			updateGoalAndKrName,
			getKrContributors,
			updateKrAttributes,
			addOrganisationFilter,
			fetchObjectiveList,
			becomeContributor,
			setAlignmentMapAction,
			unlockRequest,
			getOnboarding,
			updateOnboarding,
			updateOnboardingCount,
			getOnboardingCount,
			updateCalloutPanel,
			getDashboardProgress,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AlignmentMap));
