import React, { FC, Fragment } from 'react';
import { Box, ClickAwayListener, MenuItem, MenuList, Popper, Tooltip, Typography } from '@material-ui/core';
import { getOkrProgressDetails } from '../../config/utils';
import OKRFlag from '../../images/okrFlag.svg';
import krFlag from '../../images/krFlag.svg';
import CircularProgress from '../OkrAction/CircularProgress';
interface ContributorDetailsProps {
	employeeId: String | Number;
	firstName?: String;
	lastName?: String;
	imagePath?: string;
	fullName?: String;
	designation?: String;
	contributorsContribution?: String | Number;
	updatedOn?: any;
	isSource?: boolean;
	scaleValue?: any;
	popoverClassName?: any;
	alignmentMapPopover?: any;
}

export const AlignmentOkrListPopover: FC<any> = (props) => {
	const { open, anchorEl, setOpen, setAnchorEl, okrListKey, scaleValue, popoverClassName, alignmentMapPopover } = props;
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleListKeyDown = (event: any) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};
	const okrList = okrListKey && okrListKey.filter((item: any) => item.objectiveType === 1);
	const standAloneKrList = okrListKey && okrListKey.filter((item: any) => item.objectiveType === 2);
	const getProgessPercentage = (kr: any) => {
		const { color } = getOkrProgressDetails({
			value: kr.krScore,
			dueDate: kr.dueDate,
		});
		return (
			<Typography className='kr-score' style={{ color: color }}>
				{kr?.krScore}%
			</Typography>
		);
	};
	const getProgessStandalonePercentage = (item: any) => {
		const { color } = getOkrProgressDetails({
			value: item.score,
			dueDate: item.dueDate || item.endDate,
		});
		return (
			<Typography className='kr-score' style={{ color: color }}>
				{item?.score}%
			</Typography>
		);
	};
	const getMenuItem = (item: any) => {
		return (
			item &&
			item.length > 0 &&
			item.map((kr: any, idx: any) => (
				<MenuItem>
					<Box className='okr-popover-okr-left'>
						<img src={krFlag} alt='kricon' />
						<Box className='okr-title'>
							{kr.krName.length > 32 ? (
								<Tooltip arrow title={kr.krName}>
									<Typography variant='h4' className='cursor'>
										{kr.krName.substring(0, 32)}...
									</Typography>
								</Tooltip>
							) : (
								<Typography variant='h4'>{kr.krName}</Typography>
							)}
						</Box>
					</Box>
					<Box className='okr-popover-okr-right'>{getProgessPercentage(kr)}</Box>
				</MenuItem>
			))
		);
	};
	const getObjName = (item: any) => {
		return item.length > 75 ? (
			<Tooltip arrow title={item}>
				<Typography variant='h4' className='cursor'>
					{item.substring(0, 75)}...
				</Typography>
			</Tooltip>
		) : (
			<Typography variant='h4'>{item}</Typography>
		);
	};
	const getKrName = (item: any) => {
		return item.length > 32 ? (
			<Tooltip arrow title={item}>
				<Typography variant='h4'>{item.substring(0, 32)}...</Typography>
			</Tooltip>
		) : (
			<Typography variant='h4'>{item}</Typography>
		);
	};
	const scaleStyle = {
		transform: `scale(${scaleValue})`,
	};
	return (
		<Fragment>
			<Popper
				className={popoverClassName || 'okr-popover-dropdown'}
				open={open}
				anchorEl={anchorEl}
				transition
				placement='bottom-start'
				modifiers={{
					preventOverflow: {
						enabled: alignmentMapPopover ? false : true,
						boundariesElement: alignmentMapPopover ? 'window' : 'scrollParent',
					},
				}}
			>
				<ClickAwayListener onClickAway={handleClose}>
					<Box className='okr-popover-dropdown-wrap' onKeyDown={handleListKeyDown} style={scaleStyle}>
						<Box className='okr-popover-dropdown-inner'>
							<Box className='okr-popover-dropdown-shadow'>
								{okrList && okrList.length > 0 && (
									<MenuList className='okr-popover-okr-list'>
										{okrList.map((item: any, index: any) => (
											<MenuItem>
												<Box className='okr-popover-okr'>
													<Box className='okr-popover-okr-left'>
														<img src={OKRFlag} alt='okricon' />
														<Box className='okr-title'>
															{Boolean(item.objectiveName) ? (
																getObjName(item.objectiveName)
															) : Boolean(item.name) ? (
																getObjName(item.name)
															) : (
																<></>
															)}
														</Box>
														<Box className='okr-cycle-info'></Box>
													</Box>
													<Box className='okr-popover-okr-right'>
														<Box className='progressChart'>
															<CircularProgress
																percentage={item?.score}
																score={item?.score}
																dueDate={item?.dueDate || item?.endDate}
															/>
														</Box>
													</Box>
												</Box>
												<MenuList className='okr-popover-kr-list'>
													{Boolean(item.peopleViewKeyResults) ? (
														getMenuItem(item.peopleViewKeyResults)
													) : Boolean(item.directReportsKeyResults) ? (
														getMenuItem(item.directReportsKeyResults)
													) : (
														<></>
													)}
												</MenuList>
											</MenuItem>
										))}
									</MenuList>
								)}
								{standAloneKrList && standAloneKrList.length > 0 && (
									<Box className='standalone-krlist'>
										<Typography variant='body2' className='standalone-head'>
											Standalone Key Result
										</Typography>
										<MenuList className='okr-popover-kr-list'>
											{standAloneKrList.length > 0 &&
												standAloneKrList.map((item: any, index: any) => (
													<MenuItem>
														<Box className='okr-popover-okr-left'>
															<img src={krFlag} alt='kricon' />
															<Box className='okr-title'>
																{Boolean(item.objectiveName) ? (
																	getKrName(item.objectiveName)
																) : Boolean(item.name) ? (
																	getKrName(item.name)
																) : (
																	<></>
																)}
															</Box>
														</Box>
														<Box className='okr-popover-okr-right'>{getProgessStandalonePercentage(item)}</Box>
													</MenuItem>
												))}
										</MenuList>
									</Box>
								)}
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			</Popper>
		</Fragment>
	);
};
