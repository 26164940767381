import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import DateRangePicker from 'react-daterange-picker';
import { CycleDropdown } from './CycleDropdown';
import 'react-daterange-picker/dist/css/react-calendar.css';
import moment from 'moment';
import { formatDate, getDueDaysText, getMonthDate, getMonthDateYear } from '../../config/utils';
import { OKRPopover } from './OKRPopover';
import { getLocalStorageItem } from '../../services/StorageService';
import { Enums } from '../../config/enums';
import AlertDialog from './Dialog';
import { OKRButton } from './OKRButton';
import { CalendarIcon } from '../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';
import { ForwardArrow } from '../../config/svg/ArrowSvg';

// Not in use for now == below commented code is used for End Of month buttons
/*const ToolbarComponent = ({ durationCycleDetailForRange, onChange, maximumDate }) => {
	return Boolean(durationCycleDetailForRange) && durationCycleDetailForRange.length ? (
		<Box className='calender-toolbar'>
			{durationCycleDetailForRange.map((item, index) =>
				item.isActive && module === 'goal' ? (
					<Button
						key={`objective-date-${index}`}
						disabled={!item.isActive}
						onClick={() => onChange(new Date(item.endOfMonthDate))}
					>
						{item.title}
					</Button>
				) : (
					item.isActive &&
					module !== 'goal' &&
					moment(new Date(formatDate(maximumDate))).diff(new Date(formatDate(item.endOfMonthDate))) >= 0 && (
						<Button
							key={`objective-date-${index}`}
							disabled={
								!item.isActive ||
								(module !== 'goal' &&
									moment(new Date(formatDate(maximumDate))).diff(new Date(formatDate(item.endOfMonthDate))) < 0)
							}
							onClick={() => onChange(new Date(item.endOfMonthDate))}
						>
							{item.title}
						</Button>
					)
				)
			)}
		</Box>
	) : (
		<Fragment />
	);
};*/

export default function ReactDateRangePicker(props) {
	const {
		type,
		isCustomStyle,
		popperAnchorOrigin,
		popperTransformOrigin,
		popperAnchorPosition,
		bottomChildComponent = <></>, // This will be used to show any custom component in date range bottom part
		handleDateSelection,
		// durationCycleDetail, // This will be used for End Of month buttons creation
		minimumDate,
		maximumDate,
		startDateValue,
		endDateValue,
		allCycleDetails,
		selectedCycleDetails,
		setSelectedCycleDetails,
		isCurrentCycle,
		isCycleVisible = false,
		numberOfCalendars = Enums.TWO,
		selectionType = 'range',
		selectedDateRange,
		setSelectedDateRange,
		isDateRangeTextFieldVisible,
		isOnlyDueDateVisible,
		isCycleDisable = false,
		KRDateRangeDetail,
		resToUseInCalendar,
		editModee,
		setEditModee,
		showBottomText,
		isDatePickerDisable,
		placeholderText,
		singleDateRangeTitle,
	} = props;
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	const cycleId = getLocalStorageItem('cycleId') || null;
	const currentCycleYear = getLocalStorageItem('year') || null;
	const [selectedMinDate, setSelectedMinDate] = useState(minimumDate);
	const [selectedMaxDate, setSelectedMaxDate] = useState(maximumDate);
	const [calenderAnchorEl, setCalenderAnchorEl] = useState(null);
	const [openCalender, setOpenCalender] = useState(false);
	// const [durationCycleDetailForRange, setDurationCycleDetailForRange] = useState(durationCycleDetail);
	const [currentCycleId, setCurrentCycleId] = useState(cycleId);
	const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [modalProps, setModalProps] = useState({
		open: false,
		message: '',
	});
	const [contributor, setContributor] = useState();
	const [score, setScore] = useState();
	const { t } = useTranslation();

	useEffect(() => {
		// if (type === 'CreateKR' && KRDateRangeDetail && KRDateRangeDetail.start && KRDateRangeDetail.end) {
		minimumDate && setSelectedMinDate(minimumDate);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minimumDate]);

	useEffect(() => {
		// if (type === 'CreateKR' && KRDateRangeDetail && KRDateRangeDetail.start && KRDateRangeDetail.end) {
		maximumDate && setSelectedMaxDate(maximumDate);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maximumDate]);

	// useEffect(() => {
	// 	durationCycleDetail && setDurationCycleDetailForRange(durationCycleDetail);
	// }, [durationCycleDetail]);

	useEffect(() => {
		if (KRDateRangeDetail && KRDateRangeDetail.start && KRDateRangeDetail.end) {
			const range = moment().range(new Date(KRDateRangeDetail.start), new Date(KRDateRangeDetail.end));
			setSelectedDateRange(range);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [KRDateRangeDetail]);

	useEffect(() => {
		setSelectedDateRange(
			selectionType === 'range'
				? moment().range(new Date(startDateValue), new Date(endDateValue))
				: moment(new Date(startDateValue))
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * @function handleCalenderToggle - this is used to open date range pop up
	 * @param {Event} e - This contains input on click event details
	 */
	const handleCalenderToggle = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setCalenderAnchorEl(e.currentTarget);
		setOpenCalender((prev) => !prev);
	};

	/**
	 * @function handleSelect - This is used to handle date range selection from calender
	 * @param {Object} range - This will contains start and end date in moment format
	 */
	const handleSelect = (range) => {
		let startDT = null;
		let endDT = null;
		if (selectionType === 'range') {
			startDT = formatDate(range?.start?._d);
			endDT = formatDate(range?.end?._d);
		} else {
			startDT = formatDate(range?._d);
		}
		handleDateSelection(range, startDT, endDT, selectionType, false);
		setOpenCalender(false);
	};
	// below commented method is used for End Of month buttons click
	/**
	 * @function onChange - This is used to handle end of month date selection
	 * @param {Date} maxDateRange - This will contain user selected end of month date
	 */
	/*const onChange = (maxDateRange) => {
		let momentDate = null;
		let startDT = null;
		let endDT = null;
		if (selectionType === 'range') {
			momentDate = moment().range(new Date(selectedDateRange.start._d), new Date(maxDateRange));
			startDT = formatDate(selectedDateRange?.start?._d);
			endDT = formatDate(maxDateRange);
		} else {
			momentDate = moment(new Date(maxDateRange));
		}
		handleDateSelection(momentDate, startDT, endDT, selectionType, false);
	};*/

	/**
	 * @function handleCycleChange - This is used to handle cycle change in date range picker
	 * @param {String} id - Not in Use
	 * @param {Object} quarterData - Selected cycle detils
	 * @param {Number} year - Current selected year
	 */

	useEffect(() => {
		let numberOfContributor = resToUseInCalendar?.myGoalsDetails?.map((item) => item.contributors.length);
		setContributor(numberOfContributor);
		let score = resToUseInCalendar?.myGoalsDetails?.map((item) => item.score);
		setScore(score);
	}, [resToUseInCalendar?.myGoalsDetails]);

	const handleCycleChange = (id, quarterData, year) => {
		// below commented code is used for End Of month buttons
		/*const durationForQuarter = getEndDateForEveryMonthForQuarter(
			quarterData?.startDate,
			quarterData?.endDate,
			year,
			cycleDuration
		);
		durationForQuarter && setDurationCycleDetailForRange(durationForQuarter);*/
		if (
			selectedCycleDetails?.organisationCycleId !== quarterData?.organisationCycleId &&
			resToUseInCalendar?.myGoalsDetails?.length !== 0 &&
			contributor > 1 &&
			score > 0 &&
			editModee
		) {
			setModalProps({
				...modalProps,
				open: true,
				message: '',
				type: 'dateRangeCycleChange',
				cycleDetails: { quarterData, year },
			});
		} else if (
			selectedCycleDetails?.organisationCycleId !== quarterData?.organisationCycleId &&
			resToUseInCalendar?.myGoalsDetails?.length !== 0 &&
			contributor > 1 &&
			editModee
		) {
			setModalProps({
				...modalProps,
				open: true,
				message: '',
				type: 'dateRangeCycleChange1',
				cycleDetails: { quarterData, year },
			});
		} else {
			setModalProps({ open: false, message: '' });
			updateCycleDetails(quarterData, year);
		}
	};
	const updateCycleDetails = (quarterData, year) => {
		const momentDate =
			selectionType === 'range'
				? moment().range(new Date(quarterData?.startDate), new Date(quarterData?.endDate))
				: moment(new Date(quarterData?.startDate));
		quarterData?.startDate && setSelectedMinDate(new Date(quarterData?.startDate));
		quarterData?.endDate && setSelectedMaxDate(new Date(quarterData?.endDate));

		quarterData?.organisationCycleId && setCurrentCycleId(quarterData?.organisationCycleId);
		year && setCurrentSelectedCycleYear(year);
		setSelectedCycleDetails({ ...quarterData, year });

		handleDateSelection(momentDate, quarterData?.startDate, quarterData?.endDate, selectionType, true, {
			...quarterData,
			year,
		});
	};

	const handleCloseModal = (e, action) => {
		if (action === 1) {
			const { cycleDetails } = modalProps;
			updateCycleDetails(cycleDetails?.quarterData, cycleDetails?.year);
		} else {
			//
		}
		setModalProps({ open: false, message: '' });
	};

	return (
		<>
			{type === 'WeeklyTrend' && (
				<Box className='timeline-field wt-timeline-field'>
					<Box className='calendar-btn'>
						<OKRButton
							className='wt-date-btn'
							title={selectedDateRange && selectedDateRange.start ? `${getMonthDateYear(selectedDateRange.start)}` : ''}
							text={selectedDateRange && selectedDateRange.start ? `${getMonthDateYear(selectedDateRange.start)}` : ''}
							handleClick={(e) => {
								handleCalenderToggle(e);
							}}
						/>
						<ForwardArrow />
						<OKRButton
							className='wt-date-btn'
							title={selectedDateRange && selectedDateRange.end ? `${getMonthDateYear(selectedDateRange.end)}` : ''}
							text={selectedDateRange && selectedDateRange.end ? `${getMonthDateYear(selectedDateRange.end)}` : ''}
							handleClick={(e) => {
								handleCalenderToggle(e);
							}}
						/>
					</Box>
				</Box>
			)}
			{type !== 'WeeklyTrend' && isDateRangeTextFieldVisible && (
				<input
					type='text'
					className='form-control'
					readOnly={true}
					disabled={isDatePickerDisable || false}
					placeholder={placeholderText ? placeholderText : ''}
					value={
						selectionType === 'range'
							? selectedDateRange && selectedDateRange.start && selectedDateRange.end
								? `${getMonthDateYear(selectedDateRange.start)} - ${getMonthDateYear(selectedDateRange.end)}`
								: ''
							: selectedDateRange
							? `${getMonthDateYear(selectedDateRange)}`
							: ''
					}
					onClick={handleCalenderToggle}
				/>
			)}
			{type !== 'WeeklyTrend' && isOnlyDueDateVisible && (
				<Box className='timeline-field'>
					<Box className='calendar-btn'>
						<OKRButton
							title={
								props.isCreationFlow
									? t('blankSelection')
									: selectionType === 'range'
									? selectedDateRange && selectedDateRange.start && selectedDateRange.end
										? `${getMonthDateYear(selectedDateRange.start)} - ${getMonthDateYear(selectedDateRange.end)}`
										: ''
									: selectedDateRange
									? `${getMonthDateYear(selectedDateRange)}`
									: ''
							}
							text={
								selectionType === 'range'
									? selectedDateRange
										? `${getMonthDate(selectedDateRange.end)} (${selectedCycleDetails?.symbol}, ${
												selectedCycleDetails?.year
										  })`
										: ''
									: selectedDateRange
									? `${getMonthDate(selectedDateRange)} (${selectedCycleDetails?.symbol},${selectedCycleDetails?.year})`
									: ''
							}
							icon={<CalendarIcon />}
							handleClick={(e) => {
								handleCalenderToggle(e);
							}}
						/>
					</Box>
					<Typography variant='h6'>{bottomChildComponent}</Typography>
				</Box>
			)}
			{openCalender && (
				<OKRPopover
					open={openCalender}
					setOpen={setOpenCalender}
					anchorEl={calenderAnchorEl}
					setAnchorEl={setCalenderAnchorEl}
					popperClassName={'calender-control-popover'}
					popperInnerClass={'calender-control-inner'}
					handlePopperClose={(event, reason) => {
						// if (reason !== 'backdropClick') {
						setOpenCalender(false);
						setCalenderAnchorEl(null);
						// }
					}}
					useCustomStyle={isCustomStyle}
					popperAnchorOrigin={popperAnchorOrigin}
					popperTransformOrigin={popperTransformOrigin}
					popperAnchorPosition={popperAnchorPosition}
					{...props}
				>
					<Box className='calender-control-area'>
						<Box className='date-range-header' id='mainCylceDiv'>
							{selectionType === 'range' ? (
								<Typography>{t('dateRangeTitle')}</Typography>
							) : (
								<Typography>{singleDateRangeTitle || t('singleDateTitle')}</Typography>
							)}
							{isCycleVisible && (
								<CycleDropdown
									allCycleDetails={allCycleDetails}
									currentCycleId={currentCycleId}
									currentSelectedCycleYear={currentSelectedCycleYear}
									cycleDuration={cycleDuration}
									handleCycleChange={handleCycleChange}
									selectedCycleDetails={selectedCycleDetails}
									currentCycleYear={currentCycleYear}
									isCurrentCycle={isCurrentCycle}
									isCycleDisable={isCycleDisable}
									resToUseInCalendar={resToUseInCalendar}
									editModee={editModee}
									setEditModee={setEditModee}
								/>
							)}
						</Box>
						{/* {isEndOfMonthVisible && (
						<ToolbarComponent
							durationCycleDetailForRange={durationCycleDetailForRange}
							onChange={onChange}
							maximumDate={selectedMaxDate}
						/>
					)} */}
						<DateRangePicker
							numberOfCalendars={numberOfCalendars}
							selectionType={selectionType} // single or range
							singleDateRange={selectionType === 'range' ? true : true}
							initialFromValue={selectionType === 'range' ? true : true}
							// initialDate={moment(new Date(selectedMinDate))}
							minimumDate={moment(new Date(selectedMinDate))}
							maximumDate={moment(new Date(selectedMaxDate))}
							value={selectedDateRange}
							onSelect={handleSelect}
						/>
						<Box className='calender-footer-bar'>
							<Typography>
								{showBottomText
									? getDueDaysText(startDateValue, endDateValue, selectedCycleDetails)
									: bottomChildComponent}
							</Typography>
							{selectionType === 'range' ? (
								<Typography>{`${t('dateRangeBottomRightText')}: ${
									selectionType === 'range'
										? selectedDateRange && selectedDateRange.start && selectedDateRange.end
											? getMonthDateYear(selectedDateRange.start) + ' - ' + getMonthDateYear(selectedDateRange.end)
											: ''
										: ''
								}`}</Typography>
							) : (
								<></>
							)}
						</Box>
					</Box>
				</OKRPopover>
			)}
			{showBottomText && (
				<Box className='cycle-and-remaining-days'>
					<Typography variant='h6'>
						{`${t('cycle')} ${
							selectedCycleDetails ? `${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year}` : ''
						}`}
					</Typography>
					<Typography variant='body2'>{getDueDaysText(startDateValue, endDateValue, selectedCycleDetails)}</Typography>
				</Box>
			)}
			{modalProps?.open && (
				<AlertDialog
					// module={modalProps?.type === 'dateRangeCycleChange'}
					message={
						modalProps?.type === 'dateRangeCycleChange'
							? t('OkrContributorAndContribution')
							: t('OkrContributorNoContribution')
					}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps?.open}
				/>
			)}
		</>
	);
}
