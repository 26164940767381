import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormLabel, Typography, TextField, FormControl } from '@material-ui/core';
import { OKRButton } from '../OKRButton';
import { useTranslation } from 'react-i18next';
import { AddIconSvg, EditIcon } from '../../../config/svg/CommonSvg';
import { formatDate, getCurrencyIcon, getFullName } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { AddEditContributorDetail } from '../../Goals/TeamsKr/AddEditContributorDetail';
import { ManageContributorList } from '../../Goals/TeamsKr/ManageContributorList';
import { getLocalStorageItem } from '../../../services/StorageService';
import { useSnackbar } from 'notistack';
import { TeamsKr } from '../../Goals/TeamsKr/TeamsKr';
import { UserAvatarGroup } from '../UserAvatarGroup';
import moment from 'moment';
import AlertDialog from '../Dialog';
import { IndividualKr } from '../../Goals/IndividualKr/IndividualKr';
import { assignOkr } from '../../Admin/AdminConstant';
import { checkPermission } from '../../../config/rolePermission';

export const AssignContributors: React.FC<any> = (props) => {
	const {
		keyData,
		kr,
		okr,
		okrMasterData,
		durationCycleDetail,
		keyAttribute, //startDate, dueDate, keyDescription, teamId, contributors, currencyId, startValue, targetValue, goalKeyId, metricId
		objectiveDetail, //objectiveName
		year,
		goalTypeId,
		isContributorOnly,
		actionType,
		selectedMetricDetail,
		objectiveStartDate,
		objectiveEndDate,
		updateKeyAttrDetail,
		setIsContributorUpdated,
		deleteContributor,
		contributorDetails,
		setContributorDetails,
		checkAlignStatus,
		parentGoalTypeId,
		setCallOut,
		anchorElCallouts,
		setAnchorElCallouts,
		initialCallout,
		setShowAssignKrDateCallouts,
		showAssignKrDateCallouts,
		setShowAssignKrTargetCallouts,
		showAssignKrTargetCallouts,
		setShowAssignKrTitleCallouts,
		showAssignKrTitleCallouts,
		calloutsData,
		getPermissionResult,
		resetTeamListData,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const contributorObject = {
		employeeId: Enums.ZERO,
		designation: '',
		firstName: '',
		lastName: '',
		fullName: '',
		imagePath: '',
		krStatusId: Enums.ONE,
		krStatusName: 'Pending',
		assignmentTypeId: okr.goalTypeId === 1 ? 2 : 1,
		goalStatusId: Enums.TWO,
		goalTypeId: okr.goalTypeId || 2,
		keyResult: keyData?.keyDescription || keyAttribute?.keyDescription || '',
		score: Enums.ZERO,
		startDate: keyData.startDate || keyAttribute?.startDate,
		dueDate: keyData.dueDate || keyAttribute?.dueDate,
		startValue: keyAttribute?.startValue || Enums.ZERO,
		targetValue: keyAttribute?.targetValue || Enums.ZERO,
		krAssignerMessage: '',
		metricName: '',
		currencyName: '',
		objectiveName: actionType === 'editKR' ? okr.objectiveName : objectiveDetail?.objectiveName,
		cycleId: props.userSelectedCycle?.organisationCycleId || 0,
		year,
		isAddedNew: false,
	};
	const staticModalProps = {
		open: false,
		type: '',
		message: '',
		module: '',
		event: null,
		data: { metricId: '', type: '', date: '' },
	};
	const [modalProps, setModalProps] = useState(staticModalProps);
	const [hideDefaultButton, setHideDefaultButton] = useState<boolean>(false);
	const [addContributorAnchorEl, setAddContributorAnchorEl] = useState<HTMLElement | null>(null);
	const [addContributorOpen, setAddContributorOpen] = useState<boolean>(false);
	const [selectedContributorDetails, setSelectedContributorDetails] = useState<any>(contributorObject);
	const [showTeamsOkr, setShowTeamsOkr] = useState<boolean>(false);
	// const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
	const [isListEnabled, setIsListEnabled] = useState<boolean>(false);
	const [selectedValues, setSelectedValues] = React.useState<any>([]);
	const [treeNodesData, setTreeNodesData] = React.useState<any>(null);
	const [teamsLoading, setTeamsLoading] = useState<boolean>(true);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [completeOrgData, setCompleteOrgData] = React.useState<any[]>([]);
	const [teamDetails, setTeamDetails] = useState<any>(null);
	const [disabledNodes, setDisabledNodes] = React.useState<any>([]);
	const [krMsg, setKrMsg] = React.useState<any>('');
	const [showKrAddMessage, setShowKrAddMessage] = useState<boolean>(false);

	useEffect(() => {
		if (actionType === 'editKR') {
			setSelectedContributorDetails({
				...selectedContributorDetails,
				objectiveName: okr.objectiveName,
				goalStatusId: okr.goalStatusId || 2,
				goalTypeId: okr.goalTypeId || 2,
				assignmentTypeId: okr.goalTypeId === 1 ? 2 : 1,
				cycleId: props.userSelectedCycle?.organisationCycleId || 0,
			});
		}
	}, [okr]);

	useEffect(() => {
		if (contributorDetails.length > 0) {
			let isAddedNewContributor = false;
			contributorDetails.forEach((element: any) => {
				if (element.isAddedNew === true) {
					isAddedNewContributor = true;
				}
			});
			setKrMsg(keyAttribute.krMsg);
			setShowKrAddMessage(isAddedNewContributor);
		} else {
			setShowKrAddMessage(false);
		}
	}, [contributorDetails]);

	useEffect(() => {
		if (resetTeamListData) {
			setCompleteOrgData([]);
		}
	}, [resetTeamListData]);

	const onHandleAddUserClick = (event: any) => {
		if (goalTypeId === 2 || isContributorOnly === true) {
			prepareIndividualData();
			// setCustomStyle(false);
			setAddContributorAnchorEl(addContributorAnchorEl ? null : event.currentTarget);
			setAddContributorOpen(true);
		} else {
			setShowTeamsOkr(true);
			callTreeNodes(event);
		}
	};

	const getMinDate = (date: any, okrDate: any) => {
		const datesDifferenceInDays = moment(new Date(okrDate)).isAfter(new Date(formatDate(date) || new Date()), 'days');
		return datesDifferenceInDays ? okrDate : date;
	};

	const callTreeNodes = (event: any, scrollerId = '') => {
		getUserTeams(event, scrollerId);
	};

	const scrollTo = (e: any) => {
		setTimeout(function () {
			let element = document.getElementById(e);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);
	};
	const prepareIndividualData = () => {
		let contributorsList: any[] = [];
		let selVal: any[] = [];
		contributorDetails.forEach((childData: any) => {
			selVal.push(childData.employeeId);
			contributorsList.push({
				id: childData.employeeId,
				employeeId: childData.employeeId,
				fullName: childData.firstName + ' ' + childData.lastName,
				firstName: childData.firstName,
				lastName: childData.lastName,
				teamCount: childData.teamCount,
				membersCount: childData.membersCount,
				isExternal: childData.isExternal === true ? true : false,
				secondaryText: childData.designation,
				imagePath: childData.imagePath,
				backGroundColorCode: childData.backGroundColorCode,
				colorCode: childData.colorCode,
				parentID: childData.organisationId,
				isAlreadyAligned: childData.isAlreadyAligned,
				dueDate: childData['dueDate'],
				startValue: childData['startValue'],
				startDate: childData['startDate'],
				targetValue: childData['targetValue'],
				isAddedNew: childData.isAddedNew,
				krAssignerMessage: childData.krAssignerMessage,
			});
		});
		setTreeNodesData(contributorsList);
		setCompleteOrgData(contributorsList);
		setSelectedValues(selVal);
	};
	const getUserTeams = async (event: any, scrollerId = '') => {
		scrollTo('custom-scroll');
		let teamId = keyAttribute.teamId ? keyAttribute.teamId : objectiveDetail.teamId;
		let goalKeyId = kr?.goalKeyId ? kr.goalKeyId : 0;
		let sourceId = kr?.source ? kr.source : goalKeyId;
		if (completeOrgData.length === 0) {
			let isMetricChanged = keyAttribute.isMetricChanged ? true : false;
			const apiRes = await props.getOwnerTeamsData(
				'?teamId=' +
					teamId +
					'&sourceId=' +
					sourceId +
					'&goalKeyId=' +
					goalKeyId +
					'&isMetricChanged=' +
					isMetricChanged
			);
			setTreeNodesData(null);
			if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
				setTeamsLoading(false);
				setTeamDetails(apiRes?.data?.entity || []);
				prepareTeamData(apiRes?.data?.entity || []);
				scrollTo('custom-scroll');
			}
		} else {
			prepareTeamData(teamDetails);
			setTeamsLoading(false);
			scrollTo('custom-scroll');
		}
		if (scrollerId !== '') {
			scrollTo('custom-scroll-id-' + teamId + '_' + scrollerId);
		}
	};

	const prepareTreeChild = (orgData: any, childData: any) => {
		return {
			id: childData.employeeId,
			employeeId: childData.employeeId,
			backGroundColorCode: childData.backGroundColorCode,
			colorCode: childData.colorCode,
			teamName: childData.teamName,
			designation: childData.designation,
			employeeCode: childData.employeeId,
			firstName: childData.firstName,
			lastName: childData.lastName,
			imagePath: childData.imagePath,
			organisationId: orgData.organisationId,
			isExternal: true,
			teamId: childData.teamId,
			isAlreadyAligned: childData.isAlreadyAligned,
		};
	};

	const prepareTeamData = (data: any) => {
		let completeOrgData: any = [];
		let selValParent: string[] = [];
		let selValChild: string[] = [];
		let selVal: string[] = [];
		let nodesData: any = {};
		let parentCounter: any = {};
		let contributor = [];
		if (keyAttribute.contributors && keyAttribute.contributors.length > 0) {
			contributor = keyAttribute.contributors;
		} else if (contributorDetails && contributorDetails.length > 0) {
			contributor = contributorDetails;
		}
		if (contributor.length === 0) {
			if (keyAttribute.teamId) {
				selValParent.push(keyAttribute.teamId);
			} else if (objectiveDetail && objectiveDetail.teamOkrRequests && objectiveDetail.teamOkrRequests.length > 0) {
				objectiveDetail.teamOkrRequests.forEach((data: any) => {
					selValParent.push(data.id ? data.id : data.teamId);
				});
			}
		} else {
			selectedContributorDetails.krAssignerMessage = contributor[0].krAssignerMessage;
		}
		if (data && data.teamEmployees) {
			data.teamEmployees = data.teamEmployees.filter((element: any) => element.isExternal !== true);
		}
		let externalContributors: any[] = [];
		contributorDetails.forEach((contributor: any) => {
			if (data && data.teamEmployees && contributor.isExternal === true) {
				externalContributors.push(prepareTreeChild(data, contributor));
				//data.teamEmployees.push(prepareTreeChild(data, contributor));
				selVal.push('external_' + contributor.employeeId);
				nodesData['external_' + contributor.employeeId] = contributor;
			} else {
				if (contributor.isTeamSelected === true) {
					selValParent.push(contributor.teamId);
				} else {
					selVal.push(contributor.teamId + '_' + contributor.employeeId);
					selValChild.push(contributor.teamId + '_' + contributor.employeeId);
					nodesData[contributor.teamId + '_' + contributor.employeeId] = contributor;
				}
			}
		});
		let startDate = getMinDate(
			keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
			actionType === 'editKR' ? okr.startDate : objectiveStartDate
		);
		data = [data];
		if (externalContributors.length > 0) {
			data.push({
				id: 'external',
				organisationId: 'external',
				organisationName: '',
				membersCount: 0,
				backGroundColorCode: '',
				colorCode: '',
				teamEmployees: externalContributors,
				parentName: '',
				imagePath: '',
			});
		}
		
		let ownerId = 0;
		if (actionType === 'editKR' && okr?.goalTypeId) {
			ownerId = okr.owner;
		} else {
			ownerId = objectiveDetail.owner;
		}
		let tCount = 0;
		let disabledNodesList: any[] = [];
		data.forEach((orgData: any) => {
			let teamObj = {
				id: orgData.organisationId,
				fullName: orgData.organisationName,
				membersCount: orgData.membersCount,
				backGroundColorCode: orgData.backGroundColorCode,
				colorCode: orgData.colorCode,
				canHaveForm: true,
				children: [
					{
						id: '',
						fullName: '',
					},
				],
				isParent: true,
				secondaryText: orgData.parentName,
				imagePath: orgData.imagePath,
				isChecked: false,
				hasForm:
					selValParent.indexOf(orgData.organisationId) !== -1 ||
					(parentCounter[orgData.organisationId] &&
						parentCounter[orgData.organisationId] === orgData.teamEmployees.length),
				expanded: false,
				startDate: startDate,
				dueDate: keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate,
				startValue: keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue,
				targetValue: keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue,
			};
			if (nodesData[orgData.organisationId]) {
				teamObj = Object.assign(teamObj, {
					dueDate: nodesData[orgData.organisationId]['dueDate'],
					startValue: nodesData[orgData.organisationId]['startValue'],
					startDate: nodesData[orgData.organisationId]['startDate'],
					targetValue: nodesData[orgData.organisationId]['targetValue'],
				});
			}
			let orgEmpData = orgData.teamEmployees;
			let childrenData: any[] = [];
			orgEmpData?.forEach((childData: any) => {
				if (ownerId !== childData.employeeId) {
					let childKey = orgData.organisationId + '_' + childData.employeeId;
					let isExists = selValChild.indexOf(childKey) !== -1;
					let childObj = {
						id: orgData.organisationId + '_' + childData.employeeId,
						isChecked: isExists,
						employeeId: childData.employeeId,
						fullName: childData.firstName + ' ' + childData.lastName,
						firstName: childData.firstName,
						lastName: childData.lastName,
						teamCount: childData.teamCount,
						membersCount: childData.membersCount,
						isExternal: childData.isExternal === true ? true : false,
						secondaryText: childData.designation,
						imagePath: childData.imagePath,
						backGroundColorCode: childData.backGroundColorCode,
						colorCode: childData.colorCode,
						canHaveForm: true,
						isParent: false,
						parentID: orgData.organisationId,
						hasForm: isExists,
						teamId: orgData.organisationId,
						startDate: startDate,
						dueDate: keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate,
						teamName: orgData.organisationName,
						teambackGroundColorCode: orgData.backGroundColorCode,
						teamcolorCode: orgData.colorCode,
						userTeamId: orgData.organisationId === 'external' ? childData.teamId : orgData.organisationId,
						isAlreadyAligned: childData.isAlreadyAligned,
						startValue: keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue,
						targetValue: keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue,
					};
					if (isExists === true) {
						teamObj.expanded = true;
					}
					if (childData.isAlreadyAligned === true) {
						if (selVal.indexOf(childKey) === -1) {
							selVal.push(childKey);
						}
						disabledNodesList.push(childKey);
					}
					if (nodesData[childKey]) {
						childObj = Object.assign(childObj, {
							dueDate: nodesData[childKey]['dueDate'],
							startValue: nodesData[childKey]['startValue'],
							startDate: nodesData[childKey]['startDate'],
							targetValue: nodesData[childKey]['targetValue'],
							teamName: nodesData[childKey]['teamName'],
							teambackGroundColorCode: nodesData[childKey]['backGroundColorCode'],
							teamcolorCode: nodesData[childKey]['colorCode'],
						});
						teamObj.expanded = true;
					} else {
						childObj = Object.assign(childObj, {
							dueDate: teamObj['dueDate'],
							startValue: teamObj['startValue'],
							startDate: teamObj['startDate'],
							targetValue: teamObj['targetValue'],
						});
					}
					childrenData.push(childObj);
					tCount++;
				}
			});
			//teamObj.membersCount = tCount;
			teamObj.children = childrenData;
			completeOrgData.push(teamObj);
		});
		setDisabledNodes(disabledNodesList);
		setTreeNodesData(completeOrgData);
		setCompleteOrgData(completeOrgData);
		setSelectedValues(selVal);
		setTotalCount(tCount);
	};

	const onHandleShowAllContributors = (_event: any) => {
		setIsButtonDisable(false);
		setHideDefaultButton(false);
		setIsListEnabled((prev) => !prev);
	};

	const getTeamData = () => {
		let teamData: any = '';
		if (goalTypeId === 1) {
			if (actionType === 'editKR') {
				teamData = okr;
			} else {
				teamData = objectiveDetail;
			}
		}
		return teamData;
	};

	const onSaveTeamContributorsDetailClick = (
		_event: any,
		contributorDetail: any,
		selectedTeams: any,
		isParent: boolean
	) => {
		let updateContributors: any = [];
		selectedTeams.forEach((selectedTeam: any) => {
			if (
				selectedContributorDetails &&
				selectedContributorDetails.krAssignerMessage &&
				selectedContributorDetails.krAssignerMessage !== ''
			) {
				setSelectedContributorDetails({
					...selectedContributorDetails,
					krAssignerMessage: contributorDetail.krAssignerMessage,
				});
			}
			let idVal = selectedTeam.id;
			if (!selectedTeam.isParent) {
				idVal = selectedTeam.id.split('_')[1];
			}
			let existingContributor = [];
			if (!selectedTeam.isParent) {
				existingContributor = contributorDetails.filter((contri: any) => contri.employeeId === idVal);
			}
			let contData = {
				employeeId: selectedTeam.isParent ? 0 : idVal,
				assignmentTypeId: 2,
				isExternal: selectedTeam.isExternal === true ? true : false,
				objectiveName: contributorDetail.objectiveName,
				fullName: selectedTeam.fullName,
				firstName: selectedTeam.firstName,
				lastName: selectedTeam.lastName,
				designation: selectedTeam.secondaryText,
				keyResult: contributorDetail.keyResult,
				krStatusId: existingContributor.length > 0 ? existingContributor[0].krStatusId : Enums.ONE,
				krStatusName: existingContributor.length > 0 ? existingContributor[0].krStatusName : 'Pending',
				isSelfCreation: existingContributor.length > 0 ? existingContributor[0].isSelfCreation : false,
				score: 0,
				startDate: isParent === true ? contributorDetail.startDate : selectedTeam.startDate,
				dueDate: isParent === true ? contributorDetail.dueDate : selectedTeam.dueDate,
				startValue: isParent === true ? contributorDetail.startValue : selectedTeam.startValue,
				targetValue: isParent === true ? contributorDetail.targetValue : selectedTeam.targetValue,
				krAssignerMessage: contributorDetail.krAssignerMessage,
				imagePath: selectedTeam.imagePath,
				goalStatusId:  actionType === 'editKR' ? (okr.goalStatusId || 2) : 2,
				cycleId: props.userSelectedCycle?.organisationCycleId || 0,
				year: props.userSelectedCycle?.year,
				teamId: selectedTeam.isParent ? selectedTeam.id : selectedTeam.parentID === 'external' ? selectedTeam.userTeamId : selectedTeam.parentID,
				teamName: selectedTeam.teamName,
				backGroundColorCode: selectedTeam.teambackGroundColorCode,
				colorCode: selectedTeam.teamcolorCode,
				isTeamSelected: selectedTeam.isParent ? true : false,
				selectedMetric: selectedMetricDetail,
			};
			contData.startValue = contData.startValue === '' ? 0 : contData.startValue;
			contData.targetValue = contData.targetValue === '' ? 0 : contData.targetValue;
			updateContributors.push(contData);
		});
		setContributorDetails(updateContributors);
		setShowTeamsOkr(false);
		updateKeyAttrDetail('contributors', updateContributors);
		setIsButtonDisable(false);
		setIsContributorUpdated(true);
		enqueueSnackbar(t('contributorsAddedMsg'), {
			variant: 'success',
			autoHideDuration: 5000,
		});
	};

	const onSaveIndividualContributorsDetailClick = (
		_event: any,
		contributorDetail: any,
		selectedTeams: any,
		isParent: boolean
	) => {
		let updateContributors: any = [];
		selectedTeams.forEach((selectedTeam: any) => {
			if (
				selectedContributorDetails &&
				selectedContributorDetails.krAssignerMessage &&
				selectedContributorDetails.krAssignerMessage != ''
			) {
				setSelectedContributorDetails({
					...selectedContributorDetails,
					krAssignerMessage: contributorDetail.krAssignerMessage,
				});
			}
			let idVal = selectedTeam.id;
			let existingContributor = contributorDetails.filter((contri: any) => contri.employeeId == idVal);
			let contData = {
				employeeId: idVal,
				assignmentTypeId: 2,
				isExternal: selectedTeam.isExternal === true ? true : false,
				objectiveName: contributorDetail.objectiveName,
				fullName: selectedTeam.fullName,
				firstName: selectedTeam.firstName,
				lastName: selectedTeam.lastName,
				designation: selectedTeam.secondaryText,
				keyResult: contributorDetail.keyResult,
				krStatusId: existingContributor.length > 0 ? existingContributor[0].krStatusId : Enums.ONE,
				krStatusName: existingContributor.length > 0 ? existingContributor[0].krStatusName : 'Pending',
				isSelfCreation: existingContributor.length > 0 ? existingContributor[0].isSelfCreation : false,
				score: 0,
				startDate: selectedTeam.startDate,
				dueDate: selectedTeam.dueDate,
				startValue: selectedTeam.startValue,
				targetValue: selectedTeam.targetValue,
				imagePath: selectedTeam.imagePath,
				goalStatusId: actionType === 'editKR' ? (okr.goalStatusId || 2) : 2,
				cycleId: props.userSelectedCycle?.organisationCycleId || 0,
				year: props.userSelectedCycle?.year,
				backGroundColorCode: selectedTeam.teambackGroundColorCode,
				colorCode: selectedTeam.teamcolorCode,
				selectedMetric: selectedMetricDetail,
				isAddedNew: selectedTeam.isAddedNew,
				krAssignerMessage: selectedTeam.krAssignerMessage,
			};
			contData.startValue = contData.startValue === '' ? 0 : contData.startValue;
			contData.targetValue = contData.targetValue === '' ? 0 : contData.targetValue;
			updateContributors.push(contData);
		});
		setContributorDetails(updateContributors);
		setShowTeamsOkr(false);
		updateKeyAttrDetail('contributors', updateContributors);
		setIsButtonDisable(false);
		setIsContributorUpdated(true);
		enqueueSnackbar(t('contributorsAddedMsg'), {
			variant: 'success',
			autoHideDuration: 5000,
		});
	};

	const deleteContributorFromKR = async (
		contributor: any,
		updateContributor: boolean = true,
		showMessage: boolean = true
	) => {
		const { isAddedNew, employeeId, goalId } = contributor;
		if (isAddedNew) {
			if (updateContributor) {
				const updatedContributorDetails =
					contributorDetails && contributorDetails.length
						? contributorDetails.filter((item: any) => item.employeeId !== employeeId)
						: [];
				setContributorDetails(updatedContributorDetails);
				updateKeyAttrDetail('contributors', updatedContributorDetails);
				if (updatedContributorDetails.length === 0) {
					setIsListEnabled(false);
				}
			}
		} else {
			const data = `goalKeyId=${goalId || 0}&employeeId=${employeeId}`;
			const response = await deleteContributor(data);
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				/** Remove Contributor from List */
				if (updateContributor) {
					const updatedContributorDetails =
						contributorDetails && contributorDetails.length
							? contributorDetails.filter((item: any) => item.employeeId !== employeeId)
							: [];
					setContributorDetails(updatedContributorDetails);
					updateKeyAttrDetail('contributors', updatedContributorDetails);
					if (updatedContributorDetails.length === 0) {
						setIsListEnabled(false);
					}
				}
				if (showMessage) {
					enqueueSnackbar(response?.data?.messageList?.Result, {
						variant: 'success',
						autoHideDuration: 5000,
					});
				}
				// fetchOkr();
			} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
				const errorMessages = Object.values(response?.data?.messageList);
				if (errorMessages && errorMessages.length > 0) {
					errorMessages.forEach((item: any) =>
						enqueueSnackbar(item, {
							variant: 'error',
							autoHideDuration: 5000,
						})
					);
				}
			} else if (response && response.data.status === Enums.STATUS_SERVER_ERROR) {
				enqueueSnackbar(t('goalAddedFail'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		}
	};

	const onHandleCancelClick = (_event: any) => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		setHideDefaultButton(false);
		setIsButtonDisable(false);
		setSelectedContributorDetails(contributorObject);
	};

	const onSaveContributorDetailClick = (_event: any, contributorDetail: any) => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		if (Boolean(selectedContributorDetails)) {
			const isExist =
				contributorDetails && contributorDetails.length
					? contributorDetails.find((item: any) => item.employeeId === contributorDetail.employeeId)
					: null;
			const updateContributors =
				contributorDetails && contributorDetails.length
					? isExist
						? contributorDetails.map((item: any) => {
								if (isExist && item.employeeId === contributorDetail.employeeId) {
									item = contributorDetail;
								}
								return item;
						  })
						: [...contributorDetails, contributorDetail]
					: [...contributorDetails, contributorDetail];
			setContributorDetails(updateContributors);
			updateKeyAttrDetail('contributors', updateContributors);
			setHideDefaultButton(false);
			setIsButtonDisable(false);
			contributorObject.objectiveName = selectedContributorDetails.objectiveName;
			setSelectedContributorDetails(contributorObject);
			setIsContributorUpdated(true);
		}
	};

	const userAligned = async (selectedUser: any) => {
		const details = kr ? kr : okr;
		const goalKeyId = details?.goalKeyId ? details?.goalKeyId : 0;
		let sourceType = Enums.TWO;
		const sourceId = details?.source && details?.source > 0 ? details?.source : goalKeyId;
		const apiData = `sourceType=${sourceType}&sourceId=${sourceId}&employeeId=${selectedUser.employeeId}`;
		const resp = await checkAlignStatus(apiData);
		const { entity } = resp.data;
		let isAligned = false;

		if (props.isKeyResultDrawer) {
			if (details?.contributors.find((item: any) => item.employeeId === selectedUser.employeeId)) {
				isAligned = false;
			}
		} else {
			if (entity && entity?.isAligned === false) {
				isAligned = false;
			} else if (entity?.isAligned && entity?.alignStatus) {
				isAligned = true;
				if (entity?.alignStatus === Enums.ONE) {
					//Pending
					enqueueSnackbar(`${t('pendingUserMessage')} ${getFullName(selectedUser)}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				} else if (entity?.alignStatus === Enums.THREE) {
					//Declined
					enqueueSnackbar(`${getFullName(selectedUser)} ${t('declinedUserMessage')}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				} else {
					enqueueSnackbar(`${getFullName(selectedUser)} ${t('alreadyAlign')}`, {
						variant: 'info',
						autoHideDuration: 3000,
					});
				}
			} else {
				enqueueSnackbar(`${getFullName(selectedUser)} ${t('alreadyAlign')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			}
		}
		return isAligned;
	};

	const onDeleteContributor = (event: any, contributor: any, type: String) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'Delete') {
			setModalProps({
				open: true,
				type: 'DeleteKRContributor',
				message: t('deleteKRContributor'),
				module: 'user',
				event,
				data: contributor,
			});
		} else if (type === 'Edit') {
			if (goalTypeId === 2) {
				setSelectedContributorDetails({ ...contributor });
				setAddContributorAnchorEl(null);
				setAddContributorOpen(false);
				setHideDefaultButton(true);
				setIsButtonDisable(true);
				setIsListEnabled((prev) => !prev);
			} else {
				setSelectedContributorDetails({ ...contributor });
				setIsListEnabled((prev) => !prev);
				setShowTeamsOkr(true);
				setTreeNodesData(null);
				callTreeNodes(event, contributor.employeeId);
			}
		}
	};

	const handleCloseModal = (event: any, type: Number) => {
		event.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'DeleteKRContributor') {
				deleteContributorFromKR(modalProps.data);
			}
		}
		setModalProps(staticModalProps);
	};

	const handleKrMessageChange = (value: any) => {
		contributorDetails.forEach((element: any) => {
			if (element.isAddedNew === true) {
				element.krAssignerMessage = value;
			}
		});
	};

	return (
		<Box
			className={
				hideDefaultButton || addContributorOpen
					? 'kr-assign-contributor assignee-data add-contributor-open'
					: 'kr-assign-contributor assignee-data'
			}
		>
			<Box className='assign-contributor-label'>
				<Box display='flex' alignItems='center'>
					{contributorDetails && contributorDetails.length > 0 ? (
						<FormLabel component='legend'>{t('contributorsLabel')}</FormLabel>
					) : (
						<Fragment>
							<OKRButton
								className={`add-btn2 ${addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''}`}
								icon={<AddIconSvg />}
								color='primary'
								handleClick={onHandleAddUserClick}
								disabled={false}
								id={'add-contributor-btn'}
							/>
							<FormLabel component='legend'>{t('contributorsLabel')}</FormLabel>
						</Fragment>
					)}
				</Box>
				<Box>
					{contributorDetails && contributorDetails.length > 0 ? (
						<OKRButton
							className={`btn-link-type1 ${addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''}`}
							disabled={hideDefaultButton}
							icon={<EditIcon />}
							text={t('manageContributor')}
							handleClick={onHandleShowAllContributors}
							id={'mng-contributor-btn'}
						/>
					) : (
						<OKRButton
							className={`btn-link-type1 disabled ${
								addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''
							}`}
							disabled={hideDefaultButton}
							icon={<EditIcon />}
							text={t('manageContributor')}
							id={'mng-contributor-btn'}
						/>
					)}
				</Box>
			</Box>

			{contributorDetails && contributorDetails.length ? (
				<Box>
					<Box className='add-more-contri'>
						<Box className='kr-attribute-avatar-list'>
							<UserAvatarGroup
								{...props}
								t={t}
								max={10}
								contributorDetails={contributorDetails}
								handleAddUserClick={onHandleAddUserClick}
								addButtonClassName='add-btn'
								avatarClassName=''
								isButtonVisible={false}
								isButtonDisable={hideDefaultButton || isButtonDisable}
								isOnHoverVisible={true}
								contributorDetailsType={true}
								showEditHeader={true}
								hideScore={true}
								hideLinks={true}
								doNotAllowUserSelection={true}
								showTeamDetails={getTeamData() !== ''}
								onEditHeaderclick={() => {
									setIsListEnabled(true);
								}}
							/>
						</Box>
						{checkPermission(getPermissionResult?.employeePermissions, assignOkr) ? (
							<OKRButton
								className={`add-btn2 ${addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''}`}
								icon={<AddIconSvg />}
								color='primary'
								title={
									!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('AddMoreContributors') : ''
								}
								handleClick={onHandleAddUserClick}
								disabled={false}
								id={'add-contributor-btn'}
							/>
						) : (
							<></>
						)}
					</Box>
					{goalTypeId === 2 && showKrAddMessage === true && (
						<FormControl fullWidth>
							<TextField
								className='assign-contributor-message'
								id={'assign-contributor-msg'}
								defaultValue={keyAttribute.krMsg}
								onChange={(event: any) => {
									const { value } = event.target;
									handleKrMessageChange(value);
									setKrMsg(value);
									updateKeyAttrDetail('krMsg', value);
								}}
								multiline
								rows={2}
								InputLabelProps={{
									shrink: true,
								}}
								label={t('addMessage')}
								InputProps={{ 'aria-label': t('addMessage'), disableUnderline: true }}
								placeholder={t('addMessagePlaceholder')}
							/>
						</FormControl>
					)}
				</Box>
			) : (
				getTeamData() !== '' && (
					<Box className='kr-no-contributors-text'>
						<Typography variant='subtitle1'>
							<em>{t('ifNoContributors')}</em>
						</Typography>
					</Box>
				)
			)}
			{showTeamsOkr && (
				<TeamsKr
					{...props}
					disabledNodes={disabledNodes}
					selectedValues={selectedValues}
					setSelectedValues={setSelectedValues}
					treeNodesData={treeNodesData}
					setTreeNodesData={setTreeNodesData}
					open={showTeamsOkr}
					setOpen={setShowTeamsOkr}
					teamsLoading={teamsLoading}
					setDataUpdated={() => {}}
					setIsSaveDisabled={() => {}}
					totalCount={totalCount}
					t={t}
					selectedContributorDetails={selectedContributorDetails}
					keyAttribute={keyAttribute}
					okrMasterData={okrMasterData}
					onSaveTeamContributorsDetailClick={onSaveTeamContributorsDetailClick}
					getCurrencyIcon={getCurrencyIcon}
					selectedMetricDetail={selectedMetricDetail}
					keyData={keyData}
					completeOrgData={completeOrgData}
					setCompleteOrgData={setCompleteOrgData}
					deleteContributor={deleteContributorFromKR}
					contributorDetails={contributorDetails}
					type={2}
					durationCycleDetail={durationCycleDetail}
					objectiveStartDate={objectiveStartDate}
					objectiveEndDate={objectiveEndDate}
					onHandleCancelClick={onHandleCancelClick}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					dueCycle={okr?.dueCycle}
					userAligned={userAligned}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					initialCallout={initialCallout}
					showAssignKrDateCallouts={showAssignKrDateCallouts}
					setShowAssignKrDateCallouts={setShowAssignKrDateCallouts}
					setShowAssignKrTargetCallouts={setShowAssignKrTargetCallouts}
					showAssignKrTargetCallouts={showAssignKrTargetCallouts}
					showAssignKrTitleCallouts={showAssignKrTitleCallouts}
					setShowAssignKrTitleCallouts={setShowAssignKrTitleCallouts}
					calloutsData={calloutsData}
				/>
			)}

			{addContributorOpen ? (
				<IndividualKr
					{...props}
					t={t}
					open={addContributorOpen}
					setOpen={setAddContributorOpen}
					completeOrgData={completeOrgData}
					selectedValues={selectedValues}
					setSelectedValues={setSelectedValues}
					treeNodesData={treeNodesData}
					setTreeNodesData={setTreeNodesData}
					setDataUpdated={() => {}}
					setIsSaveDisabled={() => {}}
					totalCount={totalCount}
					selectedContributorDetails={selectedContributorDetails}
					keyAttribute={keyAttribute}
					onSaveIndividualContributorsDetailClick={onSaveIndividualContributorsDetailClick}
					getCurrencyIcon={getCurrencyIcon}
					selectedMetricDetail={selectedMetricDetail}
					keyData={keyData}
					setCompleteOrgData={setCompleteOrgData}
					deleteContributor={deleteContributorFromKR}
					contributorDetails={contributorDetails}
					type={2}
					onHandleCancelClick={onHandleCancelClick}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					dueCycle={okr?.dueCycle}
					userAligned={userAligned}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					initialCallout={initialCallout}
					showAssignKrDateCallouts={showAssignKrDateCallouts}
					setShowAssignKrDateCallouts={setShowAssignKrDateCallouts}
					setShowAssignKrTargetCallouts={setShowAssignKrTargetCallouts}
					showAssignKrTargetCallouts={showAssignKrTargetCallouts}
					showAssignKrTitleCallouts={showAssignKrTitleCallouts}
					setShowAssignKrTitleCallouts={setShowAssignKrTitleCallouts}
					calloutsData={calloutsData}
					krMsg={krMsg}
				/>
			) : (
				<></>
			)}
			{hideDefaultButton ? (
				<AddEditContributorDetail
					{...props}
					t={t}
					type={2}
					keyData={keyData}
					keyAttribute={keyAttribute}
					durationCycleDetail={durationCycleDetail}
					objectiveStartDate={objectiveStartDate}
					objectiveEndDate={objectiveEndDate}
					setKRContributorDetailDashboard={() => {}}
					selectedContributorDetails={selectedContributorDetails}
					onHandleCancelClick={onHandleCancelClick}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					getCurrencyIcon={getCurrencyIcon}
					hideBackButton={true}
					handleCancel={onHandleCancelClick}
					goalTypeId={goalTypeId}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					initialCallout={initialCallout}
					calloutsData={calloutsData}
				/>
			) : (
				<></>
			)}
			{isListEnabled && (
				<ManageContributorList
					{...props}
					contributorDetails={contributorDetails}
					keyAttribute={keyAttribute}
					keyData={keyData}
					objectiveEndDate={objectiveEndDate}
					getCurrencyIcon={getCurrencyIcon}
					onDeleteContributor={onDeleteContributor}
					t={t}
					teamData={getTeamData()}
					goalTypeId={parentGoalTypeId ? parentGoalTypeId : goalTypeId}
					open={isListEnabled}
					setOpen={setIsListEnabled}
					durationCycleDetail={durationCycleDetail}
					objectiveStartDate={objectiveStartDate}
					selectedContributorDetails={selectedContributorDetails}
					setContributorDetails={setContributorDetails}
					updateKeyAttrDetail={updateKeyAttrDetail}
					setIsContributorUpdated={() => {}}
					dueCycle={okr?.dueCycle}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					anchorElCallouts={anchorElCallouts}
					initialCallout={initialCallout}
					calloutsData={calloutsData}
				/>
			)}

			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
					isCancel={false}
				/>
			)}
		</Box>
	);
};
