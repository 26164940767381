import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
// import DirectReportsMap from '../DirectReportsMap';
import DirectReportsMap from '../DirectReportsMap/index2';
import DirectReportsMapCompact from '../DirectReportsMap/compact';
import DirectReportsMapUltra from '../DirectReportsMap/ultracompact';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import AlignmentImage from '../../../images/alignment-bg.svg';
import noRecordFound from '../../../images/search-placeholder.svg';

export const DirectReportsTabs: React.FC<any> = (props) => {
	const { tabValue, userDetail, directReportResultStatus, directReportResult, tabFilter, t, alignmentFilterData } =
		props;
	return (
		<Box className='alignment-direct-reports-tab'>
			{directReportResultStatus === 'success' && directReportResult && directReportResult.length === 1 ? (
				<Box display='flex' justifyContent='center'>
					<Box className='no-record-message' textAlign='center'>
						<Typography variant='h2' component='h2'>
							{t('NoRecord')}
						</Typography>
						{/* <Typography variant='h3'>{t('allDone')}</Typography> */}
						{/* <Typography>{t('noOkr')}</Typography> */}
						<img width='440' alt={t('globalSearchNoRecord')} src={noRecordFound} />
					</Box>
				</Box>
			) : directReportResultStatus === 'success' && directReportResult && directReportResult.length > 1 ? (
				props.tabFilter === 'relaxed' ? (
					<DirectReportsMap {...props} userDetail={userDetail} tabValue={tabValue} tabFilter={tabFilter} />
				) : props.tabFilter === 'compact' ? (
					<DirectReportsMapCompact {...props} userDetail={userDetail} tabValue={tabValue} tabFilter={tabFilter} />
				) : (
					<DirectReportsMapUltra {...props} userDetail={userDetail} tabValue={tabValue} tabFilter={tabFilter} />
				)
			) : directReportResultStatus === 'pending' ? (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
};
