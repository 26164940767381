import * as React from 'react';
import {
	LayoutAnimation,
	DataBinding,
	DiagramComponent,
	SnapConstraints,
	NodeConstraints,
	Inject,
	DiagramTools,
	ComplexHierarchicalTree,
	ChildArrangement,
	LineDistribution,
	ConnectionPointOrigin,
	ConnectorConstraints,
} from '@syncfusion/ej2-react-diagrams';
import { Box, IconButton, Typography } from '@material-ui/core';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { IndividualSmallIcon, LinkIcon } from '../../../config/svg/CommonSvg';
import { SampleBase } from './sample-base';
import { DataManager } from '@syncfusion/ej2-data';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import ObjectiveCard from '../AlignmentListing/ObjectiveCard';
import StandaloneCard from '../AlignmentListing/StandaloneCard';
import { DiagramLayoutHelper } from '../DiagramHelper/diagram-layout-helper';
import { DiagramSelectionHelper } from '../DiagramHelper/diagram-selection-helper';
import { DiagramOkrHelper } from '../DiagramHelper/diagram-okr-helper';
import { DiagramJsonHelper, TemplateType } from '../DiagramHelper/diagram-json-helper';
import DiagramOverview from '../DiagramHelper/diagram-overview-component';
import { DiagramHelperUtil, MapTypes, MapViews } from '../DiagramHelper/diagram-helper-util';
import { getUserDetails } from '../../../config/utils';

let diagramInstance;
let template;
let diagramTimer;
let diagramLayoutHelper;
let diagramSelectionHelper;
let diagramOkrHelper;
let alignmentData = [];
let ref_overview;
let diagramRendered = false;
let scaleValue;
let userDetail;
let setStateList = [];
let previouseActiveNode = null;
let mapView = MapViews.Team;

export default class CompactViewTeamMap extends SampleBase {
	constructor(props) {
		super(props);
		diagramRendered = false;
		this.state = {
			overviewProps: { diagramId: 'diagram' },
		};
		userDetail = getUserDetails();
		template = this.diagramTemplate.bind(this);
		DiagramHelperUtil.MapType = MapTypes.CompactViewTeamMap;
		diagramLayoutHelper = new DiagramLayoutHelper();
		diagramSelectionHelper = new DiagramSelectionHelper();
		diagramOkrHelper = new DiagramOkrHelper(userDetail);
		ref_overview = React.createRef();
	}

	updateNodeData(nextProps) {
		// let nodes = diagramLayoutHelper.GetJsonNodes(diagramInstance);
		let okrObj = {};
		let krObj = {};
		nextProps.alignmentMapAction.value.forEach((item) => {
			if (item.okrId) {
				okrObj[item.okrId] = item;
			}
			if (item.krId) {
				krObj[item.krId] = item;
			}
		});
		// console.log('objs', okrObj, krObj);

		let nodeToUpdate = nextProps.alignmentMapAction.value
			.map((item) =>
				alignmentData.find(
					(node) => (item.okrId && node.objectiveId === item.okrId) || (!item.okrId && node.objectiveId === item.krId)
				)
			)
			.filter((i) => i)
			.map((j) => ({
				...j,
				score:
					j.objectiveType === 1
						? okrObj[j.objectiveId]
							? okrObj[j.objectiveId].okrScore
							: j.score
						: krObj[j.objectiveId]
						? krObj[j.objectiveId].krScore
						: j.score,
				krCurrentValue: krObj[j.objectiveId] ? krObj[j.objectiveId].krCurrentValue : j.krCurrentValue,
				inValue: krObj[j.objectiveId] ? krObj[j.objectiveId].inValue : j.inValue,
				outValue: krObj[j.objectiveId] ? krObj[j.objectiveId].outValue : j.outValue,
				currencyInValue: krObj[j.objectiveId] ? krObj[j.objectiveId].currencyInValue : j.currencyInValue,
				currencyOutValue: krObj[j.objectiveId] ? krObj[j.objectiveId].currencyOutValue : j.currencyOutValue,

				okrViewKeyResults: j.okrViewKeyResults.map((kr) => ({
					...kr,
					krCurrentValue: krObj[kr.krId] ? krObj[kr.krId].krCurrentValue : kr.krCurrentValue,
					krScore: krObj[kr.krId] ? krObj[kr.krId].krScore : kr.krScore,
					inValue: krObj[kr.krId] ? krObj[kr.krId].inValue : kr.inValue,
					outValue: krObj[kr.krId] ? krObj[kr.krId].outValue : kr.outValue,
					currencyInValue: krObj[kr.krId] ? krObj[kr.krId].currencyInValue : kr.currencyInValue,
					currencyOutValue: krObj[kr.krId] ? krObj[kr.krId].currencyOutValue : kr.currencyOutValue,
				})),
			}));

		// console.log('nodeToUpdate', nodeToUpdate);

		alignmentData.forEach((item) => {
			nodeToUpdate.forEach((i) => {
				if (i.objectiveId === item.objectiveId) {
					item.score = i.score;
					item.krCurrentValue = i.krCurrentValue;
					item.inValue = i.inValue;
					item.outValue = i.outValue;
					item.currencyInValue = i.currencyInValue;
					item.currencyOutValue = i.currencyOutValue;
					item.okrViewKeyResults = [...i.okrViewKeyResults];
				}
			});
		});

		// console.log('updated .........', alignmentData);

		nodeToUpdate.forEach((item) => {
			// console.log('...update happens here...', item);
			setTimeout(() => {
				this.refreshNodeData(item);
			}, 0);
		});

		// console.log('nodeToUpdate', nodeToUpdate);
	}

	checkUnreadFeedback(data) {
		const { alignResult } = this.props;

		const allUnread = alignResult.okrViewResponses.filter(
			(item) => item.isMyOkr && (item.isUnreadFeedback || item.okrViewKeyResults.some((i) => i.isUnreadFeedback))
		);

		const teamUnread = data.filter(
			(item) => item.isMyOkr && (item.isUnreadFeedback || item.okrViewKeyResults.some((i) => i.isUnreadFeedback))
		);

		// const teamUnread = allUnread && allUnread.length && allUnread.filter((item) => item.teamId);

		// console.log('team', this.props, allUnread);
		return {
			allUnread: allUnread.length + teamUnread.length,
			teamUnread: teamUnread.length,
		};
	}

	markDataRead(data, objectiveId, krId) {
		data.forEach((item) => {
			if (item.objectiveId === objectiveId) {
				if (krId) {
					item.okrViewKeyResults.forEach((i) => {
						if (i.krId === krId) {
							i.isUnreadFeedback = false;
						}
						this.refreshNodeData(item);
					});
				} else {
					item.isUnreadFeedback = false;
					this.refreshNodeData(item);
				}
			}
		});
	}

	viewFeedbackUpdate(nextProps) {
		if (nextProps.alignmentMapAction.value && nextProps.alignmentMapAction.value.length) {
			let objectiveId = nextProps.alignmentMapAction.value[0].objectiveId;
			let krId = nextProps.alignmentMapAction.value[0].krId;
			this.markDataRead(alignmentData, objectiveId, krId);
			const { alignResult } = this.props;
			this.markDataRead(alignResult.okrViewResponses, objectiveId, krId);
			this.props.setIsUnreadFeedback(this.checkUnreadFeedback(alignmentData));
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		// if (nextProps.tabFilter !== this.props.tabFilter) {
		// 	let nodes = diagramLayoutHelper.GetJsonNodes(diagramInstance);
		// 	this.setMapType(nextProps.tabFilter);
		// 	if (this.props.tabFilter === 'relaxed') {
		// 		diagramSelectionHelper.SetHelpers(diagramInstance, diagramLayoutHelper, diagramOkrHelper);
		// 		diagramSelectionHelper.RemoveAllSelection();
		// 		diagramSelectionHelper.RemoveVirualLink();
		// 	}
		// 	DiagramHelperUtil.TimerStart('new bind');
		// 	for (let i = 0; i < nodes.length; i++) {
		// 		let n = nodes[i];
		// 		updateSizeOnTabSwitch(n, diagramInstance);
		// 		let nodeSetState = setStateList.pop();
		// 		if (nodeSetState) {
		// 			nodeSetState(nextProps.tabFilter);
		// 		}
		// 	}
		// 	while (setStateList && setStateList.length > 0) {
		// 		let nodeSetState = setStateList.pop();
		// 		if (nodeSetState) {
		// 			nodeSetState(nextProps.tabFilter);
		// 		}
		// 	}

		// 	DiagramHelperUtil.TimerEnd('new bind');

		// 	DiagramHelperUtil.TimerStart('new bind layout');
		// 	diagramLayoutHelper.DoCustomOkrLayout(diagramInstance, diagramOkrHelper, false);
		// 	DiagramHelperUtil.TimerEnd('new bind layout end');

		// 	DiagramHelperUtil.TimerStart('new bind dia ins start');
		// 	DiagramHelperUtil.RefreshDiagram(diagramInstance);
		// 	DiagramHelperUtil.TimerEnd('new bind dia ins  end');
		// 	this.updateOverview();
		// 	return false;
		// }
		// console.log('update', nextProps.alignmentMapAction, this.props.alignmentMapAction);
		if (
			diagramInstance &&
			diagramInstance.nodes &&
			nextProps &&
			nextProps.alignmentMapAction &&
			nextProps.alignmentMapAction.value &&
			nextProps.alignmentMapAction.value.length &&
			// this.props.alignmentMapAction.value &&
			// this.props.alignmentMapAction.value.length &&
			nextProps.alignmentMapAction.status &&
			nextProps.alignmentMapAction.status === 'PENDING'
		) {
			let alignmentMapAction = {
				...nextProps.alignmentMapAction,
				status: 'PROCESSING',
			};
			this.props.setAlignmentMapAction(alignmentMapAction);

			// update node and diagram
			if (nextProps.alignmentMapAction.actionType === 'UPDATE_PROGRESS') {
				this.updateNodeData(nextProps);
			} else if (nextProps.alignmentMapAction.actionType === 'VIEW_FEEDBACK') {
				this.viewFeedbackUpdate(nextProps);
			}

			alignmentMapAction = {
				...nextProps.alignmentMapAction,
				status: 'COMPLETED',
			};
			this.props.setAlignmentMapAction(alignmentMapAction);
		}
		// return true;
		/* if (
			nextProps &&
			nextProps.alignmentMapAction &&
			(nextProps.alignmentMapAction.actionType !== this.props.alignmentMapAction.actionType ||
				nextProps.alignmentMapAction.status === 'RELOAD')
		) {
			return true;
		} */
		return false;
	}

	onItemClick(args) {
		switch (args.item.text) {
			case 'Zoom In':
				let zoomin = { type: 'ZoomIn', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomin);
				this.updateOverview();
				break;
			case 'Zoom Out':
				let zoomout = { type: 'ZoomOut', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomout);
				this.updateOverview();
				break;
			case 'Reset':
				diagramInstance.reset();
				diagramSelectionHelper.RemoveAllSelection();
				this.layoutDiagram();
				break;
			case 'ToggleExpand':
				expandAll();
				break;
			case 'Map':
				ToggleButton();
				break;
			default:
				break;
		}
	}
	componentWillUnmount() {
		let diagramContainer = document.getElementById('diagram-container');
		if (diagramContainer) {
			//diagramContainer.removeEventListener("mousemove", this.handleDiagramAreaMouseMove);
			diagramContainer.removeEventListener('click', this.handleDiagramAreaMouseClick);
		}
	}
	componentDidMount() {
		let diagramContainer = document.getElementById('diagram-container');
		if (diagramContainer) {
			//diagramContainer.addEventListener('mousemove', this.handleDiagramAreaMouseMove);
			diagramContainer.addEventListener('click', this.handleDiagramAreaMouseClick.bind(this));
		}
	}

	handleDiagramAreaMouseClick(e) {
		diagramSelectionHelper.OnDiagramMouseClick(e, diagramInstance, diagramLayoutHelper, diagramOkrHelper);

		let nodeId = diagramSelectionHelper.GetNodeIdFromElement(e.target, diagramInstance);
		let node = diagramInstance.nodes.find((n) => n.id === nodeId);

		if (
			(!node && previouseActiveNode && previouseActiveNode.activeNode) ||
			(node &&
				previouseActiveNode &&
				previouseActiveNode.activeNode &&
				previouseActiveNode.objectiveId !== node.data.objectiveId)
		) {
			alignmentData.forEach((item) => {
				if (
					previouseActiveNode.objectiveId === item.objectiveId &&
					!e.target.closest('.kebab-icon') &&
					!e.target.closest('.settings-btn') &&
					((node && node.id.indexOf('_sourceIcon') < 0) || !node) &&
					((node && node.id.indexOf('_contributorIcon') < 0) || !node)
				) {
					previouseActiveNode.activeNode = false;
					item.activeNode = false;
					setTimeout(() => {
						this.refreshNodeData(item);
					}, 0);
				}
			});
		}

		if (node) {
			if (
				(DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
					DiagramHelperUtil.MapType === MapTypes.CompactViewTeam) &&
				diagramSelectionHelper.IsOkrNode(node)
			) {
				let pendingNode =
					node.data.okrViewKeyResults && node.data.okrViewKeyResults.find((item) => item.krStatusId === 1);
				if (pendingNode) {
					node.data.activeNode = true;
					previouseActiveNode = { ...node.data };
					alignmentData.forEach((item) => {
						if (
							node.data.objectiveId === item.objectiveId &&
							!e.target.closest('.kebab-icon') &&
							!e.target.closest('.settings-btn') &&
							node.id.indexOf('_sourceIcon') < 0
						) {
							item.activeNode = true;
							setTimeout(() => {
								this.refreshNodeData(item);
							}, 0);
						}
					});
				}
			}
		}
	}

	handleDiagramAreaMouseMove(e) {
		diagramSelectionHelper.OnDiagramMouseMove(e, diagramInstance);
	}
	refreshNodeData(data) {
		let objectiveUniqueId = data.objectiveUniqueId;
		if (diagramInstance && objectiveUniqueId) {
			let templateNodes = diagramInstance.nodes.filter((n) => n.reactProps);
			let nodeToUpdate = templateNodes.find((n) => n.data && n.data.objectiveUniqueId === objectiveUniqueId);
			if (nodeToUpdate) {
				nodeToUpdate.data = data;
				let reactProps = nodeToUpdate.reactProps;
				reactProps.setOkrData.forEach((f) => {
					// console.log('react props');
					f({ data: {} });
					f(nodeToUpdate);
				});
				if (reactProps.setKrData) {
					reactProps.setKrData.forEach((f) => {
						f({ data: {} });
						f(nodeToUpdate);
					});
				}
				this.refreshKrHelpers(nodeToUpdate.data);
			}
		}
	}
	refreshKrHelpers(data) {
		if (data && diagramSelectionHelper && diagramInstance) {
			let krItems = [];
			if (data.objectiveType === 2) {
				krItems = [data];
			} else {
				krItems = data.okrViewKeyResults && data.okrViewKeyResults.length > 0 ? data.okrViewKeyResults : [];
			}
			let helperNode;
			let krNodeId = 'krResItem_';
			for (let i = 0; i < krItems.length; i++) {
				if (krItems[i].objectiveType === 2) {
					krNodeId += 'sta:';
				}
				krNodeId += krItems[i].krUniqueId;
				helperNode = diagramInstance.nodes.find((n) => n.id === krNodeId + '_srcNode_link_helper');
				if (helperNode && helperNode.annotations && helperNode.annotations.length > 0) {
					helperNode.annotations[0].content = diagramSelectionHelper.GetKrSelectionText(krItems[i], 'source');
					DiagramHelperUtil.RefreshUI(helperNode);
					diagramSelectionHelper.AddSelectionStyleToKr(krItems[i]);
				}
				helperNode = diagramInstance.nodes.find((n) => n.id === krNodeId + '_targetNode_link_helper');
				if (helperNode && helperNode.annotations && helperNode.annotations.length > 0) {
					helperNode.annotations[0].content = diagramSelectionHelper.GetKrSelectionText(krItems[i], 'target');
					DiagramHelperUtil.RefreshUI(helperNode);
					diagramSelectionHelper.AddSelectionStyleToKr(krItems[i]);
				}
			}
		}
	}
	setReactProps(nodeId, propName, propValue) {
		if (diagramInstance && !diagramRendered) {
			let actualNode = diagramInstance.nodes.find((n) => n.id === nodeId);
			if (actualNode) {
				actualNode['reactProps'] = actualNode['reactProps'] ? actualNode['reactProps'] : [];
				actualNode['reactProps'][propName] = actualNode['reactProps'][propName]
					? actualNode['reactProps'][propName]
					: [];
				actualNode['reactProps'][propName].push(propValue);

				if (!actualNode['refreshMe']) {
					actualNode['refreshMe'] = this.refreshNodeData.bind(this);
				}
			}
		}
	}
	diagramTemplate(node) {
		if (node.data) {
			if (node.data.objectiveType === 2) {
				const [tabFilter, setTabFiter] = React.useState(this.props.tabFilter);
				setStateList.push(setTabFiter);
				const [nodeObj, setOkrData] = React.useState(node);
				this.setReactProps(node.data.diagramParentNodeId, 'setOkrData', setOkrData);
				return (
					<StandaloneCard
						{...this.props}
						data={nodeObj}
						userDetail={this.props.userDetail}
						handleDeleteOkr={this.props.handleDeleteOkr}
						setOpenDrawer={this.props.setOpenDrawer}
						setModalProps={this.props.setModalProps}
						updateProgress={this.props.updateProgress}
						showEditLoader={this.props.showEditLoader}
						isLocked={this.props.isLocked}
						diagramInstance={diagramInstance}
						tabFilter={tabFilter}
					/>
				);
			}
			if (node.id.indexOf('contributorIcon') >= 0 && node.data.isContributorExist && !node.data.isParentOkr) {
				return (
					<IconButton className='connect-icon connect-icon-first'>
						<IndividualSmallIcon />
					</IconButton>
				);
			}
			if (node.id.indexOf('sourceIcon') >= 0 && node.data.isSourceExist) {
				if (node.data.isobjectiveType === 2) {
					return (
						<IconButton
							className={`connect-icon connect-icon-link ${node.data.isactionLevel > 0 ? 'default-icon' : ''}`}
						>
							<LinkIcon />
						</IconButton>
					);
				} else {
					return (
						<IconButton
							className={`connect-icon connect-icon-arrow ${node.data.isactionLevel > 0 ? 'default-icon' : ''}`}
						>
							<RightArrowIcon />
						</IconButton>
					);
				}
			}
			if (node.data.templateType === TemplateType.Okr) {
				const [tabFilter, setTabFiter] = React.useState(this.props.tabFilter);
				setStateList.push(setTabFiter);
				const [nodeObj, setOkrData] = React.useState(node);
				this.setReactProps(node.id, 'setOkrData', setOkrData);
				return (
					<ObjectiveCard
						{...this.props}
						setCount={this.props.setCount}
						tabFilter={tabFilter}
						data={nodeObj}
						userDetail={this.props.userDetail}
						handleDeleteOkr={this.props.handleDeleteOkr}
						setModalProps={this.props.setModalProps}
						updateProgress={this.props.updateProgress}
						showEditLoader={this.props.showEditLoader}
						showAddGoalForm={this.props.showAddGoalForm}
						showAddGoal={this.props.showAddGoal}
						durationCycleDetail={this.props.durationCycleDetail}
						currentCycleEndDate={this.props.currentCycleEndDate}
						currentCycleId={this.props.currentCycleId}
						year={this.props.year}
						t={this.props.t}
						fetchOkr={this.props.fetchOkr}
						setGoalDetail={this.props.setGoalDetail}
						setIsGoalCreated={this.props.setIsGoalCreated}
						saveAndEditObjectiveAndKr={this.props.saveAndEditObjectiveAndKr}
						setFormSubmit={this.props.setFormSubmit}
						formSubmit={this.props.formSubmit}
						isGoalCreated={this.props.isGoalCreated}
						isLocked={this.props.isLocked}
						scaleValue={scaleValue}
						diagramInstance={diagramInstance}
					/>
				);
			}
		}
		return '';
	}
	setDiagramTimer() {
		if (diagramTimer) {
			clearTimeout(diagramTimer);
			this.toggleDiagramLoading(true);
		}
		diagramTimer = setTimeout(() => {
			this.toggleDiagramLoading(false);
			this.onAfterDiagramCreated();
			diagramRendered = true;
		}, 10);
	}
	onAfterDiagramCreated() {
		if (diagramInstance && diagramInstance.nodes) {
			diagramInstance.layout.type = 'None';
			diagramSelectionHelper.DoReverseConnection(diagramInstance);
			diagramOkrHelper.CreateOkrNodes(diagramInstance, diagramSelectionHelper);
			this.layoutDiagram();
			DiagramHelperUtil.RefreshDiagram(diagramInstance);
		}
	}
	layoutDiagram() {
		if (diagramInstance) {
			diagramSelectionHelper.HideExceptMyOkrs(diagramInstance);
			diagramOkrHelper['ref_overview'] = ref_overview;
			diagramLayoutHelper.DoCustomOkrLayout(diagramInstance, diagramOkrHelper, true);
			diagramLayoutHelper.FocusMyOkrs(diagramInstance);
			//this.updateOverview();
		}
	}
	updateOverview() {
		if (ref_overview && ref_overview.current) ref_overview.current.updateOverview();
	}
	toggleDiagramLoading(isShow) {
		let wText = document.querySelector('.control-section .welcome-content');
		if (wText) {
			wText.style.display = isShow ? '' : 'none';
		}
	}
	setMapType(tabFilter) {
		tabFilter = tabFilter ? tabFilter : this.props.tabFilter;
		DiagramHelperUtil.SetMapType(tabFilter, mapView);
		diagramOkrHelper.Reset();
	}
	render() {
		this.setMapType();
		const { teamResult } = this.props;
		this.setDiagramTimer();
		if (teamResult.length <= 0) {
			return (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography>{this.props.t('loading')}</Typography>
					</Box>
				</Box>
			);
		} else {
			alignmentData = teamResult.okrViewResponses;
			alignmentData =
				alignmentData &&
				alignmentData.length > 0 &&
				alignmentData.map((item, i) => {
					//item.index = i + 1;
					if (item.parent.length === 0) {
						item.parent = '';
					}
					return item;
				});
			DiagramJsonHelper.UpdateHelperProperties(alignmentData);
			this.props.setIsUnreadFeedback(this.checkUnreadFeedback(alignmentData));
		}

		return (
			<Box className='diagram-control-pane okr-view-relaxed compact-tab'>
				<MenuComponent items={this.menuItems} />
				<Box className='control-panel'>
					<Box className='control-section'>
						<Box className='content-wrapper'>
							<ToolbarComponent
								id='toolbar_diagram'
								clicked={this.onItemClick.bind(this)}
								items={[
									{
										type: 'Button',
										//tooltipText: 'ZoomIn',
										text: 'Zoom In',
										//prefixIcon: 'e-diagram-icons e-diagram-zoomin',
									},
									{
										type: 'Button',
										//tooltipText: 'ZoomOut',
										text: 'Zoom Out',
										//prefixIcon: 'e-diagram-icons e-diagram-zoomout',
									},
									{
										type: 'Button',
										text: 'Map',
									},
									{
										type: 'Button',
										//tooltipText: 'Reset',
										text: 'Reset',
										//prefixIcon: 'e-diagram-icons e-diagram-reset',
									},

									/*{
										type: 'Button',
										//tooltipText: 'Reset',
										text: 'ToggleExpand',
										//prefixIcon: 'e-diagram-icons e-diagram-reset',
									},*/
								]}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='control-section'>
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography>{this.props.t('loading')}</Typography>
						</Box>
					</Box>
					<Box id='diagram-container' className='content-wrapper'>
						<DiagramComponent
							id='diagram'
							ref={(diagram) => (diagramInstance = diagram)}
							width={'100%'}
							//height={'1500px'}
							height={'calc(100vh - 224px)'}
							snapSettings={{ constraints: SnapConstraints.None }}
							nodeTemplate={template}
							//configures data source settings
							dataSourceSettings={{
								id: 'objectiveUniqueId',
								parentId: 'parent',
								dataSource: new DataManager(alignmentData),
								doBinding: (nodeModel, data, diagram) => {
									//nodeModel.excludeFromLayout = true;
									nodeDefaults(nodeModel, diagram);
								},
							}}
							layout={{
								connectionPointOrigin: ConnectionPointOrigin.SamePoint,
								type: 'ComplexHierarchicalTree',
								arrangement: ChildArrangement.Linear,
								horizontalSpacing: 200,
								verticalSpacing: 130,
							}}
							//Disables all interactions except zoom/pan
							tool={DiagramTools.ZoomPan}
							//Defines the default node and connector properties
							getNodeDefaults={(obj, diagram) => {
								/* tslint:disable:no-string-literal */
								obj.constraints = NodeConstraints.Default & ~NodeConstraints.Select;
							}}
							getConnectorDefaults={(connector, diagram) => {
								return connectorDefaults(connector, diagram);
							}}
							pageSettings={{
								// Sets the PageOrientation for the diagram to page
								orientation: 'Landscape',
								// Sets the Page Break for diagram
								showPageBreaks: false,
								// Defines the background color and image  of diagram
								background: {
									color: 'none',
								},
								scrollLimit: 'Infinity',
							}}
							scrollChange={() => {
								if (ref_overview && ref_overview.current && !ref_overview.current.state.dragging) {
									this.updateOverview();
								}
							}}
						>
							<Inject services={[DataBinding, ComplexHierarchicalTree, LineDistribution]} />
						</DiagramComponent>
						<DiagramOverview ref={ref_overview} {...this.state.overviewProps}></DiagramOverview>
					</Box>
				</Box>
				{/* <button onClick={() => expandAll()}>Expand All</button> */}
			</Box>
		);
	}
}

function nodeDefaults(obj, diagram) {
	obj.constraints = NodeConstraints.Default & ~NodeConstraints.Select;
	if (!diagramSelectionHelper.IsSelectionHelper(obj)) {
		obj.visible = false;
		obj.data.templateType = TemplateType.Okr;
		obj.style = {
			fill: 'none',
			strokeColor: 'none',
			strokeWidth: 1,
			color: 'white',
		};
		obj.shape = {
			type: 'HTML',
			cornerRadius: 6,
		};
		obj.width = 500;
		if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr) {
			obj.width = 300;
		}
		obj.data.width = obj.width;
		if (obj.data.objectiveType === 2) {
			if (obj.data.krUniqueId) {
				obj.id = 'sta-' + obj.data.krUniqueId;
			} else {
				obj.id = 'sta-' + obj.data.objectiveUniqueId;
			}
		}
		obj.height = obj.data.height = diagramOkrHelper.GetOkrHeight(obj);
		obj.ports = [
			diagramOkrHelper.getLeftPort(obj),
			diagramOkrHelper.getRightPort(obj),
			diagramOkrHelper.getVLeftPort(obj),
			diagramOkrHelper.getVRightPort(obj),
		];
	}
	return obj;
}
//sets default value for Connector.
function connectorDefaults(connector, diagram) {
	connector.constraints = ConnectorConstraints.Default & ~ConnectorConstraints.Select;
	if (!diagramSelectionHelper.IsSelectionHelper(connector)) {
		connector.targetDecorator.shape = 'Arrow';
		connector.type = 'Orthogonal'; // "Bezier";
		// connector.type = "Bezier";
		connector.visible = false;
		connector.cornerRadius = 100;
		connector.targetDecorator.height = 10;
		connector.targetDecorator.width = 10;
		connector.style = { strokeColor: '#5B708B', strokeWidth: 3 };
		connector.targetDecorator.style = {
			fill: '#5B708B',
			strokeColor: '#5B708B',
			strokeWidth: 3,
		};
		//connector.targetPadding = 10;
		return connector;
	}
}
function expandAll(isOnLoad = false) {
	//fits the diagram content within the viewport
	// if (diagramInstance) {
	diagramInstance.fitToPage();
	if (diagramInstance) {
		setTimeout(
			() => {
				for (let i = 0; i < diagramInstance.nodes.length; i++) {
					diagramInstance.nodes[i].isExpanded = isOnLoad ? false : !diagramInstance.nodes[i].isExpanded;
				}
			},
			isOnLoad ? 1000 : 100
		);
	}
	// }
}
function ToggleButton() {
	let element = document.querySelectorAll("[aria-label^='Map']");
	let x = document.getElementById('overview-panel');
	if (x.style.visibility === 'hidden') {
		x.style.visibility = 'visible';
		x.style.opacity = 1;
		element.forEach(function (tile) {
			tile.classList.add('active');
		});
	} else {
		x.style.visibility = 'hidden';
		x.style.opacity = 0;
		element.forEach(function (tile) {
			tile.classList.remove('active');
		});
	}
}
