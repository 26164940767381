import React from 'react';

export const LinkedInIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_303_55317)'>
			<path
				d='M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10 19.0909C4.97922 19.0909 0.909102 15.0208 0.909102 10C0.909102 4.97922 4.97922 0.909102 10 0.909102C15.0208 0.909102 19.0909 4.97922 19.0909 10C19.0909 15.0208 15.0208 19.0909 10 19.0909Z'
				fill='#292929'
			/>
			<path d='M7.49973 8.12598H5V15.0003H7.49973V8.12598Z' fill='#292929' />
			<path
				d='M6.24989 7.49979C6.94019 7.49979 7.49979 6.94019 7.49979 6.24989C7.49979 5.5596 6.94019 5 6.24989 5C5.5596 5 5 5.5596 5 6.24989C5 6.94019 5.5596 7.49979 6.24989 7.49979Z'
				fill='#292929'
			/>
			<path
				d='M13.3495 8.12695C11.4747 8.12695 11.2497 9.23936 11.2497 9.23936V8.12695H8.75V15.0013H11.2497V11.2517C11.2497 11.2517 11.2497 10.0018 12.3121 10.0018C12.9371 10.0018 13.1245 10.5579 13.1245 11.2517V15.0013H15.6243V11.2517C15.6243 9.29559 14.7744 8.12695 13.3495 8.12695Z'
				fill='#292929'
			/>
		</g>
		<defs>
			<clipPath id='clip0_303_55317'>
				<rect width='20' height='20' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
export const FacebookIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_303_55326)'>
			<path
				d='M9.11015 7.22224V8.33337H7.99902V10H9.11015V15H11.3324V10H12.8101L12.999 8.33337H11.3324V7.36113C11.3324 6.91115 11.3768 6.67224 12.0712 6.67224H12.999V5H11.5101C9.73239 5.00005 9.11015 5.83335 9.11015 7.22224Z'
				fill='#292929'
			/>
			<path
				d='M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10 19.0909C4.97922 19.0909 0.909102 15.0208 0.909102 10C0.909102 4.97922 4.97922 0.909102 10 0.909102C15.0208 0.909102 19.0909 4.97922 19.0909 10C19.0909 15.0208 15.0208 19.0909 10 19.0909Z'
				fill='#292929'
			/>
		</g>
		<defs>
			<clipPath id='clip0_303_55326'>
				<rect width='20' height='20' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
export const InstagramIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_303_55332)'>
			<path
				d='M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10 19.0909C4.97922 19.0909 0.909102 15.0208 0.909102 10C0.909102 4.97922 4.97922 0.909102 10 0.909102C15.0208 0.909102 19.0909 4.97922 19.0909 10C19.0909 15.0208 15.0208 19.0909 10 19.0909Z'
				fill='#292929'
			/>
			<path
				d='M13.9924 6.00761C13.3426 5.35782 12.482 5 11.5691 5H8.43094C7.51801 5 6.65733 5.35782 6.00761 6.00761C5.35782 6.65733 5 7.51801 5 8.43094V11.569C5 12.482 5.35782 13.3427 6.00761 13.9924C6.65733 14.6422 7.51801 15 8.43094 15H11.5691C12.482 15 13.3427 14.6422 13.9924 13.9924C14.6421 13.3427 15 12.482 15 11.569V8.43094C15 7.51801 14.6421 6.65733 13.9924 6.00761ZM10 12.6465C8.54073 12.6465 7.35352 11.4593 7.35352 10C7.35352 8.54073 8.54073 7.35352 10 7.35352C11.4593 7.35352 12.6465 8.54073 12.6465 10C12.6465 11.4593 11.4593 12.6465 10 12.6465ZM12.9318 7.48253C12.8773 7.537 12.8017 7.56828 12.7247 7.56828C12.6476 7.56828 12.5721 7.537 12.5176 7.48253C12.4629 7.42798 12.4317 7.35245 12.4317 7.27531C12.4317 7.19818 12.4629 7.12257 12.5176 7.0681C12.5721 7.01355 12.6475 6.98235 12.7247 6.98235C12.8017 6.98235 12.8773 7.01355 12.9318 7.0681C12.9864 7.12257 13.0177 7.19818 13.0177 7.27531C13.0177 7.35237 12.9864 7.42798 12.9318 7.48253Z'
				fill='#292929'
			/>
			<path
				d='M9.99805 7.43945C8.58574 7.43945 7.4375 8.58769 7.4375 10C7.4375 11.4123 8.58574 12.5605 9.99805 12.5605C11.4104 12.5605 12.5586 11.4123 12.5586 10C12.5586 8.5877 11.4104 7.43945 9.99805 7.43945Z'
				fill='#292929'
				stroke='#E5F0FF'
			/>
		</g>
		<defs>
			<clipPath id='clip0_303_55332'>
				<rect width='20' height='20' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
export const TwitterIcon = () => (
	<svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clip-path='url(#clip0_804_11675)'>
			<g clip-path='url(#clip1_804_11675)'>
				<g clip-path='url(#clip2_804_11675)'>
					<g clip-path='url(#clip3_804_11675)'>
						<path
							d='M10.9305 9.36789L14.5713 5.13574H13.7086L10.5472 8.81045L8.02232 5.13574H5.11011L8.9283 10.6926L5.11011 15.1306H5.97291L9.31134 11.25L11.9778 15.1306H14.8901L10.9303 9.36789H10.9305ZM9.74878 10.7415L9.36192 10.1882L6.28379 5.78525H7.60901L10.0931 9.33855L10.48 9.89189L13.709 14.5106H12.3838L9.74878 10.7417V10.7415Z'
							fill='#292929'
						/>
					</g>
				</g>
				<path
					d='M10 0.271484C4.47715 0.271484 0 4.74863 0 10.2715C0 15.7943 4.47715 20.2715 10 20.2715C15.5229 20.2715 20 15.7943 20 10.2715C20 4.74863 15.5229 0.271484 10 0.271484ZM10 19.3624C4.97922 19.3624 0.909102 15.2923 0.909102 10.2715C0.909102 5.2507 4.97922 1.18059 10 1.18059C15.0208 1.18059 19.0909 5.2507 19.0909 10.2715C19.0909 15.2923 15.0208 19.3624 10 19.3624Z'
					fill='#292929'
				/>
			</g>
		</g>
		<defs>
			<clipPath id='clip0_804_11675'>
				<rect width='20' height='20' fill='white' transform='translate(0 0.271484)' />
			</clipPath>
			<clipPath id='clip1_804_11675'>
				<rect width='20' height='20.2715' fill='white' />
			</clipPath>
			<clipPath id='clip2_804_11675'>
				<rect width='10' height='10' fill='white' transform='translate(5 5.13574)' />
			</clipPath>
			<clipPath id='clip3_804_11675'>
				<rect width='9.77995' height='10' fill='white' transform='translate(5.11011 5.13574)' />
			</clipPath>
		</defs>
	</svg>
);
