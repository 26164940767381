import {
	Avatar,
	Box,
	ClickAwayListener,
	Grow,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography,
	Tooltip,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { OKRButton } from '../../Common/OKRButton';
import { DownFilledArrow } from '../../../config/svg/ArrowSvg';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../config/utils';
import { getLocalStorageItem } from '../../../services/StorageService';
import { Enums } from '../../../config/enums';
import ProgressLabel from '../../Common/ProgressLabel';
// import { useSnackbar } from 'notistack';
// const year = getLocalStorageItem('currentYear' || 'year') || null;
// const cycleId = getLocalStorageItem('currentCycleId' || 'cycleId') || 0;
// const selectedYear = getLocalStorageItem('year') || getLocalStorageItem('currentYear');
// import uniqBy from 'lodash/uniqBy';

const TeamOkrFilters: React.FC<any> = (props) => {
	const { t, getMyTeamDetails, addTeamOkrFilter, teamOkrFilterData, userDetail } = props;
	// const { enqueueSnackbar } = useSnackbar();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openTeamMenu, setOpenTeamMenu] = useState<boolean>(false);
	const [organisations, setOrganisations] = useState<any[]>([]);
	const [selectedYear, sstSelectedYear] = useState<any>(null);
	const [cycleId, setCycleId] = useState<any>(null);

	useEffect(() => {
		const year = getLocalStorageItem('currentYear' || 'year') || null;
		const cycle = getLocalStorageItem('cycleId') || 0;
		const selectedYear = getLocalStorageItem('year') || year;
		setCycleId(cycle);
		sstSelectedYear(selectedYear);
	}, []);

	useEffect(() => {
		if (cycleId && cycleId > 0) {
			getTeamDetails();
		}
	}, [cycleId]);

	useEffect(() => {
		if (organisations.length > 0 && (!teamOkrFilterData || !teamOkrFilterData.length)) {
			addTeamOkrFilter([organisations[0]]);
		}
	}, [organisations, teamOkrFilterData]);

	const getTeamDetails = () => {
		getMyTeamDetails(`empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`)
			.then((response: any) => {
				if (
					response?.data &&
					response?.data?.status === Enums.STATUS_SUCCESS &&
					response?.data?.entityList &&
					response?.data?.entityList.length > 0
				) {
					// response?.data?.entityList?.sort((a: any, b: any) => a.teamName.localeCompare(b.teamName));
					response?.data?.entityList?.sort(
						(a: any, b: any) =>
							b.keyCount - a.keyCount || a.teamScore - b.teamScore || a.teamName.localeCompare(b.teamName)
					);
					const { entityList } = response?.data;
					setOrganisations(entityList);
				} else {
					setOrganisations([]);
				}
			})
			.catch((_error: any) => {
				setOrganisations([]);
			});
	};

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenTeamMenu(anchorEl ? false : true);
	};

	const handleMenuClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setOpenTeamMenu(false);
	};

	const handleFilterClick = (e: any, filterItem: any) => {
		e.preventDefault();
		const copy = [...organisations];
		const index = organisations.indexOf(filterItem);
		const selected = organisations[index];
		if (index > 1) {
			copy.splice(index, 1);
			copy.unshift(selected);
		}
		setOrganisations(copy);
		addTeamOkrFilter([selected]);
	};

	return (
		<Fragment>
			<Box className='teams-filter-btns'>
				{
					organisations.length > 1 &&
					(organisations.slice(0, 2).map((item: any) => (
						<Fragment key={item.teamName}>
							<OKRButton
								className={`team-btn filterBtn ${teamOkrFilterData && teamOkrFilterData.length && teamOkrFilterData[0].teamId === item.teamId
										? 'active'
										: ''
									}`}
								text={
									item.teamName && item.teamName.length > 12 ? item.teamName.substring(0, 12) + '...' : item.teamName
								}
								title={item.teamName && item.teamName.length > 12 ? item.teamName : ''}
								handleClick={(e) => handleFilterClick(e, item)}
								style={{
									backgroundColor: getRandomBackgroundColor(item.backGroundColorCode),
									color: getRandomColor(item.colorCode),
								}}
								percentage={item}
								id={`team-name-${item?.teamId}`}
							/>
						</Fragment>
					)))
				}
			</Box>

			{organisations && organisations?.length > 2 && (
				<Fragment>
					<Box className='teams-filter-dropdown'>
						<OKRButton title={t('moreTeams')} icon={<DownFilledArrow />} handleClick={handleToggle} />
					</Box>
					<Popper
						className='popper-menu-list team-filter-list-menu'
						open={openTeamMenu}
						anchorEl={anchorEl}
						placement='bottom-start'
						transition
					>
						{({ TransitionProps }) => (
							<Grow {...TransitionProps}>
								<Paper>
									<ClickAwayListener onClickAway={handleMenuClose}>
										<MenuList id='menu-list-grow'>
											{organisations.slice(2, organisations.length).map((team: any) => (
												<MenuItem
													key={team.teamName}
													onClick={(e) => {
														handleFilterClick(e, team);
														handleToggle(e);
													}}
													id={`team-name-${team?.teamId}`}
												>
													<ListItemAvatar>
														<Avatar
															className='team-avatar-small'
															style={{
																backgroundColor: getRandomBackgroundColor(team.backGroundColorCode),
																color: getRandomColor(team.colorCode),
															}}
															src={team?.imagePath}
														>
															{getUserName({ firstName: '', lastName: '', fullName: team.teamName })}
														</Avatar>
													</ListItemAvatar>
													{/* <ListItemText primary={<Typography variant='h5'>{team.teamName}</Typography>} /> */}
													<ListItemText
														primary={
															<Typography variant='h5' className='team-filter-txt'>
																{team.teamName && team.teamName.length > 12 ? (
																	<Tooltip arrow title={team?.teamName} aria-label='Teams'>
																		<Typography>{team?.teamName.substring(0, 12)}...</Typography>
																	</Tooltip>
																) : (
																	<Typography>{team.teamName}</Typography>
																)}
															</Typography>
														}
													/>
													<ProgressLabel percentage={team} />
												</MenuItem>
											))}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Fragment>
			)}
		</Fragment>
	);
};

export default TeamOkrFilters;
