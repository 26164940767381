import React from 'react';
import {
	LayoutAnimation,
	DiagramTools,
	DataBinding,
	DiagramComponent,
	SnapConstraints,
	Inject,
	ComplexHierarchicalTree,
	ChildArrangement,
	LineDistribution,
	ConnectionPointOrigin,
} from '@syncfusion/ej2-react-diagrams';
import { Box, Typography } from '@material-ui/core';
import { SampleBase } from '../RelaxedMap/sample-base';
import { DataManager } from '@syncfusion/ej2-data';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { ToolbarComponent } from '@syncfusion/ej2-react-navigations';
import PeopleRootNode from '../AlignmentListing/PeopleRootNode';
import PeopleCard from '../AlignmentListing/PeopleCard';
import DiagramOverview from '../DiagramHelper/diagram-overview-component';
import { DiagramHelperUtil, MapTypes } from '../DiagramHelper/diagram-helper-util';
import { DiagramLayoutHelper } from '../DiagramHelper/diagram-layout-helper';
let diagramInstance;
let diagramLayoutHelper;
let template;
let diagramTimer;
let ref_overview;
let layoutedNodes;
export default class PeopleMap extends SampleBase {
	constructor(props) {
		super(props);
		template = this.diagramTemplate;
		this.state = {
			hSpace: 200,
			vSpace: 30,
			overviewProps: { diagramId: 'diagram' },
		};
		diagramLayoutHelper = new DiagramLayoutHelper(diagramInstance);
		DiagramHelperUtil.MapType = MapTypes.Relax;
		ref_overview = React.createRef();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.tabFilter !== this.props.tabFilter) {
			// this.rendereComplete();
			ToggleButton(true);
			if (diagramInstance) {
				// diagramInstance.reset();
				// diagramInstance.fitToPage();
				// // console.log(diagramInstance.scrollSettings.currentZoom)
				// if (diagramInstance.scrollSettings.currentZoom > 0.5) {
				// 	diagramInstance.zoomTo({ type: 'ZoomOut', zoomFactor: 0.5 });
				// }
			}
		}
	}
	onItemClick(args) {
		switch (args.item.text) {
			case 'Zoom In':
				let zoomin = { type: 'ZoomIn', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomin);
				if (this.updateOverview) {
					this.updateOverview();
				}
				break;
			case 'Zoom Out':
				let zoomout = { type: 'ZoomOut', zoomFactor: 0.2 };
				diagramInstance.zoomTo(zoomout);
				if (this.updateOverview) {
					this.updateOverview();
				}
				break;
			case 'Reset':
				diagramInstance.reset();
				this.ResetZoom();
				//diagramInstance.fitToPage();
				break;
			case 'Map':
				ToggleButton();
				break;
			default:
				break;
		}
	}

	diagramTemplate = (data) => {
		let cardobject = document.querySelectorAll('.foreign-object .foreign-object');
		if (cardobject.length > 0) {
			cardobject.forEach(function (value, index) {
				value.style.zIndex = cardobject.length - index;
			});
		}

		if (data && data.data.actionLevel !== 0) {
			return (
				<PeopleCard
					{...this.props}
					data={data}
					userDetail={this.props.userDetail}
					handleDeleteOkr={this.props.handleDeleteOkr}
					setOpenDrawer={this.props.setOpenDrawer}
					setModalProps={this.props.setModalProps}
					updateProgress={this.props.updateProgress}
					showEditLoader={this.props.showEditLoader}
					diagramInstance={diagramInstance}
				/>
			);
		}
		return <PeopleRootNode {...this.props} data={data} />;
	};
	rendereComplete() {
		if (diagramInstance) {
			//diagramInstance.fitToPage();
			// diagramInstance.zoomTo({ type: 'ZoomOut', zoomFactor: 0.5 });
			// window.scrollTo({
			// 	top: Math.ceil(diagramInstance.scroller.diagramHeight / 2) - 250,
			// 	behavior: 'smooth',
			// });
		}
	}
	updateOverview() {
		if (ref_overview && ref_overview.current) ref_overview.current.updateOverview();
	}
	render() {
		const { peopleResult } = this.props;
		let peopleData = [];
		this.setDiagramTimer();
		if (peopleResult.length <= 0) {
			return (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography>{this.props.t('loading')}</Typography>
					</Box>
				</Box>
			);
		} else {
			peopleData =
				peopleResult &&
				peopleResult.length > 0 &&
				peopleResult.map((item, i) => {
					//item.index = i + 1;
					if (item.parent.length === 0) {
						item.parent = '';
					}
					return item;
				});
		}

		return (
			<Box className='diagram-control-pane'>
				<MenuComponent items={this.menuItems} />
				<Box className='control-panel'>
					<Box className='control-section'>
						<Box className='content-wrapper'>
							<ToolbarComponent
								id='toolbar_diagram'
								clicked={this.onItemClick.bind(this)}
								items={[
									{
										type: 'Button',
										//tooltipText: 'ZoomIn',
										text: 'Zoom In',
										//prefixIcon: 'e-diagram-icons e-diagram-zoomin',
									},
									{
										type: 'Button',
										// tooltipText: 'ZoomOut',
										text: 'Zoom Out',
										//prefixIcon: 'e-diagram-icons e-diagram-zoomout',
									},
									{
										type: 'Button',
										text: 'Map',
									},
									{
										type: 'Button',
										//tooltipText: 'Reset',
										text: 'Reset',
										//prefixIcon: 'e-diagram-icons e-diagram-reset',
									},
								]}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='control-section'>
					<Box className='content-wrapper'>
						<DiagramComponent
							id='diagram'
							ref={(diagram) => (diagramInstance = diagram)}
							width={'100%'}
							//height={'1500px'}
							height={'calc(100vh - 224px)'}
							snapSettings={{ constraints: SnapConstraints.None }}
							nodeTemplate={template}
							// backgroundColor='#6BA5D7'
							//configures data source settings
							dataSourceSettings={{
								id: 'employeeUniqueId',
								parentId: 'parent',
								//dataSource: new DataManager(localBindData),
								dataSource: new DataManager(peopleData),
								doBinding: (nodeModel, data, diagram) => {
									nodeModel.nodeTemplate = template.bind(data);
									nodeModel.template = template;
									nodeModel.style = { boder: '1px solid #000000' };
									nodeModel.shape = {
										type: 'HTML',
									};
								},
							}}
							//Disables all interactions except zoom/pan
							tool={DiagramTools.ZoomPan}
							//Configures automatic layout
							layout={{
								// connectionPointOrigin: ConnectionPointOrigin.DifferentPoint,
								connectionPointOrigin: ConnectionPointOrigin.SamePoint,
								// enableAnimation: true,
								type: 'ComplexHierarchicalTree',
								orientation: 'RightToLeft',
								// orientation: 'LeftToRight',
								horizontalSpacing: this.props.tabFilter === 'peopleultracompact' ? 100 : this.state.hSpace,
								verticalSpacing: this.props.tabFilter === 'peopleultracompact' ? 30 : this.state.vSpace,
								arrangement: ChildArrangement.Linear,
								// arrangement: ChildArrangement.NonLinear,
							}}
							//Defines the default node and connector properties
							getNodeDefaults={(obj, diagram) => {
								/* tslint:disable:no-string-literal */
								return nodeDefaults(obj, diagram, this.props.tabFilter);
							}}
							getConnectorDefaults={(connector, diagram) => {
								return connectorDefaults(connector, diagram);
							}}
							scrollSettings={{
								canAutoScroll: false,
								scrollLimit: 'Infinity',
								// horizontalOffset: 0,
							}}
							// pageSettings={
							// 	{
							// 		// Sets the PageOrientation for the diagram to page
							// 		orientation: 'Landscape',
							// 		// Sets the Page Break for diagram
							// 		showPageBreaks: false,
							// 		// Defines the background color and image  of diagram
							// 		background: {
							// 			color: 'red'
							// 		},
							// 		width: '100%'
							// 	}
							// }
							//   created={() => {
							//     //fits the diagram content within the viewport
							//     diagramInstance.fitToPage();
							//   }}
							scrollChange={() => {
								if (ref_overview && ref_overview.current && !ref_overview.current.state.dragging) {
									this.updateOverview();
								}
							}}
						>
							<Inject services={[DataBinding, LayoutAnimation, ComplexHierarchicalTree, LineDistribution]} />
						</DiagramComponent>
						<DiagramOverview ref={ref_overview} {...this.state.overviewProps}></DiagramOverview>
					</Box>
				</Box>
			</Box>
		);
	}

	onAfterDiagramCreated() {
		if (diagramInstance) {
			// console.log('after diagram created called');
			this.ResetZoom();
			this.MakeCenterPosition();
			diagramInstance.dataBind();
			this.CreateHeaderLabels();
			diagramInstance.dataBind();
		}
	}
	ResetZoom() {
		let totalWidth = diagramInstance.scroller.diagramWidth;
		let contNode = this.getAllContributorNodes(diagramInstance)[0];
		if (contNode) {
			let layoutHSpace = diagramInstance.layout.horizontalSpacing;
			let extraLeftRightSpace = 50;
			totalWidth = contNode.width * 3 + 2 * layoutHSpace + extraLeftRightSpace;
		}
		let intialZoom = diagramInstance.scrollSettings.viewPortWidth / totalWidth;
		let currentZoom = diagramInstance.scrollSettings.currentZoom;
		let zoomDiff = currentZoom - intialZoom;
		let zoomDiffPercentage = zoomDiff / intialZoom;
		let zoomOutOptions = { type: 'ZoomOut', zoomFactor: zoomDiffPercentage };
		diagramInstance.zoomTo(zoomOutOptions);
	}
	MakeCenterPosition() {
		let di = diagramInstance;
		var rootnode = di.nodes.find((x) => x.data.actionLevel === 0);
		var layerbound = di.diagramLayer.getBoundingClientRect();
		let vw = di.scrollSettings.viewPortWidth;
		let tx = di.scroller.transform.tx * di.scroller.transform.scale;
		var centerx = tx + (vw * di.scroller.transform.scale) / 2;
		var diffx = rootnode.offsetX - centerx;
		diagramInstance.nodes.forEach((n) => {
			n.offsetX = n.offsetX - diffx;
			n.visible = true;
		});
		diagramInstance.connectors.forEach((c) => {
			c.visible = true;
		});
	}
	setDiagramTimer() {
		if (diagramTimer) {
			clearTimeout(diagramTimer);
			//this.toggleDiagramLoading(true);
			// console.log('onAfterDiagramCreated cleared');
		}
		diagramTimer = setTimeout(() => {
			// console.log('onAfterDiagramCreated started');
			//this.toggleDiagramLoading(false);
			this.onAfterDiagramCreated();
		}, 10);
	}
	getRootNodes(diagramIns) {
		if (diagramIns && diagramIns.nodes) return diagramIns.nodes.filter((n) => n.data && n.data.actionLevel === 0);
		return null;
	}
	getContributorNodes(diagramIns, centerNode) {
		if (diagramIns && diagramIns.nodes)
			return diagramIns.nodes.filter(
				(n) => n.data && n.data.actionLevel !== 0 && n.offsetX > centerNode.offsetX + centerNode.width
			);
		return null;
	}
	getAllContributorNodes(diagramIns) {
		if (diagramIns && diagramIns.nodes) return diagramIns.nodes.filter((n) => n.data && n.data.actionLevel !== 0);
		return null;
	}
	CreateHeaderLabels() {
		let rootNodes = this.getRootNodes(diagramInstance);
		if (rootNodes && rootNodes.length > 0) {
			this.CreateOkrLabels(diagramInstance, rootNodes[0]);
		}
	}
	//#region - Okr Labels Creation
	CreateOkrLabels(diagramIns, centerNode) {
		let x = centerNode.offsetX;
		let h = 20;

		let lowNode = diagramIns.nodes.sort(function (n1, n2) {
			return n1.offsetY - n2.offsetY;
		})[0];
		let y = lowNode.offsetY - lowNode.height / 2 - 40;
		let w = centerNode.width;
		let hSpace = 200;

		let contributorNode = this.getContributorNodes(diagramIns, centerNode)[0];
		if (!contributorNode) {
			contributorNode = {
				width: 500,
				offsetX: x + w / 2 + hSpace + 500 / 2,
			};
		}

		this.CreateOkrLabel(
			diagramIns,
			contributorNode,
			'okrlabel_Parent_OKRs',
			x - w / 2 - hSpace - contributorNode.width / 2,
			y,
			65,
			h,
			this.props.t('parentsLabel')
		);

		this.CreateOkrLabel(
			diagramIns,
			contributorNode,
			'okrlabel_Contributor_OKRs',
			contributorNode.offsetX,
			y,
			130,
			h,
			this.props.t('myContributors')
		);
	}

	ClearIfExist(diagramIns, id, type) {
		let obj = diagramIns[type].find((n) => n.id === id);
		if (obj) {
			diagramIns.remove(obj);
		}
	}

	CreateOkrLabel(diagramIns, centerNode, id, x, y, w, h, label) {
		let node = diagramIns.nodes.find((n) => n.id === id);
		if (node) {
			diagramIns.remove(node);
		}
		node = {
			id: id,
			height: h,
			offsetX: x,
			offsetY: y,
			width: w,
			style: {
				strokeColor: 'none',
				fill: 'none',
			},
			annotations: [
				{
					content: label,
					style: {
						color: '#292929',
					},
				},
			],
			excludeFromLayout: true,
		};
		let leftLineId = node.id + '_link_helper' + '_left _line';
		this.ClearIfExist(diagramIns, leftLineId, 'connectors');
		let leftLine = {
			id: leftLineId,
			sourcePoint: {
				x: node.offsetX - centerNode.width / 2 - 30,
				y: node.offsetY,
			},
			targetID: node.id,
			sourceDecorator: { shape: 'None' },
			targetDecorator: { shape: 'None' },
			style: {
				strokeColor: DiagramHelperUtil.distInfoLineDefaultStyle.strokeColor,
				strokeWidth: DiagramHelperUtil.distInfoLineDefaultStyle.strokeWidth,
			},
		};

		let rightLineId = node.id + '_link_helper' + '_right _line';
		this.ClearIfExist(diagramIns, rightLineId, 'connectors');
		let rightLine = {
			id: rightLineId,
			sourceID: node.id,
			targetPoint: {
				x: node.offsetX + centerNode.width / 2 + 30,
				y: node.offsetY,
			},
			sourceDecorator: { shape: 'None' },
			targetDecorator: { shape: 'None' },
			style: {
				strokeColor: DiagramHelperUtil.distInfoLineDefaultStyle.strokeColor,
				strokeWidth: DiagramHelperUtil.distInfoLineDefaultStyle.strokeWidth,
			},
		};

		diagramIns.add(node);
		diagramIns.add(leftLine);
		diagramIns.add(rightLine);
	}

	//#endregion
}

//sets default value for Node.
function nodeDefaults(obj, diagram, tab) {
	//obj.backgroundColor = obj.data.color;
	if (!obj.excludeFromLayout) {
		obj.visible = false;
		if (obj.data && obj.data.actionLevel < 1) {
			obj.style = {
				fill: 'none',
				strokeColor: 'none',
				color: 'white',
				borderCorner: '30',
			};
			obj.expandIcon = {
				height: 24,
				width: 24,
				shape: '',
				fill: 'white',
				fillOpacity: 0.1,
				offset: { x: 0.49, y: 1.015 },
				borderColor: '#39a3fa',
			};
			obj.expandIcon.verticalAlignment = 'Center';
			obj.expandIcon.margin = { left: 0, right: 0, top: 0, bottom: 0 };
			obj.collapseIcon.offset = { x: 0.49, y: 1.015 };
			obj.collapseIcon.verticalAlignment = 'Center';
			obj.collapseIcon.margin = { left: 0, right: 0, top: 0, bottom: 0 };
			obj.collapseIcon.height = 24;
			obj.collapseIcon.width = 24;
			obj.collapseIcon.shape = '';
			obj.margin = { left: 10, right: 10, top: 0, bottom: 0 };
			obj.collapseIcon.fill = 'white';
			obj.collapseIcon.borderColor = '#39a3fa';
		}
		if (tab === 'peoplerelaxed') {
			obj.width = 500;
			obj.height = 180;
		} else if (tab === 'peoplecompact') {
			obj.width = 500;
			obj.height = 118;
		} else {
			obj.width = 404;
			obj.height = 66;
		}
		//obj.offsetX = 500;
		// obj.offsetY = obj.data.index * 300;
		if (obj.data && obj.data.actionLevel === 0) {
			obj.width = 120;
			obj.height = 120;
			obj.expandIcon.offset = { x: 0.5, y: 1.05 };
			obj.collapseIcon.offset = { x: 0.5, y: 1.05 };
		}
		obj.isExpanded = true;
	}
	return obj;
}
//sets default value for Connector.
function connectorDefaults(connector, diagram) {
	if (connector.id.indexOf('_helper') < 0) {
		connector.visible = false;
		connector.targetDecorator.shape = 'Arrow';
		connector.type = 'Orthogonal'; // "Bezier";
		// connector.type = "Bezier";
		connector.cornerRadius = 100;
		connector.targetDecorator.height = 10;
		connector.targetDecorator.width = 10;
		connector.style = { strokeColor: '#5B708B', strokeWidth: 3 };
		connector.targetDecorator.style = {
			fill: '#5B708B',
			strokeColor: '#5B708B',
			strokeWidth: 3,
		};
		// connector.ports = {
		// 	sourcePoint: { x: 400, y: -500 },
		// 	targetPoint: { x: 200, y: 400 },
		// };
		// connector.sourcePoint = { x: -400, y: -500 };
	}
	return connector;
}
function ToggleButton(shoulddHide = false) {
	let element = document.querySelectorAll("[aria-label^='Map']");
	let x = document.getElementById('overview-panel');
	if (shoulddHide) {
		x.style.visibility = 'hidden';
		x.style.opacity = 0;
		element.forEach(function (tile) {
			tile.classList.remove('active');
		});
		return;
	}
	if (x.style.visibility === 'hidden') {
		x.style.visibility = 'visible';
		x.style.opacity = 1;
		element.forEach(function (tile) {
			tile.classList.add('active');
		});
	} else {
		x.style.visibility = 'hidden';
		x.style.opacity = 0;
		element.forEach(function (tile) {
			tile.classList.remove('active');
		});
	}
}
