import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Typography, FormControl, Input, InputAdornment, IconButton, Button, FormHelperText } from '@material-ui/core';
import LinearLoader from '../../Common/Loader';
import { getLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import Logo from '../../../images/okr-logo.svg';
import LoginImage from '../../../images/login-bg.svg';
import { VisibilityOff, Visibility } from '../../../config/svg/ProfileSvg';
import { ProfileIcon } from '../../../config/svg/CommonSvg';
import { Link } from 'react-router-dom';
import { getQuarterStartEnd } from '../../../config/utils';
import '../../../styles/pages/login/login.scss';
import { ClientLogo } from '../../Common/ClientLogo';
import { useSnackbar } from 'notistack';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { SECRET_LOGIN } from '../../../config/app-url';
import { URL_REGEX } from '../../../config/constant';
import { HOME } from '../../../config/app-url';

export default function CustomDomain(props) {
	const history = useHistory();

	let { currentYear } = getQuarterStartEnd();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [isFormSubmit, setIsFormSubmit] = useState(false);
	const [loader, setLoader] = useState(false);

	const accessTokenId = JSON.parse(getLocalStorageItem('accessTokenId'));

	if (accessTokenId) {
		props.history.push(HOME);
	}

	const [formData, setFormData] = useState({
		url: '',
		password: '',
		username: '',
		showPassword: false,
		formValid: false,
		isUrlValid: false,
		messageList: {},
	});

	const handleChange = (prop) => (event) => {
		const value = event.target.value;
		if (prop === 'url') {
			let urlValid = value.match(URL_REGEX) ? true : false;
			setFormData({
				...formData,
				url: value.trim(),
				isUrlValid: urlValid,
				formValid: value.trim() && urlValid,
			});
		} else {
			setFormData({ ...formData, [prop]: event.target.value });
		}
	};

	const handleClickShowPassword = () => {
		setFormData({ ...formData, showPassword: !formData.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	async function logincall(event) {
		setLoader(true);
		if (formData.username && formData.password) {
			try {
				// Custom Login API Calling...
				props
					.userValidation({ userEmailId: formData.username, userPassword: formData.password })
					.then((result) => {
						if (result.data.isSuccess) {
							let user = {
								organisationId: 24,
								organisationName: '',
								firstName: formData.username,
								lastName: '',
								designation: '',
								employeeId: 0,
								emailId: formData.username,
								employeeCode: '',
								imagePath: '',
								roleId: 1,
								roleName: '',
								loggedInAs: '',
								isActive: true,
								reportingTo: null,
								reportingName: null,
								lastLoginTime: '2022-10-06T06:14:13.697',
								loginFailCount: null,
								expireTime: 1665124645,
								version: '3.6.0',
								productID: '0265-XXD-443D654',
								license: 'Unlock:OKR Subscription',
								belongsTo: 'Compunnel Software Group',
								directReports: true,
								isTeamLeader: true,
								isImpersonatedUser: false,
								impersonatedBy: '',
								impersonatedById: 0,
								impersonatedByUserName: '',
							};
							setLocalStorageItem('userDetail', JSON.stringify(user));
							setLocalStorageItem('tokenId', JSON.stringify(result.data.entity));
							setLocalStorageItem('accessTokenId', JSON.stringify(result.data.entity));
							props.history.push(HOME);
						} else {
							setLoader(false);
							enqueueSnackbar(result.data.messageList.message, {
								variant: 'error',
								autoHideDuration: 5000,
							});
						}
					})
					.catch((e) => {
						// console.log(e);
					});
			} catch (err) {
				setLoader(false);
				enqueueSnackbar(t('InternalServerErrorMsg'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		} else {
			setLoader(false);
		}
	}

	const submitForm = (e) => {
		e.preventDefault();
		setIsFormSubmit(true);
		// console.log(formData);
		//if (formData.formValid) {
		// const data = {
		// 	url: formData.url,
		// };
	};

	return (
		<Fragment>
			<section className='sign-in-area'>
				{loader && <LinearLoader />}
				<div className='login-screen'>
					<div className='inner'>
						<div className='img-inner-wrapper'>
							<ClientLogo className='client-logo' />
							<AnimatedIcon className='' width='348' type='image/svg+xml' alt='Login' imagePath={LoginImage} />
						</div>
					</div>

					<div className='inner login-screen'>
						<div className='login-inner-wrapper custom-domain-content custom-login'>
							<img src={Logo} className='logo' alt='' />
							{/* <Typography variant='h2' className='welcomeText'>Welcome to <span>Unlock:OKR</span></Typography> */}
							<Typography className='welcomeSubText'>Please login to your account.</Typography>
							<form className='login-inner-form domain-form ' onSubmit={submitForm}>
								<div className='custom-login-form'>
									<FormControl variant='outlined' style={{ width: '100%' }} error={isFormSubmit && !formData.username}>
										<Input
											id='input-with-icon-adornment'
											placeholder='User Name'
											value={formData.username}
											onChange={handleChange('username')}
											endAdornment={
												<InputAdornment position='end'>
													<ProfileIcon />
												</InputAdornment>
											}
										/>
										{isFormSubmit && !formData.username && <FormHelperText>{t('enterUsername')}</FormHelperText>}
									</FormControl>
									<FormControl variant='outlined' style={{ width: '100%' }} error={isFormSubmit && !formData.password}>
										<Input
											id='standard-adornment-password'
											placeholder='Password'
											type={formData.showPassword ? 'text' : 'password'}
											value={formData.password}
											onChange={handleChange('password')}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														disableRipple='true'
														aria-label='toggle password visibility'
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
													>
														{formData.showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
										{isFormSubmit && !formData.password && <FormHelperText>{t('enterPassword')}</FormHelperText>}
									</FormControl>
									{/* <FormControlLabel
										value='remember_me'
										control={<Checkbox color='primary' />}
										label='Remember me'
										labelPlacement='end'
										className='rememberMe_box'
									/> */}

									<Button type='submit' className='login-btn btn-primary' color='primary' onClick={logincall}>
										Log In
									</Button>
								</div>
							</form>

							<div className='footer-content'>
								<Typography variant='body2'>
									&copy; {currentYear} {t('infoproRights')}
									{/* {' | '}
									<Link target='_blank' to='/privacy-policy'>
										{t('privacyPolicy')}
									</Link> */}
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
}
