import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Logo } from '../../config/svg/GlobalSvg';
import { MenuIcon } from '../../config/svg/HeaderActionSvg';
import { LeftArrow } from '../../config/svg/ArrowSvg';
import DrawerList from './DrawerList';
import PrimarySearch from './PrimarySearch';
import UserNavigation from './UserNavigation';
import MyAccount from './MyAccount';
import Notification from './Notification';
import TenureNavigation from './TenureNavigation';
import LinearLoader from '../Common/Loader';
import {
	AppBar,
	Toolbar,
	IconButton,
	Drawer,
	Box,
	Typography,
	Tooltip,
	Dialog,
	Slide,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';
import {
	clearLocalStorage,
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from '../../services/StorageService';
import { HOME, LOGIN_INITIATOR_URL } from '../../config/app-url';
import '../../styles/layout/header.scss';
import { ClientLogo } from '../Common/ClientLogo';
import { signalRConnection } from '../../config/utils';
import { Enums } from '../../config/enums';

export default function Header(props) {
	const {
		loaderStatus,
		listOkrResult,
		giveFeedbackStatus,
		askFeedbackStatus,
		resetFeedback,
		showGuidedTour,
		guideTourVisible,
		listOkrSuccess,
	} = props;
	const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();
	const [profileOpen, setProfileOpen] = useState(false);
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	//SignalR implement
	// useEffect(() => {
	// 	if (history.location.pathname === '/unlock-me' && !props.isSignalRConnected) {
	// 		props.signalRConnectionStatus(true);
	// 		signalRConnection(Enums.topicNotesTag);
	// 		signalRConnection(Enums.topicAddContributor);
	// 		signalRConnection(Enums.topicDeleteOKR);
	// 		signalRConnection(Enums.topicConversationTag);
	// 		signalRConnection(Enums.topicNudgeTeam);
	// 		signalRConnection(Enums.topicUpdateOKRDueDate);
	// 		// signalRConnection(Enums.topicOKRCoach);
	// 		signalRConnection(Enums.topicAcceptOKR);
	// 		signalRConnection(Enums.topicDeclineOKR);
	// 		signalRConnection(Enums.topicGivePersonalFeedback);
	// 		signalRConnection(Enums.topicPersonalFeedback);
	// 		signalRConnection(Enums.topicFeedbackOneOnOne);
	// 		signalRConnection(Enums.topicUpdateKrScore);
	// 		signalRConnection(Enums.topicUpdateKrValueAlignmentMap);
	// 	}

	// 	// signalRConnection(Enums.topicSignalR);
	// }, [history.location]);

	// useEffect(() => {
	// 	if (props.getPermissionStatus === '') {
	// 		//==== Get Employee profile related permission
	// 		props.getEmployeePermission(undefined, history);
	// 	}

	// 	document.body.classList.add('user-body-class');
	// 	return () => {
	// 		document.body.classList.remove('user-body-class');
	// 	};
	// }, [history.location]);

	useEffect(() => {
		// if (listOkrSuccess === 'success') {
		// 	props.getGuidedTourActivity().then((res) => {
		// 		if (res.data.entity.toShowGuidedTour) {
		// 			showGuidedTour(true);
		// 		}
		// 	});
		// }
		if (
			props.getPermissionResult &&
			props.getPermissionResult.employeePermissions &&
			!props.getPermissionResult.employeePermissions.length
		) {
			props.getEmployeePermission(undefined, history);
		}
	}, [listOkrResult]);

	if (!userDetail) {
		//==== if clearing storage then
		//==== save redirect Url before clearing storage

		let redirectUrl = getLocalStorageItem('redirectUrl');
		let otherCycleObjective = getLocalStorageItem('otherCycleObjective');
		let empId = getLocalStorageItem('empId');
		clearLocalStorage();
		if (redirectUrl) {
			setLocalStorageItem('redirectUrl', redirectUrl);
			setLocalStorageItem('otherCycleObjective', otherCycleObjective);
		}
		if (empId) {
			setLocalStorageItem('empId', empId);
		}
		window.location = LOGIN_INITIATOR_URL;
	}

	useEffect(() => {
		if (guideTourVisible) {
			setOpen(false);
		} else if (!guideTourVisible && props.isClosedByUser) {
			setOpen(true);
			props.userClosedGuidedTour(false);
		}
	}, [guideTourVisible]);

	useEffect(() => {
		if (open) {
			document.body.classList.add('guide-me-check');
		} else {
			document.body.classList.remove('guide-me-check');
		}
	}, [open]);

	const handleToggle = (flag) => {
		if (flag === 1) {
			setOpen((prevOpen) => !prevOpen);
		} else {
			setOpen(false);
		}
	};

	const openGuidedMenu = (e) => {
		e.preventDefault();
		setOpen(false);
		showGuidedTour(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const navigateToHomePage = () => {
		removeLocalStorageItem('currentUser');
		props.resetOkr();
		history.push(HOME);
		props.updateRoute(HOME);
	};

	const checkDrawer = () => {
		if (open) {
			setOpen(false);
		}
	};

	useEffect(() => {
		window.onfocus = function () {
			const url = getLocalStorageItem('currentRoute');
			if (url && url !== props.currentRoute) {
				props.updateRoute(url);
				window.location.href = url;
			} else if (
				getLocalStorageItem('userDetail') &&
				userDetail &&
				userDetail.employeeId !== JSON.parse(getLocalStorageItem('userDetail'))?.employeeId
			) {
				props.updateRoute(url);
				window.location.href = url;
			} else {
				props.updateRoute(url);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	useEffect(() => {
		if (giveFeedbackStatus === 'success') {
			enqueueSnackbar(t('givenFeedback'), {
				variant: 'success',
			});
		} else if (giveFeedbackStatus === 'error') {
			enqueueSnackbar(t('givingFeedbackError'), { variant: 'error' });
		}

		return () => {
			try {
				resetFeedback();
			} catch (err) {}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [giveFeedbackStatus]);

	useEffect(() => {
		if (askFeedbackStatus === 'success') {
			enqueueSnackbar(t('feedbackRequest'), {
				variant: 'success',
				autoHideDuration: 3000,
			});
		} else if (askFeedbackStatus === 'error') {
			enqueueSnackbar(t('feedbackRequestError'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}

		return () => {
			try {
				resetFeedback();
			} catch (err) {}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [askFeedbackStatus]);

	const closeSignalRUpdateDialog = () => {
		props.showRefreshSnackBar(false);
	};

	return (
		<Fragment>
			{userDetail && (
				<Fragment>
					<AppBar className='app-header' onClick={checkDrawer}>
						<Toolbar>
							<Box className='header-left'>
								<IconButton
									color='inherit'
									aria-label={t('HeaderMainAriaLabel')}
									// onClick={() => handleToggle(1)}
									edge='start'
									className='drawer-open'
									id='side-nav-bar'
								>
									{open ? <LeftArrow /> : <MenuIcon />}
								</IconButton>
								<Box className='app-logo'>
									<Typography variant='h1'>
										<Tooltip title={`${t('homeLabel')}`} arrow>
											<IconButton
												color='inherit'
												id='logo'
												onClick={(e) => {
													history.push(HOME);
												}}
											>
												<Logo />
											</IconButton>
										</Tooltip>
									</Typography>
								</Box>
								{/* <PrimarySearch {...props} /> */}
							</Box>
							<nav className='main-nav' aria-label='Main'>
								<UserNavigation {...props} />
							</nav>
							<Box className='header-right'>
								{/* <ClientLogo className='client-logo' /> */}
								{/* <Notification {...props} /> */}
								{/* <TenureNavigation {...props} handleToggle={handleToggle} /> */}
								<MyAccount
									{...props}
									profileOpen={profileOpen}
									setProfileOpen={setProfileOpen}
									loginAs={props.loginAs}
								/>
							</Box>
						</Toolbar>

						{loaderStatus && <LinearLoader data={listOkrSuccess === 'success' ? false : true} />}
					</AppBar>
					<Drawer
						className='drawer-nav'
						anchor='left'
						open={open}
						onClose={handleDrawerClose}
						// onEscapeKeyDown={handleDrawerClose}
						// onBackdropClick={handleDrawerClose}
					>
						<DrawerList {...props} handleToggle={handleToggle} openGuidedMenu={openGuidedMenu} />
					</Drawer>
				</Fragment>
			)}
		</Fragment>
	);
}
