import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddNewMailPopup } from './AddNewMailPopup';
import '../../../src/styles/pages/mail/mail.scss';
import { MailList } from './MailList';
import { OKRButton } from '../Common/OKRButton';
import { AddIconSvg } from '../../config/svg/CommonSvg';
import AlertDialog from '../Common/Dialog';
import { createMailFeature, getAllMailList } from '../../action/mail';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

export const Mails: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [featureModule, setFeatureModule] = useState<any>({});
	const [formData, setFormData] = useState<any>({});
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [imageList, setImageList] = useState<any[]>([]);
	const [isError, setIsError] = useState<any>({});
	const [showDialog, setShowDialog] = useState<any>({
		open: false,
		message: '',
		type: '',
	});
	const [tenantOptions, setTenantOptions] = useState<any>([]);
	const [selectedTenant, setSelectedTenant] = useState<any>([]);

	// Reset the form and optionally hide the modal
	const handleReset = (hideModal = false) => {
		setFormData({});
		setIsError({});
		setFormEdited(false);
		setSelectedTenant([]);
		hideModal && setFeatureModule({ open: false });
	};
	// Handle opening the AddNewMailPopup
	const handleOpen = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setFeatureModule({ open: true, type: 'Add' });
		handleReset();
	};

	// Handle closing the AddNewMailPopup
	const handleClose = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		setFeatureModule({ open: false, type: '' });
		if (formEdited === true) {
			setFeatureModule({ ...featureModule });
			setShowDialog({
				open: true,
				type: 'Unsaved',
				message: t('unSavedItemAlert'),
			});
		}
	};

	// Handle editing and updating a feature
	const editUpdateFeature = (record: any) => {
		setIsError({});
		setFormEdited(false);
		setFeatureModule({ open: true, type: 'Edit', data: record });
	};

	// Handle sending a feature to a list
	const sendListFeature = (record: any) => {
		setFeatureModule({ open: false, type: 'SendList', data: record });
	};

	// Check form validation
	const checkValidation = (type: any) => {
		let errorData = {};
		if (!formData?.subject) {
			errorData = { ...errorData, subject: true };
		}
		if ((!formData?.tenantId || (formData?.tenantId && formData?.tenantId.length <= 0)) && type === 'sent') {
			errorData = { ...errorData, tenantId: true };
		}
		if (!formData?.body && type === 'sent') {
			errorData = { ...errorData, body: true };
		}
		if (errorData && Object.keys(errorData).length > 0) {
			setIsError({ ...errorData });
			return false;
		} else {
			setIsError({});
			return true;
		}
	};
	// Perform an API call to save a feature
	const apiCallToSaveFeature = async (type: any) => {
		setFormEdited(false);
		const requestPayload: any = {
			systemEmailId: 0,
			subject: formData?.subject || '',
			body: formData?.body || '',
			documentRequests: [],
			status: type === 'sent' ? 3 : 1,
			tenantId: formData?.tenantId || [],
		};
		imageList.forEach((value: any) => {
			if (formData?.body) {
				if (formData?.body.includes(value.filePath)) {
					requestPayload.documentRequests.push({
						documentId: 0,
						documentModuleId: 2,
						moduleDetailsId: 0,
						uploadFileName: value.fileName,
						filePath: value.filePath,
						storageFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					});
				}
			} else if (featureModule.data?.body) {
				if (featureModule.data?.body.includes(value.filePath)) {
					requestPayload.documentRequests.push({
						documentId: 0,
						documentModuleId: 2,
						moduleDetailsId: 0,
						uploadFileName: value.fileName,
						filePath: value.filePath,
						storageFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					});
				}
			} else {
			}
		});

		// Update requestPayload based on featureModule type
		if (featureModule?.type === 'Edit' || featureModule?.type === 'SendList') {
			requestPayload.systemEmailId = featureModule.data?.systemEmailId || 0;
		}
		if (featureModule?.type === 'SendList') {
			requestPayload.systemEmailId = featureModule.data?.systemEmailId || 0;
			requestPayload.subject = featureModule.data?.subject || 0;
			requestPayload.body = featureModule.data?.body || 0;
		}

		const response: any = await dispatch(createMailFeature(requestPayload));

		handleResponse(response);
	};
	// Helper function to handle API response and display snackbar
	const handleResponse = (response: any) => {
		if (response && response?.data) {
			const { messageList } = response?.data;
			enqueueSnackbar(messageList?.SystemEmailId || 'Email sent successfully.', {
				variant: 'success',
				autoHideDuration: 3000,
			});
			dispatch(getAllMailList());
		} else {
			const { messageList } = response?.data;
			enqueueSnackbar(messageList?.SystemEmailId || 'Some error Occurred!', {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};

	// Handle the save button click
	const handleSaveClick = (event: any, type: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'sent') {
			if (checkValidation('sent')) {
				setShowDialog({
					open: true,
					type: 'Sent',
					message: t('sentConfirmation'),
				});
			}
		} else {
			if (checkValidation('draft')) {
				setFormEdited(false);
				apiCallToSaveFeature('draft');
				handleReset(true);
			}
		}
	};

	// Handle form input changes
	const handleChangeValue = (event: any) => {
		const { value, name } = event.target;
		setFormData({ ...formData, [name]: value });
		setFormEdited(true);
	};

	// Handle closing the modal
	const handleCloseModal = (e: any, type: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (type === 1) {
			if (showDialog?.type === 'Sent') {
				apiCallToSaveFeature('sent');
				handleReset(true);
			} else {
				handleReset(true);
			}
		}
		setShowDialog({ open: false, message: '', type: '' });
	};

	return (
		<Box className='mails-wrapper whats-new-wrapper'>
			{/* Container for add mail button */}
			<Box className='whats-tabs-wrap'>
				<OKRButton
					className={'add-btn'}
					icon={<AddIconSvg />}
					title={t('newMail')}
					handleClick={(event: any) => {
						event.preventDefault();
						event.stopPropagation();
						handleOpen(event);
					}}
				/>
			</Box>

			{/* List displaying the mails */}
			<MailList
				{...props}
				editUpdateFeature={editUpdateFeature}
				sendListFeature={sendListFeature}
				setShowDialog={setShowDialog}
			/>

			{/* Popup for adding a new mail or editing an existing one */}
			<AddNewMailPopup
				{...props}
				open={featureModule?.open || false}
				handleClose={handleClose}
				featureType={featureModule?.type || 'Add'}
				editableData={featureModule?.data || {}}
				formData={formData}
				isError={isError}
				handleChangeValue={handleChangeValue}
				setFormData={setFormData}
				setFormEdited={setFormEdited}
				formEdited={formEdited}
				handleSaveClick={handleSaveClick}
				imageList={imageList}
				setImageList={setImageList}
				tenantOptions={tenantOptions}
				setTenantOptions={setTenantOptions}
				selectedTenant={selectedTenant}
				setSelectedTenant={setSelectedTenant}
			/>

			{/* Conditional rendering for showing an alert dialog */}
			{showDialog && showDialog?.open && (
				<AlertDialog
					message={showDialog?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={showDialog?.open}
					isCancel={false}
				/>
			)}
		</Box>
	);
};
