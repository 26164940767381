import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	allMailList: [],
	allMailListPending: false,
	allMailListError: null,
};

export default function mailReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.GET_Mail_List_SUCCESS:
			return {
				...state,
				allMailList: action.payload,
				allMailListPending: false,
				allMailListError: null,
			};
		case actionTypes.GET_Mail_List_PENDING:
			return {
				...state,
				allMailList: [],
				allMailListPending: true,
				allMailListError: null,
			};
		case actionTypes.GET_Mail_List_FAILED:
			return {
				...state,
				allMailList: [],
				allMailListPending: false,
				allMailListError: action.payload,
			};
		default:
			return state;
	}
}
