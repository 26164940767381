import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CfrTabPanel, CfrTabs } from './CfrTabs';
import '../../../styles/pages/cfr/cfr.scss';
import { Conversations } from './Conversations';
import { Notes } from './Notes';
import { Tasks } from './Tasks';
import { useSnackbar } from 'notistack';
import LinearLoader from '../../Common/Loader';
import AlertDialog from '../../Common/Dialog';
import { useEffect } from 'react';
import { removeLocalStorageItem } from '../../../services/StorageService';

export const Cfr: React.FC<any> = (props) => {
	const { okr, kr, type, selectTab, isCfrUpdated, setIsTaskModified, setShowDialog, showDialog } = props;
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = React.useState<Number>();
	const [loader, setLoader] = React.useState<boolean>(false);
	const [taskCount, setTaskCount] = React.useState<any>({
		totalTask: 0,
		taskCompleted: 0,
	});
	const [notesCount, setNotesCount] = React.useState<Number>(0);
	const [conversationCount, setConversationCount] = React.useState<Number>(0);
	const [isTaskEdited, setIsTaskEdited] = React.useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setIsTaskModified(isTaskEdited);
	}, [isTaskEdited]);

	const handleTabChange = (event: any, newValue: Number) => {
		if (isTaskEdited) {
			setShowDialog(true);
		} else {
			setTabSelected(newValue);
			if (newValue !== 0) {
				if (type === 1 && okr?.isUnreadConversation === true) {
					okr.isUnreadConversation = false;
				} else if (type === 2 && kr?.isUnreadConversation === true) {
					kr.isUnreadConversation = false;
				}
			}
		}
	};

	React.useEffect(() => {
		props.resetCount();
		return () => {
			props.resetTasks();
			removeLocalStorageItem('conversationHighlight');
			removeLocalStorageItem('notesHighlight');
			removeLocalStorageItem('isConversationRedirection');
		};
	}, []);

	React.useEffect(() => {
		if (selectTab === 'task') {
			setTabSelected(2);
		} else if (selectTab === 'notes') {
			setTabSelected(3);
		} else if (selectTab === 'conversation') {
			setTabSelected(0);
		}
	}, [selectTab]);

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	React.useEffect(() => {
		if (tabSelected !== 2 && !isCfrUpdated) {
			if (type === 2) {
				setTaskCount({
					...taskCount,
					totalTask: kr?.totalTask,
					taskCompleted: kr?.taskCompleted,
				});
				setNotesCount(kr.totalNotes);
			} else if (type === 1) {
				setTaskCount({
					...taskCount,
					totalTask: okr.totalTask,
					taskCompleted: okr.taskCompleted,
				});
				setNotesCount(okr.totalNotes);
			}
		}
	}, [tabSelected]);

	const handleCloseModal = () => {
		setShowDialog(false);
	};

	return (
		<>
			<Box className='drawer-inner-content '>
				{loader && <LinearLoader />}
				<Box className='drawer-content-panel'>
					<Box className='cfr-head'>
						<Box className='okr-name'>
							<Typography variant='h4'>{type === 2 ? t('keyResultName') : t('objectiveName')}</Typography>
							<Typography>{type === 2 ? kr?.keyDescription : okr?.objectiveName}</Typography>
						</Box>
						<CfrTabs
							taskCount={taskCount}
							notesCount={notesCount}
							{...props}
							handleTabChange={handleTabChange}
							tabSelected={tabSelected}
							type={type}
							okr={okr}
							kr={kr}
						/>
					</Box>
					<Box className='tabs-panel-content'>
						<CfrTabPanel value={tabSelected} index={0} className='conversation-tab'>
							<Conversations
								{...props}
								setLoader={setLoader}
								tabSelected={tabSelected}
								conversationCount={conversationCount}
								setConversationCount={setConversationCount}
								isTaskEdited={isTaskEdited}
								setIsTaskEdited={setIsTaskEdited}
								setShowDialog={setShowDialog}
							/>
						</CfrTabPanel>
						<CfrTabPanel value={tabSelected} index={1}>
							<Box className='tab-panel-inner'>Feedback</Box>
						</CfrTabPanel>
						<CfrTabPanel value={tabSelected} index={2}>
							<Tasks
								{...props}
								tabSelected={tabSelected}
								setTaskCount={setTaskCount}
								taskCount={taskCount}
								setLoader={setLoader}
								loader={loader}
								showApiMsgs={showApiMsgs}
								isTaskEdited={isTaskEdited}
								setIsTaskEdited={setIsTaskEdited}
								setShowDialog={setShowDialog}
							/>
						</CfrTabPanel>
						<CfrTabPanel value={tabSelected} index={3}>
							<Notes
								{...props}
								setLoader={setLoader}
								tabSelected={tabSelected}
								notesCount={notesCount}
								setNotesCount={setNotesCount}
								isTaskEdited={isTaskEdited}
								setIsTaskEdited={setIsTaskEdited}
								setShowDialog={setShowDialog}
							/>
						</CfrTabPanel>
					</Box>
				</Box>
			</Box>
			{showDialog && (
				<AlertDialog
					message={t('taskUnsavedChanges')}
					handleCloseModal={handleCloseModal}
					modalOpen={showDialog}
					isCancel={true}
				/>
			)}
		</>
	);
};
