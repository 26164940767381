import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DurationCycleDetailProps } from '../../Goals/dataTypes';
import { GoalAttributes } from '../../Goals/CreateObjective/GoalAttributes';
import { Enums } from '../../../config/enums';
import {
	getCurrentQuarterAndYear,
	formatDate,
	createUUID,
	getUserDetails,
	getEndDateForEveryMonthForQuarter,
	getSelectedCycle,
} from '../../../config/utils';
import AlertDialog from '../../Common/Dialog';
import { CreateObjectiveDetail } from './CreateObjectiveDetail';
import moment from 'moment';
import { CreateKeyResult } from '../../Goals/KeyResult/CreateKeyResult';
import { CommonMessages } from '../../Common/CommonMessages';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { getKeyDetailObject, getGoalDetailObject } from '../../Common/OkrUtil';
import { Box } from '@material-ui/core';

interface CurrentQuarterAndYearProps {
	year?: String | Number | null;
	quarterSymbol?: String | any;
}

export const CreateObjective: React.FC<any> = (props) => {
	const {
		showAddGoal,
		showAddGoalForm,
		t,
		fetchOkr,
		goalDetail,
		setGoalDetail,
		setIsGoalCreated,
		saveAndEditObjectiveAndKr,
		formSubmit,
		isGoalCreated,
		showApiMsgs,
		setIsTabChanged,
		addObjectiveCallback,
		appDrawerInfo,
		isAnyDrawerOpened,
		resToUseInCalendar,
		editModee,
		setEditModee,
	} = props;

	const currentCycleEndDate = getLocalStorageItem('currentCycleEndDate') || null;
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || null;
	const { year, quarterSymbol }: CurrentQuarterAndYearProps = getCurrentQuarterAndYear();
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const defaultGaolDateError = { error: '', helperText: '', type: '' };
	const defaultFormError = { error: '', helperText: '', type: '' };

	/** ========= state hooks start ============*/

	const [durationCycleDetail, setDurationCycleDetail] = useState<any>(null);
	const [finalSubmitted, setFinalSubmitted] = useState(false);

	useEffect(() => {
		const durationForQuarter = getEndDateForEveryMonthForQuarter(
			cycleStartDate,
			currentCycleEndDate,
			year,
			cycleDuration
		);
		setDurationCycleDetail(durationForQuarter);
	}, [cycleStartDate, currentCycleEndDate, year, cycleDuration]);

	const [openCalender, setOpenCalender] = useState<Boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<Boolean>(false);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '', details: { id: '' } });

	const [keyDetailObject, setKeyDetailObject] = useState<any>(getKeyDetailObject());
	const [goalDetailObject, setGoalDetailObject] = useState<any>(getGoalDetailObject());
	const [objectiveDetail, setObjectiveDetail] = useState<any>({
		...getGoalDetailObject(),
		myGoalsDetails: [{ ...getKeyDetailObject() }],
	});
	const [goalAttrDateError, setGaolAttrDateError] = useState<any>(defaultGaolDateError);
	const [goalFormError, setGoalFormError] = useState<any>(defaultFormError);

	const [isSaveDisabled, setIsSaveDisabled] = useState<Boolean>(true);
	const [goalsAttribute, setGoalsAttribute] = useState<any>({});
	const [showKr, setShowKr] = useState<Boolean>(false);
	const [snackbarProps, setSnackbarProps] = useState<any>({});
	const [loader, setLoader] = useState<Boolean>(false);
	const [autoSaveData, setAutoSaveData] = useState<any>(null);
	const [isAutoSaving, setIsAutoSaving] = useState<any>(null);
	const [isDataSaved, setIsDataSaved] = useState<any>(false);
	const [parentKeyAttribute, setParentKeyAttribute] = useState<any>({});
	const [krFocus, setKrFocus] = useState<Boolean>(false);

	useEffect(() => {
		const updatedData = objectiveDetail.myGoalsDetails.map((item: any) => {
			return {
				...item,
				dueDate:
					props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
						? props.userSelectedCycle.endDate
						: item.dueDate,
				startDate:
					props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
						? props.userSelectedCycle.startDate
						: item.startDate,
			};
		});
		setObjectiveDetail({
			...objectiveDetail,
			startDate:
				props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
					? props.userSelectedCycle.startDate
					: objectiveDetail.startDate,
			endDate:
				props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
					? props.userSelectedCycle.endDate
					: objectiveDetail.endDate,
			objectiveCycleId: props.userSelectedCycle?.organisationCycleId,
			year: props.userSelectedCycle?.year,
			dueDate:
				props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID
					? props.userSelectedCycle.endDate
					: objectiveDetail.dueDate,
			myGoalsDetails: updatedData,
			symbol: props.userSelectedCycle.symbol,
		});
		// getKeyDetailObject;
	}, []);

	/** ======================================== */
	useEffect(() => {
		setShowKr(false);
		const { showAttributeDrawer, actionType, okr } = goalDetail || {};

		if (showAttributeDrawer && actionType === 'editGoal') {
			setGoalsAttribute(okr);
			setObjectiveDetail({
				...objectiveDetail,
				owner: okr.owner,
				isCoach: okr.isCoach,
			});
			setOpenDrawer(true);
		} else if (showAttributeDrawer && (actionType === 'editKr' || actionType === 'addNewKr')) {
			setObjectiveDetail(okr);
			setShowKr(true);
		}

		return () => {
			// setShowKr(false);
		};
	}, [goalDetail]);

	// useEffect(() => {
	// 	setObjectiveDetail({
	// 		...objectiveDetail,
	// 		symbol: props.userSelectedCycle.symbol,
	// 	});
	// }, [props.userSelectedCycle]);

	/**
	 * @param date
	 * @param _name
	 */
	const handleDateChange = (date: any, _name: any, startDate?: any, cycle?: any) => {
		setOpenCalender(false);
		const updatedData = objectiveDetail.myGoalsDetails.map((item: any) => {
			const datesDifferenceInDays = moment(new Date(item.dueDate || new Date())).isAfter(
				new Date(formatDate(date) || new Date()),
				'days'
			);
			const selectedCycleId: any = cycleId;
			let dueDt = datesDifferenceInDays
				? formatDate(date)
				: cycle?.organisationCycleId !== parseInt(selectedCycleId)
				? formatDate(date)
				: formatDate(date);
			const updatedContributor = item?.contributors?.map((contrib: any) => {
				return {
					...contrib,
					dueDate: dueDt,
					startDate: startDate,
					year: cycle?.year,
					cycleId: cycle?.organisationCycleId ? cycle.organisationCycleId : objectiveDetail.objectiveCycleId,
				};
			});

			return {
				...item,
				isTyped: false,
				dueDate: dueDt,
				startDate: startDate,
				objectiveCycleId: cycle?.organisationCycleId ? cycle.organisationCycleId : objectiveDetail.objectiveCycleId,
				year: cycle?.year,
				symbol: cycle?.symbol,
				cycleDetailData: cycle || item?.cycleDetailData,
				contributors: updatedContributor,
			};
		});

		setObjectiveDetail({
			...objectiveDetail,
			endDate: formatDate(date),
			startDate: formatDate(startDate),
			myGoalsDetails: [...updatedData],
			isTyped: true,
			objectiveCycleId: cycle?.organisationCycleId ? cycle.organisationCycleId : objectiveDetail.objectiveCycleId,
			year: cycle?.year,
			symbol: cycle?.symbol,
			cycleDetailData: cycle || objectiveDetail?.cycleDetailData,
		});

		getCallAutoSave({ ...objectiveDetail, endDate: formatDate(date), myGoalsDetails: [...updatedData], isTyped: true });
	};

	/**
	 *
	 * @param details
	 */
	const handleEndOfMonthDateSelection = (details: DurationCycleDetailProps) => {
		setOpenCalender(false);
		const updatedData = objectiveDetail.myGoalsDetails.map((item: any) => {
			const datesDifferenceInDays = moment(new Date(item.dueDate || new Date())).isAfter(
				new Date(formatDate(details.endOfMonthDate) || new Date()),
				'days'
			);
			return {
				...item,
				isTyped: false,
				dueDate: datesDifferenceInDays ? formatDate(details.endOfMonthDate) : item.dueDate,
			};
		});
		setObjectiveDetail({
			...objectiveDetail,
			endDate: formatDate(details.endOfMonthDate),
			myGoalsDetails: [...updatedData],
			isTyped: true,
		});
		getCallAutoSave({
			...objectiveDetail,
			endDate: formatDate(details.endOfMonthDate),
			myGoalsDetails: [...updatedData],
			isTyped: true,
		});
	};

	const handleKeyboardButtonClick = (open: Boolean) => {
		setOpenCalender(open);
	};

	const handleObjectiveNameChange = (event: any) => {
		event.persist();
		const { value } = event.target;
		const { myGoalsDetails } = objectiveDetail;
		setGoalFormError(defaultFormError);
		const updatedData =
			myGoalsDetails && myGoalsDetails.length > 0
				? myGoalsDetails?.map((item: any) => {
						return {
							...item,
							isTyped: false,
						};
				  })
				: [];
		setObjectiveDetail({ ...objectiveDetail, myGoalsDetails: [...updatedData], objectiveName: value, isTyped: true });
		getCallAutoSave({ ...objectiveDetail, myGoalsDetails: [...updatedData], objectiveName: value, isTyped: true });
	};

	/**
	 *
	 * @param krIndex
	 * @param formData
	 */
	const addNewKR = (formData: any, okrDetails: any) => {
		setFinalSubmitted(false);
		const length = okrDetails.myGoalsDetails.length;
		const index = formData ? okrDetails.myGoalsDetails.findIndex((data: any) => data.id === formData.id) : -1;
		if (index + Enums.ONE === length) {
			const updatedGoalDetail = [
				...okrDetails.myGoalsDetails,
				{
					...keyDetailObject,
					id: createUUID(),
					dueDate: okrDetails.endDate,
					startDate: okrDetails.startDate,
					isNewKey: true,
				},
			];
			setObjectiveDetail({
				...okrDetails,
				myGoalsDetails: [...updatedGoalDetail],
			});
		}
	};

	const resetKeyAttributeById = (item: any) => {
		const { myGoalsDetails, startDate, endDate } = objectiveDetail;
		const updatedDetail = myGoalsDetails.map((element: any) => {
			if (element.id === item.id) {
				element.startDate = startDate;
				element.dueDate = endDate;
				element.startValue = 0;
				element.targetValue = 0;
				element.metricId = 0;
				element.currencyId = 0;
			}
			return element;
		});

		setObjectiveDetail({ ...objectiveDetail, myGoalsDetails: updatedDetail });
	};

	const updateFormDetail = (
		item: any,
		key: any,
		value: any,
		isKeyData: Boolean,
		keyAttribute: any,
		resetContribtor = false,
		startDate?: any
	) => {
		const { myGoalsDetails } = objectiveDetail;
		let updatedDetail = [];
		if (isKeyData) {
			updatedDetail = myGoalsDetails.map((element: any) => {
				if (item && element.id === item.id) {
					element.startDate = keyAttribute.startDate ? keyAttribute.startDate : element.startDate;
					element.dueDate = keyAttribute.dueDate ? keyAttribute.dueDate : element.dueDate;
					element.startValue = keyAttribute.startValue ? keyAttribute.startValue : element.startValue;
					element.targetValue = keyAttribute.targetValue ? keyAttribute.targetValue : element.targetValue;
					element.metricId = keyAttribute.metricId ? keyAttribute.metricId : element.metricId;
					element.currencyId = keyAttribute.currencyId ? keyAttribute.currencyId : element.currencyId;
					element.showCurrentTarget = keyAttribute.showCurrentTarget
						? keyAttribute.showCurrentTarget
						: element.showCurrentTarget;
					element.metricName = keyAttribute.metricName ? keyAttribute.metricName : element.metricName;
					element.contributors = keyAttribute.contributors ? keyAttribute.contributors : element.contributors;
					element.keyNotes = keyAttribute.keyNotes ? keyAttribute.keyNotes : element.keyNotes;
					element.keyDescription = keyAttribute.keyDescription ? keyAttribute.keyDescription : element.keyDescription;
					element.krMsg = keyAttribute.krMsg ? keyAttribute.krMsg : element.krMsg;
					element.isNewKey = false;
					element.weightId = keyAttribute.weightId;
					element.confidenceId = keyAttribute.confidenceId;
				}
				if (resetContribtor === true) {
					element.contributors = [];
				}
				return element;
			});
		} else {
			updatedDetail = myGoalsDetails.map((element: any) => {
				if (element.id === item.id) {
					if (key === 'keyDescription' || key === 'dueDate') {
						element.isTyped = true;
						element.isNewKey = false;
					}
					element[key] = value;
					if (startDate) {
						element.startDate = startDate;
					}
				} else {
					element.isTyped = false;
				}
				return element;
			});
		}
		//setIsDataSaved(true);
		setObjectiveDetail({ ...objectiveDetail, isTyped: false, myGoalsDetails: updatedDetail });
		if (key === 'keyDescription' || key === 'dueDate') {
			getCallAutoSave({ ...objectiveDetail, updatedKey: key, isTyped: false, myGoalsDetails: updatedDetail });
		}
	};

	const handleDrawerOpen = (_event: any) => {
		setEditModee(false);
		setShowKr(false);
		setOpenDrawer(true);
	};

	const isGoalAttributeUpdated = (isPopup: boolean = false) => {
		let goalPrev = goalsAttribute;
		let goalNew = objectiveDetail;
		if (isPopup === true) {
			goalNew = goalDetail.okr;
			if (goalNew.goalTypeId === 1 && goalNew.teamOkrRequests && goalNew.teamOkrRequests.length > 0) {
				goalNew.teamOkrRequests[0].id = goalNew.teamOkrRequests[0].teamId;
			}
		}
		let isUpdated =
			(goalPrev.objectiveDescription && goalNew.objectiveDescription !== goalPrev.objectiveDescription) ||
			goalNew.objectiveName !== goalPrev.objectiveName ||
			goalNew.isPrivate !== goalPrev?.isPrivate ||
			(goalPrev?.owner && goalNew.owner !== goalPrev?.owner) ||
			(goalPrev?.goalNatureId && goalNew.goalNatureId !== goalPrev?.goalNatureId);

		if (!isUpdated) {
			if (isPopup === true) {
				isUpdated =
					goalNew?.linkedObjective?.objectiveId !== goalPrev?.linkedObjective?.objectiveId ||
					(goalPrev.startDate && formatDate(goalPrev.startDate) !== formatDate(goalNew.startDate)) ||
					(goalPrev.endDate && formatDate(goalPrev.endDate) !== formatDate(goalNew.endDate));
			} else {
				isUpdated =
					(goalPrev?.linkedObjective && goalNew.linkedObjectiveId !== goalPrev?.linkedObjectiveId) ||
					(goalPrev.startDate && goalNew.startDate !== goalPrev.startDate) ||
					(goalPrev.endDate && goalNew.endDate !== goalPrev.endDate);
			}
		}
		if (!isUpdated && goalPrev.goalTypeId && goalPrev.goalTypeId !== goalNew.goalTypeId) {
			isUpdated =
				goalPrev.goalTypeId !== 1 ||
				(goalPrev.teamOkrRequests !== undefined &&
					goalPrev.teamOkrRequests !== null &&
					goalPrev.teamOkrRequests.length !== 0 &&
					!isTeamArraySame(goalPrev, goalNew));
		} else if (
			!isUpdated &&
			goalPrev.goalTypeId === 1 &&
			goalNew.goalTypeId === 1 &&
			goalPrev.teamOkrRequests !== undefined &&
			goalNew.teamOkrRequests !== undefined
		) {
			isUpdated = !isTeamArraySame(goalPrev, goalNew);
		}
		return !isUpdated;
	};
	const isTeamArraySame = (goal: any, objective: any) => {
		//This check is here for only 1 team as of now;
		let isSame = true;
		if (goal.teamOkrRequests.length !== objective.teamOkrRequests.length) {
			isSame = false;
		} else if (goal.teamOkrRequests.length === 0 && objective.teamOkrRequests.length === 0) {
			isSame = true;
		} else if (!goal.teamOkrRequests[0].id && objective.teamOkrRequests[0].id) {
			isSame = false;
		} else if (goal.teamOkrRequests[0].id !== objective.teamOkrRequests[0].id) {
			isSame = false;
		}
		return isSame;
	};
	const validGoalAttributeForm = (formData: any) => {
		if (!formData.objectiveName) {
			setGaolAttrDateError({
				error: t('objectiveNameError'),
				helperText: t('objectiveNameError'),
				type: 'objectiveName',
			});
			return false;
		} else if (formData.objectiveName?.trim() === '') {
			setGaolAttrDateError({
				error: t('objectiveNameError'),
				helperText: t('objectiveNameError'),
				type: 'objectiveName',
			});
			return false;
		} else if (!formData.goalNatureId || formData.goalNatureId === 0) {
			setGaolAttrDateError({
				error: t('errorTxtNatureOfOKR'),
				helperText: t('errorTxtNatureOfOKR'),
				type: 'natureError',
			});
			return false;
		} else if (!formData.goalTypeId || formData.goalTypeId === 0) {
			setGaolAttrDateError({
				error: t('objectiveTypeError'),
				helperText: t('objectiveTypeError'),
				type: 'objectiveTypeError',
			});
			return false;
		}
		return true;
	};
	const redirectToCycle = (cycleId: number) => {
		if (props.teamCycleDetails?.length) {
			let cycleObj = getSelectedCycle(cycleId, props.teamCycleDetails);
			if (cycleObj?.quarter) {
				props.updateCurrentCycleId(cycleObj?.quarter);
			}
		}
	};
	/** handle close objective drawer */
	const handleDrawerClose = async (event: any, type: String) => {
		setShowKr(false);
		setGaolAttrDateError(defaultGaolDateError);
		if (type === 'save') {
			setSnackbarProps({});
			const datesDifferenceInDays = moment(
				new Date(goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate || new Date())
			).isAfter(
				new Date(goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate || new Date()),
				'days'
			);
			if (Boolean(datesDifferenceInDays)) {
				setGaolAttrDateError({
					error: t('startDateDueDateError'),
					helperText: t('startDateDueDateError'),
					type: 'dueDate',
				});
			} else {
				if (goalDetail && goalDetail.showAttributeDrawer && goalDetail.actionType === 'editGoal') {
					if (goalsAttribute.goalTypeId === 1) {
						//checking if teams button is selected and no team is chosen
						if (goalsAttribute.teamId === 0 || goalsAttribute.teamId === null) {
							return false;
						}
					}
					if (validGoalAttributeForm(goalsAttribute)) {
						setLoader(true);
						if (goalsAttribute.expand) {
							setLocalStorageItem('lastSavedGoal', JSON.stringify(goalsAttribute));
						}
						const data = { ...goalsAttribute };
						if (data.linkedObjective && data.linkedObjective.objectiveId && !data.linkedObjectiveId) {
							data.linkedObjectiveId = data.linkedObjective.objectiveId;
						}

						if (goalsAttribute.goalTypeId === 1) {
							data.owner =
								goalsAttribute.isCoach === true
									? goalsAttribute.owner
									: goalsAttribute?.teamOkrRequests[0].organisationHead;
						}
						const selectedCycleDetails = goalsAttribute?.cycleDetailData || objectiveDetail?.cycleDetailData;
						const response = await props.updateGoalAttributes(data);
						if (response && response.data && response.data.status === 200) {
							if (event && event.ownerChanged === true) {
								setSnackbarProps({ show: true, message: t('ownerChangeSuccess'), variant: 'success' });
							} else {
								setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
							}
							setLoader(false);
							setLoader(false);
							setIsSaveDisabled(true);
							setGaolAttrDateError(defaultGaolDateError);
							setOpenDrawer(false);
							setGoalsAttribute({});
							setGoalDetail({});
							if (
								goalsAttribute.objectiveCycleId &&
								goalsAttribute.objectiveCycleId !== props.userSelectedCycle?.organisationCycleId
							) {
								props.redirectToCycleId(goalsAttribute.objectiveCycleId);
							} else {
								data?.goalObjectiveId && setLocalStorageItem('updatedGoalObjId', data?.goalObjectiveId);
								fetchOkr();
							}
						} else if (response && response.data && response.data.status === 400) {
							setLoader(false);
							const responseAPIMessage = response.data.messageList;
							const keys = Object.keys(responseAPIMessage);
							const messages = keys.map((item) => responseAPIMessage[item]);
							setSnackbarProps({ show: true, message: messages, variant: 'error' });
						}
					}
				} else {
					if (validGoalAttributeForm(goalsAttribute)) {
						objectiveDetail.isSaveClicked = true;
						if (goalsAttribute.teamOkrRequests?.length > 0) {
							objectiveDetail.goalTypeId = 1;
						}
						setObjectiveDetail({
							...objectiveDetail,
							goalTypeId: goalsAttribute.goalTypeId
								? goalsAttribute.goalTypeId
								: objectiveDetail.goalTypeId
								? objectiveDetail.goalTypeId
								: 2,
							goalNatureId: goalsAttribute.goalNatureId,
							objectiveName: goalsAttribute.objectiveName
								? goalsAttribute.objectiveName
								: objectiveDetail.objectiveName,
							isPrivate:
								goalsAttribute.isPrivate === false
									? goalsAttribute.isPrivate
									: objectiveDetail.isPrivate
									? objectiveDetail.isPrivate
									: goalsAttribute.isPrivate,
							objectiveDescription: goalsAttribute.objectiveDescription
								? goalsAttribute.objectiveDescription
								: objectiveDetail.objectiveDescription,
							startDate: goalsAttribute.startDate ? goalsAttribute.startDate : objectiveDetail.startDate,
							endDate: goalsAttribute.endDate ? goalsAttribute.endDate : objectiveDetail.endDate,
							myGoalsDetails: goalsAttribute.myGoalsDetails
								? goalsAttribute.myGoalsDetails
								: objectiveDetail.myGoalsDetails,
							teamOkrRequests: goalsAttribute.teamOkrRequests
								? goalsAttribute.teamOkrRequests
								: objectiveDetail.teamOkrRequests,
							teamId: goalsAttribute.teamId ? goalsAttribute.teamId : objectiveDetail.teamId,
							teamName: goalsAttribute.teamName ? goalsAttribute.teamName : objectiveDetail.teamName,
							teamLogo: goalsAttribute.teamLogo ? goalsAttribute.teamLogo : objectiveDetail.teamLogo,
							teamMembersCount: goalsAttribute.teamMembersCount
								? goalsAttribute.teamMembersCount
								: objectiveDetail.teamMembersCount,
							owner:
								goalsAttribute.goalTypeId === 2
									? goalsAttribute.owner
										? goalsAttribute.owner
										: objectiveDetail.owner
									: goalsAttribute.teamOkrRequests[0]?.isMyTeam
									? goalsAttribute.owner
										? goalsAttribute.owner
										: objectiveDetail.owner
									: goalsAttribute.teamOkrRequests[0].organisationHead,

							// (goalsAttribute.teamOkrRequests[0]?.isMyTeam)
							// 	? (goalsAttribute.owner ? goalsAttribute.owner : objectiveDetail.owner)
							// 	: goalsAttribute.teamOkrRequests[0].organisationHead,//goalsAttribute.owner ? goalsAttribute.owner : objectiveDetail.owner,
							ownerDesignation: goalsAttribute.ownerDesignation
								? goalsAttribute.ownerDesignation
								: objectiveDetail.ownerDesignation,
							ownerEmailId: goalsAttribute.ownerEmailId ? goalsAttribute.ownerEmailId : objectiveDetail.ownerEmailId,
							ownerFirstName: goalsAttribute.ownerFirstName
								? goalsAttribute.ownerFirstName
								: objectiveDetail.ownerFirstName,
							ownerLastName: goalsAttribute.ownerLastName
								? goalsAttribute.ownerLastName
								: objectiveDetail.ownerLastName,
							ownerImagePath: goalsAttribute.ownerImagePath,
							ownerEmployeeCode: goalsAttribute.ownerEmployeeCode
								? goalsAttribute.ownerEmployeeCode
								: objectiveDetail.ownerEmployeeCode,
							linkedObjectiveId: goalsAttribute.linkedObjectiveId
								? goalsAttribute.linkedObjectiveId
								: objectiveDetail.linkedObjectiveId,
							linkedObjective: goalsAttribute.linkedObjective
								? goalsAttribute.linkedObjective
								: objectiveDetail.linkedObjective,
							isCoach: goalsAttribute.isCoach,
							cycleDetailData: goalsAttribute?.cycleDetailData || objectiveDetail?.cycleDetailData,
							year:
								goalsAttribute?.cycleDetailData?.year ||
								objectiveDetail?.cycleDetailData?.year ||
								goalsAttribute?.year ||
								objectiveDetail?.year,
							objectiveCycleId:
								goalsAttribute?.cycleDetailData?.organisationCycleId ||
								objectiveDetail?.cycleDetailData?.organisationCycleId ||
								props.userSelectedCycle?.organisationCycleId,
						});
						setIsSaveDisabled(true);
						setGaolAttrDateError(defaultGaolDateError);
						setOpenDrawer(false);
						setGoalsAttribute({});
						props.updateCalloutPanel && props.updateCalloutPanel('createOkr');
					}
				}
			}
		} else if (type === 'close') {
			setShowKr(false);
			setGoalDetail({});
			if (!isGoalAttributeUpdated()) {
				setModalProps({
					open: true,
					type: 'GoalAttr',
					message: t('unSavedItemAlert'),
					module: 'user',
					details: { id: '' },
				});
			} else {
				setGoalsAttribute({});
				setOpenDrawer(false);
				props.updateCalloutPanel && props.updateCalloutPanel('createOkr');
			}
		}
	};

	/** handle close create goal drawer */
	const handleCloseCreateGoal = (_event: any, type: any) => {
		if (type === 'cancel' && goalDetailObject.objectiveName !== objectiveDetail.objectiveName) {
			setModalProps({
				open: true,
				type: 'Goal',
				message: t('unSavedItemAlertMessage'),
				module: 'createMyGoal',
				details: { id: '' },
			});
			removeLocalStorageItem('showCallouts');
			props.updateCalloutPanel('');
		} else if (type === 'delete') {
			setModalProps({
				open: true,
				type: 'deleteMyObjective',
				message: t('deleteOkrAlertMessage'),
				module: 'deleteMyObjective',
				details: { id: '' },
			});
		} else {
			showAddGoalForm(false);
			Boolean(isGoalCreated) && fetchOkr(true);
			setIsGoalCreated(false);
			removeLocalStorageItem('showCallouts');
			props.updateCalloutPanel('');
		}
		setIsTabChanged(false);
	};

	/** handle close drawer by confirmation popup */
	const handleCloseModal = async (event: any, type: Number) => {
		event.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'GoalAttr') {
				setIsSaveDisabled(true);
				setOpenDrawer(false);
				setGoalsAttribute({});
				props.updateCalloutPanel && props.updateCalloutPanel('createOkr');
			} else if (modalProps.type === 'Goal') {
				if (autoSaveData) {
					const { myGoalsDetails } = objectiveDetail;
					const updatedDetail =
						myGoalsDetails && myGoalsDetails.length > 0
							? myGoalsDetails.map((element: any, index: number) => {
									element.goalKeyId = getGoalKeyId(element, index);
									return element;
							  })
							: [];
					const request = {
						...objectiveDetail,
						goalObjectiveId: autoSaveData?.goalObjectiveId || 0,
						goalStatusId: Enums.ONE,
						myGoalsDetails: updatedDetail,
					};
					handleSaveObjective(event, 3, request); // Save as Draft
				} else {
					handleSaveObjective(event, 3); // Save as Draft
				}
				removeLocalStorageItem('showCallouts');
				props.updateCalloutPanel('');
			}
		} else if (type === 2) {
			if (modalProps.type === 'Goal') {
				if (autoSaveData?.goalObjectiveId) {
					const userDetail: any = getUserDetails();
					const { employeeId } = userDetail;
					const id = autoSaveData?.goalObjectiveId;
					const goalType = Enums.ONE;
					const message = 'Objective';
					const data = `employeeId=${employeeId}&goalObjectiveId=${id}&goalType=${goalType}`;
					const response = await props.deleteObjective(data);
					if (response.data.status === 200) {
						fetchOkr();
						showApiMsgs(response?.data?.messageList?.Result, 'success');
					} else {
						showApiMsgs(t('deletedMessageFail', { message: `${message}` }), 'error');
					}
				}
				setObjectiveDetail({
					...goalDetailObject,
					myGoalsDetails: [{ ...keyDetailObject }],
				});
				showAddGoalForm(false);
				Boolean(isGoalCreated) && fetchOkr(true);
				setIsGoalCreated(false);
			} else if (modalProps.type === 'deleteMyObjective') {
				showApiMsgs(t('okrDeletedSuccess'), 'success');
				showAddGoalForm(false);
				setIsGoalCreated(false);
			} else if (modalProps.type === 'DeleteKR') {
				const { myGoalsDetails } = objectiveDetail;
				const updatedKeyResultDetails = myGoalsDetails.filter((ele: any) => ele.id !== modalProps.details.id);
				setObjectiveDetail({ ...objectiveDetail, myGoalsDetails: updatedKeyResultDetails });
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '', details: { id: '' } });
	};

	/** delete Key Result */
	const deleteAddedKR = (_event: any, item: any) => {
		if (item?.keyDescription) {
			setModalProps({
				open: true,
				type: 'DeleteKR',
				message: t('deleteKrAlert'),
				module: 'deleteKR',
				details: item,
			});
		} else {
			const { myGoalsDetails } = objectiveDetail;
			const updatedKeyResultDetails = myGoalsDetails.filter((ele: any) => ele.id !== item.id);
			setObjectiveDetail({ ...objectiveDetail, myGoalsDetails: updatedKeyResultDetails });
		}
	};

	const validateAddContributorForm = (formData: any, type: Number) => {
		setSnackbarProps({});
		if (!formData.objectiveName || formData.objectiveName.trim() === '') {
			setSnackbarProps({ show: true, message: t('objectiveNameError'), variant: 'error' });
			return false;
		}
		if (type === 1 || type === 2) {
			if (formData?.myGoalsDetails && formData?.myGoalsDetails?.length) {
				const data = formData.myGoalsDetails.filter(
					(item: any) => item.keyDescription && item.keyDescription.trim() !== ''
				);
				if (data && data.length === 0) {
					setSnackbarProps({ show: true, message: t('keyResultError'), variant: 'error' });
					return false;
				}
			}
		}
		return true;
	};
	/**
	 * it is to handle save objective
	 * @param type <Number> will be 1 for save and exit and 2 for save and add another
	 */
	const handleSaveObjective = async (event: any, type: Number, request?: any) => {
		/** type = 1 === save and Add Another
		 *  type = 2 === Save & Exit
		 *  type = 3 === Save as Draft
		 *  type = 4 === Auto Save
		 */
		let apiRequest =
			type === 4
				? request
				: type === 3 && request?.goalObjectiveId && request?.goalObjectiveId > 0
				? request
				: objectiveDetail;
		let isNew =
			type === 4 && apiRequest?.goalObjectiveId && apiRequest?.goalObjectiveId > 0
				? false
				: type === 3 && apiRequest?.goalObjectiveId && apiRequest?.goalObjectiveId > 0
				? false
				: true;
		if (type === 1 || type === 2) {
			if (autoSaveData) {
				const { myGoalsDetails } = objectiveDetail;
				const updatedDetail =
					myGoalsDetails && myGoalsDetails.length > 0
						? myGoalsDetails.map((element: any, index: number) => {
								element.goalKeyId = getGoalKeyId(element, index);
								element.contributors.map((ele: any) => {
									ele.krStatusId = Enums.KR_STATUS_PENDING;
									return ele;
								});
								return element;
						  })
						: [];
				const updatedRequest = {
					...objectiveDetail,
					goalObjectiveId: autoSaveData?.goalObjectiveId || 0,
					goalStatusId: Enums.TWO,
					myGoalsDetails: updatedDetail,
				};
				apiRequest = updatedRequest;
				isNew = false;
			}
		} else if (type === 3 && autoSaveData) {
			const updatedDetail =
				objectiveDetail.myGoalsDetails && objectiveDetail.myGoalsDetails.length > 0
					? objectiveDetail.myGoalsDetails.map((element: any, index: number) => {
							element.goalKeyId = getGoalKeyId(element, index);
							element.contributors.map((ele: any) => {
								ele.krStatusId = Enums.KR_STATUS_PENDING;
								return ele;
							});
							return element;
					  })
					: [];
			const updatedRequest = {
				...objectiveDetail,
				goalObjectiveId: autoSaveData?.goalObjectiveId || 0,
				goalStatusId: Enums.ONE,
				myGoalsDetails: updatedDetail,
			};
			apiRequest = updatedRequest;
			isNew = false;
		}
		let currentOwner = apiRequest.owner;
		apiRequest?.myGoalsDetails?.forEach((goalKrData: any) => {
			goalKrData.owner = currentOwner;
		});
		apiRequest.myGoalsDetails = apiRequest.myGoalsDetails.filter(
			(item: any) => item.keyDescription && item.keyDescription.trim() !== ''
		);
		if (validateAddContributorForm(apiRequest, type)) {
			setSnackbarProps({});
			setGoalFormError(defaultFormError);
			setIsAutoSaving(true);
			setKrFocus(false);
			const response = await saveAndEditObjectiveAndKr(apiRequest, type, isNew);
			if (response === 'success') {
				if (type !== 4) {
					removeLocalStorageItem('showCallouts');
					props.updateCalloutPanel('');
				}

				setShowKr(false);
				setAutoSaveData({});
				setIsAutoSaving(null);
				setObjectiveDetail({
					...goalDetailObject,
					myGoalsDetails: [{ ...keyDetailObject }],
				});
				addObjectiveCallback(type);
				isAnyDrawerOpened({
					...appDrawerInfo,
					isNewObjectiveFormOpened: false,
				});
			} else {
				if (response !== 'error' && response?.goalObjectiveId) {
					setAutoSaveData(response ? response : {});
					if (!apiRequest?.isTyped) {
						const { myGoalsDetails } = apiRequest;
						const formData =
							myGoalsDetails && myGoalsDetails.length && myGoalsDetails.find((item: any) => item?.isTyped);
						addNewKR(formData, apiRequest);
					}
				}
				setIsAutoSaving(false);
				setTimeout(() => {
					setIsAutoSaving(null);
				}, 500);
			}
			if (request && request.updatedKey) {
				setKrFocus(true);
			}
		}
	};
	const [autoSave, setAutoSave] = useState<any>(null);
	// useEffect(() => {
	// 	autoSave && handleSaveObjective({}, 4, autoSave); // Save as Draft
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [autoSave]);

	const debouncedSave = useCallback(
		debounce(async (autoSaveNew: any) => {
			setIsDataSaved(false);
			await handleSaveObjective({}, 4, autoSaveNew);
		}, 1200),
		[]
	);

	const debouncedNoAutoSave = useCallback(
		debounce(async (objectiveDetails: any) => {
			const { myGoalsDetails } = objectiveDetails;
			if (myGoalsDetails && myGoalsDetails.length > 0) {
				let hasBlank = false;
				myGoalsDetails.forEach((element: any, index: number) => {
					if (element.keyDescription.trim() === '' || !element.keyDescription) {
						hasBlank = true;
					}
				});
				if (hasBlank === false) {
					let myGoalsDetailsList = Object.assign([], myGoalsDetails);
					myGoalsDetailsList.push({
						...keyDetailObject,
						id: createUUID(),
						dueDate: objectiveDetails.endDate,
						startDate: objectiveDetails.startDate,
						isNewKey: true,
					});
					setObjectiveDetail({
						...objectiveDetails,
						myGoalsDetails: myGoalsDetailsList,
					});
				}
			}
		}, 1200),
		[]
	);

	useEffect(() => {
		if (autoSave && !isAutoSaving) {
			// TODO: temporary change to stop auto save feature will look for a better solution.
			debouncedNoAutoSave(objectiveDetail);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoSave, debouncedSave]);

	// const delayedQuery = useCallback(
	// 	_.debounce((request: any) => {
	// 		setAutoSave(request);
	// 	}, 1000),
	// 	[]
	// );

	const getGoalKeyId = (_element: any, index: number) => {
		const { myGoalsDetails } = autoSaveData ? autoSaveData : { myGoalsDetails: [] };
		if (myGoalsDetails && myGoalsDetails.length) {
			const indexVal = myGoalsDetails.findIndex((_item: any, path: number) => path === index);
			if (indexVal >= 0) {
				return myGoalsDetails[indexVal].goalKeyId;
			}
		}
		return Enums.ZERO;
	};

	const getCallAutoSave = (obj: any) => {
		const { myGoalsDetails } = obj;
		const updatedDetail =
			myGoalsDetails && myGoalsDetails.length > 0
				? myGoalsDetails.map((element: any, index: number) => {
						element.goalKeyId = getGoalKeyId(element, index);
						element.contributors.map((ele: any) => {
							ele.krStatusId = Enums.KR_STATUS_PENDING;
							return ele;
						});
						return element;
				  })
				: [];
		// delayedQuery({

		// });
		setAutoSave({
			...obj,
			goalObjectiveId: autoSaveData?.goalObjectiveId || 0,
			goalStatusId: Enums.ONE,
			myGoalsDetails: updatedDetail,
		});
	};

	/** render component elements */
	return (
		<Box className='pad-container'>
			{snackbarProps.show && <CommonMessages snackbarProps={snackbarProps} setSnackbarProps={setSnackbarProps} />}
			<CreateObjectiveDetail
				t={t}
				addNewKR={addNewKR}
				deleteAddedKR={deleteAddedKR}
				updateFormDetail={updateFormDetail}
				resetKeyAttributeById={resetKeyAttributeById}
				openCalender={openCalender}
				handleSaveObjective={handleSaveObjective}
				showAddGoal={showAddGoal}
				durationCycleDetail={durationCycleDetail}
				handleKeyboardButtonClick={handleKeyboardButtonClick}
				quarterSymbol={quarterSymbol}
				handleDrawerOpen={handleDrawerOpen}
				handleEndOfMonthDateSelection={handleEndOfMonthDateSelection}
				handleCloseCreateGoal={handleCloseCreateGoal}
				objectiveDetail={objectiveDetail}
				handleObjectiveNameChange={handleObjectiveNameChange}
				handleDateChange={handleDateChange}
				currentCycleEndDate={currentCycleEndDate}
				formSubmit={formSubmit}
				year={year}
				goalFormError={goalFormError}
				setShowKr={setShowKr}
				{...props}
				autoSaveData={autoSaveData}
				isAutoSaving={isAutoSaving}
				isDataSaved={isDataSaved}
				setIsDataSaved={setIsDataSaved}
				setFinalSubmitted={setFinalSubmitted}
				finalSubmitted={finalSubmitted}
				krFocus={krFocus}
				editModee={editModee}
				setEditModee={setEditModee}
				goalsAttribute={goalsAttribute}
			/>
			{openDrawer && (
				<GoalAttributes
					durationCycleDetail={durationCycleDetail}
					currentCycleEndDate={currentCycleEndDate}
					open={openDrawer}
					handleDrawerClose={handleDrawerClose}
					objectiveDetail={objectiveDetail}
					t={t}
					{...props}
					isGoalAttributeUpdated={isGoalAttributeUpdated}
					goalAttrDateError={goalAttrDateError}
					setIsSaveDisabled={setIsSaveDisabled}
					setGoalsAttribute={setGoalsAttribute}
					goalsAttribute={goalsAttribute}
					setGaolAttrDateError={setGaolAttrDateError}
					loader={loader}
					updateFormDetail={updateFormDetail}
					keyData={goalDetail.kr}
					parentKeyAttribute={parentKeyAttribute}
					resToUseInCalendar={resToUseInCalendar}
					editModee={editModee}
					setEditModee={setEditModee}
				/>
			)}
			{showKr && (
				<CreateKeyResult
					currentCycleEndDate={currentCycleEndDate}
					objectiveEndDate={objectiveDetail.endDate || new Date()}
					objectiveStartDate={objectiveDetail.startDate || new Date()}
					objectiveDetail={objectiveDetail}
					durationCycleDetail={durationCycleDetail}
					defaultTypeChar={Enums.DEFAULT_TYPE_CHAR}
					t={t}
					addNewKR={addNewKR}
					updateFormDetail={updateFormDetail}
					resetKeyAttributeById={resetKeyAttributeById}
					keyData={goalDetail.kr}
					deleteAddedKR={deleteAddedKR}
					goalDetail={goalDetail}
					setSnackbarProps={setSnackbarProps}
					setGoalDetail={setGoalDetail}
					setShowKr={setShowKr}
					autoSaveData={autoSaveData}
					isAutoSaving={isAutoSaving}
					quarterSymbol={quarterSymbol}
					setParentKeyAttribute={setParentKeyAttribute}
					setCallOut={() => {}}
					setAnchorElCallouts={() => {}}
					krFocus={krFocus}
					{...props}
				/>
			)}
			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Box>
	);
};
