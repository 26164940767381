import React, { Fragment, useEffect, useRef, useState } from 'react';
import noRecordFound from '../../../images/search-placeholder.svg';
import { Avatar, Box, Typography, Tooltip } from '@material-ui/core';
import { getUserName, getRandomBackgroundColor, getRandomColor } from '../../../config/utils';
import { EditTreeIcon } from '../../../config/svg/CommonSvg';
import { ClickAwayListener } from '@material-ui/core';
import AlertDialog from '../../Common/Dialog';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { getLocalStorageItem } from '../../../services/StorageService';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';

export const TeamsOkr: React.FC<any> = (props) => {
	const {
		treeNodesData,
		open,
		teamsLoading,
		t,
		setOpen,
		setShowChips,
		setSelectedTeams,
		showChips,
		selectedTeams,
		editEnabled,
		callTreeNodes,
		setDeletedNodes,
		teamDetails,
		anchorElCallouts,
		otherTeams,
	} = props;

	const [modalProps, setModalProps] = useState<any>({
		modalOpen: false,
		deleteNodeId: 0,
		anchorEl: null,
	});

	const handleClose = () => {
		setOpen(false);
	};
	const nodeClicked = (args: any) => {
		let nodeDetails: any = Object.assign({}, args);
		let selectedNode = [];
		nodeDetails.teamId = args.id;
		nodeDetails.fullname = args.fullname;
		nodeDetails.goalObjectiveId = 0;
		nodeDetails.teamEmployees = [];
		nodeDetails.teamEmployees = [];
		nodeDetails.leaderDesignation = args.leaderDesignation;
		nodeDetails.leaderFirstName = args.leaderFirstName;
		nodeDetails.leaderImagePath = args.leaderImagePath;
		nodeDetails.leaderLastName = args.leaderLastName;

		selectedNode.push(nodeDetails);
		setSelectedTeams(selectedNode);
		setShowChips(true);
		handleClose();
	};

	const handleDelete = (nodeId: any) => {
		setModalProps({ modalOpen: true, deleteNodeId: nodeId, anchorEl: null });
	};

	const handleClick = (event: any) => {
		setModalProps({ ...setModalProps, anchorEl: event.currentTarget });
	};

	const handleClose1 = () => {
		setModalProps({ ...setModalProps, anchorEl: null });
	};

	const handleCloseModal = (e: any, action: any) => {
		if (action === 1) {
			let filterTeams: any[] = [];
			selectedTeams.forEach((data: any) => {
				if (data.id !== modalProps.deleteNodeId) {
					filterTeams.push(data);
				}
			});
			setDeletedNodes(filterTeams);
		}
		setModalProps({ modalOpen: false, deleteNodeId: 0, anchorEl: null });
	};
	//callout popup
	const [showCallouts, setShowCallouts] = useState<boolean>(false);
	useEffect(() => {
		if (getLocalStorageItem('showCallouts')) {
			setShowCallouts(true);
		}
	}, [props.onboardingCount]);
	const inputRefOkrDescription: any = useRef();
	/*useEffect(() => {
		if (open) {
			setAnchorElCallouts && setAnchorElCallouts(inputRefOkrDescription.current);
			setCallOut &&
				setCallOut({
					open: true,
					timer: getReadTimer(t('teamListGuidedText')),
					text: `${t('teamListGuidedText')}`,
					placement: 'left-start',
					classname: 'middle-md',
				});
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut &&
				setCallOut({
					open: false,
					timer: 0,
					text: '',
					placement: '',
					classname: '',
				});
		}
	}, [open]);*/
	return (
		<Fragment>
			{open ? (
				<Box className=' teamPopover'>
					<ClickAwayListener onClickAway={handleClose}>
						<Typography
							component='div'
							ref={inputRefOkrDescription}
							className='contributors contibutor-score teams-data'
						>
							<Box className='tree-view-popover'>
								{treeNodesData !== null && treeNodesData.length > 0 ? (
									<Box>
										<div className='k-treeview' id='custom-scroll'>
											{props.myTeams && props.myTeams.length > 0 && <p className='team-tag-popover'>My Team(s)</p>}
											{treeNodesData.map((item: any) => (
												<Fragment>
													{item.isMyTeam && (
														<Box
															className={`teamsRow ${
																selectedTeams.length > 0 && selectedTeams[0].id === item.id ? 'e-active' : ''
															} `}
															onClick={() => {
																nodeClicked(item);
															}}
															id={`team-list-row-${item.id}`}
														>
															{item.membersCount === undefined || parseInt(item.membersCount) === 0 ? (
																<Tooltip arrow title={t('teamsZeroMember')}>
																	<Box>
																		<UserInfo userInfo={item} chartProps={props} />
																	</Box>
																</Tooltip>
															) : (
																<UserInfo userInfo={item} chartProps={props} />
															)}
														</Box>
													)}
												</Fragment>
											))}
											{otherTeams && otherTeams.length > 0 && (
												<Fragment>
													<p className='team-tag-popover'>Other Team(s)</p>
													{treeNodesData.map((item: any) => (
														<Fragment>
															{!item.isMyTeam && (
																<Box
																	className={`teamsRow ${
																		selectedTeams.length > 0 && selectedTeams[0].id == item.id ? 'e-active' : ''
																	} `}
																	onClick={() => {
																		nodeClicked(item);
																	}}
																	id={`team-list-row-${item.id}`}
																>
																	{item.membersCount === undefined || parseInt(item.membersCount) === 0 ? (
																		<Tooltip arrow title={t('teamsZeroMember')}>
																			<Box>
																				<UserInfo userInfo={item} chartProps={props} />
																			</Box>
																		</Tooltip>
																	) : (
																		<UserInfo userInfo={item} chartProps={props} />
																	)}
																</Box>
															)}
														</Fragment>
													))}
												</Fragment>
											)}
										</div>
									</Box>
								) : teamsLoading ? (
									<div className='teams-loader'>
										<span>{t('loading')}</span>
									</div>
								) : teamDetails && teamDetails.length === 0 ? (
									<Box className='search-no-record-global owner-search' display='flex' justifyContent='center'>
										<Box className='no-record-message' textAlign='center'>
											<Typography variant='h2' component='h2'>
												{t('NoRecord')}
											</Typography>
											<Typography variant='h6' component='h6' className='no-sentence-case'>
												{t('allDone')}
											</Typography>
											<AnimatedIcon
												className='no-record-img'
												width='250'
												type='image/svg+xml'
												alt={t('globalSearchNoRecord')}
												imagePath={noRecordFound}
											/>
										</Box>
									</Box>
								) : (
									<NoSearchRecord />
								)}
							</Box>
						</Typography>
					</ClickAwayListener>
				</Box>
			) : (
				<span></span>
			)}
			{showChips && selectedTeams.length > 0 ? (
				<Fragment>
					<Box className='user-team-wrap'>
						{selectedTeams.map((element: any, index: any) => {
							let selectedTags = (
								<Box className='user-info user-team-info'>
									<Box className='user-img'>
										{element && element.imagePath ? (
											<Avatar className='avatar-default' alt={`${element.fullname}`} src={element.imagePath} />
										) : (
											<Avatar
												className='avatar-default'
												style={{
													backgroundColor: getRandomBackgroundColor(element?.backGroundColorCode),
													color: getRandomColor(element?.colorCode),
												}}
											>
												{getUserName({
													firstName: '',
													lastName: '',
													fullName: element.fullname,
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4'>{element.fullname}</Typography>
										<Typography variant='subtitle2'>
											{element.teamMembersCount !== undefined && element.teamMembersCount !== 0 ? (
												<span className='secondary-text-span'>{element.teamMembersCount} Members</span>
											) : element.membersCount !== undefined && element.membersCount !== 0 ? (
												<span className='secondary-text-span'>{element.membersCount} Members</span>
											) : (
												<span className='secondary-text-span no-member'>No Member</span>
											)}
										</Typography>
									</Box>
								</Box>
							);
							if (index < 4) {
								if (editEnabled) {
									return (
										<>
											{selectedTags}
											{editEnabled && selectedTeams.length > 0 ? (
												<Tooltip
													arrow
													title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? 'Edit' : ''}
												>
													<span className='edit-tree' id='eidt-team-tree' onClick={callTreeNodes}>
														<EditTreeIcon />
													</span>
												</Tooltip>
											) : (
												<span></span>
											)}
										</>
									);
								} else {
									return selectedTags;
								}
							}
						})}
					</Box>
				</Fragment>
			) : (
				<span></span>
			)}

			{modalProps.modalOpen && (
				<AlertDialog
					module='organization'
					message={t('deleteTeamMemberAlertMessage')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.modalOpen}
				/>
			)}
		</Fragment>
	);
};

export const UserInfo = (props: any) => {
	let kr = props.userInfo;
	return (
		<Box className='user-team-wrap'>
			<Box className='user-info user-team-info'>
				<Box className='user-img'>
					{kr && kr.imagePath ? (
						<Avatar alt={`${kr.fullname}`} src={kr.imagePath} />
					) : (
						<Avatar
							style={{
								backgroundColor: getRandomBackgroundColor(kr.backGroundColorCode),
								color: getRandomColor(kr.colorCode),
							}}
						>
							{getUserName({
								firstName: '',
								lastName: '',
								fullName: kr.fullname,
							})}
						</Avatar>
					)}
				</Box>

				<Box className='user-info-details'>
					<Typography variant='h4'>{kr.fullname}</Typography>
					<Typography className='secondary-text-div' variant='subtitle2'>
						{kr.teamCount !== undefined ? <span className='secondary-text-span'>{kr.teamCount} Teams(s)</span> : ''}
						{kr.membersCount !== undefined && kr.teamCount !== undefined ? (
							<span className='secondary-text-span'> | </span>
						) : (
							''
						)}
						{kr.membersCount !== undefined && kr.membersCount !== 0 ? (
							<span className='secondary-text-span'>{kr.membersCount} Member(s)</span>
						) : (
							<span className='secondary-text-span'>No Member</span>
						)}
					</Typography>
					{kr.secondaryText && (
						<Typography
							className='team-text-span'
							variant='body2'
							style={{
								backgroundColor: getRandomBackgroundColor(kr.parentTeamBackGroundColorCode),
								color: getRandomColor(kr.parentTeamColorCode),
							}}
						>
							{kr.secondaryText}
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
};
