import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, FormLabel, List, ListItem, TextField, Typography } from '@material-ui/core';
import {
	getFullName,
	getCurrencyIcon,
	getMonthDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserName,
} from '../../config/utils';
import { clone } from 'lodash';
import { Enums } from '../../config/enums';
import { BooleanIcon, CurrencyIcon, NoUnitsIcon, NumbersIcon, PercentageIcon } from '../../config/svg/MyGoalSvg';
import { OKRButton } from '../Common/OKRButton';

export const KrDeclineAttributesDetail: React.FC<any> = (props) => {
	const {
		declineMessage,
		setDeclineMessage,
		okr,
		kr,
		setOrphanKRData,
		okrMasterData,
		orphanKRData,
		KRType,
		selectedMetricDetail,
	} = props;
	const [sourceContributor, setSourceContributor] = useState<any>();
	const keyData = KRType === 'WithParent' ? clone(kr) : clone(okr);

	useEffect(() => {
		const { metricId } = keyData;
		if (metricId) {
			const { metricMasters } = okrMasterData;
			const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 5 : keyData?.metricId;
			const selectedCurrencyId = keyData?.currencyId ? keyData.currencyId : keyData?.currencyId;
			let selectedCurrencyDetail: any = {};
			const selectedMetric =
				metricMasters && metricMasters.length
					? metricMasters.find((item: any) => item.metricId === selectedMetricId)
					: null;
			if (selectedMetric && selectedMetric?.name === 'Currency') {
				const { metricDataMaster } = selectedMetric;
				const selectedCurrency =
					metricDataMaster && metricDataMaster.length
						? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
						: null;
				selectedCurrencyDetail = selectedCurrency;
			}
			setOrphanKRData({
				...orphanKRData,
				metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
				metricDescription: selectedMetric && selectedMetric?.description ? selectedMetric?.description : '',
				currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		const { contributors } = kr;
		if (contributors) {
			const sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			setSourceContributor(sourceData);
		} else {
			const { contributors } = okr;
			const sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			setSourceContributor(sourceData);
		}
	}, [okr, kr]);

	const callbackRef = useCallback((inputElement) => {
		setTimeout(() => {
			if (inputElement) {
				inputElement.value = '';
				inputElement.focus();
			}
		}, 100);
	}, []);

	return (
		<Box className='drawer-inner-content'>
			<Box className='drawer-content-panel'>
				<form className='drawer-form-fields kr-decline-form' noValidate autoComplete='off'>
					<Box className='drawer-field-row user-info'>
						<Box className='user-img'>
							{sourceContributor && sourceContributor?.imagePath ? (
								<Avatar alt={getFullName(sourceContributor)} src={sourceContributor?.imagePath} />
							) : sourceContributor ? (
								<Avatar
									style={{
										backgroundColor: getRandomBackgroundColor(sourceContributor?.backGroundColorCode),
										color: getRandomColor(sourceContributor?.colorCode),
									}}
								>
									{getUserName(sourceContributor)}
								</Avatar>
							) : (
								<></>
							)}
						</Box>
						<Box className='user-info-details'>
							<Typography variant='h4'>{sourceContributor ? getFullName(sourceContributor) : ''}</Typography>
							<Typography variant='subtitle2'>{`${sourceContributor?.designation}`}</Typography>
						</Box>
					</Box>
					{orphanKRData?.assignmentTypeId === Enums.TWO ? (
						<Box className='drawer-input-field'>
							<FormLabel component='legend'>Objective</FormLabel>
							<Typography>{orphanKRData?.objectiveName}</Typography>
						</Box>
					) : (
						<></>
					)}
					<Box className='drawer-input-field'>
						<FormLabel component='legend'>Key Result</FormLabel>
						<Typography>{orphanKRData?.keyDescription}</Typography>
					</Box>
					<Box className='drawer-input-field kr-measure-unit'>
						<FormLabel component='legend'>Assigned progress</FormLabel>
						<Box display='flex'>
							<OKRButton
								className={`measure-btn`}
								id={`measure-btn-${orphanKRData && orphanKRData?.metricName}`}
								icon={
									orphanKRData && orphanKRData?.metricName === 'Percentage' ? (
										<PercentageIcon />
									) : orphanKRData && orphanKRData?.metricName === 'Numbers' ? (
										<NumbersIcon />
									) : orphanKRData && orphanKRData?.metricName === 'Currency' ? (
										<CurrencyIcon />
									) : orphanKRData && orphanKRData?.metricName === 'Boolean' ? (
										<BooleanIcon />
									) : orphanKRData && orphanKRData?.metricName === 'NoUnits' ? (
										<NoUnitsIcon />
									) : (
										<></>
									)
								}
								text={orphanKRData?.metricDescription || ''}
								disabled={true}
							/>
							{orphanKRData && (orphanKRData?.metricName === 'NoUnits' || orphanKRData?.metricName === 'Boolean') ? (
								<></>
							) : (
								<Box className='kr-metrics-fileds decline-metrics-field'>
									<Box className='kr-metrics-fileds-inner'>
										<Box className='current-value'>
											<FormLabel component='legend'>Current</FormLabel>
											<Box className='currency-icon-wrap'>
												{orphanKRData && orphanKRData?.metricName === 'Percentage' ? (
													<PercentageIcon />
												) : orphanKRData && orphanKRData?.metricName === 'Numbers' ? (
													<NumbersIcon />
												) : orphanKRData && orphanKRData?.metricName === 'Currency' ? (
													getCurrencyIcon(
														orphanKRData && orphanKRData?.currencyName ? orphanKRData?.currencyName : 'Dollar'
													)
												) : (
													<></>
												)}
												<Typography>{orphanKRData?.startValue}</Typography>
											</Box>
										</Box>

										<Box className='matrics-field-seprator' component='span'>
											{'/'}
										</Box>

										<Box className='target-value'>
											<FormLabel component='legend'>Target</FormLabel>
											<Box className='currency-icon-wrap'>
												{orphanKRData && orphanKRData?.metricName === 'Percentage' ? (
													<PercentageIcon />
												) : orphanKRData && orphanKRData?.metricName === 'Numbers' ? (
													<NumbersIcon />
												) : orphanKRData && orphanKRData?.metricName === 'Currency' ? (
													getCurrencyIcon(
														orphanKRData && orphanKRData?.currencyName ? orphanKRData?.currencyName : 'Dollar'
													)
												) : (
													<></>
												)}
												<Typography>{orphanKRData?.targetValue}</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
					{/* <Box className='decline-img-section'>
						<AnimatedIcon className='' width='358' type='image/svg+xml' alt='Decline' imagePath={DeclineImage} />
					</Box> */}
					{sourceContributor && sourceContributor?.krAssignerMessage && (
						<Box className='message-trail drawer-input-field'>
							<Typography className='message-trail-label'>Message</Typography>
							<List className='message-trail-first-label' component='ul'>
								<ListItem className='first-label-row'>
									<Box className='user-info'>
										<Box className='user-img'>
											{sourceContributor?.imagePath ? (
												<Avatar alt={getFullName(sourceContributor)} src={sourceContributor?.imagePath} />
											) : (
												<Avatar>{getUserName(sourceContributor)}</Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Typography variant='h4'>{getFullName(sourceContributor)}</Typography>
											<Box className='user-info-list'>
												<Typography variant='subtitle2'>{`${sourceContributor?.designation}`}</Typography>
												<Typography variant='subtitle2'>{`Received on ${getMonthDate(
													sourceContributor?.createdOnAssigner || new Date()
												)}`}</Typography>
											</Box>
										</Box>
									</Box>
									<Box className='user-message-description'>
										<Typography>{sourceContributor?.krAssignerMessage}</Typography>
									</Box>
								</ListItem>
							</List>
						</Box>
					)}

					<Box className='drawer-input-field'>
						<TextField
							className='kr-text-field'
							id={'decline-msg-field'}
							value={declineMessage}
							multiline
							rows={2}
							inputRef={callbackRef}
							label='Add Message'
							placeholder='Type your message...'
							InputProps={{ 'aria-label': 'Type your message...', disableUnderline: true }}
							onChange={(event: any) => setDeclineMessage(event?.target?.value)}
							fullWidth
						/>
					</Box>
				</form>
			</Box>
		</Box>
	);
};
