import React from 'react';

export const DownArrowIcon = () => (
	<svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.1515 1.80276C11.4503 1.5159 11.46 1.04113 11.1731 0.74232C10.8863 0.443512 10.4115 0.433823 10.1127 0.720679L11.1515 1.80276ZM6.13208 5.58172L5.61268 6.12276C5.9029 6.40137 6.36126 6.40137 6.65148 6.12276L6.13208 5.58172ZM2.15148 0.720678C1.85267 0.433822 1.3779 0.443511 1.09104 0.74232C0.804183 1.04113 0.813873 1.5159 1.11268 1.80276L2.15148 0.720678ZM10.1127 0.720679L5.61268 5.04068L6.65148 6.12276L11.1515 1.80276L10.1127 0.720679ZM6.65148 5.04068L2.15148 0.720678L1.11268 1.80276L5.61268 6.12276L6.65148 5.04068Z'
			fill='#292929'
		/>
	</svg>
);
export const UpArrowIcon = () => (
	<svg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1.14076 4.58884C0.841949 4.8757 0.832259 5.35047 1.11912 5.64928C1.40597 5.94809 1.88075 5.95778 2.17955 5.67092L1.14076 4.58884ZM6.16016 0.809884L6.67955 0.268844C6.38933 -0.00976896 5.93098 -0.00976896 5.64076 0.268844L6.16016 0.809884ZM10.1408 5.67092C10.4396 5.95778 10.9143 5.94809 11.2012 5.64928C11.4881 5.35047 11.4784 4.8757 11.1796 4.58884L10.1408 5.67092ZM2.17955 5.67092L6.67955 1.35092L5.64076 0.268844L1.14076 4.58884L2.17955 5.67092ZM5.64076 1.35092L10.1408 5.67092L11.1796 4.58884L6.67955 0.268844L5.64076 1.35092Z'
			fill='#292929'
		/>
	</svg>
);
export const RightArrowIcon = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6.77437 29.1441C6.21153 28.5362 6.24803 27.5872 6.85589 27.0244L19.8422 15L6.8559 2.97563C6.24803 2.41279 6.21153 1.46375 6.77437 0.855879C7.33721 0.248013 8.28625 0.21151 8.89412 0.774351L23.0691 13.8994C23.3757 14.1832 23.55 14.5821 23.55 15C23.55 15.4178 23.3757 15.8167 23.0691 16.1006L8.89412 29.2256C8.28625 29.7885 7.33721 29.752 6.77437 29.1441Z'
			fill='#292929'
		/>
	</svg>
);

export const TopArrowIcon = () => (
	<svg width='10' height='10' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M17.01 8.04005L31.54 21.8401C32.13 22.39 32.15 23.32 31.6 23.91C31.04 24.5 30.12 24.5201 29.53 23.9601L16 11.1201L2.47002 23.9601C1.88002 24.5201 0.96002 24.4901 0.400021 23.9101C-0.159981 23.3201 -0.129978 22.4001 0.450022 21.8401L14.99 8.04005C15.56 7.51005 16.44 7.51005 17.01 8.04005Z'
			fill='#292929'
		/>
	</svg>
);

export const LeftArrow = () => (
	<svg width='16' height='16' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M23.2256 0.855895C23.7885 1.46376 23.752 2.41281 23.1441 2.97565L10.1578 15L23.1441 27.0244C23.752 27.5872 23.7885 28.5363 23.2256 29.1441C22.6628 29.752 21.7137 29.7885 21.1059 29.2256L6.93088 16.1006C6.62428 15.8168 6.45 15.4179 6.45 15C6.45 14.5822 6.62428 14.1833 6.93088 13.8994L21.1059 0.774366C21.7137 0.211526 22.6628 0.248028 23.2256 0.855895Z'
			fill='#292929'
		/>
	</svg>
);

export const InfoArrow = () => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.04005 14.99L21.84 0.459979C22.39 -0.130021 23.32 -0.150021 23.91 0.399979C24.5 0.959979 24.52 1.87998 23.96 2.46998L11.12 16L23.96 29.53C24.52 30.12 24.49 31.04 23.91 31.6C23.32 32.16 22.4 32.13 21.84 31.55L8.04005 17.01C7.51005 16.44 7.51005 15.56 8.04005 14.99Z'
			fill='#292929'
		/>
	</svg>
);
export const DownFilledArrow = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M23.1153 9.52175C23.2986 9.84426 23.2946 10.2404 23.1047 10.559L15.8859 20.4262C15.7 20.7384 15.3634 20.9297 15 20.9297C14.6366 20.9297 14.3 20.7384 14.1141 20.4262L6.8953 10.559C6.70544 10.2404 6.70138 9.84426 6.88465 9.52175C7.06793 9.19923 7.4103 9 7.78125 9L22.2187 9C22.5897 9 22.9321 9.19923 23.1153 9.52175Z'
			fill='#292929'
		/>
	</svg>
);
export const ProgressUpArrow = () => (
	<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M0.253483 6.54139C-0.48516 7.87441 0.478879 9.51074 2.00287 9.51074H7.84511C9.40579 9.51074 10.3651 7.80286 9.55304 6.47008L6.49333 1.44847C5.69502 0.138279 3.77962 0.177782 3.03601 1.51977L0.253483 6.54139Z'
			fill='#39A3FA'
		/>
	</svg>
);
export const ProgressDownArrow = () => (
	<svg width='10' height='10' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M18.6038 25.4035C16.8899 27.9762 13.1101 27.9762 11.3962 25.4036L0.733711 9.3979C-1.18336 6.52016 0.879629 2.66699 4.33746 2.66699L25.6625 2.66699C29.1204 2.66699 31.1834 6.52015 29.2663 9.39789L18.6038 25.4035Z'
			fill='#292929'
		/>
	</svg>
);

export const SelectArrow = () => (
	<svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.7336 2.57211C11.1089 2.16693 11.0847 1.53423 10.6795 1.15894C10.2744 0.783638 9.64165 0.807863 9.26636 1.21304L10.7336 2.57211ZM5.83331 6.39104L5.09967 7.07057C5.28892 7.27489 5.55481 7.39104 5.83331 7.39104C6.11182 7.39104 6.3777 7.27489 6.56696 7.07057L5.83331 6.39104ZM2.40027 1.21304C2.02497 0.807863 1.39227 0.783638 0.987091 1.15893C0.581911 1.53423 0.557687 2.16693 0.932983 2.57211L2.40027 1.21304ZM9.26636 1.21304L5.09967 5.7115L6.56696 7.07057L10.7336 2.57211L9.26636 1.21304ZM6.56696 5.7115L2.40027 1.21304L0.932983 2.57211L5.09967 7.07057L6.56696 5.7115Z'
			fill='#292929'
		/>
	</svg>
);
export const RightArrow = () => (
	<svg width='20' height='20' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6.77437 29.1441C6.21153 28.5362 6.24803 27.5872 6.85589 27.0244L19.8422 15L6.8559 2.97563C6.24803 2.41279 6.21153 1.46375 6.77437 0.855879C7.33721 0.248013 8.28625 0.21151 8.89412 0.774351L23.0691 13.8994C23.3757 14.1832 23.55 14.5821 23.55 15C23.55 15.4178 23.3757 15.8167 23.0691 16.1006L8.89412 29.2256C8.28625 29.7885 7.33721 29.752 6.77437 29.1441Z'
			fill='#292929'
		/>
	</svg>
);
export const ForwardArrow = () => (
	<svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M53.4615 61.0769L75 39.5385M75 39.5385L53.4615 18M75 39.5385H5'
			stroke='#626262'
			stroke-width='8'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);
