import React, { useState } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UnlockLogo } from '../../../config/svg/CommonSvgIcon';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from '../../../config/svg/SocialIcon';
import moment from 'moment';

export const Footer: React.FC<any> = (props) => {
	const { t } = useTranslation();

	return (
		<Box className='whats-new-footer'>
			<Box className='footer-left'>
				<Box className='footer-logo'>
					<UnlockLogo />
				</Box>
				<Typography variant='body2'>&copy; {moment().year()} Infopro Learning, Inc. All rights reserved.</Typography>
			</Box>
			<Box className='footer-right'>
				<Typography variant='body2'>Follow Unlock:U</Typography>
				<Link href={'https://www.linkedin.com/company/unlock-u'} target='_blank'>
					<LinkedInIcon />
				</Link>
				<Link href={'https://www.facebook.com/UnlockUHQ/'} target='_blank'>
					<FacebookIcon />
				</Link>
				<Link href={'https://twitter.com/UnlockUHQ'} target='_blank'>
					<TwitterIcon />
				</Link>
			</Box>
		</Box>
	);
};
