import { postRequest, getRequest, putRequest } from '../config/auth';
import {
	GIVE_FEEDBACK,
	ASK_FEEDBACK,
	FETCH_FEEDBACK,
	FEEDBACK_1To1,
	COMMENT_FEEDBACK,
	REDIRECT_FEEDBACK,
	PERSONAL_FEEDBACK_OneOnOne,
	PERSONAL_FEEDBACK_ASK,
	PERSONAL_FEEDBACK_ASK_DETAIL,
	PERSONAL_FEEDBACK_FETCH_GIVING_FEEDBACK,
	PERSONAL_FEEDBACK_UPDATE_GIVING_FEEDBACK,
	PERSONAL_FEEDBACK_CRITERIA_MASTER,
	PERSONAL_FEEDBACK_REQUEST_AGAIN,
	PERSONAL_FEEDBACK_CANCEL_REQUEST,
	PERSONAL_FEEDBACK_VIEW,
	PERSONAL_FEEDBACK_DETAIL,
	PERSONAL_FEEDBACK_ACCEPT_REJECT,
} from '../config/api-url';

export const fetchFeedbackAPI = (data) => getRequest(`${FETCH_FEEDBACK}/${data}`, data);
export const giveFeedbackAPI = (data) => postRequest(`${GIVE_FEEDBACK}`, data);
export const askFeedbackAPI = (data) => postRequest(`${ASK_FEEDBACK}`, data);
export const feedback1To1API = (data) => postRequest(`${FEEDBACK_1To1}`, data);
export const commentFeedbackAPI = (data) => postRequest(`${COMMENT_FEEDBACK}`, data);
export const redirectFeedbackAPI = (data) => postRequest(`${REDIRECT_FEEDBACK}?${data}`, data);

/** Personal Feedback API Start Here */
export const requestOneToOnePersonalFeedbackAPI = (data) => postRequest(`${PERSONAL_FEEDBACK_OneOnOne}`, data);
export const askPersonalFeedbackAPI = (data) => postRequest(`${PERSONAL_FEEDBACK_ASK}`, data);
export const askDetailsPersonalFeedbackAPI = (data) => getRequest(`${PERSONAL_FEEDBACK_ASK_DETAIL}?${data}`, data);
export const giveDetailsPersonalFeedbackAPI = (data) =>
	getRequest(`${PERSONAL_FEEDBACK_FETCH_GIVING_FEEDBACK}?${data}`, data);
export const giveUpdatePersonalFeedbackAPI = (data) => postRequest(`${PERSONAL_FEEDBACK_UPDATE_GIVING_FEEDBACK}`, data);
export const getCriteriaMasterAPI = (data) => getRequest(`${PERSONAL_FEEDBACK_CRITERIA_MASTER}?${data}`, data);
export const requestAgainPersonalFeedbackAPI = (data) => getRequest(`${PERSONAL_FEEDBACK_REQUEST_AGAIN}?${data}`, data);
export const cancelRequestPersonalFeedbackAPI = (data) =>
	putRequest(`${PERSONAL_FEEDBACK_CANCEL_REQUEST}?${data}`, data);
export const viewPersonalFeedbackAPI = (data) => getRequest(`${PERSONAL_FEEDBACK_VIEW}?${data}`, data);
export const personalFeedbackDetailAPI = (data) => getRequest(`${PERSONAL_FEEDBACK_DETAIL}?${data}`, data);
export const personalFeedbackAcceptRejectAPI = (data) => putRequest(`${PERSONAL_FEEDBACK_ACCEPT_REJECT}`, data);
/** Personal Feedback API End Here */
