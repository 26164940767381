import React from 'react';
import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Popper,
	Typography,
	Tooltip,
} from '@material-ui/core';
import { getUserName } from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';

export const AssignedUserListPopover: React.FC<any> = (props) => {
	const { open, anchorEl, setOpen, setAnchorEl, poperContainer, t, assignedUserDetails, roleData } = props;
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};

	return (
		<Popper
			className={'assigned-user-popover'}
			open={open}
			anchorEl={anchorEl}
			transition
			placement={'bottom-start'}
			id='popoverList'
			container={poperContainer === true ? anchorEl : document.body}
		>
			<ClickAwayListener onClickAway={handleClose}>
				<Box className='assignedUser-details-inner'>
					<Box className='assignedUser-details-head'>
						<Typography variant='h4'>{t('assignedUsers')}</Typography>
					</Box>
					<MenuList className='assignedUser-details-list'>
						{assignedUserDetails &&
							assignedUserDetails.length > 0 &&
							assignedUserDetails.map((item: any, index: number) => (
								<MenuItem key={index} id={`assigned-user-${index}`}>
									<Box className='assignedUser-details-left'>
										<ListItemAvatar>
											<Avatar
												className='avatar-default'
												key={`user-icon-${item.employeeId}`}
												src={item.imagePath || ''}
											>
												{getUserName({
													firstName: item.firstName || '',
													lastName: item.lastName || '',
													fullName: item.label || '',
												})}
											</Avatar>
										</ListItemAvatar>
										<Box className='assignedUser-info'>
											<ListItemText
												className='contributor-name'
												primary={
													item?.firstName && item?.lastName && (item?.firstName + item?.lastName).length > 42 ? (
														<Tooltip title={`${item.firstName} ${item?.lastName}`} arrow>
															<Typography
																variant='h4'
																className='tooltip-text'
															>{`${item.firstName} ${item?.lastName}`}</Typography>
														</Tooltip>
													) : (
														<Typography variant='h4'>{`${item.firstName} ${item?.lastName}`}</Typography>
													)
												}
												secondary={
													item.designation.length > 42 ? (
														<Tooltip title={item?.designation} arrow>
															<Typography variant='subtitle2' className='secondary-text tooltip-text'>
																{item?.designation}
															</Typography>
														</Tooltip>
													) : (
														<Typography variant='subtitle2' className='secondary-text'>
															{item?.designation ? item?.designation : t('notAvailable')}
														</Typography>
													)
												}
											/>
										</Box>
									</Box>
									{/* <Box className='assignedUser-details-right'>
										<Chip className='chip' label={'Admin'} />
									</Box> */}
								</MenuItem>
							))}
					</MenuList>

					{roleData && roleData?.totalPages !== props.pageIndex && roleData?.roleId > 0 && (
						<Box className='alignCenter' display={'flex'} justifyContent={'center'}>
							<OKRButton
								id='load-more'
								className='btn-link'
								handleClick={props.loadMoreUser}
								text={'Load More...'}
							></OKRButton>
						</Box>
					)}
				</Box>
			</ClickAwayListener>
		</Popper>
	);
};
