import React, { Fragment, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Box, Slider, IconButton, Typography, Icon } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../../Common/Dialog';
import { DeleteIcon } from '../../../config/svg/CommonSvg';

const LogoEditor = (props) => {
	const { handleFileChange, orgData, showImageEditor, removeLogo, updateOrgDataImagePath } = props;
	const { imagePath } = orgData;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [modalOpen, setModalOpen] = useState(false);
	const [image, setImage] = useState(orgData.imagePath);
	const [imageName, setImageName] = useState('');
	const [sliderValue, setSliderValue] = React.useState(1);
	const [straightenValue, setStraightenValue] = useState(0);
	const [uploadingPic, setUploadingPic] = useState(false);
	const avatarEditorRef = useRef();

	const uploadImage = (event) => {
		const file = event.target.files[0];
		const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];
		try {
			if (file) {
				const extension = file.name.split('.').slice(-1).pop();
				const fSize = file.size;
				const fileSize = Math.round(fSize / 1024);
				if (fileSize > 1024) {
					enqueueSnackbar(t('OrgLogo'), { variant: 'error', autoHideDuration: 5000 });
					event.target.value = '';
				} else if (!allowedExtensions.includes(extension ? extension.toLowerCase() : '')) {
					enqueueSnackbar(t('OrgLogoType'), {
						variant: 'error',
						autoHideDuration: 5000,
					});
					event.target.value = '';
				} else {
					handleFileChange(event, file);
					setImageName(file.name);
					setImage(file);
					event.target.value = '';
				}
			} else {
				event.target.value = '';
			}
		} catch (err) {
			console.log(err);
		}
	};
	const handleSliderChange = (event, newValue) => {
		setSliderValue(newValue);
	};
	const handleStraightenSliderChange = (event, newValue) => {
		setStraightenValue(newValue);
	};
	const onRefreshIconClick = () => {
		setSliderValue(1);
		setStraightenValue(0);
	};
	const DataURIToBlob = (dataURI) => {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	};
	const onClickSave = () => {
		setUploadingPic(true);
		try {
			if (image && avatarEditorRef && avatarEditorRef.current) {
				const imageBase64 = avatarEditorRef.current.getImageScaledToCanvas().toDataURL(image.type || 'image/png');
				const file = DataURIToBlob(imageBase64);
				updateImage(file, imageBase64);
			} else {
				updateImage('', '');
			}
		} catch (err) {
			setUploadingPic(false);
		}
	};
	const updateImage = async (file, imageBase64) => {
		if (file && imageBase64) {
			const formData = new FormData();
			const name = imageName && !imageName.includes('svg') ? imageName : `${Math.random(0, 10)}.png`;
			formData.append('file', file, name);
			updateOrgDataImagePath(formData, imageBase64);
		} else {
			updateOrgDataImagePath('', '');
		}
		showImageEditor({}, false);
	};

	const onDeleteIconClick = (e) => {
		setImage(null);
		removeLogo(e);
		enqueueSnackbar(t('OrgLogoDelete'), {
			variant: 'success',
			autoHideDuration: 5000,
		});
	};
	const handleCloseModal = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			onDeleteIconClick(e);
		}
		setModalOpen(false);
	};

	return (
		<Fragment>
			<Box className='image-edit-area' style={{ backgroundColor: '#292929' }}>
				<Box className='upload-action'>
					<div className='upload-button' id='upload-btn'>
						<label htmlFor='file-input-for-editor' className='upload-image-label'>
							{t('uploadPhoto')}
						</label>
						<input
							className='upload-image-file-input'
							accept='image/x-png,image/jpeg,image/jpg,image/svg+xml'
							type='file'
							id='file-input-for-editor'
							onChange={uploadImage}
						/>
					</div>
					<Box>
						{Boolean(imagePath) ? (
							<IconButton size='small' className='deleteIcon' onClick={() => setModalOpen(true)}>
								<DeleteIcon />
							</IconButton>
						) : image ? (
							<IconButton
								size='small'
								className='deleteIcon'
								onClick={() => {
									if (image) {
										setImage(null);
										enqueueSnackbar(t('OrgProfileLogoDelete'), {
											variant: 'success',
											autoHideDuration: 5000,
										});
									}
								}}
							>
								<DeleteIcon />
							</IconButton>
						) : (
							<Icon size='small' className='deleteIcon'>
								<DeleteIcon />
							</Icon>
						)}
					</Box>
				</Box>
				<Box display='flex' alignItems='center' justifyContent='center'>
					<Box className='profile-avatar'>
						<AvatarEditor
							ref={avatarEditorRef}
							image={image}
							width={320}
							height={320}
							border={0}
							borderRadius={250}
							scale={sliderValue}
							rotate={straightenValue}
							disableBoundaryChecks={false}
							disableHiDPIScaling={false}
							crossOrigin={'anonymous'}
							className={image ? 'image-section-white-bg' : 'image-section'}
						/>
					</Box>
				</Box>
				<Box display='flex' alignItems='center' justifyContent='space-between' className='image-setting'>
					<Box width={'100%'} p={2}>
						<Typography>Zoom</Typography>
						<Slider
							step={0.1}
							min={-2}
							max={5}
							value={typeof sliderValue === 'number' ? sliderValue : 1}
							onChange={handleSliderChange}
							aria-labelledby='input-slider'
						/>
					</Box>
					<Box width={'100%'} p={2}>
						<Typography>Straighten</Typography>
						<Slider
							min={0}
							max={360}
							value={typeof straightenValue === 'number' ? straightenValue : 0}
							onChange={handleStraightenSliderChange}
							aria-labelledby='input-slider'
						/>
					</Box>
					<Box>
						<IconButton className='reset-btn' size='small' onClick={onRefreshIconClick} style={{ marginTop: 10 }}>
							<RefreshIcon />
						</IconButton>
					</Box>
				</Box>
			</Box>
			<Box className='image-edit-action'>
				<div className='okr-form-action'>
					<Button
						id='cancel-btn'
						className='cancel-btn btn-link'
						color='primary'
						onClick={() => showImageEditor({}, false)}
					>
						{t('cancelBtn')}
					</Button>
					<Button
						id='save-btn'
						className='submitBtn btn-primary'
						color='primary'
						onClick={onClickSave}
						disabled={uploadingPic}
					>
						{t('saveAndExitBtn')}
					</Button>
				</div>
			</Box>
			{modalOpen && (
				<AlertDialog
					module='delete'
					message={t('OrgLogoRemoveAlert')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};

export default LogoEditor;
