import {
	Dialog,
	TextField,
	DialogContent,
	DialogActions,
	DialogContentText,
	Typography,
	Button,
	Box,
} from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerTransitionSetting } from '../../../config/utils';
import { AdminAlertUnsavedIcon } from '../../../config/svg/CommonSvgIcon';
import { ErrorIcon } from '../../../config/svg/formElementIcons';

export const DialogComponent: React.FC<any> = (props: any) => {
	const {
		message,
		handleConfirmClick,
		matchString,
		handleCloseModal,
		modalOpen,
		module,
		isCancel,
		headingText,
		subHeading,
		dialogFor = '',
	} = props;
	const [userInputValue, setInputValue] = React.useState<string>('');
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = React.useState<any>('');

	React.useEffect(() => {
		return () => {
			setErrorMessage('');
			setInputValue('');
		};
	}, [modalOpen]);

	const handleUserConfirmation = () => {
		if (userInputValue.toLowerCase() === matchString.toLowerCase()) {
			handleConfirmClick();
		} else {
			setErrorMessage(`Incorrect input! Please type ${matchString}.`);
		}
	};

	const getButtonText = (type: any) => {
		if (dialogFor === 'unlockUsers') {
			return type === 1 ? t('unlockLabel') : t('cancelBtn');
		} else if (module === 'information') {
			return type === 1 ? t('cancelBtn') : t('yesLabel');
		} else if (module === 'confirmation') {
			return type === 1 ? t('delete') : t('cancelBtn');
		}
	};

	return (
		<React.Fragment>
			<Dialog
				className={`default-dialog ${module === 'confirmation' ? 'delete-dialog-box' : ''}`}
				open={modalOpen}
				transitionDuration={DrawerTransitionSetting}
				onClose={handleCloseModal}
			>
				<DialogContent>
					<AdminAlertUnsavedIcon />
					{module === 'information' ? (
						<DialogContentText
							id='alert-dialog-slide-description'
							dangerouslySetInnerHTML={{ __html: message }}
						></DialogContentText>
					) : (
						<React.Fragment>
							<Box className='delete-dialog-inner'>
								<Box className='delete-confirm-text'>
									<Typography>{headingText}</Typography>
									<Typography>{subHeading}</Typography>
								</Box>
								<Box className='delete-confirm-text-field'>
									<Typography>
										{t('toConfirmEnter')}
										<strong> {matchString}!</strong>
									</Typography>

									<TextField
										value={userInputValue}
										name='deleteInput'
										error={errorMessage}
										helperText={
											errorMessage && (
												<React.Fragment>
													<ErrorIcon />
													{errorMessage}
												</React.Fragment>
											)
										}
										onChange={(e) => {
											setInputValue(e.target.value);
											setErrorMessage('');
										}}
										className='helperText-bottom'
									/>
								</Box>
							</Box>
						</React.Fragment>
					)}
				</DialogContent>
				<DialogActions>
					{!isCancel ? (
						<React.Fragment>
							{module === 'confirmation' ? (
								<React.Fragment>
									<Button className='btn-link' onClick={handleUserConfirmation}>
										{getButtonText(1)}
									</Button>
									<Button className='btn-primary' onClick={(e) => handleCloseModal(e, 1)}>
										{getButtonText(2)}
									</Button>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Button className='btn-link' onClick={(e) => handleCloseModal(e, 2)}>
										{getButtonText(1)}
									</Button>
									<Button className='btn-primary' onClick={(e) => handleCloseModal(e, 1)}>
										{getButtonText(2)}
									</Button>
								</React.Fragment>
							)}
						</React.Fragment>
					) : (
						<Button className='btn-primary' onClick={(e) => handleCloseModal(e, 2)}>
							{t('okLabel')}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
