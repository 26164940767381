import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	Grow,
	Link,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Slider,
	Avatar,
	Typography,
	Tooltip,
} from '@material-ui/core';
import {
	getFullName,
	getUserName,
	getRandomBackgroundColor,
	getRandomColor,
	getOkrProgressDetails,
	dateDifferenceInDays,
} from '../../config/utils';
import { Enums } from '../../config/enums';
import { OKRButton } from '../Common/OKRButton';
import { DownFilledArrow, ProgressUpArrow } from '../../config/svg/ArrowSvg';
import { CancelIcon } from '../../config/svg/Action';
import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { USER_VIEW, HOME } from '../../config/app-url';
import { TeamsPopperMenu } from './TeamsOkr/TeamsPopperMenu';
import AlertDialog from '../Common/Dialog';
import { OkrCardDetails } from './OkrCardDetails';
import ProgressLabel from '../Common/ProgressLabel';
import { useSnackbar } from 'notistack';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import WelcomeImage from '../../images/my-goal-bg.svg';
import clone from 'clone';
import _ from 'lodash';

export const OkrCard: React.FC<any> = (props) => {
	const {
		getMyTeamDetails,
		userDetail,
		year,
		showApiMsgs,
		t,
		currentUser,
		updateNudgeTeams,
		getMyTeamOKRDetails,
		fetchOkr,
		openTeamDetails,
		saveActionType,
		setSaveActionType,
		alignmentFilterData,
		tabValue,
		cardDashboard,
		setCardDashboard,
	} = props;
	const [teamDetails, setTeamDetails] = useState<any>(null);
	const [teamsOKR, setTeamsOKR] = useState<any>(null);
	const [modalProps, setModalProps] = useState({
		open: false,
		type: '',
		message: '',
		module: '',
		details: { teamId: 0, teamName: '' },
		actionType: '',
	});
	const [openTeamMenu, setOpenTeamMenu] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [teamsOKRDetails, setTeamsOKRDetails] = useState<any>(null);
	const [showNudgeBar, setShowNudgeBar] = useState<any>(false);
	const [showTeamsBar, setShowTeamsBar] = useState<any>(false);
	const [apiLoading, setApiLoading] = useState<boolean>(false);
	const [originalGoalList, setOriginalGoalList] = useState<any>([]); //== for filter
	const [isProgressUpdate, setIsProgressUpdate] = useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const selectedYear = getLocalStorageItem('year') || year;
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const [prevCycleMsg, setPrevCycleMsg] = useState<boolean>(false);

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenTeamMenu(anchorEl ? false : true);
	};

	const handleClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setOpenTeamMenu(false);
	};
	useEffect(() => {
		if (currentCycleId === cycleId) {
			setPrevCycleMsg(false);
		} else if (currentCycleId !== cycleId) {
			setPrevCycleMsg(true);
		}
	}, [currentCycleId, cycleId]);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		getTeamDetails();
		if (openTeamDetails) {
			viewSelectedTeamsOkr('', openTeamDetails, '');
		}
	}, []);
	useEffect(() => {
		const notificationRedirection = JSON.parse(getLocalStorageItem('notificationRedirection') || '{}');
		if (
			notificationRedirection &&
			notificationRedirection.tabToOpen &&
			notificationRedirection.tabToOpen === 'TEAMSOKR'
		) {
			getTeamDetails();
		}
	}, [props.location]);

	// based on saveActionType teamokrdetail reloaded
	// [todo] add check of type e.g. teams tab / all tab
	useEffect(() => {
		let teamData: any = JSON.parse(getLocalStorageItem('teamId') || '{}');
		let crSrTeam: any = getSelectedTeam(teamDetails || []);
		if (crSrTeam !== null) {
			teamData = crSrTeam;
		}
		if ((saveActionType === Enums.ONE || saveActionType === Enums.TWO) && teamData && teamData.teamId) {
			getMyTeamOKR(teamData);
		}
	}, [saveActionType]);

	useEffect(() => {
		if (tabValue === 1) {
			if (alignmentFilterData && alignmentFilterData.length > 0) {
				const filteredArray = originalGoalList.myGoalOkrResponses.filter((item: any) => {
					const scoreArray = alignmentFilterData;
					const checkedProgress = scoreArray?.filter((it: any) => it.isChecked);
					if (checkedProgress.length > 0) {
						let scoreObject = checkedProgress.find((scoreItem: any) => {
							if (scoreItem.type === 'progress') {
								return item.okrProgress === scoreItem.code;
							}
						});
						if (typeof scoreObject === 'undefined') {
							return false;
						}
					}
					return true;
				});
				setTeamsOKRDetails({
					...originalGoalList,
					// ...teamsOKRDetails,
					myGoalOkrResponses: filteredArray,
				});
			} else {
				let updatedValue =
					originalGoalList && originalGoalList.myGoalOkrResponses ? setRecentData(originalGoalList) : originalGoalList;
				setTeamsOKRDetails(updatedValue);
			}
		}
	}, [alignmentFilterData, originalGoalList]);

	// handle card click and filter okrs
	useEffect(() => {
		if (
			!_.isEmpty(cardDashboard) &&
			originalGoalList &&
			originalGoalList.myGoalOkrResponses &&
			originalGoalList.myGoalOkrResponses.length
		) {
			let isFilter = 0;
			const filteredArray = originalGoalList?.myGoalOkrResponses.filter((item: any) => {
				const filterObj = cardDashboard;
				for (const property in filterObj) {
					isFilter += filterObj[property] ? 1 : 0;
					if (filterObj[property] && property === item.okrProgress) {
						return item;
					}
				}
			});
			setTeamsOKRDetails(
				isFilter === 0
					? { ...originalGoalList, myGoalOkrResponses: originalGoalList.myGoalOkrResponses }
					: { ...originalGoalList, myGoalOkrResponses: filteredArray }
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardDashboard]);

	const getSelectedTeam = (data: any) => {
		const currentSearchedTeamId = getLocalStorageItem('currentSearchedTeamId');
		let crSrTeam: any = null;
		if (data.length > 0 && currentSearchedTeamId && currentSearchedTeamId !== '') {
			setLocalStorageItem('currentSearchedTeamId', '');
			const currentSearchedTeam = JSON.parse(getLocalStorageItem('currentSearchedTeamData') || '{}');
			setLocalStorageItem('currentSearchedTeamData', '{}');
			data.forEach((teamD: any) => {
				if (teamD.teamId === currentSearchedTeamId) {
					crSrTeam = teamD;
				}
			});
			if (crSrTeam === null) {
				let selectedTeamDetail = Object.assign({}, currentSearchedTeam);
				selectedTeamDetail.teamName = currentSearchedTeam.organisationName;
				selectedTeamDetail.teamId = currentSearchedTeam.organisationId;
				selectedTeamDetail.teamLogo = currentSearchedTeam.imagePath;
				selectedTeamDetail.leaderName = `${currentSearchedTeam.firstName} ${currentSearchedTeam.lastName}`;
				selectedTeamDetail.teamFound = 'no';
				return selectedTeamDetail;
			} else {
				crSrTeam.teamFound = 'yes';
			}
		}
		return crSrTeam;
	};
	const getTeamDetails = () => {
		props.showLoader(true);
		getMyTeamDetails(`empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`)
			.then((response: any) => {
				props.showLoader(false);
				if (
					response?.data &&
					response?.data?.status === Enums.STATUS_SUCCESS &&
					response?.data?.entityList &&
					response?.data?.entityList.length > 0
				) {
					let crSrTeam: any = getSelectedTeam(response?.data?.entityList || []);
					response?.data?.entityList?.sort((a: any, b: any) => a.teamName.localeCompare(b.teamName));
					const { entityList } = response?.data;
					setLocalStorageItem('teamId', JSON.stringify(entityList));
					setTeamDetails(entityList);
					const teamData: any = JSON.parse(getLocalStorageItem('teamId') || '{}');

					// load teams details first if redirected from Notification
					const notificationRedirection = JSON.parse(getLocalStorageItem('notificationRedirection') || '{}');
					if (
						notificationRedirection &&
						notificationRedirection.tabToOpen &&
						notificationRedirection.tabToOpen === 'TEAMSOKR'
					) {
						const itemArray = notificationRedirection.data && notificationRedirection.data.split('/');
						const notificationTeamId = itemArray[1] ? parseInt(itemArray[1].trim()) : 0;
						const selectedTeamDetail = entityList.filter((teamD: any) => {
							return teamD.teamId === notificationTeamId;
						});

						if (selectedTeamDetail && selectedTeamDetail.length > 0) {
							viewSelectedTeamsOkr('', selectedTeamDetail[0], selectedTeamDetail[0]?.teamName);
							const copy = [...entityList];
							const index = entityList.findIndex((item: any) => item.teamId === selectedTeamDetail[0].teamId);
							const selected = entityList[index];
							copy.splice(index, 1);
							copy.unshift(selected);
							setTeamDetails(copy);
						} else {
							viewSelectedTeamsOkr('', entityList[0], entityList[0]?.teamName);
						}
					} else if (crSrTeam !== null) {
						viewSelectedTeamsOkr('', crSrTeam, crSrTeam?.teamName);
					} else {
						if (teamData?.teamId !== undefined) {
							viewSelectedTeamsOkr('', teamData, teamData?.teamName);
						} else {
							viewSelectedTeamsOkr('', entityList[0], entityList[0]?.teamName);
						}
					}
				} else {
					setTeamDetails([]);
				}
			})
			.catch((_error: any) => {
				showApiMsgs('There is no Team OKR for the particular user.', 'error');
				setTeamDetails([]);
				props.showLoader(false);
			});
	};

	const setRecentData = (list: any) => {
		let recentL = Object.assign([], list);
		let recentData = JSON.parse(getLocalStorageItem('recentContributorData') || '{}');
		if (getLocalStorageItem('recentCalled') === 'true' && recentData) {
			let recentContributorList: any = {};
			recentData.forEach((element: any) => {
				recentContributorList[element.goalKeyId] = element;
			});
			recentL?.myGoalOkrResponses.forEach((element: any) => {
				if (element.myGoalsDetails && element.myGoalsDetails.length > 0) {
					element.myGoalsDetails.forEach((krElement: any) => {
						if (recentContributorList[krElement.goalKeyId]) {
							krElement.hasRecent = recentContributorList[krElement.goalKeyId].recentContribution !== 0;
							krElement.recentContributorData = recentContributorList[krElement.goalKeyId];
						}
					});
				}
			});
		}
		return recentL;
	};

	useEffect(() => {
		if (props.listOkrSuccess === 'success' && tabValue === 1) {
			let teamData: any = JSON.parse(getLocalStorageItem('teamId') || '{}');
			getMyTeamOKR(teamData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.listOkrSuccess]);

	const getMyTeamOKR = (teamDetails: any) => {
		const { teamId } = teamDetails;
		if (teamId && !apiLoading) {
			props.showLoader(true);
			setApiLoading(true);
			getMyTeamOKRDetails(`teamId=${teamId}&empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`)
				.then((response: any) => {
					props.showLoader(false);
					if (!teamDetails || isProgressUpdate) {
						updateTeamData();
					}
					const { data } = response;

					//Keep an original copy for filter
					let originalTeamGoalList = clone(response?.data.entity);
					const confidenceOkr = getLocalStorageItem('confidenceOkr')
					originalTeamGoalList.myGoalOkrResponses.map((item: any) => {
						let { okrProgress } = getOkrProgressDetails({
							value: item.score,
							dueDate: item.dueDate,
						});
						if (confidenceOkr && parseInt(confidenceOkr) === item.goalObjectiveId) {
							item.expand = true;
						}
						item.okrProgress = okrProgress;
						return item;
					});
					data?.entity.myGoalOkrResponses.map((item: any) => {
						if (confidenceOkr && parseInt(confidenceOkr) === item.goalObjectiveId) {
							item.expand = true;
						}
						return item;
					});
					removeLocalStorageItem('confidenceOkr');
					setTeamsOKR(teamDetails);
					setOriginalGoalList(setRecentData(originalTeamGoalList));

					props.getTeamsOkrProgress(
						`teamId=${teamId}&empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`
					);

					if (data && data?.status === Enums.STATUS_SUCCESS && data?.entity) {
						setTeamsOKRDetails(setRecentData(data?.entity));
					} else {
						const { messageList, entity } = response?.data;
						if (messageList) {
							const keys = Object.keys(messageList);
							const messages = keys.map((item) => messageList[item]);
							showApiMsgs(messages, 'error');
						}
						setTeamsOKRDetails(entity);
					}
					setShowNudgeBar(true);
					setShowTeamsBar(true);
					if (saveActionType) {
						setSaveActionType(null);
					}
					if (teamDetails.teamFound) {
						if (teamDetails.teamFound === 'yes') {
							enqueueSnackbar(t('alreadyAlignedToTeam'), {
								variant: 'success',
								autoHideDuration: 5000,
							});
						} else if (teamDetails.teamFound === 'no') {
							if (data?.entity?.myGoalOkrResponses?.length > 0) {
								enqueueSnackbar(t('alreadyAlignedToTeam'), {
									variant: 'success',
									autoHideDuration: 5000,
								});
							} else {
								enqueueSnackbar(t('notAlignedToTeam'), {
									variant: 'error',
									autoHideDuration: 5000,
								});
							}
						}
						delete teamDetails.teamFound;
						setLocalStorageItem('teamId', JSON.stringify(teamDetails));
					}
					setApiLoading(false);
				})
				.catch((_error: any) => {
					props.showLoader(false);
					setApiLoading(false);
					showApiMsgs('There is no Okr for the particular Team.', 'error');
				});
		}
	};

	const updateTeamData = () => {
		props.showLoader(true);
		getMyTeamDetails(`empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`)
			.then((response: any) => {
				if (
					response?.data &&
					response?.data?.status === Enums.STATUS_SUCCESS &&
					response?.data?.entityList &&
					response?.data?.entityList.length > 0
				) {
					response?.data?.entityList?.sort((a: any, b: any) => a.teamName.localeCompare(b.teamName));
					const { entityList } = response?.data;
					if (isProgressUpdate) {
						const teamData: any = JSON.parse(getLocalStorageItem('teamId') || '{}');
						if (entityList && entityList.length) {
							const copy = [...entityList];
							// const index = entityList.indexOf(teamData);
							const index = copy.findIndex((item: any) => item.teamId === teamData.teamId);
							const selected = entityList[index];
							copy.splice(index, 1);
							copy.unshift(selected);
							setTeamDetails(copy);
						}
						setIsProgressUpdate(false);
					} else {
						setTeamDetails(entityList);
					}
				} else {
					setTeamDetails([]);
				}
				props.showLoader(false);
			})
			.catch((_error: any) => {
				showApiMsgs('There is no Team OKR for the particular user.', 'error');
				setTeamDetails([]);
				props.showLoader(false);
			});
	};

	const getCardColor = (progress: any) => {
		let colorClass = 'grey';
		if (progress) {
			if (progress?.toLowerCase() === 'ffc200') {
				colorClass = 'yellow';
			} else if (progress?.toLowerCase() === 'ff2200') {
				colorClass = 'red';
			} else if (progress?.toLowerCase() === '60a917') {
				colorClass = 'green';
			}
		}
		return colorClass;
	};

	const redirectToPeoplePage = (event: any, team: any) => {
		event.preventDefault();
		event.stopPropagation();
		const { employeeId } = userDetail;
		if (employeeId !== team?.leaderDetails?.employeeId) {
			setLocalStorageItem('currentUser', JSON.stringify(team?.leaderDetails || userDetail));
			// setting redirect url for dashboard teamsOkr tab
			setLocalStorageItem(
				'backUrl',
				JSON.stringify({
					url: HOME,
					tabToOpen: 'TEAMSOKR',
				})
			);
			props.resetOkr();
			props.history.push(USER_VIEW);
			props.updateRoute(USER_VIEW);
		}
	};
	const viewSelectedTeamsOkr = (event: any, selectedTeamDetails: any, teamName: string) => {
		setCardDashboard({});
		if (event) {
			event.preventDefault();
			event.stopPropagation();
			setAnchorEl(null);
			setOpenTeamMenu(false);
		}
		if (teamName === 'All') {
			// View All Teams
			setTeamsOKR(null);
			localStorage.removeItem('teamId');
			getTeamDetails();
		} else {
			// viewTeams OKR
			getMyTeamOKR(selectedTeamDetails);
			localStorage.setItem('teamId', JSON.stringify(selectedTeamDetails));
		}

		if (teamDetails && teamDetails.length) {
			setTeamDetailsSequence(selectedTeamDetails);
		}
	};

	const setTeamDetailsSequence = (selectedTeamDetails: any) => {
		const copy = [...teamDetails];
		const index = teamDetails.findIndex((item: any) => item.teamId === selectedTeamDetails.teamId);
		const selected = teamDetails[index];
		copy.splice(index, 1);
		copy.unshift(selected);
		setTeamDetails(copy);
	};
	const handleMenuAction = (menuName: string, selectedTeamDetails: any, type: string) => {
		if (menuName === 'viewOKR') {
			// viewTeams OKR
			getMyTeamOKR(selectedTeamDetails);
			localStorage.setItem('teamId', JSON.stringify(selectedTeamDetails));
		} else if (menuName === 'nudgeTeam') {
			localStorage.setItem('teamId', JSON.stringify(selectedTeamDetails));
			const { teamId, teamName } = selectedTeamDetails;
			const apiRequest = {
				teamName: teamName || '',
				teamId: teamId || 0,
				cycle: cycleId || 0,
				year: year || 0,
			};
			updateNudgeTeams(apiRequest)
				.then((response: any) => {
					if (response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
						const { messageList } = response?.data;
						if (messageList) {
							const keys = Object.keys(messageList);
							const messages = keys.map((item) => messageList[item]);
							showApiMsgs(messages, 'success');
						}
						if (modalProps?.actionType === 'teamsOKR') {
							getMyTeamOKR(modalProps?.details);
							localStorage.setItem('teamId', JSON.stringify(modalProps?.details));
						}
					} else {
						const { messageList } = response?.data;
						if (messageList) {
							const keys = Object.keys(messageList);
							const messages = keys.map((item) => messageList[item]);
							showApiMsgs(messages, 'error');
						}
					}
				})
				.catch((_error: any) => {
					showApiMsgs(t('internalServerError'), 'error');
				});
		}
	};
	const handleCloseModal = async (event: any, type: Number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			const { teamId, teamName } = modalProps?.details;
		}
		setModalProps({
			open: false,
			type: '',
			message: '',
			module: '',
			details: { teamId: 0, teamName: '' },
			actionType: '',
		});
	};
	const getAttentionTeamsCount = () => {
		let filterData = [];
		if (teamsOKR && teamsOKRDetails) {
			filterData =
				teamsOKRDetails.myGoalOkrResponses && teamsOKRDetails.myGoalOkrResponses.length > 0
					? teamsOKRDetails.myGoalOkrResponses.filter(
							(item: any) => item.progress === Enums.ONE || item.progress === Enums.TWO
					  )
					: 0;
		} else {
			filterData =
				teamDetails && teamDetails?.length > 0
					? teamDetails.filter(
							(item: any) => item?.keyCount > 0 && (item.progress === Enums.ONE || item.progress === Enums.TWO) && item
					  )
					: 0;
		}

		const getCount = (filterData && filterData.length) || 0;
		if (getCount === 0) {
			setShowNudgeBar(false);
		}
		return getCount;
	};

	const isAnyOkrCreatedBefore = (daysDifference: number = 6) => {
		const today = new Date();
		const diff =
			teamsOKRDetails &&
			teamsOKRDetails.myGoalOkrResponses.filter(
				(item: any) => dateDifferenceInDays(item.createdOn, today) > daysDifference
			);
		return diff.length;
	};

	useEffect(() => {
		if (isProgressUpdate) {
			updateTeamData();
		}
	}, [isProgressUpdate]);

	return (
		<Fragment>
			<Box className='okr-tabpanel-actions pad-container'>
				<Box className='teams-filter'>
					<Box className='teams-filter-btns'>
						{showTeamsBar && teamDetails && teamDetails?.length >= 2 ? (
							[0, 1].map((team: any) =>
								teamDetails[team].teamName && teamDetails[team].teamName.length > 12 ? (
									<Tooltip title={teamDetails[team].teamName} arrow>
										<OKRButton
											key={teamDetails[team]?.teamId}
											id={`team-name-${teamDetails[team]?.teamId}`}
											className={`team-btn ${teamsOKR?.teamId === teamDetails[team]?.teamId ? 'active' : ''}`}
											text={teamDetails[team].teamName.substring(0, 12) + '...'}
											handleClick={(event: any) =>
												viewSelectedTeamsOkr(event, teamDetails[team], teamDetails[team]?.teamName)
											}
											percentage={teamDetails[team]}
											style={{
												backgroundColor: getRandomBackgroundColor(teamDetails[team]?.backGroundColorCode),
												color: getRandomColor(teamDetails[team]?.colorCode),
											}}
										/>
									</Tooltip>
								) : (
									<OKRButton
										key={teamDetails[team]?.teamId}
										id={`team-name-${teamDetails[team]?.teamId}`}
										className={`team-btn ${teamsOKR?.teamId === teamDetails[team]?.teamId ? 'active' : ''}`}
										text={teamDetails[team].teamName}
										handleClick={(event: any) =>
											viewSelectedTeamsOkr(event, teamDetails[team], teamDetails[team]?.teamName)
										}
										percentage={teamDetails[team]}
										style={{
											backgroundColor: getRandomBackgroundColor(teamDetails[team]?.backGroundColorCode),
											color: getRandomColor(teamDetails[team]?.colorCode),
										}}
									/>
								)
							)
						) : (
							<></>
						)}
					</Box>
					{showTeamsBar && teamDetails && teamDetails?.length > 2 && (
						<Fragment>
							<Box className='teams-filter-dropdown'>
								<OKRButton title={t('moreTeams')} icon={<DownFilledArrow />} handleClick={handleToggle} />
							</Box>
							<Popper
								className='popper-menu-list team-filter-list-menu'
								open={openTeamMenu}
								anchorEl={anchorEl}
								placement='bottom-start'
								transition
							>
								{({ TransitionProps }) => (
									<Grow {...TransitionProps}>
										<Paper>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList id='menu-list-grow'>
													{teamDetails.slice(2, teamDetails.length).map((team: any) => (
														<MenuItem
															key={team?.teamId}
															id={`team-name-${team?.teamId}`}
															onClick={(event: any) => viewSelectedTeamsOkr(event, team, team?.teamName)}
														>
															<ListItemAvatar>
																{team?.teamLogo ? (
																	<Avatar className='team-avatar-small' alt={`${team.teamName}`} src={team?.teamLogo} />
																) : (
																	<Avatar
																		className='team-avatar-small'
																		style={{
																			backgroundColor: getRandomBackgroundColor(team.backGroundColorCode),
																			color: getRandomColor(team.colorCode),
																		}}
																	>
																		{getUserName({ firstName: '', lastName: '', fullName: team.teamName })}
																	</Avatar>
																)}
															</ListItemAvatar>
															<ListItemText
																primary={
																	<Typography variant='h5' className='team-filter-txt'>
																		{team.teamName && team.teamName.length > 12 ? (
																			<Tooltip arrow title={team?.teamName} aria-label='Teams'>
																				<Typography>{team?.teamName.substring(0, 12)}...</Typography>
																			</Tooltip>
																		) : (
																			<Typography>{team.teamName}</Typography>
																		)}
																	</Typography>
																}
															/>

															<ProgressLabel percentage={team} />
														</MenuItem>
													))}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Fragment>
					)}
				</Box>
				{showNudgeBar && (getAttentionTeamsCount() > 0 || (teamsOKR && teamsOKR.teamScore < 1)) ? (
					<Box className='nudge-message-box'>
						{teamsOKRDetails?.myGoalOkrResponses.length > 0 && teamsOKR && teamsOKR.teamScore < 1 ? (
							isAnyOkrCreatedBefore(6) ? (
								<Box className='nudge-bar'>
									<Typography variant='body2'>
										{`No progress in last 7 days. Take the next few mins to update progress.`}
									</Typography>
									<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={() => setShowNudgeBar(false)} />
								</Box>
							) : (
								<></>
							)
						) : (
							teamDetails &&
							teamDetails?.length > 0 && (
								<Box className='nudge-bar'>
									<Typography variant='body2'>
										{`${getAttentionTeamsCount()} items need your attention. Take the next few mins to update progress.`}
									</Typography>
									<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={() => setShowNudgeBar(false)} />
								</Box>
							)
						)}
					</Box>
				) : (
					''
				)}
			</Box>
			{teamsOKR ? (
				teamsOKRDetails && teamsOKRDetails?.myGoalOkrResponses ? (
					<OkrCardDetails
						{...props}
						teamsOKRDetails={teamsOKRDetails}
						setTeamsOKRDetails={setTeamsOKRDetails}
						teamsOKR={teamsOKR}
						getCardColor={getCardColor}
						getRandomBackgroundColor={getRandomBackgroundColor}
						getRandomColor={getRandomColor}
						userDetail={userDetail}
						handleMenuAction={handleMenuAction}
						getMyTeamOKR={getMyTeamOKR}
						fetchOkr={fetchOkr}
						goalDetail={props.goalDetail}
						showAddGoalForm={props.showAddGoalForm}
						setGoalDetail={props.setGoalDetail}
						setIsGoalCreated={props.setIsGoalCreated}
						saveAndEditObjectiveAndKr={props.saveAndEditObjectiveAndKr}
						setFormSubmit={props.setFormSubmit}
						formSubmit={props.formSubmit}
						isGoalCreated={props.isGoalCreated}
						showApiMsgs={showApiMsgs}
						isTabChanged={props.isTabChanged}
						setIsTabChanged={props.setIsTabChanged}
						addObjectiveCallback={props.addObjectiveCallback}
						openTeamDetails={openTeamDetails}
						setIsProgressUpdate={setIsProgressUpdate}
						cardDashboard={cardDashboard}
					/>
				) : teamsOKRDetails && teamsOKRDetails.length === 0 ? (
					<Box className='pad-container'>No Teams Available</Box>
				) : (
					<Box></Box>
				)
			) : (
				<Box hidden className='okr-card-main pad-container'>
					{showTeamsBar && teamDetails && teamDetails?.length > 0 ? (
						teamDetails.map((team: any, index: number) => (
							<Box
								key={index}
								className='okr-tabpanel-card'
								style={{ cursor: 'pointer' }}
								onClick={(event: any) => viewSelectedTeamsOkr(event, team, team?.teamName)}
							>
								<Box className='okr-tabpanel-card-top'>
									<Box className='user-info user-team-info'>
										<Box className='user-img'>
											{team?.teamLogo ? (
												<Avatar className='team-avatar-small' alt={team.teamName} src={team?.teamLogo} />
											) : (
												<Avatar
													className='team-avatar-small'
													style={{
														backgroundColor: getRandomBackgroundColor(team.backGroundColorCode),
														color: getRandomColor(team.colorCode),
													}}
												>
													{getUserName({ firstName: '', lastName: '', fullName: team.teamName })}
												</Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Typography variant='h4'>{team.teamName}</Typography>
											<Typography variant='subtitle2'>
												{`Leader - `}
												<Link
													href='#'
													onClick={(event: any) => {
														event.preventDefault();
														event.stopPropagation();
														redirectToPeoplePage(event, team);
													}}
												>
													{getFullName({
														firstName: team?.leaderDetails?.firstName,
														lastName: team?.leaderDetails?.lastName,
													})}
												</Link>
											</Typography>
										</Box>
									</Box>
									<Box className='okr-tabpanel-card-actions'>
										<TeamsPopperMenu
											team={team}
											t={t}
											currentUser={currentUser}
											{...props}
											handleMenuAction={handleMenuAction}
											type={'teams'}
										/>
									</Box>
								</Box>
								<Box className='okr-tabpanel-card-bottom'>
									<Box className='user-progress-text'>
										{team?.keyCount && team?.keyCount > 0 ? (
											team?.isDeltaVisible &&
											(team?.lastSevenDaysProgress > 0 ? (
												<Typography variant='body2' className='progress-started'>
													<ProgressUpArrow />
													{`${team?.lastSevenDaysProgress || 0}% ${t('progressLastSevenDays')}`}
												</Typography>
											) : (
												<Typography variant='body2' className='progress-not-started'>
													0% {t('progressLastSevenDays')}
												</Typography>
											))
										) : (
											<Typography variant='body2'>{`OKRs not yet created`}</Typography>
										)}
										<Typography variant='h2'>
											{`${team?.keyCount && team?.keyCount > 0 ? team?.teamScore || 0 : 0}%`}
										</Typography>
									</Box>
									<Box className='user-progress-bar'>
										<Slider
											className={`linear-slider ${getCardColor(team?.progressCode)}`}
											value={team?.keyCount && team?.keyCount > 0 ? team?.teamScore || 0 : 0}
											aria-label=''
											valueLabelDisplay='off'
											marks={false}
											disabled
										/>
									</Box>
								</Box>
							</Box>
						))
					) : teamDetails && teamDetails.length === 0 ? (
						<Box>No Teams Available</Box>
					) : (
						<Box></Box>
					)}
				</Box>
			)}

			{teamDetails && teamDetails.length < 1 && (
				<Box textAlign='center' className='pad-container'>
					<AnimatedIcon className='' width='516' type='image/svg+xml' alt='WelcomeImage' imagePath={WelcomeImage} />
					{prevCycleMsg ? (
						<>
							<Typography variant='h2'>{t('NoRecord')}</Typography>
							<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
						</>
					) : (
						<>
							<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
							<Typography>{t('myGoalNoDataMsg2')}</Typography>
						</>
					)}
				</Box>
			)}

			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
};
