import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@material-ui/core';
import { getMonthDate } from '../../../config/utils';

export const ReportsLineChart = (props) => {
	const {
		reportsData,
		showLegend,
		maxValueRecord,
		labels,
		checkedTeam,
		isEngagement = false,
		teamReportEngagement = false,
		isTeamReport,
		// ========
		toolTipPostfix = '',
		colors,
		titleText,
		xAxisText,
		yAxisText,
	} = props;
	const { t } = useTranslation();
	const [chartDetails, setChartDetails] = useState(null);

	useEffect(() => {
		if (reportsData && reportsData.myProgressDetails) {
			const lineChartData = reportsData;
			delete lineChartData.myProgressOverall;
			const objKeys = Object.keys(lineChartData);
			let categoriesData = [];
			const seriesOneData = [];
			const seriesTwoData = [];
			const seriesThreeData = [];
			const orgColor = colors[2]; // teamReportEngagement ? colors[1] : colors[2];
			const teamColor = !isTeamReport
				? checkedTeam && checkedTeam.length > 0
					? checkedTeam[0].backGroundColorCode
						? checkedTeam[0].backGroundColorCode
						: colors[1]
					: teamReportEngagement
					? colors[1]
					: colors[1]
				: colors[1];
			const myColor = !isTeamReport
				? colors[0]
				: checkedTeam && checkedTeam.length > 0
				? checkedTeam[0].backGroundColorCode
					? checkedTeam[0].backGroundColorCode
					: colors[0]
				: colors[0];

			objKeys &&
				objKeys.length &&
				objKeys.forEach((item) => {
					const rec = lineChartData[item];
					rec &&
						rec.length &&
						rec.forEach((data) => {
							const { date, progress } = data;
							const xCategoryData = date ? getMonthDate(date) : '';

							if (item === 'myProgressDetails') {
								seriesOneData.push({
									y: progress ? parseInt(progress) : 0,
									xLabel: xCategoryData,
								});
								categoriesData.push(xCategoryData);
							}

							if (item === 'myTeamMemberProgressDetails') {
								seriesTwoData.push({
									y: progress ? parseInt(progress) : 0,
									xLabel: xCategoryData,
								});
							}

							if (item === 'myOrgMemberProgressDetails') {
								seriesThreeData.push({
									y: progress ? parseInt(progress) : 0,
									xLabel: xCategoryData,
								});
							}
						});
				});

			categoriesData = categoriesData.reverse();

			const columnWithGroupedChartData = {
				chart: {
					type: 'areaspline',
					height: '322',
				},
				credits: {
					enabled: false,
				},
				title: {
					align: 'left',
					text: titleText || null,
				},
				subtitle: {
					text: '',
				},
				xAxis: {
					title: {
						text: xAxisText || null,
						style: {
							color: '#738FB5',
							fontSize: '10px',
							fontWeight: '700',
							textTransform: 'uppercase',
						},
					},
					labels: {
						useHTML: true,
						align: 'center',
						formatter: (detail) => {
							return categoriesData[detail?.value];
						},
						style: {
							color: '#738FB5',
							fontSize: '12px',
							fontWeight: '700',
							fontFamily: 'Lato',
						},
					},
					minTickInterval: 1,
					tickInterval: 1,
					tickWidth: 1,
					tickColor: 'rgba(161, 171, 185, 0.3)',
					tickLength: 8,
					tickmarkPlacement: 'on',
					gridLineWidth: 0,
					// crosshairs: false,
				},
				yAxis: {
					title: {
						text: yAxisText || null,
						style: {
							color: '#738FB5',
							fontSize: '10px',
							fontWeight: '700',
							fontFamily: 'Lato',
							textTransform: 'uppercase',
						},
					},
					labels: {
						useHTML: true,
						format: `{value}${toolTipPostfix || ''}`,
						style: {
							color: '#738FB5',
							fontSize: '12px',
							fontWeight: '700',
							fontFamily: 'Lato',
						},
					},
					min: 0,
					max: isEngagement
						? maxValueRecord.progress < 50
							? maxValueRecord.progress + 50
							: maxValueRecord.progress + 100
						: maxValueRecord.progress + 30,
					minTickInterval: (Math.ceil(maxValueRecord.progress / 100) * 100) / 5,
					tickInterval: (Math.ceil(maxValueRecord.progress / 100) * 100) / 5,
				},
				tooltip: {
					// crosshairs: true,
					shared: true,
					shadow: false,
					useHTML: true,
					backgroundColor: '#292929',
					borderColor: 'none',
					padding: 0,
					headerFormat: '', //'<span style="font-size:11px">Details</span><br>',
					pointFormat: `<div style='position:relative;padding-left:15px'><span style="font-size:20px;color:{point.color};position:absolute;top:-7px;left:0px;">\u25CF</span>
							<span >{point.xLabel}</span>&nbsp;:&nbsp;
							<b>{point.y}${toolTipPostfix || ''}</b><br/></div>`,
					style: {
						color: '#fff',
						fontWeight: 'normal',
						fontFamily: 'Lato',
						fontSize: '12px',
						zIndex: '1',
					},
				},
				plotOptions: {
					series: {
						marker: {
							enabled: true,
						},
						stickyTracking: false,
					},
					areaspline: {
						stickyTracking: false,
						dataLabels: {
							enabled: false,
							format: '{point.y}' + toolTipPostfix,
							style: {
								color: '#292929',
								fontSize: '10px',
								fontWeight: '700',
								fontFamily: 'Lato',
							},
						},
					},
				},
				legend: {
					enabled: showLegend || false,
					itemDistance: 13,
					padding: 2,
					margin: 0,
					itemMarginTop: 25,
					itemMarginBottom: 0,
					symbolHeight: 8,
					symbolWidth: 8,
					symbolRadius: 6,
					itemStyle: {
						color: '#292929',
						fontWeight: 'bold',
						fontFamily: 'Lato',
						fontSize: '12px',
					},
				},
				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 400,
							},
							chartOptions: {
								plotOptions: {
									series: {
										marker: {
											radius: 2.5,
										},
									},
									pointStart: 0,
								},
								legend: {
									layout: 'vertical',
									align: 'center',
									verticalAlign: 'bottom',
								},
							},
						},
					],
				},
				series: [
					{
						name: labels[0], // Me
						marker: {
							enabled: true,
							symbol: 'circle',
						},
						color: myColor,
						data: seriesOneData.reverse(),
						fillColor: {
							linearGradient: [0, 0, 0, 300],
							stops: [
								[0, Highcharts.color(myColor).setOpacity(0.4).get('rgba')],
								[1, Highcharts.color(myColor).setOpacity(0).get('rgba')],
							],
						},
					},
					{
						name: labels[1], // My Team
						marker: {
							enabled: true,
							symbol: 'circle',
						},
						color: teamColor,
						data: seriesTwoData.reverse(),
						fillColor: {
							linearGradient: [0, 0, 0, 300],
							stops: [
								[0, Highcharts.color(teamColor).setOpacity(0.4).get('rgba')],
								[1, Highcharts.color(teamColor).setOpacity(0).get('rgba')],
							],
						},
					},
					{
						name: labels[2], // 'Org Mem'
						marker: {
							enabled: true,
							symbol: 'circle',
						},
						color: orgColor,
						data: seriesThreeData.reverse(),
						fillColor: {
							linearGradient: [0, 0, 0, 300],
							stops: [
								[0, Highcharts.color(orgColor).setOpacity(0.35).get('rgba')],
								[1, Highcharts.color(orgColor).setOpacity(0).get('rgba')],
							],
						},
					},
				],
			};

			setChartDetails(columnWithGroupedChartData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportsData]);

	return (
		<>
			{chartDetails ? (
				<HighchartsReact highcharts={Highcharts} options={chartDetails} />
			) : (
				<Box className='org-loading'>
					<Box textAlign='center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				</Box>
			)}
		</>
	);
};
