import React, { Fragment, useEffect, useState } from 'react';
import clone from 'clone';
import { OKRDrawer } from '../Common/OKRDrawer';
import { KrAcceptAttributeDetail } from './KrAcceptAttributesDetail';
import '../Goals/OkrListing/style.scss';
import { Enums } from '../../config/enums';
import { getUserDetails } from '../../config/utils';
import AlertDialog from '../Common/Dialog';
import { OKRButton } from '../Common/OKRButton';
import { checkPermission } from '../../config/rolePermission';
import { OneToOneModule } from '../Admin/AdminConstant';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';

export const KrAcceptAttributes: React.FC<any> = (props) => {
	const {
		open,
		okr,
		loader,
		setIsAcceptKRFormUpdated,
		kr,
		KRType,
		setFeedback,
		isAcceptKRFormUpdated,
		openDrawer,
		setOpenDrawer,
		setLoader,
		fetchOkr,
		isAlignment,
	} = props;
	const { t } = useTranslation();
	const keyData = KRType === 'WithParent' ? clone(kr) : clone(okr);
	const year = getLocalStorageItem('currentYear') || null;
	const selectedYear = getLocalStorageItem('year') || year;
	const [orphanKRData, setOrphanKRData] = useState<any>({});
	const loggedInUserDetail = getUserDetails();
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const { enqueueSnackbar } = useSnackbar();
	const [krAttrDateError, setKRAttrDateError] = useState<any>({
		error: '',
		helperText: '',
		type: '',
	});
	const [acceptModalProps, setAcceptModalProps] = useState<any>({
		open: false,
		data: null,
		message: '',
	});
	useEffect(() => {
		const objectiveData = clone(okr);
		const myObj =
			KRType === 'WithParent'
				? {
						objectiveName: objectiveData?.objectiveName || '',
						goalObjectiveId: objectiveData?.goalObjectiveId,
						goalTypeId: okr.goalTypeId,
						objectiveDescription: objectiveData?.objectiveDescription,
						objectiveCycleId: objectiveData?.objectiveCycleId,
						confidenceId:0,
				  }
				: {};
		setOrphanKRData({ ...keyData, ...myObj });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validateAddContributorForm = (formData: any) => {
		if (formData.assignmentTypeId === Enums.TWO) {
			if (!formData.objectiveName) {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				return false;
			} else if (formData.objectiveName.trim() === '') {
				setKRAttrDateError({
					error: t('objectiveNameError'),
					helperText: t('objectiveNameError'),
					type: 'objectiveName',
				});
				return false;
			}
		}
		if (!formData.keyDescription) {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		} else if (formData.keyDescription.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyResult',
			});
			return false;
		}
		if (okr.goalTypeId === Enums.ONE && !formData.GoalTypeId) {
			setKRAttrDateError({
				error: t('selectionTypeError'),
				helperText: t('selectionTypeError'),
				type: 'assignment_error',
			});
			return false;
		}
		if (formData.GoalTypeId === 1 && !formData.TeamId) {
			setKRAttrDateError({
				error: t('errorTeamRequired'),
				helperText: t('errorTeamRequired'),
				type: 'assignment_error',
			});
			return false;
		}
		if (formData?.metricId === Enums.ONE || formData?.metricId === Enums.TWO || formData?.metricId === Enums.THREE) {
			formData.startValue = !formData.startValue ? 0 : formData.startValue;
			formData.targetValue = !formData.targetValue ? 0 : formData.targetValue;
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				return false;
			}
		}
		return true;
	};
	const handleDrawerCloseClick = (event: any, type: any) => {
		if (type === 'close' || type === 'requestOneOnOne') {
			setKRAttrDateError({
				error: '',
				helperText: '',
				type: '',
			});
			handleAcceptDrawerClose(event, type, orphanKRData);
		} else if (validateAddContributorForm(orphanKRData)) {
			handleAcceptDrawerClose(event, type, orphanKRData);
		}
	};
	const handleCloseModal = (e: any, action: any) => {
		if (action === 1) {
			handleAcceptDrawerClose(e, 'save', orphanKRData);
		}
		setAcceptModalProps({ open: false, data: '', type: '' });
	};

	const showApiMsgs = (msg: any, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const showErrorMsgWithObject = (response: any) => {
		try {
			const errorMessages = Object.values(response?.data?.messageList);
			if (errorMessages && errorMessages.length > 0) {
				errorMessages.forEach((item: any) =>
					enqueueSnackbar(item, {
						variant: 'error',
						autoHideDuration: 5000,
					})
				);
			}
		} catch (e) {}
	};
	const handleAcceptDrawerClose = async (event: any, type: String, orphanKRData: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'requestOneOnOne') {
			const { contributors } = openDrawer.kr;
			let sourceData = [];
			if (contributors) {
				sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			} else {
				const { contributors } = openDrawer.okr;
				sourceData = contributors && contributors.length > 0 && contributors.find((item: any) => item.isSource);
			}
			setFeedback({
				open: true,
				reqType: 4,
				okr: openDrawer.okr,
				kr: openDrawer.kr,
				goalType: props.KRType === 'WithParent' ? 'kr' : 'okr',
				goalId: props.KRType === 'WithParent' ? openDrawer.okr.goalObjectiveId : openDrawer.kr.goalKeyId,
				krId: openDrawer.kr?.goalKeyId,
				sourceContributor: sourceData,
				declineMessage: '',
			});
		} else if (type === 'close') {
			if (isAcceptKRFormUpdated) {
				setAcceptModalProps({
					open: true,
					type: 'AcceptKR',
					message: t('unSavedItemAlert'),
					module: 'user',
					moduleType: 1,
				});
			} else {
				setIsAcceptKRFormUpdated(false);
				setOpenDrawer({ open: false });
				setAcceptModalProps({ open: false, type: '', message: '', module: '' });
			}
		} else {
			const apiRequest = {
				goalKeyId: orphanKRData?.goalKeyId || 0,
				goalObjectiveId: orphanKRData?.goalObjectiveId || 0,
				keyDescription: orphanKRData?.keyDescription || '',
				objectiveName: orphanKRData?.objectiveName || '',
				score: orphanKRData?.score || 0,
				startDate: orphanKRData?.startDate || new Date(),
				dueDate: orphanKRData?.dueDate || new Date(),
				krStatusId: Enums.KR_STATUS_ACCEPTED,
				importedType: orphanKRData?.importedType || 0,
				importedId: orphanKRData?.importedId || 0,
				progress: orphanKRData?.progress || 0,
				source: orphanKRData?.source || 0,
				startValue: orphanKRData?.startValue || 0,
				targetValue: orphanKRData?.targetValue || 0,
				assignmentTypeId: orphanKRData?.assignmentTypeId || 0,
				krAssignerMessage: orphanKRData?.krAssignerMessage || '',
				krAssigneeMessage: orphanKRData?.krAssigneeMessage || '',
				currencyId: orphanKRData?.currencyId || 0,
				currencyCode: orphanKRData?.currencyCode || '',
				goalStatusId: Enums.TWO,
				TeamId: orphanKRData?.TeamId ? orphanKRData?.TeamId : 0,
				IsSelf: true,
				Contributors: orphanKRData?.Contributors ? orphanKRData?.Contributors : [],
				GoalTypeId: orphanKRData?.GoalTypeId
					? orphanKRData?.GoalTypeId
					: orphanKRData.goalTypeId
					? orphanKRData.goalTypeId
					: 2,
				keyNotes: orphanKRData?.keyNotes,
				objectiveDescription: orphanKRData?.objectiveDescription,
				confidenceId: orphanKRData?.confidenceId,
			};
			if (orphanKRData?.GoalTypeId === 1) {
				apiRequest.IsSelf = false;
			}
			apiRequest.GoalTypeId = orphanKRData.goalTypeId ? orphanKRData.goalTypeId : 2;
			setLoader(true);
			setIsAcceptKRFormUpdated(false);
			const response = await props.acceptKr(apiRequest);
			setLoader(false);
			if (response && response.data.status === Enums.STATUS_SUCCESS) {
				let userSelectedCycleId: any = cycleId;
				if (orphanKRData?.objectiveCycleId !== parseInt(userSelectedCycleId)) {
					setOpenDrawer({ open: false });
					setTimeout(() => {
						setLocalStorageItem('highlightGoal', orphanKRData?.goalObjectiveId);
						setLocalStorageItem('goalType', 1);
					}, 0);
					setAcceptModalProps({ open: false, type: '', message: '', module: '' });
					showApiMsgs(response?.data?.messageList?.Result, 'success');
					props.redirectToCycleId(parseInt(orphanKRData?.objectiveCycleId));
				} else {
					if (loggedInUserDetail.employeeId && cycleId && selectedYear) {
						const data = `empId=${loggedInUserDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
						props.getDashboardProgress(data);
					}
					setOpenDrawer({ open: false });
					setAcceptModalProps({ open: false, type: '', message: '', module: '' });
					showApiMsgs(response?.data?.messageList?.Result, 'success');
					fetchOkr();
					if (isAlignment) {
						const endpoint = `empId=${
							loggedInUserDetail && loggedInUserDetail.employeeId
						}&cycle=${cycleId}&year=${selectedYear}`;
						props.getAlignmentList(endpoint);
					}
				}
			} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
				const errorMessages = Object.values(response?.data?.messageList);
				if (errorMessages && errorMessages.length > 0) {
					showErrorMsgWithObject(response);
				}
			} else if (response && response.data.status === Enums.STATUS_SERVER_ERROR) {
				showApiMsgs(t('goalAddedFail'), 'error');
			}
		}
	};

	return (
		<Fragment>
			<OKRDrawer
				open={open}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={'Accepting a Key Result'}
				children={
					<KrAcceptAttributeDetail
						{...props}
						orphanKRData={orphanKRData}
						setOrphanKRData={setOrphanKRData}
						krAttrDateError={krAttrDateError}
						setKRAttrDateError={setKRAttrDateError}
						setIsAcceptKRFormUpdated={setIsAcceptKRFormUpdated}
						t={t}
					/>
				}
				moreButton={false}
				showBackButton={true}
				backButton={
					checkPermission(props.getPermissionResult?.employeePermissions, OneToOneModule) ? (
						<OKRButton
							className={'request-btn request11Btn'}
							text={t('requestOnetoOne')}
							handleClick={(event) => handleDrawerCloseClick(event, 'requestOneOnOne')}
						/>
					) : (
						<></>
					)
				}
				handleDrawerClose={(event: any) => handleDrawerCloseClick(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerCloseClick(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerCloseClick(event, 'close')}
				hideDefaultButton={false}
				loader={loader}
				isSaveButtonDisabled={false}
				saveButtonText={'Accept'}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>

			{acceptModalProps.open && (
				<AlertDialog
					module={'accept'}
					message={acceptModalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={acceptModalProps.open}
					isCancel={false}
				/>
			)}
		</Fragment>
	);
};
