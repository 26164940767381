import React, { Fragment, useEffect, useState } from 'react';
import ReactJoyride, { STATUS, ACTIONS, EVENTS, StoreHelpers } from 'react-joyride';
import { Box, Typography, Select, MenuItem, OutlinedInput } from '@material-ui/core';
import { SelectArrow } from '../../config/svg/ArrowSvg';

export const GuidedTour: React.FC<any> = (props: any) => {
	const { getGuidedTourData, guidedTourData, guideTourVisible, listOkrResult } = props;
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [navChangeSelect, setNavChangeSelect] = useState(false);
	const [filterGuidedTourData, setFilterGuidedTourData] = useState([]);
	const [steps, setsteps] = useState<any>([]);

	/**Create Drop down for each Step */
	const getDropDown = (index: number) => {
		return (
			<Select
				onChange={(e: any) => handleGuidedMenu(e)}
				input={<OutlinedInput />}
				value={index}
				className='guided-menu'
				displayEmpty
				style={{ width: 250 }}
				IconComponent={() => <SelectArrow />}
				MenuProps={{
					disableScrollLock: true,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					classes: { paper: 'guided-tour-popper' },
				}}
				inputProps={{ 'aria-label': 'Without label' }}
				label={'Tell me about...'}
			>
				<MenuItem disabled value='0'>
					<em>Tell me about...</em>
				</MenuItem>
				{filterGuidedTourData &&
					filterGuidedTourData.length > 0 &&
					filterGuidedTourData.map((item: any, index: number) => (
						<MenuItem data-set={index} key={index} value={item.guidedId}>
							{item.guidedTourName}
						</MenuItem>
					))}
			</Select>
		);
	};

	/**
	 * Default steps for guided tour
	 */
	const listItems: Array<any> = [
		{
			content: (
				<Box className='guided-tour'>
					<Box className='guided-step-text'>
						<Typography variant='h4'>Welcome to Guided Tour</Typography>
						<Typography>This tour will help you to explore the features & navigation system.</Typography>
					</Box>
					{getDropDown(0)}
				</Box>
			),
			placement: 'center',
			target: 'body',
			isFixed: true,
			dataIndex: 0,
			disableScrolling: true,
		},
		{
			content: (
				<Box className='guided-tour'>
					<Box className='guided-step-text'>
						<Typography variant='h4'>All and Team OKRs</Typography>
						<Typography>
							All OKRs are OKRs you have in hand for this cycle and required to make progress on. Team OKRs are your
							team's initiatives.
						</Typography>
					</Box>
					{getDropDown(1)}
				</Box>
			),
			placement: 'bottom-start',
			target: '.obj-title-wrap',
			dataIndex: 1,
		},
		{
			content: (
				<Box className='guided-tour'>
					<Box className='guided-step-text'>
						<Typography variant='h4'>My Progress</Typography>
						<Typography>
							This is your overall progress. The RAGS suggests areas you are doing well in and areas to focus on.
						</Typography>
					</Box>
					{getDropDown(2)}
				</Box>
			),
			target: '.progress-cards-dashboard',
			placement: 'top-start',
			dataIndex: 2,
		},
		{
			content: (
				<Box className='guided-tour'>
					<Box className='guided-step-text'>
						<Typography variant='h4'>Objective(s) and Key Result(s)</Typography>
						<Typography>
							These are OKRs created by you, or OKRs (Team, Individual) you are contributing to and required to make
							progress on.
						</Typography>
					</Box>
					{getDropDown(3)}
				</Box>
			),
			target: '.my-obj-listing-content:first-child',
			placement: 'top-start',
			dataIndex: 3,
		},
		{
			content: (
				<Box className='guided-tour'>
					<Box className='guided-step-text'>
						<Typography variant='h4'>Explore Navigation</Typography>
						<Typography>Here you can explore, About us, Tour & many more..</Typography>
					</Box>
					{getDropDown(4)}
				</Box>
			),
			target: '.drawer-open',
			placement: 'bottom-start',
			styles: {
				options: {
					borderRadius: '50px',
				},
			},
			dataIndex: 4,
		},
		{
			content: (
				<Box className='guided-tour'>
					<Box className='guided-step-text'>
						<Typography variant='h4'>OKRs Cycle</Typography>
						<Typography>Change date from here to view previous, current cycles.</Typography>
					</Box>
					{getDropDown(5)}
				</Box>
			),
			target: '.tenure-stepper .MuiMobileStepper-root',
			placement: 'bottom-end',
			dataIndex: 5,
		},
		{
			content: (
				<Box className='guided-tour'>
					<Box className='guided-step-text'>
						<Typography variant='h4'>Create New or Contribute</Typography>
						<Typography>
							Create new OKRs for yourself and teams or start with contributing to your colleagues and team's existing
							OKRs.
						</Typography>
					</Box>
					{getDropDown(6)}
				</Box>
			),
			target: '.add-btn',
			placement: 'bottom-end',
			dataIndex: 6,
		},
	];

	useEffect(() => {
		if (guidedTourData?.entity?.length > 0) {
			let allSteps = Object.assign(listItems);
			//If account is locked remove lock step
			setsteps(allSteps);
			if (listOkrResult && listOkrResult.isLocked) {
				allSteps = allSteps.filter((item: any) => item.dataIndex !== 6);
				setsteps(allSteps);
			}
			//If no okrs remove okr step
			if (listOkrResult.okrCount === 0) {
				allSteps = allSteps.filter((item: any) => item.dataIndex !== 3);
				setsteps(allSteps);
			}
		}
	}, [guidedTourData, filterGuidedTourData]);

	useEffect(() => {
		if (guideTourVisible) {
			//GET Guided tour data via API
			getGuidedTourData();

			//Stop body scroll if show Guided Tour
			document.body.style.overflow = 'hidden';
			window.scrollTo(0, 0);
		} else {
			//Enable body scroll if hide Guided Tour
			document.body.style.overflow = 'initial';
		}
	}, [guideTourVisible]);

	/**
	 * Filter api response
	 */
	useEffect(() => {
		if (guidedTourData && guidedTourData.entity) {
			let checkIfUnlocked: any = [...guidedTourData.entity];
			setFilterGuidedTourData(guidedTourData.entity);
			if (listOkrResult && listOkrResult.isLocked) {
				if (guidedTourData && guidedTourData?.entity?.length && guidedTourData?.entity?.length) {
					checkIfUnlocked = guidedTourData.entity.filter((item: any) => item.guidedId !== 6);
					setFilterGuidedTourData(checkIfUnlocked);
				}
			}
			if (listOkrResult.okrCount <= 0) {
				let checkOkrCount = checkIfUnlocked.filter((item: any) => item.guidedId !== 3);
				setFilterGuidedTourData(checkOkrCount);
			}
		}
	}, [guidedTourData]);

	/**
	 * Handle dropdown menu
	 * @param e HTMLEVENT
	 */
	const handleGuidedMenu = (e: any) => {
		const { value } = e.target;
		//get index of selected item from select box
		const currentIndex = filterGuidedTourData.map((item: any) => item.guidedId).indexOf(value);
		setCurrentIndex(currentIndex + 1);
		setNavChangeSelect(true);
	};

	/**
	 * Handle Joyride callback function
	 * @param data Object
	 */
	const handleJoyrideCallback = (data: any) => {
		const { status, type, index, action, currentIndex } = data;
		let currentItem = navChangeSelect ? currentIndex : index;
		if (action === 'close') {
			props.showGuidedTour(false);
			props.userClosedGuidedTour(true);
			setCurrentIndex(0); //resetting step to 0 once closed
			return;
		}
		setNavChangeSelect(false);
		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			// Need to set our running state to false, so we can restart if we click start again.
			props.showGuidedTour(false);
			props.userClosedGuidedTour(true);
			setCurrentIndex(0);
		} else if (type === EVENTS.STEP_AFTER && currentItem === 0) {
			setCurrentIndex(currentItem + (action === ACTIONS.PREV ? -1 : 1));
		} else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
			// Update state to advance the tour
			setCurrentIndex(currentItem + (action === ACTIONS.PREV ? -1 : 1));
		} else if (type === EVENTS.TOOLTIP_CLOSE) {
			setCurrentIndex(currentItem + 1);
		}
	};

	/**Default Joyride method */
	const getHelpers = (helpers: StoreHelpers) => {
		// eslint-disable-next-line no-self-assign
		helpers = helpers;
	};

	/**
	 * to default hide beacon element
	 * @returns JSX
	 */
	const HiddenBeacon = () => {
		return <div style={{ display: 'none' }}></div>;
	};

	return (
		<Fragment>
			<ReactJoyride
				hideBackButton={true}
				callback={handleJoyrideCallback}
				continuous={true}
				disableScrolling={true}
				getHelpers={getHelpers}
				run={guideTourVisible}
				disableOverlayClose={true}
				beaconComponent={HiddenBeacon}
				stepIndex={currentIndex}
				showSkipButton={false}
				steps={steps}
				locale={{ last: <Typography title=''>Got it!</Typography>, next: <Typography title=''>Next</Typography> }}
				spotlightPadding={10}
				styles={{
					options: {
						zIndex: 2000000,
						backgroundColor: '#292929',
						beaconSize: 30,
						overlayColor: 'rgba(41, 41, 41, 0.5)',
						primaryColor: '#39A3FA',
						spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
						textColor: '#fff',
						width: 338,
					},
				}}
			/>
		</Fragment>
	);
};

export default GuidedTour;
