import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';

export const OkrListingHead: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const {
		showArchiveList,
		isExpandAll,
		expandCollapseAll,
		showAddGoal,
		isFutureCycle,
		myArchiveGoalList,
		getFilter,
		myGoalList,
	} = props;
	return (
		<Box className='okr-listing-head pad-container'>
			<Box className='okr-listing-head-left'>
				<Box className='expand-all'>
					{myGoalList.length > 0 && (
						<OKRButton
							className={isExpandAll ? 'expand-all-inner collapse-active' : 'expand-all-inner'}
							handleClick={() => {
								showArchiveList ? expandCollapseAll(2) : expandCollapseAll(1);
							}}
							title={
								<Typography variant='body2' className='expand-txt'>
									{t('expandAndCollapseLabel')}
								</Typography>
							}
							text={<RightArrowIcon />}
						/>
					)}
					<Typography className='sort-obj-title' variant='h2'>
						{t('OkrLevel')}
					</Typography>
				</Box>
			</Box>
			<Box className='okr-listing-head-right'>
				{!showAddGoal && isFutureCycle !== true && (
					<Box className='goal-okr-filter alignment-map-filter'>
						{showArchiveList && myArchiveGoalList.length > 0 ? getFilter() : !showArchiveList && getFilter()}
					</Box>
				)}
			</Box>
		</Box>
	);
};
