import React, { useRef, useState } from 'react';
import { Box, MenuItem, Typography, MenuList } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getQuarterStartEnd } from '../../../config/utils';
import { getLocalStorageItem } from '../../../services/StorageService';
import { DownloadReportIcon } from '../../../config/svg/CommonSvg';
import { CSVLink } from 'react-csv';
import { Fragment } from 'react';

export default function DownloadReports(props) {
	let { currentYear } = getQuarterStartEnd();
	const { t } = useTranslation();
	const selectedYear = getLocalStorageItem('year') || currentYear;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const { directReports, roleId } = userDetail || {};

	const progressRef = useRef();
	const quarterRef = useRef();
	const statusRef = useRef();

	const [progressLoader, setProgressLoader] = useState(false);
	const [feedbackLoader, setFeedbackLoader] = useState(0);
	const [quarterLoader, setQuarterLoader] = useState(false);
	const [statusLoader, setStatusLoader] = useState(false);
	const [statusReportData, setStatusReportData] = useState([]);
	const [quarterReportData, setQuarterReportData] = useState([]);
	const [progressReportData, setProgressReportData] = useState([]);

	const statusReport = async () => {
		if (!statusLoader) {
			setStatusLoader(true);
			const data = `cycle=${cycleId}&year=${selectedYear}&isOwnTeam=${roleId === 1 ? false : Boolean(directReports)}`;
			await props
				.fetchStatusReport(data)
				.then((r) => setStatusReportData(r.data.entity))
				.catch((e) => console.log(e));
			statusRef.current.link.click();
			setStatusLoader(false);
		}
	};

	const quarterReport = async () => {
		if (!quarterLoader) {
			setQuarterLoader(true);
			const data = `cycle=${cycleId}&year=${selectedYear}&isOwnTeam=${roleId === 1 ? false : Boolean(directReports)}`;
			await props
				.fetchQuarterReport(data)
				.then((r) => setQuarterReportData(r.data.entity))
				.catch((e) => console.log(e));
			quarterRef.current.link.click();
			setQuarterLoader(false);
		}
	};

	const progressReport = async () => {
		if (!progressLoader) {
			setProgressLoader(true);
			const data = `cycle=${cycleId}&year=${selectedYear}&isOwnTeam=${roleId === 1 ? false : Boolean(directReports)}`;
			await props
				.fetchProgressReport(data)
				.then((r) => setProgressReportData(r.data.entity))
				.catch((e) => console.log(e));
			progressRef.current.link.click();
			setProgressLoader(false);
		}
	};

	const feedbackReport = async (type) => {
		if (!feedbackLoader) {
			setFeedbackLoader(type);
			await props
				.fetchFeedbackReport(`empId=0&type=` + type)
				.then((r) => {
					const url = window.URL.createObjectURL(new Blob([r.data]));
					const link = document.createElement('a');
					link.href = url;
					var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					var matches = filenameRegex.exec(r.headers['content-disposition']);
					link.setAttribute('download', matches && matches.length> 0 ? matches[1] : 'Feedback.xlsx')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					setFeedbackLoader(0);
				})
				.catch((e) => console.log(e));
		}
	};
	
	return (
		<Fragment>
			{roleId !== 1 && !directReports ? (
				<></>
			) : (
				<>
					<Typography variant='h6' className='download-report-head'>
						<Typography component={'span'}>{t('downloadReport')}</Typography>
					</Typography>
					<MenuList className='download-report-menu'>
						<MenuItem className='reports-download' onClick={statusReport}>
							<DownloadReportIcon />
							{statusLoader && (
								<Box className='spinner'>
									<Box className='bounce1'></Box>
									<Box className='bounce2'></Box>
									<Box className='bounce3'></Box>
								</Box>
							)}
							<Typography variant='button'>{t('statusReport')}</Typography>
							<CSVLink
								data={statusReportData}
								headers={[
									{ key: 'employeeCode', label: 'Employee ID' },
									{ key: 'firstName', label: 'Employee First Name' },
									{ key: 'lastName', label: 'Employee Last Name' },
									{ key: 'emailId', label: 'Mail Id' },
									{ key: 'status', label: 'Status' },
								]}
								filename='statusReports.csv'
								style={{ display: 'none' }}
								ref={statusRef}
								target='_blank'
							/>
						</MenuItem>

						<MenuItem className='reports-download' onClick={quarterReport}>
							<DownloadReportIcon />
							{quarterLoader && (
								<Box className='spinner'>
									<Box className='bounce1'></Box>
									<Box className='bounce2'></Box>
									<Box className='bounce3'></Box>
								</Box>
							)}
							<Typography variant='button'>{t('quarterReport')}</Typography>
							<CSVLink
								data={quarterReportData}
								headers={[
									{ key: 'employeeId', label: 'Employee ID' },
									{ key: 'firstName', label: 'Employee First Name' },
									{ key: 'lastName', label: 'Employee Last Name' },
									{ key: 'emailId', label: 'Email ID' },
									{ key: 'reportingToFirstName', label: 'Reporting To First Name' },
									{ key: 'reportingToLastName', label: 'Reporting To Last Name' },
									{ key: 'objectiveName', label: 'Objective Name' },
									{ key: 'objectiveScore', label: 'Objective Score' },
									{ key: 'keyDescription', label: 'Key Description' },
									{ key: 'keyStartDate', label: 'Key Start Date' },
									{ key: 'keyDueDate', label: 'Key Due Date' },
									{ key: 'keyScore', label: 'Key Score' },
								]}
								filename='quarterReports.csv'
								style={{ display: 'none' }}
								ref={quarterRef}
								target='_blank'
							/>
						</MenuItem>

						<MenuItem className='reports-download' onClick={progressReport}>
							<DownloadReportIcon />
							{progressLoader && (
								<Box className='spinner'>
									<Box className='bounce1'></Box>
									<Box className='bounce2'></Box>
									<Box className='bounce3'></Box>
								</Box>
							)}
							<Typography variant='button'>{t('progressReport')}</Typography>
							<CSVLink
								data={progressReportData}
								headers={[
									{ key: 'employeeID', label: 'Employee ID' },
									{ key: 'firstName', label: 'Employee First Name' },
									{ key: 'lastName', label: 'Employee Last Name' },
									{ key: 'emailId', label: 'Email ID' },
									{ key: 'objectiveCount', label: 'Objective Count' },
									{ key: 'krCount', label: 'KR Count' },
									{ key: 'progressScore', label: 'Progress Score' },
								]}
								filename='progressReports.csv'
								style={{ display: 'none' }}
								ref={progressRef}
								target='_blank'
							/>
						</MenuItem>
						{userDetail.directReports ? (
							<MenuItem className='reports-download' onClick={() => { feedbackReport(1) }}>
								<DownloadReportIcon />
								{feedbackLoader === 1 && (
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								)}
								<Typography variant='button'>{t('directPersonalFeedbackReport')}</Typography>

							</MenuItem>) : (<></>)
						}
						{userDetail.roleId === 1 ? (
							<MenuItem className='reports-download' onClick={() => { feedbackReport(2) }}>
								<DownloadReportIcon />
								{feedbackLoader === 2 && (
									<Box className='spinner'>
										<Box className='bounce1'></Box>
										<Box className='bounce2'></Box>
										<Box className='bounce3'></Box>
									</Box>
								)}
								<Typography variant='button'>{t('orgPersonalFeedbackReport')}</Typography>
							</MenuItem>
						) : (<></>)}
					</MenuList>
				</>
			)}
		</Fragment>
	);
}
