import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OrgIcon, EditUserIcon, TeamUserIcon } from '../../config/svg/CommonSvgIcon';
import { checkPermission } from '../../config/rolePermission';
import { viewOrganizationManagementPage, viewRoleManagement, viewUserManagementPage } from './AdminConstant';

export const AdminTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`admin-tab-${index}`}
			aria-labelledby={`admin-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

AdminTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `admin-tabpanel-${index}`,
		'aria-controls': `admin-tabpanel-${index}`,
	};
}

export const AdminListingTabs: React.FC<any> = (props) => {
	const { handleTabChange = {}, tabSelected = 0, getPermissionResult } = props;
	const { t } = useTranslation();
	return (
		<Tabs className='okr-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Admin Tabs'>
			{checkPermission(getPermissionResult?.employeePermissions, viewUserManagementPage) && (
				<Tab icon={<TeamUserIcon />} label={t('manageUserLabel')} value={0} {...a11yProps(0)} />
			)}
			{checkPermission(getPermissionResult?.employeePermissions, viewRoleManagement) && (
				<Tab icon={<EditUserIcon />} label={t('role&Permission')} value={1} {...a11yProps(1)} />
			)}
			{checkPermission(getPermissionResult?.employeePermissions, viewOrganizationManagementPage) && (
				<Tab icon={<OrgIcon />} label={t('organizationLabel')} value={2} {...a11yProps(2)} />
			)}
		</Tabs>
	);
};
