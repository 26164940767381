import React, { Fragment, useState } from 'react';
import { Popper, Grow, Paper, ClickAwayListener, MenuItem, MenuList } from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { KebabIcon } from '../../config/svg/CommonSvg';

interface OKRPopperProps {
	popperClassName?: string;
	handlePopperClose?: (event: any) => void;
	children?: React.ReactNode;
	popperPlacement?: any;
	popperContainer?: any;
	openPopper?: any;
	setOpenPopper?: any;
	anchorEl?: any;
	setAnchorEl?: any;
	key?: any;
	popperKey?: any;
	handleOpenImpersonate?: any;
}

export const OKRPopper: React.FC<any> = (props) => {
	const {
		popperClassName,
		handlePopperClose,
		children,
		popperPlacement,
		popperContainer,
		popperKey,
		instance,
		handleMoveSSOLogin,
		handleInstanceForceLogout,
		handleOpenImpersonate,
	} = props;
	const [] = useState<HTMLElement | null>(null);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openPopper, setOpenPopper] = useState<boolean>(false);

	const handleClose = (event: any) => {
		setOpenPopper(false);
		setAnchorEl(null);
		handlePopperClose && handlePopperClose(event);
	};

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenPopper((prev) => !prev);
	};

	const getActionItems = () => {
		return (
			<MenuList>
				<MenuItem
					// key={`menu-item-${row.index}`}
					onClick={(e) => {
						handleToggle(e);
						props.setSelectedInstance(instance);
						props.setDrawerProps({ open: true });
						// setAnchorEl(null);
					}}
				>
					Edit
				</MenuItem>
				{instance.isActive && (
					<MenuItem
						// key={`menu-item-${row.index}`}
						onClick={(e) => {
							handleOpenImpersonate(e, instance);
							//handleToggle(e);
							//props.handleImpersonate(e, instance);
						}}
					>
						Impersonate
					</MenuItem>
				)}
				{/* {instance && instance.isActive && (
					<MenuItem
						onClick={(e) => {
							handleToggle(e);
							props.handleMoveSSOLogin(e, instance);
						}}
					>
						{instance.isSSO ? 'Move to DB Login' : 'Move to SSO Login'}
					</MenuItem>
				)} */}
				{instance && instance.isActive && (
					<MenuItem
						onClick={(e) => {
							handleToggle(e);
							props.handleInstanceForceLogout(e, instance);
						}}
					>
						{instance.isForceLogout && instance.isActive ? 'Undo Force Logout' : 'Force Logout'}
					</MenuItem>
				)}
				{instance && !instance.isActive && (
					<MenuItem
						// key={`menu-item-${row.index}`}
						onClick={(e) => {
							handleToggle(e);
							props.handleDeleteInstance(e, instance);
						}}
					>
						Delete
					</MenuItem>
				)}
				{/* {!instance?.isFederated && (
					<MenuItem
						// key={`menu-item-${row.index}`}
						onClick={(e) => {
							handleToggle(e);
							props.handleSSO(e, instance);
						}}
					>
						Convert to Federated
					</MenuItem>
				)} */}
			</MenuList>
		);
	};

	return (
		<Fragment>
			<OKRButton className='kebab-icon' icon={<KebabIcon />} handleClick={handleToggle} />
			<Popper
				key={popperKey}
				className={popperClassName || ''}
				open={openPopper || false}
				anchorEl={anchorEl}
				transition
				placement={popperPlacement || 'bottom-end'}
				container={popperContainer === true ? anchorEl : document.body}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>{getActionItems()}</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Fragment>
	);
};
