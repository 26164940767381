import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	userUnreadNotification,
	deleteNotification,
	readSingleNotification,
	resetNotification,
	personalFeedbackAcceptReject,
} from '../../action/notification';
import {
	fetchProgressReport,
	fetchFeedbackReport,
	fetchStatusReport,
	fetchQuarterReport,
	getGoalsDetails,
	getConversationById,
	getNoteById,
} from '../../action/myGoal';
import { searchEmployee, searchCompleted, searchFailed, globalSearchAPIWithTeam } from '../../action/search';
import { logoutAction, impersonateUser } from '../../action/signin';
import {
	updateRoute,
	showLoader,
	updatePage,
	getCycleDetails,
	updatePrevRoute,
	getUserById,
	showGuidedTour,
	userClosedGuidedTour,
	changeCycleId,
	getGuidedTourActivity,
	isFederationUser,
	signalRConnectionStatus,
	updateCurrentCycleId,
	currentCycleIdObject,
	getUserSelectedCycle,
	teamCycleDetails,
	currentDashboardTab,
	isImpersonated,
} from '../../action/common';
import {
	requestOneToOnePersonalFeedback,
	askDetailsPersonalFeedback,
	askPersonalFeedback,
	giveDetailsPersonalFeedback,
	giveUpdatePersonalFeedback,
	getCriteriaMaster,
	cancelRequestPersonalFeedback,
	requestAgainPersonalFeedback,
	viewPersonalFeedback,
	personalFeedbackDetail,
} from '../../action/personalFeedback';
import { getObjectiveList, resetOkr, checkAlignStatus } from '../../action/myGoal';
import { getOrganization } from '../../action/organization';
import { getEmployeePermission } from '../../action/roles';
import {
	updateProfileDetail,
	updateProfilePassword,
	deleteProfilePic,
	getProfile,
	updateProfilePic,
} from '../../action/users';

import {
	redirectToFeedback,
	resetFeedback,
	giveFeedback,
	askFeedback,
	requestOneToOneFeedback,
	commentFeedback,
	fetchFeedback,
} from '../../action/feedback';
import { resetAlignment, setAlignmentMapAction } from '../../action/alignment';
import { showNotification, showRefreshSnackBar } from '../../action/signalR';
import Header from '../../components/Header';

const mapStateToProps = (state) => {
	return {
		loaderStatus: state.commonReducer.loaderStatus,
		currentRoute: state.commonReducer.currentRoute,
		globalSearchPage: state.commonReducer.globalSearchPage,
		showNotifier: state.commonReducer.showNotifier,

		listOkrPending: state.okrReducer.listOkrPending,
		listOkrResult: state.okrReducer.listOkrResult,
		listOkrSuccess: state.okrReducer.listOkrSuccess,
		listOkrError: state.okrReducer.listOkrError,

		searchPending: state.searchReducer.searchPending,
		searchResult: state.searchReducer.searchResult,
		searchSuccess: state.searchReducer.searchSuccess,
		searchError: state.searchReducer.searchError,

		userUnreadNotificationStatus: state.notificationReducer.userUnreadNotificationStatus,
		userUnreadNotificationResult: state.notificationReducer.userUnreadNotificationResult,
		adminUnreadNotificationStatus: state.notificationReducer.adminUnreadNotificationStatus,
		adminUnreadNotificationResult: state.notificationReducer.adminUnreadNotificationResult,

		listNotificationPending: state.notificationReducer.listNotificationPending,
		listNotificationResult: state.notificationReducer.listNotificationResult,
		listNotificationSuccess: state.notificationReducer.listNotificationSuccess,
		listNotificationForAdminPending: state.notificationReducer.listNotificationForAdminPending,
		listNotificationForAdminResult: state.notificationReducer.listNotificationForAdminResult,
		listNotificationForAdminSuccess: state.notificationReducer.listNotificationForAdminSuccess,
		readNotificationSuccess: state.notificationReducer.readNotificationSuccess,
		deleteNotificationSuccess: state.notificationReducer.deleteNotificationSuccess,
		giveFeedbackStatus: state.feedbackReducer.giveFeedbackStatus,
		askFeedbackStatus: state.feedbackReducer.askFeedbackStatus,
		feedbackOneOnOneStatus: state.feedbackReducer.feedback1To1Status,

		commentFeedbackStatus: state.feedbackReducer.commentFeedbackStatus,
		fetchFeedbackPending: state.feedbackReducer.fetchFeedbackPending,
		fetchFeedbackSuccess: state.feedbackReducer.fetchFeedbackSuccess,
		fetchFeedbackResult: state.feedbackReducer.fetchFeedbackResult,
		fetchFeedbackError: state.feedbackReducer.fetchFeedbackError,

		guideTourVisible: state.commonReducer.guideTourVisible,
		dashboardCurrentTab: state.commonReducer.dashboardCurrentTab,
		isClosedByUser: state.commonReducer.isClosedByUser,
		cycleDetailsAction: state.commonReducer.cycleObj,
		onboardingCount: state.onboardingReducer.onboardingCount,
		activeOnboardingPanel: state.onboardingReducer.activeOnboardingPanel,

		getPermissionStatus: state.rolesReducer.getPermissionStatus,
		getPermissionResult: state.rolesReducer.getPermissionResult,

		isFederationUserSuccess: state.commonReducer.isFederationUserSuccess,
		isFederationUserResult: state.commonReducer.isFederationUserResult,
		notificationHighLight: state.signalRReducer.showNotification,
		showRefreshOptionSnackBar: state.signalRReducer.showRefreshOptionSnackBar,
		isSignalRConnected: state.commonReducer.isSignalRConnected,
		okrType: state.commonReducer.okrType,
		currentCycleId: state.commonReducer.currentCycleId,
		cycleIdDetails: state.commonReducer.cycleIdDetails,
		userSelectedCycle: state.commonReducer.userSelectedCycle,
		teamCycleData: state.commonReducer.teamCycleDetails,
		teamCycleDetails1: state.commonReducer.teamCycleDetails,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			searchEmployee,
			globalSearchAPIWithTeam,
			deleteNotification,
			searchCompleted,
			searchFailed,
			logoutAction,
			updateRoute,
			showLoader,
			readSingleNotification,
			resetNotification,
			updatePage,
			getObjectiveList,
			getCycleDetails,
			redirectToFeedback,
			resetFeedback,
			giveFeedback,
			askFeedback,
			requestOneToOneFeedback,
			commentFeedback,
			fetchFeedback,
			resetOkr,
			resetAlignment,
			getOrganization,
			updatePrevRoute,
			updateProfileDetail,
			updateProfilePassword,
			deleteProfilePic,
			getProfile,
			updateProfilePic,
			checkAlignStatus,
			fetchProgressReport,
			fetchFeedbackReport,
			fetchStatusReport,
			fetchQuarterReport,
			getGoalsDetails,
			getUserById,
			requestOneToOnePersonalFeedback,
			askDetailsPersonalFeedback,
			askPersonalFeedback,
			giveDetailsPersonalFeedback,
			giveUpdatePersonalFeedback,
			getCriteriaMaster,
			cancelRequestPersonalFeedback,
			requestAgainPersonalFeedback,
			viewPersonalFeedback,
			personalFeedbackDetail,
			personalFeedbackAcceptReject,
			setAlignmentMapAction,
			showGuidedTour,
			userClosedGuidedTour,
			changeCycleId,
			getGuidedTourActivity,
			userUnreadNotification,
			getEmployeePermission,
			isFederationUser,
			showNotification,
			signalRConnectionStatus,
			showRefreshSnackBar,
			updateCurrentCycleId,
			currentCycleIdObject,
			getUserSelectedCycle,
			getConversationById,
			getNoteById,
			teamCycleDetails,
			impersonateUser,
			currentDashboardTab,
			isImpersonated,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
