import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UnlockLogo } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';

export const Header: React.FC<any> = (props) => {
	const { isUserLoggedIn } = props;
	const { t } = useTranslation();
	const [redirectURL, setRedirectURL] = useState<any>(null);

	useEffect(() => {
		if (window.location.href.includes('?target=')) {
			const targetURI = window.location.href.split('?target=');
			if (targetURI && targetURI.length > 1) {
				const isValidURL: any = targetURI[1].match(
					/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
				);
				if (isValidURL) {
					if (targetURI[1].startsWith('https://')) {
						setRedirectURL(targetURI[1]);
					} else {
						const updatedURL = `https://${targetURI[1]}`;
						setRedirectURL(updatedURL);
					}
				}
			}
		}
	}, []);

	const handleRedirectToUnlockOKR = () => {
		redirectURL && window.open(redirectURL, '_blank');
	};

	return (
		<Box className='whats-new-header'>
			<Box className='logo'>
				<UnlockLogo />
			</Box>
			{redirectURL && !isUserLoggedIn ? (
				<OKRButton
					className='btn-primary go-to-button'
					text={t('goToButtonLevel')}
					handleClick={handleRedirectToUnlockOKR}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
