import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	userUnreadNotification,
	deleteNotification,
	readSingleNotification,
	resetNotification,
	personalFeedbackAcceptReject,
} from '../../action/notification';
import { fetchProgressReport, fetchFeedbackReport, fetchStatusReport, fetchQuarterReport, getGoalsDetails } from '../../action/myGoal';
import { searchEmployee, searchCompleted, searchFailed, globalSearchAPIWithTeam } from '../../action/search';
import { logoutAction, impersonateUser } from '../../action/signin';
import {
	updateRoute,
	showLoader,
	updatePage,
	getCycleDetails,
	updatePrevRoute,
	getUserById,
	showGuidedTour,
	userClosedGuidedTour,
	changeCycleId,
	getGuidedTourActivity,
	isFederationUser,
} from '../../action/common';
import {
	requestOneToOnePersonalFeedback,
	askDetailsPersonalFeedback,
	askPersonalFeedback,
	giveDetailsPersonalFeedback,
	giveUpdatePersonalFeedback,
	getCriteriaMaster,
	cancelRequestPersonalFeedback,
	requestAgainPersonalFeedback,
	viewPersonalFeedback,
	personalFeedbackDetail,
} from '../../action/personalFeedback';
import { getObjectiveList, resetOkr, checkAlignStatus } from '../../action/myGoal';
import { getOrganization } from '../../action/organization';
import {
	updateProfileDetail,
	updateProfilePassword,
	deleteProfilePic,
	getProfile,
	updateProfilePic,
} from '../../action/users';

import {
	redirectToFeedback,
	resetFeedback,
	giveFeedback,
	askFeedback,
	requestOneToOneFeedback,
	commentFeedback,
	fetchFeedback,
} from '../../action/feedback';
import { resetAlignment, setAlignmentMapAction } from '../../action/alignment';
import { Header } from '../../components/Admin/Header';
import { getEmployeePermission, getRoles } from '../../action/roles';

const mapStateToProps = (state) => {
	return {
		loaderStatus: state.commonReducer.loaderStatus,
		currentRoute: state.commonReducer.currentRoute,
		globalSearchPage: state.commonReducer.globalSearchPage,
		showNotifier: state.commonReducer.showNotifier,

		searchPending: state.searchReducer.searchPending,
		searchResult: state.searchReducer.searchResult,
		searchSuccess: state.searchReducer.searchSuccess,
		searchError: state.searchReducer.searchError,

		adminUnreadNotificationStatus: state.notificationReducer.adminUnreadNotificationStatus,
		adminUnreadNotificationResult: state.notificationReducer.adminUnreadNotificationResult,

		listNotificationForAdminPending: state.notificationReducer.listNotificationForAdminPending,
		listNotificationForAdminResult: state.notificationReducer.listNotificationForAdminResult,
		listNotificationForAdminSuccess: state.notificationReducer.listNotificationForAdminSuccess,
		readNotificationSuccess: state.notificationReducer.readNotificationSuccess,
		deleteNotificationSuccess: state.notificationReducer.deleteNotificationSuccess,

		guideTourVisible: state.commonReducer.guideTourVisible,
		dashboardCurrentTab: state.commonReducer.dashboardCurrentTab,
		isClosedByUser: state.commonReducer.isClosedByUser,
		cycleDetailsAction: state.commonReducer.cycleObj,
		onboardingCount: state.onboardingReducer.onboardingCount,
		activeOnboardingPanel: state.onboardingReducer.activeOnboardingPanel,

		getPermissionStatus: state.rolesReducer.getPermissionStatus,
		getPermissionResult: state.rolesReducer.getPermissionResult,

		isFederationUserSuccess: state.commonReducer.isFederationUserSuccess,
		isFederationUserResult: state.commonReducer.isFederationUserResult,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			searchEmployee,
			globalSearchAPIWithTeam,
			deleteNotification,
			searchCompleted,
			searchFailed,
			logoutAction,
			updateRoute,
			showLoader,
			readSingleNotification,
			resetNotification,
			updatePage,
			getObjectiveList,
			getCycleDetails,
			redirectToFeedback,
			resetFeedback,
			giveFeedback,
			askFeedback,
			requestOneToOneFeedback,
			commentFeedback,
			fetchFeedback,
			resetOkr,
			resetAlignment,
			getOrganization,
			updatePrevRoute,
			updateProfileDetail,
			updateProfilePassword,
			deleteProfilePic,
			getProfile,
			updateProfilePic,
			checkAlignStatus,
			fetchProgressReport,
			fetchFeedbackReport,
			fetchStatusReport,
			fetchQuarterReport,
			getGoalsDetails,
			getUserById,
			requestOneToOnePersonalFeedback,
			askDetailsPersonalFeedback,
			askPersonalFeedback,
			giveDetailsPersonalFeedback,
			giveUpdatePersonalFeedback,
			getCriteriaMaster,
			cancelRequestPersonalFeedback,
			requestAgainPersonalFeedback,
			viewPersonalFeedback,
			personalFeedbackDetail,
			personalFeedbackAcceptReject,
			setAlignmentMapAction,
			showGuidedTour,
			userClosedGuidedTour,
			changeCycleId,
			getGuidedTourActivity,
			userUnreadNotification,
			getEmployeePermission,
			getRoles,
			isFederationUser,
			impersonateUser,
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
