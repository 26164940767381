import React, { Fragment } from 'react';
import T from 'prop-types';

export let BASE_MARGIN = 160;

export default class ForeignObjectElement extends React.PureComponent {
	render() {
		const {
			nodeData,
			nodeSize,
			render,
			foreignObjectWrapper,
			handleOnClick,
			handleToggle,
			handleDeleteOrganization,
			alignResult,
			resetFeedback,
			handleDeleteObjective,
			openAddKeyResult,
			handleChange,
			checked,
			updateObjectiveSlider,
			getObjectiveByObjectiveId,
			zoomFit,
			setTransitionDuration,
		} = this.props;
		const pathName = window.location.pathname;
		// delete this after poc code removed
		const id = pathName === '/goal-map' ? nodeData.importedId !== 0 : nodeData.importedId !== null;
		const x = nodeSize.x / 2 - 25;
		const y = nodeSize.y / 2;
		const height = nodeData.name === 'parent' ? 0 : nodeSize.y - BASE_MARGIN;
		//	console.log(nodeData, '----------');
		return (
			<Fragment>
				<g>
					{!nodeData.parentItem && nodeData.rootNode && id && nodeData.name !== 'parent' && (
						<g
							className='node-bottom-arrow'
							id={nodeData.goalObjectiveId + '_parent'}
							fill='none'
							transform={`translate(${x + 48},20) rotate(180)`}
							width={48}
							height={48}
							onClick={(event) => handleOnClick(event, 'parent')}
							opacity={1}
						>
							{/* <circle r='9' fill='#39A3FA' stroke='white' /> */}
							{Boolean(nodeData.parentOpen) ? (
								<svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<g filter='url(#filter0_d)'>
										<path
											d='M24 32C30.624 32 36 26.624 36 20C36 13.376 30.624 8 24 8C17.376 8 12 13.376 12 20C12 26.624 17.376 32 24 32Z'
											fill='white'
											stroke='none'
										/>
									</g>
									<path
										d='M19.0001 19C18.4478 19 18.0001 19.4477 18.0001 20C18.0001 20.5523 18.4478 21 19.0001 21L19.0001 19ZM24.0001 20L24.0001 19H24.0001L24.0001 20ZM29.0001 21C29.5524 21 30.0001 20.5523 30.0001 20C30.0001 19.4477 29.5524 19 29.0001 19L29.0001 21ZM19.0001 21L24.0001 21L24.0001 19L19.0001 19L19.0001 21ZM24.0001 21L29.0001 21L29.0001 19L24.0001 19L24.0001 21Z'
										fill='#A1ABB9'
										stroke='none'
									/>
									<defs>
										<filter
											id='filter0_d'
											x='0'
											y='0'
											width='48'
											height='48'
											filterUnits='userSpaceOnUse'
											colorInterpolationFilters='sRGB'
										>
											<feFlood floodOpacity='0' result='BackgroundImageFix' />
											<feColorMatrix
												in='SourceAlpha'
												type='matrix'
												values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
											/>
											<feOffset dy='4' />
											<feGaussianBlur stdDeviation='6' />
											<feColorMatrix
												type='matrix'
												values='0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0.2 0'
											/>
											<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
											<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
										</filter>
									</defs>
								</svg>
							) : (
								<svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<g filter='url(#filter0_d)'>
										<path
											d='M24 32C30.624 32 36 26.624 36 20C36 13.376 30.624 8 24 8C17.376 8 12 13.376 12 20C12 26.624 17.376 32 24 32Z'
											fill='white'
											stroke='none'
										/>
									</g>
									<path
										d='M18.293 18.7071C17.9024 18.3166 17.9024 17.6834 18.293 17.2929C18.6835 16.9024 19.3166 16.9024 19.7072 17.2929L18.293 18.7071ZM24.0001 23L24.7072 23.7071C24.3166 24.0976 23.6835 24.0976 23.293 23.7071L24.0001 23ZM28.293 17.2929C28.6835 16.9024 29.3166 16.9024 29.7072 17.2929C30.0977 17.6834 30.0977 18.3166 29.7072 18.7071L28.293 17.2929ZM19.7072 17.2929L24.7072 22.2929L23.293 23.7071L18.293 18.7071L19.7072 17.2929ZM23.293 22.2929L28.293 17.2929L29.7072 18.7071L24.7072 23.7071L23.293 22.2929Z'
										fill='#A1ABB9'
										stroke='none'
									/>
									<defs>
										<filter
											id='filter0_d'
											x='0'
											y='0'
											width='48'
											height='48'
											filterUnits='userSpaceOnUse'
											colorInterpolationFilters='sRGB'
										>
											<feFlood floodOpacity='0' result='BackgroundImageFix' />
											<feColorMatrix
												in='SourceAlpha'
												type='matrix'
												values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
											/>
											<feOffset dy='4' />
											<feGaussianBlur stdDeviation='6' />
											<feColorMatrix
												type='matrix'
												values='0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0.2 0'
											/>
											<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
											<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
										</filter>
									</defs>
								</svg>
							)}
						</g>
					)}
					{nodeData.goalKeyId && (
						<g
							width='11'
							stroke='none'
							height='10'
							transform={`translate(${x + 19},-38)`}
							viewBox='0 0 11 10'
							fill='none'
						>
							<ellipse stroke='none' cx='6' cy='5' rx='5.24975' ry='5' fill='#39a3fa' />
						</g>
					)}
					<g>
						<foreignObject width={nodeSize.x} height={height} {...foreignObjectWrapper}>
							{React.cloneElement(render, {
								nodeData,
								handleToggle,
								handleDeleteOrganization,
								alignResult,
								resetFeedback,
								handleDeleteObjective,
								openAddKeyResult,
								handleChange,
								checked,
								updateObjectiveSlider,
								getObjectiveByObjectiveId,
								zoomFit,
								setTransitionDuration,
							})}
						</foreignObject>
					</g>
					{nodeData['_children'] &&
						nodeData['_children'].length > 0 &&
						!nodeData.parentItem &&
						!nodeData.showKr &&
						nodeData.name !== 'parent' && (
							<g
								className='node-bottom-arrow'
								id={nodeData.goalObjectiveId + '_child'}
								fill='none'
								transform={`translate(${x},${y - 15})`}
								width={48}
								height={48}
								onClick={(event) => handleOnClick(event, 'children')}
								opacity={1}
							>
								{/* <circle r='9' fill='#39A3FA' stroke='white' /> */}
								{!nodeData['_collapsed'] ? (
									<svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<g filter='url(#filter0_d)'>
											<path
												d='M24 32C30.624 32 36 26.624 36 20C36 13.376 30.624 8 24 8C17.376 8 12 13.376 12 20C12 26.624 17.376 32 24 32Z'
												fill='white'
												stroke='none'
											/>
										</g>
										<path
											d='M19.0001 19C18.4478 19 18.0001 19.4477 18.0001 20C18.0001 20.5523 18.4478 21 19.0001 21L19.0001 19ZM24.0001 20L24.0001 19H24.0001L24.0001 20ZM29.0001 21C29.5524 21 30.0001 20.5523 30.0001 20C30.0001 19.4477 29.5524 19 29.0001 19L29.0001 21ZM19.0001 21L24.0001 21L24.0001 19L19.0001 19L19.0001 21ZM24.0001 21L29.0001 21L29.0001 19L24.0001 19L24.0001 21Z'
											fill='#A1ABB9'
											stroke='none'
										/>
										<defs>
											<filter
												id='filter0_d'
												x='0'
												y='0'
												width='48'
												height='48'
												filterUnits='userSpaceOnUse'
												colorInterpolationFilters='sRGB'
											>
												<feFlood floodOpacity='0' result='BackgroundImageFix' />
												<feColorMatrix
													in='SourceAlpha'
													type='matrix'
													values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
												/>
												<feOffset dy='4' />
												<feGaussianBlur stdDeviation='6' />
												<feColorMatrix
													type='matrix'
													values='0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0.2 0'
												/>
												<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
												<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
											</filter>
										</defs>
									</svg>
								) : (
									<svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<g filter='url(#filter0_d)'>
											<path
												d='M24 32C30.624 32 36 26.624 36 20C36 13.376 30.624 8 24 8C17.376 8 12 13.376 12 20C12 26.624 17.376 32 24 32Z'
												fill='white'
												stroke='none'
											/>
										</g>
										<path
											d='M18.293 18.7071C17.9024 18.3166 17.9024 17.6834 18.293 17.2929C18.6835 16.9024 19.3166 16.9024 19.7072 17.2929L18.293 18.7071ZM24.0001 23L24.7072 23.7071C24.3166 24.0976 23.6835 24.0976 23.293 23.7071L24.0001 23ZM28.293 17.2929C28.6835 16.9024 29.3166 16.9024 29.7072 17.2929C30.0977 17.6834 30.0977 18.3166 29.7072 18.7071L28.293 17.2929ZM19.7072 17.2929L24.7072 22.2929L23.293 23.7071L18.293 18.7071L19.7072 17.2929ZM23.293 22.2929L28.293 17.2929L29.7072 18.7071L24.7072 23.7071L23.293 22.2929Z'
											fill='#A1ABB9'
											stroke='none'
										/>
										<defs>
											<filter
												id='filter0_d'
												x='0'
												y='0'
												width='48'
												height='48'
												filterUnits='userSpaceOnUse'
												colorInterpolationFilters='sRGB'
											>
												<feFlood floodOpacity='0' result='BackgroundImageFix' />
												<feColorMatrix
													in='SourceAlpha'
													type='matrix'
													values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
												/>
												<feOffset dy='4' />
												<feGaussianBlur stdDeviation='6' />
												<feColorMatrix
													type='matrix'
													values='0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0 0.683333 0 0 0 0.2 0'
												/>
												<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
												<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
											</filter>
										</defs>
									</svg>
								)}
							</g>
						)}
					<use
						xlinkHref={'#' + nodeData.goalObjectiveId + '_parent'}
						onClick={(event) => handleOnClick(event, 'parent')}
					/>
				</g>
			</Fragment>
		);
	}
}

ForeignObjectElement.defaultProps = {
	foreignObjectWrapper: {},
};

ForeignObjectElement.propTypes = {
	render: T.oneOfType([T.element, T.node]).isRequired,
	nodeData: T.object.isRequired,
	nodeSize: T.shape({
		x: T.number,
		y: T.number,
	}).isRequired,
	foreignObjectWrapper: T.object,
	handleOnClick: T.func,
};
