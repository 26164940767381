import React, { Fragment } from 'react';
import T from 'prop-types';
import { select } from 'd3';

import SvgTextElement from './SvgTextElement';
import ForeignObjectElement from './ForeignObjectElement';
import './style.scss';

export default class Node extends React.Component {
	state = {
		transform: this.setTransform(this.props.nodeData, this.props.orientation, true),
		initialStyle: {
			opacity: 0,
		},
	};

	componentDidMount() {
		this.commitTransform();
	}

	componentDidUpdate() {
		this.commitTransform();
	}

	shouldComponentUpdate(nextProps) {
		return this.shouldNodeTransform(this.props, nextProps);
	}

	shouldNodeTransform = (ownProps, nextProps) =>
		nextProps.subscriptions !== ownProps.subscriptions ||
		nextProps.nodeData.x !== ownProps.nodeData.x ||
		nextProps.nodeData.y !== ownProps.nodeData.y ||
		nextProps.orientation !== ownProps.orientation;

	setTransform(nodeData, orientation, shouldTranslateToOrigin = false) {
		const { x, y, parent } = nodeData;
		const { type } = this.props;
		const yWidth = type === 'topNode' ? y - 200 : y;
		if (shouldTranslateToOrigin) {
			const hasParent = typeof parent === 'object';
			const originX = hasParent ? parent.x : 0;
			const originY = hasParent ? parent.y : 0;
			return orientation === 'horizontal' ? `translate(${originY},${originX})` : `translate(${originX},${originY})`;
		}
		return orientation === 'horizontal' ? `translate(${y},${x})` : `translate(${x},${yWidth})`;
	}

	applyTransform(nodeData, transform, transitionDuration, opacity = 1, done = () => {}) {
		if (nodeData.name === 'parent') {
			opacity = 0;
		}
		if (transitionDuration === 0) {
			select(this.node).attr('transform', transform).style('opacity', opacity);
			done();
		} else {
			select(this.node)
				.transition()
				.duration(transitionDuration)
				.attr('transform', transform)
				.style('opacity', opacity)
				.each('end', done);
		}
	}

	commitTransform() {
		const { nodeData, orientation, transitionDuration } = this.props;
		const transform = this.setTransform(nodeData, orientation);

		this.applyTransform(nodeData, transform, transitionDuration);
	}

	renderNodeElement = (nodeStyle) => {
		const { circleRadius, nodeSvgShape } = this.props;
		/* TODO: DEPRECATE <circle /> */
		if (circleRadius) {
			return <circle r={circleRadius} style={nodeStyle.circle} />;
		}

		return nodeSvgShape.shape === 'none'
			? null
			: React.createElement(nodeSvgShape.shape, {
					...nodeStyle.circle,
					...nodeSvgShape.shapeProps,
			  });
	};

	handleOnClick = (evt, type) => {
		evt.preventDefault();
		evt.stopPropagation();
		this.props.onClick(this.props.nodeData.id, evt, type);
	};

	handleOnMouseOver = (evt) => {
		this.props.onMouseOver(this.props.nodeData.id, evt);
	};

	handleOnMouseOut = (evt) => {
		this.props.onMouseOut(this.props.nodeData.id, evt);
	};

	componentWillLeave(done) {
		const { nodeData, orientation, transitionDuration } = this.props;
		const transform = this.setTransform(nodeData, orientation, true);
		this.applyTransform(transform, transitionDuration, 0, done);
	}

	render() {
		const { nodeData, nodeSize, nodeLabelComponent, allowForeignObjects, styles } = this.props;
		const nodeStyle = nodeData._children ? { ...styles.node } : { ...styles.leafNode };
		return (
			<Fragment>
				<g
					id={nodeData.id}
					ref={(n) => {
						this.node = n;
					}}
					style={this.state.initialStyle}
					className={
						nodeData._children
							? nodeData.parentItem
								? 'parentNode node leafNodeBase node_' + nodeData.id + ''
								: 'node nodeBase node_' + nodeData.id + ''
							: nodeData.parentItem
							? 'parentNode node leafNodeBase node_' + nodeData.id + ''
							: 'node leafNodeBase node_' + nodeData.id + ''
					}
					onMouseOver={this.handleOnMouseOver}
					onMouseOut={this.handleOnMouseOut}
					opacity={nodeData.name === 'parent' ? 0 : 1}
					source-id={nodeData.rootNode}
					level={nodeData.alignLevel}
				>
					{allowForeignObjects && nodeLabelComponent ? (
						<ForeignObjectElement
							handleOnClick={this.handleOnClick}
							textAnchor='end'
							nodeData={nodeData}
							nodeSize={nodeSize}
							handleToggle={this.props.handleToggle}
							handleDeleteOrganization={this.props.handleDeleteOrganization}
							chartType={this.props.chartType}
							handleDeleteObjective={this.props.handleDeleteObjective}
							openAddKeyResult={this.props.openAddKeyResult}
							handleChange={this.props.handleChange}
							checked={this.props.checked}
							updateObjectiveSlider={this.props.updateObjectiveSlider}
							getObjectiveByObjectiveId={this.props.getObjectiveByObjectiveId}
							resetFeedback={this.props.resetFeedback}
							alignResult={this.props.alignResult}
							zoomFit={this.props.zoomFit}
							setTransitionDuration={this.props.setTransitionDuration}
							{...nodeLabelComponent}
						/>
					) : (
						<SvgTextElement {...this.props} nodeStyle={nodeStyle} />
					)}
				</g>
			</Fragment>
		);
	}
}

Node.defaultProps = {
	nodeLabelComponent: null,
	name: '',
	attributes: undefined,
	circleRadius: undefined,
	styles: {
		node: {
			circle: {},
			name: {},
			attributes: {},
		},
		leafNode: {
			circle: {},
			name: {},
			attributes: {},
		},
	},
};

Node.propTypes = {
	nodeData: T.object.isRequired,
	nodeSvgShape: T.object.isRequired,
	nodeLabelComponent: T.object,
	nodeSize: T.object.isRequired,
	orientation: T.oneOf(['horizontal', 'vertical']).isRequired,
	transitionDuration: T.number.isRequired,
	onClick: T.func.isRequired,
	onMouseOver: T.func.isRequired,
	onMouseOut: T.func.isRequired,
	name: T.string,
	attributes: T.object,
	textLayout: T.object.isRequired,
	subscriptions: T.object.isRequired, // eslint-disable-line react/no-unused-prop-types
	allowForeignObjects: T.bool.isRequired,
	circleRadius: T.number,
	styles: T.object,
};
