import { Diagram } from '@syncfusion/ej2-diagrams';
import { DiagramHelperUtil, DiagramHelperUtil as Util, MapTypes } from './diagram-helper-util';
import { DiagramOkrHelper } from './diagram-okr-helper';
let diagramIns: any;
let diagramOkrHelper: DiagramOkrHelper;
let layoutNodes;
let nodeExpandedStr = 'IsNodeExpanded';
let nodeVirtualExpandedStr = 'IsNodeVirtualExpanded';
export class DiagramLayoutHelper {
	startY: any;
	constructor(diagramInstance: any) {}
	SetDiagramInstance(diagramInstance: any, diagramOkrHelperIns: any) {
		diagramIns = diagramInstance;
		diagramOkrHelper = diagramOkrHelperIns;
	}
	//#region - Custom Own Layout
	GetJsonNodes(diagramIns: any) {
		let ins = this;
		layoutNodes = diagramIns.nodes.filter((j: any) => {
			if (!ins.IsHelperNode(j) && j.data && j.data.jsonNode) return true;
		});
		return layoutNodes;
	}
	GetLayoutNodes(diagramIns: any) {
		let ins = this;
		layoutNodes = diagramIns.nodes.filter((j: any) => {
			if (!ins.IsHelperNode(j) && j.data && ins.IsVisible(j) && j.data.jsonNode) return true;
		});
		return layoutNodes;
	}
	IsHelperNode(obj: any) {
		return DiagramHelperUtil.IsHelperNode(obj);
	}
	DoCustomOkrLayout(
		diagramInstance: any,
		diagramOkrHelper: any,
		isInitialLoad: boolean = false,
		isCreateTopLabels: boolean = true,
		isSwitch: boolean = false
	) {
		this.SetDiagramInstance(diagramInstance, diagramOkrHelper);
		let startX = diagramInstance.scrollSettings.viewPortWidth / 2;
		let startY = 0;
		//DiagramHelperUtil.TimerStart('diagram layout');
		let jsonnodeList = this.GetJsonNodes(diagramInstance);

		for (let i = 0; i < jsonnodeList.length; i++) {
			var node = jsonnodeList[i];
			node.data['LayoutDone'] = false;
		}
		let nodeList = this.GetLayoutNodes(diagramInstance);
		let ins = this;
		let myOkrNodes = nodeList.filter((j: any) => {
			if (j.data && ins.IsVisible(j) && j.data.isMyOkr) return true;
		});
		myOkrNodes = myOkrNodes.sort(function (n1: any, n2: any) {
			return n1.data.sIndex - n2.data.sIndex;
		});
		if (!isInitialLoad && myOkrNodes && myOkrNodes.length > 0) {
			startX = myOkrNodes[0].offsetX;
			// startY = myOkrNodes[0].offsetY - myOkrNodes[0].height / 2;
		}
		if (isInitialLoad) {
			diagramInstance.nodes.forEach((n: any) => {
				n.offsetX = 0;
				n.offsetY = 0;
			});
		}
		DiagramHelperUtil.TimerStart('diagram layout');

		for (let i = 0; i < myOkrNodes.length; i++) {
			startY = startY ? startY : this.GetStartY(diagramInstance, myOkrNodes[i]);
			this.PositionNodes(diagramInstance, myOkrNodes[i], startX, startY, myOkrNodes[i].height, true, false);
			this.PositionNodes(diagramInstance, myOkrNodes[i], startX, startY, myOkrNodes[i].height, false, true);
			DiagramHelperUtil.RefreshDiagram(diagramInstance);

			let vSpace: number = 30;
			if (
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
			) {
				vSpace = 25;
			}

			startY = this.GetMaxHeightOFOKR(diagramInstance, myOkrNodes[i], vSpace);
		}
		DiagramHelperUtil.TimerEnd('diagram layout end');
		if (isCreateTopLabels) {
			DiagramHelperUtil.TimerStart('diagram top label start');
			//DiagramHelperUtil.TimerStartt("okr");
			diagramOkrHelper.CreateTopLabels(diagramInstance, myOkrNodes);
			//DiagramHelperUtil.Timerend("okr");
			DiagramHelperUtil.TimerEnd('diagram top label end');

			DiagramHelperUtil.RefreshDiagram(diagramInstance);
		}

		// DiagramHelperUtil.TimerEnd('diagram layout end');
		// //DiagramHelperUtil.TimerStartt("okr");
		diagramOkrHelper.CreateInfoLabels(diagramInstance, myOkrNodes, 0);
		// //DiagramHelperUtil.Timerend("okr");
		// DiagramHelperUtil.TimerStart('overview update start');
		// // if (diagramOkrHelper.ref_overview && diagramOkrHelper.ref_overview.current)
		// // 	diagramOkrHelper.ref_overview.current.updateOverview();
		DiagramHelperUtil.RefreshDiagram(diagramInstance);
		// DiagramHelperUtil.TimerEnd('overview update start');
	}
	GetStartY(di: any, node: any) {
		if (!this.startY) {
			this.startY = -10; //-(di.scroller.transform.ty + 450);
			if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr) {
				this.startY = -8;
			}
			if (DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam) {
				this.startY = 10;
			}
		}
		return this.startY;
	}
	LayoutOnExpOrCOl(diagramInstance: any, diagramOkrHelper: any, selectednode: any, isContributor: any) {
		this.SetDiagramInstance(diagramInstance, diagramOkrHelper);

		//var selectedMyOKR = this.GetMyOkrNode(diagramInstance, selectednode, isContributor);

		DiagramHelperUtil.TimerStart('diagram layout');
		let nodeList = this.GetJsonNodes(diagramInstance);

		for (let i = 0; i < nodeList.length; i++) {
			var node = nodeList[i];
			node.data['LayoutDone'] = false;
		}

		let ins = this;
		let myOkrData = nodeList.filter((j: any) => {
			if (j.data && ins.IsVisible(j) && j.data.isMyOkr) return true;
		});
		myOkrData = myOkrData.sort(function (n1: any, n2: any) {
			return n1.data.sIndex - n2.data.sIndex;
		});

		let startX = myOkrData[0].offsetX;
		let startY = myOkrData[0].offsetY - myOkrData[0].height / 2;

		for (let i = 0; i < myOkrData.length; i++) {
			startY = startY ? startY : 60;
			this.PositionNodes(diagramInstance, myOkrData[i], startX, startY, myOkrData[i].height, false, false);
			this.PositionNodes(diagramInstance, myOkrData[i], startX, startY, myOkrData[i].height, true, true);
			DiagramHelperUtil.RefreshDiagram(diagramInstance);

			let vSpace: number = 30;
			if (
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
			) {
				vSpace = 25;
			}
			startY = this.GetMaxHeightOFOKR(diagramInstance, myOkrData[i], vSpace);
		}

		diagramOkrHelper.CreateInfoLabels(diagramInstance, myOkrData, 0);
		DiagramHelperUtil.connectorCollections = [];
		//DiagramHelperUtil.Timerend("okr");
		DiagramHelperUtil.TimerStart('over');
		// if (diagramOkrHelper.ref_overview && diagramOkrHelper.ref_overview.current)
		// 	diagramOkrHelper.ref_overview.current.updateOverview();
		DiagramHelperUtil.TimerEnd('over');
	}

	positionNodesForExp(diagramInstance: any, currentNode: any, diffY: any, isParentArrangement: any, ismyOKR: any) {
		let parentnodes = [];
		if (isParentArrangement) parentnodes = this.GetParentNodes(diagramInstance, currentNode);
		else parentnodes = this.GetChildNodes(diagramInstance, currentNode);
		for (var j = 0; j < parentnodes.length; j++) {
			this.positionNodesForExp(diagramInstance, parentnodes[j], diffY, isParentArrangement, false);
		}
		if (!ismyOKR) {
			currentNode.offsetY += diffY;
			diagramOkrHelper.UpdateChildOffsets(currentNode);
			DiagramHelperUtil.RefreshUI(currentNode);
		}

		//currentNode.style.color = 'none';
		// setTimeout(() => {
		// 	DiagramHelperUtil.dataBind(currentNode);
		// }, 10);
	}

	PositionNodes(
		diagramInstance: any,
		currentNode: any,
		startX: number,
		startY: number,
		maxHeight: number,
		isParentArrangement: boolean,
		myOkrRendered: boolean
	) {
		if (!myOkrRendered) {
			currentNode.offsetX = startX;
			currentNode.offsetY = startY + currentNode.height / 2;
			currentNode.data['LayoutDone'] = true;
			DiagramHelperUtil.SetVisibility(currentNode, true);
			diagramOkrHelper.UpdateChildOffsets(currentNode);

			if (DiagramHelperUtil) {
				var con = DiagramHelperUtil.connectorCollections[currentNode.id];
				if (con) {
					DiagramHelperUtil.SetVisibility(con, true);
					//DiagramHelperUtil.RefreshUI(con);
				}
			}
		}

		let parentnodes = [];
		if (isParentArrangement) parentnodes = this.GetParentNodes(diagramInstance, currentNode);
		else parentnodes = this.GetChildNodes(diagramInstance, currentNode);
		startY = this.RenderChildNodes(diagramInstance, parentnodes, startX, startY, maxHeight, isParentArrangement);
		return startY;
	}

	RenderChildNodes(
		diagramInstance: any,
		parentnodes: any,
		startX: number,
		startY: number,
		maxheight: number,
		isparentarrangement: boolean
	) {
		let hSpace: number = 200;
		let vSpace: number = 30;
		if (
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
			DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
		) {
			hSpace = 180;
			vSpace = 15;
		}
		for (let i = 0; i < parentnodes.length; i++) {
			let sX = isparentarrangement ? startX - parentnodes[i].width - hSpace : startX + parentnodes[i].width + hSpace;
			startY = this.PositionNodes(
				diagramInstance,
				parentnodes[i],
				sX,
				startY,
				this.GetMaxHeight(maxheight, parentnodes[i]),
				isparentarrangement,
				false
			);
			if (parentnodes.length - 1 > i) {
				startY = startY + parentnodes[i].height + vSpace;
			}
		}
		return startY;
	}

	GetMaxHeight(maxheight: number, node: any) {
		return maxheight > node.height ? maxheight : node.height;
	}

	GetParentNodes(diagramInstance: any, currentnode: any) {
		var ns = [];
		var expendedType = this.isNodeExpanded(currentnode, diagramInstance);
		if (!expendedType) {
			return [];
		}
		var ps = currentnode.data.parent;
		let ins = this;
		for (let i = 0; i < ps.length; i++) {
			var node = this.GetLayoutNodes(diagramInstance).find(
				(n: any) => n.data && !n.data.isMyOkr && n.data.objectiveUniqueId === ps[i].toString()
			);
			if (this.IsValidForLayout(node, expendedType)) {
				ns.push(node);
			}
		}
		return ns;
	}

	GetParentNodesForGetMaxHeight(diagramInstance: any, currentnode: any) {
		var ns = [];
		var expendedType = this.isNodeExpanded(currentnode, diagramInstance);
		if (!expendedType) {
			return [];
		}
		var ps = currentnode.data.parent;
		var ins = this;
		for (let i = 0; i < ps.length; i++) {
			var node = this.GetLayoutNodes(diagramInstance).find(
				(n: any) => n.data && ins.IsVisible(n) && !n.data.isMyOkr && n.data.objectiveUniqueId === ps[i].toString()
			);
			if (node) {
				ns.push(node);
			}
		}
		return ns;
	}

	IsValidForLayout(currentnode: any, expendedType: any) {
		if (currentnode && currentnode.data) {
			var donelayout = currentnode.data['LayoutDone'];
			if (donelayout) {
				return false;
			}
			if (expendedType === nodeExpandedStr && !currentnode.data.isParentVirtualLink) {
				return true;
			}
			if (expendedType === nodeVirtualExpandedStr && currentnode.data.isParentVirtualLink) {
				return true;
			}
		}
		return false;
	}

	isNodeExpanded(currentnode: any, diagramInstance: any) {
		var IconNode = DiagramHelperUtil.GetNode(currentnode.id + '_sourceIcon', diagramInstance);
		var VIconNode = DiagramHelperUtil.GetNode(currentnode.id + '_sourceIcon_virtual', diagramInstance);
		if (IconNode && IconNode.data && IconNode.data.expanded) {
			return nodeExpandedStr;
		} else if (VIconNode && VIconNode.data && VIconNode.data.expanded) {
			return nodeVirtualExpandedStr;
		}
		return false;
	}

	IsVisible(obj: any) {
		if (obj) {
			if (obj.visible) return true;
			if (obj.data && obj.data.visible) return true;
		}
		return false;
	}
	GetChildNodes(diagramInstance: any, currentnode: any) {
		var ns = [];
		var oEdges = currentnode.outEdges;
		let ins = this;
		for (let i = 0; i < oEdges.length; i++) {
			var con = diagramInstance.connectors.find((n: any) => n.id === oEdges[i]);
			var node = this.GetLayoutNodes(diagramInstance).find(
				(n: any) => n.data && ins.IsVisible(n) && !n.data.isMyOkr && n.id === con.targetID
			);
			if (node) {
				ns.push(node);
			}
		}
		return ns;
	}

	GetMyOkrNode(diagramInstance: any, selectednode: any, isContributor: any) {
		let ins = this;
		if (selectednode.data.isMyOkr) return selectednode;
		else if (isContributor) {
			var ps = selectednode.data.parent;
			var Parentnode = this.GetLayoutNodes(diagramInstance).find(
				(n: any) => n.data && ins.IsVisible(n) && n.data.objectiveUniqueId === ps[0].toString()
			);
			selectednode = this.GetMyOkrNode(diagramInstance, Parentnode, isContributor);
		} else {
			var oEdges = selectednode.outEdges;
			var con = diagramInstance.connectors.find((n: any) => n.id === oEdges[0]);
			var childnode = this.GetLayoutNodes(diagramInstance).find(
				(n: any) => n.data && ins.IsVisible(n) && n.id === con.targetID
			);
			selectednode = this.GetMyOkrNode(diagramInstance, childnode, isContributor);
		}
		return selectednode;
	}

	GetMaxHeightOFOKR(diagramInstance: Diagram, myokr: any, vSpace: any) {
		var childs = this.GetChildNodes(diagramInstance, myokr);
		var parents = this.GetParentNodesForGetMaxHeight(diagramInstance, myokr);
		childs = childs.concat(parents);
		childs.push(myokr);
		let nodes = childs.sort(function (n1: any, n2: any) {
			return n2.offsetY + n2.height - (n1.offsetY + n1.height);
		});
		return nodes[0].offsetY + nodes[0].height / 2 + vSpace;
	}
	//#endregion
	//#region -- Focus Okr Secion
	FocusMyOkrs(diagramInstance: any) {
		let nodeList = this.GetLayoutNodes(diagramInstance);
		let ins = this;
		let myOkrData = nodeList.filter((j: any) => {
			return j.data && ins.IsVisible(j) && j.data.isMyOkr;
		});
		if (myOkrData && myOkrData.length > 0) {
			let layoutHSpace = diagramInstance.layout.horizontalSpacing;
			let extraLeftRightSpace = 50;
			let len: number = 2;
			let okrCount: number = 3;
			if (
				DiagramHelperUtil.MapType === MapTypes.CompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.CompactViewTeam
			) {
				len = 4;
			}
			if (
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewOkr ||
				DiagramHelperUtil.MapType === MapTypes.UltraCompactViewTeam
			) {
				len = 8;
				layoutHSpace = 120;
				// vSpace = 15;
				// okrCount = 5;
			}

			let totalWidth = 500 * okrCount + 2 * layoutHSpace + extraLeftRightSpace; // layout spacing;
			let intialZoom = diagramInstance.scrollSettings.viewPortWidth / totalWidth;
			let currentZoom = diagramInstance.scrollSettings.currentZoom;

			if (currentZoom < intialZoom) {
				let zoomDiff = intialZoom - currentZoom;
				let zoomDiffPercentage = Math.abs(zoomDiff) / intialZoom;
				let zoom = { type: 'ZoomIn', zoomFactor: Math.abs(zoomDiffPercentage) };
				diagramInstance.zoomTo(zoom);
				DiagramHelperUtil.RefreshUI(diagramInstance);
			} else {
				let zoomDiff = currentZoom - intialZoom;
				let zoomDiffPercentage = Math.abs(zoomDiff) / intialZoom;
				let zoom = { type: 'ZoomOut', zoomFactor: Math.abs(zoomDiffPercentage) };
				diagramInstance.zoomTo(zoom);
				DiagramHelperUtil.RefreshUI(diagramInstance);
			}

			//diagramInstance.pan(0, 5000);
			////if (DiagramHelperUtil.MapType !== MapTypes.UltraCompactViewOkr) {
			// diagramInstance.scrollSettings = {
			// 	verticalOffset: (this.startY),
			// 	padding: { right: 50, bottom: 50 },
			// };
			// DiagramHelperUtil.RefreshUI(diagramInstance);
			// //}
		}
	}
	//#endregion
}
