import * as React from 'react';
import { Avatar, Box, IconButton, Tooltip } from '@material-ui/core';
import { IndividualSmallIcon } from '../../../config/svg/CommonSvg';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import { getUserName } from '../../../config/utils';
import { useTranslation } from 'react-i18next';

export interface PeopleRootNodeProps {}

const PeopleRootNode: React.FC<any> = (props: any) => {
	const { data, currentUser, userDetail } = props;
	const okr = data.data;
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<Box className='people-root-node'>
				{okr?.isContributorExist === true && (
					<Tooltip title={`${t('contributorsLabel')}`} arrow>
						<IconButton className='connect-icon'>
							<IndividualSmallIcon />
						</IconButton>
					</Tooltip>
				)}
				{okr?.isSourceExist === true && (
					<Tooltip title={`${t('parentLabel')}`} arrow>
						<IconButton className='connect-icon connect-icon-arrow'>
							<RightArrowIcon />
						</IconButton>
					</Tooltip>
				)}
				{userDetail && userDetail.imagePath && userDetail.imagePath.trim() !== '' ? (
					<Avatar className='profile-avatar-style' src={userDetail.imagePath} />
				) : userDetail && userDetail.firstName ? (
					<Avatar className='profile-avatar-style'>{getUserName(userDetail)}</Avatar>
				) : (
					<Avatar className='profile-avatar-style'></Avatar>
				)}
			</Box>
		</React.Fragment>
	);
};

export default PeopleRootNode;
