import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuList, MenuItem, Link } from '@material-ui/core';
import { HOME, INSIGHT, ALIGNMENTMAPS, ADMIN, REPORTS } from '../../../config/app-url';
import { Enums } from '../../../config/enums';

export default function AdminNavigation(props) {
	const { currentRoute, updateRoute, location } = props;
	const { t } = useTranslation();

	useEffect(() => {
		const path = location && location.pathname ? location.pathname : '';
		updateRoute(path);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<MenuList>
			<MenuItem
				className={`home ${currentRoute === HOME ? 'active' : ''}`}
				onClick={(e) => {
					props.switchUser(e, 'user', HOME);
				}}
			>
				<Link>{t('okrsLabel')}</Link>
			</MenuItem>
			<MenuItem
				className={currentRoute === ALIGNMENTMAPS ? 'active' : ''}
				onClick={(e) => {
					props.switchUser(e, 'user', ALIGNMENTMAPS);
				}}
			>
				<Link>{t('alignmentMaps')}</Link>
			</MenuItem>
			{ props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID && <MenuItem
				className={currentRoute === REPORTS ? 'active' : ''}
				onClick={(e) => {
					props.switchUser(e, 'user', REPORTS);
				}}
			>
				<Link>{t('reports')}</Link>
			</MenuItem>}
			{/* <MenuItem
				className={currentRoute === INSIGHT ? 'active' : ''}
				onClick={(e) => {
					props.switchUser(e, 'user', INSIGHT);
				}}
			>
				<Link>{t('insights')}</Link>
			</MenuItem> */}
			<MenuItem
				className={currentRoute === ADMIN ? 'active' : ''}
				onClick={(e) => {
					props.switchUser(e, 'admin', ADMIN);
				}}
			>
				<Link>{t('admin')}</Link>
			</MenuItem>
		</MenuList>
	);
}
