import { combineReducers } from 'redux';
import commonReducer from '../reducers/common';
import searchReducer from '../reducers/search';
import notificationReducer from '../reducers/notification';
import signReducer from '../reducers/signin';
import okrReducer from '../reducers/myGoal';
import feedbackReducer from '../reducers/feedback';
import organizationReducer from '../reducers/organization';
import rolesReducer from '../reducers/roles';
import usersReducer from '../reducers/users';
import alignReducer from '../reducers/alignment';
import forgotPasswordReducer from '../reducers/forgotPassword';
import resetPasswordReducer from '../reducers/resetPassword';
import ssoLoginReducer from '../reducers/ssoLogin';
import onboardingReducer from '../reducers/onboarding';
import reportsReducer from '../reducers/reports';
import cfrReducer from '../reducers/cfr';
import signalRReducer from '../reducers/signalR';
import okrUpdateReducer from './okrUpdate';
import mailReducer from '../reducers/mail';

const mainReducer = combineReducers({
	commonReducer,
	searchReducer,
	notificationReducer,
	signReducer,
	forgotPasswordReducer,
	resetPasswordReducer,
	okrReducer,
	feedbackReducer,
	organizationReducer,
	rolesReducer,
	usersReducer,
	alignReducer,
	ssoLoginReducer,
	onboardingReducer,
	reportsReducer,
	cfrReducer,
	signalRReducer,
	okrUpdateReducer,
	mailReducer,
});

const rootReducer = (state, action) => {
	return mainReducer(state, action);
};

export default rootReducer;
