import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, Collapse, Link, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NewTabIcon } from '../../config/svg/CommonSvg';
import { DeleteIcon, EditIcon, RightArrowIcon } from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';
import NotFoundImg from '../../images/no-found-notes.svg';
import { deleteVersionDetail, deleteVersionFeature, getAllVersionForUser } from '../../action/okrUpdate';
import AlertDialog from '../Common/Dialog';
import { RecordVoiceOver } from '@material-ui/icons';
import { Enums } from '../../config/enums';

export const UpdateFeatureList: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { selectedVersion, getSelectedVersionFeatureList, editUpdateFeature, isUserLoggedIn } = props;
	const { allVersionFeatureDetails, allVersionFeatureDetailsPending } = useSelector(
		(state: any) => state.okrUpdateReducer
	);

	const [versionFeatureList, setVersionFeatureList] = useState<any>([]);
	const [expandedFeatureList, setExpandedFeatureList] = useState<any>([]);
	const [showDialog, setShowDialog] = useState<any>({ open: false });

	useEffect(() => {
		if (allVersionFeatureDetails && allVersionFeatureDetails.length) {
			setVersionFeatureList(allVersionFeatureDetails);
			const featureList = allVersionFeatureDetails[0]?.featureId;
			setExpandedFeatureList([featureList]);
		} else {
			setVersionFeatureList([]);
		}
	}, [allVersionFeatureDetails]);

	const handleFeatureListToggle = (e: any, OkrfeatureId: any) => {
		let element = document.getElementById(OkrfeatureId);
		let currentTop = 0;
		if (element) {
			currentTop = element.getBoundingClientRect().top;
		}
		let featureList = Object.assign([], expandedFeatureList);
		const elementIndex = featureList.indexOf(OkrfeatureId);
		if (elementIndex !== -1) {
			featureList.splice(elementIndex, 1);
		} else {
			featureList = [OkrfeatureId]; //.push(OkrfeatureId);
			setTimeout(() => {
				if (element) {
					const newTop = element.getBoundingClientRect().top;
					const scrollDifference = newTop - currentTop;

					window.scrollBy(0, scrollDifference);
				}
			}, 300);
		}
		setExpandedFeatureList(featureList);
	};
	const handleFeatureEdit = async (event: any, record: any) => {
		event.preventDefault();
		event.stopPropagation();
		editUpdateFeature(record);
	};
	const handleFeatureDelete = async (event: any, record: any) => {
		event.preventDefault();
		event.stopPropagation();
		const queryParam = `?featureId=${record?.featureId}`;
		const response: any = await dispatch(deleteVersionFeature(queryParam));
		if (response && response.data && response.data.isSuccess) {
			enqueueSnackbar(t('featureDeleteSuccessMsg'), {
				variant: 'success',
				autoHideDuration: 5000,
			});

			getSelectedVersionFeatureList(selectedVersion);
		} else {
			const { messageList } = response?.data || {};
			enqueueSnackbar(messageList?.featureId, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleCloseModal = async (event: any, type: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setShowDialog({ open: false });
			if (versionFeatureList && versionFeatureList.length === 1 && selectedVersion?.isPublished) {
				const queryParam = `?versionId=${selectedVersion?.versionId}`;
				const response: any = await dispatch(deleteVersionDetail(queryParam));
				if (response && response.data && response.data.isSuccess) {
					enqueueSnackbar(t('featureDeleteSuccessMsg'), {
						variant: 'success',
						autoHideDuration: 5000,
					});
					const reqPostPayload = {
						publishType: 1,
						pageIndex: 1,
						pageSize: Enums.TEN,
					};
					dispatch(getAllVersionForUser(reqPostPayload));
					setVersionFeatureList([]);
				} else {
					const { messageList } = response?.data || {};
					enqueueSnackbar(messageList?.featureId, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			} else {
				handleFeatureDelete(showDialog.event, showDialog.record);
			}
		} else {
			setShowDialog({ open: false });
		}
	};
	const handlePopperScroll = (event: any, elementId: any) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);

			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
		}, 500);
	};
	return (
		<Box className='okr-update-list-area'>
			{versionFeatureList && versionFeatureList.length ? (
				versionFeatureList.map((item: any) => (
					<Box className='okr-update-list' key={item?.featureId}>
						<Box className='okr-update-list-inner'>
							<Box
								className='okr-update-head'
								onClick={(e) => {
									handleFeatureListToggle(e, item?.featureId);

									//handlePopperScroll(e, item?.featureId);
								}}
							>
								<Box
									//className='rotating-arrow'
									className={`rotating-arrow ${
										expandedFeatureList.indexOf(item.featureId) !== -1 ? 'expand' : 'collapse'
									}`}
									//onClick={(e) => handleFeatureListToggle(e, item?.featureId)}
								>
									<RightArrowIcon />
								</Box>
								<List component='ul'>
									<ListItem className='okr-update-col1'>
										<Box className='feature-title'>
											<Typography variant='h4'>{item?.title || ''}</Typography>
											<Typography>{item?.subTitle || ''}</Typography>
										</Box>
									</ListItem>
									<ListItem className='okr-update-col2'>
										<Box
											className={`feature-type-chip ${
												item?.featureType === 1 ? 'feature-type-new' : 'feature-type-fix'
											}`}
										>
											{item?.featureType === 1 ? t('newFeature') : t('FixLabel')}
										</Box>
									</ListItem>
									<ListItem className='okr-update-col3'>
										{item?.featureUserManual && (
											<Tooltip arrow title={item?.uploadedFileName?.uploadFileName || ''}>
												<Link
													href={item?.featureUserManual?.filePath}
													target='_blank'
													onClick={(event) => event.stopPropagation()}
												>
													User Manual <NewTabIcon />
												</Link>
											</Tooltip>
										)}
										{isUserLoggedIn && (
											<OKRButton
												icon={<EditIcon />}
												handleClick={(event: any) => {
													event.stopPropagation();
													handleFeatureEdit(event, item);
												}}
											/>
										)}
										{isUserLoggedIn && (
											<OKRButton
												icon={<DeleteIcon />}
												handleClick={(event: any) => {
													event.preventDefault();
													event.stopPropagation();
													setShowDialog({ open: true, event, record: item });
												}}
											/>
										)}
									</ListItem>
								</List>
							</Box>
							<Box id={item?.featureId}></Box>
							<Collapse className='okr-update-content' in={expandedFeatureList.indexOf(item.featureId) !== -1}>
								<Box className='feature-content'>
									{/* <Typography>{item?.description || ''}</Typography> */}
									<Box className='formatted-text' dangerouslySetInnerHTML={{ __html: item?.description || '' }}></Box>
								</Box>
							</Collapse>
						</Box>
					</Box>
				))
			) : allVersionFeatureDetailsPending ? (
				<>
					<Box className='loading-txt-center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				</>
			) : (
				<Box className='version-not-found'>
					{selectedVersion && selectedVersion?.versionId ? (
						<Typography variant='h4'>{t('versionContentNotFound')}</Typography>
					) : (
						<Typography variant='h4'>{t('noVersionFound')}</Typography>
					)}
					<img width='270' alt='NotFoundImg' src={NotFoundImg} />
				</Box>
			)}
			{showDialog?.open && (
				<AlertDialog
					message={
						versionFeatureList && versionFeatureList.length === 1 && selectedVersion?.isPublished
							? t('deleteLastFeatureFromPublishedVersion')
							: t('deletePopup')
					}
					handleCloseModal={handleCloseModal}
					modalOpen={showDialog.open || false}
					isCancel={false}
				/>
			)}
		</Box>
	);
};
