import { postRequest, getRequest, postRequestOKR } from '../config/auth';
import {
	SIGN_IN,
	SSO_LOGIN,
	LOGOUT,
	AZURE_SIGN_IN,
	TENANT_DATA,
	ORG_CURRENT_CYCLE,
	IMPERSONATE_USER,
	TENANT_USER,
	TENANT_ACTION_OKR,
	USER_VALIDATION,
} from '../config/api-url';

export const signInAPI = (data) => postRequest(`${SIGN_IN}`, data);
export const ssoLoginAPI = (data) => postRequest(`${SSO_LOGIN}`, data);
export const logoutAPI = (data) => postRequest(`${LOGOUT}`, data);
export const azureSignInAPI = (data) => getRequest(`${AZURE_SIGN_IN}`, data);
export const tenantAPI = (data) => postRequest(`${TENANT_DATA}`, data);
export const orgCurrentCycleAPI = (data) => getRequest(`${ORG_CURRENT_CYCLE}`, data);
export const impersonateUserAPI = (data) => postRequest(`${IMPERSONATE_USER}`, data);
export const supportUserAPI = (emailId) => getRequest(`${TENANT_USER}/${emailId}`);
export const tenantActionOkrAPI = (data) => postRequestOKR(`${TENANT_ACTION_OKR}`, data);
export const userValidationAPI = (data) => postRequestOKR(`${USER_VALIDATION}`, data);
