import React, { Fragment, useState, useEffect } from 'react';
import { Box, Typography, InputBase } from '@material-ui/core';
import { AddIconSvg, SearchIcon } from '../../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';
import { checkPermission } from '../../../config/rolePermission';
import { createTeams } from '../AdminConstant';

export default function OrgHeader(props) {
	const { filterSearchValue, getPermissionResult } = props;
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const handleChange = (e) => {
		filterSearchValue(e.target.value);
		setSearchValue(e.target.value);
	};

	const searchClicked = (e) => {
		// if (e.key === 'Enter') {
		// 	setSearchValue('');
		// }
	};

	const [scrolled, setScrolled] = useState(false);

	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 0) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	});

	let x = ['navbar'];
	if (scrolled) {
		x.push('scrolled');
	}

	return (
		<Box className={x.join(' ')}>
			<Box className='global-header'>
				<Box className='global-head-info'>
					{props.isOrgDashboard ? (
						''
					) : (
						<Fragment>
							<Box className='global-head-title'>
								<Typography variant='h2'>{t('organizationLabels')}</Typography>
							</Box>
						</Fragment>
					)}
				</Box>
				<Box className='global-head-action'>
					<Box className='search-btn'>
						<Box className='search-icon'>
							<SearchIcon />
						</Box>
						<InputBase
							placeholder={`${t('searchPeople')}...`}
							value={searchValue}
							onChange={(e) => handleChange(e)}
							onKeyDown={(e) => searchClicked(e)}
							inputProps={{ 'aria-label': t('searchPeople') }}
							id='org-src'
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
