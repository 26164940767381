import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { OKRDrawer } from './OKRDrawer';
import WeeklyTrend from './WeeklyTrend';
import { OKRButton } from './OKRButton';
import ReactDateRangePicker from './ReactDateRangePicker';
import chartStaticDetails from './WeeklyTrendStaticData.json';
import moment from 'moment';
import { getDueDays, getMonthDateYear, getMonthDate } from '../../config/utils';
import { Enums } from '../../config/enums';
import { KrTrendContributors } from './KrTrendContributors';
import { getLocalStorageItem } from '../../services/StorageService';

export const WeeklyTrendDetails = (props) => {
	const {
		t,
		openWeeklyTrendDrawer,
		setOpenWeeklyTrendDrawer,
		userSelectedCycle,
		getWeeklyTrendDetails,
		okrMasterData,
	} = props;
	const { confidences } = okrMasterData || {};
	const { okr, type, confidenceValue, rootDetail } = openWeeklyTrendDrawer?.data || {};
	const { employeeId } = rootDetail || {};
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const staticChartColors = ['#EA9246', '#FF9BE9', '#9F9CF5', '#2BEDE1']; // ['#6CF', '#39F', '#06C', '#036']
	const chartDefaultData = [
		{
			name: 'My Confidence',
			lineWidth: 1,
			color: staticChartColors[0],
			marker: {
				enabled: true,
				symbol: 'circle',
			},
			tooltip: {
				useHTML: true,
				headerShape: 'square',
				headerFormat: '<span style="text-align: center"><b>{series.name}</b></span>',
				pointFormat: '<span> on {point.xLabel} is {point.type}</span>',
			},
			yAxis: 1,
			data: [],
		},
		{
			name: 'My Progress',
			lineWidth: 1,
			color: staticChartColors[1],
			marker: {
				enabled: true,
				symbol: 'circle',
			},
			tooltip: {
				headerFormat: '<b>{series.name}</b><br>',
				pointFormat: '{point.xLabel}: {point.y} %',
			},
			data: [],
		},
		{
			name: 'Avg. Contributor Confidence',
			lineWidth: 1,
			color: staticChartColors[2],
			marker: {
				enabled: true,
				symbol: 'circle',
			},
			tooltip: {
				useHTML: true,
				headerFormat: '<span style="text-align: center"><b>{series.name}</b></span>',
				pointFormat: '<span> on {point.xLabel} is {point.type}</span>',
			},
			yAxis: 1,
			data: [],
		},
		{
			name: 'Avg. Contributor Progress',
			lineWidth: 1,
			color: staticChartColors[3],
			marker: {
				enabled: true,
				symbol: 'circle',
			},
			tooltip: {
				headerFormat: '<b>{series.name}</b><br>',
				pointFormat: '{point.xLabel}: {point.y} %',
			},
			data: [],
		},
	];

	const [zoomTypeValue, setZoomTypeValue] = useState(); //'1M'
	const [chartData, setChartData] = useState([]);
	const [categories, setCategories] = useState([]);
	const [chartColors, setChartColors] = useState([]);
	const [selectedDateRange, setSelectedDateRange] = useState(null);
	const [dueDaysDiff, setDueDaysDiff] = useState(0);
	const [weeklyTrendAPIResp, setWeeklyTrendAPIResp] = useState(null);
	const [contributorList, setContributorList] = useState([]);
	const [ownerData, setOwnerData] = useState({});
	const [loader, setLoader] = useState(true);
	const [chartMinMaxDate, setChartMinMaxDate] = useState();

	useEffect(() => {
		setLoader(true);
		getWeeklyTrendData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (zoomTypeValue === '1W') {
			setChartData(generateSeriesData(zoomTypeValue));
			setCategories(generateCategories(zoomTypeValue));
			setChartColors(staticChartColors);
		} else if (zoomTypeValue === '1M') {
			setChartData(generateSeriesData(zoomTypeValue));
			setCategories(generateCategories(zoomTypeValue));
			setChartColors(staticChartColors);
		} else if (zoomTypeValue === '3M') {
			setChartData(generateSeriesData(zoomTypeValue));
			setCategories(generateCategories(zoomTypeValue));
			setChartColors(staticChartColors);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zoomTypeValue]);

	const generateCategories = (zoomValue) => {
		if (zoomValue === '1W') {
			return chartStaticDetails[zoomValue]?.categoriesData;
		} else if (zoomValue === '1M') {
			return chartStaticDetails[zoomValue]?.categoriesData;
		} else if (zoomValue === '3M') {
			return chartStaticDetails[zoomValue]?.categoriesData;
		}
	};
	const generateSeriesData = (zoomValue) => {
		if (zoomValue === '1W') {
			return chartStaticDetails[zoomValue]?.chartData;
		} else if (zoomValue === '1M') {
			return chartStaticDetails[zoomValue]?.chartData;
		} else if (zoomValue === '3M') {
			return chartStaticDetails[zoomValue]?.chartData;
		}
	};

	const handleDrawerClose = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setOpenWeeklyTrendDrawer({ open: false, data: '' });
	};

	const handleZoomClick = (event, zoomType) => {
		event.preventDefault();
		event.stopPropagation();
		setZoomTypeValue(zoomType || '1W');
	};

	const getWeeklyTrendData = () => {
		const requestPayload = `goalKeyId=${okr?.goalKeyId || 0}&cycle=${cycleId || 0}`;
		getWeeklyTrendDetails(requestPayload)
			.then((resp) => {
				if (resp && resp?.status && resp?.status === 200 && resp?.data) {
					const { entity } = resp?.data || {};
					if (entity) {
						const { auditReportDetails, weeklyTrendContributors } = entity || {};
						setWeeklyTrendAPIResp(entity);
						convertAPIRespForChart(auditReportDetails, weeklyTrendContributors);
						convertAPIRespForContributor(weeklyTrendContributors);
						let dateRangeObj = null;
						if (auditReportDetails && auditReportDetails.length > 0) {
							dateRangeObj = {
								start: auditReportDetails[0]?.date || new Date(okr?.startDate || new Date()),
								end: auditReportDetails[auditReportDetails.length - 1]?.date || new Date(okr?.dueDate || new Date()),
							};
						}
						setChartMinMaxDate(dateRangeObj);
					} else {
						console.warn('No Data...', resp);
					}
				}
				setLoader(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const convertAPIRespForChart = (auditReportDetails, weeklyTrendContributors) => {
		const categoriesData = [];
		const seriesOneData = [];
		const seriesTwoData = [];
		const seriesThreeData = [];
		const seriesFourData = [];
		const krOwner =
			weeklyTrendContributors && weeklyTrendContributors.length
				? weeklyTrendContributors.find((item) => item.isOwner === 1)
				: {};

		auditReportDetails &&
			auditReportDetails.length &&
			[...auditReportDetails].forEach((element, indexVal) => {
				const {
					date,
					myConfidenceId,
					averageConfidenceId,
					myConfidence,
					myProgress,
					averageConfidence,
					averageProgress,
				} = element;
				const myConfType =
					confidences && confidences.length
						? confidences.find((item) => item?.confidenceValue === myConfidenceId)
						: { confidenceName: 'Low' };
				const avgConfType =
					confidences && confidences.length
						? confidences.find((item) => item?.confidenceValue === averageConfidenceId)
						: { confidenceName: 'Low' };
				const xCategoryData = date ? getMonthDate(date) : '';
				const xCategory = date ? getMonthDateYear(date) : '';
				categoriesData.push(xCategoryData);
				seriesOneData.push({
					y: myConfidence ? parseInt(myConfidence) : 0,
					xLabel: xCategory || '',
					type: myConfType?.confidenceName || '',
				});
				seriesTwoData.push({
					y: myProgress ? parseInt(myProgress) : 0,
					xLabel: xCategory || '',
					type: '',
				});
				seriesThreeData.push({
					y: averageConfidence ? parseInt(averageConfidence) : 0,
					xLabel: xCategory || '',
					type: avgConfType?.confidenceName || '',
				});
				seriesFourData.push({
					y: averageProgress ? parseInt(averageProgress) : 0,
					xLabel: xCategory || '',
					type: '',
				});
			});
		if (userDetail?.employeeId === employeeId) {
			chartDefaultData.forEach((ele, index) => {
				if (index === 0) {
					// My Confidence
					ele.data = [...seriesOneData];
				}
				if (index === 1) {
					// My Progress
					ele.data = [...seriesTwoData];
				}
				if (index === 2) {
					// Avg. Contributor Confidence
					ele.data = [...seriesThreeData];
				}
				if (index === 3) {
					// Avg. Contributor Progress
					ele.data = [...seriesFourData];
				}
			});
		} else {
			chartDefaultData.forEach((ele, index) => {
				if (index === 0) {
					// My Confidence
					ele.name = `${krOwner?.firstName}'s Confidence`;
					ele.data = [...seriesOneData];
				}
				if (index === 1) {
					// My Progress
					ele.name = `${krOwner?.firstName}'s Progress`;
					ele.data = [...seriesTwoData];
				}
				if (index === 2) {
					// Avg. Contributor Confidence
					ele.data = [...seriesThreeData];
				}
				if (index === 3) {
					// Avg. Contributor Progress
					ele.data = [...seriesFourData];
				}
			});
		}

		setChartData(chartDefaultData || []);
		setCategories(categoriesData || []);
		setChartColors(staticChartColors);
	};

	const convertAPIRespForContributor = (weeklyTrendContributors) => {
		// const weeklyTrendContributors = chartStaticDetails?.APIResp?.entity?.weeklyTrendContributors;
		const onlyContributor =
			weeklyTrendContributors && weeklyTrendContributors.length
				? weeklyTrendContributors.filter((item) => item.isOwner !== 1)
				: [];
		onlyContributor && onlyContributor.length && setContributorList([...onlyContributor]);
		setOwnerData(
			weeklyTrendContributors && weeklyTrendContributors.length
				? weeklyTrendContributors.find((item) => item.isOwner === 1)
				: {}
		);
	};

	const dateChangeHandler = (_range, start, end, _selectionType) => {
		const { auditReportDetails, weeklyTrendContributors } = weeklyTrendAPIResp || {};
		const dateObj = {
			start: start ? getMonthDateYear(start) : '',
			end: end ? getMonthDateYear(end) : '',
			startIndex: 0,
			lastIndex: auditReportDetails.length - 1,
		};
		auditReportDetails &&
			auditReportDetails.length &&
			[...auditReportDetails].forEach((element, indexVal) => {
				const { date } = element;
				const xCategory = date ? getMonthDateYear(date) : '';
				if (xCategory === dateObj.start) {
					dateObj.startIndex = indexVal;
				}
				if (xCategory === dateObj.end) {
					dateObj.lastIndex = indexVal;
				}
			});
		const filterData = auditReportDetails.filter(
			(_item, index) => index >= dateObj.startIndex && index <= dateObj.lastIndex
		);
		convertAPIRespForChart(filterData && filterData.length ? filterData : auditReportDetails, weeklyTrendContributors);
	};

	return (
		<Fragment>
			<OKRDrawer
				open={openWeeklyTrendDrawer?.open || true}
				loader={loader}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel drawer-no-footer'}
				headerTitle={'Key Result Trend'}
				children={
					<Box className='drawer-inner-content'>
						{chartData && chartData.length ? (
							<Box className='drawer-content-panel okr-drawer-form-area kr-weekly-trends-area'>
								{userDetail?.employeeId === employeeId ? (
									<KrTrendContributors
										max={Enums.THREE}
										confidences={confidences}
										contributorList={contributorList}
										ownerData={ownerData}
										dueDate={okr.dueDate}
									/>
								) : (
									<></>
								)}
								<Box className='wt-chart-info'>
									<Typography variant='subtitle1'>{t('wtZoomInfoText')}</Typography>
									{/* <Typography variant='subtitle2'>{t('wtUpdatedOn')}</Typography> */}
								</Box>
								<Box className='weekly-trends-chart-area'>
									<Box className='weekly-trends-chart-top'>
										<Box className='weekly-trends-zoom-filter'>
											<Typography variant='subtitle2' style={{ display: 'none' }}>
												{t('zoomLabel')}:
											</Typography>
											<Box style={{ display: 'none' }}>
												<OKRButton
													className={zoomTypeValue === '1W' ? 'active' : ''}
													text={'1W'}
													title={'1 Week'}
													handleClick={(event) => handleZoomClick(event, '1W')}
												/>
												<OKRButton
													className={zoomTypeValue === '1M' ? 'active' : ''}
													text={'1M'}
													title={'1 Month'}
													handleClick={(event) => handleZoomClick(event, '1M')}
												/>
												<OKRButton
													className={zoomTypeValue === '3M' ? 'active' : ''}
													text={'3M'}
													title={'3 Months'}
													handleClick={(event) => handleZoomClick(event, '3M')}
												/>
											</Box>
										</Box>

										<Box className='weekly-trends-date-filter'>
											{chartMinMaxDate && chartMinMaxDate.start && chartMinMaxDate.end ? (
												<ReactDateRangePicker
													type={'WeeklyTrend'}
													startDateValue={chartMinMaxDate.start}
													endDateValue={chartMinMaxDate.end}
													minimumDate={new Date(chartMinMaxDate.start || new Date())}
													maximumDate={new Date(chartMinMaxDate.end || new Date())}
													handleDateSelection={(range, start, end, selectionType, isCycleChanged) => {
														if (selectionType === 'range') {
															const startDt = moment(start);
															const endDt = moment(end);
															const daysDiff = getDueDays(startDt, endDt, props.userSelectedCycle);
															setDueDaysDiff(daysDiff);
														}
														setSelectedDateRange(range);
														dateChangeHandler(range, start, end, selectionType);
													}}
													bottomChildComponent={<></>}
													allCycleDetails={[]}
													dueDaysDiff={dueDaysDiff}
													setDueDaysDiff={setDueDaysDiff}
													isCurrentCycle={userSelectedCycle}
													selectedDateRange={selectedDateRange}
													setSelectedDateRange={setSelectedDateRange}
													selectionType={'range'}
													numberOfCalendars={Enums.TWO}
													isDateRangeTextFieldVisible={false}
													isOnlyDueDateVisible={false}
													isCycleVisible={false}
													isEndOfMonthVisible={false}
												/>
											) : (
												<></>
											)}
										</Box>
									</Box>

									{chartData && chartData.length ? (
										<WeeklyTrend categories={categories} chartData={chartData} chartColors={chartColors} />
									) : (
										<></>
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
				}
				moreButton={false}
				handleDrawerClose={(event) => handleDrawerClose(event)}
				handleSaveClick={(event) => handleDrawerClose(event)}
				handleCancelClick={(event) => handleDrawerClose(event)}
				hideDefaultButton={true}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
		</Fragment>
	);
};
