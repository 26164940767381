import * as React from 'react';
import { Avatar, Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddIconSvg } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../../config/svg/Action';
// import { RichTextWithTagging } from '../../Common/cfr/RichTextWithTagging';
import { useState, useEffect } from 'react';
import SfEditor from '../../Admin/CommonComponent/SfEditor/SfEditor';
import { toolbarTaggingSettings } from '../../Admin/CommonComponent/SfEditor/SfEditorEnum';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../config/utils';

export const AddText: React.FC<any> = (props) => {
	const {
		showAvatar,
		avatarUserDetails,
		placeholderText,
		handleSave,
		tabSelected,
		showEditor,
		setShowEditor,
		handleCancel,
		setEditorText,
		editorText = '',
		errorMessage,
		showHelpText,
		setImageList,
		imageList,
		isEditMode,
		maxLength,
		disableBtn,
		setDisableBtn,
		uploadCase,
		isAdded,
		isTaskEdited,
		setShowDialog,
		setIsTaskEdited,
		handleEnterClick,
	} = props;
	const { t } = useTranslation();
	const [errorText, setErrorText] = useState<any>('');
	useEffect(() => {
		if (setShowEditor) {
			setShowEditor(false);
		}
	}, [tabSelected]);

	useEffect(() => {
		setErrorText('');
	}, [showEditor]);
	return (
		<Box className={`add-cfr-text ${showEditor ? 'editor-visible' : ''}`}>
			{!showEditor && (
				<>
					{showAvatar && (
						<>
							{avatarUserDetails.imagePath ? (
								<Avatar src={avatarUserDetails.imagePath} />
							) : (
								<Avatar
									style={{
										backgroundColor: getRandomBackgroundColor(avatarUserDetails.backGroundColorCode),
										color: getRandomColor(avatarUserDetails.colorCode),
									}}
								>
									{getUserName(avatarUserDetails)}
								</Avatar>
							)}
						</>
					)}
					<TextField
						id='cfr-add-field'
						value={editorText}
						fullWidth
						placeholder={placeholderText}
						onChange={(e) => setEditorText(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<AddIconSvg />
								</InputAdornment>
							),
						}}
						onClick={(e) => {
							if (isAdded === true && isTaskEdited === true) {
								setShowDialog(true);
								let customBtn = document.getElementById('cfr-add-field');
								customBtn?.blur();
							} else {
								setShowEditor(true);
							}
						}}
						inputProps={{ maxLength: maxLength }}
						onKeyDown={handleEnterClick}
					/>
				</>
			)}

			{showEditor && (
				<>
					{showAvatar && (
						<>
							{avatarUserDetails.imagePath ? (
								<Avatar src={avatarUserDetails.imagePath} />
							) : (
								<Avatar
									style={{
										backgroundColor: getRandomBackgroundColor(avatarUserDetails.backGroundColorCode),
										color: getRandomColor(avatarUserDetails.colorCode),
									}}
								>
									{getUserName(avatarUserDetails)}
								</Avatar>
							)}
						</>
					)}
					<Box className='editior-pane'>
						<Box className='cfr-control-section' id='rteImage'>
							<SfEditor
								{...props}
								enableTagging={true}
								focusOnLoad={true}
								toolbarSettings={toolbarTaggingSettings}
								placeholder={placeholderText}
								handleEditorChange={(value: string) => {
									if (value && (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>')) {
										setIsTaskEdited(false);
									} else {
										setIsTaskEdited(true);
									}
									setEditorText(value);
									setErrorText('');
								}}
								value={editorText}
								setImageList={setImageList}
								imageList={imageList}
								uploadCase={uploadCase}
							/>
						</Box>
					</Box>
				</>
			)}

			<Box className='add-cfr-actions'>
				<Box className='help-text'>
					{showHelpText && showEditor && !isEditMode && (
						<Typography className='notes-help-text'>{t('notesHelpText')}</Typography>
					)}
					{errorText !== '' ? <Typography className='error-text'>{errorText}</Typography> : <></>}
				</Box>

				{((showEditor && !editorText) || editorText.trim() !== '') && (
					<Box className='btn-wrap'>
						<OKRButton
							className='add-cfr-cancel'
							id={'add-cfr-cancel'}
							icon={<CancelIcon />}
							handleClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								handleCancel();
								setShowEditor(false);
								setErrorText('');
								setIsTaskEdited(false);
							}}
						/>
						<OKRButton
							className={`add-cfr-save ${(!editorText || editorText.trim() === '') && 'btn-disabled'}`}
							id={'add-cfr-save'}
							icon={<TickIcon />}
							disabled={showEditor && disableBtn === true}
							handleClick={(e: any) => {
								if (
									editorText.trim() === '' ||
									editorText.trim() === '<p></p>' ||
									editorText.trim() === '<p><br></p>'
								) {
									setErrorText(errorMessage || t('fieldRequired'));
								} else {
									handleSave(e);
									setDisableBtn && setDisableBtn(true);
								}
							}}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};
