import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import krFlag from '../../../images/krFlag.svg';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import { Enums } from '../../../config/enums';
import { getTeamFilterQueryString, getUserDetails } from '../../../config/utils';
import { OkrPopperMenu } from '../../OkrAction/OkrPopperMenu';
import { getDifferenceBetweenDates } from '../../../config/utils';
import { OkrProgressBar } from '../../OkrAction/OkrProgressBar';
import moment from 'moment';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import { ListTooltip } from '../../OkrAction/ListTooltip';
import { goalDetailObject, keyDetailObject } from '../../Common/OkrUtil';

export interface RelaxedCardProps {}

const KrResultItemCard: React.FC<any> = (props: any) => {
	const { t, currentUser, data, handleDeleteOkr, setOpenDrawer, setModalProps, krItem, teamOkrFilterData } = props;
	const okr = data.data;

	const [showKr, setShowKr] = useState(false);
	const [modalPropsKR, setmodalPropsKR] = useState<any>({ open: false, type: '', message: '' });

	const [objectiveDetail, setObjectiveDetail] = useState<any>({
		...goalDetailObject,
		myGoalsDetails: [{ ...keyDetailObject }],
	});
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);

	const defaultGaolDateError = { error: '', helperText: '', type: '' };
	const [goalsAttribute, setGoalsAttribute] = useState<any>({});
	const [goalAttrDateError, setGaolAttrDateError] = useState<any>(defaultGaolDateError);
	const [snackbarProps, setSnackbarProps] = useState<any>({});
	const [keyAttribute, setKeyAttribute] = useState<any>({});
	const [keyRes, setKeyRes] = useState<boolean>(false);
	const [dataUpdated, setdataUpdated] = useState<any>({});

	const [krLoader, setKrLoader] = useState<Boolean>(false);
	const [keyData, setKeyData] = useState<any>({});
	const [apiCallComplete, setApiCallComplete] = useState<boolean>(false);
	const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	const year = getLocalStorageItem('currentYear') || null;
	const selectedYear = getLocalStorageItem('year') || year;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const loggedInUserDetail = getUserDetails();

	const handleKRClick = async (krObj: any, okrObj: any) => {
		Promise.allSettled([props.getOkrDetail(okrObj.objectiveId), props.getKrDetail(krObj.krId)]).then(
			(response: any) => {
				if (response) {
					let getOKRDetail =
						Boolean(response) && response.length && response[0] && response[0].value
							? response[0].value.data.entity
							: '';
					let getKRDetail =
						Boolean(response) && response.length && response[1] && response[1].value
							? response[1].value.data.entity
							: '';
					if (getOKRDetail && getKRDetail) {
						let isContributor = false;
						if (getKRDetail && getKRDetail.contributors && getKRDetail.contributors.length > 0) {
							getKRDetail.contributors.forEach((item: any) => {
								if (item.employeeId === userDetail?.employeeId) {
									isContributor = true;
								}
							});
						}
						getKRDetail.isContributorOnly = getOKRDetail.owner !== userDetail?.employeeId && isContributor;
						let obj = {
							kr: getKRDetail,
							okr: getOKRDetail,
							actionType: 'editKR',
							goalDetail: {},
							showAttributeDrawer: true,
						};
						getKRDetail = {
							...getKRDetail,
							contributors: getKRDetail.contributors.filter(
								(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
							),
						};
						setKeyRes(true);
						setKeyAttribute(getKRDetail);
						setdataUpdated(obj);
					}
				}
			}
		);

		// let okrs = JSON.parse(JSON.stringify(listOkrResult));
		// let currentORK =
		// 	okrs &&
		// 	okrs.myGoalOkrResponses &&
		// 	okrs.myGoalOkrResponses.filter((item: any) => item.goalObjectiveId === okrObj.objectiveId);

		// if (currentORK) {
		// 	let getKR = currentORK[0].myGoalsDetails.filter((kr: any) => kr.goalKeyId === krObj.krId);
		// 	let isContributor = false;
		// 	if (getKR && getKR.length > 0 && getKR[0].contributors && getKR[0].contributors.length > 0) {
		// 		getKR[0].contributors.forEach((item: any) => {
		// 			if (item.employeeId === userDetail?.employeeId) {
		// 				isContributor = true;
		// 			}
		// 		});
		// 	}
		// 	getKR[0].isContributorOnly = currentORK[0].owner !== userDetail?.employeeId && isContributor;
		// 	let obj = {
		// 		kr: getKR[0],
		// 		okr: currentORK[0],
		// 		actionType: 'editKR',
		// 		goalDetail: {},
		// 		showAttributeDrawer: true,
		// 	};
		// 	getKR[0] = {
		// 		...getKR[0],
		// 		contributors: getKR[0].contributors.filter(
		// 			(item: any) => !item.isSource && item.employeeId !== userDetail.employeeId
		// 		),
		// 	};
		// 	setKeyRes(true);
		// 	setKeyAttribute(getKR[0]);
		// 	setdataUpdated(obj);
		// }
	};

	const validateAddKRForm = (formData: any) => {
		if (formData?.metricId === Enums.ONE || formData?.metricId === Enums.TWO || formData?.metricId === Enums.THREE) {
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				return false;
			}
		}
		if (formData.keyDescription === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyDescription',
			});
			return false;
		} else if (formData.keyDescription.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyDescription',
			});
			return false;
		}
		return true;
	};

	const updateFormDetail = (item: any, key: any, value: any, isKeyData: Boolean, keyAttribute: any) => {
		const { myGoalsDetails } = objectiveDetail;
		let updatedDetail = [];
		if (isKeyData) {
			updatedDetail = myGoalsDetails.map((element: any) => {
				if (element.id === item.id) {
					element.startDate = keyAttribute.startDate ? keyAttribute.startDate : element.startDate;
					element.dueDate = keyAttribute.dueDate ? keyAttribute.dueDate : element.dueDate;
					element.startValue = keyAttribute.startValue ? keyAttribute.startValue : element.startValue;
					element.targetValue = keyAttribute.targetValue ? keyAttribute.targetValue : element.targetValue;
					element.metricId = keyAttribute.metricId ? keyAttribute.metricId : element.metricId;
					element.currencyId = keyAttribute.currencyId ? keyAttribute.currencyId : element.currencyId;
					element.showCurrentTarget = keyAttribute.showCurrentTarget
						? keyAttribute.showCurrentTarget
						: element.showCurrentTarget;
					element.metricName = keyAttribute.metricName ? keyAttribute.metricName : element.metricName;
					element.contributors = keyAttribute.contributors ? keyAttribute.contributors : element.contributors;
					element.keyNotes = keyAttribute.keyNotes ? keyAttribute.keyNotes : element.keyNotes;
					element.keyDescription = keyAttribute.keyDescription ? keyAttribute.keyDescription : element.keyDescription;
					element.isNewKey = false;
				}
				return element;
			});
		} else {
			updatedDetail = myGoalsDetails.map((element: any) => {
				if (element.id === item.id) {
					if (key === 'keyDescription' || key === 'dueDate') {
						element.isTyped = true;
						element.isNewKey = false;
					}
					element[key] = value;
				} else {
					element.isTyped = false;
				}
				return element;
			});
		}

		setObjectiveDetail({ ...objectiveDetail, isTyped: false, myGoalsDetails: updatedDetail });
		// if (key === 'keyDescription' || key === 'dueDate') {
		// 	getCallAutoSave({ ...objectiveDetail, isTyped: false, myGoalsDetails: updatedDetail });
		// }
	};

	const handleDrawerClose = async (_event: any, type: String) => {
		// setShowKr(false);
		setGaolAttrDateError(defaultGaolDateError);
		if (type === 'save') {
			setSnackbarProps({});
			const datesDifferenceInDays = moment(
				new Date(keyAttribute.startDate ? keyAttribute.startDate : keyData?.startDate || new Date())
			).isAfter(new Date(keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate || new Date()), 'days');
			if (Boolean(datesDifferenceInDays)) {
				setGaolAttrDateError({
					error: t('startDateDueDateError'),
					helperText: t('startDateDueDateError'),
					type: 'dueDate',
				});
			} else {
				const { showAttributeDrawer, actionType } = dataUpdated || {};
				if (showAttributeDrawer && actionType === 'editKR') {
					let updateContributors = { ...keyAttribute };
					let contributors = updateContributors.contributors
						? updateContributors.contributors.map((item: any) => {
								return {
									...item,
									keyResult: keyAttribute?.keyDescription,
								};
						  })
						: [];
					updateContributors.contributors = contributors;
					const data = { ...updateContributors, year };
					if (validateAddKRForm(data)) {
						setApiCallComplete(true);
						/* 
						// needed when to reload from here
						setKrLoader(true);
						const alignmentMapAction = {
							actionType: 'UPDATE_PROGRESS',
							status: 'RELOAD',
							value: [],
						};
						props.setAlignmentMapAction(alignmentMapAction); */
						const response = await props.updateKrAttributes(data);
						if (response && response.data && response.data.status === 200) {
							setApiCallComplete(false);
							setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
							// setKrLoader(false);
							// setGoalDetail({});
							// setdataUpdated({});
							// setShowKr(false);
							//props.fetchOkr();
							if (props.tabValue === 0) {
								props.getAlignmentData();
							} else if (props.tabValue === 1) {
								const teamFilter = getTeamFilterQueryString(teamOkrFilterData);
								const data = `empId=${
									userDetail && userDetail.employeeId
								}&cycle=${cycleId}&year=${selectedYear}${teamFilter}`;
								props.fetchTeamsAlignmentMap(data);
							}
							/* 
							const alignmentMapAction = {
								actionType: 'UPDATE_PROGRESS',
								status: '',
								value: [],
							};
							props.setAlignmentMapAction(alignmentMapAction); */
						} else {
							setSnackbarProps({ show: true, message: response?.data?.messageList, variant: 'error' });
							setKrLoader(false);
						}
						setKRAttrDateError(defaultKRDateError);
						// setOpenDrawer(false);
						// setKeyAttribute({});
					}
				} else {
					if (validateAddKRForm(keyAttribute)) {
						if (showAttributeDrawer && actionType === 'addNewKr') {
							setdataUpdated({ ...dataUpdated, kr: { ...dataUpdated.kr, ...keyAttribute }, saved: true });
							setKRAttrDateError(defaultKRDateError);
							setOpenDrawer(false);
							setKeyAttribute({});
							setShowKr(false);
						} else {
							updateFormDetail(keyData, '', '', true, keyAttribute);
							setKRAttrDateError(defaultKRDateError);
							setOpenDrawer(false);
							setKeyAttribute({});
							setShowKr(false);
						}
					}
				}
			}
		} else if (type === 'close') {
			if (!isKRAttributeUpdated()) {
				setmodalPropsKR({
					open: true,
					type: 'editKR',
					message: t('unSavedItemAlert'),
					module: 'user',
					details: { id: '' },
				});
			} else {
				setKeyRes(false);
				setGoalsAttribute({});
				setOpenDrawer(false);
			}
		}
	};

	const isKRAttributeUpdated = () => {
		if ((dataUpdated && dataUpdated.actionType === 'editKR') || dataUpdated.actionType === 'addNewKr') {
			const keyDetailsRes = dataUpdated?.okr.assignmentTypeId === 1 ? dataUpdated.okr : dataUpdated.kr;
			return checkFormValues(keyDetailsRes);
		} else {
			return checkFormValues(keyData);
		}
	};

	const checkFormValues = (keyData: any) => {
		return (keyAttribute.startDate && !getDifferenceBetweenDates(keyData.startDate, keyAttribute.startDate)) ||
			(keyAttribute.dueDate && !getDifferenceBetweenDates(keyAttribute.dueDate, keyData.dueDate)) ||
			(keyAttribute.metricId && keyAttribute.metricId !== keyData.metricId) ||
			(keyAttribute.currencyId && keyAttribute.currencyId !== keyData.currencyId) ||
			(keyAttribute.startValue && keyAttribute.startValue !== keyData.startValue) ||
			(keyAttribute.targetValue && keyAttribute.targetValue !== keyData.targetValue) ||
			(keyAttribute?.keyNotes && keyAttribute?.keyNotes !== keyData.keyNotes) ||
			(keyAttribute?.weightId && keyAttribute?.weightId !== keyData.weightId) ||
			(keyAttribute?.keyDescription && keyAttribute?.keyDescription !== keyData.keyDescription)
			? false
			: true;
	};

	const handleKebabClick = (kr: any, type: any) => {
		// let krDlt = okr.filter((item:any ) => item.goalObjectiveId)
		if (type === 2) {
			setKeyData(okr);
		} else if (type === 4) {
			setKeyData(kr);
		}
	};

	useEffect(() => {
		setGoalsAttribute(okr);
		// setGoalDetail({ ...goalDetail, actionType: 'editGoal', showAttributeDrawer: true });
	}, []);

	const handleAcceptDeclineClick = (open: Boolean, okr: any, kr: any, type: String, KRType: String) => {
		if (okr.objectiveId > 0) {
			Promise.allSettled([props.getOkrDetail(okr.objectiveId), props.getKrDetail(kr.krId)]).then((response: any) => {
				if (response) {
					let getOKRDetail =
						Boolean(response) && response.length && response[0] && response[0].value ? response[0].value : '';
					let getKRDetail =
						Boolean(response) && response.length && response[1] && response[1].value ? response[1].value : '';

					let isAlreadyAlignedContributor = okr.okrViewKeyResults.filter((kr: any) => {
						let hasContributor = kr?.okrViewKeyContributors.filter(
							(items: any) =>
								items.employeeId === loggedInUserDetail.employeeId && items.krStatusId === Enums.KR_STATUS_ACCEPTED
						);
						return hasContributor.length > 0;
					});
					if (getOKRDetail.status === 200 && getKRDetail.status === 200) {
						if (getKRDetail.data.entity.krStatusId === Enums.KR_STATUS_PENDING) {
							setOpenDrawer({
								open: true,
								okr: {
									...getOKRDetail.data.entity,
									okrContributors: isAlreadyAlignedContributor,
								},
								kr: getKRDetail.data.entity,
								type: type,
								KRType: 'WithParent',
							});
						} else {
							setModalProps({
								open: true,
								type: 'AcceptKR',
								message: t('actionAlreadyTaken'),
								module: 'user',
								isCancel: true,
							});
						}
					}
				}
			});
		}
	};

	const handleCloseModal = async (event: any, type: Number) => {
		if (type === 1) {
			setmodalPropsKR({ open: false, type: '', message: '', module: '' });
			setKeyRes(false);
		} else {
			setmodalPropsKR({ open: false, type: '', message: '', module: '' });
		}
	};
	return (
		<React.Fragment>
			{krItem && (
				<Box className='kr-item' id={`kr_${krItem?.krUniqueId}`} key={`kr_${krItem?.krUniqueId}`}>
					{/* {okr?.parent !== '' && (
								<IconButton className='connect-icon connect-icon-link'>
									<LinkIcon />
								</IconButton>
							)} */}
					<Box className='kr-item-col1'>
						<img src={krFlag} alt='kr flag' />
						<ListTooltip
							objName={krItem?.krName || ''}
							description={krItem?.keyNotes || ''}
							alignmentMapDescription={true}
							characterCount={30}
							titleClassName={'kr-details'}
							currentUser={currentUser}
							diagramInstance={props.diagramInstance}
						/>
					</Box>
					<Box className='kr-item-col2'>
						{!krItem.isVirtualLink && !krItem.isParentVirtualLink && (
							<UserAvatarGroup
								{...props}
								loginUserAvatar={
									krItem.okrViewKeyContributors && krItem.okrViewKeyContributors.length
										? krItem.okrViewKeyContributors.find((itemKR: any) => itemKR.isSource)
										: krItem
								}
								contributorDetails={
									krItem.okrViewKeyContributors && krItem.okrViewKeyContributors.length
										? krItem.okrViewKeyContributors.filter((itemKR: any) => !itemKR.isSource)
										: []
								}
								addButtonClassName='add-btn'
								avatarClassName=''
								isButtonVisible={false}
								max={2}
								currentUser={currentUser}
								contributorDetailsType={true}
								alignmentMapTab={true}
								contributorClassName={
									'contributor-details-popover alignmentmap-contributorPopup contributorPopup-krLevel'
								}
								showTarget={true}
								popoverNoModifiers={true}
								actionLevel={krItem?.actionLevel}
							/>
						)}
						<OkrProgressBar
							parentOkr={okr}
							okr={krItem}
							type={'krAlignmentMap'}
							t={t}
							updateProgress={props.updateProgress}
							showEditLoader={props.showEditLoader}
							currentUser={currentUser}
							okrMasterData={props.okrMasterData}
							tabValue={props.tabValue}
							diagramInstance={props.diagramInstance}
							isScoreLocked={props.alignResult.isScoreLocked}
							userSelectedCycle={props.userSelectedCycle}
						/>

						{krItem.krStatusId === Enums.KR_STATUS_PENDING ? (
							<OkrPopperMenu
								{...props}
								poperClassName={'alignment-popper-menu popper-menu-list okr-list-popper-menu'}
								okr={okr}
								kr={krItem}
								type={4}
								t={t}
								handleDeleteOkr={handleDeleteOkr}
								KRType={'WithParent'}
								alignmentMap={true}
								handleAcceptDeclineClick={handleAcceptDeclineClick}
								currentUser={currentUser}
								//poperPlacement={'bottom-start'}
								poperContainer={false}
								handleKRClick={handleKRClick}
								keyAttribute={keyAttribute}
								setKeyAttribute={setKeyAttribute}
								keyRes={keyRes}
								// setKeyRes={setKeyRes}
								dataUpdated={dataUpdated}
								setdataUpdated={setdataUpdated}
								goalDetail={dataUpdated}
								setGoalDetail={setdataUpdated}
								handleDrawerClose={handleDrawerClose}
								keyData={dataUpdated.kr}
								handleKebabClick={handleKebabClick}
								loader={krLoader}
								showKr={showKr}
								diagramInstance={props.diagramInstance}
							/>
						) : (
							<OkrPopperMenu
								{...props}
								poperClassName={'alignment-popper-menu popper-menu-list okr-list-popper-menu'}
								okr={okr}
								kr={krItem}
								type={2}
								t={t}
								handleDeleteOkr={handleDeleteOkr}
								alignmentMap={true}
								currentUser={currentUser}
								//poperPlacement={'bottom'}
								poperContainer={false}
								handleKRClick={handleKRClick}
								keyAttribute={keyAttribute}
								setKeyAttribute={setKeyAttribute}
								keyRes={keyRes}
								// setKeyRes={setKeyRes}
								showKr={showKr}
								dataUpdated={dataUpdated}
								setdataUpdated={setdataUpdated}
								goalDetail={dataUpdated}
								setGoalDetail={setdataUpdated}
								handleDrawerClose={handleDrawerClose}
								keyData={dataUpdated.kr}
								loader={krLoader}
								handleKebabClick={handleKebabClick}
								diagramInstance={props.diagramInstance}
								apiCallComplete={apiCallComplete}
								setApiCallComplete={setApiCallComplete}
							/>
						)}
					</Box>
				</Box>
			)}
			{modalPropsKR.open && (
				<AlertDialog
					module={modalPropsKR.module ? modalPropsKR.module : 'user'}
					message={modalPropsKR.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalPropsKR.open}
				/>
			)}
		</React.Fragment>
	);
};

export default KrResultItemCard;
