export const BaseUrl = process.env.REACT_APP_API_BASEURL;
export const BaseBlobUrl = process.env.REACT_APP_BLOB_BASEURL;
export const apiEndpointSuffix =
	process.env.REACT_APP_API_END_POINT_SUFFIX === '-' ? '' : process.env.REACT_APP_API_END_POINT_SUFFIX || '';

export const TrialEnvApiUrl = 'https://unlockokr-apim-trial.azure-api.net';
/** notification */
export const NOTIFICATION_BASE_URL = `${BaseUrl}/notification${apiEndpointSuffix}/api/v2/OkrNotifications`;

/** user management  */
export const OkrUserManagement = `${BaseUrl}/user${apiEndpointSuffix}`;
export const OkrAuth = `${BaseUrl}/user${apiEndpointSuffix}`;
export const UserManagement = `${BaseUrl}/admin${apiEndpointSuffix}/api`;
export const TeamManagement = `${BaseUrl}/user${apiEndpointSuffix}`;

/** okr management  */
export const OkrManagement = `${BaseUrl}/okr${apiEndpointSuffix}/api`;

/** report  */
export const Reports = 'https://okr-dev-reporting.compunnel.com/api/';

/** feedback */
export const FEEDBACK_BASE_URL = `${BaseUrl}/feedback${apiEndpointSuffix}/api`;

/** alignment */
export const AlignmentMap = `${BaseUrl}/okr${apiEndpointSuffix}/api`;

/** tenant */
export const Tenant = `${BaseUrl}/tenant${apiEndpointSuffix}`;

/** SSO */
export const SSODomain = `${BaseUrl}/ssodomain${apiEndpointSuffix}`;

/** Support */
export const Support = `${BaseUrl}/tenant${apiEndpointSuffix}`;

/**Guided Tour */
export const GuideTour = `${BaseUrl}/guidedtour${apiEndpointSuffix}/api/GuidedTour`;

/**Onboarding */
export const Onboarding = `${BaseUrl}/guidedtour${apiEndpointSuffix}/api/OnBoarding`;

/** Admin */
export const AdminRoles = `${BaseUrl}/user${apiEndpointSuffix}`;

/**Bilk CSV Template url */
export const BulkCsvTemplateUrl = `${BaseBlobUrl}/common/BulkUploadEmployeeTemplate.csv`;

/**Bulk Emails CSV Template url */
export const BulkEmailCsvTemplateUrl = `${BaseBlobUrl}/common/BulkEmailUpdateTemplate.csv`;

/** team management  */
export const OkrTeamManagement = `${BaseUrl}/user${apiEndpointSuffix}`;

/** okr reports  */
export const OkrReports = `${BaseUrl}/report${apiEndpointSuffix}`;

/** Task */
export const OkrTask = `${BaseUrl}/task${apiEndpointSuffix}`;

/** Notes */
export const okrNote = `${BaseUrl}/conversation${apiEndpointSuffix}`;

/** Conversations */
export const okrConversation = `${BaseUrl}/conversation${apiEndpointSuffix}`;

/** Checkins */
export const okrCheckins = `${BaseUrl}/conversation${apiEndpointSuffix}`;

// Trial Env Api
export const OkrTrialEnv = `${BaseUrl}/okrtrial${apiEndpointSuffix}`;

// System Email
export const SystemEmail = `${BaseUrl}/tenant${apiEndpointSuffix}`;
