import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { AdminTabPanel } from './AdminListingTabs';
import Organizations from './Organizations';
import { SubHeader } from './SubHeader';
import { RolesPermissionList } from './AddRolePermission';
import { checkPermission } from '../../config/rolePermission';
import { viewOrganizationManagementPage, viewRoleManagement, viewUserManagementPage } from './AdminConstant';
import { User } from './User';

export const Admin: React.FC<any> = (props: any) => {
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const [openRolePermissionDrawer, setOpenRolePermissionDrawer] = useState<boolean>(false);
	const [openCreateUserDrawer, setOpenCreateUserDrawer] = useState<boolean>(false);
	const [openAddTeamDrawer, setOpenAddTeamDrawer] = useState<boolean>(false);

	useEffect(() => {
		const tabValue = checkPermission(props.getPermissionResult?.employeePermissions, viewUserManagementPage)
			? 0
			: checkPermission(props.getPermissionResult?.employeePermissions, viewRoleManagement)
			? 1
			: checkPermission(props.getPermissionResult?.employeePermissions, viewOrganizationManagementPage)
			? 2
			: 3;
		setTabSelected(tabValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.getPermissionResult?.employeePermissions]);

	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
		props.refetchUserDetails();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabSelected]);
	useEffect(() => {
		if (props.currentRoute === '/admin') {
			document.body.classList.add('admin-body-class');

			document.body.classList.remove('user-body-class');
		}
	}, [props.currentRoute]);
	/**
	 *
	 * @param event HTMLEvent
	 * @param newValue number
	 */
	const handleMenuClick = (e: any, type: any) => {
		if (type === 'addNewUsers') {
			setOpenCreateUserDrawer(!openCreateUserDrawer);
		} else if (type === 'addNewTeam') {
			setOpenAddTeamDrawer(!openAddTeamDrawer);
		} else {
			setOpenRolePermissionDrawer((prevState) => !prevState);
		}
	};

	return (
		<Fragment>
			<SubHeader
				handleTabChange={handleTabChange}
				handleMenuClick={handleMenuClick}
				tabSelected={tabSelected}
				openCreateUserDrawer={openCreateUserDrawer}
				{...props}
			/>
			<Box className='page-content-wrapper'>
				<AdminTabPanel value={tabSelected} index={0} className='admin-user-tab'>
					<User
						{...props}
						tabSelected={tabSelected}
						addUserClick={handleMenuClick}
						openCreateUserDrawer={openCreateUserDrawer}
						setOpenCreateUserDrawer={setOpenCreateUserDrawer}
					/>
				</AdminTabPanel>
				<AdminTabPanel value={tabSelected} index={1} className='admin-role-tab'>
					<RolesPermissionList
						{...props}
						tabSelected={tabSelected}
						openRolePermissionDrawer={openRolePermissionDrawer}
						handleMenuClick={handleMenuClick}
					/>
				</AdminTabPanel>
				<AdminTabPanel value={tabSelected} index={2} className='admin-org-tab'>
					<Organizations
						{...props}
						tabSelected={tabSelected}
						handleMenuClick={handleMenuClick}
						openAddTeamDrawer={openAddTeamDrawer}
					/>
				</AdminTabPanel>

				{openRolePermissionDrawer && (tabSelected === 0 || tabSelected === 2) && (
					<RolesPermissionList
						{...props}
						tabSelected={tabSelected}
						openRolePermissionDrawer={openRolePermissionDrawer}
						handleMenuClick={handleMenuClick}
					/>
				)}

				{openCreateUserDrawer && (tabSelected === 1 || tabSelected === 2) && (
					<User
						{...props}
						tabSelected={tabSelected}
						addUserClick={handleMenuClick}
						openCreateUserDrawer={openCreateUserDrawer}
					/>
				)}
				{openAddTeamDrawer && (tabSelected === 1 || tabSelected === 0) && (
					<Organizations
						{...props}
						tabSelected={tabSelected}
						handleMenuClick={handleMenuClick}
						openAddTeamDrawer={openAddTeamDrawer}
					/>
				)}
			</Box>
		</Fragment>
	);
};
