import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
    showNotification: false,
    updateProgress: null,
    updatedOkr: {},
    showRefreshOptionSnackBar: false,
    isCalendarOpen: false,
    appDrawerInfo: {
        showRefreshOption: false,
        isDrawerOpened: false,
        isNewObjectiveFormOpened: false,
        isProgressPopperOpened: false,
        isKebabMenuOpened: false,
        isOKREdit: false,
        isNewKRAdded: false,
        isFilterApplied: false,
    },
};

export default function signalRReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionTypes.HIGHLIGHT_NOTIFICATION:
            return {
                ...state,
                showNotification: action.payload,
            };
        case actionTypes.SIGNALR_UPDATE_PROGRESS:
            if (Object.values(state.appDrawerInfo).some((key) => key === true)) {
                return {
                    ...state,
                    showRefreshOptionSnackBar: true,
                };
            } else {
                return {
                    ...state,
                    updateProgress: action.payload,
                    showRefreshOptionSnackBar: false,
                };
            }
        case actionTypes.SIGNALR_OKR_UPDATED:
            return {
                ...state,
                updatedOkr: action.payload,
            };
        case actionTypes.IS_DRAWER_DIALOG_OPENED:
            return {
                ...state,
                appDrawerInfo: action.payload,
            };
        case actionTypes.SHOW_REFRESH_SNACKBAR:
            return {
                ...state,
                showRefreshOptionSnackBar: action.payload,
            };
        case actionTypes.CALENDAR_OPEN_STATE:
            return {
                ...state,
                isCalendarOpen: action.payload,
            };
        case actionTypes.IS_NON_DASHBOARD_UPDATED:
            return {
                ...state,
                showRefreshOptionSnackBar: action.payload,
            };
        default:
            return state;
    }
};