import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, FormControl, Input, InputAdornment, IconButton, Button, FormHelperText } from '@material-ui/core';
import Logo from '../../../images/okr-logo.svg';
import { VisibilityOff, Visibility } from '../../../config/svg/ProfileSvg';
import { Link } from 'react-router-dom';
import CatWalkImage from '../../../images/login5_catwalk.svg';
import { useSnackbar } from 'notistack';
import { getQuarterStartEnd } from '../../../config/utils';
import { SECRET_LOGIN } from '../../../config/app-url';
import '../../../styles/pages/login/login.scss';
import { clearLocalStorage } from '../../../services/StorageService';
import { ClientLogo } from '../../Common/ClientLogo';
import { AnimatedIcon } from '../../Common/AnimatedIcon';

export default function ResetPassword(props) {
	const [isFormSubmit, setIsFormSubmit] = useState(false);
	const [isTokenExpire, setIsTokenExpire] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		let queryString = props.location.search.split('?id=');
		let id = queryString[1] ? queryString[1] : '';
		if (id !== '') {
			clearLocalStorage();
			localStorage.setItem('tokenId', JSON.stringify(id));
		}
	}, [props.location.search]);

	const { enqueueSnackbar } = useSnackbar();
	let { currentYear } = getQuarterStartEnd();

	const handleClickShowPassword = () => {
		setFormData({ ...formData, showPassword: !formData.showPassword });
	};
	const handleClickShowRePassword = () => {
		setFormData({ ...formData, showRePassword: !formData.showRePassword });
	};

	const [formData, setFormData] = React.useState({
		password: '',
		rePassword: '',
		showPassword: false,
		showRePassword: false,
		formValid: false,
		isPasswordValid: false,
		isRePasswordValid: false,
		messageList: {},
	});
	const handleChange = (prop) => (event) => {
		const value = event.target.value;
		if (prop === 'password') {
			let psdValid = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/) ? true : false;
			setFormData({
				...formData,
				password: value.trim(),
				isPasswordValid: psdValid,
				formValid: value.trim() && formData.rePassword.trim() && psdValid && formData.isRePasswordValid,
			});
		} else if (prop === 'rePassword') {
			setFormData({
				...formData,
				rePassword: value.trim(),
				isRePasswordValid: formData.password === value.trim(),
				formValid:
					value.trim() && formData.password.trim() && formData.isPasswordValid && formData.password === value.trim(),
			});
		} else {
			setFormData({ ...formData, [prop]: event.target.value });
		}
	};

	const submitForm = (e) => {
		e.preventDefault();
		setIsFormSubmit(true);
		if (formData.formValid) {
			let data = {
				newPassword: formData.password,
			};
			props.resetPasswordAction(data);
		}
	};

	const generateMail = () => {
		props.generateResetPasswordMail().then((response) => {
			setIsTokenExpire(false);
			if (response && response.data) {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
			}
		});
	};

	useEffect(() => {
		const responseAPI = props.messageList;
		const keys = Object.keys(responseAPI);
		const messages = keys.map((item) => responseAPI[item]);
		if (props.resetPasswordStatus === 200) {
			clearLocalStorage();
			enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
			props.history.push(SECRET_LOGIN);
		} else if (props.resetPasswordStatus === 400) {
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
		} else if (props.resetPasswordStatus === 401) {
			setIsTokenExpire(true);
			enqueueSnackbar(t('ResetPasswordTokenNotValid'), { variant: 'error', autoHideDuration: 5000 });
		}
		props.updateStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.resetPasswordStatus]);

	return (
		<Fragment>
			<section className='sign-in-area'>
				<div className='login-screen'>
					<div className='inner'>
						<div className='img-inner-wrapper'>
							<ClientLogo className='client-logo' />
							<AnimatedIcon className='' width='404' type='image/svg+xml' alt='' imagePath={CatWalkImage} />
						</div>
					</div>
					<div className='inner login-form-area'>
						<div className='login-inner-wrapper reset-inner-form'>
							<img src={Logo} className='logo' alt='' />
							<Typography variant='h2'>{t('createPassword')}</Typography>
							<Typography>{t('enterNewPassword')}</Typography>
							<form className='login-inner-form reset-form' onSubmit={submitForm}>
								{isTokenExpire && (
									<Typography>
										{t('expiredToken')}
										<Link onClick={() => generateMail()}> {t('clickHere')} </Link>
										{t('generateNew')}
									</Typography>
								)}
								<FormControl
									variant='outlined'
									style={{ width: '100%' }}
									className='pass-txtbox'
									error={isFormSubmit && !formData.isPasswordValid}
								>
									<Input
										id='standard-adornment-password'
										type={formData.showPassword ? 'text' : 'password'}
										value={formData.password}
										onChange={handleChange('password')}
										placeholder={t('passwordPlaceholder')}
										className='password'
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													disableRipple='true'
													aria-label={t('ResetPasswordInputAriaLabel')}
													onClick={handleClickShowPassword}
												>
													{formData.showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
									{!formData.isPasswordValid && (
										<FormHelperText className='pass-error'>{t('strongPassword')}</FormHelperText>
									)}
								</FormControl>
								<FormControl
									variant='outlined'
									style={{ width: '100%' }}
									className='repass-txtbox'
									error={isFormSubmit && !formData.isRePasswordValid}
								>
									<Input
										id='standard-adornment-retype-password'
										type={formData.showRePassword ? 'text' : 'password'}
										value={formData.rePassword}
										onChange={handleChange('rePassword')}
										placeholder={t('ResetPasswordRetypePassword')}
										className='reset-pass'
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													disableRipple='true'
													aria-label='toggle password visibility'
													onClick={handleClickShowRePassword}
												>
													{formData.showRePassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										}
									/>
									{isFormSubmit && !formData.isRePasswordValid && (
										<FormHelperText>{t('passwordNotMatch')}</FormHelperText>
									)}
								</FormControl>

								<Button type='submit' className='btn-primary login-btn'>
									{t('create')}
								</Button>
							</form>

							<div className='footer-content'>
								<Typography variant='body2'>
									&copy; {currentYear} {t('infoproRights')}
									{' | '}
									<Link target='_blank' to='/privacy-policy'>
										{t('privacyPolicy')}
									</Link>
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
}
