import { Box, TextField, Typography, FormLabel, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EmailIdIcon,
	EmployeeIDIcon,
	ErrorIcon,
	JobTitleIcon,
	NameIcon,
	ReportingToIcon,
	TeamsIcon,
	RolePermissionIcon,
} from '../../../../config/svg/formElementIcons';
import { EditIcon } from '../../../../config/svg/CommonSvgIcon';
import _ from 'lodash';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { userDataFields } from '../UsersHelper';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';
import { getTooltipLicense } from '../../../../config/utils';
import { InformationMessage } from '../../CommonComponent/InformationMessage';
import { Enums } from '../../../../config/enums';
import { USER_ROLE_ID } from '../../../../config/users';
import { OKRButton } from '../../../Common/OKRButton';
import '../../../../styles/pages/admin/users.scss';

export const CreateUserForm: React.FC<any> = (props) => {
	const isGuidedTourShow = process.env.REACT_APP_ISGUIDEDTOURSHOW;
	const {
		getDesignation,
		searchEmployee,
		getActiveRoles,
		rolesDataResult,
		fetchOrganizationsList,
		listOrgResult,
		userFormData,
		setUserFormData,
		isEditForm,
		formError,
		setFormError,
		setFormEdited,
		licenseDetails,
		getLicenseDetails,
		licenseDetailsStatus,
	} = props;
	const [designationList, setDesignationList] = useState<string[]>([]);
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [teamsOptions, setTeamsOptions] = useState<any>([]);
	const [rolesOptions, setRolesOptions] = useState<any>([]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});
	const { t } = useTranslation();
	const [isEditField, setIsEditField] = useState<boolean>(false);
	useEffect(() => {
		if (licenseDetailsStatus === '' && (!licenseDetails || Object.keys(licenseDetails).length === 0)) {
			getLicenseDetails();
		}
	}, [licenseDetails, licenseDetailsStatus]);
	useEffect(() => {
		if (rolesDataResult.length === 0) {
			getActiveRoles();
		} else {
			let defaultRole: any = [];
			let rolesOptionsList = rolesDataResult.map((rolesData: any) => {
				if (rolesData.roleId === USER_ROLE_ID) {
					defaultRole.push({
						...rolesData,
						label: rolesData.roleName,
						value: rolesData.roleId,
					});
				}
				return {
					...rolesData,
					label: rolesData.roleName,
					value: rolesData.roleId,
				};
			});
			if (!isEditForm && rolesOptionsList.length > 0) {
				setUserFormData({ ...userFormData, [userDataFields.roleId]: defaultRole });
			}
			setRolesOptions(rolesOptionsList);
		}
	}, [rolesDataResult]);

	useEffect(() => {
		if (!listOrgResult?.entityList) {
			fetchOrganizationsList();
		}
		if (listOrgResult) {
			if (listOrgResult.entityList && listOrgResult.entityList.length > 0) {
				setTeamsOptions(
					listOrgResult.entityList.map((orgData: any) => {
						return {
							...orgData,
							label: orgData.teamName,
							value: orgData.teamId,
							imagePath: orgData.logoImagePath,
							secondaryText: orgData.teamHeadName,
							count: orgData.employeeCount,
							colorCode: orgData.colorcode,
							backGroundColorCode: orgData.backGroundColorCode,
						};
					})
				);
			}
		}
	}, [listOrgResult]);

	/**
	 * Designation on change funtion to initiate throttle call
	 * @param e event
	 */
	const handleDesignationThrottled = (e: any) => {
		e.preventDefault();
		const { value } = e.target;
		delayedQuery(value);
	};

	/**
	 * delay user typing and call api when user stops typing
	 */
	const delayedQuery = useCallback(
		_.debounce((q) => onDesignationChange(q), 500),
		[]
	);

	/**
	 * call designation api to fetch listing
	 * @param value string
	 */
	const onDesignationChange = async (value: string) => {
		if (value) {
			let resp = await getDesignation(`designation=${value}`);
			if (resp.data.status === 200) {
				setDesignationList(resp.data.entityList);
			}
		}
	};

	const searchReportingTo = async (value: string) => {
		if (value.length >= 3) {
			const resp = await searchEmployee(value, 1, 6, 1);
			let list = resp.data.entityList;
			//list = list && list.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (list && list.length > 0) {
				setReportingToOptions(
					list.map((userData: any) => {
						return {
							...userData,
							label: userData.firstName + ' ' + userData.lastName,
							value: userData.employeeId,
							secondaryText: userData.designation,
						};
					})
				);
			} else {
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
				setReportingToOptions([]);
			}
		}
	};

	const setUserFieldData = (field: string, value: any) => {
		setUserFormData({ ...userFormData, [field]: value });
		setFormError({ ...formError, [field]: '' });
		setFormEdited(true);
	};
	const getUserFieldData = (field: string) => {
		return userFormData[field];
	};

	const handleChange = (e: any, name: any) => {
		e.preventDefault();
		const { target } = e;
		if (target.value.trim().length > 100) {
			return false;
		} else {
			setUserFieldData(name, target.value);
		}
	};

	const handleEditField = () => {
		setIsEditField(true);
	};

	return (
		<Fragment>
			{!isEditForm && licenseDetails && licenseDetails.availableLicense <= Enums.LICENSE_THRESHOLD ? (
				<InformationMessage
					messageText={t('lessThenFiveSeatsAvailable', {
						availableLicense: licenseDetails.availableLicense > 0 ? licenseDetails.availableLicense : 0,
					})}
					title={`${getTooltipLicense(
						licenseDetails.availableLicense > 0 ? licenseDetails.availableLicense : 0,
						licenseDetails.bufferLicense,
						t
					)}`}
					icon={false}
					toolTipClassName={
						licenseDetails.availableLicense <= 0 ||
						(licenseDetails.availableLicense === 0 && licenseDetails.bufferLicense === 0)
							? 'tooltip-layout4'
							: 'tooltip-layout2'
					}
				/>
			) : (
				<></>
			)}
			<form className='drawer-form-fields' noValidate autoComplete='off'>
				<>
					<Box className='drawer-form-fields-group'>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<TextField
								id='firstName'
								className='field-col2 helperText-bottom'
								required
								label={
									<Fragment>
										<NameIcon />
										{t('firstNameLabel')}
									</Fragment>
								}
								placeholder={t('firstNameLabel')}
								value={getUserFieldData(userDataFields.firstName)}
								name={t('firstNameLabel')}
								error={(formError && formError[userDataFields.firstName]) || ''}
								helperText={
									formError &&
									formError[userDataFields.firstName] && (
										<>
											<ErrorIcon /> {formError[userDataFields.firstName] || ''}
										</>
									)
								}
								onChange={(e) => {
									handleChange(e, userDataFields.firstName);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('firstNameLabel'), disableUnderline: true }}
							/>
							<TextField
								id='lastName'
								className='field-col2 helperText-bottom'
								required
								label={
									<Fragment>
										<NameIcon />
										{t('lastNameLabel')}
									</Fragment>
								}
								placeholder={t('lastNameLabel')}
								value={getUserFieldData(userDataFields.lastName)}
								name={t('lastNameLabel')}
								onChange={(e) => {
									handleChange(e, userDataFields.lastName);
								}}
								error={(formError && formError[userDataFields.lastName]) || ''}
								helperText={
									formError &&
									formError[userDataFields.lastName] && (
										<>
											<ErrorIcon /> {formError[userDataFields.lastName] || ''}
										</>
									)
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('lastNameLabel'), disableUnderline: true }}
							/>
						</Box>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<TextField
								id='emailAddress'
								className='field-col2 helperText-bottom'
								required
								label={
									<Fragment>
										<EmailIdIcon />
										{t('emailAddressLabel')}
									</Fragment>
								}
								placeholder={t('emailAddressPlaceholder')}
								error={(formError && formError[userDataFields.emailId]) || ''}
								// disabled={isEditForm === true}
								disabled={isEditField === false && isEditForm}
								helperText={
									formError &&
									formError[userDataFields.emailId] && (
										<>
											<ErrorIcon /> {formError[userDataFields.emailId] || ''}
										</>
									)
								}
								value={getUserFieldData(userDataFields.emailId)}
								name={t('emailAddressLabel')}
								onChange={(e) => {
									setUserFieldData(userDataFields.emailId, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									'aria-label': t('emailAddressPlaceholder'),
									disableUnderline: true,
									// endAdornment: <span className='edit-icon-in-label'><EditIcon /></span>,
									endAdornment: (
										<span>
											{isEditForm ? (
												<span className='edit-icon-in-label' onClick={handleEditField}>
													{/* {<EditIcon />} */}
													<OKRButton
														className='user-edit-icon'
														// handleClick={(e) => handleMenuClick(e, 'editUser', user)}
														icon={<EditIcon />}
														title={t('editUserDetailsText')}
													/>
												</span>
											) : (
												<></>
											)}
										</span>
									),
								}}
							/>
							<TextField
								id='employeeID'
								className='field-col2 helperText-bottom'
								label={
									<Fragment>
										<EmployeeIDIcon />
										{t('employeeIDLabel')}
									</Fragment>
								}
								placeholder={t('userEmployeeIDPlaceholder')}
								value={getUserFieldData(userDataFields.employeeCode)}
								name={t('employeeIDLabel')}
								onChange={(e) => {
									setUserFieldData(userDataFields.employeeCode, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('userEmployeeIDPlaceholder'), disableUnderline: true }}
								error={(formError && formError[userDataFields.employeeCode]) || ''}
								helperText={
									formError &&
									formError[userDataFields.employeeCode] && (
										<>
											<ErrorIcon /> {formError[userDataFields.employeeCode] || ''}
										</>
									)
								}
							/>
						</Box>
					</Box>
					<Box className='drawer-form-fields-group'>
						<Box className='field-head'>
							<Typography variant='h4'>{t('assignJobMgrTeamLabel')}</Typography>
						</Box>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<Autocomplete
								freeSolo
								className='field-col2 helperText-bottom'
								classes={{ popper: 'autocomplete-popper' }}
								disableClearable
								options={designationList}
								value={getUserFieldData(userDataFields.designation)}
								onChange={(e, newValue) => {
									setUserFieldData(userDataFields.designation, newValue);
								}}
								renderInput={(params: any) => (
									<TextField
										{...params}
										label={
											<Fragment>
												<JobTitleIcon />
												{t('designationLabel')}
											</Fragment>
										}
										onChange={(e) => {
											handleDesignationThrottled(e);
											setUserFieldData(userDataFields.designation, e.target.value);
										}}
										error={(formError[userDataFields.designation] && formError[userDataFields.designation]) || ''}
										helperText={
											formError[userDataFields.designation] &&
											formError[userDataFields.designation] && (
												<>
													<ErrorIcon />
													{(formError[userDataFields.designation] && formError[userDataFields.designation]) || ''}
												</>
											)
										}
										placeholder={t('searchAddJobTitleText')}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								)}
							/>
							{(isGuidedTourShow === 'true' || isEditForm === true) && (
								<Box
									className={`field-col2 ${
										formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]
											? 'select-error helperText-bottom '
											: ''
									}`}
								>
									<FormLabel>
										<ReportingToIcon />
										{t('reportingToLabel')}
									</FormLabel>
									<MultiSelect
										key={'reportingTo'}
										id='reporting-to-dropdown'
										selectedOptions={getUserFieldData(userDataFields.reportingTo)}
										optionsList={reportingToOptions}
										onSelectOption={(value: any) => {
											setUserFieldData(userDataFields.reportingTo, value);
											setReportingToOptions([]);
											setEmptyRecords({ ...emptyRecords, reportingTo: false });
										}}
										isMulti={true}
										customFilter={() => {}}
										placeHolder={t('searchReportingTo')}
										noOptionsMessage={<NoSearchRecord />}
										showNoOptions={emptyRecords.reportingTo === true}
										fetchAsyncData={true}
										isSingleSelection={true}
										performSearch={searchReportingTo}
										closeMenuOnSelect={true}
										labelTemplate={'avatarLabel'}
										selectClassName={'select-search-dropdown select-search-icon'}
										selectClassNamePrefix={'react-select'}
										error={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
										helperText={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
									/>
								</Box>
							)}
						</Box>
						{(isGuidedTourShow === 'true' || isEditForm === true) && (
							<Box className={`drawer-input-field drawer-input-field-col2`}>
								<Box
									className={`team-field-col2 ${
										formError[userDataFields.teamId] && formError[userDataFields.teamId]
											? 'select-error helperText-bottom '
											: ''
									}`}
								>
									<FormLabel>
										<TeamsIcon />
										{t('teamsLabel')}
										<span className='asterisk'>*</span>
									</FormLabel>
									<MultiSelect
										key={'teamId'}
										id='team-id-dropdown'
										selectedOptions={getUserFieldData(userDataFields.teamId)}
										optionsList={teamsOptions}
										onSelectOption={(value: any) => {
											setUserFieldData(userDataFields.teamId, value);
										}}
										customFilter={(option: any, searchText: any) => {
											if (searchText.length >= 3 && option.label.toLowerCase().includes(searchText.toLowerCase())) {
												return true;
											}
										}}
										placeHolder={t('assignTeam')}
										isMulti={true}
										noOptionsMessage={<NoSearchRecord />}
										showNoOptions={emptyRecords.team === true}
										fetchAsyncData={false}
										isSingleSelection={false}
										performSearch={(searchText: string) => {
											if (searchText.length > 3) {
												setEmptyRecords({ ...emptyRecords, team: true });
											} else {
												setEmptyRecords({ ...emptyRecords, team: false });
											}
											setFormError({ ...formError, teamId: '' });
										}}
										blurCalled={() => {
											setEmptyRecords({ ...emptyRecords, team: false });
										}}
										closeMenuOnSelect={true}
										labelTemplate={'teamAvatarLabel'}
										selectClassName={'select-search-dropdown select-search-team-dropdown select-search-icon'}
										selectClassNamePrefix={'react-select'}
										error={(formError[userDataFields.teamId] && formError[userDataFields.teamId]) || ''}
										helperText={
											formError[userDataFields.teamId] &&
											formError[userDataFields.teamId] && (
												<>
													<ErrorIcon />
													{(formError[userDataFields.teamId] && formError[userDataFields.teamId]) || ''}
												</>
											)
										}
									/>
								</Box>
							</Box>
						)}
					</Box>
					{(isGuidedTourShow === 'true' || isEditForm === true) && (
						<Box className='drawer-form-fields-group'>
							<Box className='field-head'>
								<Typography variant='h4'>{t('assignRoleLabel')}</Typography>
							</Box>
							<Box className={`drawer-input-field drawer-field-col1-bigsize`}>
								<Box
									className={`field-col1  ${
										formError[userDataFields.roleId] && formError[userDataFields.roleId]
											? 'select-error helperText-bottom '
											: ''
									}`}
								>
									<MultiSelect
										key={'rolesIdDropdown'}
										id='roles-id-dropdown'
										selectedOptions={getUserFieldData(userDataFields.roleId)}
										optionsList={rolesOptions}
										onSelectOption={(value: any) => {
											setUserFieldData(userDataFields.roleId, value);
										}}
										customFilter={(option: any, searchText: any) => {
											if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
												return true;
											}
										}}
										placeHolder={t('assignRoleLabel')}
										noOptionsMessage={<NoSearchRecord />}
										showNoOptions={emptyRecords.roleId === true}
										fetchAsyncData={false}
										isSingleSelection={false}
										performSearch={() => {}}
										closeMenuOnSelect={true}
										labelTemplate={'onlyLabel'}
										isMulti={false}
										selectClassName={`user-role-dropdown`}
										selectClassNamePrefix={'user-role'}
										disableSearch={true}
										error={(formError[userDataFields.roleId] && formError[userDataFields.roleId]) || ''}
										helperText={
											formError[userDataFields.roleId] &&
											formError[userDataFields.roleId] && (
												<>
													<ErrorIcon /> {(formError[userDataFields.roleId] && formError[userDataFields.roleId]) || ''}
												</>
											)
										}
									/>
								</Box>
							</Box>
						</Box>
					)}
					<Box className='note-text'>
						<Typography className='short-des-text'>
							<strong>{t('note')}</strong> {t('emailNotify')}
						</Typography>
					</Box>
				</>
			</form>
		</Fragment>
	);
};
