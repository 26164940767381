import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoRecord } from '../NoRecord';
import ConversationImg from '../../../../images/no-found-conversation.svg';
import { Avatar, Box, Chip, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { OKRButton } from '../../../Common/OKRButton';
import { KebabIcon, LikeFilledIcon, LikeIcon } from '../../../../config/svg/CommonSvg';
import { PopperMenu } from '../../../Admin/CommonComponent/PopperMenu';
import { DeleteIcon, EditIcon } from '../../../../config/svg/CommonSvgIcon';
import { ReadMore } from '../ReadMore';
import { AddText } from '../AddText';
import { useSnackbar } from 'notistack';
import { Enums } from '../../../../config/enums';
import {
	getCurrentUserDetails,
	getRandomBackgroundColor,
	getRandomColor,
	getTime,
	getUserDetails,
	getUserName,
} from '../../../../config/utils';
import { EditConversations } from './EditConversations';
import AlertDialog from '../../../Common/Dialog';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../../services/StorageService';

export const Conversations: React.FC<any> = (props) => {
	const {
		tabSelected,
		okr,
		type,
		kr,
		conversationCount,
		setConversationCount,
		setLoader,
		// setIsCfrUpdated,
		setIsTaskEdited,
		isTaskEdited,
		setShowDialog,
		isPastCycle,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const [showEditor, setShowEditor] = useState(false);
	const [savedId, setSavedId] = useState(0);
	const [conversationContent, setConversationContent] = React.useState<string>('');
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [clickedData, setClickedData] = useState<any>({});
	const [editedConversation, setEditedConversation] = useState<any>({});
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
	const [conversationAction, setconversationAction] = useState<any>('');
	const [imageList, setImageList] = useState<any[]>([]);
	const currentUser = getCurrentUserDetails();
	const loggedInUserDetail = getUserDetails();
	const [pageIndex, setPageIndex] = useState(1);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [listData, setListData] = useState<any[]>([]);
	const [disableBtn, setDisableBtn] = useState<boolean>(false);
	const [listPending, setListPending] = useState<boolean>(true);

	React.useEffect(() => {
		setListPending(true);
		getConversation();
	}, []);

	React.useEffect(() => {
		if (props.getConversationStatus === 'success') {
			setConversationCount(props.conversationList.pagingInfo ? props.conversationList.pagingInfo.totalRecords : 0);
			setPageIndex(props.conversationList.pagingInfo ? props.conversationList.pagingInfo.pageIndex : 1);
			if (
				props.conversationList.pagingInfo?.totalPages > 1 &&
				props.conversationList.pagingInfo?.totalPages > props.conversationList.pagingInfo?.pageIndex
			) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}

			if (props.conversationList.pagingInfo?.pageIndex === 1) {
				setListData(props.conversationList.entityList);
				let conversationHighlight: any = getLocalStorageItem('conversationHighlight');
				if (Boolean(conversationHighlight)) {
					conversationHighlight = JSON.parse(conversationHighlight);
					if (conversationHighlight.conversationId > 0) {
						setSavedId(conversationHighlight.conversationId);
					}
				}
				setTimeout(() => {
					setSavedId(0);
					removeLocalStorageItem('isNoteRedirection');
					removeLocalStorageItem('conversationHighlight');
				}, 7000);
			} else {
				if (props.conversationList.entityList) {
					setListData([...listData, ...props.conversationList.entityList]);
				} else if (props.conversationList.entityList === null) {
					setListData([]);
				}
			}
			listPending && setListPending(false);
			setLoader(false);
			setIsTaskEdited(false);
		} else {
			// if (props.getConversationStatus === 'failed') {
			// 	getConversation();
			// 	setLoader(true);
			// }

			if (props.getConversationStatus === 'pending') {
				setLoader(true);
			}
		}
	}, [props.getConversationStatus]);

	React.useEffect(() => {
		if (showEditor === true) {
			setEditedConversation({});
		}
	}, [showEditor]);

	const getSourceId = () => {
		let sourceId = 0;
		if (type === 1) {
			sourceId = okr.source ? okr.source : okr.goalObjectiveId;
		} else if (type === 2) {
			sourceId = kr.source ? kr.source : kr.goalKeyId;
		}
		return sourceId;
	};

	const getConversation = (page = 1) => {
		let data = '';
		let sourceId = getSourceId();
		if (type === 1) {
			data = `goalTypeId=1&pageSize=10&pageIndex=${page}&goalSourceId=${sourceId}`;
		} else if (type === 2) {
			data = `goalTypeId=2&pageSize=10&pageIndex=${page}&goalSourceId=${sourceId}`;
		}
		props.getConversation(data);
	};

	const canEditOrModify = () => {
		if (currentUser) {
			return false;
		}
		return true;
	};

	const handleClick = (event: any, data: any) => {
		if (isTaskEdited === true) {
			setShowDialog(true);
		} else {
			setAnchorEl(anchorEl ? null : event.currentTarget);
			setOpen(true);
			setClickedData(data);
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
		setClickedData({});
	};

	const createConversation = (content = '', editedImageList = []) => {
		let payLoad: any = {
			description: conversationContent,
			goalTypeId: type === 1 ? 1 : 2,
			goalId: type === 1 ? okr.goalObjectiveId : kr.goalKeyId,
			assignedFiles: [],
			employeeTags: [],
			type: 1,
			goalSourceId: getSourceId(),
		};
		if (editedConversation.conversationId) {
			payLoad = {
				description: content,
				conversationId: editedConversation.conversationId,
				assignedFiles: [],
				employeeTags: [],
				isActive: true,
				type: editedConversation.type,
				goalSourceId: getSourceId(),
			};
			saveConversation(payLoad, editedImageList);
		} else {
			saveConversation(payLoad, imageList);
		}
	};
	const saveConversation = async (payload: any, images: any[]) => {
		if (
			payload.description.trim() === '' ||
			payload.description.trim() === '<p></p>' ||
			payload.description.trim() === '<p><br></p>'
		) {
			return false;
		}
		const elem = document.createElement('span');
		elem.innerHTML = payload.description;
		let tagged = elem.getElementsByClassName('tagged-name');
		for (let i = 0; i < tagged.length; i++) {
			let employeeId: any = tagged[i].getAttribute('rel');
			if (employeeId) {
				payload.employeeTags.push({
					employeeId: parseInt(employeeId),
				});
			}
		}
		images.forEach((value: any) => {
			if (payload.description.includes(value.filePath)) {
				payload.assignedFiles.push({
					storageFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					fileName: value.fileName,
					filePath: value.filePath,
				});
			}
		});
		let response: any = {};
		if (editedConversation.conversationId) {
			response = await props.UpdateConversation(payload);
		} else {
			response = await props.addConversation(payload);
		}
		if (response.data.status === Enums.STATUS_SUCCESS) {
			// setIsCfrUpdated(true);
			if (!editedConversation.conversationId) {
				setSavedId(response.data?.entity?.conversationId);
				setTimeout(function () {
					setSavedId(0);
				}, 5000);
			}
			setEditedConversation({});
			enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
				variant: 'success',
				autoHideDuration: 5000,
			});
			getConversation();
			hideAddEditor();
			setDisableBtn(false);
		} else {
			enqueueSnackbar(response?.data?.messageList.message, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const hideAddEditor = () => {
		setConversationContent('');
		setShowEditor(false);
	};
	const editConversation = (conversation: any) => {
		handleClose();
		hideAddEditor();
		setTimeout(function () {
			setEditedConversation(conversation);
		}, 200);
	};
	const handleDeleteConversation = (conversation: any) => {
		setEditedConversation(conversation);
		setOpenDeleteDialog(!openDeleteDialog);
		handleClose();
		setconversationAction('delete');
		hideAddEditor();
	};

	const likeConversationClicked = async (conversation: any) => {
		let payload = {
			conversationId: conversation.conversationId,
			employeeId: loggedInUserDetail.employeeId,
			isActive: !conversation.isLiked,
		};
		let response = await props.likeConversation(payload);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			var index = listData.findIndex((x: any) => x.conversationId === conversation.conversationId);
			let itemAttributes = listData[index];
			if (payload.isActive) {
				itemAttributes = {
					...itemAttributes,
					totalLikeCount: itemAttributes.totalLikeCount + 1,
					isLiked: true,
				};
				itemAttributes.conversationReactions.push({
					fullName: loggedInUserDetail.firstName + ' ' + loggedInUserDetail.lastName,
					employeeId: loggedInUserDetail.employeeId,
				});
			} else {
				itemAttributes = {
					...itemAttributes,
					totalLikeCount: itemAttributes.totalLikeCount - 1,
					isLiked: false,
				};
				var indexC = itemAttributes.conversationReactions.findIndex(
					(x: any) => x.employeeId === loggedInUserDetail.employeeId
				);
				if (indexC !== -1) {
					itemAttributes.conversationReactions.splice(indexC, 1);
				}
			}
			setListData([
				...listData.slice(0, index),
				Object.assign({}, listData[index], itemAttributes),
				...listData.slice(index + 1),
			]);
		} else {
			enqueueSnackbar(response?.data?.messageList.message, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			handleConfirmClick();
		}
		setOpenDeleteDialog(!openDeleteDialog);
		setEditedConversation({});
		setconversationAction('');
	};

	const handleConfirmClick = async () => {
		let data = `conversationId=${editedConversation.conversationId}`;
		const deleteTaskResponse = await props.deleteConversation(data);
		if (Boolean(deleteTaskResponse) && deleteTaskResponse.data.status === 200) {
			// setIsCfrUpdated(true);
			enqueueSnackbar(t('conversationDeleted'), {
				variant: 'success',
				autoHideDuration: 5000,
			});
			getConversation();
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	return (
		<>
			<Box className='cfr-tab-inner-head'>
				{!isPastCycle ? (
					<AddText
						{...props}
						tabSelected={tabSelected}
						showEditor={showEditor}
						setShowEditor={setShowEditor}
						setImageList={setImageList}
						imageList={imageList}
						showAvatar={true}
						avatarUserDetails={loggedInUserDetail}
						placeholderText={
							canEditOrModify() ? t('conversationPlaceholdarText') : t('conversationPeoplePlaceholdarText')
						}
						setEditorText={setConversationContent}
						editorText={conversationContent}
						handleSave={createConversation}
						handleCancel={() => {
							setConversationContent('');
							setEditedConversation({});
						}}
						errorMessage={t('conversationsRequired')}
						showHelpText={false}
						disableBtn={disableBtn}
						setDisableBtn={setDisableBtn}
						uploadCase={'CONVERSATION'}
						isAdded={true}
						isTaskEdited={isTaskEdited}
						setIsTaskEdited={setIsTaskEdited}
						setShowDialog={setShowDialog}
					/>
				) : (
					<Box className='task-help-text'>
						<Typography className='helper-text'>{t('conversationsNotPermitted')}</Typography>
					</Box>
				)}
				<Box className='count-head'>
					<Typography variant='body2'>
						{conversationCount} {t('commentsLabel')}
					</Typography>
				</Box>
			</Box>
			<Box className={`tab-panel-inner ${listData && listData.length > 0 ? ' ' : 'no-record-available'}`}>
				{listPending ? (
					<Box textAlign='center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				) : (
					<>
						{listData && listData.length > 0 ? (
							<>
								<List className='cfr-list comment-list' disablePadding>
									{listData.map((item: any, index: any) => (
										<ListItem className={`${savedId === item.conversationId ? 'highlight' : ''}`}>
											{item.imagePath ? (
												<Avatar className={'user-avtar'} src={item.imagePath} />
											) : (
												<Avatar
													className={'user-avtar'}
													style={{
														backgroundColor: getRandomBackgroundColor(item.backGroundColorCode),
														color: getRandomColor(item.colorCode),
													}}
												>
													{getUserName(item)}
												</Avatar>
											)}
											<Box className='comment-section'>
												<Box className='cfr-list-inner'>
													<Box className='cfr-list-content'>
														<Typography className='author-name'>{item.fullName}</Typography>
														<Box className='timestamp'>
															<Typography variant='body2'>{getTime(item.createdOn)}</Typography>
															{item.isEdited ? <Typography variant='body2'>{t('editedLabel')}</Typography> : <></>}
														</Box>
														<Box className='collapse-content'>
															{editedConversation.conversationId &&
															item.conversationId === editedConversation.conversationId &&
															conversationAction !== 'delete' ? (
																<EditConversations
																	{...props}
																	canEditOrModify={canEditOrModify}
																	createConversation={createConversation}
																	description={item.description}
																	editedConversation={editedConversation}
																	setEditedConversation={setEditedConversation}
																	isTaskEdited={isTaskEdited}
																	setIsTaskEdited={setIsTaskEdited}
																	setShowDialog={setShowDialog}
																/>
															) : (
																<ReadMore {...props} text={item.description} isHtmlContent={true} />
															)}
														</Box>
														{editedConversation.conversationId &&
														item.conversationId === editedConversation.conversationId &&
														conversationAction !== 'delete' ? (
															<></>
														) : item.type === 2 ? (
															<Chip className={'progress-notes'} label={t('progressNoteLabel')} />
														) : (
															<></>
														)}
													</Box>
													{loggedInUserDetail.employeeId === item.createdBy && !isPastCycle && (
														<OKRButton
															title={t('moreOptions')}
															className={`menu-btn ${
																open && clickedData.conversationId === item.conversationId ? 'active' : ''
															}`}
															handleClick={(e: any) => {
																handleClick(e, item);
															}}
															icon={<KebabIcon />}
														/>
													)}
												</Box>
												<OKRButton
													className={`like-btn ${item.isLiked ? 'active' : ''}`}
													text={item.totalLikeCount ? item.totalLikeCount : ' 0'}
													handleClick={(e: any) => {
														!isPastCycle && likeConversationClicked(item);
													}}
													icon={item.isLiked ? <LikeFilledIcon /> : <LikeIcon />}
													title={
														item.conversationReactions.length > 0 ? (
															<List disablePadding>
																{item.conversationReactions.map((element: any) => {
																	return <ListItem>{element.fullName}</ListItem>;
																})}
															</List>
														) : undefined
													}
													tooltipClassName={'tooltip-html'}
													disableRipple={isPastCycle}
												/>
											</Box>
										</ListItem>
									))}
								</List>
								{showLoadMore && (
									<List className='load-more-ul' disablePadding>
										<ListItem className='load-more'>
											<OKRButton
												handleClick={(e) => {
													getConversation(pageIndex + 1 || 1);
												}}
												className={`load-more-btn`}
												text={t('loadMore')}
											/>
										</ListItem>
									</List>
								)}
							</>
						) : (
							<NoRecord
								{...props}
								noRecordHead={t('noConversationHead')}
								noRecordMsg={t('noConversationMsg')}
								noRecordImg={ConversationImg}
							/>
						)}
					</>
				)}
				<PopperMenu
					anchorEl={anchorEl}
					open={open}
					handleClose={handleClose}
					popperClassName={'popper-menu popper-menu-type2 popper-cfr-menu'}
					{...props}
					children={
						<>
							<ListItem
								onClick={() => {
									editConversation(clickedData);
								}}
							>
								<ListItemAvatar>
									<EditIcon />
								</ListItemAvatar>

								<ListItemText primary={t('editCommentLabel')} />
							</ListItem>
							<ListItem
								onClick={() => {
									handleDeleteConversation(clickedData);
								}}
							>
								<ListItemAvatar>
									<DeleteIcon />
								</ListItemAvatar>
								<ListItemText primary={t('delete')} />
							</ListItem>
						</>
					}
				></PopperMenu>
			</Box>
			{openDeleteDialog && (
				<AlertDialog
					module='information'
					message={t('deleteConversationPrompt')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}
		</>
	);
};
