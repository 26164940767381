import * as actionTypes from './actionTypes';
import {
	getNotesAPI,
	addNotesAPI,
	deleteNotesAPI,
	UpdateNotesAPI,
	addConversationAPI,
	deleteConversationAPI,
	getConversationAPI,
	UpdateConversationAPI,
	likeConversationAPI,
} from '../services/cfrService';

const fetchNotesPending = () => ({
	type: actionTypes.FETCH_GET_NOTES_PENDING,
});
const fetchNotesCompleted = (payload) => ({
	type: actionTypes.FETCH_GET_NOTES_SUCCESS,
	payload,
});
const fetchNotesFailed = (error) => ({
	type: actionTypes.FETCH_GET_NOTES_FAILED,
	error,
});
export const resetCount = (error) => ({
	type: actionTypes.FETCH_GET_NOTES_PENDING,
	error,
});
export const getNotes = (data) => {
	return (dispatch) => {
		dispatch(fetchNotesPending());
		return getNotesAPI(data)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					dispatch(fetchNotesCompleted(response.data));
				} else {
					dispatch(fetchNotesFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchNotesFailed(error));
			});
	};
};
export const addNotes = (data) => {
	return () => {
		return addNotesAPI(data);
	};
};
export const deleteNotes = (data) => {
	return () => {
		return deleteNotesAPI(data);
	};
};
export const UpdateNotes = (data) => {
	return () => {
		return UpdateNotesAPI(data);
	};
};

const fetchConversationPending = () => ({
	type: actionTypes.FETCH_CONVERSATION_PENDING,
});
const fetchConversationCompleted = (payload) => ({
	type: actionTypes.FETCH_CONVERSATION_SUCCESS,
	payload,
});
const fetchConversationFailed = (error) => ({
	type: actionTypes.FETCH_CONVERSATION_FAILED,
	error,
});
export const resetConversationCount = (error) => ({
	type: actionTypes.FETCH_CONVERSATION_PENDING,
	error,
});
export const getConversation = (data) => {
	return (dispatch) => {
		dispatch(fetchConversationPending());
		return getConversationAPI(data)
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					dispatch(fetchConversationCompleted(response.data));
				} else {
					dispatch(fetchConversationFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchConversationFailed(error));
			});
	};
};
export const addConversation = (data) => {
	return () => {
		return addConversationAPI(data);
	};
};
export const deleteConversation = (data) => {
	return () => {
		return deleteConversationAPI(data);
	};
};
export const likeConversation = (data) => {
	return () => {
		return likeConversationAPI(data);
	};
};
export const UpdateConversation = (data) => {
	return () => {
		return UpdateConversationAPI(data);
	};
};

export const resetConversation = (error) => ({
	type: actionTypes.RESET_CONVERSATION,
	error,
});

export const resetNotes = (error) => ({
	type: actionTypes.RESET_NOTES,
	error,
});
