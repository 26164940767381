/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HeaderTooltip } from '../HeaderTooltip';
import ReportFilter from '../Filters';
import RadialChart from '../Charts/RadialChart';
import { Line } from '../Charts/splineChart';
import { getLocalStorageItem } from '../../../services/StorageService';
import _ from 'lodash';
import { getUserDetails } from '../../../config/utils';
import TeamEngagementReport from './TeamEngagementReport';
import moment from 'moment';
import { ReportsGaugeChart } from '../HighchartReports/ReportsGaugeChart';
import { TEAMS_CHART_COLOR } from '../../../config/constant';
import { ReportsLineChart } from '../HighchartReports/ReportsLineChart';

const TeamReports = (props: any) => {
	const {
		getLeadingTeamsDetails,
		leadingTeams,
		getTeamOkrProgressDetails,
		tabSelectedTeam,
	} = props;

	const { t } = useTranslation();
	const [overAllProgress, setOverAllProgress] = React.useState<any>({});
	const [anchorEl, setAnchorEl] = React.useState<boolean>(false);
	const [isFilterChanged, setIsFilterChanged] = React.useState<boolean>(false);
	const [teams, setTeams] = React.useState<any>([]);
	const [filterSelectionError, setFilterSelectionError] = React.useState<boolean>(false);
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const [teamsPreviousState, setTeamsPreviousState] = React.useState<any>([]);
	const [resetClicked, setResetClicked] = React.useState<boolean>(false);
	const [maxValueRecord, setMaxValueRecord] = React.useState<any>({});
	const [dropDownClicked, setDropDownClicked] = React.useState<boolean>(false);

	const cycleId = getLocalStorageItem('cycleId') || 0;
	const cycleEndDate = getLocalStorageItem('cycleEndDate') || '';
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || '';
	const [reportsData, setReportsData] = React.useState<any>({});
	const [checkedTeam, setCheckedTeam] = React.useState<any>([]);
	const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

	const handleFilterOption = (e: any, filterType: string, filterId?: number) => {
		const { checked } = e.target;
		let allTeams = [...teams];
		if (filterType === 'teams') {
			let updateTeams = allTeams.map((item: any) => {
				if (item.teamId === filterId) {
					return {
						...item,
						isChecked: checked,
					};
				}
				return item;
			});
			setTeams(updateTeams);
		} else if (filterType === 'selectAllTeam') {
			let updateTeams = allTeams.map((item: any) => {
				return {
					...item,
					isChecked: checked,
				};
			});
			setTeams(updateTeams);
		}
	};

	useEffect(() => {
		if (resetClicked) {
			getTeamProgress();
		}
	}, [resetClicked]);

	const handleApplyFilter = () => {
		setTeamsPreviousState(teams);
		handleFilterBtnClick();
		getTeamProgress();
	};

	const handleFilterBtnClick = () => {
		setAnchorEl((prev) => !prev);
		// setTeams(teamsPreviousState);
	};

	const handleClose = (event: any) => {
		setAnchorEl(false);
		setTeams(teamsPreviousState);
	};

	React.useEffect(() => {
		if (leadingTeams.length) {
			let allTeams = [...leadingTeams];
			let checkItem = allTeams.map((item: any) => {
				return {
					...item,
					isChecked: true,
				};
			});
			if (leadingTeams.length === 1) {
				setCheckedTeam(leadingTeams);
			} else {
				setCheckedTeam([]);
			}
			setTeams(checkItem);
			setTeamsPreviousState(checkItem);
		}
	}, [leadingTeams]);

	React.useEffect(() => {
		//Call Team data
		if (anchorEl) {
			let data = `cycleId=${currentCycleId}`;
			if (!leadingTeams.length) {
				getLeadingTeamsDetails(data);
			}
		}
	}, [anchorEl]);

	React.useEffect(() => {
		let allTeams;
		if (teams.length) {
			validateFilter();
			allTeams = teams.filter((item: any) => item.isChecked).length === teams.length;
			if (!allTeams) {
				setIsFilterChanged(true);
			} else {
				setIsFilterChanged(false);
			}
		}
		// setTeams(teams);
	}, [teams]);

	React.useEffect(() => {
		if (cycleStartDate && cycleEndDate) {
			getTeamProgress();
		}
	}, []);

	React.useEffect(() => {
		if (tabSelectedTeam) {
			updateFilter();
		}
	}, [tabSelectedTeam]);

	const updateFilter = () => {
		// teams, setTeams
		let allTeams = [...teams];

		// let updatedTeam = allTeams.filter((team) => team.teamId === tabSelectedTeam);
		let updatedTeam = allTeams.map((team) => {
			return {
				...team,
				isChecked: team.teamId === tabSelectedTeam ? true : false,
			};
		});
		setTeamsPreviousState(updatedTeam);
		setTeams(updatedTeam);
		setDropDownClicked(true);
	};

	useEffect(() => {
		if (dropDownClicked) {
			getTeamProgress();
		}
	}, [dropDownClicked]);

	const validateFilter = () => {
		let findFilteredTeams = teams.filter((item: any) => item.isChecked);
		if (!findFilteredTeams.length) {
			setFilterSelectionError(true);
		} else {
			setFilterSelectionError(false);
		}
	};

	const getTeamProgress = async () => {
		let data = {};
		let currentTeamList = [];
		let teamList = [];
		if (tabSelectedTeam && tabSelectedTeam != 'All' && tabSelectedTeam !== 0) {
			teamList.push(tabSelectedTeam);
		}
		let selectedTeams = [...teams];
		let getFilterList = _.filter(selectedTeams, 'isChecked');
		currentTeamList = getFilterList.length === 1 ? getFilterList : [];
		if (anchorEl) {
			data = {
				cycleId: Number(cycleId) || 0,
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: _.map(getFilterList, 'teamId') || [],
				okrNatureIds: [],
			};
		} else {
			data = {
				cycleId: Number(cycleId) || 0,
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: teamList ? teamList : [],
				okrNatureIds: [],
			};
		}
		setResetClicked(false);
		setDropDownClicked(false);
		const apiRes = await getTeamOkrProgressDetails(data);
		if (apiRes && (apiRes.data.status === 200 || apiRes.data.status === 204)) {
			setOverAllProgress(apiRes.data.entity.teamProgressOverall);
			setReportsData(apiRes.data.entity);
			if (currentTeamList.length === 1) {
				setCheckedTeam(currentTeamList);
			} else {
				setCheckedTeam([]);
			}

			let myOrgs = apiRes.data?.entity.teamOrgMemberProgressDetails.map((org: any) => {
				if (moment(org.date) > moment(new Date())) {
					return {
						...org,
						x: new Date(org.date),
					};
				} else {
					return {
						...org,
						x: new Date(org.date),
						y: org.progress,
					};
				}
			});
			let myProgressDetails = apiRes.data?.entity?.teamProgressDetails.map((myDetail: any) => {
				if (moment(myDetail.date) > moment(new Date())) {
					return {
						...myDetail,
						x: new Date(myDetail.date),
					};
				} else {
					return {
						...myDetail,
						x: new Date(myDetail.date),
						y: myDetail.progress,
					};
				}
			});
			let myTeamMemberProgressDetailsData = apiRes.data?.entity?.teamMostProgressDetails.map((myDetail: any) => {
				if (moment(myDetail.date) > moment(new Date())) {
					return {
						...myDetail,
						x: new Date(myDetail.date),
					};
				} else {
					return {
						...myDetail,
						x: new Date(myDetail.date),
						y: myDetail.progress,
					};
				}
			});
			let allRecords = [
				...apiRes.data?.entity?.teamOrgMemberProgressDetails,
				...apiRes.data?.entity?.teamProgressDetails,
				...apiRes.data?.entity?.teamMostProgressDetails,
			];
			let maxVal = _.maxBy(allRecords, 'progress');
			setMaxValueRecord(maxVal || {});

			setReportsData({
				...reportsData,
				myOrgMemberProgressDetails: myTeamMemberProgressDetailsData,
				myTeamMemberProgressDetails: myOrgs,
				myProgressDetails: myProgressDetails,
			});
			setDataLoaded(true);

			let data = Object.assign({}, apiRes.data.entity.teamProgressOverall);
			data.myOrgMemberProgress = apiRes.data.entity.teamProgressOverall.myTeamMemberProgress;
			data.myTeamMemberProgress = apiRes.data.entity.teamProgressOverall.myOrgMemberProgress;
			setOverAllProgress(data);
			//setIsReportDataAvailable(true);
		} else if (apiRes && apiRes.data.status === 204) {
			//setIsReportDataAvailable(false);
		}
	};

	const handleResetFilter = () => {
		let updateTeams = teams.map((item: any) => {
			return {
				...item,
				isChecked: true,
			};
		});
		setTeams(updateTeams);
		setTeamsPreviousState(updateTeams);
		setAnchorEl(false);
		setResetClicked(true);
	};

	return (
		<>
			<Box className='reports-head'>
				<Box className='reports-head-left'>
					<Typography variant='h2'>{t('myTeamProgressReportLabel')}</Typography>
					<HeaderTooltip tooltipText={t('teamOkrProgressReportTooltipText')} btnId={'team-reports-tooltip'} />
				</Box>
				<Box className='reports-head-right'>
					{leadingTeams && leadingTeams.length > 1 && (
						<ReportFilter
							{...props}
							toolTipText={t('filterTeamOkrTooltip')}
							teams={teams}
							isSencondFilters={false}
							handleFilterOption={handleFilterOption}
							handleApplyFilter={handleApplyFilter}
							handleResetFilter={handleResetFilter}
							handleFilterBtnClick={handleFilterBtnClick}
							handleClose={handleClose}
							filterSelectionError={filterSelectionError}
							anchorEl={anchorEl}
							isFilterChanged={isFilterChanged}
							filterId={'team-reports'}
						/>
					)}
				</Box>
			</Box>
			<Box className='reports-card col-full'>
				<Box className='report-col col-one-third'>
					<Typography variant='h4'>
						{t('overallPerformanceLabel')}
						<span>
							{t('overAllCurrentDate', { currentDate: moment(overAllProgress?.progressDate).format('MMMM D, YYYY') })}
						</span>
					</Typography>
					<ReportsGaugeChart
						{...props}
						t={t}
						isOrgReport={false}
						teamOkrProgress={true}
						checkedTeam={checkedTeam}
						overAllProgress={overAllProgress}
						colors={TEAMS_CHART_COLOR}
						labels={[t('myTeamsLabel'), t('orgLabel'), t('mostProgressedTeamLabel')]}
						chartHight={'244'}
						paneBackgroundColor={'rgba(244, 241, 248, 0.5)'}
					/>
					{/* <RadialChart
						isTeamReport={true}
						{...props}
						t={t}
						teamOkrProgress={true}
						checkedTeam={checkedTeam}
						color1={'#8FC3F6'}
						color2={'#FA989D'}
						color3={'#45E7BA'}
						overAllProgress={overAllProgress}
						labels={[t('myTeamsLabel'), t('orgLabel'), t('mostProgressedTeamLabel')]}
					/> */}
				</Box>
				<Box className='report-col col-two-thirds'>
					<Typography variant='h4' className='title-center'>
						{t('fourWeekReportLabel')}
					</Typography>
					<ReportsLineChart
						{...props}
						t={t}
						dataLoaded={dataLoaded}
						overAllProgress={overAllProgress}
						reportsData={reportsData}
						showLegend={true}
						maxValueRecord={maxValueRecord}
						colors={TEAMS_CHART_COLOR}
						labels={[t('myTeamsLabel'), t('orgLabel'), t('mostProgressedTeamLabel')]}
						isTeamReport={true}
						checkedTeam={checkedTeam}
						toolTipPostfix={'%'}
						xAxisText={'Weeks'}
						yAxisText={'Progress'}
					/>
					{/* <Line
						{...props}
						t={t}
						dataLoaded={dataLoaded}
						overAllProgress={overAllProgress}
						reportsData={reportsData}
						color1={'#8FC3F6'}
						color2={'#FA989D'}
						showLegend={true}
						color3={'#45E7BA'}
						maxValueRecord={maxValueRecord}
						labels={[t('myTeamsLabel'), t('orgLabel'), t('mostProgressedTeamLabel')]}
						isTeamReport={true}
						checkedTeam={checkedTeam}
					/> */}
				</Box>
			</Box>

			<TeamEngagementReport
				{...props}
				tabSelectedTeam={tabSelectedTeam}
				headTitle={t('myTeamEngagementReportLabel')}
				headTitleTooltip={t('teamEngagementReportTooltip')}
				teamOkrProgress={true}
				color1={'#8FC3F6'}
				color2={'#FA989D'}
				color3={'#45E7BA'}
				isEngagement={true}
			/>
			{/* <TeamLoadDistribution {...props} /> */}
		</>
	);
};

export default TeamReports;
