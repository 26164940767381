import { getRequest, putRequest, postRequest, downloadXLSFile } from '../config/auth';
import {
	CYCLE_DETAILS,
	IMPORT_CYCLE_DETAILS,
	GET_USER_BY_ID,
	GUIDED_TOUR,
	GUIDED_TOUR_ANY_ACTIVITY,
	IS_FEDERATION_USER,
	TENANT_LIST,
	DELETE_INSTANCE,
	UPDATE_INSTANCE,
	CONVERT_FEDERATED,
	TENANT_MODULES,
	MOVE_SSO_TO_DB,
	UPDATE_FORCE_LOGOUT,
	CLIENT_ENGAGEMENT_REPORT,
	DEFAULT_HOME_SCREEN,
} from '../config/api-url';

export const getCycleDetailsApi = (data) => getRequest(`${CYCLE_DETAILS}`, data);
export const getPreviousCycleDetailsApi = (data) => getRequest(`${IMPORT_CYCLE_DETAILS}?${data}`, data);

export const getUsersByIdAPI = (data) => getRequest(`${GET_USER_BY_ID}?${data}`, data);

/**Guided tour */
export const getGuidedTourDataApi = (data) => getRequest(`${GUIDED_TOUR}`, data);
export const getGuidedTourActivityDataApi = (data) => getRequest(`${GUIDED_TOUR_ANY_ACTIVITY}`);
export const getTenatListApi = (data) => getRequest(`${TENANT_LIST}`);
export const deleteInstanceApi = (data) => putRequest(`${DELETE_INSTANCE}`, data);
export const isFederationUserApi = (emailId) => getRequest(`${IS_FEDERATION_USER}?emailId=${emailId}`);
export const updateInstanceLicenseAPI = (data) => putRequest(`${UPDATE_INSTANCE}`, data);
export const convertFederatedAPI = (data) => postRequest(`${CONVERT_FEDERATED}`, data);
export const getModuleListApi = (data) => getRequest(`${TENANT_MODULES}`);
export const moveSSOToDbAPI = (data) => postRequest(`${MOVE_SSO_TO_DB}`, data);
export const updateForceLogoutAPI = (data) => postRequest(`${UPDATE_FORCE_LOGOUT}`, data);
export const clientEngagementReportApi = (data) => downloadXLSFile(`${CLIENT_ENGAGEMENT_REPORT}?${data}`, data);

export const getDefaultHomeScreenListApi = (data) => getRequest(`${DEFAULT_HOME_SCREEN}`);
