import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
	Avatar,
	Box,
	Typography,
	ListItem,
	Checkbox,
	ListItemIcon,
	ListItemText,
	Collapse,
	List,
} from '@material-ui/core';
import { getUserName, getRandomBackgroundColor, getRandomColor } from '../../../config/utils';
import _ from 'lodash';
import { OKRDrawer } from '../../Common/OKRDrawer';
import GlobalSearch from '../../Common/GlobalSearch';
import { LinkDeleteIcon, ExternalContributorIcon } from '../../../config/svg/CommonSvg';
import { useSnackbar } from 'notistack';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import '../../../styles/pages/drawer/assignContributorPanel.scss';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';

export const TeamMembersList: React.FC<any> = (props) => {
	const {
		treeNodesData,
		open,
		teamsLoading,
		t,
		completeOrgData,
		handleNext,
		handleCancel,
		setSelectedTeamIds,
		selectedTeamIds,
		setCompleteOrgData,
		keyAttribute,
		keyData,
		userAligned,
		disabledNodes,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
		selectedParents,
		setSelectedParents,
		setIsParentChecked,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const [intermediateList, setIntermediateList] = useState<any[]>([]);
	const [subModalProps, setSubModalProps] = useState<any>(null);
	const [defaultSelectedTeamsData, setDefaultSelectedTeamsData] = useState(null);

	useEffect(() => {
		if (
			selectedTeamIds !== undefined &&
			completeOrgData &&
			completeOrgData.length > 0 &&
			completeOrgData[0].children.length > 0
		) {
			setSelectedTeamIds(selectedTeamIds);
			setParentSelection(selectedTeamIds);
			if (defaultSelectedTeamsData === null && selectedTeamIds.length > 0) {
				setDefaultSelectedTeamsData(selectedTeamIds);
			}
		}
	}, [selectedTeamIds]);

	const parentSelected = (item: any, e: any) => {
		let isChecked: boolean = e.target.checked;
		let selectedIdsL: any[] = Object.assign([], selectedTeamIds);
		let indexParent = selectedParents.indexOf(item.id);
		if (isChecked === true) {
			if (indexParent === -1) {
				selectedParents.push(item.id);
				item.children.forEach((child: any) => {
					selectedIdsL.push(child.id);
				});
			}
		} else {
			if (indexParent !== -1) {
				selectedParents.splice(indexParent, 1);
			}
			item.children.forEach((child: any) => {
				if (child.isAlreadyAligned === true) {
					selectedIdsL.push(child.id);
				} else {
					let selectedIndex = selectedIdsL.indexOf(child.id);
					if (selectedIndex !== -1) {
						selectedIdsL.splice(selectedIndex, 1);
					}
				}
			});
		}
		selectedIdsL = selectedIdsL.filter((c: any, index: any) => {
			return selectedIdsL.indexOf(c) === index;
		});
		setSelectedTeamIds(selectedIdsL);
	};

	const childSelected = (item: any, e: any) => {
		let isChecked: boolean = e.target.checked;
		let selectedList: any[] = Object.assign([], selectedTeamIds);
		const index = selectedList.indexOf(item.id);
		if (isChecked === true && index === -1) {
			selectedList.push(item.id);
		} else if (isChecked === false) {
			if (index !== -1) {
				selectedList.splice(index, 1);
			}
		}
		setSelectedTeamIds(selectedList);
	};
	const setParentSelection = (selectedList: any) => {
		let intermediateL = Object.assign([], intermediateList);
		let selectedParentsL = Object.assign([], selectedParents);
		completeOrgData.forEach((parent: any) => {
			let selectedchild: any[] = [];
			parent.children.forEach((child: any) => {
				if (selectedList.indexOf(child.id) !== -1) {
					selectedchild.push(child.id);
				}
			});
			let selParentIndex = selectedParentsL.indexOf(parent.id);
			if (selectedchild.length === parent.children.length) {
				selectedParentsL.push(parent.id);
			} else {
				if (selParentIndex !== -1) {
					selectedParentsL.splice(selParentIndex, 1);
				}
				let selInterIndex = intermediateL.indexOf(parent.id);
				if (selectedchild.length > 0) {
					intermediateL.push(parent.id);
				} else {
					if (selInterIndex !== -1) {
						intermediateL.splice(selInterIndex, 1);
					}
				}
			}
		});
		intermediateL = intermediateL.filter((c: any, index: any) => {
			return intermediateL.indexOf(c) === index;
		});
		selectedParentsL = selectedParentsL.filter((c: any, index: any) => {
			return selectedParentsL.indexOf(c) === index;
		});
		setIntermediateList(intermediateL);
		setSelectedParents(selectedParentsL);
	};
	const prepareTreeChild = (orgData: any, childData: any) => {
		return {
			id: orgData.id + '_' + childData.employeeId,
			employeeId: childData.employeeId,
			fullName: childData.firstName + ' ' + childData.lastName,
			firstName: childData.firstName,
			lastName: childData.lastName,
			teamCount: childData.teamCount,
			membersCount: childData.membersCount,
			secondaryText: childData.designation,
			imagePath: childData.imagePath,
			backGroundColorCode: childData.backGroundColorCode,
			colorCode: childData.colorCode,
			canHaveForm: true,
			isParent: false,
			parentID: childData.organisationId,
			teamId: childData.organisationId,
			userTeamId: childData.organisationId,
			startDate: keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
			dueDate: keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate,
			isExternal: true,
			teamName: childData.organisationName,
			teambackGroundColorCode: childData.backGroundColorCode,
			teamcolorCode: childData.colorCode,
			startValue: keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue,
			targetValue: keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue,
			isAddedNew: true,
		};
	};
	const removeChild = (user: any) => {
		let fullList = Object.assign([], completeOrgData);
		let parentI = -1; //parent index
		let childI = -1; // child index
		fullList.forEach((parent: any, parentIndex: any) => {
			parentI = parentIndex;
			if (childI === -1) {
				childI = parent.children.findIndex((item: any) => item.id === user.id);
			}
		});
		if (parentI !== -1 && childI !== -1) {
			fullList[parentI].children.splice(childI, 1);
		}
		setCompleteOrgData(fullList);
		let selectedList: any[] = Object.assign([], selectedTeamIds);
		const indexS = selectedList.indexOf(user.id, 0);
		if (indexS > -1) {
			selectedList.splice(indexS, 1);
		}
		setSelectedTeamIds(selectedList);
	};
	const userSelected = async (selectedUser: any) => {
		let user = selectedUser[0];
		let childExists: boolean = false;
		let externalIndex = 0;
		completeOrgData.forEach((parent: any, parentIndex: any) => {
			parent.children.forEach((element: any) => {
				if (element.id === parent.id + '_' + user.employeeId) {
					childExists = true;
					user = element;
					user.isAddedNew = true;
				}
			});
			if (parent.id === 'external') {
				externalIndex = parentIndex;
			}
		});
		if (childExists === false) {
			let isAligned = await userAligned(user);
			if (isAligned === false) {
				if (externalIndex !== 0) {
					user = prepareTreeChild(completeOrgData[externalIndex], user);
				} else {
					completeOrgData.push({
						id: 'external',
						organisationId: 'external',
						organisationName: '',
						membersCount: 0,
						backGroundColorCode: '',
						colorCode: '',
						children: [],
						parentName: '',
						imagePath: '',
					});
					externalIndex = completeOrgData.length - 1;
					user = prepareTreeChild(completeOrgData[externalIndex], user);
				}
				let fullList = Object.assign([], completeOrgData);
				fullList[externalIndex].children.push(user);
				setCompleteOrgData(fullList);
				childSelected(user, { target: { checked: true } });
			}
		} else if (selectedTeamIds.indexOf(completeOrgData[0].id + '_' + user.employeeId) > 0) {
			enqueueSnackbar(`${user.firstName + ' ' + user.lastName} ${t('alreadyAlign')}`, {
				variant: 'info',
				autoHideDuration: 3000,
			});
		} else {
			let isAligned = await userAligned(user);
			if (isAligned === false) {
				childSelected(user, { target: { checked: true } });
			} else {
			}
		}
		setTimeout(function () {
			let element = document.getElementById('custom-scroll-id-' + user.id);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);
	};
	//callouts
	const letsAssignContributorTitle: any = useRef();
	useEffect(() => {
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel('memberListDrawer');
		}, 500);
		return () => {
			props.updateCalloutPanel && props.updateCalloutPanel('');
		};
	}, []);

	useEffect(() => {
		if (
			open &&
			props.activeOnboardingPanel === 'memberListDrawer' &&
			getLocalStorageItem('memberListDrawercalloutDone') === null &&
			getLocalStorageItem('showCallouts')
		) {
			setTimeout(() => {
				setAnchorElCallouts && setAnchorElCallouts(letsAssignContributorTitle.current);
				setCallOut && setCallOut(calloutsData[5]);
			}, 800);
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
		}
	}, [props.activeOnboardingPanel, open]);

	const checkCloseForUnsavedData = (e: any) => {
		if (_.isEqual(defaultSelectedTeamsData, selectedTeamIds)) {
			handleCancel(e);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		} else {
			setSubModalProps(true);
		}
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
			handleCancel(event);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
		setSubModalProps(false);
	};

	return (
		<Fragment>
			<OKRDrawer
				open={open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('letsAssignContributor')}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-subpanel assign-contributor-panel-wrap'>
							<Box className='assign-contributor-panel' {...({ ref: letsAssignContributorTitle } as any)}>
								{treeNodesData !== null && treeNodesData.length > 0 ? (
									<Fragment>
										<Box className='drawer-search-field'>
											<GlobalSearch
												{...props}
												module={'myGoal'}
												onSelectedUser={userSelected}
												fetchCycleUsers={true}
												placeHolderText={t('SearchTeamMembers')}
												customClass={'user-quick-search-type2'}
												customId={'team-member-src'}
											/>
										</Box>
										<Box className='teams-nested-list'>
											<List component='div' disablePadding className='assigned-team-list'>
												{treeNodesData !== null && treeNodesData.length > 0 ? (
													treeNodesData.map((item: any) =>
														item.id !== 'external' || item.children.length > 0 ? (
															<Fragment>
																{item.id === 'external' ? (
																	<span className='external-user'>
																		<ExternalContributorIcon /> {t('externalContributor')}
																	</span>
																) : (
																	<ListItem
																		button
																		className={`${
																			item.children.length === 0 ? 'teamCountZero assigned-team' : 'assigned-team'
																		}`}
																		id={`custom-scroll-id-${item.id}`}
																	>
																		<ListItemIcon>
																			<Checkbox
																				icon={<BorderCheckboxIcon />}
																				checkedIcon={<CheckedIcon />}
																				indeterminateIcon={<UnCheckedIcon />}
																				inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
																				onChange={(e) => {
																					parentSelected(item, e);
																				}}
																				checked={selectedParents.indexOf(item.id) !== -1}
																				disabled={item.children.length === 0}
																				indeterminate={
																					selectedTeamIds.length === 0
																						? false
																						: completeOrgData &&
																						  completeOrgData.length > 0 &&
																						  intermediateList.indexOf(item.id) !== -1 &&
																						  selectedParents.indexOf(item.id) === -1
																						? true
																						: false
																				}
																			/>
																		</ListItemIcon>
																		<ListItemText className='teams-select-all'>
																			<UserInfo {...props} userInfo={item} chartProps={props} />
																		</ListItemText>
																	</ListItem>
																)}
																<Collapse in={open} timeout='auto' unmountOnExit className='assigned-team-members'>
																	<List component='div' disablePadding>
																		{item.children.map((child: any) => (
																			<ListItem
																				id={`custom-scroll-id-${child.id}`}
																				button
																				className={`${child.isAlreadyAligned === true ? 'already-aligned-child' : ''}`}
																			>
																				<ListItemIcon>
																					<Checkbox
																						icon={<BorderCheckboxIcon />}
																						checkedIcon={<CheckedIcon />}
																						inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
																						checked={selectedTeamIds.indexOf(child.id) !== -1}
																						onChange={(e) => {
																							childSelected(child, e);
																						}}
																						disabled={child.isAlreadyAligned === true ? true : false}
																					/>
																				</ListItemIcon>
																				<Box className='display-flex-space-between'>
																					<UserInfo {...props} userInfo={child} chartProps={props} />
																					{child.isExternal === true && (
																						<Box
																							className='search-user-close-icon'
																							onClick={(e) => {
																								removeChild(child);
																							}}
																						>
																							<LinkDeleteIcon />
																						</Box>
																					)}
																				</Box>
																			</ListItem>
																		))}
																	</List>
																</Collapse>
															</Fragment>
														) : (
															<></>
														)
													)
												) : (
													<></>
												)}
											</List>
										</Box>
									</Fragment>
								) : teamsLoading ? (
									<Box className='teams-loader'>
										<span>{t('loading')}</span>
									</Box>
								) : (
									<NoSearchRecord />
								)}
							</Box>
						</Box>
					</Box>
				}
				moreButton={false}
				handleDrawerClose={(e: any) => {
					checkCloseForUnsavedData(e);
				}}
				handleSaveClick={(event: any) => {
					if (
						completeOrgData &&
						completeOrgData.length > 0 &&
						selectedTeamIds.length > 0 &&
						!(disabledNodes.length !== 0 && selectedTeamIds.length === disabledNodes.length)
					) {
						let externalIndex = selectedParents.indexOf('external');
						externalIndex !== -1 && selectedParents.splice(externalIndex, 1);
						let isAllTeamSelected = true;
						completeOrgData.forEach((parent: any) => {
							if (parent.id !== 'external' && selectedParents.indexOf(parent.id) === -1) {
								isAllTeamSelected = false;
							}
						});
						if (isAllTeamSelected === true) {
							setIsParentChecked(true);
							handleNext(3);
						} else {
							setIsParentChecked(false);
							handleNext(1);
						}
					}
				}}
				handleCancelClick={(e: any) => {
					checkCloseForUnsavedData(e);
				}}
				hideDefaultButton={false}
				nextButtonTooltip={
					!(
						completeOrgData &&
						completeOrgData.length > 0 &&
						selectedTeamIds.length > 0 &&
						!(disabledNodes.length !== 0 && selectedTeamIds.length === disabledNodes.length)
					) && t('disabledNextButton')
				}
				isSaveButtonDisabled={
					!(
						completeOrgData &&
						completeOrgData.length > 0 &&
						selectedTeamIds.length > 0 &&
						!(
							(
								disabledNodes.length !== 0 && _.isEqual(disabledNodes, selectedTeamIds)
							) /* selectedTeamIds.length === disabledNodes.length*/
						)
					)
				}
				saveButtonText={'Next'}
				showBackButton={false}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				activeOnboardingPanel={props.activeOnboardingPanel}
				// hideCloseIcon={true}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
		</Fragment>
	);
};

export const UserInfo = (props: any) => {
	const { t } = props;
	let kr = props.userInfo;
	return (
		<Box className='user-info '>
			<Box className='user-img'>
				{kr && kr.imagePath ? (
					<Avatar alt={`${kr.fullname}`} src={kr.imagePath} />
				) : (
					<Avatar
						style={{
							backgroundColor: getRandomBackgroundColor(kr.backGroundColorCode),
							color: getRandomColor(kr.colorCode),
						}}
					>
						{getUserName({
							firstName: '',
							lastName: '',
							fullName: kr.fullName,
						})}
					</Avatar>
				)}
			</Box>
			<Box className='user-info-details'>
				<ListItemText className={`contributor-name`}>
					<Box display='flex' alignItems='center'>
						<Typography variant='h4'>{kr.fullName}</Typography>
						{kr.isAlreadyAligned === true && (
							<Typography className='already-aligned-tag' variant='body2'>
								{t('alreadyAssignedLabel')}
							</Typography>
						)}
					</Box>
					{kr.isParent && (
						<Typography
							variant='subtitle2'
							style={{
								backgroundColor: getRandomBackgroundColor(kr.parentTeamBackGroundColorCode),
								color: getRandomColor(kr.parentTeamColorCode),
							}}
						>
							{kr.secondaryText}
						</Typography>
					)}
				</ListItemText>
				{kr.isParent ? (
					<Typography variant='subtitle2'>
						{kr.teamCount !== undefined ? <span className='secondary-text-span'>{kr.teamCount} Teams</span> : ''}
						{kr.membersCount !== undefined && kr.teamCount !== undefined ? (
							<span className='secondary-text-span'> | </span>
						) : (
							''
						)}
						{kr.membersCount !== undefined && kr.membersCount !== 0 ? (
							<span className='secondary-text-span'>{kr.membersCount} Members</span>
						) : (
							<span className='secondary-text-span'>{t('noMemberLabel')}</span>
						)}
					</Typography>
				) : (
					<Typography variant='subtitle2'>
						{kr.secondaryText ? <span className='secondary-text-span'>{kr.secondaryText}</span> : ''}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
