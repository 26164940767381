import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, Collapse, Link, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EditIcon, RightArrowIcon } from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';
import NotFoundImg from '../../images/no-found-notes.svg';
import { getAllMailList } from '../../action/mail';
import { getMonthDateYear } from '../../config/utils';
import { EditEmailIcon, SendEmailIcon } from '../../config/svg/CommonSvg';

export const MailList: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { editUpdateFeature, setShowDialog, sendListFeature } = props;
	const { allMailList, allMailListPending } = useSelector((state: any) => state.mailReducer);

	const [mailList, setMailList] = useState<any>();
	const [expandedMailList, setExpandedMailList] = useState<any>([]);

	useEffect(() => {
		dispatch(getAllMailList());
	}, []);

	useEffect(() => {
		if (allMailList && allMailList.length) {
			setMailList(allMailList);
		} else {
			setMailList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allMailList]);

	const handleMailListToggle = (e: any, OkrfeatureId: any) => {
		let element = document.getElementById(OkrfeatureId);
		let currentTop = 0;
		if (element) {
			currentTop = element.getBoundingClientRect().top;
		}

		let mailListContent = Object.assign([], expandedMailList);
		const elementIndex = mailListContent.indexOf(OkrfeatureId);
		if (elementIndex !== -1) {
			mailListContent.splice(elementIndex, 1);
		} else {
			mailListContent = [OkrfeatureId];
			setTimeout(() => {
				if (element) {
					const newTop = element.getBoundingClientRect().top;
					const scrollDifference = newTop - currentTop;

					window.scrollBy(0, scrollDifference);
				}
			}, 300);
		}
		setExpandedMailList(mailListContent);
	};
	const handlePopperScroll = (event: any, elementId: any) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);

			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
		}, 500);
	};
	const handleFeatureEdit = async (event: any, record: any) => {
		event.preventDefault();
		event.stopPropagation();
		editUpdateFeature(record);
	};
	const handleFeatureSend = async (event: any, record: any) => {
		event.preventDefault();
		event.stopPropagation();
		sendListFeature(record);
	};
	return (
		<Box className='okr-update-list-area'>
			<Box className='okr-list-panel-head'>
				<List component='ul' disablePadding>
					<ListItem className='okr-update-col1'>
						<Typography variant='body2'>{t('subjectLine')}</Typography>
					</ListItem>
					<ListItem className='okr-update-col2'>
						<Typography variant='body2'>{t('statusOfTheEmail')}</Typography>
					</ListItem>
					<ListItem className='okr-update-col3'>
						<Typography variant='body2'>{t('sentDate')}</Typography>
					</ListItem>
				</List>
			</Box>
			{mailList && mailList.length ? (
				mailList.map((item: any) => (
					<Box className='okr-update-list' key={item?.systemEmailId}>
						<Box className='okr-update-list-inner'>
							<Box
								className='okr-update-head'
								onClick={(e) => {
									handleMailListToggle(e, item?.systemEmailId);
									//handlePopperScroll(e, item?.systemEmailId);
								}}
							>
								<Box
									className={`rotating-arrow ${
										expandedMailList.indexOf(item.systemEmailId) !== -1 ? 'expand' : 'collapse'
									}`}
								>
									<RightArrowIcon />
								</Box>
								<List component='ul' disablePadding>
									<ListItem className='okr-update-col1'>
										<Box className='feature-title'>
											<Typography variant='h4'>{item?.subject || ''}</Typography>
										</Box>
									</ListItem>
									<ListItem className='okr-update-col2'>
										<Box
											className={`mail-status ${item?.status === 'Draft' ? 'draft-chip' : ''} ${
												item?.status === 'Sent' ? 'sent-chip' : ''
											}`}
										>
											{item?.status}
										</Box>
									</ListItem>
									<ListItem className='okr-update-col3'>
										<Box className={`sent-date`}>
											<Typography>{item?.sentDate ? getMonthDateYear(item?.sentDate) : '--'}</Typography>
										</Box>
										{item?.isEditable ? (
											<Box className='email-actions'>
												<OKRButton
													title={t('editEmail')}
													icon={<EditEmailIcon />}
													handleClick={(event: any) => {
														event.stopPropagation();
														handleFeatureEdit(event, item);
													}}
												/>
												<OKRButton
													wrapClassName={`send-email-btn-wrap ${!item?.body ? 'disabled' : ''}`}
													title={item?.body ? t('sendEmail') : t('emailContentValidation')}
													icon={<SendEmailIcon />}
													disabled={item?.body ? false : true}
													buttonType={'disableButtonTooltip'}
													handleClick={(event: any) => {
														event.stopPropagation();
														handleFeatureSend(event, item);
														setShowDialog({
															open: true,
															type: 'Sent',
															message: t('sentConfirmation'),
														});
													}}
												/>
											</Box>
										) : (
											<></>
										)}
									</ListItem>
								</List>
							</Box>
							<Box id={item?.systemEmailId}></Box>
							<Collapse className='okr-update-content' in={expandedMailList.indexOf(item.systemEmailId) !== -1}>
								<Box className='feature-content'>
									<Box className='formatted-text' dangerouslySetInnerHTML={{ __html: item?.body || '' }}></Box>
								</Box>
							</Collapse>
						</Box>
					</Box>
				))
			) : allMailListPending ? (
				<>
					<Box className='loading-txt-center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				</>
			) : (
				<Box className='version-not-found'>
					<Typography variant='h4'>{t('noRecord')}</Typography>
					<img width='270' alt='NotFoundImg' src={NotFoundImg} />
				</Box>
			)}
		</Box>
	);
};
