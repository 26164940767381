import React, { useState, Fragment, useEffect } from 'react';
import { Box, Button, Link, Tooltip, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { AlignmentMapTabPanel, AlignmentMapTabs } from './AlignmentMapTabs';
import { AlignmentMapSecondaryTabs } from './AlignmentMapSecondaryTabs';
import { AlignmentListing } from '../AlignmentListing';
import AlertDialog from '../../Common/Dialog';
import {
	createUUID,
	formatDate,
	getCurrentUserDetails,
	getEndDateForEveryMonthForQuarter,
	getUserDetails,
	GAmodalView,
} from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { KrAcceptAttributes } from '../../OkrAction/KrAcceptAttributes';
import { KrDeclineAttributes } from '../../OkrAction/KrDeclineAttributes';
import { clone } from 'lodash';
import { AlignmentMapTeamsSecondaryTabs } from './AlignmentMapTeamsSecondaryTabs';
import PersonalFeedbackMenu from '../../PersonalFeedback';
import { PersonalFeedbackAction } from '../../PersonalFeedback/PersonalFeedbackAction';
import { LockIcon } from '../../../config/svg/CommonSvg';
import { CreateObjective } from '../../OkrAction/CreateAlignOkr/CreateObjective';
import { AlignmentMapDirectReportsSecondaryTabs } from './AlignmentMapDirectReportsSecondaryTabs';
import { AlignmentMapFilter } from '../AlignmentMapFilter';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { ALIGNMENTMAPS, HOME, USER_VIEW } from '../../../config/app-url';
import Feedback from '../../Feedback';
import { CreateAlignOkr } from '../../OkrAction/CreateAlignOkr';
import { AlignmentMap } from '../../../config/url';

export const AlignmentHeader: React.FC<any> = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const {
		deleteContributor,
		acceptKr,
		fetchOkr,
		alignmentFilterData,
		getAlignmentList,
		addAlignmentFilter,
		alignResult,
	} = props;
	const { t } = useTranslation();
	const [tabValue, setTabValue] = useState<Number>(0);
	const [tabFilter, setTabFilter] = useState<string>('relaxed');
	const [openDrawer, setOpenDrawer] = useState<any>({});
	const defaultModalProps = {
		open: false,
		message: '',
		unlockRequest: false,
		type: '',
		module: '',
	};
	const [modalProps, setModalProps] = useState<any>(defaultModalProps);
	const userDetail: any = getUserDetails();
	const year = getLocalStorageItem('currentYear') || null;
	const selectedYear = getLocalStorageItem('year') || getLocalStorageItem('currentYear');
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const [tabButtonFilter, setTabButtonFilter] = useState<String>('okrView');
	const currentUser = getCurrentUserDetails();
	const [objectiveModalProps, setObjectiveModalProps] = useState<any>({
		open: false,
		type: '',
		message: '',
		module: '',
		id: 0,
	});
	const [feedback, setFeedback] = useState<any>({ type: 0, open: false });
	const [loader, setLoader] = useState<boolean>(false);
	const [isAcceptKRFormUpdated, setIsAcceptKRFormUpdated] = useState<boolean>(false);
	const [myGoalList, setMyGoalList] = useState<any>([]);
	/**
	 * Start method for Add to Objective
	 **/
	const [showAddGoal, setShowAddGoal] = useState<Boolean>(false);
	const [formSubmit, setFormSubmit] = useState<Boolean>(false);
	const [goalDetail, setGoalDetail] = useState<any>({ actionType: '', goalDetail: {} });
	// const [isUnLockRequested, setIsUnLockRequested] = useState<Boolean>(false);
	const [durationCycleDetail, setDurationCycleDetail] = useState<any>(null);
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || null;
	const currentCycleEndDate = getLocalStorageItem('currentCycleEndDate') || null;
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const [isGoalCreated, setIsGoalCreated] = useState<Boolean>(false);
	const { employeeId }: any = getUserDetails();
	const [saveActionType, setSaveActionType] = useState<any>(null);
	const [isTabChanged, setIsTabChanged] = useState<boolean>(false);
	const [deleteTeamOkrKr, setDeleteTeamOkrKr] = useState<boolean>(false);
	const [reloadTeam, setReloadTeam] = useState<boolean>(false);
	const [isUnreadFeedback, setIsUnreadFeedback] = useState<any>({
		allUnread: 0,
		teamUnread: 0,
	});

	useEffect(() => {
		window.addEventListener('scroll', scrollListHeader);
	});

	// check if filter has already value then reset it
	useEffect(() => {
		if (props.alignmentFilterData && props.alignmentFilterData.length) {
			props.addAlignmentFilter([]);
		}
	}, []);

	useEffect(() => {
		getAlignmentData();
	}, [alignmentFilterData, saveActionType, props.userSelectedCycle]);

	const getAlignmentData = () => {
		if (!currentUser && userDetail && userDetail.employeeId && cycleId !== 0) {
			if (tabValue === 0 && ['relaxed', 'compact', 'ultracompact'].indexOf(tabFilter) > -1) {
				let filters = alignmentFilterData.length
					? alignmentFilterData
							.filter((item: any) => item.isChecked)
							.map((item: any) => `searchTexts=${item.code}`)
							.join('&')
					: '';
				filters = filters ? `&${filters}` : '';
				const data = `empId=${userDetail.employeeId}&cycle=${cycleId}&year=${selectedYear}${filters}`;
				getAlignmentList(data);
			}
		} else if (currentUser && currentUser.employeeId && cycleId !== 0) {
			const data = `empId=${currentUser.employeeId}&cycle=${cycleId}&year=${selectedYear}`;
			getAlignmentList(data);
		}
	};

	useEffect(() => {
		if (props.listOkrSuccess === 'success') {
			if (props.listOkrResult) {
				const allListData =
					(props.listOkrResult.myGoalOkrResponses && clone(props.listOkrResult.myGoalOkrResponses)) || [];
				const allObjectiveData = clone(allListData).filter(
					(data: any) => data.goalStatusId !== Enums.GOAL_STATUS_ARCHIVE
				);
				setMyGoalList(allObjectiveData);
				// setIsUnLockRequested(props.listOkrResult.isUnLockRequested);
			}
		}
		return () => clearTimeout(undefined);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.listOkrSuccess, props.listOkrResult]);

	useEffect(() => {
		var pageName = 'alignment-maps/';
		if (tabValue === 0) pageName = pageName + 'all';
		else if (tabValue === 1) pageName = pageName + 'teamokrs';
		else pageName = pageName + 'directreports';
		GAmodalView(pageName);
	}, [tabValue]);

	const handleTabChange = (event: any, newValue: Number) => {
		setTabButtonFilter('');
		setTabValue(newValue);
		setIsTabChanged(true);
		removeLocalStorageItem('currentUser');

		const tabToOpen =
			newValue === 2 ? 'directReport' : newValue === 1 ? 'TEAMSOKR' : newValue === 0 ? tabButtonFilter : '';
		setLocalStorageItem(
			'globalBackUrl',
			JSON.stringify({
				url: ALIGNMENTMAPS,
				tabToOpen,
			})
		);

		setLocalStorageItem(
			'activeTab',
			newValue === 2
				? 'directReport_relaxed'
				: newValue === 1
				? 'teamsOkr_relaxed'
				: newValue === 0
				? 'okr_relaxed'
				: ''
		);
	};

	const goBackHandle = () => {
		props.resetOkr();
		const backUrl = JSON.parse(getLocalStorageItem('backUrl') || '{}');
		const backUrlAlignmentMaps = JSON.parse(getLocalStorageItem('backUrlAlignmentMaps') || '{}');
		if (backUrlAlignmentMaps && backUrlAlignmentMaps.url === USER_VIEW) {
			props.history.push(USER_VIEW);
			props.updateRoute(USER_VIEW);
			removeLocalStorageItem('backUrlAlignmentMaps');
		} else {
			removeLocalStorageItem('currentUser');
			if (props.prevRoute) {
				props.history.push(props.prevRoute);
				props.updateRoute(props.prevRoute);
			} else if (backUrl && backUrl.url === ALIGNMENTMAPS) {
				props.history.push(ALIGNMENTMAPS);
				props.updateRoute(ALIGNMENTMAPS);
			} else {
				props.history.push(HOME);
				props.updateRoute(HOME);
			}
		}
	};
	const scrollListHeader = (e: any) => {
		let listobjelement = document.getElementById('secondary-tab');
		let listtopPos = listobjelement?.offsetTop;
		listtopPos = listtopPos ? listtopPos - 148 : 0;
		if (window.pageYOffset > listtopPos) {
			listobjelement?.classList.add('sticky-list-head');
		} else {
			listobjelement?.classList.remove('sticky-list-head');
		}
	};

	const handleDeleteOkr = (e: any, okr: any, kr: any, type: any) => {
		const keyData = type === 2 ? kr : okr;
		const id = type === 1 || type === 5 ? okr.objectiveId : keyData.krId;
		let goalType = type === 1 || type === 5 ? 1 : 2;
		let message = t('deleteConfirmationForAlignment', {
			type: `${type === 1 ? t('objective') : type === 5 ? t('draft') : t('keyResult')}`,
		});

		const findKrLength = okr && okr.okrViewKeyResults ? okr.okrViewKeyResults?.length : 0;

		if (Boolean(kr) && findKrLength === 1 && kr.owner !== kr.employeeId) {
			message = t('deleteConfirmationWithObjectiveOwner');
		} else if (Boolean(kr) && findKrLength === 1 && kr.contributorCount === 0) {
			message = t('deleteConfirmationForAlignment', {
				type: t('keyResult'),
			});
		} else if (Boolean(kr) && findKrLength === 1 && kr.contributorCount && kr.contributorCount > 0) {
			message = t('deleteConfirmationWithKrcontributor');
		} else if (Boolean(kr) && kr.contributorCount && kr.contributorCount > 0) {
			message = t('deleteConfirmationWithKr');
		} else if (okr && okr.contributorCount && okr.contributorCount > 0 && okr.objectiveType === 1) {
			message = t('deleteConfirmationWithOkr');
		} else if (okr && okr.contributorCount && okr.contributorCount > 0 && okr.objectiveType === 2) {
			message = t('deleteConfirmationWithKr');
		}

		setObjectiveModalProps({
			open: true,
			type: goalType,
			id,
			message,
		});
	};

	const showApiMsgs = (msg: any, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 2000,
		});
	};

	const showErrorMsgWithObject = (response: any) => {
		try {
			const errorMessages = Object.values(response?.data?.messageList);
			if (errorMessages && errorMessages.length > 0) {
				errorMessages.forEach((item: any) =>
					enqueueSnackbar(item, {
						variant: 'error',
						autoHideDuration: 5000,
					})
				);
			}
		} catch (e) {}
	};

	/** Delete objective and kr api call */
	const handleCloseObjectiveModal = async (event: any, type: Number) => {
		const userDetail: any = getUserDetails();
		const dataProps = objectiveModalProps;
		const { id } = dataProps;
		const { employeeId } = userDetail;
		let message = '';
		if (type === 1) {
			message = 'Objective';

			if (dataProps.type === 1) {
				const data = `employeeId=${employeeId}&goalObjectiveId=${id}&goalType=${dataProps.type}`;
				const response = await props.deleteObjective(data);
				if (response.data.status === 200) {
					getAlignmentData();
					setDeleteTeamOkrKr(true); // call team-tab api to refresh listing
					showApiMsgs(response?.data?.messageList?.Result, 'success');
				} else {
					showApiMsgs(t('deletedMessageFail', { message: `${message}` }), 'error');
				}
			} else {
				message = 'Key Result';
				const data = `goalKeyId=${id}&employeeId=${employeeId}`;
				const response = await deleteContributor(data);
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					showApiMsgs(t('deletedMessage', { message: `${message}` }), 'success');
					getAlignmentData();
					setDeleteTeamOkrKr(true); // call team-tab api to refresh listing
				} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
					showErrorMsgWithObject(response);
				}
			}
		}
		setObjectiveModalProps(defaultModalProps);
	};

	const resetDecline = () => {
		setFeedback({ type: 0, open: false });
		setOpenDrawer({ open: false });
		setModalProps(defaultModalProps);
	};

	// based on save action type calling api
	const addObjectiveCallback = (type: any) => {
		setSaveActionType(type);
	};
	const handleCloseModal = async (event: any, type: Number) => {
		event.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'DeclineKR') {
				setOpenDrawer({ open: false });
			} else if (modalProps.type === 'AcceptKR') {
				setOpenDrawer({ open: false });
				setIsAcceptKRFormUpdated(false);
			}
			// else if (modalProps.type === 'myGoal') {
			// 	const data = {
			// 		year: year,
			// 		cycle: currentCycleId,
			// 		employeeId: userDetail.employeeId,
			// 	};
			// 	try {
			// 		setModalProps(defaultModalProps);
			// 		const response = await props.unlockRequest(data);
			// 		if (response.data.status === 200) {
			// 			setIsUnLockRequested(true);
			// 			enqueueSnackbar(response.data.messageList.unlockStatus, {
			// 				variant: 'success',
			// 				autoHideDuration: 5000,
			// 			});
			// 		} else {
			// 			enqueueSnackbar(t('unlockRequest'), {
			// 				variant: 'error',
			// 				autoHideDuration: 5000,
			// 			});
			// 		}
			// 	} catch (err) {
			// 		enqueueSnackbar(t('unlockRequest'), {
			// 			variant: 'error',
			// 			autoHideDuration: 5000,
			// 		});
			// 	}
			// }
		}
		setModalProps(defaultModalProps);
	};

	const setDefaultData = (data: any, masterData: any, type: number) => {
		const { goalTypes, goalStatus } = props.okrMasterData;
		if (data.goalTypeId === Enums.ZERO) {
			const { goalTypeId } = goalTypes.find((item: any) => item.isDefault);
			data.goalTypeId = goalTypeId;
		}
		if (data.goalStatusId === Enums.ZERO) {
			const { goalStatusId } = goalStatus.find((item: any) => item.isDefault);
			data.goalStatusId = goalStatusId;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = goalStatusId || item.goalStatusId;
					return item;
				});
			}
		}
		setGoalStatusId(type, data);
	};

	const setGoalStatusId = (type: number, data: any) => {
		if (type === Enums.TWO && data.goalStatusId === Enums.GOAL_STATUS_DRAFT) {
			data.goalStatusId = type;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = type || item.goalStatusId;
					return item;
				});
			}
		}

		if (type === Enums.THREE || type === Enums.FOUR) {
			data.goalStatusId = Enums.GOAL_STATUS_DRAFT;
			if (data?.myGoalsDetails && data?.myGoalsDetails.length > 0) {
				data.myGoalsDetails = data.myGoalsDetails.map((item: any) => {
					item.goalStatusId = Enums.GOAL_STATUS_DRAFT || item.goalStatusId;
					return item;
				});
			}
		}
	};
	const saveAndEditObjectiveAndKr = async (objectiveDetail: any, type: number, isAdd: Boolean) => {
		let maxGoalObjectiveId = 0;
		if (props.listOkrResult.myGoalOkrResponses) {
			maxGoalObjectiveId = Math.max(...props.listOkrResult.myGoalOkrResponses.map((o: any) => o.goalObjectiveId), 0);
		}

		setLocalStorageItem('maxGoalObjectiveId', maxGoalObjectiveId);
		/**---Max Id ------------ */
		setFormSubmit(true);
		const { metricMasters } = props.okrMasterData || [];
		const noUnit = metricMasters?.find((item: any) => item.isDefault);
		// const standAlone = assignmentTypes && assignmentTypes.length && assignmentTypes.find((item: any) => item.isDefault);
		const updatedGoalDetail = objectiveDetail.myGoalsDetails.map((item: any) => {
			item.keyDescription = item?.keyDescription?.trim();
			if (item.metricId === 0) {
				item.metricId = noUnit?.metricId;
			}
			setGoalStatusId(type, item);
			item.goalStatusId = objectiveDetail?.goalStatusId || item.goalStatusId;
			item.contributors = objectiveDetail?.isPrivate
				? []
				: item.contributors.map((contributor: any) => {
						setGoalStatusId(type, contributor);
						return contributor;
				  });

			return item;
		});
		const filterData = updatedGoalDetail.filter((elem: any) => !elem.isNewKey);

		let data = { ...objectiveDetail, objectiveName: objectiveDetail?.objectiveName.trim(), myGoalsDetails: filterData };
		/** set default data to save goals */
		setDefaultData(data, props.okrMasterData, type);

		try {
			const dataToSave = isAdd ? [{ ...data }] : { ...data };
			const response = await props.addUpdateObjective(dataToSave, isAdd);
			if (response && response.data.status === Enums.STATUS_SUCCESS) {
				setFormSubmit(false);
				if (type === Enums.ONE) {
					showAddGoalForm(true);
					fetchOkr(true);
				} else if (type === Enums.TWO || type === Enums.THREE) {
					showAddGoalForm(false);
					fetchOkr(true);
				} else if (type === Enums.FOUR) {
					const responseModal = isAdd
						? response?.data?.entityList && response?.data?.entityList.length
							? response?.data?.entityList[0]
							: {}
						: response?.data?.entity || {};
					return responseModal || {};
				}
				setTimeout(() => {
					enqueueSnackbar(response?.data?.messageList?.Result, {
						variant: 'success',
						autoHideDuration: 3000,
					});
				}, 1200);
				return 'success';
			} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
				setFormSubmit(false);
				const errorMessages = Object.values(response?.data?.messageList);
				if (errorMessages && errorMessages.length > 0) {
					errorMessages.forEach((item: any) =>
						enqueueSnackbar(item, {
							variant: 'error',
							autoHideDuration: 5000,
						})
					);
				}
				return 'error';
			} else if (response && response.data.status === Enums.STATUS_SERVER_ERROR) {
				setFormSubmit(false);
				enqueueSnackbar(t('goalAddedFail'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
				return 'error';
			}
		} catch (e) {
			setFormSubmit(false);
			// console.error(e);
		}
	};
	const showAddGoalForm = (show: Boolean) => {
		setFormSubmit(false);
		setGoalDetail({});
		setShowAddGoal(show);
	};

	useEffect(() => {
		const durationForQuarter = getEndDateForEveryMonthForQuarter(
			cycleStartDate,
			currentCycleEndDate,
			year,
			cycleDuration
		);
		setDurationCycleDetail(durationForQuarter);
	}, [cycleStartDate, currentCycleEndDate, year, cycleDuration]);

	/* Add Objective END*/
	const sendUnlockRequest = () => {
		// setModalProps({
		// 	open: true,
		// 	message: isUnLockRequested ? t('request4AlreadyRaised') : t('request4Editing', { emailID: userDetail.emailId }),
		// 	unlockRequest: true,
		// 	type: 'myGoal',
		// 	module: 'myGoal',
		// });
		if (!props?.alignResult?.isScoreLocked) {
			enqueueSnackbar(t('duringGracePeriod'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		} else {
			enqueueSnackbar(t('afterGracePeriod'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	useEffect(() => {
		backUrlNavigation();
	}, []);
	useEffect(() => {
		handleActiveTab();
	}, [props.location]);

	const handleActiveTab = () => {
		const activeTab = getLocalStorageItem('activeTab') || '';
		const activeTabName = activeTab.split('_');
		if (!currentUser && activeTabName.length === 2) {
			addAlignmentFilter([]);
			const tabValueIndex = activeTabName[0] === 'directReport' ? 2 : activeTabName[0] === 'teamsOkr' ? 1 : 0;
			setTabValue(tabValueIndex);
			setTabFilter(activeTabName[1] || 'compact');
			if (tabValueIndex === 0 && activeTabName[0] === 'people') {
				setTabButtonFilter('peopleView');
				setTabFilter(activeTabName.length === 2 ? `people${activeTabName[1]}` : 'peoplerelaxed');
			}
		} else if (currentUser && activeTabName.length === 2) {
			addAlignmentFilter([]);
			setTabValue(0);
			setTabButtonFilter('okrView');
			setTabFilter(activeTabName[1] || 'compact');
		}
	};

	const backUrlNavigation = () => {
		const activeTab = getLocalStorageItem('activeTab') || '';
		const activeTabName = activeTab.split('_');
		if (!props.peopleAlignmentMap) {
			const backUrl = JSON.parse(getLocalStorageItem('backUrl') || '{}');
			if (backUrl && backUrl.url === ALIGNMENTMAPS) {
				if (backUrl.tabToOpen && backUrl.tabToOpen === 'peopleView') {
					setTabValue(0);
					setTabButtonFilter('peopleView');
					setTabFilter(activeTabName.length === 2 ? `people${activeTabName[1]}` : 'peoplecompact');
					setLocalStorageItem(
						'activeTab',
						activeTabName.length === 2 ? `people_${activeTabName[1]}` : 'people_compact'
					);
					addAlignmentFilter([]);
				} else if (backUrl.tabToOpen && backUrl.tabToOpen === 'directReport') {
					setTabValue(2);
					setTabFilter(activeTabName.length === 2 ? activeTabName[1] : 'compact');
					setLocalStorageItem(
						'activeTab',
						activeTabName.length === 2 ? `directReport_${activeTabName[1]}` : 'directReport_compact'
					);
				} else if (backUrl.tabToOpen && backUrl.tabToOpen === 'okrView') {
					setTabValue(0);
					setTabButtonFilter('okrView');
					setTabFilter(activeTabName.length === 2 ? activeTabName[1] : 'compact');
					setLocalStorageItem('activeTab', activeTabName.length === 2 ? `okr_${activeTabName[1]}` : 'okr_compact');
				}
				removeLocalStorageItem('backUrl');
			}
		}
	};

	const handleBackBtn = () => {
		props.resetOkr();
		let cycleObj = JSON.parse(JSON.stringify(props.cycleIdDetails));
		props.updateCurrentCycleId(cycleObj);
	};

	return (
		<Fragment>
			<Box
				// className={
				// 	showAddGoal === true
				// 		? 'content-header content-header-primary create-card-active'
				// 		: 'content-header content-header-primary'
				// }
				className='page-subheader page-subheader-home'
				id=''
			>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						{!currentUser && props.userSelectedCycle?.cycleStatus === Enums.FUTURE_CYCLE_ID && (
							<Box className='back-to-main cycle-back-amaps'>
								<Typography variant='body2'>
									<Link className='back-to-main-link' onClick={handleBackBtn}>
										<BackArrow /> {t('back')}
									</Link>
									{t('watchingOKRS', { name: `${props.userSelectedCycle?.label}` })}
								</Typography>
							</Box>
						)}
						<Fragment>
							{currentUser ? (
								<Fragment>
									<Box className='back-to-main cycle-back-amaps'>
										<Typography variant='body2'>
											<Link className='back-to-main-link' onClick={goBackHandle}>
												<BackArrow /> {t('back')}
											</Link>
										</Typography>
									</Box>
									<Typography variant='h3' className='page-title'>
										{currentUser.firstName}'s {t('alignmentMap')}
									</Typography>
								</Fragment>
							) : (
								<Fragment>
									{/* <Typography variant='h3' className='page-title'>
										{t('alignmentMap')}
									</Typography> */}
									<AlignmentMapTabs
										t={t}
										tabValue={tabValue}
										setTabFilter={setTabFilter}
										tabFilter={tabFilter}
										handleTabChange={handleTabChange}
										setTabButtonFilter={setTabButtonFilter}
										tabButtonFilter={tabButtonFilter}
										userDetail={userDetail}
										{...props}
										isUnreadFeedback={isUnreadFeedback}
									/>
									{props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID && (
										<Box className='goal-okr-filter alignment-map-filter'>
											<AlignmentMapFilter viewPage={'alignmentMap'} viewName={tabButtonFilter} {...props} />
										</Box>
									)}
								</Fragment>
							)}
						</Fragment>
					</Box>
					<Box className={`page-subheader-right ${showAddGoal ? 'create-form-display' : ''}`}>
						{props.alignResult && props.alignResult.isLocked && !currentUser && (
							<Tooltip title={`${t('lockTooltipText')}`} arrow>
								<Button
									disabled={currentUser ? true : false}
									className='btn-circle-white lockPurple'
									onClick={() => sendUnlockRequest()}
								>
									<LockIcon />
								</Button>
							</Tooltip>
						)}

						{props.listOkrResult && !currentUser ? (
							<PersonalFeedbackMenu {...props} loggedInUser={!currentUser} userDetail={userDetail} />
						) : (
							<PersonalFeedbackAction {...props} loggedInUser={!currentUser} userDetail={userDetail} />
						)}
					</Box>
				</Box>
			</Box>

			{showAddGoal && props.listOkrSuccess !== 'pending' && (
				<Box className='alignment-create-card'>
					{props.okrMasterData && (
						<CreateObjective
							{...props}
							showAddGoalForm={showAddGoalForm}
							showAddGoal={showAddGoal}
							durationCycleDetail={durationCycleDetail}
							currentCycleEndDate={currentCycleEndDate}
							currentCycleId={currentCycleId}
							year={year}
							t={t}
							goalDetail={goalDetail}
							fetchOkr={fetchOkr}
							setGoalDetail={setGoalDetail}
							setIsGoalCreated={setIsGoalCreated}
							saveAndEditObjectiveAndKr={saveAndEditObjectiveAndKr}
							setFormSubmit={setFormSubmit}
							formSubmit={formSubmit}
							isGoalCreated={isGoalCreated}
							showApiMsgs={showApiMsgs}
							isTabChanged={isTabChanged}
							setIsTabChanged={setIsTabChanged}
							addObjectiveCallback={addObjectiveCallback}
						/>
					)}
				</Box>
			)}

			{!showAddGoal && (
				<Box className='alignment-tab-panel'>
					<AlignmentMapTabPanel value={tabValue} index={0}>
						<AlignmentMapSecondaryTabs
							{...props}
							tabValue={tabValue}
							handleTabChange={handleTabChange}
							setTabFilter={setTabFilter}
							tabFilter={tabFilter}
							setTabButtonFilter={setTabButtonFilter}
							tabButtonFilter={tabButtonFilter}
							userDetail={userDetail}
							currentUser={currentUser}
						/>
						{
							<AlignmentListing
								{...props}
								tabValue={tabValue}
								tabFilter={tabFilter}
								handleDeleteOkr={handleDeleteOkr}
								setOpenDrawer={setOpenDrawer}
								setModalProps={setModalProps}
								getAlignmentData={getAlignmentData}
								isLocked={props.alignResult && props.alignResult.isLocked}
								setIsUnreadFeedback={setIsUnreadFeedback}
								isFutureCycle={
									(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID) ||
									(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.CURRENT_CYCLE_ID)
										? false
										: true
								}
							/>
						}
					</AlignmentMapTabPanel>
					<AlignmentMapTabPanel value={tabValue} index={1}>
						<AlignmentMapTeamsSecondaryTabs
							{...props}
							calledFrom={'okrViewPrimaryTab'}
							tabValue={tabValue}
							handleTabChange={handleTabChange}
							setTabFilter={setTabFilter}
							tabFilter={tabFilter}
							setTabButtonFilter={setTabButtonFilter}
							tabButtonFilter={tabButtonFilter}
							userDetail={userDetail}
							currentUser={currentUser}
						/>
						{
							<AlignmentListing
								{...props}
								tabValue={tabValue}
								tabFilter={tabFilter}
								handleDeleteOkr={handleDeleteOkr}
								setOpenDrawer={setOpenDrawer}
								setModalProps={setModalProps}
								getAlignmentData={getAlignmentData}
								isLocked={props.alignResult && props.alignResult.isLocked}
								deleteTeamOkrKr={deleteTeamOkrKr}
								reloadTeam={reloadTeam}
								setIsUnreadFeedback={setIsUnreadFeedback}
								isFutureCycle={
									(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID) ||
									(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.CURRENT_CYCLE_ID)
										? false
										: true
								}
							/>
						}
					</AlignmentMapTabPanel>
					<AlignmentMapTabPanel value={tabValue} index={2}>
						<AlignmentMapDirectReportsSecondaryTabs
							{...props}
							tabValue={tabValue}
							handleTabChange={handleTabChange}
							setTabFilter={setTabFilter}
							tabFilter={tabFilter}
							setTabButtonFilter={setTabButtonFilter}
							tabButtonFilter={tabButtonFilter}
							userDetail={userDetail}
						/>
						{
							<AlignmentListing
								{...props}
								tabValue={tabValue}
								tabFilter={tabFilter}
								handleDeleteOkr={handleDeleteOkr}
								setOpenDrawer={setOpenDrawer}
								setModalProps={setModalProps}
								getAlignmentData={getAlignmentData}
								isLocked={props.alignResult && props.alignResult.isLocked}
								setIsUnreadFeedback={setIsUnreadFeedback}
								isFutureCycle={
									(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.PAST_CYCLE_ID) ||
									(props.userSelectedCycle && props.userSelectedCycle?.cycleStatus === Enums.CURRENT_CYCLE_ID)
										? false
										: true
								}
							/>
						}
					</AlignmentMapTabPanel>
				</Box>
			)}
			{objectiveModalProps.open && (
				<AlertDialog
					module={objectiveModalProps.module ? objectiveModalProps.module : 'goals'}
					message={objectiveModalProps.message}
					handleCloseModal={handleCloseObjectiveModal}
					modalOpen={objectiveModalProps.open}
				/>
			)}

			{openDrawer.open && openDrawer.type === 'accept' && (
				<KrAcceptAttributes
					{...props}
					loader={loader}
					goalList={myGoalList}
					open={openDrawer.open}
					okr={openDrawer.okr}
					KRType={openDrawer?.KRType || 'Standalone'}
					kr={openDrawer?.kr || {}}
					isAcceptKRFormUpdated={isAcceptKRFormUpdated}
					setIsAcceptKRFormUpdated={setIsAcceptKRFormUpdated}
					setFeedback={setFeedback}
					openDrawer={openDrawer}
					setOpenDrawer={setOpenDrawer}
					setLoader={setLoader}
					fetchOkr={fetchOkr}
					isAlignment={true}
				/>
			)}
			{openDrawer.open && openDrawer.type === 'decline' && (
				<KrDeclineAttributes
					{...props}
					loader={loader}
					goalList={myGoalList}
					open={openDrawer.open}
					okr={openDrawer.okr}
					KRType={openDrawer?.KRType || 'Standalone'}
					kr={openDrawer?.kr || {}}
					setFeedback={setFeedback}
					setModalProps={setModalProps}
					setOpenDrawer={setOpenDrawer}
					setLoader={setLoader}
					fetchOkr={fetchOkr}
					isAlignment={true}
				/>
			)}
			{feedback.open && (
				<Feedback
					feedbackOld={true}
					openFeedbackType={feedback.reqType}
					objective={feedback.okr}
					objId={feedback.goalId}
					setFeedback={setFeedback}
					userDetail={userDetail}
					currentUser={feedback.sourceContributor}
					sourceDetails={feedback.sourceContributor}
					resetDecline={resetDecline}
					krId={feedback.krId}
					{...{ ...props, type: feedback.goalType }}
				/>
			)}

			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
};
