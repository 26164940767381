import React, { Fragment, useState } from 'react';
import {
	Box,
	Avatar,
	Typography,
	Grow,
	Tooltip,
	Popper,
	Paper,
	ClickAwayListener,
	MenuItem,
	ListItemText,
	ListItemAvatar,
	MenuList,
} from '@material-ui/core';
import { LinkIcon, ProfileIcon } from '../../config/svg/CommonSvg';
import { ProgressUpArrow } from '../../config/svg/ArrowSvg';
import CircularProgress from '../OkrAction/CircularProgress';
import { getUserName, getRandomBackgroundColor, getRandomColor } from '../../config/utils';
import { OkrPopperMenu } from '../../components/OkrAction/OkrPopperMenu';
import { HOME, USER_VIEW, PEOPLEMAPS, ALIGNMENTMAPS } from '../../config/app-url';
import { setLocalStorageItem } from '../../services/StorageService';
import { ContributorsListPopover } from './ContributorsListPopover';
import { AlignmentOkrListPopover } from './AlignmentOkrListPopover';
import { OKRButton } from './OKRButton';
import ProgressLabel from './ProgressLabel';

export const OKRTabPanelCard: React.FC<any> = (props) => {
	const { data, t, panelUsed, alignmentmapTab, cardId } = props;
	const directData = data.data ? data.data : data;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const contributorDetails = directData.contributorsLastSevenDaysProgress;
	const [okranchorEl, setOkrAnchorEl] = useState<any>(null);
	const [okropen, okrsetOpen] = useState<boolean>(false);
	const [scaleValue, setScaleValue] = React.useState<any>(1);
	const [openTeamMenu, setOpenTeamMenu] = useState<boolean>(false);
	const [anchorElTeam, setAnchorElTeam] = useState<HTMLElement | null>(null);
	/**
	 * Method to redirect user to people view when click on card
	 * @param e eventObject
	 * @param employeeObj Object
	 */
	const handleCardClick = async (e: any, directData: any) => {
		const employeeId = directData.employeeId;
		e.stopPropagation();
		e.preventDefault();
		window.scroll(0, 150);
		const dataObj = 'empId=' + employeeId;
		const resp = await props.getUserById(dataObj);
		if (resp && resp.status === 200 && resp.data && resp.data.status === 200) {
			setLocalStorageItem(
				'backUrl',
				JSON.stringify({
					url: panelUsed || HOME,
					tabToOpen: 'directReport',
				})
			);

			if (panelUsed === '/alignment-maps') {
				setLocalStorageItem('currentUser', JSON.stringify(directData));
				props.history.push(PEOPLEMAPS);
				props.updateRoute(PEOPLEMAPS);
			} else {
				if (resp.data.entityList) {
					setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
				}
				props.resetOkr();
				props.history.push(USER_VIEW);
				props.updateRoute(USER_VIEW);
			}
		}
	};

	const handleProgressOpen = (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		setAnchorEl(event.currentTarget);
		setOpen(true);
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};
	const handleOpen = (event: any) => {
		setOkrAnchorEl(event.currentTarget);
		okrsetOpen(true);
		if (props.diagramInstance) {
			setScaleValue(scaleValue ? props.diagramInstance.scrollSettings.currentZoom : 1);
		}
	};
	const handleClose = () => {
		okrsetOpen(false);
		setOkrAnchorEl(null);
	};
	const handleOpenTeam = (event: any) => {
		setAnchorElTeam(event.currentTarget);
		setOpenTeamMenu(true);
	};
	const handleCloseTeam = (event: any) => {
		setAnchorElTeam(null);
		setOpenTeamMenu(false);
	};

	return (
		<Fragment>
			<Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1500 } : {})}>
				<Box
					id={cardId || ''}
					onClick={(e) => {
						handleCardClick(e, directData);
					}}
					className={`okr-tabpanel-card ${
						props.tabFilter === 'compact'
							? 'okr-tabpanel-compact-card'
							: props.tabFilter === 'ultracompact'
							? 'okr-tabpanel-ultracompact-card'
							: ''
					}`}
				>
					<Box className='okr-tabpanel-card-top'>
						<Box className='user-info'>
							<Box className='user-img'>
								{directData?.imagePath ? (
									<Avatar
										className='avatar-default'
										alt={`${directData?.firstName} ${directData?.lastName}`}
										src={directData?.imagePath}
									/>
								) : (
									<Avatar className='avatar-default'>
										{getUserName({
											firstName: directData?.firstName,
											lastName: directData?.lastName,
											fullName: directData?.fullname,
										})}
									</Avatar>
								)}
							</Box>
							<Box className='user-info-details'>
								<Box className='user-info-label'>
									<Typography variant='h4'>{`${directData?.firstName} ${directData?.lastName}`}</Typography>
									{props.tabFilter !== 'ultracompact' && (
										<Typography variant='subtitle2'>{directData?.designation}</Typography>
									)}

									{props.panelUsed === ALIGNMENTMAPS ? (
										<Typography
											className='contribution-text'
											variant='body2'
											aria-owns={open ? 'mouse-over-popover' : undefined}
											aria-haspopup='true'
											onMouseOver={handleOpen}
											onMouseLeave={handleClose}
										>
											<LinkIcon /> {directData?.contributions} Contribution(s)
											<AlignmentOkrListPopover
												{...props}
												data={data?.data}
												open={okropen}
												setOpen={okrsetOpen}
												anchorEl={okranchorEl}
												setAnchorEl={setOkrAnchorEl}
												directReportCard={true}
												okrListKey={directData.directReportsObjectives}
												scaleValue={scaleValue}
												alignmentMapPopover={alignmentmapTab ? true : false}
												popoverClassName={
													alignmentmapTab ? 'okr-popover-dropdown scale-popover-dropdown' : 'okr-popover-dropdown'
												}
											/>
										</Typography>
									) : (
										<Typography className='contribution-text' variant='body2' aria-haspopup='true'>
											<LinkIcon /> {directData?.contributions} Contribution(s)
										</Typography>
									)}
								</Box>
							</Box>
						</Box>
						<Box className='okr-tabpanel-card-actions'>
							{props.tabFilter !== 'ultracompact' ? (
								<Box className='progressChart'>
									<CircularProgress
										percentage={directData?.score}
										colorCode={directData?.directReportColorCode}
										score={directData?.score}
									/>
								</Box>
							) : (
								<Box className='progressText'>
									<Typography
										variant='body1'
										style={{
											color: directData?.directReportColorCode ? `#${directData?.directReportColorCode}` : '#bdbdbd',
										}}
									>
										{directData?.score}%
									</Typography>
								</Box>
							)}

							{props.panelUsed && (
								<Box
									onClick={(e: any) => {
										e.stopPropagation();
									}}
								>
									<OkrPopperMenu
										poperClassName={
											alignmentmapTab
												? 'alignment-popper-menu popper-menu-list okr-list-popper-menu alignment-direct-menu'
												: 'alignment-popper-menu popper-menu-list okr-list-popper-menu dashboard-direct-menu'
										}
										{...props}
										okr={directData}
										directReports={true}
										panelUsed={props.panelUsed}
										t={t}
										poperPlacement={'bottom-end'}
										poperContainer={false}
										showGiveFeedback={1}
										diagramInstance={props.diagramInstance}
									/>
								</Box>
							)}
						</Box>
					</Box>
					{props.tabFilter !== 'ultracompact' ? (
						<Box className='okr-tabpanel-card-bottom'>
							<Box className='user-progress-text'>
								<Box>
									{props.tabFilter === 'compact' ? (
										<></>
									) : (
										<Fragment>
											{directData?.keyResultCount && directData?.keyResultCount > 0 ? (
												directData?.isDeltaVisible &&
												(directData?.lastSevenDaysProgress > 0 ? (
													<Typography variant='body2' className='progress-started' onClick={handleProgressOpen}>
														<ProgressUpArrow />
														{directData?.lastSevenDaysProgress}% {t('progressLastSevenDays')}
													</Typography>
												) : (
													<Typography variant='body2' className='progress-not-started'>
														0% {t('progressLastSevenDays')}
													</Typography>
												))
											) : (
												<Typography variant='body2'>{`OKRs not yet created`}</Typography>
											)}
										</Fragment>
									)}
									<Box className='tabpanel-okr-counts'>
										<Box className='okr-counts-text'>
											<Typography variant='h6'>
												{`${directData?.objectivesCount || directData?.objectiveCount || 0} ${t('objectives')}`}
											</Typography>
											<Typography variant='h6'>{`${directData?.keyResultCount} ${t('keyResults')}`}</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							{open && (
								<ContributorsListPopover
									{...props}
									open={open}
									setOpen={setOpen}
									anchorEl={anchorEl}
									setAnchorEl={setAnchorEl}
									contributorDetails={contributorDetails}
									loginUserAvatar={false}
									poperContainer={false}
									scaleValue={scaleValue}
									contributorClassName={
										alignmentmapTab
											? 'contributor-details-popover alignment-direct-contributor'
											: 'contributor-details-popover'
									}
									alignmentMapPopover={alignmentmapTab ? true : false}
									popupView={0}
								/>
							)}
						</Box>
					) : (
						<></>
					)}
					{props.tabFilter === 'compact' || props.tabFilter === 'ultracompact' ? (
						<></>
					) : (
						<Box className='direct-reports-bottom'>
							<Box className='teams-tags'>
								<Box className='team-tag recruitment-tag'>
									{directData?.directReportsTeamResponse.length > 0 &&
										directData?.directReportsTeamResponse.map((item: any, index: number) => (
											<Fragment>
												{index === 0 && (
													<Fragment>
														<Box
															className='team-tag-text chandu'
															style={{
																backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																color: getRandomColor(item?.colorCode),
															}}
														>
															{item.teamName.length > 10 ? (
																<Tooltip title={item.teamName} aria-label='Organization Name' arrow>
																	<Typography
																		variant='body2'
																		style={{
																			color: getRandomColor(item?.colorCode),
																		}}
																	>
																		{item.teamName.substring(0, 10)}...
																	</Typography>
																</Tooltip>
															) : (
																<Typography
																	variant='body2'
																	style={{
																		color: getRandomColor(item?.colorCode),
																	}}
																>
																	{item?.teamName}
																</Typography>
															)}
														</Box>
														<Box
															className='team-progress'
															style={{
																background: item?.teamColorCode ? `#${item?.teamColorCode}` : '#bdbdbd',
															}}
														>
															<Typography variant='body2'>{item?.teamScore}%</Typography>
														</Box>
													</Fragment>
												)}
											</Fragment>
										))}
								</Box>
								{directData?.directReportsTeamResponse.length > 1 && (
									<Box
										className='direct-report-team-cont'
										aria-owns={openTeamMenu ? 'mouse-over-popover' : undefined}
										aria-haspopup='true'
										onMouseOver={handleOpenTeam}
										onMouseLeave={handleCloseTeam}
									>
										<OKRButton className={'btn-count'} text={`+${directData?.directReportsTeamResponse.length - 1}`} />
										<Popper
											className='popper-menu-list team-filter-list-menu'
											transition
											open={openTeamMenu}
											anchorEl={anchorElTeam}
											placement='bottom-start'
										>
											<Paper>
												<ClickAwayListener onClickAway={handleCloseTeam}>
													<MenuList id='direct-report-team-list'>
														{directData?.directReportsTeamResponse.length > 0 &&
															directData?.directReportsTeamResponse.map((item: any, index: number) => (
																<>
																	{index > 0 && (
																		<MenuItem key={item?.teamId} id={`team-name-${directData?.organizationName}`}>
																			<ListItemAvatar>
																				<Avatar
																					className='team-avatar-small'
																					style={{
																						backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																						color: getRandomColor(item?.colorCode),
																					}}
																					//src={team?.imagePath}
																				>
																					{getUserName({
																						firstName: '',
																						lastName: '',
																						fullName: item?.teamName,
																					})}
																				</Avatar>
																			</ListItemAvatar>
																			<ListItemText
																				primary={
																					<Typography variant='h5' className='team-filter-txt'>
																						{item?.teamName && item?.teamName.length > 12 ? (
																							<Tooltip arrow title={item?.teamName} aria-label='Teams'>
																								<Typography>{item?.teamName.substring(0, 12)}...</Typography>
																							</Tooltip>
																						) : (
																							<Typography>{item?.teamName}</Typography>
																						)}
																					</Typography>
																				}
																			/>
																			<ProgressLabel percentage={item} />
																		</MenuItem>
																	)}
																</>
															))}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Popper>
									</Box>
								)}
							</Box>
							<Box className='user-count-txt'>
								<Tooltip title={t('contributorsLabel')} arrow>
									<span>
										<ProfileIcon />
										<Typography variant='h6'>{directData?.contributorsCount}</Typography>
									</span>
								</Tooltip>
							</Box>
						</Box>
					)}
				</Box>
			</Grow>
		</Fragment>
	);
};
