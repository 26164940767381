import React from 'react';
import { Avatar, Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import noRecordFound from '../../images/search-placeholder.svg';
import { useTranslation } from 'react-i18next';
import { AnimatedIcon } from '../Common/AnimatedIcon';

export const NoListFound: React.FC<any> = (props) => {
	const { showSkeleton } = props;
	const { t } = useTranslation();
	return (
		<Box className='my-obj-listing pad-container'>
			{showSkeleton ? (
				<Box className='my-obj-listing-content no-okr-listing'>
					<Box className='my-obj-listing-inner'>
						<Box className='okr-head'>
							<List component='ul'>
								<ListItem className='okr-col1 okr-drag-wrap'>
									<Box className='okr-objective'>
										<Skeleton width='80%' animation={false}>
											<Typography>.</Typography>
										</Skeleton>
										<Skeleton width='70%' animation={false}>
											<Typography>.</Typography>
										</Skeleton>
										<Skeleton width='25%' animation={false}>
											<Button className='btn-blue-chip'></Button>
										</Skeleton>
									</Box>
								</ListItem>
								<ListItem className='okr-col2'>
									<Box className='contributors'>
										<Box className='contributors-avatar-group'>
											<Skeleton variant='circle' animation={false}>
												<Avatar className='contributor-owner' />
											</Skeleton>
											<AvatarGroup max={3} className='contributor-list'>
												<Skeleton variant='circle' animation={false}>
													<Avatar />
												</Skeleton>
												<Skeleton variant='circle' animation={false}>
													<Avatar />
												</Skeleton>
												<Skeleton variant='circle' animation={false}>
													<Avatar />
												</Skeleton>
											</AvatarGroup>
										</Box>
										<Skeleton variant='circle' width={30} height={30} animation={false}>
											<Button className='add-btn2'></Button>
										</Skeleton>
									</Box>
								</ListItem>
								<ListItem className='okr-col3'>
									<Skeleton width='40%' animation={false}>
										<Typography>.</Typography>
									</Skeleton>
									<Skeleton width='60%' animation={false}>
										<Typography>.</Typography>
									</Skeleton>
								</ListItem>
								<ListItem className='okr-col4'>
									<Box className='obj-progress'>
										<Skeleton width='15%' animation={false}>
											<Typography>.</Typography>
										</Skeleton>
										<Skeleton width='90%' animation={false} />
										<Skeleton width='60%' animation={false}>
											<Typography>.</Typography>
										</Skeleton>
									</Box>
								</ListItem>
							</List>
						</Box>
					</Box>
				</Box>
			) : (
				<Box display='flex' justifyContent='center'>
					<Box className='no-record-message' textAlign='center'>
						<Typography variant='h2' component='h2'>
							{t('NoRecord')}
						</Typography>
						<Typography variant='h3'>{t('allDone')}</Typography>
						{/* <Typography>{t('noOkr')}</Typography> */}
						<AnimatedIcon
							className='no-record-img'
							width='500'
							type='image/svg+xml'
							alt={t('globalSearchNoRecord')}
							imagePath={noRecordFound}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};
