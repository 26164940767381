import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import NotYetCheckedIn from '../../../images/not-yet-checked-in.svg';
import CheckInsMissed from '../../../images/check-ins-missed.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { EditIcon } from '../../../config/svg/CommonSvg';
import { Enums } from '../../../config/enums';
import SfEditor from '../../Admin/CommonComponent/SfEditor/SfEditor';
import { toolbarNoSimleySettings } from '../../Admin/CommonComponent/SfEditor/SfEditorEnum';
import moment from 'moment';
import AlertDialog from '../../Common/Dialog';

export const QuesAns: React.FC<any> = (props) => {
	const {
		checkInData,
		handleQuestionChange,
		handleCheckInUpdate,
		canCheckInEdit,
		viewToShow,
		editCheckIn,
		isEdit,
		// showQuestionHeading,
		selectedUser,
		selectedDate,
		setIsDataSaving,
		defaultCheckInData,
	} = props;
	const { t } = useTranslation();
	const ref = useRef<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {} });

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [isFormEdited]);

	const isDataChanged = () => {
		let isChanged = false;
		defaultCheckInData.forEach((element: any, index: number) => {
			if (element?.checkInDetails !== checkInData[index].checkInDetails) {
				if (
					(!checkInData[index].checkInDetails || checkInData[index].checkInDetails?.trim() === '' || checkInData[index].checkInDetails?.trim() === '<p></p>' ||
						checkInData[index].checkInDetails?.trim() === '<p><br></p>') && element.checkInDetails === '') {
					
				} else{
					isChanged = true;
				}
			}
		});
		return isChanged;
	}
	const handleClickOutside = (event: any) => {
		if (ref.current && !ref.current?.contains(event.target) && isFormEdited && isDataChanged()) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || (!dialogDiv[0].contains(event.target))) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({ open: true, event: event })
			}
		} else {
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			await setIsFormEdited(false);
			const { target } = modalProps.event;
			const clonedNativeEvent = document.createEvent('HTMLEvents');
			clonedNativeEvent.initEvent('click', true, false);
			target.dispatchEvent(clonedNativeEvent);
		} else {
		}
		setModalProps({ open: false, event: {} });
	};

	return (
		<>
			<div className='ques-ans-area' ref={ref}>
				<Box className='ques-ans-head'>
					{/* {showQuestionHeading() ? (
						<Typography variant='h3'>{t('checkinsHeading')}</Typography>
					) : (
						<Typography variant='h3'>{t('checkinsHeading2')}</Typography>
					)} */}
					{selectedDate?.checkInStatus === Enums.STATUS_CHECKED_ID && checkInData?.length > 0 && canCheckInEdit() && (
						<Box className='cont-style'>
							<OKRButton
								handleClick={(e) => editCheckIn()}
								icon={<EditIcon />}
								text={t('editResponses')}
								className={'btn-link'}
								disabled={isEdit}
								id={'edit-res-btn'}
							></OKRButton>

							{/* {console.log('checkInData', checkInData[0])} */}
							{checkInData[0]?.isAfterCycleCutoff && (
								<Typography className='text-style' variant='body2'>
									Last updated on {moment(checkInData[0]?.checkInSubmitDate).format('MM/DD/YYYY')}
								</Typography>
							)}
						</Box>
					)}
				</Box>

				{viewToShow === 'missed' ? (
					<Box className='checkins-no-data'>
						<Box className='no-record'>
							<AnimatedIcon
								className='no-record-img'
								width={'212'}
								type='image/svg+xml'
								alt={'Not Yet Checked In'}
								imagePath={CheckInsMissed}
							/>
							<Typography variant='h2'>{t('itwasMissedPrimaryText')}</Typography>
							<Typography>
								{t('itwasMissedSecondaryText', { userName: `${selectedUser.firstName} ${selectedUser.lastName}` })}
							</Typography>
							<Typography>{t('itwasMissedTertiaryText')}</Typography>
						</Box>
					</Box>
				) : viewToShow === 'noYetChecked' ? (
					<Box className='ques-ans-area'>
						<Box className='checkins-no-data'>
							<Box className='no-record'>
								<AnimatedIcon
									className='no-record-img'
									width={'296'}
									type='image/svg+xml'
									alt={'Not Yet Checked In'}
									imagePath={NotYetCheckedIn}
								/>
								<Typography variant='h2'>{t('notYetCheckedInLabel')}</Typography>
								<Typography>
									{t('notYetCheckedInSecondaryText', {
										userName: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
									})}
								</Typography>
							</Box>
						</Box>
					</Box>
				) : (
					<List component='ul' disablePadding className='ques-ans-list'>
						{checkInData &&
							checkInData.length > 0 &&
							checkInData.map((item: any, index: number) => (
								<ListItem key={index}>
									<Typography className='qa-list-index'>{item.checkInPointsId}</Typography>
									<Typography variant='h3'>{item.checkInPoints}</Typography>
									<Box className='ans-box editior-pane'>
										{canCheckInEdit() && !(selectedDate.checkInStatus === Enums.STATUS_CHECKED_ID && !isEdit) ? (
											<SfEditor
												className={selectedDate.checkInStatus === Enums.STATUS_CHECKED_ID && !isEdit ? 'inactive' : ''}
												//isDisabled={currentDate.checkInStatus === Enums.STATUS_CHECKED_ID && !isEdit}
												value={item.checkInDetails}
												skipBlankCheck={true}
												handleEditorChange={(value: string) => {
													setIsFormEdited(true)
													handleQuestionChange(value, item, index)
												}}
												id={`question-${index}`}
												editorId={`question-${index}`}
												toolbarSettings={toolbarNoSimleySettings}
											/>
										) : (
											<Box
												className='long-text'
												dangerouslySetInnerHTML={{ __html: item.checkInDetails ? item.checkInDetails : '--' }}
											></Box>
										)}
									</Box>
								</ListItem>
							))}
					</List>
				)}

				{canCheckInEdit() && checkInData && checkInData.length > 0 && (
					<Box className='ques-ans-list-bottom'>
						<Box className='ques-ans-notes'>
							<Typography
								className='short-des-text'
								dangerouslySetInnerHTML={{ __html: t('checkinNotes') }}
							></Typography>
						</Box>
						<Box className='ques-and-action'>
							<OKRButton
								handleClick={(e) => {
									setIsDataSaving(true);
									handleCheckInUpdate(e);
								}}
								className='btn-primary'
								disabled={(selectedDate.checkInStatus === Enums.STATUS_CHECKED_ID && !isEdit) || props.isDataSaving}
								text={selectedDate.checkInStatus === Enums.STATUS_CHECKED_ID && !isEdit ? t('submitted') : t('submit')}
								id={'ques-submit-btn'}
							/>
						</Box>
					</Box>
				)}
				{modalProps.open && (
					<AlertDialog
						module='checkinUnsaved'
						message={t('checkinUnsavedMessage')}
						handleCloseModal={handleCloseModal}
						modalOpen={modalProps.open}
					/>
				)}
			</div>
		</>
	);
};
