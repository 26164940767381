import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Typography, ListItem, Checkbox, ListItemIcon, ListItemText, List } from '@material-ui/core';
import { getUserName, getRandomBackgroundColor, getRandomColor } from '../../../config/utils';
import _ from 'lodash';
import { OKRDrawer } from '../../Common/OKRDrawer';
import GlobalSearch from '../../Common/GlobalSearch';
import { useSnackbar } from 'notistack';
import { getLocalStorageItem } from '../../../services/StorageService';
import noColleaguesImg from '../../../images/search-no-colleagues.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import AlertDialog from '../../Common/Dialog';
import '../../../styles/pages/drawer/assignContributorPanel.scss';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';

export const IndividualKrMembersList: React.FC<any> = (props) => {
	const {
		open,
		teamsLoading,
		t,
		completeOrgData,
		handleNext,
		handleCancel,
		setSelectedTeamIds,
		selectedTeamIds,
		setCompleteOrgData,
		keyAttribute,
		keyData,
		userAligned,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
		krMsg,
	} = props;
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (selectedTeamIds !== undefined && completeOrgData && completeOrgData.length > 0) {
			setSelectedTeamIds(selectedTeamIds);
		}
	}, [selectedTeamIds]);

	const childSelected = (item: any, e: any) => {
		let isChecked: boolean = e.target.checked;
		let selectedList: any[] = Object.assign([], selectedTeamIds);
		const index = selectedList.indexOf(item.id);
		if (isChecked === true && index === -1) {
			selectedList.push(item.id);
		} else if (isChecked === false) {
			if (index !== -1) {
				selectedList.splice(index, 1);
			}
		}
		setSelectedTeamIds(selectedList);
	};

	const prepareTreeChild = (childData: any) => {
		return {
			id: childData.employeeId,
			employeeId: childData.employeeId,
			fullName: childData.firstName + ' ' + childData.lastName,
			firstName: childData.firstName,
			lastName: childData.lastName,
			teamCount: childData.teamCount,
			membersCount: childData.membersCount,
			secondaryText: childData.designation,
			imagePath: childData.imagePath,
			backGroundColorCode: childData.backGroundColorCode,
			colorCode: childData.colorCode,
			canHaveForm: true,
			isParent: false,
			parentID: childData.organisationId,
			teamId: childData.organisationId,
			startDate: keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
			dueDate: keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate,
			teamName: childData.organisationName,
			teambackGroundColorCode: childData.backGroundColorCode,
			teamcolorCode: childData.colorCode,
			startValue: keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue,
			targetValue: keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue,
			isAddedNew: true,
			krAssignerMessage: krMsg,
		};
	};

	const userSelected = async (selectedUser: any) => {
		let user = selectedUser[0];
		let childExists: boolean = false;
		completeOrgData.forEach((element: any) => {
			if (element.id === user.employeeId) {
				childExists = true;
				user = element;
				user.isAddedNew = true;
			}
		});
		if (childExists === false) {
			let isAligned = await userAligned(user);
			if (isAligned === false) {
				user = prepareTreeChild(user);
				let fullList = Object.assign([], completeOrgData);
				fullList.push(user);
				setCompleteOrgData(fullList);
				childSelected(user, { target: { checked: true } });
			}
		} else if (selectedTeamIds.indexOf(user.employeeId) > 0) {
			enqueueSnackbar(`${user.firstName + ' ' + user.lastName} ${t('alreadyAlign')}`, {
				variant: 'info',
				autoHideDuration: 3000,
			});
		} else {
			let isAligned = await userAligned(user);
			if (isAligned === false) {
				childSelected(user, { target: { checked: true } });
			}
		}
		setTimeout(function () {
			let element = document.getElementById('custom-scroll-id-' + user.id);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);
	};
	const [subModalProps, setSubModalProps] = useState<any>(null);
	const [defaultCompleteOrgData, setDefaultCompleteOrgData] = useState([]);
	//callouts
	const letsAssignContributorTitle: any = useRef();
	useEffect(() => {
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel('memberListDrawer');
		}, 500);
		setDefaultCompleteOrgData(completeOrgData);

		return () => {
			props.updateCalloutPanel && props.updateCalloutPanel('');
		};
	}, []);

	useEffect(() => {
		if (
			open &&
			props.activeOnboardingPanel === 'memberListDrawer' &&
			getLocalStorageItem('memberListDrawercalloutDone') === null &&
			getLocalStorageItem('showCallouts')
		) {
			setTimeout(() => {
				setAnchorElCallouts && setAnchorElCallouts(letsAssignContributorTitle.current);
				setCallOut && setCallOut(calloutsData[11]);
			}, 800);
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
		}
	}, [props.activeOnboardingPanel, open]);

	const checkCloseForUnsavedData = (e: any) => {
		if (_.isEqual(defaultCompleteOrgData, completeOrgData)) {
			handleCancel(e);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		} else {
			setSubModalProps(true);
		}
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
			handleCancel(event);
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
		setSubModalProps(false);
	};
	return (
		<Fragment>
			<OKRDrawer
				open={open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('letsAssignContributor')}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-subpanel assign-contributor-panel-wrap'>
							<Box className='assign-contributor-panel'>
								<Box className='drawer-search-field' {...({ ref: letsAssignContributorTitle } as any)}>
									<GlobalSearch
										{...props}
										module={'myGoal'}
										onSelectedUser={userSelected}
										fetchCycleUsers={true}
										placeHolderText={t('searchColleague')}
										defaultFocus={true}
										customClass={'user-quick-search-type2'}
										customId={'indv-member-src'}
									/>
								</Box>

								{completeOrgData != null && completeOrgData.length > 0 ? (
									<Fragment>
										<Box className='teams-nested-list'>
											<List component='div' disablePadding className='assigned-team-list'>
												{completeOrgData != null && completeOrgData.length > 0 ? (
													completeOrgData.map((child: any) => (
														<ListItem
															id={`custom-scroll-id-${child.id}`}
															button
															className={`${child.isAlreadyAligned === true ? 'already-aligned-child' : ''}`}
														>
															<ListItemIcon>
																<Checkbox
																	icon={<BorderCheckboxIcon />}
																	checkedIcon={<CheckedIcon />}
																	inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
																	checked={selectedTeamIds.indexOf(child.id) !== -1}
																	onChange={(e) => {
																		childSelected(child, e);
																	}}
																	disabled={child.isAlreadyAligned === true ? true : false}
																/>
															</ListItemIcon>
															<ListItemText>
																<UserInfo userInfo={child} chartProps={props} />
															</ListItemText>
														</ListItem>
													))
												) : (
													<></>
												)}
											</List>
										</Box>
									</Fragment>
								) : teamsLoading ? (
									<Box className='teams-loader'>
										<span>{t('loading')}</span>
									</Box>
								) : (
									<Box className='user-no-record' textAlign='center'>
										<Typography variant='h4'>{t('searchAddColleague')}</Typography>
										<Typography variant='h6' className='no-sentence-case'>
											{t('searchAddMultiColleague')}
										</Typography>
										<AnimatedIcon
											className=''
											width='250'
											type='image/svg+xml'
											alt='No Colleagues'
											imagePath={noColleaguesImg}
										/>
									</Box>
								)}
							</Box>
						</Box>
					</Box>
				}
				moreButton={false}
				handleDrawerClose={(e: any) => {
					checkCloseForUnsavedData(e);
				}}
				handleSaveClick={(event: any) => {
					if (completeOrgData && completeOrgData.length > 0) {
						handleNext(1);
					}
				}}
				handleCancelClick={(e: any) => {
					checkCloseForUnsavedData(e);
				}}
				hideDefaultButton={false}
				nextButtonTooltip={
					!(completeOrgData && completeOrgData.length > 0 && selectedTeamIds.length > 0) && t('disabledNextButton')
				}
				isSaveButtonDisabled={!(completeOrgData && completeOrgData.length > 0 && selectedTeamIds.length > 0)}
				saveButtonText={'Next'}
				showBackButton={false}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				hideCloseIcon={true}
				activeOnboardingPanel={props.activeOnboardingPanel}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
		</Fragment>
	);
};

export const UserInfo = (props: any) => {
	let kr = props.userInfo;
	return (
		<Box className='user-info '>
			<Box className='user-img'>
				{kr && kr.imagePath ? (
					<Avatar alt={`${kr.fullname}`} src={kr.imagePath} />
				) : (
					<Avatar
						style={{
							backgroundColor: getRandomBackgroundColor(kr.backGroundColorCode),
							color: getRandomColor(kr.colorCode),
						}}
					>
						{getUserName({
							firstName: '',
							lastName: '',
							fullName: kr.fullName,
						})}
					</Avatar>
				)}
			</Box>
			<Box className='user-info-details'>
				<ListItemText className={`contributor-name`}>
					<Typography variant='h4'>{kr.fullName}</Typography>
					{kr.isParent && (
						<Typography
							variant='subtitle2'
							style={{
								backgroundColor: getRandomBackgroundColor(kr.parentTeamBackGroundColorCode),
								color: getRandomColor(kr.parentTeamColorCode),
							}}
						>
							{kr.secondaryText}
						</Typography>
					)}
				</ListItemText>
				{kr.isParent ? (
					<Typography variant='subtitle2'>
						{kr.teamCount !== undefined ? <span className='secondary-text-span'>{kr.teamCount} Teams</span> : ''}
						{kr.membersCount !== undefined && kr.teamCount !== undefined ? (
							<span className='secondary-text-span'> | </span>
						) : (
							''
						)}
						{kr.membersCount !== undefined && kr.membersCount !== 0 ? (
							<span className='secondary-text-span'>{kr.membersCount} Members</span>
						) : (
							<span className='secondary-text-span'>No Member</span>
						)}
					</Typography>
				) : (
					<Typography variant='subtitle2'>
						{kr.secondaryText ? <span className='secondary-text-span'>{kr.secondaryText}</span> : ''}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
