import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Slider, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { DurationCycleDetailProps } from '../dataTypes';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { SettingsIcon } from '../../../config/svg/MyGoalSvg';
import { DeleteIcon } from '../../../config/svg/CommonSvg';
import { KeyResultAttributes } from './KeyResultAttributes';
import {
	formatDate,
	getCurrentUserDetails,
	getDateRange,
	getDifferenceBetweenDates,
	getDueDays,
	getDueDaysText,
	getUserDetails,
} from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import AlertDialog from '../../Common/Dialog';
import { CommonMessages } from '../../Common/CommonMessages';
import { Enums } from '../../../config/enums';
import { getLocalStorageItem, setLocalStorageItem } from '../../../services/StorageService';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import { OkrProgressBar } from '../../OkrAction/OkrProgressBar';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';

interface keyResultFormDataProps {
	index: Number;
	keyResultName: String;
	keyResultDueDate: Date;
}

interface CreateKeyResultProps {
	currentCycleEndDate?: String;
	durationCycleDetail: DurationCycleDetailProps;
	keyData: any;
	defaultTypeChar: Number;
	isDeleted?: Boolean;
	formData?: keyResultFormDataProps;
	setIsDeleted?: any;
	addNewKR: (item: any) => void;
	updateFormDetail: (
		item: any,
		key: any,
		value: any,
		isKeyData: Boolean,
		keyAttribute: any,
		resetContribtor: any,
		stateDate?: any
	) => void;
	deleteAddedKR: (event: any, item: any) => void;
	t: (name: String) => string;
	objectiveEndDate?: string;
	objectiveStartDate?: string;
	resetKeyAttributeById: (item: any) => void;
	objectiveDetail?: any;
	goalDetail?: any;
	updateKrAttributes: (item: any) => any;
	setGoalDetail?: any;
	goalFormError?: any;
	fetchOkr: () => void;
	setShowKr?: any;
	autoSaveData?: any;
	isAutoSaving?: any;
	okrMasterData?: any;
	placeHolderTxt?: any;
	quarterSymbol?: any;
	setIsDataSaved?: any;
	setParentKeyAttribute?: (item: any) => void;
	onboardingCount?: any;
	setCallOut?: (item: any) => void;
	anchorElCallouts?: any;
	setAnchorElCallouts?: (item: any) => void;
	initialCallout?: any;
	calloutsData?: any;
	showKrCallouts?: any;
	showKrSettingCallouts?: any;
	setShowKrSettingCallouts?: (item: any) => void;
	setShowKrCallouts?: (item: any) => void;
	index?: any;
	updateCalloutPanel?: (panel: any) => void;
	activeOnboardingPanel: any;
	finalSubmitted?: Boolean;
	listOkrResult?: any;
	krFocus?: boolean;
	userSelectedCycle?: any;
	dateCycle?: any;
	editModee?: any;
	setEditModee?: any;
	currentCycleId?: any;
}

export const CreateKeyResult: React.FC<CreateKeyResultProps> = (props) => {
	const {
		durationCycleDetail,
		keyData,
		defaultTypeChar,
		t,
		updateFormDetail,
		deleteAddedKR,
		objectiveDetail,
		goalDetail,
		setGoalDetail,
		goalFormError,
		setShowKr,
		isAutoSaving,
		placeHolderTxt,
		setIsDataSaved,
		setCallOut,
		anchorElCallouts,
		setAnchorElCallouts,
		initialCallout,
		showKrCallouts,
		showKrSettingCallouts,
		setShowKrSettingCallouts,
		setShowKrCallouts,
		index,
		calloutsData,
		finalSubmitted,
		currentCycleEndDate,
		dateCycle,
		setEditModee,
	} = props;
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const loginUserAvatar = getUserDetails();
	const [openDrawer, setOpenDrawer] = useState<Boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isUpdated, setIsUpdated] = useState<Boolean>(false);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);
	const [keyAttribute, setKeyAttribute] = useState<any>({});
	const [krLoader, setKrLoader] = useState<Boolean>(false);
	const [snackbarProps, setSnackbarProps] = useState<any>({});
	const year = getLocalStorageItem('currentYear');
	const currentUser: any = getCurrentUserDetails();
	const [modalPropsOKR, setModalPropsOKR] = useState<any>({ open: false, type: '', message: '' });
	const [apiCallComplete, setApiCallComplete] = useState<any>(false);
	/** ======================================== */
	useEffect(() => {
		const { showAttributeDrawer, actionType, kr, okr } = goalDetail || {};
		if (showAttributeDrawer && actionType === 'editKR') {
			const keyDetails = okr?.assignmentTypeId === 1 ? goalDetail.okr : goalDetail.kr;

			setKeyAttribute({
				...keyDetails,
				contributors:
					keyDetails?.contributors && keyDetails.contributors.length > 0
						? keyDetails.contributors.map((item: any) => {
								//item.objectiveName = goalDetail.okr.objectiveName;
								if (!item.goalId) {
									item.krStatusId = Enums.KR_STATUS_PENDING;
								}
								return item;
						  })
						: [],
				goalStatusId: okr.goalStatusId || 2,
			});
			setOpenDrawer(true);
		} else if (showAttributeDrawer && actionType === 'addNewKr') {
			setOpenDrawer(true);
			setKeyAttribute({
				...kr,
				contributors:
					kr?.contributors && kr.contributors.length > 0
						? kr.contributors.map((item: any) => {
								item.objectiveName = goalDetail.okr.objectiveName;
								item.krStatusId = Enums.KR_STATUS_PENDING;
								return item;
						  })
						: [],
				goalStatusId: okr.goalStatusId || 2,
			});
		} else {
			setOpenDrawer(false);
		}
	}, [goalDetail]);

	useEffect(() => {
		props.setParentKeyAttribute && props.setParentKeyAttribute(keyAttribute);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyAttribute]);

	useEffect(() => {
		setSelectedDateRange(getDateRange(keyData.startDate, keyData.dueDate));
	}, [keyData.startDate, keyData.dueDate]);

	useEffect(() => {
		if (krFocus) {
			inputRefKrType.current?.focus();
			inputRefKrTypeOther.current?.focus();
		}
	}, [props.krFocus]);

	const [keyResultEndDate, setKeyResultEndDate] = useState<any>('');
	const handleDateCloseModal = (event: any, type: Number) => {
		if (type === 1) {
			if (modalPropsOKR.type === 'updateDateForKR') {
				setModalPropsOKR({ open: false, type: '', message: '', module: '', data: {} });
				updateFormDetail(
					modalPropsOKR.data.okr,
					'dueDate',
					formatDate(new Date(modalPropsOKR.data.date)),
					false,
					{},
					keyResultEndDate
				);
			}
			setKeyResultEndDate('');
		}
		setModalPropsOKR({ open: false, type: '', message: '', module: '' });
	};

	const handleKRNameChange = (event: any) => {
		const { value } = event.target;
		updateFormDetail(keyData, 'owner', loginUserAvatar?.employeeId || Enums.ZERO, false, {}, false);
		updateFormDetail(keyData, 'keyDescription', value, false, {}, false);
	};

	const handleDrawerOpen = (event: any) => {
		setEditModee(false);
		const { actionType } = goalDetail || {};
		if (actionType !== 'editKR' && actionType !== 'addNewKr') {
			setKeyAttribute({ ...keyAttribute, ...keyData });
		}
		setOpenDrawer(true);
	};

	const isKRAttributeUpdated = () => {
		if ((goalDetail && goalDetail.actionType === 'editKR') || goalDetail.actionType === 'addNewKr') {
			const keyDetails = goalDetail?.okr.assignmentTypeId === 1 ? goalDetail.okr : goalDetail.kr;
			return checkFormValues(keyDetails);
		} else {
			return checkFormValues(keyData);
		}
	};

	const checkFormValues = (keyData: any) => {
		return (keyAttribute.startDate && !getDifferenceBetweenDates(keyData.startDate, keyAttribute.startDate)) ||
			(keyAttribute.dueDate && !getDifferenceBetweenDates(keyAttribute.dueDate, keyData.dueDate)) ||
			(keyAttribute.metricId && keyAttribute.metricId !== keyData.metricId) ||
			(keyAttribute.currencyId && keyAttribute.currencyId !== keyData.currencyId) ||
			(keyAttribute.startValue && keyAttribute.startValue !== keyData.startValue) ||
			(keyAttribute.targetValue && keyAttribute.targetValue !== keyData.targetValue) ||
			(keyAttribute?.keyNotes && keyAttribute?.keyNotes !== keyData.keyNotes) ||
			(keyAttribute?.weightId && keyAttribute?.weightId !== keyData.weightId) ||
			(keyAttribute?.keyDescription && keyAttribute?.keyDescription !== keyData.keyDescription) ||
			(keyAttribute.contributors && keyAttribute.contributors.length !== keyData.contributors.length)
			? false
			: true;
	};

	const validateAddKRForm = (formData: any) => {
		if (!formData?.metricId) {
			setKRAttrDateError({
				error: t('noMetricSelected'),
				helperText: t('noMetricSelected'),
				type: 'krUnit',
			});
			return false;
		}
		if (formData?.metricId === Enums.ONE || formData?.metricId === Enums.TWO || formData?.metricId === Enums.THREE) {
			formData.startValue = !formData.startValue ? 0 : parseInt(formData.startValue);
			formData.targetValue = !formData.targetValue ? 0 : parseInt(formData.targetValue);
			if (formData.startValue === formData.targetValue) {
				setKRAttrDateError({
					error: t('startCurrentSameError'),
					helperText: t('startCurrentSameError'),
					type: 'targetValue',
				});
				return false;
			}
		}
		if (formData.keyDescription === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyDescription',
			});
			return false;
		} else if (formData.keyDescription.trim() === '') {
			setKRAttrDateError({
				error: t('keyResultError'),
				helperText: t('keyResultError'),
				type: 'keyDescription',
			});
			return false;
		}
		return true;
	};

	const handleDrawerClose = async (_event: any, type: String) => {
		setKRAttrDateError(defaultKRDateError);
		setIsUpdated(false);
		if (type === 'save') {
			setSnackbarProps({});
			const datesDifferenceInDays = moment(
				new Date(keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate || new Date())
			).isAfter(new Date(keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate || new Date()), 'days');
			if (Boolean(datesDifferenceInDays)) {
				setIsUpdated(true);
				setKRAttrDateError({
					error: t('startDateDueDateError'),
					helperText: t('startDateDueDateError'),
					type: 'Date',
				});
				setShowKr(false);
			} else {
				const { showAttributeDrawer, actionType } = goalDetail || {};
				if (showAttributeDrawer && actionType === 'editKR') {
					const data = {
						...keyAttribute,
						year,
						// isMetricChanged: keyAttributeMetric === keyAttribute.metricId ? false : true,
					};
					if (validateAddKRForm(data)) {
						setKrLoader(true);
						setLocalStorageItem('lastSavedGoal', JSON.stringify(goalDetail.okr));
						setApiCallComplete(true);
						if (data.metricId === Enums.FOUR || data.metricId === Enums.FIVE) {
							data.targetValue = 100;
						}
						const response = await props.updateKrAttributes(data);
						if (response && response.data && response.data.status === 200) {
							setApiCallComplete(false);
							setSnackbarProps({ show: true, message: response?.data?.messageList?.Result, variant: 'success' });
							setKrLoader(false);
							setGoalDetail({});
							setShowKr(false);
							props.fetchOkr();
						} else {
							setSnackbarProps({
								show: true,
								message: t('someErrorOccurred'),
								variant: 'error',
							});
							setKrLoader(false);
						}
						setKRAttrDateError(defaultKRDateError);
						setOpenDrawer(false);
						setKeyAttribute({});
					}
				} else {
					if (validateAddKRForm(keyAttribute)) {
						if (keyAttribute.metricId === 5) {
							keyAttribute.targetValue = 100;
						}
						setSnackbarProps({ show: true, message: t('krAddedSuccessMsg'), variant: 'success' });
						props.updateCalloutPanel && props.updateCalloutPanel('createOkr');
						if (showAttributeDrawer && actionType === 'addNewKr') {
							setGoalDetail({ ...goalDetail, kr: { ...goalDetail.kr, ...keyAttribute }, saved: true });
							setKRAttrDateError(defaultKRDateError);
							setOpenDrawer(false);
							setKeyAttribute({});
							setShowKr(false);
							setIsDataSaved && setIsDataSaved(false);
						} else {
							updateFormDetail(keyData, '', '', true, keyAttribute, false);
							setKRAttrDateError(defaultKRDateError);
							setOpenDrawer(false);
							setKeyAttribute({});
							setShowKr(false);
							setIsDataSaved && setIsDataSaved(false);
						}
					}
				}
			}
		} else if (type === 'close') {
			if (!isKRAttributeUpdated()) {
				setModalProps({ open: true, type: 'KRAttr', message: t('unSavedItemAlert'), module: 'nudgeTeam' });
			} else {
				setOpenDrawer(false);
				setShowKr(false);
				setGoalDetail({});
				props.updateCalloutPanel && props.updateCalloutPanel('createOkr');
			}
		}
	};

	const handleCloseModal = (event: any, type: Number) => {
		event.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'KRAttr') {
				setOpenDrawer(false);
				setIsUpdated(false);
				setKeyAttribute({});
				setShowKr(false);
				setGoalDetail({});
				props.updateCalloutPanel && props.updateCalloutPanel('createOkr');
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	const [krFocus, setKrFocus] = useState(false);
	const handleKRFocus = () => {
		setKrFocus(true);
	};
	const handleKRBlur = () => setKrFocus(false);

	//callout popup
	const inputRefKrType: any = useRef();
	const inputRefKrTypeOther: any = useRef();
	const inputRefkrSetting: any = useRef();
	const inputRefkrSettingOther: any = useRef();
	useEffect(() => {
		if (showKrCallouts) {
			callOutkrTypes();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showKrCallouts]);

	useEffect(() => {
		//Callout for Setting KR Icon
		if (showKrSettingCallouts) {
			calloutsKrSetting();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showKrSettingCallouts]);
	useEffect(() => {
		if (Boolean(keyData) && keyData.keyDescription.length >= 1 && objectiveDetail?.myGoalsDetails?.length === 1) {
			setShowKrSettingCallouts && setShowKrSettingCallouts(false);
			setShowKrCallouts && setShowKrCallouts(false);
			if (!getLocalStorageItem('showCallouts')) {
				if (setAnchorElCallouts && inputRefkrSetting && inputRefkrSetting.current) {
					setAnchorElCallouts(inputRefkrSetting.current);
				}
				setCallOut && calloutsData && setCallOut(calloutsData[5]);
				setShowKrSettingCallouts && setShowKrSettingCallouts(true);
				setTimeout(() => {
					setShowKrSettingCallouts && setShowKrSettingCallouts(false);
					setShowKrCallouts && setShowKrCallouts(false);
					setCallOut && setCallOut(initialCallout);
				}, 5000);
			}
		} else {
			if (!getLocalStorageItem('showCallouts')) {
				setShowKrSettingCallouts && setShowKrSettingCallouts(false);
				setShowKrCallouts && setShowKrCallouts(false);
				setCallOut && setCallOut(initialCallout);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyData.keyDescription, getLocalStorageItem('showCallouts')]);

	const calloutsKrSetting = () => {
		if (setAnchorElCallouts && inputRefkrSetting && inputRefkrSetting.current) {
			setAnchorElCallouts(inputRefkrSetting.current);
		}
	};

	const callOutkrTypes = () => {
		if (setAnchorElCallouts && inputRefKrType && inputRefKrType.current) {
			setAnchorElCallouts(inputRefKrType.current);
			inputRefKrType.current.focus();
		}
		setCallOut &&
			setCallOut({
				...calloutsData[2],
				text: `${
					placeHolderTxt === 'Add a key result'
						? `${t('createkrTitleGuidedText')}`
						: `${t('createkrTitleAnotherGuidedText')}`
				}`,
			});
	};

	const checkMandatoryFieldforKR = () => {
		if (keyData?.metricId === Enums.ONE || keyData?.metricId === Enums.TWO || keyData?.metricId === Enums.THREE) {
			keyData.startValue = !keyData.startValue ? 0 : keyData.startValue;
			keyData.targetValue = !keyData.targetValue ? 0 : keyData.targetValue;
			if (keyData.startValue === keyData.targetValue) {
				return true;
			}
		}
		return Boolean(keyData) && keyData.keyDescription.length >= Enums.DEFAULT_TYPE_CHAR && keyData.metricId;
	};
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>();

	useEffect(() => {
		setSelectedCycleDetails(dateCycle);
	}, [dateCycle]);

	const [allCycleDetails, setAllCycleDetails] = useState<any>([]);
	useEffect(() => {
		let getCycleDetails = JSON.parse(getLocalStorageItem('cycleDetail') || '');
		setAllCycleDetails(getCycleDetails);
	}, []);

	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [KRDateRangeDetail, setKRDateRangeDetail] = useState<any>({
		start: keyData.startDate,
		end: keyData.dueDate,
	});

	useEffect(() => {
		setKRDateRangeDetail({
			...KRDateRangeDetail,
			start: keyData.startDate,
			end: keyData.dueDate,
		});
	}, [keyData, dateCycle]);

	return (
		<Fragment>
			{keyData && (
				<Fragment key={keyAttribute.id || keyData.id}>
					{snackbarProps.show && <CommonMessages snackbarProps={snackbarProps} setSnackbarProps={setSnackbarProps} />}

					{!Boolean(goalDetail.okr) && (
						<Box className='create-kr'>
							<Box className='create-obj-col'>
								<Box className='okr-col1'>
									<TextField
										className='add-kr'
										id='create-keyresult-field'
										value={keyData.keyDescription}
										onChange={handleKRNameChange}
										onFocus={handleKRFocus}
										onBlur={handleKRBlur}
										multiline
										error={(goalFormError && goalFormError.type === 'keyDescription' && goalFormError?.error) || ''}
										helperText={
											(goalFormError && goalFormError.type === 'keyDescription' && goalFormError?.helperText) || ''
										}
										rows={2}
										placeholder={placeHolderTxt}
										InputProps={{ 'aria-label': 'Add Key Result', disableUnderline: true }}
										disabled={
											(!objectiveDetail?.objectiveName &&
												objectiveDetail?.objectiveName?.length === 0 &&
												!objectiveDetail.myGoalsDetails.find((krData: any) => krData.keyDescription !== '')) ||
											isAutoSaving ||
											objectiveDetail?.objectiveName?.trim() === ''
										}
										inputRef={index === 0 ? inputRefKrType : inputRefKrTypeOther}
									/>
								</Box>
								{(objectiveDetail?.objectiveName?.length > 0 && krFocus) ||
								(Boolean(keyData.keyDescription) && keyData.keyDescription.length >= defaultTypeChar) ? (
									<Fragment>
										<Box className='okr-col2 contributors'>
											<Box className='contributors-avatar-group'>
												<UserAvatarGroup
													{...props}
													contributorDetails={keyData?.contributors}
													avatarClassName=''
													isButtonVisible={false}
													max={3}
													handleAddUserClick={() => {}}
													loginUserAvatar={
														objectiveDetail?.isCoach === true
															? {
																	firstName: objectiveDetail?.ownerFirstName,
																	lastName: objectiveDetail?.ownerLastName,
																	imagePath: objectiveDetail?.ownerImagePath,
																	employeeId: objectiveDetail?.employeeId,
															  }
															: loginUserAvatar
													}
													currentUser={false}
													contributorDetailsType={true}
												/>
												{keyData?.contributors.length <= 0 && (
													<AvatarGroup max={3} className='contributor-list'>
														<Skeleton variant='circle' animation={false}>
															<Avatar />
														</Skeleton>
														<Skeleton variant='circle' animation={false}>
															<Avatar />
														</Skeleton>
													</AvatarGroup>
												)}
											</Box>
										</Box>
										<Box className='okr-col3'>
											<Box className='set-timeline-area'>
												<Box className='calender-date' id={'create-kr-cal-date'}>
													{keyData.keyDescription && keyData.keyDescription.trim() !== '' ? (
														<ReactDateRangePicker
															type={'CreateKR'}
															currentCycleEndDate={currentCycleEndDate}
															durationCycleDetail={durationCycleDetail}
															startDateValue={keyData.startDate}
															endDateValue={keyData.dueDate}
															minimumDate={objectiveDetail.startDate}
															maximumDate={objectiveDetail.endDate}
															isCreationFlow={true}
															handleDateSelection={(
																range: any,
																start: any,
																end: any,
																selectionType: String,
																isCycleChanged: Boolean
															) => {
																if (!isCycleChanged) {
																	if (keyData?.contributors.length >= 1) {
																		setModalPropsOKR({
																			open: true,
																			type: 'updateDateForKR',
																			message: t('updateKRAttribute'),
																			module: 'user',
																			data: { date: end, okr: keyData },
																		});
																	} else {
																		updateFormDetail(
																			keyData,
																			'dueDate',
																			formatDate(new Date(end)),
																			false,
																			{},
																			false,
																			start
																		);
																	}
																	setSelectedDateRange(range);
																}
															}}
															bottomChildComponent={getDueDaysText(
																keyData?.startDate,
																keyData.dueDate,
																selectedCycleDetails
															)}
															allCycleDetails={allCycleDetails}
															selectedCycleDetails={selectedCycleDetails}
															setSelectedCycleDetails={setSelectedCycleDetails}
															isCurrentCycle={props.userSelectedCycle.organisationCycleId}
															isCycleVisible={false}
															isEndOfMonthVisible={false}
															numberOfCalendars={Enums.TWO}
															selectedDateRange={selectedDateRange}
															setSelectedDateRange={setSelectedDateRange}
															isDateRangeTextFieldVisible={false}
															isOnlyDueDateVisible={true}
															KRDateRangeDetail={KRDateRangeDetail || null}
														/>
													) : (
														<></>
													)}
												</Box>
											</Box>
										</Box>
										<Box className='okr-col4'>
											{keyData.metricId ? (
												<OkrProgressBar
													parentOkr={objectiveDetail}
													okr={keyData}
													type={'kr'}
													t={t}
													createKr={true}
													updateProgress={() => {}}
													showEditLoader={() => {}}
													currentUser={currentUser}
													okrMasterData={props.okrMasterData}
													isScoreLocked={props.listOkrResult.isScoreLocked}
													// appDrawerInfo={props.appDrawerInfo}
												/>
											) : (
												<Box className='kr-progress'>
													<Box className='kr-progress-slider'>
														<Box className='progress-timestamp-info'>
															<Typography variant='h2'>
																0<sup>%</sup>
															</Typography>
														</Box>
														<Slider
															aria-label={t('MyGoalProgressSlider')}
															valueLabelDisplay='off'
															marks={false}
															disabled
														/>
														{/* <Typography variant='h6'>{t('noMetricSelected')}</Typography> */}
													</Box>
												</Box>
											)}
										</Box>
									</Fragment>
								) : (
									<></>
								)}
							</Box>
							<Box {...({ ref: index === 0 ? inputRefkrSetting : inputRefkrSettingOther } as any)}>
								{Boolean(keyData.keyDescription) && keyData.keyDescription.length >= defaultTypeChar ? (
									<Typography component='div' className='okr-field-actions'>
										<OKRButton
											className={
												!checkMandatoryFieldforKR() && finalSubmitted
													? 'settings-btn settings-btn-error'
													: 'settings-btn'
											}
											id='kr-setting-btn'
											handleClick={(e) => {
												props.updateCalloutPanel && props.updateCalloutPanel('');
												setTimeout(() => {
													props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
												}, 500);

												setCallOut && setCallOut(initialCallout);
												handleDrawerOpen(e);
											}}
											icon={<SettingsIcon />}
											text={t('attributesLabel')}
											title={!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('krHoverText') : ''}
											handleOver={(e) => {
												if (!getLocalStorageItem('showCallouts')) {
													setCallOut && setCallOut(initialCallout);
												}
											}}
											tooltipClassName='tooltip-layout4'
										/>
										<OKRButton
											className='delete-btn'
											id='kr-delete-btn'
											handleClick={(event) => deleteAddedKR(event, keyData)}
											icon={<DeleteIcon />}
											text={t('delete')}
											title={
												!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('deleteKeyResult') : ''
											}
										/>
										{/* <OKRButton className='add-notes-btn' icon={<AddNotesIcon />} /> */}
										{keyData?.isTyped && isAutoSaving ? (
											<Box className='spinner'>
												<Box className='bounce1'></Box>
												<Box className='bounce2'></Box>
												<Box className='bounce3'></Box>
											</Box>
										) : (
											keyData?.isTyped &&
											isAutoSaving === false && <Typography variant='body2'>{t('saved')}</Typography>
										)}
									</Typography>
								) : getLocalStorageItem('showCallouts') ? (
									<>
										{/* This is non clickable button to show callouts */}
										{index === 0 && (
											<Typography component='div' className='okr-field-actions'>
												<OKRButton
													className='settings-btn settings-btn-disable'
													handleClick={(e) => {}}
													icon={<SettingsIcon />}
													text={t('attributesLabel')}
													id='kr-setting-btn'
												/>
											</Typography>
										)}
									</>
								) : (
									<> </>
								)}
							</Box>
						</Box>
					)}
					{openDrawer && (
						<KeyResultAttributes
							open={openDrawer}
							handleDrawerClose={handleDrawerClose}
							isKRAttributeUpdated={isKRAttributeUpdated}
							setIsUpdated={setIsUpdated}
							krAttrDateError={krAttrDateError}
							setKeyAttribute={setKeyAttribute}
							keyAttribute={keyAttribute}
							setKRAttrDateError={setKRAttrDateError}
							loader={krLoader}
							setCallOut={setCallOut}
							setAnchorElCallouts={setAnchorElCallouts}
							initialCallout={initialCallout}
							apiCallComplete={apiCallComplete}
							setApiCallComplete={setApiCallComplete}
							{...props}
						/>
					)}
					{modalProps.open && (
						<AlertDialog
							module={modalProps.module ? modalProps.module : 'user'}
							message={modalProps.message}
							handleCloseModal={handleCloseModal}
							modalOpen={modalProps.open}
						/>
					)}
					{modalPropsOKR.open && (
						<AlertDialog
							module={modalPropsOKR.module ? modalPropsOKR.module : 'user'}
							message={modalPropsOKR.message}
							handleCloseModal={handleDateCloseModal}
							modalOpen={modalPropsOKR.open}
						/>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};
