import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { AddEditContributorDetail } from './AddEditContributorDetail';
import { KrContributorList } from '../KeyResult/KrContributorList';
import { OKRDrawer } from '../../Common/OKRDrawer';
import _ from 'lodash';
import AlertDialog from '../../Common/Dialog';

export const ManageContributorList: React.FC<any> = (props) => {
	const {
		open,
		setOpen,
		t,
		keyData,
		keyAttribute,
		contributorDetails,
		objectiveEndDate,
		getCurrencyIcon,
		onDeleteContributor,
		goalTypeId,
		durationCycleDetail,
		objectiveStartDate,
		setContributorDetails,
		updateKeyAttrDetail,
		setIsContributorUpdated,
		teamData,
		dueCycle,
		setCallOut,
		anchorElCallouts,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
	} = props;

	const [currentScreen, setCurrentScreen] = useState<number>(0);
	const [selectedContibutorD, setSelectedContibutorD] = useState<any>({});
	const [teamDetails, setTeamDetails] = useState<any>({});
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortingColumn, setSortingColumn] = useState('');
	const [subModalProps, setSubModalProps] = useState<any>(null);
	const [defaultContributorDetails, setDefaultContributorDetails] = useState<any>(null);

	useEffect(() => {
		if (defaultContributorDetails === null && contributorDetails) {
			setDefaultContributorDetails(contributorDetails);
		}
	}, [contributorDetails]);

	useEffect(() => {
		if (teamData && teamData !== '') {
			setTeamDetails({
				teamName: teamData.teamName,
				backGroundColorCode: teamData.backGroundColorCode,
				colorCode: teamData.colorCode,
			});
		}
	}, [teamData]);

	const handleClose = () => {
		setOpen(false);
		setCurrentScreen(0);
	};

	const handleCancel = () => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
		}, 500);
		setCurrentScreen(0);
		handleClose();
	};

	const handleBack = (screenNum: number) => {
		setCurrentScreen(screenNum);
	};

	const handleNext = async (screenNumber: number) => {
		setCurrentScreen(screenNumber);
	};

	const onEditContributor = (e: any, item: any, action: string) => {
		item.goalTypeId = goalTypeId;
		setSelectedContibutorD(item);
		setCurrentScreen(1);
	};
	const onHandleSaveClick = () => {};

	const onSaveContributorDetails = (event: any, krContributorDetail: any) => {
		let updateContributors = Object.assign([], contributorDetails);
		updateContributors.forEach((element: any, index: number) => {
			if (element.employeeId === krContributorDetail.employeeId) {
				updateContributors[index] = krContributorDetail;
			}
		});
		setContributorDetails(updateContributors);
		updateKeyAttrDetail('contributors', updateContributors);
		setIsContributorUpdated(true);
		handleBack(0);
	};
	const sortContributorsList = (e: any, column: any) => {
		let sortClass: any = 'desc';
		if (column === sortingColumn) {
			sortClass = sortOrder === 'asc' ? 'desc' : 'asc';
		}
		let list = _.orderBy(contributorDetails, column, sortClass);
		setContributorDetails(list);
		setSortOrder(sortClass);
		setSortingColumn(column);
	};
	const checkContributorsUpdated = (e: any) => {
		handleCancel();
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
		}, 500);
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
			handleCancel();
			props.updateCalloutPanel && props.updateCalloutPanel('');
			setTimeout(() => {
				props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
			}, 500);
		}
		setSubModalProps(false);
	};

	return (
		<Fragment>
			{currentScreen === 1 ? (
				<AddEditContributorDetail
					{...props}
					t={t}
					type={2}
					keyData={keyData}
					durationCycleDetail={durationCycleDetail}
					objectiveStartDate={objectiveStartDate}
					objectiveEndDate={objectiveEndDate}
					selectedContributorDetails={selectedContibutorD}
					onHandleCancelClick={() => {}}
					onSaveContributorDetailClick={onSaveContributorDetails}
					getCurrencyIcon={getCurrencyIcon}
					handleBack={handleBack}
					handleCancel={handleCancel}
					onHandleSaveClick={onHandleSaveClick}
					isIndividual={true}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					initialCallout={initialCallout}
					calloutsData={calloutsData}
					anchorElCallouts={anchorElCallouts}
					hideKrConfidence={true}
				/>
			) : (
				<OKRDrawer
					open={open}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={t('contributorsLabel')}
					children={
						<Box className='okr-drawer-content'>
							<KrContributorList
								{...props}
								contributorDetails={contributorDetails}
								keyAttribute={keyAttribute}
								keyData={keyData}
								objectiveEndDate={objectiveEndDate}
								getCurrencyIcon={getCurrencyIcon}
								onDeleteContributor={onDeleteContributor}
								onEditContributor={onEditContributor}
								teamDetails={teamDetails}
								t={props.t}
								goalTypeId={goalTypeId}
								dueCycle={dueCycle}
								sortContributorsList={sortContributorsList}
								sortOrder={sortOrder}
								sortingColumn={sortingColumn}
								setCallOut={setCallOut}
								setAnchorElCallouts={setAnchorElCallouts}
								initialCallout={initialCallout}
								calloutsData={calloutsData}
								anchorElCallouts={anchorElCallouts}
							/>
						</Box>
					}
					moreButton={false}
					handleDrawerClose={checkContributorsUpdated}
					handleSaveClick={(event: any) => {
						handleNext(1);
					}}
					handleCancelClick={checkContributorsUpdated}
					hideDefaultButton={currentScreen === 0}
					isSaveButtonDisabled={false}
					saveButtonText={'Next'}
					showBackButton={currentScreen === 1}
					drawerClassName={'main-drawer-panel main-drawer-subpanel drawer-no-footer'}
					isAnyDrawerOpened={props.isAnyDrawerOpened}
					appDrawerInfo={props.appDrawerInfo}
					// hideCloseIcon={true}
				/>
			)}
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
		</Fragment>
	);
};
