import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { resetPasswordAction, generateResetPasswordMail, updateStatus } from '../../../action/resetPassword';
import ResetPassword from '../../../components/Guest/ResetPassword';
import { updateRoute, showLoader, updatePage } from '../../../action/common';

const mapStateToProps = (state) => {
	return {
		resetPasswordStatus: state.resetPasswordReducer.resetPasswordStatus,
		pending: state.resetPasswordReducer.pending,
		messageList: state.resetPasswordReducer.messageList,
		entity: state.resetPasswordReducer.entity,
		error: state.resetPasswordReducer.error,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			resetPasswordAction,
			generateResetPasswordMail,
			updateRoute,
			showLoader,
			updatePage,
			updateStatus,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
