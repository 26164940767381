import {
	CREATE_VERSION_FEATURE,
	GET_VERSION_DETAILS,
	GET_VERSION_DETAILS_FOR_USER,
	NOTIFY_VERSION_DETAILS,
	PUBLISH_VERSION_DETAILS,
	SEND_EMAIL_DETAILS,
	UPDATE_VERSION_DETAILS,
	UPLOAD_FILE,
} from '../config/api-url';
import { deleteRequest, getRequestFreeTrial, postRequest, postRequestFreeTrial, putRequest } from '../config/auth';

export const getAllVersionAPI = (data) => getRequestFreeTrial(`${GET_VERSION_DETAILS}/${data}`, data);
export const getAllVersionForUserAPI = (data) => postRequestFreeTrial(`${GET_VERSION_DETAILS_FOR_USER}`, data);

export const createVersionDetailAPI = (data) => postRequest(`${UPDATE_VERSION_DETAILS}`, data);
export const updateVersionDetailAPI = (data) => putRequest(`${UPDATE_VERSION_DETAILS}`, data);
export const deleteVersionDetailAPI = (data) => deleteRequest(`${UPDATE_VERSION_DETAILS}/${data}`, data);
export const publishVersionAPI = (data) => putRequest(`${PUBLISH_VERSION_DETAILS}/${data}`, data);
export const notifyVersionAPI = (data) => putRequest(`${NOTIFY_VERSION_DETAILS}/${data}`, data);
export const emailSentAPI = (data, queryParams) => postRequest(`${SEND_EMAIL_DETAILS}/${queryParams}`, data);

export const createVersionFeatureAPI = (data) => postRequest(`${CREATE_VERSION_FEATURE}`, data);
export const updateVersionFeatureAPI = (data) => putRequest(`${CREATE_VERSION_FEATURE}`, data);
export const deleteVersionFeatureAPI = (data) => deleteRequest(`${CREATE_VERSION_FEATURE}/${data}`, data);
export const featureDocumentUploadAPI = (data, queryParams) => postRequest(`${UPLOAD_FILE}/${queryParams}`, data);
export const deleteFeatureDocumentAPI = (data) => deleteRequest(`${UPLOAD_FILE}/${data}`, data);
//
// export const giveFeedbackAPI = (data) => postRequest(`${GIVE_FEEDBACK}`, data);
// export const personalFeedbackAcceptRejectAPI = (data) => putRequest(`${PERSONAL_FEEDBACK_ACCEPT_REJECT}`, data);
// export const deleteNotesAPI = (data) => deleteRequest(`${GET_NOTES}?${data}`, data);
