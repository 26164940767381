import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Avatar, Box, Button, FormControl, InputBase, TextField, Typography, Tooltip } from '@material-ui/core';
import AssignContributor from '../OkrAction/AssignContributor';
import { CancelIconSvg, SearchIcon } from '../../config/svg/CommonSvg';
import { getTime, getUserName } from '../../config/utils';
import { CancelRequest, RequestFeedbackAgain, RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import AlertDialog from '../Common/Dialog';
import noRecordFound from '../../images/search-placeholder.svg';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import { ErrorIcon } from '../../config/svg/formElementIcons';

const RequestPersonalFeedback = (props) => {
	const {
		tabValue,
		changeTabValue,
		userDetail,
		setLoader,
		loader,
		handleDrawerConfirmation,
		setFormDataUpdated,
		askDetailsPersonalFeedback,
		askPersonalFeedback,
		requestOneToOnePersonalFeedback,
		requestAgainPersonalFeedback,
		cancelRequestPersonalFeedback,
		loggedInUser,
		currentUser,
		handlePersonalFeedbackClose,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const staticData = {
		raisedTypeId: 1,
		raisedForId: loggedInUser ? [] : [Boolean(currentUser) && currentUser.employeeId],
		feedbackById: userDetail ? userDetail.employeeId : '',
		feedbackOnTypeId: 3,
		requestRemark: t('askingForMsgPF'),
		selectedUser: [],
		submit: false,
	};
	const [formData, setFormData] = useState(staticData);
	const [askPFList, setAskPFList] = useState([]);
	const [sortFeedback, setSortFeedback] = useState(true);
	const [searchFeedback, setSearchFeedback] = useState('');
	const [maxFeedbackRequestId, setMaxFeedbackRequestId] = useState(0);
	const [modalProps, setModalProps] = useState({
		open: false,
		message: '',
		module: '',
		employeeId: '',
		feedbackRequestId: '',
	});
	const [expandRequestFeedback, setExpandRequestFeedback] = useState(null);

	// Reset focus on textbox on drawer open
	const callbackRef = useCallback((inputElement) => {
		setTimeout(() => {
			if (inputElement) {
				inputElement.value = '';
				inputElement.value = formData.requestRemark;
				inputElement.focus();
			}
		}, 100);
	}, []);

	useEffect(() => {
		getAPICall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabValue]);
	useEffect(() => {
		if (formData.requestRemark === t('askingForMsgPF') && formData.raisedForId && formData.raisedForId.length <= 0) {
			setFormDataUpdated(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const getAPICall = () => {
		setSearchFeedback('');
		fetchAskFeedback({
			sort: sortFeedback,
			search: '',
		});
	};
	const setMessageValue = (event) => {
		setFormDataUpdated(true);
		event.preventDefault();
		event.stopPropagation();
		setFormData({ ...formData, requestRemark: event.target.value });
	};
	const deleteJsonKey = (data) => {
		delete data['selectedUser'];
		delete data['submit'];
		return data;
	};

	const submitRequest = async (event, type) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 2) {
			handleDrawerConfirmation(event);
		} else {
			try {
				const maxFeedbackRequestId = Math.max(...askPFList.map((o) => o.feedbackRequestId), 0);
				setMaxFeedbackRequestId(maxFeedbackRequestId || -10);

				setFormData({ ...formData, submit: true });

				if (formData.requestRemark.trim() !== '' && formData.raisedForId.length > 0) {
					setLoader(true);
					deleteJsonKey(formData);
					askPersonalFeedback(formData)
						.then((response) => {
							if (response && response.data && response.data.status === 200) {
								setLoader(false);
								enqueueSnackbar(t('askingForSuccess'), {
									variant: 'success',
									autoHideDuration: 5000,
								});

								setFormData(staticData);
								setSortFeedback(true);
								setSearchFeedback('');
								if (loggedInUser) {
									changeTabValue(1);
									fetchAskFeedback({
										sort: true,
										search: '',
									});
								} else {
									handlePersonalFeedbackClose();
								}
							} else {
								setLoader(false);
								enqueueSnackbar(t('askingForFail'), {
									variant: 'error',
									autoHideDuration: 5000,
								});
							}
							setFormDataUpdated(false);
						})
						.catch((error) => {
							setLoader(false);
							setFormDataUpdated(false);
							console.error(error);
						});
				}
			} catch (e) {
				setLoader(false);
				console.error(e);
			}
		}
	};
	const removeUser = async ({ employeeId }) => {
		const user = formData.selectedUser.filter((item) => item.employeeId !== employeeId);
		const userIds = formData.selectedUser.filter((item) => item.employeeId !== employeeId);

		setFormData({
			...formData,
			selectedUser: user,
			raisedForId: [...userIds.map((item) => item.employeeId)],
		});
	};
	const onSelectedUser = async (selected) => {
		const isExist =
			formData.selectedUser && formData.selectedUser.find((item) => item.employeeId === selected[0].employeeId);
		if (selected.length > 0 && !isExist) {
			const allUser = [...formData.selectedUser, { ...formData.selectedUser, ...selected[0] }];
			const allUserIds = allUser.map((item) => item.employeeId);

			setFormData(() => ({
				...formData,
				selectedUser: allUser,
				raisedForId: allUserIds,
			}));
			setFormDataUpdated(true);
		}
	};

	const sortAskFeedbackList = (e, sort) => {
		setLoader(true);
		e.preventDefault();
		e.stopPropagation();
		setSortFeedback(sort);
		fetchAskFeedback({
			sort: sort,
			search: searchFeedback,
		});
	};

	const fetchAskFeedback = async ({ sort, search }) => {
		if (loggedInUser) {
			try {
				setLoader(true);
				askDetailsPersonalFeedback(`employeeId=${userDetail.employeeId}&sortOrder=${sort}&searchName=${search}`)
					.then((response) => {
						if (response && response.data && response.data.status === 200) {
							setAskPFList(response.data.entityList);
							setTimeout(() => {
								Boolean(maxFeedbackRequestId) && setMaxFeedbackRequestId(0);
							}, 5000);
						}
						setLoader(false);
					})
					.catch((error) => {
						setLoader(false);
						console.error(error);
					});
			} catch (e) {
				setLoader(false);
				console.error(e);
			}
		}
	};

	const requestOneOnOnePF = (employeeId) => {
		try {
			setLoader(true);
			const requestData = {
				requestType: 4,
				requestedTo: [employeeId],
				requestedFrom: userDetail ? userDetail.employeeId : '',
				onetoOneRemark: t('appreciateTimeOnetoOne'),
			};
			requestOneToOnePersonalFeedback(requestData)
				.then((response) => {
					if (response && response.data && response.data.status === 200) {
						enqueueSnackbar(`${t('requestOneOnOneForSuccess')}`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					} else {
						enqueueSnackbar(`${t('requestOneOnOneForFail')}`, {
							variant: 'error',
							autoHideDuration: 5000,
						});
					}
					setLoader(false);
				})
				.catch((error) => {
					setLoader(false);
					console.error(error);
				});
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};

	const requestAgainPF = async (feedbackRequestId) => {
		try {
			setLoader(true);

			requestAgainPersonalFeedback(`feedbackRequestId=${feedbackRequestId}`)
				.then((response) => {
					if (response && response.data && response.data.status === 200) {
						enqueueSnackbar(`${t('requestAgainForSuccess')}`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					} else {
						enqueueSnackbar(`${t('requestAgainForFail')}`, {
							variant: 'error',
							autoHideDuration: 5000,
						});
					}
					setLoader(false);
				})
				.catch((error) => {
					setLoader(false);
					console.error(error);
				});
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};

	const cancelRequestPF = async (feedbackRequestId) => {
		try {
			setLoader(true);

			cancelRequestPersonalFeedback(`feedbackRequestId=${feedbackRequestId}`)
				.then((response) => {
					if (response && response.data && response.data.status === 200) {
						enqueueSnackbar(`${t('requestCancelForSuccess')}`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
						setLoader(true);
						fetchAskFeedback({
							sort: sortFeedback,
							search: searchFeedback,
						});
					} else {
						enqueueSnackbar(`${t('requestCancelForFail')}`, {
							variant: 'error',
							autoHideDuration: 5000,
						});
					}
					setLoader(false);
				})
				.catch((error) => {
					setLoader(false);
					console.error(error);
				});
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};

	/*=========== Search User ==============*/
	const handleChange = (e) => {
		const { value } = e.target;
		e.preventDefault();
		e.stopPropagation();
		if (Boolean(value) && value.trim() !== '') {
			setSearchFeedback(value);
		} else {
			setSearchFeedback(value);
			fetchAskFeedback({
				sort: sortFeedback,
				search: value.trim(),
			});
		}
		// setSearchFeedback(e.target.value);
	};
	const searchClicked = (e) => {
		if (e.key === 'Enter') {
			fetchAskFeedback({
				sort: sortFeedback,
				search: e.target.value.trim(),
			});
		}
	};
	/*=========== Search User end ==============*/

	const handleCloseModal = (e, type) => {
		e.preventDefault();
		e.stopPropagation();

		if (type === 1) {
			if (modalProps.module === 'cancelRequest') {
				cancelRequestPF(modalProps.feedbackRequestId);
			} else if (modalProps.module === 'oneToOne') {
				requestOneOnOnePF(modalProps.employeeId);
			}
		}
		setModalProps({ open: false, message: '', module: '', employeeId: '', feedbackRequestId: '' });
	};

	const expandRequestFeedbackDetails = (data) => {
		Boolean(expandRequestFeedback) && expandRequestFeedback.date === data.date
			? setExpandRequestFeedback(null)
			: setExpandRequestFeedback(data);
	};
	return (
		<Fragment>
			{tabValue === 0 ? (
				<form className='drawer-form-fields feedback-drawer' noValidate autoComplete='off'>
					{loggedInUser && (
						<FormControl
							className='drawer-field-bottom drawer-search-field helperText-bottom'
							variant='outlined'
							style={{ width: '100%' }}
						>
							<AssignContributor
								{...props}
								goal={formData}
								onSelectedUser={onSelectedUser}
								removeUser={removeUser}
								addKey={''}
								fieldLabel={t('askingForLabelPF')}
							/>
							{formData.raisedForId.length === 0 && formData.submit && (
								<Typography variant='caption' className='error-field'>
									<ErrorIcon /> {t('selectEmployee')}
								</Typography>
							)}
						</FormControl>
					)}

					<TextField
						style={{ width: '100%' }}
						className='feedback-message helperText-bottom'
						id='request-pf-msg'
						label={t('askingForMsgLabelPF')}
						multiline
						rows={4}
						placeholder={t('typeMessageHere')}
						value={formData.requestRemark}
						inputRef={callbackRef}
						onChange={setMessageValue}
						error={formData.requestRemark.trim() === ''}
						helperText={
							formData.requestRemark.trim() === '' ? (
								<>
									<ErrorIcon />
									{t('enterValidMessage')}
								</>
							) : (
								''
							)
						}
					/>

					<Box className='okr-form-action feedback-action'>
						<Button
							id='req-pf-cancel'
							className='cancel-btn btn-link'
							onClick={(event) => submitRequest(event, 2)}
							color='primary'
						>
							{t('cancelBtn')}
						</Button>
						<Button
							className='submitBtn btn-primary'
							id='req-pf-send'
							disabled={formData.requestRemark.trim() === '' || formData.raisedForId.length === 0 || loader}
							onClick={(event) => submitRequest(event, 1)}
							color='primary'
						>
							{t('send')}
						</Button>
					</Box>
				</form>
			) : (
				<Fragment>
					<Box className='pf-filter-actions'>
						{(Boolean(askPFList) && askPFList.length > 0) || (Boolean(searchFeedback) && searchFeedback.trim()) ? (
							<Box className='search-btn'>
								<InputBase
									className={searchFeedback.trim() ? 'Mui-focused' : ''}
									placeholder={`${t('searchPeople')}…`}
									value={searchFeedback}
									inputProps={{ 'aria-label': t('searchPeople') }}
									onChange={(e) => handleChange(e)}
									onKeyDown={(e) => searchClicked(e)}
									id={'request-pf-src-field'}
								/>
								<Box className='search-icon' id='request-pf-src-icon'>
									{Boolean(searchFeedback) && searchFeedback.trim() ? (
										<Box
											style={{ transform: 'rotate(45deg)', cursor: 'pointer', zIndex: '1' }}
											onClick={(e) => {
												setSearchFeedback('');
												setSortFeedback(true);
												fetchAskFeedback({ sort: true, search: '' });
											}}
										>
											<CancelIconSvg />
										</Box>
									) : (
										<SearchIcon />
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
						{Boolean(askPFList) && askPFList.length > 0 && (
							<Box className='sorting-action' display='flex'>
								<Link
									id='filter-newest-requestpf'
									className={sortFeedback ? 'active' : ''}
									onClick={(e) => sortAskFeedbackList(e, true)}
								>
									Newest
								</Link>
								<Link
									id='filter-oldest-requestpf'
									className={sortFeedback ? '' : 'active'}
									onClick={(e) => sortAskFeedbackList(e, false)}
								>
									Oldest
								</Link>
							</Box>
						)}
					</Box>
					{Boolean(askPFList) && askPFList.length > 0 ? (
						askPFList.map((data) => (
							<Box
								className={
									Boolean(maxFeedbackRequestId) && data.feedbackRequestId > maxFeedbackRequestId
										? 'newly-highlighted feedback-comments-area pf-comments-area'
										: 'feedback-comments-area pf-comments-area'
								}
								key={`requestPF_${data.feedbackRequestId}`}
								id={`request-pf-${data.feedbackRequestId}`}
							>
								{Boolean(expandRequestFeedback) &&
								expandRequestFeedback.feedbackRequestId === data.feedbackRequestId ? (
									<Box className='feedback-comments active'>
										<Box
											className='comments-zoom-in animate animate-fadeIn'
											style={{ cursor: 'pointer' }}
											onClick={() => expandRequestFeedbackDetails(data)}
										>
											<Box
												className='personal-feed-cols'
												display='flex'
												alignItems='center'
												justifyContent='space-between'
											>
												<Box className='personal-feed-col1' display='flex' alignItems='center'>
													{data.imagePath ? (
														<Avatar alt={`${data.firstName} ${data.lastName}`} src={data.imagePath} />
													) : (
														<Avatar>{getUserName(data)}</Avatar>
													)}
													<Box>
														<Typography variant='h4'>
															{data.firstName} {data.lastName}
														</Typography>
														<Typography variant='subtitle2'>{data.designation}</Typography>
													</Box>
												</Box>
												<Box className='personal-feed-col2'>
													<Tooltip title={`${t('pfRequestedOn')}`}>
														<Typography variant='body2'>{getTime(data.date)}</Typography>
													</Tooltip>
												</Box>
												<Box
													className='personal-feed-col3'
													display='flex'
													alignItems='center'
													justifyContent='flex-end'
												>
													<Tooltip title={t('requestOnetoOne')} arrow>
														<Button
															id={`request-one-btn-${data.feedbackRequestId}`}
															onClick={() =>
																setModalProps({
																	open: true,
																	message: t('requestOnToOneMsg'),
																	module: 'oneToOne',
																	employeeId: data.employeeId,
																})
															}
														>
															<RequestOneOnOneIcon2 />
														</Button>
													</Tooltip>
													{data.status === 4 ? (
														<Typography className='cancelled'>{t('RequestPFCancelled')}</Typography>
													) : data.status === 3 ? (
														<Typography className='received'>{t('RequestPFReceived')}</Typography>
													) : (
														<Fragment>
															<Tooltip title={t('RequestPFCancelRequest')} arrow>
																<Button
																	id={`request-cancel-btn-${data.feedbackRequestId}`}
																	onClick={() =>
																		setModalProps({
																			open: true,
																			message: t('cancelRequestAlertMsg'),
																			module: 'cancelRequest',
																			feedbackRequestId: data.feedbackRequestId,
																		})
																	}
																>
																	<CancelRequest />
																</Button>
															</Tooltip>
															<Tooltip
																title={t('resendRequestNotification')}
																arrow
																classes={{ popper: 'tooltip-layout3' }}
															>
																<Button
																	id={`request-feedback-againbtn-${data.feedbackRequestId}`}
																	onClick={() => requestAgainPF(data.feedbackRequestId)}
																>
																	<RequestFeedbackAgain />
																</Button>
															</Tooltip>
														</Fragment>
													)}
												</Box>
											</Box>
											<Box className='requested-expand-mssg feedback-message'>
												<Typography>{t('FeedbackMessageLabel')}:</Typography>
												<Typography>{data.requestRemark}</Typography>
											</Box>
										</Box>
									</Box>
								) : (
									<Box className='feedback-comments'>
										<Box
											className='comments-zoom-out animate animate-fadeIn'
											style={{ cursor: 'pointer' }}
											onClick={() => expandRequestFeedbackDetails(data)}
										>
											<Box display='flex' alignItems='center' justifyContent='space-between'>
												<Box className='personal-feed-col1' display='flex' alignItems='center'>
													{data.imagePath ? (
														<Avatar alt={`${data.firstName} ${data.lastName}`} src={data.imagePath} />
													) : (
														<Avatar>{getUserName(data)}</Avatar>
													)}
													<Box>
														<Typography variant='h4'>
															{data.firstName} {data.lastName}
														</Typography>
														<Typography variant='subtitle2'>{data.designation}</Typography>
													</Box>
												</Box>
												<Box className='personal-feed-col2'>
													<Tooltip arrow title={`${t('pfRequestedOn')}`}>
														<Typography variant='body2'>{getTime(data.date)}</Typography>
													</Tooltip>
												</Box>
												<Box
													className='personal-feed-col3'
													display='flex'
													alignItems='center'
													justifyContent='flex-end'
												>
													<Tooltip title={t('requestOnetoOne')} arrow>
														<Button
															id={`request-one-btn-${data.feedbackRequestId}`}
															onClick={() =>
																setModalProps({
																	open: true,
																	message: t('requestOnToOneMsg'),
																	module: 'oneToOne',
																	employeeId: data.employeeId,
																})
															}
														>
															<RequestOneOnOneIcon2 />
														</Button>
													</Tooltip>
													{data.status === 4 ? (
														<Typography className='cancelled'>{t('RequestPFCancelled')}</Typography>
													) : data.status === 3 ? (
														<Typography className='received'>{t('RequestPFReceived')}</Typography>
													) : (
														<Fragment>
															<Tooltip title='Cancel Request' arrow>
																<Button
																	id={`request-cancel-btn-${data.feedbackRequestId}`}
																	onClick={() =>
																		setModalProps({
																			open: true,
																			message: t('cancelRequestAlertMsg'),
																			module: 'cancelRequest',
																			feedbackRequestId: data.feedbackRequestId,
																		})
																	}
																>
																	<CancelRequest />
																</Button>
															</Tooltip>
															<Tooltip
																title={t('resendRequestNotification')}
																arrow
																classes={{ popper: 'tooltip-layout3' }}
															>
																<Button
																	id={`request-feedback-againbtn-${data.feedbackRequestId}`}
																	onClick={() => requestAgainPF(data.feedbackRequestId)}
																>
																	<RequestFeedbackAgain />
																</Button>
															</Tooltip>
														</Fragment>
													)}
												</Box>
											</Box>
										</Box>
									</Box>
								)}
							</Box>
						))
					) : loader ? (
						<Typography></Typography>
					) : (
						<Box className='search-no-record-global' display='flex' justifyContent='center'>
							<Box className='no-record-message'>
								{(Boolean(askPFList) && askPFList.length > 0) || (Boolean(searchFeedback) && searchFeedback.trim()) ? (
									<Fragment>
										<Typography variant='h2' component='h2'>
											{t('CommonOOPS')}
										</Typography>
										<Typography variant='h6' className='no-sentence-case'>
											{t('globalSearchNoRecord')}
										</Typography>
									</Fragment>
								) : (
									<Typography variant='h2' component='h2'>
										{t('NoRecord')}
									</Typography>
								)}
								<AnimatedIcon
									className='no-record-img'
									width='250'
									type='image/svg+xml'
									alt={t('globalSearchNoRecord')}
									imagePath={noRecordFound}
								/>
							</Box>
						</Box>
					)}
				</Fragment>
			)}

			{modalProps.open && (
				<AlertDialog
					module={modalProps.module}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
};

export default RequestPersonalFeedback;
