import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	Typography,
	Button,
	FormHelperText,
	Link,
	Box,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import AlertDialog from '../../Common/Dialog';
import { clearLocalStorage, getLocalStorageItem } from '../../../services/StorageService';
import { LockIcon } from '../../../config/svg/CommonSvg';
import { AZURE_LOGOUTURI } from '../../../config/constant';
import { ErrorIcon } from '../../../config/svg/formElementIcons';

export default function ChangePassword(props) {
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [isFormSubmit, setIsFormSubmit] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const initialData = {
		oldPassword: '',
		newPassword: '',
		confirmPassword: '',
		isOldPasswordValid: false,
		isNewPasswordValid: false,
		isConfirmPasswordValid: false,
		formValid: false,
		disabled: userDetail.ssoLogin ? userDetail.ssoLogin : false,
	};
	const [formData, setFormData] = useState(initialData);
	const { t } = useTranslation();

	const handleDrawerConfirmation = () => {
		if (JSON.stringify(formData) !== JSON.stringify(initialData)) {
			props.setModalProps({
				open: true,
				type: 'closeDrawer',
				message: t('unSavedItemAlert'),
				module: 'profile',
			});
		} else {
			props.setProfileOpen(false);
		}
	};

	const handleCloseModal = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			submitRequest(e, 2);
		}
		setModalOpen(false);
	};

	const handleChange = (event, prop) => {
		const value = event.target.value;
		props.setFormDataUpdated(true);
		if (prop === 'oldPassword') {
			setFormData({
				...formData,
				oldPassword: value.trim(),
				formValid:
					value.trim() &&
					formData.newPassword.trim() &&
					formData.confirmPassword.trim() &&
					formData.isNewPasswordValid &&
					formData.isNewPasswordValid === formData.isConfirmPasswordValid,
			});
		} else if (prop === 'newPassword') {
			let psdValid = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/) ? true : false;
			setFormData({
				...formData,
				newPassword: value.trim(),
				isNewPasswordValid: psdValid,
				formValid:
					formData.oldPassword.trim() &&
					value.trim() &&
					formData.confirmPassword.trim() &&
					psdValid &&
					formData.oldPassword === value.trim(),
			});
		} else if (prop === 'confirmPassword') {
			setFormData({
				...formData,
				confirmPassword: value.trim(),
				isConfirmPasswordValid: formData.newPassword === value.trim(),
				formValid:
					formData.oldPassword.trim() &&
					formData.newPassword.trim() &&
					value.trim() &&
					formData.isNewPasswordValid &&
					formData.newPassword === value.trim(),
			});
		} else {
			setFormData({ ...formData, [prop]: event.target.value });
		}
	};

	const submitRequest = async (e, type) => {
		e.preventDefault();
		setIsFormSubmit(true);
		if (formData.formValid) {
			let data = {
				employeeId: formData.employeeId,
				oldPassword: formData.oldPassword,
				newPassword: formData.newPassword,
			};
			props.setLoader(true);
			let resp = await props.updateProfilePassword(data);
			if (resp.data.status === 200) {
				props.setLoader(false);
				enqueueSnackbar(t('successfullyUpdatedPassword'), { variant: 'success', autoHideDuration: 5000 });
				props.setProfileOpen(false);
				/*=== Logout User =====*/
				clearLocalStorage();
				sessionStorage.clear();
				window.location.href = AZURE_LOGOUTURI;
			} else {
				props.setLoader(false);
				const responseAPI = resp.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
			}
		}
	};

	return (
		<Fragment>
			<Box className='drawer-content'>
				<Box className='drawer-inner-content'>
					<Box className='drawer-content-panel'>
						<Box className='password-wrapper'>
							<form className='drawer-form-fields reset-password-forminfo drawer-form-fields'>
								<Box className='drawer-field-row title-wrapper'>
									<Typography className='title' variant='h3'>
										{t('resetPassword')}
									</Typography>
									{formData.disabled && <Typography variant='body2'>{t('ChangePasswordText')}</Typography>}
								</Box>
								<Box className='input-group-wrapper'>
									<Box className='drawer-input-field'>
										<InputLabel htmlFor='profile-oldpassword' className='first-lbl'>
											{t('oldPassword')}
										</InputLabel>
										<Input
											fullWidth={true}
											id='profile-oldpassword'
											type={showOldPassword ? 'text' : 'password'}
											value={formData.oldPassword}
											onChange={(e) => handleChange(e, 'oldPassword')}
											startAdornment={
												<InputAdornment position='start'>
													<LockIcon />
												</InputAdornment>
											}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														aria-label={t('ResetPasswordInputAriaLabel')}
														onClick={() => setShowOldPassword(!showOldPassword)}
														disableRipple='true'
													>
														{showOldPassword ? <Link>{t('hideBtn')}</Link> : <Link>{t('showBtn')}</Link>}
													</IconButton>
												</InputAdornment>
											}
											disabled={formData.disabled}
										/>
									</Box>
									<Box className='drawer-input-field'>
										<InputLabel htmlFor='profile-newpassword'>{t('newPassword')}</InputLabel>
										<Box className='helperText-bottom'>
											<Input
												fullWidth={true}
												id='profile-newpassword'
												type={showNewPassword ? 'text' : 'password'}
												value={formData.newPassword}
												onChange={(e) => handleChange(e, 'newPassword')}
												startAdornment={
													<InputAdornment position='start'>
														<LockIcon />
													</InputAdornment>
												}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label={t('ResetPasswordInputAriaLabel')}
															onClick={() => setShowNewPassword(!showNewPassword)}
															disableRipple='true'
														>
															{showNewPassword ? <Link>{t('hideBtn')}</Link> : <Link>{t('showBtn')}</Link>}
														</IconButton>
													</InputAdornment>
												}
												disabled={formData.disabled}
												error={isFormSubmit && !formData.isNewPasswordValid}
											/>
											{isFormSubmit && !formData.isNewPasswordValid && (
												<FormHelperText>
													<ErrorIcon />
													{t('strongPassword')}
												</FormHelperText>
											)}
										</Box>
									</Box>
									<Box className='drawer-input-field '>
										<InputLabel htmlFor='profile-confirmpassword'>{t('confirmPassword')}</InputLabel>
										<Box className='helperText-bottom'>
											<Input
												className={
													formData.newPassword === formData.confirmPassword &&
													formData.newPassword &&
													formData.confirmPassword
														? 'valid'
														: formData.newPassword &&
														  formData.confirmPassword &&
														  formData.newPassword !== formData.confirmPassword
														? 'invalid'
														: ''
												}
												fullWidth={true}
												id='profile-confirmpassword'
												type={showConfirmPassword ? 'text' : 'password'}
												value={formData.confirmPassword}
												onChange={(e) => handleChange(e, 'confirmPassword')}
												startAdornment={
													<InputAdornment position='start'>
														<LockIcon />
													</InputAdornment>
												}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton
															aria-label={t('ResetPasswordInputAriaLabel')}
															onClick={() => setShowConfirmPassword(!showConfirmPassword)}
															disableRipple='true'
														>
															{showConfirmPassword ? <Link>{t('hideBtn')}</Link> : <Link>{t('showBtn')}</Link>}
														</IconButton>
													</InputAdornment>
												}
												disabled={formData.disabled}
												error={isFormSubmit && !formData.isConfirmPasswordValid}
											/>
											{isFormSubmit && !formData.isConfirmPasswordValid && (
												<FormHelperText>
													<ErrorIcon />
													{t('passwordNotMatch')}
												</FormHelperText>
											)}
										</Box>
										{/* </FormControl> */}
									</Box>
								</Box>
							</form>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className='drawer-footer-actions'>
				<Button
					className='cancel-btn btn-link'
					color='primary'
					onClick={handleDrawerConfirmation}
					disabled={formData.disabled}
				>
					{t('cancelBtn')}
				</Button>
				<Button
					className='submitBtn btn-primary save-btn'
					color='primary'
					onClick={() => setModalOpen(true)}
					disabled={formData.disabled}
				>
					{t('saveChangesBtn')}
				</Button>
			</Box>
			{modalOpen && (
				<AlertDialog
					module='changePassword'
					message={t('ChangePasswordConfirmation')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
}
