import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { OKRDrawer } from '../Common/OKRDrawer';
import { EditOrgForm } from './EditOrgForm';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getDefaultHomeScreenList } from '../../action/common';
import { Enums } from '../../config/enums';
import { useDispatch } from 'react-redux';

export const EditOrgDrawer: React.FC<any> = (props) => {
	const { drawerProps, setDrawerProps, currentItem, moduleList } = props;
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const [orgDetail, setOrgDetail] = useState<any>({});
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	const [defaultHomeScreenList, setDefaultHomeScreenList] = useState<any>([]);

	useEffect(() => {
		getDefaultHomeScreenDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentItem?.tenantId) {
			let organisation = JSON.parse(JSON.stringify(currentItem));
			setOrgDetail(organisation);
		}
	}, []);

	const { t } = useTranslation();
	const handleDrawerAction = (event: any, type: string) => {
		if (type === 'cancel') {
			handleDrawerClose();
		} else {
			saveOrgnastionDetail();
		}
	};
	const handleDrawerClose = () => {
		setDrawerProps({ currentItem: '', open: false });
		props.setSelectedInstance({});
	};

	const handleInputBlur = (e: any) => {
		const { name, value } = e.target;
		let orgDetailObj = { ...orgDetail };
		if (name === 'purchaseLicense' && value === '') {
			orgDetailObj.purchaseLicense = 0;
			setOrgDetail(orgDetailObj);
		} else if (name === 'bufferLicense' && value === '') {
			orgDetailObj.bufferLicense = 0;
			setOrgDetail(orgDetailObj);
		}
	};

	const saveOrgnastionDetail = async () => {
		//alert(orgDetail);
		let orgDetailObj = { ...orgDetail };
		props.showLoader(true);
		let dataRes = await props.updateInstanceData(orgDetailObj);
		if (dataRes && dataRes.data && dataRes.data.status === 200) {
			props.showLoader(false);
			enqueueSnackbar('Saved successfully', {
				variant: 'success',
			});
			props.fetchTenantList();
			handleDrawerClose();
		} else {
			props.showLoader(false);
			const responseAPI = dataRes?.data?.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages}`, {
				variant: 'error',
			});
		}
	};

	const handleFieldChange = (e: any) => {
		const { name, value, checked } = e.target;
		console.log(name, checked);
		let orgDetails = { ...orgDetail };
		if (name === 'isActive') {
			orgDetails[name] = checked;
		} else if (name === 'isChatBotAllowed') {
			orgDetails[name] = checked;
		} else if (name === 'isLGAllowed') {
			orgDetails[name] = checked;
		} else {
			orgDetails[name] = value;
		}
		setOrgDetail(orgDetails);
	};

	const handleDateChange = (date: any) => {
		console.log(date);
		let orgDetails = { ...orgDetail };
		orgDetails.demoExpiryDate = date;
		setOrgDetail(orgDetails);
	};
	const handleModuleChange = (e: React.ChangeEvent<HTMLInputElement>, moduleId: any) => {
		const { value, checked } = e.target;
		let orgDetails = { ...orgDetail };
		if (checked) {
			orgDetails.modules.push(moduleId);
		} else {
			let filteredModules = orgDetails.modules.filter((module: any) => module !== moduleId);
			orgDetails = { ...orgDetails, modules: filteredModules };
		}
		setOrgDetail(orgDetails);
	};

	const handleDropDownChange = (details: any) => {
		if (details) {
			setOrgDetail({ ...orgDetail, ...details });
		}
	};
	const getDefaultHomeScreenDetails = async () => {
		try {
			const requestParams = {};
			const response: any = await dispatch(getDefaultHomeScreenList(requestParams));
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				console.log(response);
				setDefaultHomeScreenList(response?.data?.entityList);
			} else {
				setDefaultHomeScreenList([]);
			}
		} catch (error) {
			console.error(error);
			setDefaultHomeScreenList([]);
		}
	};

	return (
		<>
			<OKRDrawer
				open={drawerProps.open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel '}
				headerTitle={t('editOrganisation')}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-panel'>
							<EditOrgForm
								{...props}
								orgDetail={orgDetail}
								setSelectedDateRange={setSelectedDateRange}
								handleFieldChange={handleFieldChange}
								handleDateChange={handleDateChange}
								handleInputBlur={handleInputBlur}
								moduleList={moduleList}
								handleModuleChange={handleModuleChange}
								defaultHomeScreenList={defaultHomeScreenList}
								handleDropDownChange={handleDropDownChange}
							/>
						</Box>
					</Box>
				}
				moreButton={false}
				saveButtonText={'Save'}
				handleDrawerClose={(event) => handleDrawerAction(event, 'cancel')}
				handleSaveClick={(event) => handleDrawerAction(event, 'save')}
				handleCancelClick={(event) => handleDrawerAction(event, 'cancel')}
			/>
		</>
	);
};
