import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuList, MenuItem, Link } from '@material-ui/core';
import { HOME, ALIGNMENTMAPS, ADMIN, REPORTS, WHATS_NEW, MAILS } from '../../config/app-url';
import { adminTabPermission } from '../../config/rolePermission';

export default function UserNavigation(props) {
	const { currentRoute, updateRoute, location, resetOkr } = props;
	const { t } = useTranslation();

	useEffect(() => {
		const path = location && location.pathname ? location.pathname : '';
		resetOkr();
		updateRoute(path);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let employeePermissions = [];
	if (
		props.getPermissionStatus === 'success' &&
		props.getPermissionResult &&
		props.getPermissionResult.employeePermissions
	) {
		employeePermissions = props.getPermissionResult.employeePermissions;
	}
	return (
		<MenuList>
			<MenuItem
				id='impersonate'
				className={`home ${currentRoute === HOME ? 'active' : ''}`}
				onClick={(e) => {
					props.switchUser(e, 'user', HOME);
				}}
			>
				<Link>{t('Dashboard')}</Link>
			</MenuItem>
			<MenuItem
				id='whats-new'
				className={`${currentRoute === WHATS_NEW ? 'active' : ''}`}
				onClick={(e) => {
					props.switchUser(e, 'user', WHATS_NEW);
				}}
			>
				<Link>{t('whatsNew')}</Link>
			</MenuItem>
			<MenuItem
				id='email'
				className={`${currentRoute === MAILS ? 'active' : ''}`}
				onClick={(e) => {
					props.switchUser(e, 'user', MAILS);
				}}
			>
				<Link>{t('emails')}</Link>
			</MenuItem>
		</MenuList>
		// <MenuList>
		// 	<MenuItem
		// 		id='okrs'
		// 		className={`home ${currentRoute === HOME ? 'active' : ''}`}
		// 		onClick={(e) => {
		// 			props.switchUser(e, 'user', HOME);
		// 		}}
		// 	>
		// 		<Link>{t('okrsLabel')}</Link>
		// 	</MenuItem>
		// 	<MenuItem
		// 		id='alignmentMaps'
		// 		className={currentRoute === ALIGNMENTMAPS ? 'active' : ''}
		// 		onClick={(e) => {
		// 			props.switchUser(e, 'user', ALIGNMENTMAPS);
		// 		}}
		// 	>
		// 		<Link>{t('alignmentMaps')}</Link>
		// 	</MenuItem>
		// 	{/* <MenuItem
		// 		id='insight'
		// 		className={currentRoute === INSIGHT ? 'active' : ''}
		// 		onClick={(e) => {
		// 			props.switchUser(e, 'user', INSIGHT);
		// 		}}
		// 	>
		// 		<Link>{t('insights')}</Link>
		// 	</MenuItem> */}
		// 	<MenuItem
		// 		id='reports'
		// 		className={currentRoute === REPORTS ? 'active' : ''}
		// 		onClick={(e) => {
		// 			props.switchUser(e, 'user', REPORTS);
		// 		}}
		// 	>
		// 		<Link>{t('reports')}</Link>
		// 	</MenuItem>

		// 	{adminTabPermission(employeePermissions) ? (
		// 		<MenuItem
		// 			id='admin'
		// 			className={currentRoute === ADMIN ? 'active' : ''}
		// 			onClick={(e) => {
		// 				props.switchUser(e, 'admin', ADMIN);
		// 			}}
		// 		>
		// 			<Link>{t('admin')}</Link>
		// 		</MenuItem>
		// 	) : (
		// 		<></>
		// 	)}
		// </MenuList>
	);
}
