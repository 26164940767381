import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	userUnreadNotificationStatus: '',
	userUnreadNotificationResult: [],
	adminUnreadNotificationStatus: '',
	adminUnreadNotificationResult: [],
	listNotificationPending: false,
	listNotificationSuccess: false,
	listNotificationResult: [],
	listNotificationForAdminPending: false,
	listNotificationForAdminSuccess: false,
	listNotificationForAdminResult: [],
	readNotificationSuccess: false,
	deleteNotificationSuccess: false,
	notificationTabData: null,
	deleteNotificationResponse: null,
};

export default function notificationReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.USER_UNREAD_NOTIFICATION_PENDING:
			return {
				...state,
				userUnreadNotificationStatus: 'pending',
			};
		case actionTypes.USER_UNREAD_NOTIFICATION_SUCCESS:
			return {
				...state,
				userUnreadNotificationStatus: 'success',
				userUnreadNotificationResult: action.payload.entity,
			};
		case actionTypes.USER_UNREAD_NOTIFICATION_FAILED:
			return {
				...state,
				userUnreadNotificationStatus: 'fail',
			};
		case actionTypes.ADMIN_UNREAD_NOTIFICATION_PENDING:
			return {
				...state,
				adminUnreadNotificationStatus: '',
			};
		case actionTypes.ADMIN_UNREAD_NOTIFICATION_SUCCESS:
			return {
				...state,
				adminUnreadNotificationStatus: 'success',
				adminUnreadNotificationResult: action.payload.entity,
			};
		case actionTypes.ADMIN_UNREAD_NOTIFICATION_FAILED:
			return {
				...state,
				adminUnreadNotificationStatus: 'fail',
			};
		case actionTypes.FETCH_NOTIFICATION_PENDING:
			return {
				...state,
				listNotificationPending: true,
				listNotificationSuccess: false,
			};
		case actionTypes.FETCH_NOTIFICATION_SUCCESS:
			return {
				...state,
				listNotificationPending: false,
				listNotificationSuccess: true,
				listNotificationResult: {
					pageIndex: 1,
					pageSize: 10,
					totalRecords: 194,
					totalPages: 20,
					headerCode: 0,
					records: [
						{
							id: 97762,
							notificationsBy: 398,
							notificationsTo: 398,
							notificationsMessage: 'RESET period for Ritesh approved',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: '',
							imagePath:
								'https://unlockokrblobcdndev.azureedge.net/e648628f-f65c-40cc-8a28-c601daf26a89/ProfileImage/8b400028-f60b-4f71-9d92-9a206d50d43d.jpg',
							firstName: 'Rahul',
							lastName: 'Papnoi',
							createdOn: '2021-11-18T04:10:54.533',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 97229,
							notificationsBy: 719,
							notificationsTo: 398,
							notificationsMessage: 'ri attribute(s) have been modified by AAAashish. Please review the same.',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: 'hightlightOKR/13070/14306/1',
							imagePath: '',
							firstName: 'AAAashish',
							lastName: 'Juyal',
							createdOn: '2021-11-16T14:55:18.303',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 97227,
							notificationsBy: 719,
							notificationsTo: 398,
							notificationsMessage:
								'Hi Rahul, AAAashish has requested you to contribute to the KR ri.You may want to review it to take the best action.',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: 'KrAssignment/2/14306/13070/1',
							imagePath: '',
							firstName: 'AAAashish',
							lastName: 'Juyal',
							createdOn: '2021-11-16T14:55:17.39',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 96968,
							notificationsBy: 719,
							notificationsTo: 398,
							notificationsMessage: 'LLLL attribute(s) have been modified by AAAashish. Please review the same.',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: 'hightlightOKR/12931/14267/1',
							imagePath: '',
							firstName: 'AAAashish',
							lastName: 'Juyal',
							createdOn: '2021-11-16T07:37:29.283',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 96948,
							notificationsBy: 719,
							notificationsTo: 398,
							notificationsMessage: 'LLLL attribute(s) have been modified by AAAashish. Please review the same.',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: 'hightlightOKR/12931/14267/1',
							imagePath: '',
							firstName: 'AAAashish',
							lastName: 'Juyal',
							createdOn: '2021-11-15T19:15:02.733',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 96946,
							notificationsBy: 719,
							notificationsTo: 398,
							notificationsMessage: 'LLLL attribute(s) have been modified by AAAashish. Please review the same.',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: 'hightlightOKR/12931/14267/1',
							imagePath: '',
							firstName: 'AAAashish',
							lastName: 'Juyal',
							createdOn: '2021-11-15T19:10:49.58',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 96944,
							notificationsBy: 719,
							notificationsTo: 398,
							notificationsMessage:
								'Hi Rahul, AAAashish has requested you to contribute 30 numbers to the KR LLLL. You may want to review it to take the best action.',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: 'KrAssignment/2/14267/12931/1',
							imagePath: '',
							firstName: 'AAAashish',
							lastName: 'Juyal',
							createdOn: '2021-11-15T19:10:48.847',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 96748,
							notificationsBy: 398,
							notificationsTo: 398,
							notificationsMessage: 'RESET period for Satish approved',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: '',
							imagePath:
								'https://unlockokrblobcdndev.azureedge.net/e648628f-f65c-40cc-8a28-c601daf26a89/ProfileImage/8b400028-f60b-4f71-9d92-9a206d50d43d.jpg',
							firstName: 'Rahul',
							lastName: 'Papnoi',
							createdOn: '2021-11-15T11:43:08.68',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 96726,
							notificationsBy: 398,
							notificationsTo: 398,
							notificationsMessage: 'RESET period for Ritesh approved',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: '',
							imagePath:
								'https://unlockokrblobcdndev.azureedge.net/e648628f-f65c-40cc-8a28-c601daf26a89/ProfileImage/8b400028-f60b-4f71-9d92-9a206d50d43d.jpg',
							firstName: 'Rahul',
							lastName: 'Papnoi',
							createdOn: '2021-11-15T08:52:13.07',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
						{
							id: 94606,
							notificationsBy: 398,
							notificationsTo: 398,
							notificationsMessage: 'RESET period for Satish approved',
							applicationMasterId: 3,
							isRead: false,
							isDeleted: false,
							notificationTypeId: 25,
							messageTypeId: 1,
							url: '',
							imagePath:
								'https://unlockokrblobcdndev.azureedge.net/e648628f-f65c-40cc-8a28-c601daf26a89/ProfileImage/8b400028-f60b-4f71-9d92-9a206d50d43d.jpg',
							firstName: 'Rahul',
							lastName: 'Papnoi',
							createdOn: '2021-11-09T08:55:35.113',
							okrId: 0,
							krId: 0,
							notificationOnTypeId: 0,
						},
					],
					results: null,
				}, //action.payload.entity,
			};
		case actionTypes.FETCH_NOTIFICATION_FAILED:
			return {
				...state,
				listNotificationPending: false,
				listNotificationSuccess: false,
			};
		case actionTypes.FETCH_NOTIFICATION_ADMIN_PENDING:
			return {
				...state,
				listNotificationForAdminPending: true,
				listNotificationForAdminSuccess: false,
			};
		case actionTypes.FETCH_NOTIFICATION_ADMIN_SUCCESS:
			return {
				...state,
				listNotificationForAdminPending: false,
				listNotificationForAdminSuccess: true,
				listNotificationForAdminResult: action.payload.entity,
			};
		case actionTypes.FETCH_NOTIFICATION_ADMIN_FAILED:
			return {
				...state,
				listNotificationForAdminPending: false,
				listNotificationForAdminSuccess: false,
			};
		case actionTypes.READ_NOTIFICATION_PENDING:
			return {
				...state,
				readNotificationSuccess: false,
			};
		case actionTypes.READ_NOTIFICATION_SUCCESS:
			return {
				...state,
				readNotificationSuccess: true,
			};
		case actionTypes.READ_NOTIFICATION_FAILED:
			return {
				...state,
				readNotificationSuccess: false,
			};

		case actionTypes.DELETE_NOTIFICATION_PENDING:
			return {
				...state,
				deleteNotificationSuccess: false,
				deleteNotificationResponse: null,
			};
		case actionTypes.DELETE_NOTIFICATION_SUCCESS:
			return {
				...state,
				deleteNotificationSuccess: true,
				deleteNotificationResponse: action.payload,
			};
		case actionTypes.DELETE_NOTIFICATION_FAILED:
			return {
				...state,
				deleteNotificationSuccess: false,
				deleteNotificationResponse: null,
			};
		case actionTypes.RESET_NOTIFICATION:
			return {
				...state,
				deleteNotificationSuccess: false,
				readNotificationSuccess: false,
				deleteNotificationResponse: null,
			};
		case actionTypes.UPDATE_NOTIFICATION_TAB_DATA:
			return {
				...state,
				notificationTabData: action.payload,
			};
		default:
			return state;
	}
}
