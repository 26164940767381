import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Drawer, Button, Box, Typography, Grid, Switch, Tooltip } from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import LinearLoader from './Loader';
import { DrawerTransitionSetting } from '../../config/utils';
import { CallOutTooltip } from '../OkrAction/CallOutTooltip';
import { useTranslation } from 'react-i18next';
import { getLocalStorageItem } from '../../services/StorageService';
import { defaultCalloutProps } from '../../config/constant';
import { DefaultIcon } from '../../config/svg/CommonSvg';

interface TransitionDurationProps {
	enter?: number;
	exit?: number;
}
interface OKRDrawerProps {
	drawerClassName?: string;
	open?: boolean;
	transitionDuration?: TransitionDurationProps;
	headerTitle?: String;
	children?: React.ReactNode;
	moreButton?: React.ReactNode;
	hideDefaultButton?: Boolean;
	handleDrawerClose?: (event: any) => void;
	handleSaveClick?: (event: any) => void;
	handleCancelClick?: (event: any) => void;
	isSaveButtonDisabled?: boolean;
	loader?: boolean;
	cancelBtnText?: String;
	saveButtonText?: String;
	cancelBtnClass?: string;
	cancelBtnIcon?: React.ReactNode;
	isCancelButtonDisabled?: boolean;
	showToggle?: boolean;
	toggleText?: any;
	toggleClicked?: (event: any) => void;
	toggleValue?: any;
	showBackButton?: boolean;
	backButton?: any;
	headerPostFix?: any;
	hideCloseIcon?: boolean;
	showSubmitCallouts?: any;
	activeOnboardingPanel?: any;
	handlePrevForSubmit?: () => void;
	setShowSubmitCallouts?: (value: boolean) => void;
	isAnyDrawerOpened?: any;
	appDrawerInfo?: any;
	nextButtonTooltip?: any;
	drawerHead?: any;
	apiCallComplete?: any;
}

export const OKRDrawer: React.FC<OKRDrawerProps> = (props) => {
	const {
		drawerClassName,
		open,
		headerTitle,
		children,
		moreButton,
		hideDefaultButton = false,
		handleDrawerClose,
		handleSaveClick,
		handleCancelClick,
		isSaveButtonDisabled,
		loader,
		cancelBtnText,
		saveButtonText,
		nextButtonTooltip,
		cancelBtnClass,
		cancelBtnIcon,
		isCancelButtonDisabled,
		showToggle,
		toggleText,
		toggleClicked,
		toggleValue,
		showBackButton,
		backButton,
		headerPostFix,
		hideCloseIcon,
		showSubmitCallouts,
		activeOnboardingPanel,
		handlePrevForSubmit,
		setShowSubmitCallouts,
		drawerHead = true,
		isAnyDrawerOpened,
		appDrawerInfo,
		apiCallComplete,
	} = props;
	const { t } = useTranslation();
	//callout popup
	const initialCallout = defaultCalloutProps;
	const [callOut, setCallOut] = useState(initialCallout);
	const [anchorElCallouts, setAnchorElCallouts] = useState(null);
	const saveButtonRef: any = useRef();
	useEffect(() => {
		if (props.headerTitle === 'Objective Attributes' && getLocalStorageItem('showCallouts') && showSubmitCallouts) {
			setAnchorElCallouts(saveButtonRef.current);
			setCallOut({
				open: true,
				text: `${t('submitGuidedText')}`,
				placement: 'top-start',
				classname: 'submitGuidedText',
				currentCallout: 'okrSubmit',
				currentCalloutIndex: 0,
				prev: true,
				next: false,
				done: true,
				tooltipIcon: <DefaultIcon />,
			});
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showSubmitCallouts]);

	useEffect(() => {
		if (activeOnboardingPanel === 'okrDrawer' && getLocalStorageItem('showCallouts') === 'true') {
			setShowSubmitCallouts && setShowSubmitCallouts(false);
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOnboardingPanel]);

	useEffect(() => {
		if (isAnyDrawerOpened) {
			if (open) {
				isAnyDrawerOpened({
					...appDrawerInfo,
					isDrawerOpened: true,
				});
			}
		}
	}, [open]);

	return (
		<Drawer
			className={drawerClassName || 'main-drawer-panel main-drawer-titlepanel'}
			onClose={handleDrawerClose}
			anchor='right'
			open={open || false}
			transitionDuration={DrawerTransitionSetting}
		>
			{/* {!hideCloseIcon && ( */}
			<Button className='drawer-close-btn' id='drawer-close-btn' onClick={handleDrawerClose}>
				<RightArrowIcon />
			</Button>
			{/* )} */}
			<Box className='drawer-area'>
				{drawerHead && (
					<Box className='drawer-head'>
						<Box className='drawer-title'>
							<Typography variant='h3'>
								{headerTitle || ''}
								{headerPostFix && (
									<span>
										({headerPostFix.icon}
										{headerPostFix.value})
									</span>
								)}
							</Typography>
						</Box>

						{/* {showToggle && (
						<Typography className='coach-toggle'>
							<Grid component='label' container alignItems='center' spacing={1}>
								<Grid item>{toggleText.mainLabel}:</Grid>
								<Grid item>
									<Switch
										name='checkedToggle'
										checked={toggleValue === true}
										onChange={(event) => {
											toggleClicked && toggleClicked(event);
										}}
									/>
								</Grid>
								<Grid item>{toggleText.label}</Grid>
							</Grid>
						</Typography>
					)} */}
					</Box>
				)}
				{loader && (
					<Box className='drawer-loader'>
						<LinearLoader />
					</Box>
				)}
				<Box className='drawer-content'>
					{/* Need to render children */}
					{children}
				</Box>
				<Box className='drawer-footer-actions' display='flex' justifyContent='flex-end'>
					<Box>
						{Boolean(showBackButton) && backButton}
						<>
							{!Boolean(hideDefaultButton) && !Boolean(showBackButton) ? (
								<OKRButton
									icon={cancelBtnIcon}
									disabled={isCancelButtonDisabled || false}
									className={cancelBtnClass || 'btn-link'}
									id={'btn-cancel'}
									text={cancelBtnText || 'Cancel'}
									handleClick={handleCancelClick}
								/>
							) : (
								<></>
							)}
						</>
					</Box>
					<Box className='display-flex'>
						{Boolean(showBackButton) && (
							<>
								{!Boolean(hideDefaultButton) ? (
									<OKRButton
										icon={cancelBtnIcon}
										disabled={isCancelButtonDisabled || false}
										className={cancelBtnClass || 'btn-link'}
										id={'btn-cancel'}
										text={cancelBtnText || 'Cancel'}
										handleClick={handleCancelClick}
									/>
								) : (
									<></>
								)}
							</>
						)}
						{/* Two button will be default */}
						{!Boolean(hideDefaultButton) ? (
							<Box {...({ ref: saveButtonRef } as any)}>
								<OKRButton
									disabled={isSaveButtonDisabled || false}
									title={
										activeOnboardingPanel === 'memberListDrawer' && isSaveButtonDisabled && saveButtonText === 'Next'
											? nextButtonTooltip
											: ''
									}
									className='btn-primary'
									id={'btn-save'}
									text={saveButtonText || 'Save'}
									handleClick={handleSaveClick}
									//handleOver={callOutSaveButton}
									//handleLeave={callOutClose}
									buttonType={
										activeOnboardingPanel === 'memberListDrawer' && isSaveButtonDisabled && saveButtonText === 'Next'
											? 'disableButtonTooltip'
											: ''
									}
								/>
							</Box>
						) : (
							<></>
						)}
						{/* Add more button */}
						{moreButton}
					</Box>
				</Box>
			</Box>

			<CallOutTooltip
				open={callOut.open}
				anchorEl={anchorElCallouts}
				setAnchorEl={setAnchorElCallouts}
				setCallOut={setCallOut}
				text={t(callOut.text)}
				placement={callOut.placement}
				classname={callOut.classname}
				handlePrev={() => {
					setShowSubmitCallouts && setShowSubmitCallouts(false);
					setAnchorElCallouts(null);
					setCallOut(initialCallout);
					handlePrevForSubmit && handlePrevForSubmit();
				}}
				handleNext={() => {}}
				showNext={callOut.next}
				showPrevious={callOut.prev}
				showDone={callOut.done}
				activeOnboardingPanel={activeOnboardingPanel}
			/>
		</Drawer>
	);
};
