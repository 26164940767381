import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { ImportOkrTabs, ImportOkrTabPanel } from './ImportOkrTabs';
import { OkrLibrary } from './OkrLibrary';
import { MyOkrs } from './MyOkrs';
import { PeopleSearch } from './PeopleSearch';

export const ImportOKR: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { setOkrKrSelectionData, tabSelected, setTabSelected } = props;

	const handleTabChange = (event: any, newValue: any) => {
		setTabSelected(newValue);
		setOkrKrSelectionData({});
	};
	return (
		<>
			<ImportOkrTabs t={t} tabSelected={tabSelected} handleTabChange={handleTabChange} {...props} />
			<Box className='drawer-tabs-panel import-okr-tabpanel'>
				<ImportOkrTabPanel value={tabSelected} index={0} className='my-okrs-tab'>
					<MyOkrs t={t} />
				</ImportOkrTabPanel>
				{/* <ImportOkrTabPanel value={tabSelected} index={1} className='people-search-tab'>
					<PeopleSearch t={t} />
				</ImportOkrTabPanel> */}
				<ImportOkrTabPanel value={tabSelected} index={2} className='okr-library-tab'>
					<OkrLibrary {...props} t={t} />
				</ImportOkrTabPanel>
			</Box>
		</>
	);
};
