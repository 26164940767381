import React, { useEffect, useState } from 'react';
import { Box, FormLabel, Modal, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import CkEditor from '../Common/CkEditor/CkEditor';
import { MultiSelect } from '../Admin/CommonComponent/MultiSelect';
import { useDispatch } from 'react-redux';
import { getTenantList } from '../../action/common';
import { NoSearchRecord } from '../Admin/CommonComponent/NoSearchRecord';

export const AddNewMailPopup: React.FC<any> = (props) => {
	const {
		featureType,
		editableData,
		handleClose,
		open,
		formData,
		isError,
		handleChangeValue,
		setFormData,
		setFormEdited,
		formEdited,
		handleSaveClick,
		imageList,
		setImageList,
		tenantOptions,
		setTenantOptions,
		selectedTenant,
		setSelectedTenant,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		handleGetTenantList('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (featureType === 'Edit') {
			let allSelectedTenants: any[] = [];
			if (editableData?.tenantId.length > 0) {
				allSelectedTenants = tenantOptions.filter((tenant: any) => editableData?.tenantId.includes(tenant.value));
			}
			setSelectedTenant(allSelectedTenants);
			setFormData({
				...formData,
				systemEmailId: editableData?.systemEmailId || '',
				subject: editableData?.subject || '',
				body: editableData?.body || '',
				status: editableData?.status || '',
				tenantId: allSelectedTenants || [],
			});
		} else {
			setFormData({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [featureType]);

	const handleGetTenantList = async (value: string) => {
		const resp: any = await dispatch(getTenantList());
		if (resp && resp?.status === 200) {
			let list = resp?.data?.entityList;
			if (list && list.length > 0) {
				setTenantOptions(
					list.map((tenantData: any) => {
						return {
							label: `${tenantData.subDomain}`,
							value: tenantData.tenantId,
						};
					})
				);
			} else {
				setTenantOptions([]);
			}
		} else {
			setTenantOptions([]);
		}
	};

	return (
		<>
			{open && (
				<>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby='simple-modal-title'
						aria-describedby='simple-modal-body'
						className='version-modal mail-modal'
						disableEscapeKeyDown
						disableEnforceFocus
					>
						<Box className='version-modal-inner'>
							<Box className='modal-content'>
								<form className='drawer-form-fields' noValidate autoComplete='off'>
									<Box className='drawer-input-field drawer-input-field-col2'>
										<TextField
											fullWidth
											id='subject'
											required
											className='helperText-bottom'
											label={<>{t('subject')}</>}
											placeholder={t('subject')}
											name='subject'
											value={formData?.subject || ''}
											error={isError?.subject}
											helperText={
												isError?.subject && (
													<>
														<ErrorIcon />
														{t('subjectValidation')}
													</>
												)
											}
											onChange={handleChangeValue}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{ 'aria-label': t('subject'), disableUnderline: true }}
										/>
									</Box>
									<Box className='drawer-input-field'>
										<Box className={`${isError?.tenantId ? 'helperText-bottom' : ''}`}>
											<FormLabel className={`${isError?.tenantId ? 'Mui-error' : ''}`} component='legend'>
												Tenants
											</FormLabel>
											<MultiSelect
												key={'reportingTo'}
												id='change-reporting'
												selectedOptions={selectedTenant}
												optionsList={tenantOptions}
												onSelectOption={(value: any) => {
													setSelectedTenant(value);
													//setTenantOptions([]);
													let allSelectedTenants = value.map((tenant: any) => tenant.value);
													setFormData({ ...formData, tenantId: allSelectedTenants });
													setFormEdited(true);
												}}
												isMulti={true}
												placeHolder={t('Select Tenant')}
												noOptionsMessage={<NoSearchRecord />}
												fetchAsyncData={true}
												//isSingleSelection={true}
												performSearch={handleGetTenantList}
												//closeMenuOnSelect={true}
												labelTemplate={'avatarLabel'}
												selectClassName={'select-search-dropdown select-search-dropdown-top select-search-icon'}
												selectClassNamePrefix={'react-select'}
												blurCalled={() => {
													//setTenantOptions([]);
												}}
												error={isError?.tenantId}
												helperText={
													isError?.tenantId && (
														<>
															<ErrorIcon />
															{t('Tenant is required for send email')}
														</>
													)
												}
											/>
										</Box>
									</Box>

									<Box className='drawer-input-field '>
										<Box className={`helperText-bottom ${isError?.body ? 'error-des' : ''}`}>
											<CkEditor
												{...props}
												placeholder={
													<>
														Email Content <sup>*</sup>
													</>
												}
												uploadImage={true}
												value={formData?.body || ''}
												handleEditorChange={(value: any) => {
													setFormData({ ...formData, body: value });
													if (value !== editableData?.body) {
														setFormEdited(true);
													}
												}}
												setImageList={setImageList}
												imageList={imageList}
												editorType={4}
											/>
											{isError?.body && (
												<Typography className='error-field'>
													<ErrorIcon />
													{t('emailContentValidation')}
												</Typography>
											)}
										</Box>
									</Box>
								</form>
							</Box>
							<Box className='modal-footer'>
								<Box>
									<OKRButton className='btn-link' text={t('cancelBtn')} id='cancel-btn' handleClick={handleClose} />
								</Box>
								<Box>
									<OKRButton
										className='btn-link'
										text={t('saveDraft')}
										id='save-draft-btn'
										handleClick={(e: any) => handleSaveClick(e, 'draft')}
										disabled={featureType === 'Edit' ? (!formEdited ? true : false) : false}
									/>
									<OKRButton
										className='btn-primary'
										text={t('sendEmail')}
										id='save-btn'
										handleClick={(e: any) => handleSaveClick(e, 'sent')}
									/>
								</Box>
							</Box>
						</Box>
					</Modal>
				</>
			)}
		</>
	);
};
