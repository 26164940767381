import React from 'react';

export const AlertDeleteIcon = () => (
	<svg width='57' height='46' viewBox='0 0 57 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='34' cy='23' r='23' fill='#A1ABB9' fillOpacity='0.1' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.34204 10.6094H35.6858V37.8333C35.6858 40.4107 33.5964 42.5 31.0191 42.5H9.00871C6.43138 42.5 4.34204 40.4107 4.34204 37.8333V10.6094ZM8.34204 14.6094V37.8333C8.34204 38.2015 8.64052 38.5 9.00871 38.5H31.0191C31.3873 38.5 31.6858 38.2015 31.6858 37.8333V14.6094H8.34204Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.26392 14.6094H30.7639V9.5C30.7639 6.18629 28.0776 3.5 24.7639 3.5H15.2639C11.9502 3.5 9.26392 6.18629 9.26392 9.5V14.6094ZM13.2639 10.6094V9.5C13.2639 8.39543 14.1593 7.5 15.2639 7.5H24.7639C25.8685 7.5 26.7639 8.39543 26.7639 9.5V10.6094H13.2639Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1 12.4119C1 11.3073 1.89543 10.4119 3 10.4119L37.0278 10.4119C38.1323 10.4119 39.0278 11.3073 39.0278 12.4119C39.0278 13.5164 38.1323 14.4119 37.0278 14.4119L3 14.4119C1.89543 14.4119 1 13.5164 1 12.4119Z'
			fill='#292929'
		/>
	</svg>
);
export const AlertUnsavedIcon = () => (
	<svg width='57' height='46' viewBox='0 0 57 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='34' cy='23' r='23' fill='#A1ABB9' fillOpacity='0.1' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M22 4.8501C22.765 4.8501 23.4751 5.24746 23.8752 5.89946L43.1252 37.2697C43.5418 37.9486 43.5589 38.7996 43.1699 39.4947C42.7809 40.1898 42.0466 40.6203 41.25 40.6203H2.75005C1.95353 40.6203 1.21922 40.1898 0.830231 39.4947C0.441245 38.7996 0.458347 37.9486 0.874942 37.2697L20.1249 5.89946C20.525 5.24746 21.2351 4.8501 22 4.8501ZM6.68124 36.2203H37.3189L22 11.2565L6.68124 36.2203Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M22 18.05C23.215 18.05 24.2 19.035 24.2 20.25L24.2 25.7501C24.2 26.9651 23.215 27.9501 22 27.9501C20.7849 27.9501 19.7999 26.9651 19.8 25.7501L19.8 20.25C19.8 19.035 20.7849 18.05 22 18.05Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M22 29.05C23.2151 29.05 24.2001 30.035 24.2001 31.25L24.2001 31.8C24.2001 33.0151 23.2151 34 22 34C20.785 34 19.8 33.0151 19.8 31.8L19.8 31.25C19.8 30.035 20.785 29.05 22 29.05Z'
			fill='#292929'
		/>
	</svg>
);

export const OneToOneIcon = () => (
	<svg width='57' height='46' viewBox='0 0 57 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='34' cy='23' r='23' fill='#A1ABB9' fillOpacity='0.1' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M5.38887 20.7236C5.38887 16.8613 8.46284 13.6499 12.3528 13.6499C16.2428 13.6499 19.3168 16.8613 19.3168 20.7236C19.3168 24.5859 16.2428 27.7973 12.3528 27.7973C8.46284 27.7973 5.38887 24.5859 5.38887 20.7236ZM12.3528 17.8499C10.8703 17.8499 9.58887 19.0921 9.58887 20.7236C9.58887 22.3551 10.8703 23.5973 12.3528 23.5973C13.8354 23.5973 15.1168 22.3551 15.1168 20.7236C15.1168 19.0921 13.8354 17.8499 12.3528 17.8499ZM22.2867 20.7236C22.2867 16.808 25.4726 13.6499 29.3827 13.6499C33.2929 13.6499 36.4788 16.808 36.4788 20.7236C36.4788 24.6392 33.2929 27.7973 29.3827 27.7973C25.4726 27.7973 22.2867 24.6392 22.2867 20.7236ZM29.3827 17.8499C27.7744 17.8499 26.4867 19.1454 26.4867 20.7236C26.4867 22.3018 27.7744 23.5973 29.3827 23.5973C30.9911 23.5973 32.2788 22.3018 32.2788 20.7236C32.2788 19.1454 30.9911 17.8499 29.3827 17.8499ZM4.79032 37.2749H19.9151C19.7792 36.3988 19.438 35.5976 18.9463 34.9209C17.9878 33.6018 16.4736 32.771 14.7848 32.771H9.92086C7.36967 32.771 5.18995 34.6907 4.79032 37.2749ZM2.6249 41.4749C1.4651 41.4749 0.524902 40.5347 0.524902 39.3749V38.1311C0.524902 32.8953 4.68783 28.571 9.92086 28.571H14.7848C17.0934 28.571 19.2001 29.4182 20.8283 30.8109C22.4149 29.3767 24.4468 28.571 26.8847 28.571H31.8808C37.1704 28.571 41.4749 32.842 41.4749 38.1311V39.3749C41.4749 40.5347 40.5347 41.4749 39.3749 41.4749H2.6249ZM24.1435 37.2749H37.2063C36.7943 34.7276 34.574 32.771 31.8808 32.771H26.8847C25.3776 32.771 24.2458 33.2614 23.3557 34.209C23.7765 35.1599 24.0489 36.1926 24.1435 37.2749Z'
			fill='#292929'
		/>
		<path
			d='M11.5498 7.35C11.5498 6.1902 12.49 5.25 13.6498 5.25V5.25C14.8096 5.25 15.7498 6.1902 15.7498 7.35V7.35C15.7498 8.5098 14.8096 9.45 13.6498 9.45V9.45C12.49 9.45 11.5498 8.5098 11.5498 7.35V7.35Z'
			fill='#292929'
		/>
		<path
			d='M18.8999 7.35C18.8999 6.1902 19.8401 5.25 20.9999 5.25V5.25C22.1597 5.25 23.0999 6.1902 23.0999 7.35V7.35C23.0999 8.5098 22.1597 9.45 20.9999 9.45V9.45C19.8401 9.45 18.8999 8.5098 18.8999 7.35V7.35Z'
			fill='#292929'
		/>
		<path
			d='M26.25 7.35C26.25 6.1902 27.1902 5.25 28.35 5.25V5.25C29.5098 5.25 30.45 6.1902 30.45 7.35V7.35C30.45 8.5098 29.5098 9.45 28.35 9.45V9.45C27.1902 9.45 26.25 8.5098 26.25 7.35V7.35Z'
			fill='#292929'
		/>
	</svg>
);
export const AlertSavedIcon = () => (
	<svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7.34955 61.0209L5 71.5939L26.5 63M49.6414 46.9236C53.7531 50.4479 51.4035 53.3849 49.1699 54.0552L45.8363 55.3914M11.5 41.5C10.9444 46.1014 18.4994 58.4995 26.5 63M26.5 63L35.0022 59.7344L45.8363 55.3914M22.6216 21.0786C20.677 20.2434 16.6675 20.815 16.6675 24.8239C16.6675 29.8349 26.6683 48.376 45.8363 55.3914'
			stroke='#292929'
			strokeWidth='6'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M32 6.5C34.0294 10.1548 35.5 14.5 30.5 25.5'
			stroke='#292929'
			strokeWidth='6'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M44.4559 24.272L34.5 35M40 43.5L50.5 34L60.5 25.5'
			stroke='#292929'
			strokeWidth='6'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<rect x='44' y='11' width='2.34955' height='2.34955' rx='1.17477' stroke='#292929' strokeWidth='6' />
		<path
			d='M59.7622 13L58.5874 10.6504'
			stroke='#292929'
			strokeWidth='6'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M70.7023 19.9443L73.6472 18.4052'
			stroke='#292929'
			strokeWidth='6'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M68.1423 32.5369L70.3578 36.9631'
			stroke='#292929'
			strokeWidth='6'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const Logo = () => (
	<svg width='21' height='25' viewBox='0 0 21 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M9.17944 24.999C7.83453 24.999 6.66381 24.8172 5.66728 24.4536C4.67076 24.0899 3.84032 23.5919 3.17596 22.9595C2.51161 22.3271 2.0174 21.5801 1.69333 20.7184C1.36925 19.8567 1.20721 18.9279 1.20721 17.9318V8.91998H6.99194V17.3152C6.99194 18.1216 7.19043 18.75 7.58742 19.2006C7.98441 19.6512 8.54749 19.8765 9.27666 19.8765C10.0058 19.8765 10.5689 19.6512 10.9659 19.2006C11.3629 18.75 11.5614 18.1216 11.5614 17.3152V12.2401L17.3218 9.82117V17.9279C17.3218 18.9239 17.1476 19.8528 16.7992 20.7144C16.4508 21.5761 15.9364 22.3231 15.2558 22.9556C14.5753 23.588 13.7286 24.086 12.7159 24.4496C11.7032 24.8172 10.5243 24.999 9.17944 24.999Z'
			fill='#282929'
		/>
		<path
			d='M3.17595 22.9911C3.8403 23.6274 4.67074 24.1255 5.66322 24.4931L7.58741 19.2282C7.19852 18.7697 7.00407 18.1334 7.00407 17.3231V11.7776L1.1991 9.8725V17.9397C1.1991 18.9397 1.36113 19.8764 1.68521 20.75C2.01333 21.6077 2.50755 22.3547 3.17595 22.9911Z'
			fill='url(#paint0_linear)'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.00002 15.7144C6.28706 15.7816 5.54979 15.5484 5.00292 15.0187L1.1991 11.3033V9.8725L7.00002 11.7737V15.7144Z'
			fill='#292929'
			fillOpacity='0.5'
		/>
		<path
			d='M13.7083 1.47332C15.7216 -0.491107 18.9867 -0.491107 21 1.47332L10.3825 11.833L6.73669 8.27569L13.7083 1.47332Z'
			fill='url(#paint1_linear)'
		/>
		<path
			d='M0.725152 9.46936C-0.243019 8.5247 -0.243019 6.9911 0.725152 6.04644L0.862883 5.91205C1.83105 4.96739 3.40281 4.96739 4.37099 5.91205L10.4109 11.8053C8.39761 13.7698 5.13661 13.7698 3.1233 11.8053L0.725152 9.46936Z'
			fill='url(#paint2_linear)'
		/>
		<path
			d='M3.17595 22.9911C3.8403 23.6274 4.67074 24.1255 5.66322 24.4931L7.58741 19.2282C7.19852 18.7697 7.00407 18.1334 7.00407 17.3231V11.7776L1.1991 9.8725V17.9397C1.1991 18.9397 1.36113 19.8764 1.68521 20.75C2.01333 21.6077 2.50755 22.3547 3.17595 22.9911Z'
			fill='url(#paint3_linear)'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.00002 15.7144C6.28706 15.7816 5.54979 15.5484 5.00292 15.0187L1.1991 11.3033V9.8725L7.00002 11.7737V15.7144Z'
			fill='#292929'
			fillOpacity='0.5'
		/>
		<path
			d='M13.7083 1.47332C15.7216 -0.491107 18.9867 -0.491107 21 1.47332L10.3825 11.833L6.73669 8.27569L13.7083 1.47332Z'
			fill='url(#paint4_linear)'
		/>
		<path
			d='M0.725152 9.46936C-0.243019 8.5247 -0.243019 6.9911 0.725152 6.04644L0.862883 5.91205C1.83105 4.96739 3.40281 4.96739 4.37099 5.91205L10.4109 11.8053C8.39761 13.7698 5.13661 13.7698 3.1233 11.8053L0.725152 9.46936Z'
			fill='url(#paint5_linear)'
		/>
		<defs>
			<linearGradient
				id='paint0_linear'
				x1='4.39382'
				y1='9.87353'
				x2='4.39382'
				y2='24.4896'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#CB00FF' />
				<stop offset='0.637' stopColor='#CD0CFF' stopOpacity='0' />
			</linearGradient>
			<linearGradient
				id='paint1_linear'
				x1='19.177'
				y1='-0.306206'
				x2='8.81966'
				y2='10.3088'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.7292' stopColor='#6A02FF' />
				<stop offset='1' stopColor='#5101A8' />
			</linearGradient>
			<linearGradient
				id='paint2_linear'
				x1='0.794463'
				y1='5.9792'
				x2='8.39481'
				y2='13.7687'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.4948' stopColor='#CD0CFF' />
				<stop offset='1' stopColor='#6A02FF' />
			</linearGradient>
			<linearGradient
				id='paint3_linear'
				x1='4.39382'
				y1='9.87353'
				x2='4.39382'
				y2='24.4896'
				gradientUnits='userSpaceOnUse'
			>
				<stop stopColor='#CB00FF' />
				<stop offset='0.637' stopColor='#CD0CFF' stopOpacity='0' />
			</linearGradient>
			<linearGradient
				id='paint4_linear'
				x1='19.177'
				y1='-0.306206'
				x2='8.81966'
				y2='10.3088'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.7292' stopColor='#6A02FF' />
				<stop offset='1' stopColor='#5101A8' />
			</linearGradient>
			<linearGradient
				id='paint5_linear'
				x1='0.794463'
				y1='5.9792'
				x2='8.39481'
				y2='13.7687'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0.4948' stopColor='#CD0CFF' />
				<stop offset='1' stopColor='#6A02FF' />
			</linearGradient>
		</defs>
	</svg>
);

export const CloseIcon = () => (
	<svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z'
			fill='#E3E6EA'
		/>
		<path
			d='M18.0163 17.2287C16.94 16.1525 15.8638 15.0762 14.7875 14C15.5663 13.2212 16.345 12.4425 17.115 11.6725C17.4125 11.375 17.7188 11.0687 18.0163 10.7712C18.515 10.2725 17.7363 9.485 17.2375 9.9925C16.1613 11.0687 15.085 12.145 14.0088 13.2212C13.23 12.4425 12.4513 11.6637 11.6813 10.8937C11.3838 10.5962 11.0775 10.29 10.78 9.9925C10.2813 9.49375 9.49375 10.2725 10.0013 10.7712C11.0775 11.8475 12.1538 12.9237 13.23 14C12.4513 14.7787 11.6725 15.5575 10.9025 16.3275C10.605 16.625 10.2988 16.9312 10.0013 17.2287C9.5025 17.7275 10.2813 18.515 10.78 18.0075C11.8563 16.9312 12.9325 15.855 14.0088 14.7787C14.7875 15.5575 15.5663 16.3362 16.3363 17.1062C16.6338 17.4037 16.94 17.71 17.2375 18.0075C17.7363 18.515 18.515 17.7362 18.0163 17.2287Z'
			fill='#292929'
		/>
	</svg>
);

export const LeftArrowIcon = () => (
	<svg width='16' height='16' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M20.4783 6.88465C20.1557 6.70138 19.7596 6.70545 19.441 6.8953L9.57376 14.1141C9.26157 14.3 9.07031 14.6366 9.07031 15C9.07031 15.3634 9.26157 15.7 9.57376 15.8859L19.441 23.1047C19.7596 23.2946 20.1557 23.2986 20.4783 23.1153C20.8008 22.9321 21 22.5897 21 22.2188L21 7.78125C21 7.4103 20.8008 7.06793 20.4783 6.88465Z'
			fill='#292929'
		/>
	</svg>
);

export const RightArrowIcon = () => (
	<svg width='16' height='16' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.52175 6.88465C9.84426 6.70138 10.2404 6.70545 10.559 6.8953L20.4262 14.1141C20.7384 14.3 20.9297 14.6366 20.9297 15C20.9297 15.3634 20.7384 15.7 20.4262 15.8859L10.559 23.1047C10.2404 23.2946 9.84426 23.2986 9.52175 23.1153C9.19923 22.9321 9 22.5897 9 22.2188L9 7.78125C9 7.4103 9.19923 7.06793 9.52175 6.88465Z'
			fill='#292929'
		/>
	</svg>
);

export const BackArrow = () => (
	<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6.94537 3.65772C6.63295 3.3453 6.12642 3.3453 5.814 3.65772L1.814 7.65772C1.50158 7.97013 1.50158 8.47667 1.814 8.78909L5.814 12.7891C6.12642 13.1015 6.63295 13.1015 6.94537 12.7891C7.25779 12.4767 7.25779 11.9701 6.94537 11.6577L4.31106 9.0234H15.3797C15.8215 9.0234 16.1797 8.66523 16.1797 8.2234C16.1797 7.78157 15.8215 7.4234 15.3797 7.4234H4.31106L6.94537 4.78909C7.25779 4.47667 7.25779 3.97013 6.94537 3.65772Z'
			fill='#292929'
		/>
	</svg>
);

export const DeskPhone = () => (
	<svg width='20' height='20' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.25 0.922363L12.25 4.31247H30.8928V28.9962H0.892822V1.03614L12.25 0.922363ZM3.89282 4.00623V25.9962H27.8928V7.31247H9.24997L9.24996 3.95257L3.89282 4.00623Z'
			fill='#292929'
		/>
		<path fillRule='evenodd' clipRule='evenodd' d='M9.25 28.3125V7.125H12.25V28.3125H9.25Z' fill='#292929' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M28.75 14.25L10.375 14.25L10.375 11.25L28.75 11.25L28.75 14.25Z'
			fill='#292929'
		/>
		<path fillRule='evenodd' clipRule='evenodd' d='M17.5 19.5H14.5V16.5H17.5V19.5Z' fill='#292929' />
		<path fillRule='evenodd' clipRule='evenodd' d='M17.5 24H14.5V21H17.5V24Z' fill='#292929' />
		<path fillRule='evenodd' clipRule='evenodd' d='M21.625 19.5H18.625V16.5H21.625V19.5Z' fill='#292929' />
		<path fillRule='evenodd' clipRule='evenodd' d='M21.625 24H18.625V21H21.625V24Z' fill='#292929' />
		<path fillRule='evenodd' clipRule='evenodd' d='M25.75 19.5H22.75V16.5H25.75V19.5Z' fill='#292929' />
		<path fillRule='evenodd' clipRule='evenodd' d='M25.75 24H22.75V21H25.75V24Z' fill='#292929' />
	</svg>
);

export const TwitterIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<g clipPath='url(#clip0)'>
			<path
				d='M17.96 6.4575C17.96 6.27667 17.9533 6.10083 17.945 5.9275C18.7583 5.35 19.4425 4.62917 20 3.79917V3.79833C19.2558 4.12417 18.4642 4.34083 17.6375 4.44583C18.4875 3.93833 19.1367 3.14083 19.4417 2.18C18.6492 2.6525 17.7742 2.98583 16.8417 3.1725C16.0892 2.37083 15.0167 1.875 13.8467 1.875C11.5767 1.875 9.74917 3.7175 9.74917 5.97583C9.74917 6.30083 9.77667 6.61333 9.84417 6.91083C6.43583 6.74417 3.41917 5.11083 1.3925 2.62167C1.03917 3.23583 0.830833 3.93833 0.830833 4.69417C0.830833 6.11417 1.56167 7.3725 2.6525 8.10167C1.99333 8.08917 1.3475 7.8975 0.8 7.59667V7.64167C0.8 9.63417 2.22083 11.2892 4.085 11.6708C3.45833 11.8425 2.68667 11.8358 2.23417 11.7358C2.765 13.3592 4.27333 14.5533 6.065 14.5925C4.67 15.6833 2.89833 16.3408 0.980833 16.3408C0.645 16.3408 0.3225 16.3258 0 16.285C1.81667 17.4558 3.96833 18.125 6.29 18.125C13.835 18.125 17.96 11.875 17.96 6.4575Z'
				fill='#55ACEE'
			/>
		</g>
		<defs>
			<clipPath id='clip0'>
				<rect width='20' height='20' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export const SkypeIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M19.4242 12.1214C20.8725 5.61308 15.1708 -0.299415 8.475 0.835585C4.84 -1.42025 0 1.12892 0 5.45392C0 6.45975 0.2775 7.40142 0.76 8.21309C-0.585833 14.7364 5.1625 20.5639 11.8525 19.3514C12.6325 19.7639 13.5225 19.9989 14.4683 19.9989C18.5925 19.9989 21.2333 15.7214 19.4242 12.1214ZM10.08 16.5648C8.12833 16.5648 6.22167 16.0939 5.09333 14.4823C3.41167 12.1039 5.62917 10.9139 6.7175 11.8206C7.62583 12.5864 7.34083 14.4389 10.0083 14.4389C11.1842 14.4389 12.6358 13.9373 12.6358 12.7714C12.6358 10.4256 7.43167 11.5356 5.3625 8.97808C4.6025 8.04142 4.45917 6.38892 5.3925 5.19058C7.01917 3.09725 11.7925 2.96975 13.845 4.55058C15.74 6.01725 15.2283 7.96392 13.6992 7.96392C11.86 7.96392 12.8267 5.55725 9.8575 5.55725C7.705 5.55725 6.85833 7.08808 8.37417 7.83309C10.3917 8.83475 15.6658 8.52225 15.6658 12.5023C15.6592 15.2998 12.96 16.5648 10.08 16.5648Z'
			fill='#03A9F4'
		/>
	</svg>
);

export const LinkedInIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M19.9951 20V19.9992H20.0001V12.6642C20.0001 9.07587 19.2276 6.31171 15.0326 6.31171C13.0159 6.31171 11.6626 7.41837 11.1101 8.46754H11.0517V6.64671H7.07422V19.9992H11.2159V13.3875C11.2159 11.6467 11.5459 9.96337 13.7017 9.96337C15.8259 9.96337 15.8576 11.95 15.8576 13.4992V20H19.9951Z'
			fill='#0077B5'
		/>
		<path d='M0.3302 6.64771H4.47687V20.0002H0.3302V6.64771Z' fill='#0077B5' />
		<path
			d='M2.40167 0C1.07583 0 0 1.07583 0 2.40167C0 3.7275 1.07583 4.82583 2.40167 4.82583C3.7275 4.82583 4.80333 3.7275 4.80333 2.40167C4.8025 1.07583 3.72667 0 2.40167 0V0Z'
			fill='#0077B5'
		/>
	</svg>
);
