import React, { useState } from 'react';
import { Box, ClickAwayListener, List, ListItem, Modal, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { DownFilledArrow } from '../../config/svg/ArrowSvg';

export const DropDown: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { buttonIcon, buttonText, children, required, openDropdown, setOpenDropdown } = props;

	const handleClick = (event: any) => {
		setOpenDropdown((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpenDropdown(false);
	};

	return (
		<>
			<OKRButton
				className={`btn-with-border ${openDropdown ? 'open' : required ? 'error-btn' : ''}`}
				id={'select-feature'}
				icon={buttonIcon || <DownFilledArrow />}
				text={buttonText || 'Select Type'}
				handleClick={handleClick}
			></OKRButton>
			{required && (
				<Typography className='error-field feature-select-error'>
					<ErrorIcon />
					{t('featureTypeRequired')}
				</Typography>
			)}
			{openDropdown && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='list-dropdown'>{children}</Box>
				</ClickAwayListener>
			)}
		</>
	);
};
